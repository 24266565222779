import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Container } from '@material-ui/core';
import MessageBox from "util/messageBox";
import { connect } from "react-redux";
import { removeError } from "services/error/action";
import { Link } from 'react-router-dom';  

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    height: '100%'
  },
  contentWrap: {
    //background: 'url(' + bg + ')',
    //backgroundSize: 'cover',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100vh',
    position: 'fixed'
    //overflowY: 'scroll'
  }
}));

const Minimal = props => {
  const { children } = props;

  const classes = useStyles();

  const handleCloseMsg = () => {
    props.removeError();
  };

  const showMsgBox = () => {
    return props.error.message && props.error.message !== "";
  };

  return (
    <section className='header'>
    {children}
    </section>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const mapDispatchToProps = {
  removeError
};
const mapStateToProps = state => ({
  error: state.error
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Minimal);

