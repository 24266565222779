import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import { getLocations, getProviders } from 'services/util/action';
import { connect } from 'react-redux';
import { TextField, Box, IconButton, Typography } from '@material-ui/core';
import {
  ArrowForwardIosRounded,
  ArrowBackIosRounded
} from '@material-ui/icons';
import moment from 'moment';

const PatientFilter = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      clearFilter
    };
  });
  const { classes, locations, providers, ehrID, seldate, isWeekly } = props;
  const [locs, setLocs] = useState([]);
  const [pros, setPros] = useState([]);
  const [selecteddate, setSelecteddate] = useState(
    moment().format('MM/DD/YYYY')
  );
  const [filtervalues, setFilterValues] = useState({
    location: '',
    provider: ''
  });

  const loadLocalStorage = async () => {
    setFilterValues({
      location: localStorage.getItem('filterPTScheduleLocation')?localStorage.getItem('filterPTScheduleLocation'):'',
      provider: localStorage.getItem('filterPTScheduleProvider')?localStorage.getItem('filterPTScheduleProvider'):''
    });
    // if (isWeekly) {
    //   props.handleDrpDownChangeptScheduleWeekly(
    //     localStorage.getItem('filterPTScheduleLocation'),
    //     localStorage.getItem('filterPTScheduleProvider')
    //   );
    // } else {
    //   props.handleDrpDownChangeptScheduleDaily(
    //     localStorage.getItem('filterPTScheduleLocation'),
    //     localStorage.getItem('filterPTScheduleProvider'),
    //     selecteddate
    //   );
    // }
  };

  useEffect(() => {
    props.getLocations(ehrID);
    props.getProviders(ehrID);
  }, []);

  useEffect(() => {
    if (seldate) {
      setSelecteddate(seldate);
    }
  }, [seldate]);

  useEffect(() => {
    if (props.isLocalStorage) {
      loadLocalStorage();
    }
  }, [props.isLocalStorage]);

  useEffect(() => {
    if (locations != undefined) {
      let locationtopush = [];
      locationtopush.push({ key: 'All Locations', value: '' });
      locations.forEach(function (item) {
        locationtopush.push({
          value: item.id,
          key:
            (item.companyname !== null ? item.companyname : '') +
            ',' +
            (item.address && item.address.address ? item.address.address : '')
        });
      });
      setLocs(locationtopush);
    }
  }, [locations]);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providertopush.push({ key: 'All Providers', value: '' });
      providers.forEach(function (item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const clearFilter = () => {
    setFilterValues({});
    if (isWeekly) {
      localStorage.setItem('filterPTScheduleLocation', '');
      localStorage.setItem('filterPTScheduleProvider', '');
      props.handleDrpDownChangeptScheduleWeekly('', '', '', '');
    } else {
      localStorage.setItem('filterPTScheduleLocation', '');
      localStorage.setItem('filterPTScheduleProvider', '');
      props.handleDrpDownChangeptScheduleDaily('', '', '');
    }
    // props.handleDrpDownChangeptSchedule('', '', '');
  };

  const handleToday = () => {
    setSelecteddate(moment().format('MM/DD/YYYY'));
    props.handleToday(isWeekly);
  };

  const handleNextPreviousDaily = isNext => {
    let date;
    if (isNext) {
      date = moment(seldate).add(1, 'days');
    } else {
      date = moment(seldate).subtract(1, 'days');
    }
    setSelecteddate(moment().format('MM/DD/YYYY'));
    props.handleNextPreviousDaily(moment(date).format('MM/DD/YYYY'));
  };

  const handleNextPreviousWeekly = isNext => {
    let date;
    if (isNext) {
      date = moment(seldate).add(7, 'days');
    } else {
      date = moment(seldate).subtract(7, 'days');
    }

    let startDate = moment(date)
      .startOf('week')
      .format('MM/DD/YYYY');
    let endDate = moment(date)
      .endOf('week')
      .format('MM/DD/YYYY');

    setSelecteddate(moment(date).format('MM/DD/YYYY'));
    props.handleNextPreviousWeekly(startDate, endDate);
  };

  const handleDrpDownChange = (field, value) => {
    const newState = { ...filtervalues };
    let location, provider, status;
    if (field === 'location') {
      localStorage.setItem('filterPTScheduleLocation', value);
      location = value;
    } else {
      location = newState.location;
    }
    if (field === 'provider') {
      localStorage.setItem('filterPTScheduleProvider', value);
      provider = value;
    } else {
      provider = newState.provider;
    }
    setFilterValues({ ...filtervalues, [field]: value });
    let startDate = moment(selecteddate)
      .startOf('week')
      .format('MM/DD/YYYY');
    let endDate = moment(selecteddate)
      .endOf('week')
      .format('MM/DD/YYYY');
    isWeekly
      ? props.handleDrpDownChangeptScheduleWeekly(
        location,
        provider,
        startDate,
        endDate
      )
      : props.handleDrpDownChangeptScheduleDaily(
        location,
        provider,
        selecteddate
      );
  };

  return (
    <Box className={classes.PTfilterWrap}>
      <Box className={classes.PTfilterLeft}>
        <TextField
          name="location"
          select
          value={filtervalues.location || ''}
          style={{ maxWidth: 200 }}
          SelectProps={{ native: true }}
          variant="outlined"
          onChange={event =>
            handleDrpDownChange('location', event.target.value)
          }>
          {locs &&
            locs.map(item => <option value={item.value}>{item.key}</option>)}
        </TextField>
        &nbsp;
        <TextField
          name="provider"
          value={filtervalues.provider || ''}
          select
          SelectProps={{ native: true }}
          variant="outlined"
          onChange={event =>
            handleDrpDownChange('provider', event.target.value)
          }>
          {pros &&
            pros.map(item => <option value={item.value}>{item.key}</option>)}
        </TextField>
      </Box>
      <Box className={classes.PTfilterRight}>
        <Typography
          component="span"
          className={classes.todayBtn}
          onClick={handleToday}>
          Today
        </Typography>
        <IconButton
          size="small"
          color="primary"
          component="span"
          onClick={() =>
            isWeekly
              ? handleNextPreviousWeekly(false)
              : handleNextPreviousDaily(false)
          }>
          <ArrowBackIosRounded className={classes.calendarArrow} />
        </IconButton>
        &nbsp;&nbsp;
        <IconButton
          size="small"
          color="primary"
          component="span"
          onClick={() =>
            isWeekly
              ? handleNextPreviousWeekly(true)
              : handleNextPreviousDaily(true)
          }>
          <ArrowForwardIosRounded className={classes.calendarArrow} />
        </IconButton>
        <Typography component="span" className={classes.calendarDate}>
          {moment(selecteddate).format('MMMM YYYY')}
        </Typography>
        <TextField
          onChange={e =>
            props.setWeekly(
              selecteddate,
              e.target.value === 'Weekly' ? true : false
            )
          }
          name="Title"
          select
          SelectProps={{ native: true }}
          style={{ marginRight: 10 }}
          variant="outlined">
          <option value={'Daily'}>Daily</option>
          <option value={'Weekly'}>Weekly</option>
        </TextField>
      </Box>
    </Box>
  );
});

PatientFilter.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getLocations,
  getProviders
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  locations: state.util.locations,
  providers: state.util.providers
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(PatientFilter));