/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { makeStyles, withStyles } from '@material-ui/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { PatientSearch } from '../../../../Common/components';
import addPatientIcon from '../../../../../assets/images/icon_addPatient.svg';
import styles from '../../../style';
import { connect } from 'react-redux';
// import { PatientDetails, DocViewer } from '../../Search/components';
import { Providers, PatientInfo } from './';
import { getPTInfo, getPTInfoDetail } from 'services/ptinfo/action';
import { computeAge } from 'util/helper';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  Icon,
  IconButton,
  Popover,
  Slide,
  Modal
} from '@material-ui/core';
import {
  PostAdd,
  AddCircleOutlineOutlined,
  GridOnOutlined,
  AttachmentOutlined,
  SearchOutlined,
  CloudUploadOutlined
} from '@material-ui/icons';

const Details = props => {
  const [search, setSearch] = useState('');
  const {
    classes,
    className,
    profile,
    ehrID, userID,
    ptInfoList,
    patientInfoDetail,
    ...rest
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDetails, setIsDetails] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [state, setState] = useState({ page: 0, count: 0 });
  const [openUpload, setOpenUpload] = useState(false);

  useEffect(() => {
    fetchPatientInfo()
  }, []);

  const fetchPatientInfo = () => {
    setIsDetails(!isDetails)
    props.getPTInfoDetail(ehrID, userID);
  };



  const handleOpenDoc = () => {
    setOpenDoc(true);
  };
  const handleCloseDoc = () => {
    setOpenDoc(false);
  };
  const handleOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  // const setDetails = () => {
  //   setIsDetails(!isDetails);
  // };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.subTabRoot, className)}
    >
      {patientInfoDetail && (<CardContent>
        <PatientInfo
          goBack={fetchPatientInfo}
          handleOpenDoc={handleOpenDoc}
          pastSoapNotes={false}
          patientInfoDetail={patientInfoDetail}
        />
      </CardContent>)}



      {/* <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        elevation={4}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <PerfectScrollbar>
          <Providers handleOpenDoc={handleOpenDoc} />
        </PerfectScrollbar>
      </Popover> */}
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { getPTInfo, getPTInfoDetail };

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  //userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  profile: state && state.profile,
  ptInfoList: state.ptinfo && state.ptinfo.list,
  patientInfoDetail: state.ptinfo && state.ptinfo.patientInfo
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(Details));
