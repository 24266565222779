import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../style';
import { Grid } from '@material-ui/core'; 
import { MyTemplates, TemplateText } from './components'; 
import { connect } from 'react-redux';
import { getObjectiveTemplates } from 'services/providers/action';

const Objective = props => {
  const { classes, ehrID, userID } = props;
  // const [loading, setLoading] = useState(true);
  const [objectiveTopics, setObjectiveTopics] = useState(null);
  const [objectiveTopic, setObjectiveTopic] = useState(null);

  useEffect(() => {
    props.getObjectiveTemplates(ehrID, userID).then((res => {
      if (res && res.data.length > 0) {
        setObjectiveTopics(res.data);
        // setLoading(false);
      }
    }));
  }, []);

  const setTopicText = topicText => {
    setObjectiveTopic(topicText);
  }

  return ( 
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.tabLabel}>Objective Template - User Defined</p>
        </Grid>
        <Grid item xs={12} md={6}>
          <MyTemplates objectiveTopics={objectiveTopics} setTopicText={setTopicText} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TemplateText objectiveTopic={objectiveTopic} />
        </Grid>
      </Grid> 
  );
};

Objective.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getObjectiveTemplates
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  objectiveTemplates: state.providers && state.providers.objectiveTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Objective))
);
