import moment from 'moment'; 

  export function b64toBlob(
  b64Data,
  name = 'avatar.png',
  contentType = '',
  sliceSize = 512
) {
  if (b64Data.indexOf('image/png') > -1) {
    contentType = 'image/png';
  } else if (b64Data.indexOf('image/jpeg') > -1) {
    contentType = 'image/jpeg';
    name = 'avatar.jpeg';
  }

  const startIndex = b64Data.indexOf('base64,') + 7;

  if (startIndex > -1) {
    b64Data = b64Data.substr(startIndex);
  }

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  blob.filename = name;
  return blob;
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

export const ptScheduleListTimeElapsed = sched => {
  if (sched) {
    var appointmentDate = sched.timeTo
      ? moment(sched.timeTo).format('MM/DD/YYYY')
      : '';
    var currentDateString = moment().format('MM/DD/YYYY');
    if (appointmentDate == currentDateString) {
      var appointmentTime = moment(sched.timeTo).format('hh:mm:ss A');
      var apptArr = appointmentTime.split(':');
      var apptAMPM = appointmentTime.split(':')[2].split(' ')[1];
      var apptHr = apptArr != null ? apptArr[0] : '';
      if (apptAMPM == 'PM' && apptHr < 12) apptHr = Number(apptHr) + 12;
      if (apptAMPM == 'AM' && apptHr == 12) apptHr = Number(apptHr) - 12;
      var apptHour = apptHr.toString() < 10 ? '0' + apptHr : apptHr;
      appointmentTime =
        apptHour +
        ':' +
        appointmentTime.split(':')[1] +
        ':' +
        appointmentTime.split(':')[2];

      var time = moment().format('hh:mm:ss A');
      var timeArr = time.split(':');
      var timeAMPM = time.split(':')[2].split(' ')[1];
      var tHr = timeArr != null ? timeArr[0] : '';
      if (timeAMPM == 'PM' && tHr < 12) tHr = Number(tHr) + 12;
      if (timeAMPM == 'AM' && tHr == 12) tHr = Number(tHr) - 12;
      var timeHour = tHr < 10 ? '0' + tHr : tHr;
      time = timeHour + ':' + time.split(':')[1] + ':' + time.split(':')[2];
      if (appointmentTime >= time) {
        return '';
      } else {
        return 'timeelapsed';
      }
    } else if (Date.parse(appointmentDate) < Date.parse(currentDateString)) {
      return 'timeelapsed';
    } else {
      return '';
    }
  }
};


export const computeAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export function getUrl(url) {
 console.log(process.env.REACT_APP_API_BASE_URL);
  return process.env.REACT_APP_API_BASE_URL?process.env.REACT_APP_API_BASE_URL + url:url;
} 
//  converting binary base64 to blob and then to convert to PDF in doc viewer
export const b64toBlob1 = (b64Data, contentType='', sliceSize=512) => {
  if(b64Data!==undefined){
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
 }
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
 }
  
}

export const truncate = (input) => input && input.length > 24 ? `${input.substring(0, 24)}...` : input;
 