export default (state = {}, action) => {
  switch (action.type) {
    case 'PTINFO_GET':
      const {
        content,
        totalPages,
        totalElements,
        last,
        size,
        sort,
        first,
        numberOfElements
      } = action.data;
      return {
        ...state,
        list: {
          result: copyArray(content),
          totalPages,
          totalElements,
          last,
          size,
          sort,
          first,
          numberOfElements
        }
      };

    case 'PTINFO_DETAILS_GET':
      return {
        ...state,
        patientInfo: action.data
      };

    default:
      return state;
  }
};

const copyArray = items => {
  return items.map(item => {
    return Object.assign({}, item);
  });
};
