import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Button,
  Modal,
  Box
} from '@material-ui/core';
import {
  ExpandMore,
  EditOutlined,
  CloseOutlined,
  ChevronLeftOutlined
} from '@material-ui/icons/';
import clsx from 'clsx';
import {
  OrderHx,
  EditAllergies,
  EditMedications,
  EditHealthMaintenance,
  EditVaccinations,
  EditSocialHistory,
  EditFamilyHistory,
  EditAdvanceDirective,
  EditAdditionalItems
} from './';
import styles from '../../../style';
import moment from 'moment';
import { Roles } from 'util/enum';
import Loader from '../../../../Common/components/Loader'
import { connect } from 'react-redux';
import _ from 'lodash';

const ScheduleAppo = props => {
  const { classes, patientInfo, ptRemarks, remarks, role } = props;

  const [loadallergy, setLoadAllergy] = useState(null);
  const [loadmedication, setLoadMedication] = useState(null);
  const [loadHealthMaintenance, setLoadHealthMaintenance] = useState(null);
  const [loadVaccination, setLoadVaccination] = useState(null);
  const [loadSocialHistory, setLoadSocialHistory] = useState(null);
  const [loadFamilyHistory, setLoadFamilyHistory] = useState(null);
  const [loadAdvanceDirectives, setLoadAdvanceDirectives] = useState(null);
  const [loadAdditionalItems, setLoadAdditionalItems] = useState(null);
  const [loadDiagnosis, setLoadDiagnosis] = useState(null);
  const [loadCpt, setLoadCpt] = useState(null);
  const [openAllergy, setOpenAllergy] = useState(false);
  const [openOrderHx, setOpenOrderHx] = useState(false); 
  const [openMedication, setOpenMedication] = useState(false);
  const [openHealthMaintenance, setOpenHealthMaintenance] = useState(false);
  const [openVaccinations, setOpenVaccinations] = useState(false);
  const [openSocialHistory, setOpenSocialHistory] = useState(false);
  const [openFamilyHistory, setOpenFamilyHistory] = useState(false);
  const [openAdvanceDirective, setOpenAdvanceDirective] = useState(false);
  const [openAdditionalItems, setOpenAdditionalItems] = useState(false); 

  //#region  OrderHx  
  const handleOpenOrderHx = () => {
    setOpenOrderHx(true);
  };

  const handleCloseOrderHx = () => {
    setOpenOrderHx(false);
  };

  const renderOrderHx = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseOrderHx}
        open={openOrderHx}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 650 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">Order Hx</Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={() => handleCloseOrderHx()}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <OrderHx
            onCancel={handleCloseOrderHx}
            patientInfo={patientInfo}
          />
        </Box>
      </Modal>
    );
  }; 
  //#endregion

  //#region Allergy 
  useEffect(() => {
    if (props.allergies) {
      let allergy = props.allergies;
      if(props.allergies && props.allergies.length >0)
      {
        allergy.sort((a, b) => b.id - a.id)
      }
      setLoadAllergy(allergy);
    } 
  }, [props.allergies]); 

  const _handleAllergyClick = event => {
    event.stopPropagation();
    handleOpenAllergy();
  };

  const handleOpenAllergy = () => {
    setOpenAllergy(true);
  };
  const handleCloseAllergy = async () => {
    setOpenAllergy(false);
  };

  const renderAllergy = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseAllergy}
        open={openAllergy}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Allergies :{' '}
              {patientInfo &&
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo && 
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseAllergy}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditAllergies
            allergies={props.allergies}
            getAllergy={props.getAllergy}
            onCancel={handleCloseAllergy}
            patientId={
              patientInfo &&
              patientInfo.id
            }
          />
        </Box>
      </Modal>
    );
  }; 
  //#endregion

  //#region Medications 
  useEffect(() => {
    if (props.medication) {
      setLoadMedication(props.medication);
    }
  }, [props.medication]); 

  const _handleMedicationClick = event => {
    event.stopPropagation();
    handleOpenMedication();
  };

  const handleOpenMedication = () => {
    setOpenMedication(true);
  };

  const handleCloseMedication = async () => {
    setOpenMedication(false);
  };

  const renderMedication = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseMedication}
        open={openMedication}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Medications :{' '}
              {patientInfo && 
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseMedication}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditMedications
            getInactiveMedication={props.getInactiveMedication}
            getMedication={props.getMedication}
            inactivemedication={props.inactivemedication}
            medication={props.medication}
            onCancel={handleCloseMedication}
            patientId={
              patientInfo &&
              patientInfo.id
            }

          />
        </Box>
      </Modal>
    );
  }; 
  //#endregion

  // #region Health maintenance 
  useEffect(() => {
    if (props.healthMaintenance) {
      setLoadHealthMaintenance(props.healthMaintenance);
    }
  }, [props.healthMaintenance]);


  const _handleHealthMaintenanceClick = event => {
    event.stopPropagation();
    handleOpenHealthMaintenance();
  };

  const handleOpenHealthMaintenance = () => {
    setOpenHealthMaintenance(true);
  };

  const handleCloseHealthMaintenance = async () => {
    setOpenHealthMaintenance(false);
  };

  const renderHealthMaintenance = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseHealthMaintenance}
        open={openHealthMaintenance}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Health Maintenance :{' '}
              {patientInfo &&
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseHealthMaintenance}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditHealthMaintenance
            getHealthMaintenance={props.getHealthMaintenance}

            onCancel={handleCloseHealthMaintenance}
            patientId={
              patientInfo &&
              patientInfo.id
            }
            healthMaintenance={props.healthMaintenance}

          />
        </Box>
      </Modal>
    );
  }; 
  // #endregion

  // #region Vaccinations  
  useEffect(() => {
    if (props.vaccinations) {
      setLoadVaccination(props.vaccinations);
    }
  }, [props.vaccinations]); 

  const _handleVaccinationsClick = event => {
    event.stopPropagation();
    handleOpenVaccinations();
  };

  const handleOpenVaccinations = () => {
    setOpenVaccinations(true);
  };

  const handleCloseVaccinations = async () => {
    setOpenVaccinations(false);
  };

  const renderVaccinations = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseVaccinations}
        open={openVaccinations}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Vaccinations :{' '}
              {patientInfo && 
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseVaccinations}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditVaccinations
            getVaccination={props.getVaccination}
            onCancel={handleCloseVaccinations}
            patientId={
              patientInfo &&
              patientInfo.id
            }
            vaccinations={props.vaccinations}

          />
        </Box>
      </Modal>
    );
  }; 
  // #endregion

  // #region Social History 
  useEffect(() => {
    if (props.socialHistory) {
      setLoadSocialHistory(props.socialHistory);
    }
  }, [props.socialHistory]); 

  const _handleSocialHistoryClick = event => {
    event.stopPropagation();
    handleOpenSocialHistory();
  };

  const handleOpenSocialHistory = () => {
    setOpenSocialHistory(true);
  };

  const handleCloseSocialHistory = async () => {
    setOpenSocialHistory(false);
  };

  const renderSocialHistory = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseSocialHistory}
        open={openSocialHistory}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Social History :{' '}
              {patientInfo &&
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseSocialHistory}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditSocialHistory
            getSocialHistory={props.getSocialHistory}
            onCancel={handleCloseSocialHistory}
            patientId={
              patientInfo &&
              patientInfo.id
            }
            socialHistory={props.socialHistory}

          />
        </Box>
      </Modal>
    );
  }; 
  // #endregion

  // #region Family History  
  useEffect(() => {
    if (props.familyHistory) {
      setLoadFamilyHistory(props.familyHistory);
    }
  }, [props.familyHistory]); 

  const _handleFamilyHistoryClick = event => {
    event.stopPropagation();
    handleOpenFamilyHistory();
  };

  const handleOpenFamilyHistory = () => {
    setOpenFamilyHistory(true);
  };

  const handleCloseFamilyHistory = async () => {
    setOpenFamilyHistory(false);
  };

  const renderFamilyHistory = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseFamilyHistory}
        open={openFamilyHistory}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Family History :{' '}
              {patientInfo &&
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseFamilyHistory}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditFamilyHistory
            familyHistory={props.familyHistory}
            getFamilyHistory={props.getFamilyHistory}
            onCancel={handleCloseFamilyHistory}
            patientId={
              patientInfo &&
              patientInfo.id
            }

          />
        </Box>
      </Modal>
    );
  }; 
  // #endregion

  // #region Advance Directives 
  useEffect(() => {
    if (props.advanceDirectives) {
      setLoadAdvanceDirectives(props.advanceDirectives);
    }
  }, [props.advanceDirectives]); 

  const _handleAdvanceDirectiveClick = event => {
    event.stopPropagation();
    handleOpenAdvanceDirective();
  };

  const handleOpenAdvanceDirective = () => {
    setOpenAdvanceDirective(true);
  };

  const handleCloseAdvanceDirective = async () => {
    setOpenAdvanceDirective(false);
  };

  const renderAdvanceDirective = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick 
        onClose={handleCloseAdvanceDirective}
        open={openAdvanceDirective}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Advance Directives :{' '}
              {patientInfo &&
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseAdvanceDirective}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditAdvanceDirective
            advanceDirectives={props.advanceDirectives}
            getAdvanceDirectives={props.getAdvanceDirectives}
            onCancel={handleCloseAdvanceDirective}
            patientId={
              patientInfo &&
              patientInfo.id
            }

          />
        </Box>
      </Modal>
    );
  }; 
  // #endregion

  // #region Additional Item 
  useEffect(() => {
    if (props.additionalItems) {
      setLoadAdditionalItems(props.additionalItems);
    }
  }, [props.additionalItems]); 

  const _handleAdditionalItemsClick = event => {
    event.stopPropagation();
    handleOpenAdditionalItems();
  };

  const handleOpenAdditionalItems = () => {
    setOpenAdditionalItems(true);
  };

  const handleCloseAdditionalItems = async () => {
    setOpenAdditionalItems(false);
  };

  const renderAdditionalItems = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseAdditionalItems}
        open={openAdditionalItems}
      >
        <Box
          className={classes.modalWrap}
          style={{ width: 750 }}
        >
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              Additional Items :{' '}
              {patientInfo &&
                patientInfo.lastname
                ? patientInfo.lastname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.firstname
                ? patientInfo.firstname
                : ''}
              &nbsp;
              {patientInfo &&
                patientInfo.middleinitial
                ? patientInfo.middleinitial
                : ''}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseAdditionalItems}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <EditAdditionalItems
            additionalItems={props.additionalItems}
            getAdditionalItems={props.getAdditionalItems}
            onCancel={handleCloseAdditionalItems}
            patientId={
              patientInfo &&
              patientInfo.id
            }

          />
        </Box>
      </Modal>
    );
  }; 
  // #endregion

  //#region PastDiagnosis && CPT 
  useEffect(() => {
    if (props.cpt) {
      setLoadCpt(props.cpt);
    } else {
      setLoadCpt([]);
    }
  }, [props.cpt]);

  useEffect(() => {
    if (props.diagnosis) {
      setLoadDiagnosis(props.diagnosis);
    }
  }, [props.diagnosis]);

  const [copyDia, setCopyDia] = useState([]);
  const [copyCpt, setCopyCpt] = useState([]);


  // const handleCopyfromMHx = item => {
  //   let newDia = [];
  //   let newCpt = [];
  //   if (item.codeType === 'diagnosis') {
  //     if (copyDia && copyDia.length > 0) {
  //       newDia = [...copyDia];
  //     }
  //     newDia.push(item);
  //     setCopyDia(newDia);
  //   } else {
  //     if (copyCpt && copyCpt.length > 0) {
  //       newCpt = [...copyCpt];
  //     }
  //     newCpt.push(item);
  //     setCopyCpt(newCpt);
  //   }
  //   props.handleCopy(newDia, newCpt);
  // };


  const handleCopyfromMHx = (item, type) => {
    if (type === 'diagnosis') {
      props.handleCopy([item], []);
    } else {
      props.handleCopy([], [item]);
    }
  };

  //#endregion

  return (
    <Grid
      className={classes.rightWrap}
      item
      xs={12}
    >
      <PerfectScrollbar style={{ overflowX: 'hidden!important' }}>
        <div className={classes.MHXwrap}>
          <div className={classes.sectionHeader}>
            Medical Hx Summary
            {!props.isViewSoap ? (
              <Button
                color="primary"
                onClick={handleOpenOrderHx}
                size="small"
                variant="text"
              >
                Order Hx
              </Button>
            ) : (
              ''
            )}
          </div>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true"
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header"
              style={{ alignItems: 'center' }}
            >
              <Typography className={classes.heading}>Allergies</Typography>
              {!props.isViewSoap && (
                <IconButton
                  onClick={_handleAllergyClick}
                  size="small"
                >
                  <EditOutlined />
                </IconButton>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.allergyTable)}>
                <TableBody>
                  {!loadallergy && <Loader type="sidebar"/>}
                  {!_.isEmpty(loadallergy) && _.map(loadallergy, item => {
                        return (
                          <TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.reaction}</TableCell>
                          </TableRow>
                        );
                  })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true"
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Medications</Typography>
              {!props.isViewSoap && (
                <IconButton
                  onClick={_handleMedicationClick}
                  size="small"
                >
                  <EditOutlined />
                </IconButton>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.medicTable)}>
                <TableBody>
                {!loadmedication && <Loader type="sidebar"/>}
                  {!_.isEmpty(loadmedication) && 
                    loadmedication
                      .filter(c => c.isActive === true)
                      .sort((a, b) => b.id - a.id)
                      .map(item => {
                        return (
                          <TableRow>
                            <TableCell>{item.name} </TableCell>
                            <TableCell>{item.dose} </TableCell>
                            <TableCell>{item.directions}</TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true"
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Clinical Findings
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={classes.plainTable}>
                <TableBody>
                {role !== Roles.patient && !props.isViewSoap  && !ptRemarks && <Loader type="sidebar"/>}
                {role !== Roles.patient && props.isViewSoap && !ptRemarks && <Loader type="sidebar"/>}
                  {role !== Roles.patient && !props.isViewSoap ? ptRemarks &&
                    ptRemarks.length > 0 &&
                    ptRemarks
                      .filter(c => c.isActive === true && c.remarks !== null)
                      .sort((a, b) => b.id - a.id)
                      .map(item => (
                        <TableRow>
                          <TableCell>{item.remarks} </TableCell>
                          <TableCell>
                            {item.updatedDate
                              ? moment(item.updatedDate).format('MM/DD/YYYY')
                              : ''}
                          </TableCell>
                        </TableRow>
                      )):null}

                {role !== Roles.patient && props.isViewSoap ? ptRemarks &&
                    ptRemarks.length > 0 &&
                    ptRemarks
                      .filter(c => c.isActive === true && c.remarks !== null)
                      .sort((a, b) => b.id - a.id)
                      .map(item => (
                        <TableRow>
                          <TableCell>{item.remarks} </TableCell>
                          <TableCell>
                            {item.updatedDate
                              ? moment(item.updatedDate).format('MM/DD/YYYY')
                              : ''}
                          </TableCell>
                        </TableRow>
                      )):null}

            {role === Roles.patient && (remarks &&
                    remarks.length > 0 ?
                    remarks
                      .filter(c => c.isActive === true && c.remarks !== null)
                      .sort((a, b) => b.id - a.id)
                      .map(item => (
                        <TableRow>
                          <TableCell>{item.remarks} </TableCell>
                          <TableCell>
                            {item.updatedDate
                              ? moment(item.updatedDate).format('MM/DD/YYYY')
                              : ''}
                          </TableCell>
                        </TableRow>
                      )):null )}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true"
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Health Maintenance
              </Typography>
              {!props.isViewSoap && (
                <IconButton
                  onClick={_handleHealthMaintenanceClick}
                  size="small"
                >
                  <EditOutlined />
                </IconButton>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.healthMaint)}>
                <TableBody>
                {!loadHealthMaintenance && <Loader type="sidebar"/>}
                  {loadHealthMaintenance &&
                    loadHealthMaintenance.length > 0 &&
                    loadHealthMaintenance
                      .sort((a, b) => b.id - a.id)
                      .map(item => {
                        return (
                          <TableRow>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                              {' '}
                              {item.recordedDate
                                ? moment(item.recordedDate).format('MM/DD/YYYY')
                                : ''}
                            </TableCell>
                            <TableCell>{item.findings}</TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>{' '}
          {role !== Roles.patient && (
            <span>
              <ExpansionPanel
                className={classes.accordionWrap}
                defaultExpanded="true"
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMore />}
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Past Diagnosis
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table
                    className={clsx(classes.plainTable, classes.pastDiagTable)}
                  >
                    <TableBody>
                      {!loadDiagnosis && <Loader type="sidebar"/>}
                      {loadDiagnosis &&
                        loadDiagnosis.length > 0 &&
                        loadDiagnosis
                          .sort((a, b) => b.id - a.id)
                          .map(item => {
                            return (
                              <TableRow>
                                <TableCell>
                                  <IconButton
                                    color="primary"
                                    size="small"
                                  >
                                    <ChevronLeftOutlined
                                      className={classes.iconBlue}
                                      onClick={() => handleCopyfromMHx(item, "diagnosis")}
                                    />
                                  </IconButton>
                                </TableCell>
                                <TableCell>{item.code}</TableCell>
                                <TableCell>{item.description}</TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <ExpansionPanel
                className={classes.accordionWrap}
                defaultExpanded="true"
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMore />}
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>CPT</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table className={classes.plainTable}>
                    <TableBody>
                      {!loadCpt && <Loader type="sidebar"/>}
                      {loadCpt &&
                        loadCpt.length > 0 &&
                        loadCpt
                          .sort((a, b) => b.id - a.id)
                          .map(item => (
                            <TableRow>
                              <TableCell>
                                <IconButton
                                  color="primary"
                                  size="small"
                                >

                                  <ChevronLeftOutlined
                                    className={classes.iconBlue}
                                    onClick={() => handleCopyfromMHx(item, "cpt")}

                                  />
                                </IconButton>
                              </TableCell>
                              <TableCell>{item.code}</TableCell>
                              <TableCell>{item.description}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </span>
          )}
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true"
          >
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Vaccinations</Typography>
              {!props.isViewSoap && (
                <IconButton
                  onClick={_handleVaccinationsClick}
                  size="small"
                >
                  <EditOutlined />
                </IconButton>
              )}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.vaccination)}>
                <TableBody>
                  {!loadVaccination  && <Loader type="sidebar"/>}
                  {loadVaccination &&
                    loadVaccination.length > 0 &&
                    loadVaccination
                      .sort((a, b) => b.id - a.id)
                      .map(item => {
                        return (
                          <TableRow>
                            <TableCell>{item.name} </TableCell>
                            <TableCell>
                              {item.dategiven
                                ? moment(item.dategiven).format('MM/DD/YYYY')
                                : ''}
                            </TableCell>
                            <TableCell>{item.lot} </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {role !== Roles.patient && (
            <span>
              <ExpansionPanel  
                className={classes.accordionWrap}
                defaultExpanded="true"
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMore />}
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Social History
                  </Typography>
                  {!props.isViewSoap && (
                    <IconButton
                      onClick={_handleSocialHistoryClick}
                      size="small"
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table
                    className={clsx(classes.plainTable, classes.socialHTable)}
                  >
                    <TableBody>
                      {!loadSocialHistory && <Loader type="sidebar"/>}
                      {loadSocialHistory &&
                        loadSocialHistory.length > 0 &&
                        loadSocialHistory
                          .sort((a, b) => b.id - a.id)
                          .map(item => {
                            return (
                              <TableRow>
                                <TableCell>{item.description}</TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                className={classes.accordionWrap}
                defaultExpanded="true"
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMore />}
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Family History
                  </Typography>
                  {!props.isViewSoap && (
                    <IconButton
                      onClick={_handleFamilyHistoryClick}
                      size="small"
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table
                    className={clsx(classes.plainTable, classes.FamilyHistory)}
                  >
                    <TableBody>
                      {!loadFamilyHistory  && <Loader type="sidebar"/>}
                      {loadFamilyHistory &&
                        loadFamilyHistory.length > 0 &&
                        loadFamilyHistory
                          .sort((a, b) => b.id - a.id)
                          .map(item => {
                            return (
                              <TableRow>
                                <TableCell>{item.description}</TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                className={classes.accordionWrap}
                defaultExpanded="true"
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMore />}
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Advance Directives
                  </Typography>
                  {!props.isViewSoap && (
                    <IconButton
                      onClick={_handleAdvanceDirectiveClick}
                      size="small"
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table className={classes.plainTable}>
                    <TableBody>
                      {!loadAdvanceDirectives && <Loader type="sidebar"/>}
                      {loadAdvanceDirectives &&
                        loadAdvanceDirectives.length > 0 &&
                        loadAdvanceDirectives
                          .sort((a, b) => b.id - a.id)
                          .map(item => (
                            <TableRow>
                              <TableCell>{item.description} </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel
                className={classes.accordionWrap}
                defaultExpanded="true"
              >
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMore />}
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Additional Items
                  </Typography>
                  {!props.isViewSoap && (
                    <IconButton
                      onClick={_handleAdditionalItemsClick}
                      size="small"
                    >
                      <EditOutlined />
                    </IconButton>
                  )}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Table className={classes.plainTable}>
                    <TableBody>
                      {loadAdditionalItems === null && <Loader type="sidebar"/>}
                      {loadAdditionalItems &&
                        loadAdditionalItems.length > 0 &&
                        loadAdditionalItems
                          .sort((a, b) => b.id - a.id)
                          .map(item => (
                            <TableRow>
                              <TableCell>{item.description} </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </span>
          )}
        </div>
      </PerfectScrollbar>
      {openOrderHx && renderOrderHx()}
      {openAllergy && renderAllergy()}
      {openMedication && renderMedication()}
      {openHealthMaintenance && renderHealthMaintenance()}
      {openVaccinations && renderVaccinations()}
      {openSocialHistory && renderSocialHistory()}
      {openFamilyHistory && renderFamilyHistory()}
      {openAdvanceDirective && renderAdvanceDirective()}
      {openAdditionalItems && renderAdditionalItems()}
    </Grid>
  );
};

ScheduleAppo.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { };

const mapStateToProps = state => ({
  role: state.profile && state.profile.role,
  patientInfo: state.soap && state.soap.patientInfo,
  ptRemarks: state.soap && state.soap.ptRemarks,
  remarks: state.soap && state.soap.patientInfo && state.soap.patientInfo.remarks
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleAppo))
);
