import { LockOpenOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import styles from '../../../SignIn/style';

import {
  CircularProgress, Fab, Link, TextField, Typography,
  withStyles
} from '@material-ui/core';

import { connect } from "react-redux";
import { forgotUsername } from "services/user/action";

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: { required: true, message: '^Email is not valid' },
    length: {
      minimum: 4,
      maximum: 64
    }
  }
}

const ForgotUn = props => {
  const { classes } = props;

  const [values, setValues] = useState({
    email: "",
    ehrId: 0,
    isValid: false,
    touched: {},
    errors: {},
    isForgotUsernameView: true,
    isSentEmailView: false,
    isLoading: false
  });

  const hasError = field =>
    values.touched[field] && values.errors[field] ? true : false;
  // const [errors, setErrors] = useState(values.errors);
  // const showEmailError =
  //   values.touched.email && errors.email ? errors.email[0] : false;

  const requestUsername = async () => {
    if (values.isValid) {
      setValues({ ...values, isLoading: true });
      let { isForgotUsernameView, isSentEmailView } = values
      const status = await props.forgotUsername(values.ehrId, values.email)
      if (status) {
        isForgotUsernameView = false;
        isSentEmailView = true;
      }
      setValues({ ...values, isForgotUsernameView: isForgotUsernameView, isSentEmailView: isSentEmailView, isLoading: false });
    }
  }

  const showLogin = () => {
    props.history.push("/sign-in");
  }

  // const goBack = async () => {
  //   setValues({ ...values, isForgotUsernameView: true, isSentEmailView: false })
  // }

  const handleChange = (field, value) => {
    const newState = { ...values };

    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    setValues(newState);
  };

  return ( 
      <span>
        {values.isForgotUsernameView ? (
          <div
            className={classes.contentBodyMsg}
            style={{ position: 'relative' }}>
            <div className={classes.contentHeader}>
              <LockOpenOutlined />
              <Typography variant="h1">Forgot your Username?</Typography>
            </div>
            <div className={classes.contentInner}>
              <Typography variant="h6">
                Please enter the email associated with the account
                </Typography>
              <TextField
                InputProps={{
                  classes: {
                    root: classes.inputOutlined,
                    focused: classes.inputFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                style={{ maxWidth: 400, width: '100%' }}
                error={hasError('email')}
                fullWidth
                helperText={
                  hasError('email') ? values.errors.email[0] : null
                }
                label="Email"
                name="email"
                onChange={event =>
                  handleChange("email", event.target.value)
                }
                type="email"
                value={values.email || ''}
                variant="outlined"
              />
              {/* {showEmailError && (
                <Typography className={classes.fieldError} variant="body2">
                  {errors.email[0]}
                </Typography>
              )} */}
              <br /><br />
              {values.isLoading ? (
                <CircularProgress className={classes.progress} />
              ) : (
                  <Fab
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="add"
                    //component={RouterLink}
                    //to="/sign-in"
                    disabled={!values.isValid}
                    onClick={requestUsername}
                    className={classes.roundButton}
                    style={{ width: '100px !important', marginBottom: 5 }}>
                    Request Username
                  </Fab>)}
              < Typography className={classes.forgotTxt}>
                Did you remember your username?{' '}
                <Link component={RouterLink}
                  onClick={showLogin}>
                  Try Logging in
                  </Link>
              </Typography>
            </div>
          </div>
        ) : null}
        {values.isSentEmailView ? (
          <div
            className={classes.contentBodyMsg}
            style={{ position: 'relative' }}>
            <div className={classes.contentHeader}>
              <LockOpenOutlined />
              <Typography variant="h1">Forgot your Username?</Typography>
            </div>
            <div className={classes.contentInner}>
              <Typography>
                To ensure your security, a confirmation email has been sent to
                  the registered email address. <br />
                Please check your mailbox and follow the instruction in the
                email.
                </Typography>
              <br />
              <Typography>
                Having problems? <br />
                If you do not receive your username recovery email within a
                  few minutes, please check your spam folder.{' '}
              </Typography>
              <br />
              <br />
              <Fab
                variant="extended"
                size="medium"
                color="primary"
                aria-label="add"
                component={RouterLink}
                onClick={showLogin}
                className={classes.roundButton}
                style={{ width: '100px !important', marginBottom: 5 }}>
                Go Back
                </Fab>
            </div>
          </div>
        ) : null}
      </span> 
  );
}

ForgotUn.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  forgotUsername
};

const mapStateToProps = state => ({
  //user:state.user
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotUn))

);
