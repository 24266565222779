export default {
  lastname: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  firstname: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  username: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  emailaddress: {
    presence: { allowEmpty: false, message: '^Email is required' },
    email: { required: true, message: '^Email is not valid' }
  },
  confirmEmailaddress: {
    presence: { allowEmpty: false, message: '^Confirm Email is required' },
    email: { required: true, message: '^Confirm email is not valid' },
    equality: {
      attribute: 'emailaddress',
      message: '^Email and confirm email does not match',
      comparator: (v1, v2) => {
        return v1 === v2;
      }
    }
  }
};
