import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../../SignIn/style';

import { Typography, withStyles } from '@material-ui/core';

const Agree = props => {
  const { classes } = props;

  return (
    <span>
      <Typography className={classes.termsTitle} variant="h1">
        PROVIDER PORTAL USER REGISTRATION AGREEMENT
      </Typography>
      <div className={classes.contentBody}>
        <div className={classes.termsContent}>
          <PerfectScrollbar>
            <div>
              <p>
                Please read the following Provider Portal User Registration Agreement (<strong>"Agreement"</strong>)carefully before registering for access to certain non-public portions of this website (the <strong>“Provider Portal"</strong>).  Access to the Provider Portal is provided to You (Hereinafter “You” or “User”) by a health care provider (<strong>"Provider"</strong>) for whom You either work, or receive patient referrals from, have a relationship in conjunction with EHRZip.  Use of the Provider Portal will permit You to access certain of the Provider’s identifiable health information (<strong>"Content " or “Protected Health Information” or “PHI”</strong>) and other services that may be available from time to time (<strong>"Services"</strong>).
              </p>
              <p style={{ fontWeight: 'bold' }}>
                BY CLICKING “I AGREE” BELOW, YOU ARE COMPLETING YOUR REGISTRATION FOR ACCESS TO THE PROVIDER PORTAL ON BEHALF OF YOURSELF AND YOU ARE AGREEING, TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT WITH EHRZIP.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                IF YOU DO NOT AGREE, YOU SHOULD CLICK “I DO NOT AGREE” AND YOU WILL NOT BE PERMITTED TO ACCESS THE PROVIDER PORTAL.
              </p>
              <p style={{ fontWeight: 'bold' }}>1. Provider Portal Use</p>
              <p>
                Access to the Provider Portal is a provided to You by the Provider and EHRZip at their discretion and should be used by You only for permitted purposes authorized by the Provider.
              </p>
              <p style={{ fontWeight: 'bold' }}>2. Electronic Communications</p>
              <p>
                You understand by signing up to access the Provider Portal, You are agreeing to engage in electronic transactions and that electronic agreements and electronic signatures are binding and meet any legal requirements that any document or signature be in writing.
              </p>
              <p style={{ fontWeight: 'bold' }}>3. Consent and Agreement</p>
              <p>
                Your use of this Provider Portal is subject to the posting of any official terms or policies, including without limitation, our Privacy Policy.  However, because the Provider Portal is a hosted online, EHRZip may occasionally need to notify all Provider Portal users of announcements or changes related to the Provider Portal, such as to the Terms and Conditions of Use or other policies.
              </p>
              <p style={{ fontWeight: 'bold' }}>4. User Names and Access</p>
              <p>
                If your registration is confirmed by EHRZip, You will be granted a limited license to use the Provider Portal and will be provided with access to the Provider Portal via a user name and password that You select during the registration process or one provided by the Provider. You are responsible for all activity under any user name assigned to your account. You have the sole responsibility for ensuring the confidentiality of the user name and password for your account. You agree to immediately notify EHRZip and the Provider of any unauthorized use of your account or any user name assigned to your account and any other breach of security of which You become aware.
              </p>
              <p style={{ fontWeight: 'bold' }}>5. Services</p>
              <p>
                Subject to approval of your request for Provider Portal access and your compliance with this Agreement, EHRZip grants to You a non-exclusive, non-transferable, limited license to access and use the Provider Portal (including the Content and Services) solely for purposed authorized by the Provider.
              </p>
              <p>
                We reserve the right to change, modify, suspend or discontinue any aspect of the Content or Services available, or the Provider Portal in its entirety, at any time without notice.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                THE CONTENT MADE AVAILABLE ON THIS PROVIDER PORTAL INCLUDES IDENTIFIABLE HEALTH INFORMATION MAINTAINED BY YOUR PROVIDER AND, CERTAIN MATERIALS RELATED TO YOUR TREATMENT.  YOU ACKNOWLEDGE THAT EHRZIP HAS NO RESPONSIBILITY OR LIABILITY FOR THE HEALTH INFORMATION MAINTAINED BY YOUR PROVIDER.  SHOULD YOU HAVE ANY QUESTIONS OR CONCERNS RELATING TO THE CONTENT, YOU MUST ADDRESS THEM TO YOUR PROVIDER.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                PLEASE CONTACT THE PROVIDER WITH ANY QUESTIONS OR CONCERNS REGARDING THE HEALTH INFORMATION.  EHRZIP, NOR ITS RESPECTIVE AFFILIATES GUARANTEES THE SEQUENCE, ACCURACY, OR COMPLETENESS OF THE CONTENT AND SHALL NOT BE LIABLE IN ANY WAY TO YOU, OR ANYONE ELSE TO WHOM THE CONTENT MAY BE FURNISHED, FOR ANY DELAYS, INACCURACIES, UNAVAILABILITY, ERRORS OR OMISSIONS IN THE CONTENT. SUCH CONTENT IS RELIED UPON AT YOUR OWN RISK AND SOLE DISCRETION.
              </p>
              <p>
                Neither EHRZip nor its affiliates shall be liable for any dispute that arises between You and the Provider or any third party.
              </p>
              <p style={{ fontWeight: 'bold' }}>6. Use Terms</p>
              <p>
                User is receiving access to the Services from Provider because user is part of Provider’s workforce, or a health care provider who will be providing health care to Provider’s patients.  As a result of these relationships, User is being given certain access to portions of Provider’s Protected Health Information and the Services, which may include the creation, receipt, maintenance, and/or transmission of Protected Health Information.  Except as otherwise specified herein, the User may make any and all uses of Protected Health Information necessary to perform its obligations to Provider or the patient.  All other uses not authorized by this Agreement are prohibited.  Moreover, User may disclose Protected Health Information for the purposes authorized by this Agreement only: (i) to its employees, subcontractors and agents, in accordance with Sections 3.1(f) and 3.1(l); (ii) as directed by the Provider; or (iii) as otherwise permitted by the terms of this Agreement.
              </p>
              <p>
                Unless otherwise limited herein, the User may:
              </p>
              <ol type='a' style={{marginLeft:'30px'}}>
                <li>
                  use the Protected Health Information for its proper management and administration and to fulfill any present or future legal responsibilities of the User provided that such uses are permitted under state and federal confidentiality laws.
                </li>
                <li>
                  disclose the Protected Health Information in its possession to third parties for the purpose of its proper management and administration or to fulfill any present or future legal responsibilities of the User, provided that the User represents to the Provider, in writing, that (i) the disclosures are Required by Law; or (ii) the User has entered into a User Agreement containing substantially similar (or more stringent) terms as this Agreement with any third party that creates, receives, maintains, or transmits Content on behalf of the User.
                </li>
                <li>
                  use and/or disclose Protected Health Information as permitted under 45 C.F.R. § 164.512 except that uses or disclosures for research are not permitted without prior approval by the Provider.
                </li>
              </ol>
             <p>
                With regard to its use and/or disclosure of Protected Health Information, the User will:
              </p>
              <ol type='a' style={{marginLeft:'30px'}}>
                <li>
                  comply with the portions of the HIPAA Privacy Rule and Security Rule applicable to similar Users;
                </li>
                <li>
                  use and/or disclose the Protected Health Information only as permitted or required by this Agreement or as Required by Law and to use appropriate safeguards to prevent impermissible use or disclosure of PHI.
                </li>
                <li>
                  report to the designated Privacy Officer of the Provider, in writing, any use and/or disclosure of the Protected Health Information that is not permitted or required by this Agreement of which User becomes aware within three (3) business days of the User’s discovery of such unauthorized use and/or disclosure.
                </li>
                <li>
                  mitigate, to the greatest extent possible, any deleterious effects from any improper use and/or disclosure of Protected Health Information or other Sensitive Personal Information of which the User becomes aware and/or reports to the Provider.
                </li>
                <li>
                  implement Administrative, Physical and Technical Safeguards consistent with industry standards that reasonably and appropriately maintain the security of, prevent unauthorized use and/or disclosure of, and protect the confidentiality, integrity, and availability of any Electronic Protected Health Information or other Sensitive Personal Information it creates, receives, maintains, or transmits on behalf of Provider.
                </li>
                <li>
                  At a minimum, User shall employ Safeguards that are compliant with 45 C.F.R. Part 164, Subpart C and the National Institute of Standards and Technology (“NIST”) guidelines.
                </li>
                <li>
                  require all of its subcontractors and agents that create, receive, maintain, or transmit Protected Health Information under this Agreement to agree, in the form of a User Agreement that meets the requirements at 45 C.F.R. § 164.314(a), to adhere to substantially similar or more stringent restrictions and conditions on the use and/or disclosure of Protected Health Information that apply to the User pursuant to Section 3 of this Agreement.
                </li>
                <li>
                  ensure that any agent, including a subcontractor, agrees to implement reasonable and appropriate safeguards to protect the confidentiality, integrity, and availability of the Electronic Protected Health Information that it creates, receives, maintains, or transmits on behalf of the Provider.
                </li>
                <li>
                  within fourteen (14) days of receiving a written request from the Provider, make available during normal business hours at User’s offices all records, books, agreements, policies and procedures relating to the use and/or disclosure of Protected Health Information for purposes of enabling the Provider to determine the User’s compliance with the terms of this Agreement.
                </li>
                <li>
                  within fifteen (15) days of receiving a written request from the Provider, provide to the Provider such information as is requested by the Provider to permit the Provider to respond to a request by an Individual for an accounting of the disclosures of the Individual's Protected Health Information in accordance with 45 C.F.R. § 164.528.
                </li>
                <li>
                  to the extent that User is obligated to carry out one or more of Provider’s obligations under the Privacy Rule, comply with the Privacy Rule requirements that apply to the Provider in the performance of such obligations;
                </li>
                <li>
                  return to the Provider, within sixty (60) days of the termination of this Agreement, the Protected Health Information in its possession and retain no copies;
                </li>
                <li>
                  disclose to its subcontractors, agents or other third parties only the minimum Protected Health Information necessary to perform or fulfill a specific function required or permitted under the Services Agreement or this Agreement.
                </li>
                <li>
                  report to the designated Privacy Officer of the Provider, in writing, any Security Incident or unintentional use or disclosure of Unsecured PHI, identified internal/external breach of data, or disaster occurrence of which User becomes aware within three (3) business days of the User’s discovery of such incident. The User must include the following information, to the extent known, when reporting a breach:
                  <div>
                  <p style={{marginLeft:'45px'}}>
                    (i)	Identification of the individual whose Unsecured PHI has been, or is reasonably believed by the User to have been, accessed, acquired, used, or disclosed during a breach;
                  </p>
                  <p style={{marginLeft:'45px'}}>
                    (ii)	Circumstances of the breach;
                  </p>
                  <p style={{marginLeft:'45px'}}>
                    (iii)	Date of the breach;
                  </p>
                  <p style={{marginLeft:'45px'}}>
                    (iv)	Date of the discovery;
                  </p>
                  <p style={{marginLeft:'45px'}}>
                    (v)	Type of PHI involved (such as full name, Social Security number, date of birth, home address, account number, or medical record number); and
                  </p>
                  <p style={{marginLeft:'45px'}}>
                    (vi)	Any other additional information the Provider requests
                  </p>
                  </div>
                </li>
               
              </ol>
              <p style={{ fontWeight: 'bold' }}>7. Proprietary Ownership</p>
              <p>
                You acknowledge that the Provider Portal and all Content, and underlying technology, names, logos, text, audio, forms, documents, materials, data, video, graphics, charts, photographs, icons, and the design, selection, and arrangement of content in any medium on this Provider Portal are the sole property of the Provider, and/or EHRZip and may be protected by copyright, trademark, service mark, trade secret, trade dress, patent and other intellectual property laws, as applicable. You acquire no right, title or interest in the Provider Portal, Content or Services, or any copyrighted works, trademarks or any other intellectual property except the limited license granted in Section 5 above.
              </p>
              <p>
                Any feedback, suggestions, solutions, improvements, corrections or other contributions You provide regarding the Content, Services or Provider Portal shall become the sole property of EHRZip and You agree to assign and hereby assign any and all such rights to EHRZip without charge. You further agree to take all acts reasonably requested by EHRZip to confirm EHRZip's ownership of such items. Notwithstanding the above, certain portions of the Provider Portal may eventually permit and facilitate secure communications via the Provider Portal between patients' physicians and other clinicians and administrators participating in your care, which will be kept confidential as required by law.
              </p>
              <p style={{ fontWeight: 'bold' }}>8. Prohibited Uses</p>
              <p>
                Nothing in this Agreement may be construed as granting You any other right in the Provider Portal outside of the limited license to access the Provider Portal, Content, and Services. You may not otherwise use, reproduce, modify, mirror, transmit, display, make, offer to sell, sell, license, decompile, disassemble, reverse engineer, distribute, or create derivative works from the Provider Portal or any part thereof, or any content or technology contained therein.
              </p>
              <p>
                EHRZip may, but shall have no obligation to, access and review all information and data provided by You for compliance with the terms of this Agreement.
              </p>
              <p style={{ fontWeight: 'bold' }}> 9. Cancellation and Termination</p>
              <p>
                EHRZip and Provider reserve the right to terminate or suspend your access to and use of the Provider Portal, in part or in whole, in their discretion at any time, with or without cause.  Among other things, EHRZip and/or Provider may terminate or suspend access in the event of: (i) your breach or suspected breach of any of the provisions of this Agreement; (ii) your use or misuse of the Provider Portal, Content or Services, for any activity or course of conduct that EHRZip deems to be unlawful; (iii) in the event EHRZip or Provider reasonably determines that such termination is necessary or proper, or (iv) EHRZip ceasing to provide the Provider Portal, Content or Services. In addition, if the relationship between your Provider and EHRZip is terminated or discontinued for any reason, your account may be deactivated after the termination or discontinuance with or without notice.
              </p>
              <p style={{ fontWeight: 'bold' }}> 10. No Liability</p>
              <p style={{ fontWeight: 'bold' }}>
                YOU UNDERSTAND AND EXPRESSLY AGREE THAT USE OF THE PROVIDER PORTAL IS AT YOUR OWN RISK.  NEITHER EHRZIP, THE PROVIDER NOR THEIR RESPECTIVE AFFILIATES ARE RESPONSIBLE FOR ANY VIRUS OR DAMAGE TO YOUR COMPUTER, COMPUTER SOFTWARE, OR ANY LOSS OF DATA THAT MAY RESULT FROM USE OF THE PROVIDER PORTAL OR ACCESS TO ANY CONTENT OR SERVICES.
              </p>
              <p>
                IN NO EVENT SHALL EHRADIN, THE PROVIDER, OR ANY OF THEIR RESPECTIVE VENDORS, AFFILIATES, OFFICERS, MEMBERS, REPRESENTATIVES, SUPPLIERS, DIRECTORS, EMPLOYEES, CONSULTANTS, OWNERS, OR AGENTS BE LIABLE FOR ANY DAMAGES FOR LOSS OF USE, DATA, INFORMATION, PROFITS, BUSINESS, REVENUE, EXPECTED SAVINGS OR BUSINESS INTERRUPTION, OR ANY DIRECT, INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER ARISING OUT OF OR IN ANY WAY RELATED TO (I) THE PROVIDER PORTAL, CONTENT OR SERVICES, (II) THE USE OR PERFORMANCE OF THIS PROVIDER PORTAL OR ANY LINKED WEBSITE OR ANY CONTENT, SERVICES OR OTHER MATERIAL, SERVICES OR INFORMATION OBTAINED THROUGH THIS PROVIDER PORTAL, OR (III) OTHERWISE ARISING OUT OF THE PROVIDER PORTAL, CONTENT, OR SERVICES OR THE USE OR THE INABILITY TO USE THIS PROVIDER PORTAL, CONTENT OR SERVICES, WHETHER SUCH DAMAGES ARE BASED IN TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF EHRZIP, THE PROVIDER, OR ANY OF THEIR RESPECTIVE VENDORS OR AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES ARE OTHERWISE FORESEEABLE. YOUR SOLE AND EXCLUSIVE REMEDY IN THE EVENT OF SUCH DAMAGE IS TO DISCONTINUE YOUR ACCESS TO AND USE OF THE PROVIDER PORTAL, CONTENT AND SERVICES. NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE AGGREGATE LIABILITY OF EHRZIP AND ITS AFFILIATES FOR ANY REASON EXCEED $1,000.00 US DOLLARS, REGARDLESS OF WHETHER THE FOREGOING REMEDIES SHOULD FAIL OF THEIR ESSENTIAL PURPOSE.
              </p>
              <p>
                THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU TO THE EXTENT SUCH LIMITATION IS PROHIBITED BY APPLICABLE LAW.
              </p>
              <p style={{ fontWeight: 'bold' }}> 11. No Warranty</p>
              <p>
                <strong>
                  THE PROVIDER PORTAL, CONTENT AND SERVICES ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS. EHRZIP, THE PROVIDER, AND THEIR RESPECTIVE AFFILIATES DISCLAIM ALL OTHER WARRANTIES WITH RESPECT TO THE PROVIDER PORTAL, CONTENT AND SERVICES, INCLUDING WITHOUT LIMITATION ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING OR USAGE IN TRADE.  EHRZIP AND THEIR RESPECTIVE AFFILIATES MAKE NO WARRANTY OR REPRESENTATION REGARDING (A) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE PROVIDER PORTAL, CONTENT OR SERVICES, (B) ANY MATERIAL, INFORMATION OR DATA DOWNLOADED OR OTHERWISE OBTAINED FROM THE PROVIDER PORTAL OR (C) THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED FROM THE PROVIDER PORTAL, CONTENT, OR SERVICES.  Neither EHRZip, the Provider, nor their respective affiliates shall have any responsibility for the timeliness, deletion, mis-delivery or failure to store any user communication.
                </strong>
              </p>
              <p>
                EHRZip provides the Provider Portal, Content and Services to You through the Internet, which is subject to outages, communication and data flow failures, interruptions and delays inherent in Internet communications. You recognize that problems with the Internet, including equipment, software and network failures, impairments or congestion or the configuration of your computer systems, may prevent, interrupt or delay your access to the Provider Portal. None of EHRZip, the Provider, or their respective vendors or affiliates shall be liable for any delays, interruptions, suspensions or unavailability of the Provider Portal, or any portion of the Provider Portal, attributable to problems with the Internet or configuration of your computer systems. There is no warranty or guarantee that access or use of the Provider Portal, Content or Services will be uninterrupted or error-free.
              </p>
              <p style={{ fontWeight: 'bold' }}> 12. Your Representations and Warranties</p>
              <p>
                By clicking "I Agree" below, You represent and warrant that (i) You are at least eighteen (18) years of age, (ii) You have the legal ability and authority to enter into this Agreement with EHRZip, (iii) the information You have provided to EHRZip in your registration application is accurate and complete (iv) You will comply with any and all laws applicable to your use of the Provider Portal, (v) You will not interfere with a third party's use and enjoyment of the Provider Portal, and (vi) You will not attempt to interfere with or disrupt EHRZip's security measures. If any information You provide to EHRZip becomes inaccurate, incomplete or otherwise false or misleading, You will immediately notify EHRZip.
              </p>
              <p>
                You also represent, warrant and covenant that your use of the Provider Portal will be consistent with any and all applicable local, state, national, foreign and international laws and regulations, including, but not limited to any applicable export control laws. You will promptly report any violation of this Agreement to EHRZip.
              </p>
              <p style={{ fontWeight: 'bold' }}> 13. Indemnification</p>
              <p>
                YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS EHRZIP, THE PROVIDER AND THEIR RESPECTIVE VENDORS, AFFILIATES, AND THEIR OFFICERS, MEMBERS, REPRESENTATIVES, DIRECTORS, EMPLOYEES, CONSULTANTS, OWNERS, SUPPLIERS AND AGENTS FROM ANY AND ALL LOSSES, EXPENSES, THIRD-PARTY CLAIMS, LIABILITIES, DAMAGES AND COSTS (INCLUDING WITHOUT LIMITATION ATTORNEYS’ FEES) ARISING FROM YOUR USE OR MISUSE OF THE PROVIDER PORTAL, CONTENT OR SERVICES, YOUR USE OR MISUSE OF ANY MATERIAL, INFORMATION OR DATA DOWNLOADED OR OTHERWISE OBTAINED FROM THE PROVIDER PORTAL, ANY PROVIDER PORTAL ACTIVITY INVOLVING YOUR USER ID, YOUR VIOLATION OF THIS AGREEMENT, OR YOUR VIOLATION OF ANY APPLICABLE LAW, INCLUDING WITHOUT LIMITATION ANY PRIVACY OR DATA SECURITY LAW OR REGULATION. NOTWITHSTANDING THE FOREGOING, EHRZIP, THE PROVIDER AND THEIR RESPECTIVE VENDORS RESERVE THE RIGHT TO PARTICIPATE IN THE DEFENSE OF ANY SUCH CLAIM SUBJECT TO YOUR INDEMNIFICATION OBLIGATION.
              </p>
              <p style={{ fontWeight: 'bold' }}> 14. Interpretation and Disputes</p>
              <p>
                This Agreement shall be governed by and construed in accordance with the laws of the State of California and the federal laws of the United States, without regard to conflicts of laws principles. This Agreement and access to the Provider Portal shall be deemed to have been performed and occurred in the State of California and the courts of that State shall have exclusive jurisdiction to entertain any action arising under this Agreement. You hereby irrevocably submit to the exclusive jurisdiction and venue of the state and federal courts located in or with jurisdiction over the State of California, and the County of Sacramento, as applicable, and waive any objections as to personal jurisdiction, venue and forum non conveniens.
              </p>
              <p>
                In the event of any dispute, the prevailing party shall be entitled to recovery of its reasonable attorneys’ fees and costs. If a court or arbitrator deems any provision of this Agreement unenforceable or invalid, then the court or arbitrator shall modify such provision to the minimum extent necessary to make such provision enforceable and valid. Should such modification prove impossible or impracticable then the provision shall be severed and the remaining terms of this Agreement shall be interpreted and read to give them maximum enforceability. Except as prohibited by applicable law, any cause of action or claim with respect to the Content or use of this website or the Services, must be commenced within one (1) year after the action or claim arises.
              </p>
              <p>
                Certain provisions, by their nature or as explicitly stated, will survive any termination or expiration of this Agreement.
              </p>
              <p style={{ fontWeight: 'bold' }}> 15. Contacting Us</p>
              <p>
                If you have any other questions or concerns regarding this Agreement, please contact us at:
              </p>
              <p>
                EHRZip
                <br />Attention:
                <br />Customer Support
                <br />6510 Lonetree Blvd., Ste 300
                <br />Rocklin, CA 95765
                <br />Main 916-672-6622
                <br />Fax: 916-872-1998
              </p>
              <p style={{ fontWeight: 'bold' }}> 16. Entire Agreement</p>
              <p>
                By clicking "I Agree", You hereby agree to the above Agreement. This Agreement supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding this subject matter. This Agreement will inure to the benefit of EHRZip's successors, assigns and licensees. It is recommended that You print a copy of this Agreement for your records.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                BY CLICKING “I AGREE,” YOU CONFIRM YOU ARE IN AGREEMENT WITH THESE TERMS AND WILL BE BOUND BY THEM.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                IF YOU DO NOT AGREE, YOU SHOULD CLICK “I DO NOT AGREE” AND YOU WILL NOT BE PERMITTED TO ACCESS THE PROVIDER PORTAL.
              </p>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </span>
  );
};

Agree.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(Agree));