/* eslint-disable no-case-declarations */
import _ from 'lodash';
export default (state = {}, action) => { 
  switch (action.type) {
    case 'PATIENT_INFO':
      return {
        ...state,
        patientInfo: action.data
      };
    case 'PATIENT_REMARKS':
      return {
        ...state,
        ptRemarks: action.data
      };
    case 'PATIENT_INACTIVE_DIAGNOSIS':
      return {
        ...state,
        ptInactiveDiagnosis: action.data
      };
    case 'PATIENT_INACTIVE_MEDICATION':
      return {
        ...state,
        ptInactiveMedication: action.data
      };
    case 'PATIENT_PAST_DIAGNOSIS':
      return {
        ...state,
        ptPastDiagnosis: action.data
      };
    case 'PATIENT_ACTIVE_MEDICATION':
      return {
        ...state,
        ptActiveMedication: action.data
      };
    case 'PATIENT_MEDICAL_HISTORY':
      return {
        ...state,
        ptMedicalHistory: action.data
      };
    case 'SELECTED_PATIENT_GET':
      return {
        ...state,
        patientInfo: action.data
      };
    case 'USER_VITALSIGNS':
      return {
        ...state,
        userVitalSigns: action.data
      };
    case 'PAST_SOAP_NOTE': 
      return {  
        ...state,   
        pastSoapNote:action.data.chart
      };
    case 'PAST_SOAP_NOTE_DETAILS':
      return {
        ...state,
        pastSoapNoteDetails: action.data
      };

    case 'SAVE_TEMP_DATA':
      return {
        ...state,
        unsavedData: action.data
      };

    case 'RESET_VALUE':
      return {
        ...state,
        unsavedData: [],
        pastSoapNote:null,
        patientInfo:[],
        soap:[]
      };
    case 'CLEAR_SOAP':
      return { 
        patientInfo:null,
        ptRemarks:null, 
        ptInactiveDiagnosis:null,
        ptInactiveMedication:null,
        ptPastDiagnosis:null,
        ptActiveMedication:null,
        ptMedicalHistory:null,
        pastSoapNote:null
      }
    case 'ACTIVE_INACTIVE_MEDICATION':
      return {
        ...state,
        medicationList: {
          medications: action.data.medications,
          inactiveMedications: action.data.inactiveMedications
        }
      };
    default:
      return state;
  }
};
