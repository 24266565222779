import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../style';
import { Grid } from '@material-ui/core';
import { saveOrg } from 'services/ehr/action';
import { Info, Add, View } from './components';
import { getOrganizationbyId } from 'services/ehr/action';
import { connect } from 'react-redux';
import MessageBox from 'util/messageBox';
const OrgProfile = props => {
  const { classes, ehrID, organization, practice, className } = props;
  const [data, setData] = useState({});
  const [datapractice, setDataPractice] = useState(null);
  const [isSuccess, setIsSuccess] = useState({
    success: false,
    message: ''
  });

  const [values, setValues] = useState({
    ehrPublicLogoURL: '',
    companyTaxId: '',
    billing: {
      id: 0,
      name: '',
      creditcardnumber: '',
      cvv: '',
      expirationmonth: '',
      expirationyear: '',
      address: {
        id: 0,
        address: '',
        city: '',
        state: '',
        zip: '',
        address_Plain: '',
        city_Plain: '',
        state_Plain: '',
        zip_Plain: ''
      }
    },
    organizationNPI: '',
    soleProprietorSSNumber: '',
    ehrLogoFileURI: '',
    id: 0,
    owner: {
      id: 0,
      lastname: '',
      firstname: '',
      title: '',
      ehrid: '',
      medical: { medicallicense: '', individualnpi: '', dea: '' },
      emailaddress: null,
      individualNPI: '',
      deaNumber: '',
      licenseNumber: '',
      pecialityTaxonomy: null,
      links: []
    },
    practice: [
      {
        id: 0,
        companyname: '',
        facilityNPI: null,
        primaryPractice: null,
        businessphones: [''],
        faxnumbers: [''],
        address: {
          id: 0,
          address: '',
          city: '',
          state: '',
          zip: '',
          address_Plain: '',
          city_Plain: '',
          zip_Plain: '',
          state_Plain: ''
        }
      }
    ],
    websitename: '',
    organizationname: '',
    links: [],
    isLoading: false,
    isValid: false,
    errors: {},
    succMsg: ''
  });

  useEffect(() => {
    props.getOrganizationbyId(ehrID);
  }, []);

  useEffect(() => {
    if (organization !== undefined) {
      setValues(organization);
    }
  }, [organization]);

  const handleCloseAll = () => {
    setIsSuccess({
      success: false,
      message: ''
    });
  };

  const getValuesofConfig = (data, isDelete = false) => {
    props.saveOrg(data).then(status => {
      if (status) {
        setIsSuccess({
          success: true,
          message: isDelete
            ? 'Practice information deleted successfully'
            : 'Organization profile saved successfully'
        });
        props.getOrganizationbyId(ehrID);
      }
    });
  };

  const clearValues = () => {
    setDataPractice({
      id: 0,
      companyname: '',
      facilityNPI: '',
      primaryPractice: null,
      businessphones: [''],
      faxnumbers: [''],
      address: {
        id: 0,
        address: '',
        city: '',
        state: '',
        zip: '',
        address_Plain: '',
        city_Plain: '',
        zip_Plain: '',
        state_Plain: ''
      }
    });
  };

  const geteditValuesofPractice = dataPractice => {
    const newState = { ...values };
    if (dataPractice.id === 0) {
      newState.practice.push(dataPractice);
    }
    if (dataPractice.id > 0) {
      let array = newState.practice.slice();
      let index = newState.practice.findIndex(c => c.id === dataPractice.id);
      newState.practice[index] = dataPractice;
    }
    setValues(newState);
    props.saveOrg(values).then(status => {
      if (status) {
        setIsSuccess({
          success: true,
          message: 'Practice information saved successfully'
        });
        clearValues();
        props.getOrganizationbyId(ehrID);
      }
    });
  };

  const editPractice = dataPractice => {
    setDataPractice(dataPractice);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Info
          ehrID={ehrID}
          organization={organization}
          getValuesofConfig={getValuesofConfig}
        />
      </Grid>
      <Grid item xs={12} md={5} className={classes.colWrap}>
        <Add
          datapractice={datapractice}
          getValuesofConfig={getValuesofConfig}
          geteditValuesofPractice={geteditValuesofPractice}
        />
      </Grid>
      <Grid item xs={12} md={7} className={classes.colWrap}>
        <View
          ehrID={ehrID}
          organization={organization}
          getValuesofConfig={getValuesofConfig}
          editPractice={editPractice}
          clearValues={clearValues}
        />
      </Grid>
      <MessageBox
        open={isSuccess.success}
        variant="success"
        onClose={handleCloseAll}
        message={isSuccess.message}
      />
    </Grid>
  );
};

OrgProfile.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getOrganizationbyId,
  saveOrg
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  organization: state.ehr && state.ehr.organization,
  practice:
    state.ehr && state.ehr.organization && state.ehr.organization.practice
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrgProfile))
);
