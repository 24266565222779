/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  saveSocialHistory,
  updateSocialHistory,
  deleteSocialHistory
} from 'services/soap/action';

const SocialHistory = props => {
  const { classes, patientId } = props;

  const initialUserState = {
    id: 0,
    description: ''
  };
  const [values, setValues] = useState(initialUserState);
  const [editvalues, setEditValues] = useState(initialUserState);
  const [SH, setSH] = useState(props.socialHistory);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.socialHistory && props.socialHistory.length > 0) {
      setSH(props.socialHistory);
    }
  }, [props.socialHistory]);

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleEditFieldChange = (field, value) => {
    setEditValues({ ...editvalues, [field]: value });
  };

  const handleSaveSocialHistory = async () => {
    if (values.description) {
      const res = await props.saveSocialHistory(patientId, values);
      if (res.status) {
        setSH(res.socialHistory);
        setValues(initialUserState);
        props.getSocialHistory(res.socialHistory);
      }
    }
  };

  const handleUpdateSocialHistory = async idx => {
    if (editvalues.description) {
      const res = await props.updateSocialHistory(patientId, editvalues);
      if (res.status) {
        const newState = [...SH];
        newState[idx] = res.socialHistory;
        setSH(newState);
        setIsEdit(false);
        props.getSocialHistory(newState);
      }
    }
  };

  const handleDeleteSocialHistory = async id => {
    const res = await props.deleteSocialHistory(patientId, id);
    if (res.status) {
      setSH(res.socialHistory);
      setValues(initialUserState);
      props.getSocialHistory(res.socialHistory);
    }
  };

  const handleEditSocialHistory = async (data, index) => {
    setIsEdit(true);
    if (data) {
      setEditValues({
        id: data.id,
        description: data.description,
        editIndex: index
      });
    }
  };

  const clearSocialHistory = () => {
    setIsEdit(false);
    setValues(initialUserState);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody} style={{ borderTop: 'default' }}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('description', event.target.value)
                      }
                      placeholder="Input description"
                      value={values.description}
                      variant="outlined"
                    />
                  </TableCell>

                  <TableCell
                    width="10%"
                    align="center"
                    style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      // disabled={values.description || values.reaction}
                      onClick={() => handleSaveSocialHistory()}
                      size="small"
                      style={{ color: '#03A484' }}>
                      <CheckOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => clearSocialHistory()}
                      size="small">
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {SH &&
                  SH.length > 0 &&
                  SH.map((item, idx) => {
                    return (
                      <TableRow>
                        {isEdit && idx === editvalues.editIndex ? (
                          <>
                            <TableCell>
                              <TextField
                                className={classes.textBox}
                                id="outlined-basic"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                onChange={event =>
                                  handleEditFieldChange(
                                    'description',
                                    event.target.value
                                  )
                                }
                                placeholder="Input description"
                                value={editvalues.description}
                                variant="outlined"
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{item.description}</TableCell>
                          </>
                        )}
                        <TableCell align="center" width={72}>
                          {isEdit && idx === editvalues.editIndex ? (
                            <>
                              <IconButton
                                // disabled={values.description || values.reaction}
                                onClick={() => handleUpdateSocialHistory(idx)}
                                size="small"
                                style={{ color: '#03A484' }}>
                                <CheckOutlined />
                              </IconButton>
                              <IconButton
                                onClick={() => clearSocialHistory()}
                                size="small">
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                onClick={() =>
                                  handleEditSocialHistory(item, idx)
                                }
                                size="small">
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleDeleteSocialHistory(item.id)
                                }
                                size="small">
                                <DeleteOutline style={{ color: '#DE5555' }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
SocialHistory.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  saveSocialHistory,
  updateSocialHistory,
  deleteSocialHistory
};

const mapStateToProps = () => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SocialHistory))
);
