import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../style';
import { Grid } from '@material-ui/core';

import { MyTemplates, TemplateText } from './components';

import { connect } from 'react-redux';
import { getSubjectiveTemplates } from 'services/providers/action';

const Subjective = props => {
  const { classes, ehrID, userID } = props;
  const [soapEditorTopics, setSoapEdiTorTopics] = useState(null);
  const [subjectiveTopic, setSubjectiveTopic] = useState(null);

  useEffect(() => {
    props.getSubjectiveTemplates(ehrID, userID).then((res => {
      if (res && res.data.length > 0) {
        setSoapEdiTorTopics(res.data);
      }
    }));
  }, []);

  const setTopicText = topicText => {
    setSubjectiveTopic(topicText);
  }

  return ( 
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.tabLabel}>Subjective Template - User Defined</p>
        </Grid>
        <Grid item xs={12} md={6}>
          <MyTemplates templates={soapEditorTopics} setTopicText={setTopicText} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TemplateText subjectiveTopic={subjectiveTopic} />
        </Grid>
      </Grid> 
  );
};

Subjective.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getSubjectiveTemplates
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  subjectiveTemplates: state.providers && state.providers.subjectiveTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Subjective))
);
