import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  withStyles,
  Slide,
  Grid,
  Box
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style';
import {
  SearchOutlined,
  CancelOutlined,
  PlaylistAddCheckOutlined
} from '@material-ui/icons';
import clsx from 'clsx';
import { getPatientsOnSearch } from 'services/patient/action';
import {clearSoap, resetValue} from 'services/soap/action'
import { clearPtDoc} from 'services/home/action'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

const GlobalPatientSearch = props => {
  const { ehrID, classes, searchterm, data, isGlobal, open, ...rest } = props;

  const [globalsearch, setGlobalSearch] = useState(null);
  const [showPatientsList, setShowPatientsList] = useState(
    isGlobal ? null : open
  );
  const [patients, setPatients] = useState({});

  useEffect(() => {
    if (searchterm && searchterm.length > 0) {
      props.getPatientsOnSearch(ehrID, searchterm);
    }
  }, [searchterm]);

  useEffect(() => {
    if (
      (props.globalsearchList &&
        globalsearch !== '' &&
        globalsearch !== null) ||
      (!isGlobal && props.globalsearchList)
    ) {
      setPatients(props.globalsearchList);
      setShowPatientsList(true);
    } else {
      setPatients(null);
      setShowPatientsList(false);
    }
  }, [props.globalsearchList]);

  const handleChange = (field, value) => {
    setGlobalSearch(value);
    props.getPatientsOnSearch(ehrID, value);
  };

  const clear = () => {
    if (props.clear) {
      props.clear();
    }
  };

  const handleOpenSOAP = async patientId => {
    setShowPatientsList(false);
    setGlobalSearch('');
      props.clearSoap();
      props.resetValue();
      props.clearPtDoc();
    props.history.push({
      pathname: '/default',
      state: { patientId: patientId }
    });
  };

  return (
    <Grid
      className={classes.searchWrap}
      container
      item
      style={{ padding: 0 }}
      xs={12}>
      <Grid className={classes.searchCol1} item md={12} xs={12}>
        <Typography>{props.fullWidth}</Typography>
        {showPatientsList || !isGlobal ? (
         
            <div
              className={clsx(classes.popSearch, classes.popSearchGlobal)}
              style={{ minWidth: !isGlobal && '0', top: !isGlobal && '0px' }}>
              <PerfectScrollbar>
                <div style={{ maxHeight: 200 }}>
                  <Table className={classes.reminderTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Patient ID</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell align="center">DOB</TableCell>
                        <TableCell align="center">Sex</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patients && patients.length > 0 ? (
                        patients.map(patient => (
                          <TableRow
                            onClick={() => {
                              isGlobal
                                ? handleOpenSOAP(patient.id)
                                : props.prefillPatient(patient);
                            }}
                            style={{ cursor: 'pointer' }}>
                            <TableCell width={92}>{patient.id}</TableCell>
                            <TableCell>
                              {patient.lastname}, {patient.firstname}{' '}
                              {patient.middleinitial}
                            </TableCell>
                            <TableCell align="center" width={72}>
                              {patient.dob
                                ? moment(patient.dob).format('MM/DD/YYYY')
                                : ''}
                            </TableCell>
                            <TableCell align="center" width={72}>
                              {patient.gender && patient.gender.charAt(0)}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow className={classes.tableRow}>
                          <TableCell
                            className={classes.tableCell}
                            colSpan={10}
                            style={{ textAlign: 'center' }}>
                            No patients available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
              <Box className={classes.popButtonWrap}>
                <Typography>
                  Organization has {patients && patients.length} patients
                </Typography>
                <IconButton
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    !isGlobal
                      ? props.setShowSearch(false)
                      : setShowPatientsList(false);
                    setGlobalSearch('');
                  }}
                  title="CLOSE">
                  <CancelOutlined />
                </IconButton>
              </Box>
            </div> 
        ) : null}
        {isGlobal && (
          <div className={classes.globalSearchWrap}>
            <TextField
              autoComplete="off"
              className={clsx(classes.textBox, classes.textBoxGlobalSearch)}
              id="outlined-basic"
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline
                }
              }}
              onChange={event =>
                handleChange('globalSearchVal', event.target.value)
              }
              placeholder="Patient Search"
              value={globalsearch}
              variant="outlined"
            />
            <IconButton
              className={classes.naviSearchBtn}
              onClick={() => {
                globalsearch !== '' && globalsearch !== null
                  ? setShowPatientsList(true)
                  : setShowPatientsList(false);
              }}
              size="small">
              <SearchOutlined />
            </IconButton>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

GlobalPatientSearch.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  getPatientsOnSearch, clearSoap, resetValue, clearPtDoc
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  // globalSearchVal: state.globalsearch,
  globalsearchList:
    state.patient && state.patient.searchList && state.patient.searchList.result
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(GlobalPatientSearch))
);
