import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import styles from '../../Default/style';
import moment from 'moment';
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';

const DataHx = props => {
  const { classes, className, dataHx, isHome, ...rest } = props;
  const handleShow = (item, recap) => {
    switch (recap.type) {
      case 'Patient':
        props.handleOpenSoap(item.patient.id);
        break;
      case 'PatientInformation':
        // $scope.showPatientInsuranceSummary(casefile.patient);
        break;
      case 'SOAP':
        props.handleOpenViewSoap(
          item.patient.id,
          recap.resourceID, item
        );
        break;
      case 'Document':
        props.handleOpenDoc(
          item.patient.id,
          recap.resourceID,
          item.routeTo,
          item.patient.patient
        );
        break;
      case 'MedicalRecord':
        // $scope.showPatientMedicalRecord(casefile.patient);
        break;
    }
  };
  return (
    <div style={{ maxHeight: 275, maxWidth: isHome ? 400 : 500 }}>
      <Table className={classes.plainTable}>
        <TableBody>
          {dataHx &&
            dataHx.page.content.map(item => (
              <TableRow style={{ cursor: 'pointer' }} onClick={() => handleShow(dataHx, item)}>
                <TableCell align="center">
                  {moment(item.updatedDate).format('MM/DD/YYYY')}
                </TableCell>
                {!isHome ? (
                  <TableCell align="center">{item.createdBy} </TableCell>
                ) : (
                  ''
                )}
                <TableCell>

                  <span onClick={() => handleShow(dataHx, item)}>
                    {item.description}
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

DataHx.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(DataHx));
