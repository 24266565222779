import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../../style'; 
import {
  TextField,
  Box, 
  Button, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, 
} from '@material-ui/core';
import {
  AttachFileOutlined, 
} from '@material-ui/icons/';
import clsx from 'clsx';
import validate from 'validate.js'; 
import { connect } from 'react-redux';

// import { ValuesOfCorrectType } from 'graphql/validation/rules/ValuesOfCorrectType';

const ICDImport = props => {
  const { classes } = props;
  // const [isEdit, setIsEdit] = useState(false);
  const initialUserState = {
    icdType: '',
    file: null,
    isValid: false,
    touched: {},
    errors: {}
  };
  const [values, setValues] = useState(initialUserState);
  const schema = {
    icdType: {
      presence: { allowEmpty: false }
    },
    file: {
      presence: { allowEmpty: false }
    }
  };

  const icdTypes = [
    {
      value: 'user',
      label: 'User'
    },
    {
      value: 'system',
      label: 'System'
    }
  ];

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (field, event) => {
    const newState = { ...values };
    if (field === 'file') {
      newState.file = event.target.files[0];
    } else {
      newState[field] = event.target.value;
    }
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const handleFileUpload = async () => {
    if (values.isValid) {
      props.handleFileUpload(values);
      props.onCancel();
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.modalBody}>
        <TextField
          name="Title"
          select
          fullWidth
          size="medium"
          SelectProps={{ native: true }}
          variant="outlined"
          value={values.icdType}
          onChange={event => handleChange('icdType', event)}>
          <option>Select Code Type</option>
          {icdTypes.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Box>
      <PerfectScrollbar>
        <div style={{ maxHeight: 160 }}>
          <Table className={classes.generalTable} style={{ marginBottom: 10 }}>
            <TableHead>
              <TableRow>
                <TableCell>Files Chosen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{values.file && values.file.name}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <Box className={clsx(classes.modalFooter, classes.leftButton)}>
        <Button
          variant="outlined"
          size="small"
          style={{ textTransformm: 'capitalize' }}
          startIcon={<AttachFileOutlined />}
          onClick={handleClick}>
          Choose CSV File to Upload
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={event => handleChange('file', event)}
          style={{ display: 'none' }}
        />
        <span>
          <Button
            onClick={props.onCancel}
            style={{ marginRight: 10 }}
            variant="outlined"
            size="small">
            Cancel
          </Button>

          <Button
            disabled={!values.isValid}
            onClick={handleFileUpload}
            variant="contained"
            color="primary"
            size="small"
            style={{ borderRadius: 4 }}>
            UPLOAD
          </Button>
        </span>
      </Box>
    </React.Fragment>
  );
};

ICDImport.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {};
const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ICDImport));
