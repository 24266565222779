/* eslint-disable no-empty */
/* eslint-disable linebreak-style */

import { errorHandler } from '../error/action';
import http from 'services/http';

export const getPatientRemarks = patientid => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientid}/documents?category=All+Clinical`
    );
    if (res && res.data) {
      if (res.data.length > 0) {
        res.data.forEach(function (item) {
          if (item.remarksUpdatedDate) {
            item.remarksUpdatedDate = new Date();
          }
        });
      }
      dispatch({
        type: 'PATIENT_REMARKS',
        data: res.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientInactiveDiagnosis = patientid => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientid}/medicalHistory/inactiveDiagnosis`
    );
    if (res) {
      dispatch({
        type: 'PATIENT_INACTIVE_DIAGNOSIS',
        data: res.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientInactiveMedication = patientid => async dispatch => {
  try {
    const res =  await http.get(
      `/patients/${patientid}/medicalHistory/inactiveMedications`
    );
    if (res) {
      dispatch({
        type: 'PATIENT_INACTIVE_MEDICATION',
        data: res.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientPastDiagnosis = patientid => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientid}/medicalHistory/pastDiagnosis`
    );
    if (res) {
      dispatch({
        type: 'PATIENT_PAST_DIAGNOSIS',
        data: res.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientMedicalHistory = patientid => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientid}/medicalHistory`
    );
    if (res) {
      dispatch({
        type: 'PATIENT_MEDICAL_HISTORY',
        data: res.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientActiveMedications = patientid => async dispatch => {
  try {
    const res =  await http.get(
      `/patients/${patientid}/medicalHistory/activeMedications`
    );
    if (res) {
      dispatch({
        type: 'PATIENT_ACTIVE_MEDICATION',
        data: res.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientInfo = patientid => async dispatch => {
  try {
    const patres = await http.get(`/patients/${patientid}`);
    if (patres) {
      dispatch({
        type: 'PATIENT_INFO',
        data: patres.data 
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};


export const downloadPDFRx = (
  ehrID,
  patientId,
  userID,
  data,
  medicationComment
) => async () => {
  try {
    let comment = medicationComment ? medicationComment : '';
    const medcomment = encodeURIComponent(comment);
    const res = await http.post(
      `ehr/${ehrID}/patients/${patientId}/provider/${userID}/prescription/pdf?medicationComment=${medcomment}`,
      data,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const downloadPDFLab = (ehrID, patientId, userID, data) => async () => {
  try {
    const res = await http.post(
      `ehr/${ehrID}/patients/${patientId}/provider/${userID}/laborder/pdf`,
      data,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};
export const downloadPDFImage = (
  ehrID,
  patientId,
  userID,
  data
) => async () => {
  try {
    const res = await http.post(
      `ehr/${ehrID}/patients/${patientId}/provider/${userID}/imagingorder/pdf`,
      data,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};
export const downloadPDFPhysician = (
  ehrID,
  patientId,
  userID,
  data
) => async () => {
  try {
    const res = await http.post(
      `ehr/${ehrID}/patients/${patientId}/provider/${userID}/physicianorder/pdf`,
      data,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const downloadPDFSoapHP = (
  ehrID,
  userID,
  soapID,
  format
) => async () => {
  try {
    const res = await http.get(
      `ehr/${ehrID}/providers/${userID}/patientcharts/${soapID}/pdf?format=${format}`,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const saveSOAPPatientData = (
  ehrID,
  providerId,
  patientId,
  sign = false,
  data,
  isPastSign = false
) => async dispatch => {
  try {
    let res;
    if (data.soapnote.id > 0) {
      let soapId = isPastSign ? data.id : data.soapnote.id;
      res = await http.post(
        `ehr/${ehrID}/providers/${providerId}/patientcharts/${soapId}?patientID=${patientId}&sign=${sign}`,
        data
      );
    } else {
      res = await http.post(
        `ehr/${ehrID}/providers/${providerId}/patientcharts?patientID=${patientId}&sign=${sign}`,
        data
      );
    }
    if (res) {
      return {
        status: true,
        soapId: isPastSign ? res.data : res.data.soapnote.id
      };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePrescription = (
  ehrID,
  patientId,
  providerId,
  soapId,
  data
) => async dispatch => {
  try {
    const res = await http.post(
      `patients/${patientId}/provider/${providerId}/soapnote/${soapId}/medicalHistory/medicationlist`,
      data
    );

    if (res) {
      if (res.data)
        dispatch({
          type: 'USER_MEDICATIONS',
          data: res.data
        });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savelabOrders = (
  ehrID,
  patientId,
  providerId,
  soapId,
  data
) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/provider/${providerId}/soapnote/${soapId}/medicalHistory/labOrders`,
      data
    );

    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveImagingOrders = (
  ehrID,
  patientId,
  providerId,
  soapId,
  data
) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/provider/${providerId}/soapnote/${soapId}/medicalHistory/imagingOrders`,
      data
    );

    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePhysicianOrders = (
  ehrID,
  patientId,
  providerId,
  soapId,
  data
) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/provider/${providerId}/soapnote/${soapId}/medicalHistory/physicianOrders`,
      data
    );

    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveDiagnosis = (
  ehrID,
  patientId,
  providerId,
  soapId,
  data
) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/soapnote/${soapId}/medicalHistory/patPastDiagnosis`,
      data
    );

    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getVitalSigns = (patientId, page, limit) => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientId}/vitalsHistory?&page=${page}&limit=${limit}`
    );
    if (res) {
      dispatch({
        type: 'USER_VITALSIGNS',
        data: res.data
      });
      return res.data;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPastSoapNoteById = (patientid, page=0, limit=20) => async dispatch => {
  try {
    const resp = await http.get(`/patients/${patientid}/patientcharts?&page=${page}&limit=${limit}`);
    if (resp) {
      dispatch({
        type: 'PAST_SOAP_NOTE',
        data: {chart : resp.data, clearData : page===0}
      });
      return resp.data;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPastSoapNoteDetails = (
  ehrID,
  patientid,
  providerId,
  soapId
) => async dispatch => {
  try {
    let patPrescriptionRes,
      patLabOrderRes,
      patLabDiagnosisRes,
      patImagingOrderRes,
      patImageDiagnosisRes,
      patPhysicianOrderRes,
      patPhysicianDiagnosisRes,
      patPastDiagnosisCodesRes,
      patPastDiagnosisCPTCodesRes;

    patPrescriptionRes = await http.get(
      `/patients/${patientid}/soapnote/${soapId}`
    );
    if (patPrescriptionRes) {
    }
    patLabOrderRes = await http.get(
      `/patients/${patientid}/medicalHistory/patLabOrders/soapnote/${soapId}`
    );
    if (patLabOrderRes) {
      patLabDiagnosisRes = await http.get(
        `/patients/${patientid}/medicalHistory/patLabDiagnosis/soapnote/${soapId}`
      );
      if (patLabDiagnosisRes) {
        patLabOrderRes.data.labOrderDiagnosis = {};
        patLabOrderRes.data.labOrderDiagnosis = patLabDiagnosisRes.data;
      }
    }

    patImagingOrderRes = await http.get(
      `/patients/${patientid}/medicalHistory/patImagingOrders/soapnote/${soapId}`
    );
    if (patImagingOrderRes) {
      patImageDiagnosisRes = await http.get(
        `/patients/${patientid}/medicalHistory/patImageDiagnosis/soapnote/${soapId}`
      );
      if (patImageDiagnosisRes) {
        patImagingOrderRes.data.labOrderDiagnosis = {};
        patImagingOrderRes.data.labOrderDiagnosis = patImageDiagnosisRes.data;
      }
    }

    patPhysicianOrderRes = await http.get(
      `/patients/${patientid}/medicalHistory/patPhysicianOrders/soapnote/${soapId}`
    );
    if (patPhysicianOrderRes) {
      patPhysicianDiagnosisRes = await http.get(
        `/patients/${patientid}/medicalHistory/patPhysicianDiagnosis/soapnote/${soapId}`
      );
      if (patPhysicianDiagnosisRes) {
        patPhysicianOrderRes.data.labOrderDiagnosis = {};
        patPhysicianOrderRes.data.labOrderDiagnosis =
          patPhysicianDiagnosisRes.data;
      }
    }

    patPastDiagnosisCodesRes = await http.get(
      `/patients/${patientid}/medicalHistory/patPastDiagnosisCodes/soapnote/${soapId}?type=diagnosis`
    );
    if (patPastDiagnosisCodesRes) {
    }

    patPastDiagnosisCPTCodesRes = await http.get(
      `/patients/${patientid}/medicalHistory/patPastDiagnosisCodes/soapnote/${soapId}?type=cpt`
    );
    if (patPastDiagnosisCPTCodesRes) {
    }

    if (patPrescriptionRes) {
      dispatch({
        type: 'PAST_SOAP_NOTE_DETAILS',
        data: {
          prescriptions: patPrescriptionRes.data,
          labOrders: patLabOrderRes.data,
          imagingOrders: patImagingOrderRes.data,
          physicianOrders: patPhysicianOrderRes.data,
          diagnosisList: patPastDiagnosisCodesRes.data,
          cptList: patPastDiagnosisCPTCodesRes.data
        }
      });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveAllergy = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/allergies`,
      data
    );

    if (res) {
      return { status: true, allergies: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateAllergy = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/allergy/${data.id}`,
      data
    );

    if (res) {
      return { status: true, allergy: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteAllergy = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/allergies/${id}`
    );
    if (res) {
      return { status: true, allergies: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveMedication = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/medications`,
      data
    );

    if (res) {
      return { status: true, medications: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateMedication = (patientId, data) => async dispatch => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/medication/${data.id}`,
      data
    );

    if (res) {
      return { status: true, medication: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteMedication = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/medications/${id}`
    );
    if (res) {
      return { status: true, medications: res.data };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const inactivateMedication = (patientId, data) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/medicalHistory/inactiveMedication`,
      data
    );
    if (res) {
      return { status: true, inactiveMedication: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const activateMedication = (patientId, data) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/medicalHistory/activeMedication`,
      data
    );
    if (res) {
      return { status: true, activeMedication: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteSoap = (ehrID, userID, patientId, soapID) => async () => {
  try {
    const res = await http.delete(
      `ehr/${ehrID}/providers/${userID}/patientcharts/${soapID}?patientID=${patientId}`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveHealthMaintenance = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/healthMaintenance`,
      data
    );

    if (res) {
      return { status: true, healthMaintenance: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateHealthMaintenance = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/healthMaintenance/${data.id}`,
      data
    );

    if (res) {
      return { status: true, healthMaintenance: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteHealthMaintenance = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/healthMaintenance/${id}`
    );

    if (res) {
      return { status: true, healthMaintenance: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveVaccinations = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/vaccinations`,
      data
    );

    if (res) {
      return { status: true, vaccinations: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateVaccinations = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/vaccinations/${data.id}`,
      data
    );

    if (res) {
      return { status: true, vaccinations: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteVaccinations = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/vaccinations/${id}`
    );
    if (res) {
      return { status: true, vaccinations: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveSocialHistory = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/socialHistory`,
      data
    );
    if (res) {
      return { status: true, socialHistory: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateSocialHistory = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/socialHistory/${data.id}`,
      data
    );

    if (res) {
      return { status: true, socialHistory: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteSocialHistory = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/socialHistory/${id}`
    );
    if (res) {
      return { status: true, socialHistory: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveFamilyHistory = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/familyHistory`,
      data
    );

    if (res) {
      return { status: true, familyHistory: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateFamilyHistory = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/familyHistory/${data.id}`,
      data
    );

    if (res) {
      return { status: true, familyHistory: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteFamilyHistory = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/familyHistory/${id}`
    );
    if (res) {
      return { status: true, familyHistory: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveAdvanceDirective = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/advanceDirective`,
      data
    );

    if (res) {
      return { status: true, advanceDirective: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateAdvanceDirective = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/advanceDirective/${data.id}`,
      data
    );

    if (res) {
      return { status: true, advanceDirective: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteAdvanceDirective = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/advanceDirective/${id}`
    );
    if (res) {
      return { status: true, advanceDirective: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveAdditionalItems = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/additionalItems`,
      data
    );

    if (res) {
      return { status: true, additionalItems: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateAdditionalItems = (patientId, data) => async () => {
  try {
    let res;
    res = await http.post(
      `patients/${patientId}/medicalHistory/additionalItems/${data.id}`,
      data
    );

    if (res) {
      return { status: true, additionalItems: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteAdditionalItems = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/additionalItems/${id}`
    );
    if (res) {
      return { status: true, additionalItems: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteInactiveDiagnosis = (patientId, id) => async () => {
  try {
    const res = await http.delete(
      `patients/${patientId}/medicalHistory/inactiveDiagnosis/${id}`
    );
    if (res) {
      return { status: true, inactiveDiagnosis: res.data };
    }
    return false;
  } catch (error) {
    //errorHandler(error);
    return false;
  }
};

export const inactivateDiagnosis = (patientId, data) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/medicalHistory/inactiveDiagnosis`,
      data
    );
    if (res) {
      return { status: true, inactiveDiagnosis: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const activateDiagnosis = (patientId, data) => async () => {
  try {
    const res = await http.post(
      `patients/${patientId}/medicalHistory/activePastDiagnosis`,
      data
    );
    if (res) {
      return { status: true, activePastDiagnosis: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePastDiagnosis = (patientId, data) => async () => {
  try {
    let res, pastDiagnosisres, inactiveMedicationsres;
    res = await http.post(
      `/patients/${patientId}/medicalHistory/pastDiagnosis`,
      data
    );

    if (res) {
      pastDiagnosisres = await http.get(
        `/patients/${patientId}/medicalHistory/pastDiagnosis`
      );
      inactiveMedicationsres = await http.get(
        `/patients/${patientId}/medicalHistory/inactiveMedications`
      );
    }
    if (res) {
      return {
        status: true,
        pastDiagnosis: pastDiagnosisres.data,
        inactivateDiagnosis: inactiveMedicationsres.data
      };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteDiagnosis = (patientID, diagnosisId) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientID}/medicalHistory/diagnosis/${diagnosisId}`
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteSoapMedication = (patientID, medicationId) => async () => {
  try {
    const res = await http.post(
      `/patients/${patientID}/medicalHistory/updateMedication/${medicationId}`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteLabOrder = (patientID, labOrderId) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientID}/medicalHistory/labOrders/${labOrderId}`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteImagingOrder = (patientID, imagingOrderId) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientID}/medicalHistory/imagingOrders/${imagingOrderId}`
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deletePhysicianOrder = (
  patientID,
  physicianOrderId
) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientID}/medicalHistory/physicianOrders/${physicianOrderId}`
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveTempData = data => async dispatch => {
  dispatch({
    type: 'SAVE_TEMP_DATA',
    data: data
  });
};

// export const saveTempDataPrescription = data => async dispatch => {
//   dispatch({
//     type: 'SAVE_TEMP_DATA_PRESCRIPTION',
//     data: data
//   });
// };

// export const saveTempDataMedicationComment = data => async dispatch => {
//   dispatch({
//     type: 'SAVE_TEMP_DATA_MEDICATION',
//     data: data
//   });
// };

export const resetValue = () => async dispatch => {
  dispatch({
    type: 'RESET_VALUE'
  });
};

export const clearSoap = () => async dispatch => {
  dispatch({
    type: 'CLEAR_SOAP'
  });
};

export const getSoapActiveInactiveMedication = patientId => async dispatch => {
  try {
    let activeMedicationsres, inactiveMedicationsres;
    activeMedicationsres = await http.get(
      `/patients/${patientId}/medicalHistory/activeMedications`
    );
    inactiveMedicationsres = await http.get(
      `/patients/${patientId}/medicalHistory/inactiveMedications`
    );

    if (inactiveMedicationsres || activeMedicationsres) {
      dispatch({
        type: 'ACTIVE_INACTIVE_MEDICATION',
        data: {
          medications: activeMedicationsres.data,
          inactiveMedications: inactiveMedicationsres.data
        }
      });

      return {
        status: true,
        medications: activeMedicationsres.data,
        inactiveMedications: inactiveMedicationsres.data
      };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
