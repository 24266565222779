import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import PatientFilter from './PatientFilter';
import CalendarDaily from './CalendarDaily';
import CalendarWeekly from './CalendarWeekly';
import { Grid, Box, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getAllPhyPtSchedules,
  getAppointmentsByPatientId,
  getAllPhyPtSchedulesWeekly
} from 'services/ptschedules/action';
import moment from 'moment';
import CalendarEmpty from './CalendarEmpty';
const PTList = props => {
  const {
    classes,
    allPhyPtSchedules,
    allPhyPtSchedulesWeekly,
    seldate,
    scroll_ps,
    ehrID
  } = props;
  const [isWeekly, setIsWeekly] = useState(false);
  const childRef = useRef();
  const [isloading, setisloading] = useState(true);
  useEffect(() => {
    props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
    setisloading(false);
  }, []);

  useEffect(() => {
    setisloading(false);
  }, [allPhyPtSchedules, allPhyPtSchedulesWeekly]);

  const setWeekly = async (selecteddate, isWeekly) => {
    setisloading(true);
    if (isWeekly) {
      let startDate = selecteddate
        ? moment(selecteddate)
          .startOf('week')
          .format('MM/DD/YYYY')
        : moment()
          .startOf('week')
          .format('MM/DD/YYYY');
      let endDate = selecteddate
        ? moment(selecteddate)
          .endOf('week')
          .format('MM/DD/YYYY')
        : moment()
          .endOf('week')
          .format('MM/DD/YYYY');
      await props.getAllPhyPtSchedulesWeekly(
        ehrID,
        startDate,
        endDate);
    } else {
      props.getAllPhyPtSchedules(
        ehrID,
        selecteddate
          ? moment(selecteddate).format('MM/DD/YYYY')
          : moment().format('MM/DD/YYYY'),
        localStorage.getItem('filterPTScheduleLocation')?localStorage.getItem('filterPTScheduleLocation'):'',
        localStorage.getItem('filterPTScheduleProvider')?localStorage.getItem('filterPTScheduleProvider'):''
      );
    }
    setIsWeekly(isWeekly);
    props.handleWeekly(isWeekly);
  };

  const handleDrpDownChangeptScheduleDaily = (location, provider, date) => {
    setisloading(true);
    props.handleDrpDownChangeptScheduleDaily(location, provider, date);
  };

  const handleDrpDownChangeptScheduleWeekly = (
    location,
    provider,
    fromdate,
    todate
  ) => {
    setisloading(true);
    props.handleDrpDownChangeptScheduleWeekly(
      location,
      provider,
      fromdate,
      todate
    );
  };

  const handleToday = isWeekly => {
    setisloading(true);
    props.handleToday(isWeekly);
  };

  const handleNextPreviousDaily = date => {
    setisloading(true);
    props.handleNextPreviousDaily(date);
  };

  const handleNextPreviousWeekly = (fromDate, toDate) => {
    setisloading(true);
    props.handleNextPreviousWeekly(fromDate, toDate);
  };

  const handleEditAppointment = item => {
    props.handleEditAppointment(item);
  };

  const handleDeleteAppointment = item => {
    props.handleDeleteAppointment(item);
  };

  const handleOpenScheduleAppointment = () => {
    props.handleOpenScheduleAppointment();
  };

  return (
    <Grid item container spacing={0} style={{ borderTop: '1px solid #D4E5F9' }}>
      <Grid item xs={12}>
        <Box>
          <Box>
            <PatientFilter
              handleDrpDownChangeptScheduleDaily={
                handleDrpDownChangeptScheduleDaily
              }
              handleDrpDownChangeptScheduleWeekly={
                handleDrpDownChangeptScheduleWeekly
              }
              handleToday={handleToday}
              handleNextPreviousDaily={handleNextPreviousDaily}
              handleNextPreviousWeekly={handleNextPreviousWeekly}
              ref={childRef}
              setWeekly={setWeekly}
              seldate={seldate}
              isWeekly={isWeekly}
              isLocalStorage={props.isLocalStorage}
            />
          </Box>
          {isloading ? (
            <div
              className={classes.progressWrapper}
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginBottom: 30
              }}>
              <CircularProgress style={{ height: 30, width: 30 }} />
            </div>
          ) : (
            <Box>
              {!isWeekly &&
              allPhyPtSchedules &&
              allPhyPtSchedules !== 'undefined' &&
              allPhyPtSchedules.length > 0 ? (
                <CalendarDaily
                  data={allPhyPtSchedules}
                  openSchedule={props.handleOpen1}
                  seldate={seldate}
                  handleEditAppointment={handleEditAppointment}
                  handleDeleteAppointment={handleDeleteAppointment}
                  handleOpenScheduleAppointment={handleOpenScheduleAppointment}
                  scroll_ps={scroll_ps}
                />
              ) : isWeekly &&
                allPhyPtSchedulesWeekly &&
                allPhyPtSchedulesWeekly !== 'undefined' &&
                allPhyPtSchedulesWeekly.length > 0 ? (
                <CalendarWeekly
                  data={allPhyPtSchedulesWeekly}
                  openSchedule={props.handleOpen1}
                  seldate={seldate}
                  handleEditAppointment={handleEditAppointment}
                  handleDeleteAppointment={handleDeleteAppointment}
                />
              ) : (
                <CalendarEmpty />
              )}
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

PTList.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  getAppointmentsByPatientId,
  getAllPhyPtSchedulesWeekly
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allAppointments:
    state.ptschedules &&
    state.ptschedules.allAppointments &&
    state.ptschedules.allAppointments.result,
  allPhyPtSchedules:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedules &&
    state.ptschedules.allPhyPtSchedules.result,
  allPhyPtSchedulesWeekly:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedulesWeekly &&
    state.ptschedules.allPhyPtSchedulesWeekly.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PTList))
);