import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../style';
import { Grid, Slide } from '@material-ui/core';

import { MyTemplates, TemplateText } from './components';

import { connect } from 'react-redux';
import { getAssessmentPlanTemplates } from 'services/providers/action';

const AssessmentPlan = props => {
  const { classes, ehrID, userID } = props;
  const [assessmentPlanTopics, setAssessmentPlanTopics] = useState(null);
  const [assessmentPlanTopic, setAssessmentPlanTopic] = useState(null);

  useEffect(() => {
    props.getAssessmentPlanTemplates(ehrID, userID).then((res => {
      if (res && res.data.length > 0) {
        setAssessmentPlanTopics(res.data);
      }
    }));
  }, []);

  const setTopicText = topicText => {
    setAssessmentPlanTopic(topicText);
  }

  return ( 
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.tabLabel}>
            Assessment &amp; Plan Template - User Defined
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <MyTemplates assessmentPlanTopics={assessmentPlanTopics} setTopicText={setTopicText} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TemplateText assessmentPlanTopic={assessmentPlanTopic} />
        </Grid>
      </Grid> 
  );
};

AssessmentPlan.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAssessmentPlanTemplates
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  assessmentPlanTemplates: state.providers && state.providers.assessmentPlanTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AssessmentPlan))
);
