import { errorHandler } from '../error/action';
import http from 'services/http';

export const getAllPhyPtIssues = (
  ehrID,
  date = '',
  location = '',
  provider = '',
  status = '',
  limit = 20,
  page = 0
) => async dispatch => {
  try {
    let loc = (location === 'undefined'  || location===null) ? '' : location;
    let pro = (provider === 'undefined' ||  provider===null) ? '' : provider;
    let sta = (status === 'undefined' || status===null) ? '' : status;
    const res = await http.get(
      `/ehr/${ehrID}/ptIssues?date=${date}&limit=${limit}&location=${loc}&order=DESC&page=${page}&physician=${pro}&sortby=updatedDate&status=${sta}`
    );
    if (res) {
      if (date) {
        dispatch({
          type: 'PHYPTISSUES_GET_DATE',
          data: res.data
        });
        return true;
      }
      dispatch({
        type: 'PHYPTISSUES_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updatePtIssue = data => async () => {
  try {
    let res;
    if (data.id > 0) {
      res = await http.post(`/patient/${data.patientId}/updatePtIssue`, data);
    } else {
      res = await http.post(`/patient/${data.patientId}/savePtIssue`, data);
    }
    if (res) {
      // dispatch({
      //   type: "PATIENT_GET",
      //   data: res.data
      // });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePtIssueResponse = data => async () => {
  try {
    const res = await http.post(
      `/patient/${data.patientId}/savePtIssueResponse`,
      data
    );
    if (res) {
      // dispatch({
      //   type: "PATIENT_GET",
      //   data: res.data
      // });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const setPtIssueComplete = data => async () => {
  try {
    const res = await http.post(
      `/patient/${data.patientId}/setPtIssueComplete`,
      data
    );
    if (res) {
      // dispatch({
      //   type: "PATIENT_GET",
      //   data: res.data
      // });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
