/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-undef */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton
} from '@material-ui/core';
import {
  EditOutlined,
  DeleteOutline,
  StorageOutlined
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  deleteLabOrder,
  deleteImagingOrder,
  deletePhysicianOrder
} from 'services/soap/action';

const MyTemplates = props => {
  const { classes, typeOrder, patientId } = props;

  const [labOrdertext, setLabOrderText] = useState('');
  const [diaOrdertext, setDiaOrderText] = useState('');
  const [labOrders, setLabOrders] = useState([]);
  const [diagnosisList, setDiagnosisList] = useState([]);

  useEffect(() => {
    if (
      props.labOrders &&
      props.labOrders.labOrder &&
      props.labOrders.labOrder.length > 0
    ) {
      setLabOrders(props.labOrders.labOrder);
    }
    if (
      props.labOrders &&
      props.labOrders.labOrderDiagnosis &&
      props.labOrders.labOrderDiagnosis.length > 0
    ) {
      setDiagnosisList(props.labOrders.labOrderDiagnosis);
    }

    if (props.labOrders && props.labOrders.diaOrdertext) {
      setDiaOrderText(props.labOrders.diaOrdertext);
    }
    if (props.labOrders && props.labOrders.labOrdertext) {
      setLabOrderText(props.labOrders.labOrdertext);
    }
  }, [props.labOrders]);

  useEffect(() => {
    if (props.copydiagnosis) {
      setDiaOrderText(props.copydiagnosis);
    }
  }, [props.copydiagnosis]);

  useEffect(() => {
    if (labOrders && (labOrders.labOrder || labOrders.labOrderDiagnosis)) {
      props.handleList('labOrder', labOrders);
    }
  }, [labOrders]);

  const handleOpenTemplates = type => {
    props.handleOpenTemplates(type);
  };

  const handleOpenICD = () => {
    props.handleOpenICD(true);
  };

  const handleFieldChange = async (field, value) => {
    if (field === 'labOrder') {
      setLabOrderText(value);
    } else if (field === 'diagnosis') {
      setDiaOrderText(value);
    }
    props.handleFieldChange(field, value);
  };

  const removeLabControl = async idx => {
    let newLabs = [...labOrders];
    let res;
    if(newLabs[idx] && newLabs[idx].id){
      if (typeOrder === 1) {
        res = await props.deleteLabOrder(patientId, newLabs[idx].id);
      } else if (typeOrder === 2) {
        res = await props.deleteImagingOrder(patientId, newLabs[idx].id);
      } else if (typeOrder === 3) {
        res = await props.deletePhysicianOrder(patientId, newLabs[idx].id);
      }
      if (res) {
        newLabs.splice(idx, 1);
        setLabOrders(newLabs);
      }
    }
    else{
      newLabs.splice(idx, 1);
      if(newLabs.length===0){
        newLabs=[];
      }
      setLabOrders(newLabs);
    }
    props.handleList('labOrder', newLabs, true);
  };

  const removeDiagnosisControl = async idx => {
    let newDias = [...diagnosisList];
    let res;
    
    if(newDias[idx] && newDias[idx].id){
      if (typeOrder === 1) {
        res = await props.deleteLabOrder(patientId, newDias[idx].id);
      } else if (typeOrder === 2) {
        res = await props.deleteImagingOrder(patientId, newDias[idx].id);
      } else if (typeOrder === 3) {
        res = await props.deletePhysicianOrder(patientId, newDias[idx].id);
      }
      if (res) {
        newDias.splice(idx, 1);
        setDiagnosisList(newDias);
      }
    }
    else{
      newDias.splice(idx, 1);
      if(newDias.length===0){
        newDias=[];
      }
      setDiagnosisList(newDias);
    }
    props.handleList('labOrderDiagnosis', newDias, true);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell colspan={3}>
                    <span
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <b>
                        {' '}
                        {typeOrder === 1
                          ? 'Lab '
                          : typeOrder === 2
                            ? 'Imaging '
                            : 'Physician '}
                        Orders
                      </b>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenTemplates(3)}
                        size="small"
                        title="Templates"
                        variant="contained"
                      >
                        <EditOutlined />
                      </IconButton>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {labOrders &&
                  labOrders.length > 0 &&
                  labOrders.map((item, idx) => {
                    return (
                      <TableRow>
                        <TableCell colspan={2}>
                          {item.text ? item.text : ''}
                        </TableCell>
                        <TableCell
                          align="right"
                          width={52}
                        >
                          <IconButton size="small">
                            <DeleteOutline
                              onClick={() => {
                                removeLabControl(idx);
                              }}
                              style={{ color: '#DE5555' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow>
                  <TableCell colspan={3}>
                    <TextField
                      multiline
                      onChange={event =>
                        handleFieldChange('labOrder', event.target.value)
                      }
                      placeholder="Add comments if any"
                      rows="4"
                      style={{ width: '100%' }}
                      value={labOrdertext || ''}
                      variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell colspan={3}>
                    <span
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <b>Diagnosis</b>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenICD()}
                        size="small"
                        title="Database"
                        variant="contained"
                      >
                        <StorageOutlined />
                      </IconButton>
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diagnosisList &&
                  diagnosisList.length > 0 &&
                  diagnosisList.map((item, idx) => {
                    return (
                      <TableRow>
                        <TableCell>{item.code ? item.code : ''}</TableCell>
                        <TableCell>
                          {item.description ? item.description : ''}
                        </TableCell>
                        <TableCell
                          align="right"
                          width={52}
                        >
                          <IconButton size="small">
                            <DeleteOutline
                              onClick={() => {
                                removeDiagnosisControl(idx);
                              }}
                              style={{ color: '#DE5555' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                <TableRow>
                  <TableCell colspan={3}>
                    <TextField
                      multiline
                      onChange={event =>
                        handleFieldChange('diagnosis', event.target.value)
                      }
                      placeholder="Add comments if any"
                      rows="4"
                      style={{ width: '100%' }}
                      value={diaOrdertext || ''}
                      variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  deleteLabOrder,
  deleteImagingOrder,
  deletePhysicianOrder
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  patientInfo: state.soap && state.soap.patientInfo,
  providers: state.util.providers
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
