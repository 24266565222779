import { errorHandler } from '../error/action';
import http from 'services/http';

export const addPatient = (ehrid, patientId, user) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrid}/users?patientID=${patientId}&roles=${user.roles}&isNew=true`,
      user
    );
    if (res) {
      dispatch({
        type: 'ADD_PATIENT',
        data: res
      });
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Username already exist';
    }
    errorHandler(error, message);
    return false;
  }
};

export const getPatients = (ehrID, page = 0, search = '', limit) => async dispatch => {
  try {
    const
      order = 'ASC',
      sortby = 'lastname';
    const res = await http.get(
      `/ehr/${ehrID}/siteUsers?limit=${limit}&order=${order}&page=${page}&search=${search}&sortby=${sortby}`
    );
    if (res) {
      dispatch({
        type: 'PATIENT_GET',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSearchPatients = (ehrID, patName) => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/patients?search=${patName}`);
    if (res) {
      dispatch({
        type: 'PATIENT_SEARCH',
        data: res.data
      });
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const editPatient = (ehrID, patientID, user) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/users/${patientID}/updatePatientNew?patientId=${patientID}`,
      user
    );
    if (res) {
      // dispatch({
      //   type: "PATIENT_GET",
      //   data: res.data
      // });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const resendActivationEmail = (ehrID, userId) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/resendActivationEmail?userID=${userId}&isNew=true`
    );
    if (res) {
      // dispatch({
      //   type: "PATIENT_GET",
      //   data: res.data
      // });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientsOnSearch = (ehrID, patName) => async dispatch => {
  try {
    const limit = '20';
    const res = await http.get(
      `/ehr/${ehrID}/patients?limit=${limit}&search=${patName}`
    );
    if (res) {
      dispatch({
        type: 'LIST_SEARCH_PATIENTS',
        data: res.data
      });
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addPatientfromPtSchedule = (ehrID, data) => async dispatch => {
  try {
    let res;
    if (data.dob) {
      res = await http.post(`/ehr/${ehrID}/patients?date=${data.dob}`, data);
    } else {
      res = await http.post(`/ehr/${ehrID}/patients`, data);
    }
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const checkpatient = data => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${data.ehrID}/checkpatient/patient?patientID=${data.ehrID}`,
      data
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
