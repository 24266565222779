export default {
  firstname: {
    presence: { allowEmpty: false, message: "is required" }
  },
  lastname: {
    presence: { allowEmpty: false, message: "is required" }
  },
  emailaddress: {
    presence: { allowEmpty: false, message: "^Email is required" },
    email: { required: true, message: '^Email is not valid' },
  },
};
