export default {
  patientName: {
    presence: { allowEmpty: false, message: "is required" }
  },
  username: {
    presence: { allowEmpty: false, message: "is required" }
  },
  emailaddress: {
    presence: { allowEmpty: false, message: "is required" },
    email: true
  },
  confirmEmail: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    equality: {
      attribute: "emailaddress",
      message: "^Email and confirm email does not match",
      comparator: (v1, v2) => {
        return v1 === v2;
      }
    }
  },
};
