/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../Default/style';
import { PtReminders } from '.';
import { connect } from 'react-redux';
import { getAllPhyPtSchedules } from 'services/ptschedules/action';
import { getLocations, getProviders } from 'services/util/action';
import { getPatientDocuments,getSelectedPatient } from 'services/home/action';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  Modal
} from '@material-ui/core';
import { FileCopyOutlined, DateRangeOutlined } from '@material-ui/icons';
import { DocViewer } from 'views/Common/components';
import { MHx } from 'views/Default/components/Soap/components';

const PtReview = props => {
  const {
    classes,
    className,
    data,
    isloading,
    ehrID,
    userID,
    locations,
    providers,
    documentInfo,patientInfo,
    additionalItems,
                    advanceDirectives,
                    allergies,
                    cpt,
                    diagnosis,
                    familyHistory, 
                    healthMaintenance,
                    inactivecpt,
                    inactivediagnosis,
                    inactivemedication,
                    medication,
                    socialHistory,
                    vaccinations,
                    ptRemarks,
    ...rest
  } = props;

  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [openDoc, setOpenDoc] = useState(false);

  useEffect(() => {
    if (userID) { 
      props.getSelectedPatient(userID);
    }
  }, [userID]);

  const [value, setValue] = React.useState(0);
  const [filtervalues, setFilterValues] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    return (
      <Typography
        aria-labelledby={`simple-tab-${index}`}
        component="div"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        role="tabpanel"
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  //#region Schedule
  const handleEditAppointment = item => {
    props.handleEditAppointment(item);
  };

  const handleDeleteAppointment = item => {
    props.handleDeleteAppointment(item);
  };

  const handleOpenScheduleAppointment = () => {
    props.handleOpenScheduleAppointment();
  };

  //#endregion

  //Filter

  const handleDrpDownChange = (location, provider) => {
    setFilterValues({ location: location, provider: provider });
    props.handleDrpDownChange(location, provider);
  };
  //---------------Doc viewer
  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  const handleCloseDoc = () => {
    setOpenDoc(false);
  };

  const handleOpenDoc = async (patientId, patientDocumentId, routeTo) => {
    setOpenDoc(true);
    await props.getPatientDocuments(
      ehrID,
      patientId,
      patientDocumentId,
      routeTo
    );
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.subTabRoot, className)}
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Tabs
            aria-label="simple tabs example"
            className={classes.subTab}
            indicatorColor="primary"
            onChange={handleChange}
            value={value}
            variant="fullWidth"
          >
            <Tab
              icon={<FileCopyOutlined />}
              label="Medical Summary"
              {...a11yProps(0)}
            />

            <Tab
              icon={<DateRangeOutlined />}
              label="Reminders"
              {...a11yProps(2)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Divider />
      <CardContent className={classes.inlineTabWrap}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <TabPanel
              index={0}
              style={{ padding: 0 }}
              value={value}
            >
              <PerfectScrollbar>
                <div style={{ maxHeight: '65vh' }}>
                  <MHx patientInfo={patientInfo} 
                    isViewSoap 
                    additionalItems={additionalItems}
                    advanceDirectives={advanceDirectives}
                    allergies={allergies}
                    cpt={cpt}
                    diagnosis={diagnosis}
                    familyHistory={familyHistory}  
                    healthMaintenance={healthMaintenance}
                    inactivecpt={inactivecpt}
                    inactivediagnosis={inactivediagnosis}
                    inactivemedication={inactivemedication}
                    medication={medication}  
                    socialHistory={socialHistory}
                    vaccinations={vaccinations} 
                    />
                </div>
              </PerfectScrollbar>
            </TabPanel>

            <TabPanel
              index={1}
              value={value}
            >
              <PerfectScrollbar>
                <div style={{ maxHeight: '75vh' }}>
                  <PtReminders userID={userID} />
                </div>
              </PerfectScrollbar>
            </TabPanel>
          </Grid>
        </Grid>
      </CardContent>
     
    </Card>
  );
};

PtReview.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  getLocations,
  getProviders,
  getPatientDocuments,getSelectedPatient
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  locations: state.util.locations,
  providers: state.util.providers, 
  patientInfo: state.soap && state.soap.patientInfo,
  allPhyPtSchedules:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedules &&
    state.ptschedules.allPhyPtSchedules.result,
  documentInfo: state.home && state.home.documentInfo,
  inactivemedication: state.soap && state.soap.patientInfo && state.soap.patientInfo.inactiveMedications,
  medication: state.soap && state.soap.patientInfo && state.soap.patientInfo.medications, 
  diagnosis: state.soap && state.soap.patientInfo && state.soap.patientInfo.pastDiagnosis,
  inactivediagnosis: state.soap && state.soap.patientInfo && state.soap.patientInfo.inactiveDiagnosis,
  familyHistory: state.soap && state.soap.patientInfo && state.soap.patientInfo.medicalHistory  && state.soap.patientInfo.medicalHistory.familyHistory,
  allergies: state.soap && state.soap.patientInfo && state.soap.patientInfo.medicalHistory  && state.soap.patientInfo.medicalHistory.allergies,
  healthMaintenance: state.soap && state.soap.patientInfo && state.soap.patientInfo.medicalHistory  && state.soap.patientInfo.medicalHistory.healthMaintenance,
  vaccinations:state.soap && state.soap.patientInfo && state.soap.patientInfo.medicalHistory  && state.soap.patientInfo.medicalHistory.vaccinations, 
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PtReview))
);
