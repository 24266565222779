/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from '../../../style';

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { getLocations } from 'services/util/action';
import { connect } from 'react-redux';
import {
  getCaseFiles,
  getPatientDocuments,
  getRecaps,
} from 'services/home/action';

import moment from 'moment';
import Loader from '../../../../Common/components/Loader';

const Details = props => {
  const {
    classes,
    className,
    username,
    profile,
    userID,
    patientCharts,
    ...rest
  } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (
      patientCharts &&
      patientCharts !== undefined
    ) {
      let datatopush = [];
      patientCharts &&
      patientCharts.forEach(function (item) {
          datatopush.push({
            patientId: item.patient.id,
            patientChartID: item.documentRoute.patientChartID,
            patientVisitDate: item.visitdate
              ? moment(item.visitdate).format('MM/DD/YYYY')
              : '',
            reason:
              item.soapnote.subjective != null &&
                item.soapnote.subjective.trim() !== ''
                ? item.soapnote.subjective.substring(0, 50)
                : item.soapnote.objective !== null &&
                  item.soapnote.objective.trim() !== ''
                  ? item.soapnote.objective.substring(0, 50)
                  : item.soapnote.assessmentplan !== null &&
                    item.soapnote.assessmentplan.trim() !== ''
                    ? item.soapnote.assessmentplan.substring(0, 50)
                    : item.soapnote.diagnosis !== null &&
                      item.soapnote.diagnosis.trim() !== ''
                      ? item.soapnote.diagnosis.substring(0, 50)
                      : '',
            itemBy: item.signed
              ? 'Signed by : ' +
              item.provider.firstname +
              ' ' +
              item.provider.lastname
              : 'Created by : ' +
              item.provider.firstname +
              ' ' +
              item.provider.lastname,
            patient: item
          });
        });
      setData(datatopush);
      setLoading(false);
    }
  }, [patientCharts]);

  // const handleOpenSoap = item => {
  //  // setOpenSoap({ open: true, soap: item });
  // };
  const showPatientDocument = async item => {
    props.handleOpenDoc(item.patientid, item.patientDocumentId, item.routeTo);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '10px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: {
          '&$hover:hover': { backgroundColor: '#EFF5FC' }
        }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientChartID',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientVisitDate',
      label: 'Patient Visit Date',
      options: {
        sort: false,
        // setCellProps: value => ({
        //   style: { '&$hover:hover': { cursor: 'pointer' } }
        // })
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.handleOpenViewSoap(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1]
                )
              }>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'reason',
      label: 'Findings',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.handleOpenViewSoap(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1]
                )
              }>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'itemBy',
      label: 'Created By',
      options: {
        sort: false,

        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.handleOpenViewSoap(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1]
                )
              }>
              {value}
            </span>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: false,
    serverSide: true,
    setCellProps: rowData => ({
      style: { cursor: 'pointer' }
    }),

    onRowClick: rowData => props.handleOpenViewSoap(rowData[0], rowData[1])
  };

  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      <Grid container>
        <Grid className={classes.cardHeader} item xs={8}>
          <Typography variant="h2">
            {(profile && profile.firstname ? profile.firstname : '') +
              ' ' +
              (profile && profile.lastname ? profile.lastname : ' ') +
              ' '}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        {loading ? (

          <Loader />
        ) : (
          <MuiThemeProvider theme={theme}>
            <div className={classes.fullWidthTable}>
              <MUIDataTable
                columns={columns}
                data={data}
                options={options}
                responsive="scrollMaxHeight"
              />
            </div>
          </MuiThemeProvider>
        )}
      </CardContent>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getLocations,
  getCaseFiles,
  getPatientDocuments,
  getRecaps,
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  username: state.profile && state.profile.name,
  userID: state.profile && state.profile.userID,
  profile: state && state.profile,
  casefiles: state.home && state.home.list,
  documentInfo: state.home && state.home.documentInfo,
  patientCharts: state.home && state.home.patientCharts,
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(Details));
