// import rectBg from '../../assets/images/rect-bg.png';
export default theme => ({
  root: {
    //height: '100%',
    marginBottom: 40
  },
  grid: {
    //height: 'calc(100% - 114px)'
  },
  headerWrap: {
    background: 'transparent',
    boxShadow: 'none',
    padding: '20px 0'
  },
  naviWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1
  },
  logoImg: {
    width: 175,
    [theme.breakpoints.down('sm')]: {
      width: '125px'
    }
  },
  loginButton: {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 1,
    padding: '5px 20px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: '5px 10px'
    }
  },
  searchWrap: {
    border: '1px solid #ccc',
    height: 34,
    borderRadius: 1,
    background: '#fff',
    display: 'flex',
    position: 'relative',
    marginRight: 10,
    width: 320,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  searchWrapBigOuter: {
    justifyContent: 'center',
    padding: '20px 0 40px 0'
  },
  searchWrapBig: {
    width: 700
  },
  searchBtn: {
    background: theme.palette.primary.main,
    borderRadius: 0,
    padding: '0 5px',
    color: '#fff',
    position: 'absolute',
    right: -1,
    top: -1,
    bottom: -1,
    borderRadius: '0 3px 3px 0',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  searchInput: {
    paddingRight: 35,
    padding: '5px 0 5px 10px',
    fontSize: 14,
    width: '100%'
  },
  searchResultsWrap: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 3px rgb(197 216 234)',
    borderRadius: '5px',
    padding: 20,
    marginBottom: 15,
    '& h2': {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '19px',
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      paddingBottom: 10,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& $resultBody': {
      '& $resultItem': {
        paddingBottom: 5,
        display: 'flex',
        alignItems: 'center',
        color: '#7e7e7e',
        '& a': {
          '&:hover': {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
          }
        }
      }
    }
  },
  colorPrimary: {
    color: theme.palette.primary.main
  },
  colorSecondary: {
    color: theme.palette.secondary.main
  },
  colorGreen: {
    color: '#249b77'
  },
  colorGrey: {
    color: '#7e7e7e'
  },
  resultBody: {},
  resultItem: {},
  rightCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  quoteContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  quoteText: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 48,
    lineHeight: '50px',
    position: 'relative'
  },
  name: {
    marginTop: theme.spacing(3),
    color: '#484444',
    fontSize: 18
  },
  line: {
    background: '#3397f3',
    height: 6,
    display: 'inline-block',
    width: '40%',
    position: 'absolute',
    bottom: 10,
    lineHeight: 0,
    borderRadius: 3
  },
  contentContainer: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px',
    paddingBottom: 40,
    [theme.breakpoints.up('md')]: { 
      paddingLeft: 80,
      paddingBottom: 40
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px',
      paddingBottom: 20
    },
    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center'
    }
  },
  contentFrgt: {
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 40,
      marginTop: 20
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px',
      paddingBottom: 20
    }
  },
  contentSignin: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 40,
    [theme.breakpoints.down('xs')]: {
      padding: '0 5px'
    }
  },

  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px'
    },
    background: '#FFFFFF',
    boxShadow: '0px 0px 25px rgba(47, 47, 47, 0.5)',
    padding: '30px 40px 20px 40px',
    flexDirection: 'column',
    transition: 'display 2s'
  },
  contentBodyMsg: {
    boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    background: 'white',
    paddingBottom: 20,
    flexDirection: 'column',
    width: '80%',
    margin: '0 auto',
    borderRadius: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  contentHeader: {
    display: 'flex',
    width: '100%',
    background: 'linear-gradient(90deg, #60CE8C 17.24%, #48BDAF 100%), #2EB256',
    borderRadius: '5px 5px 0px 0px',
    padding: 30,
    paddingTop: 40,
    minHeight: 160,
    color: '#fff',
    flexDirection: 'column',
    alignItems: 'center',
    '& h1': {
      fontWeight: 300,
      color: '#fff',
      textAlign: 'center'
    },
    '& svg': {
      transform: 'scale(2)',
      marginBottom: 20
    }
  },
  contentInner: {
    padding: 40,
    textAlign: 'center',
    '& h6': {
      fontWeight: 600,
      paddingBottom: 10
    }
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  formTitle: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    }
  },
  formSubTitle: {
    fontSize: 16,
    textAlign: 'center',
    margin: '10px 0 40px 0',
    color: '#484444'
  },
  formButtonWrap: {
    padding: '20px 0',
    '& div:nth-child(2)': {
      justifyContent: 'flex-end',
      display: 'flex'
    }
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 32,
    fontWeight: 600,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24
    }
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginBottom: theme.spacing(2)
  },

  signInButton: {
    margin: theme.spacing(2, 0),
    borderRadius: 1
  },

  labelRoot: {
    color: '#B9B9B9'
  },
  labelFocused: {
    backgroundColor: theme.palette.primary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },
  labelFocusedS: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },
  textField: {
    borderColor: '#D8D8D8',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: 1,
    '&::placeholder': {
      color: 'blue',
      fontSize: 10
    },
    '& + & ': {
      marginTop: theme.spacing(2)
    },
    '&:focus $notchedOutline': {
      borderColor: 'green'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC',
      borderRadius: 1
    }
  },
  inputOutlined: {
    color: 'black',
    fontWeight: 500,
    '&$inputFocused $notchedOutline': {
      borderColor: theme.palette.primary.main,
      borderWidth: 1
    }
  },
  inputOutlinedG: {
    color: 'black',
    fontWeight: 500,
    '&$inputFocused $notchedOutline': {
      borderColor: theme.palette.secondary.main,
      borderWidth: 1
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.secondary.main
    }
  },
  inputFocused: {},
  notchedOutline: {},
  forgot: {
    marginTop: theme.spacing(1)
  },
  forgotTxt: {
    color: theme.palette.text.secondary,
    fontSize: 10,
    color: '#939393',
    '&:hover': {
      color: theme.palette.text.primary,
      textDecoration: 'none'
    }
  },
  termsContent: {
    padding: 10,
    height: 'calc(100vh - 305px)',
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: '22px',
    width: '100%',
    color: '#2f2f2f',
    '& p': {
      marginBottom: 10
    }
  },
  buttonWrap: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  roundButton: {
    width: 'auto',
    marginTop: 20,
    padding: '10px 30px!important',
    lineHeight: '16px'
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    '& svg': {
      height: 35,
      width: 35
    }
  },
  backButton: {
    backgroundColor: '#EAEAEA',
    padding: '10px 5px',
    minWidth: '50px',
    '& svg': {
      color: '#000'
    }
  },
  termsTitle: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: 10,
    marginTop: -20,
    color: theme.palette.primary.main
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 0',
    '& h2': {
      fontSize: 24,
      fontWeight: 300,
      color: theme.palette.primary.main
    },
    '& h4': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.palette.primary.main
    },
    '& h6': {
      marginTop: 20
    },
    '& Button': {
      margin: '0 5px',
      textTransform: 'capitalize'
    }
  },
  iconBig: {
    color: '#3397F3',
    width: 125,
    height: 125,
    margin: 20,
    padding: 20,
    borderRadius: '50%',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
