export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  textGrey: {
    color: '#a2a2a2'
  },
  textGreen: {
    color: theme.palette.success.main
  },
  contentWrap: {
    background: '#fff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: 10
  },
  datePickerWrap: {
    position: 'relative',
    width: '100%',
    '& .react-datepicker-wrapper': {
      display: 'block',
      marginTop: 5,
      '& input': {
        padding: '18px 15px'
      },
      '& .MuiFormLabel-root.Mui-focused': {
        backgroundColor: theme.palette.primary.main,
        color: 'white!important',
        padding: '5px 10px',
        marginLeft: -4,
        height: 25,
        paddingTop: 5,
        marginTop: -2
      }
    },
    '& .react-datepicker-popper': {
      zIndex: 1000,
      marginTop: 0,
      '& .react-datepicker__triangle': {
        left: '200px!important'
      }
    }
  },
  leftWrap: {
    paddingRight: 10,
    // height: 500,
    // overflowY: 'auto',
    // '&::-webkit-scrollbar': {
    //   width: 6
    // },
    // '&::-webkit-scrollbar-track': {
    //   backgroundColor: 'white'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#dedede',
    //   borderRadius: 3
    // },
    // overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      flexDirection: 'row',
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      display: 'flex'
    }
  },
  rightWrap: {
    border: '1px solid #D4E5F9'
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    justifyContent: 'space-between',
    display: 'flex',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalBody: {
    padding: '10px 0'
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalFooter: {
    padding: '20px 0px 10px 0px',
    textAlign: 'right'
  },
  patientName: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 20,
    marginBottom: 5
  },
  clickable: {
    cursor: 'pointer'
  },
  dialTitle: {
    '& h2': {
      fontWeight: '800'
    }
  },
  generalTable: {
    '& td': {
      padding: '8px 5px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px',
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    '& th': {
      padding: '8px 5px',
      fontWeight: 600,
      background: 'transparent',
      borderBottom: '1px solid #a2a2a2'
    },
    '& thead': {
      backgroundColor: 'transparent'
    }
  },
  ellipsisMode: {
    display: 'grid',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  PTfilterWrap: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& select': {
      padding: '8px 10px',
      fontSize: 12,
      height: 18,
      paddingRight: 30
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '5px!important'
    }
  },
  PTfilterLeft: {
    display: 'flex',
    top: 0,
    left: 0,
    padding: 10
  },
  PTfilterRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  todayBtn: {
    borderRadius: 5,
    fontSize: 12,
    border: '1px solid #ccc',
    padding: '5px 10px',
    marginRight: 20,
    cursor: 'pointer'
  },
  calendarArrow: {
    fontSize: 26,
    color: '#0000008a'
  },
  calendarDate: {
    fontSize: 14,
    color: '#0000008a',
    fontWeight: 700,
    marginRight: 40,
    marginLeft: 20
  },
  tableCalendar: {
    '& tr': {
      borderTop: '1px solid #D4E5F9',
      display: 'table',
      width: '100%',
      tableLayout: 'fixed'
    },
    '& td': {
      borderColor: '#D4E5F9',
      fontSize: '13px!important',
      '&:first-child': {
        textAlign: 'center',
        width: 100,
        borderRight: '1px solid #D4E5F9'
      }
    },
    '& th': {
      borderColor: '#D4E5F9',
      padding: '8px 5px',
      fontSize: '13px!important',
      '&:first-child': {
        fontWeight: 'bold',
        textAlign: 'center',
        borderRight: '1px solid #D4E5F9',
        width: 100
      }
    },
    '& thead': {
      backgroundColor: 'transparent',
      '& tr': {
        width: 'calc(100% - 6px)'
      }
    }
  },
  tableCalendarWeekly: {
    '& thead': {
      '& tr': {
        width: 'calc(100% - 6px)'
      }
    },
    tableLayout: 'fixed',
    '& td': {
      borderRight: '1px solid #D4E5F9',
      maxWidth: '13.5%',
      '&:last-child': {
        borderRight: '1px solid transparent'
      },
      '&:first-child': {
        width: 60
      }
    },
    '& th': {
      borderRight: '1px solid #D4E5F9',
      textAlign: 'center',
      fontWeight: 700,
      '&:last-child': {
        borderRight: '1px solid transparent'
      },
      '&:first-child': {
        width: 60
      }
    },
    '& $scheduleBox': {
      paddingLeft: 5
    }
  },
  scheduleTd: {
    padding: '0'
  },
  scheduleBoxWrap: {
    flex: 1,
    flexDirection: 'row',
    height: 50,
    position: 'relative'
  },
  scheduleBoxWrapWeekly: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    '& $scheduleBox': {
      position: 'static',
      marginLeft: 5,
      marginRight: 0
    },
    '& $scheduleBox:last-child': {
      marginRight: 5
    }
  },
  scheduleBox: {
    background: '#EFF5FC',
    boxSizing: 'border-box',
    borderRadius: '6px',
    height: 40,
    margin: 5,
    display: 'flex',
    position: 'absolute',
    border: '1px solid #ccc',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    transition: 'left 1s',
    '& $labelOne': {
      fontSize: 11,
      fontWeight: 700,
      lineHeight: '12px',
      color: 'rgba(0,0,0,.6)'
    },
    '& $labelTwo': {
      fontSize: 10,
      lineHeight: '16px',
      color: 'rgba(0,0,0,.6)'
    }
  },
  labelOne: {},
  labelTwo: {},
  position_1: {
    left: 0
  },
  position_2: {
    left: '25%',
    transition: 'left 1s'
  },
  position_3: {
    left: '50%'
  },
  position_4: {
    left: '75%'
  },
  size_1: {
    width: 'calc(25% - 10px)'
  },
  size_2: {
    width: 'calc(50% - 10px)'
  },
  size_3: {
    width: 'calc(75% - 10px)'
  },
  size_4: {
    width: 'calc(100% - 10px)'
  },
  color_1: {
    backgroundColor: '#EFF5FC',
    borderColor: '#BFDDFF'
  },
  color_2: {
    backgroundColor: '#ACE1CE',
    borderColor: '#94CFBA'
  },
  color_3: {
    backgroundColor: '#F9EEEE',
    borderColor: '#EFD5D5'
  },
  color_4: {
    backgroundColor: '#FFFAE9',
    borderColor: '#E4DFCF'
  },
  color_5: {
    backgroundColor: '#f1f1f1',
    borderColor: '#c8c8c8',
    cursor: 'default',
    opacity: 0.7
  },
  labelRoot: {
    color: '#ccc',
    backgroundColor: 'transparent'
  },
  labelFocused: {
    backgroundColor: theme.palette.primary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },

  textField: {
    fontSize: 12,
    borderColor: '#ff0000',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: 1,
    '&::placeholder': {
      color: 'blue',
      fontSize: 10
    },
    '& + & ': {
      marginTop: theme.spacing(2)
    },
    '&:focus $notchedOutline': {
      borderColor: 'green'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC',
      borderRadius: 1
    },
    '& input': {
      fontSize: 14
    },
    '& select': {
      fontSize: 14
    },
    '& textarea': {
      fontSize: 14
    }
  },
  rightIconGroup: {
    margin: -6,
    '& button': {
      marginLeft: 2
    }
  },
  leftIcon: {
    padding: 7,
    backgroundColor: '#f9f9f9',
    color: 'rgb(128, 128, 128)',
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 5
  },
  disabledRow: {
    '& td': {
      backgroundColor: '#eee',
      color: '#929292',
      '& p': {
        color: '#929292'
      }
    }
  },
  emptyWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20vh',
    flexDirection: 'column',
    '& p': {
      color: '#82C3FF',
      fontSize: 16
    }
  },
  tableScrollBody: {
    display: 'block',
    height: 'calc(90vh - 161px)'
    // overflow: 'auto',
    // '&::-webkit-scrollbar': {
    //   width: 6
    // },
    // '&::-webkit-scrollbar-track': {
    //   backgroundColor: 'white'
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   backgroundColor: '#dedede',
    //   borderRadius: 3
    // }
  }
});
