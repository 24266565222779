export default (state = {}, action) => {
  switch (action.type) {
    // case 'EHR_GET':
    //   const {
    //     content,
    //     totalPages,
    //     totalElements,
    //     last,
    //     size,
    //     sort,
    //     first,
    //     numberOfElements
    //   } = action.data;
    //   return {
    //     ...state,
    //     search: {
    //       result: copyArray(content),
    //       totalPages,
    //       totalElements,
    //       last,
    //       size,
    //       sort,
    //       first,
    //       numberOfElements
    //     }
    //   };
    case 'PHYPTISSUES_GET':
      return {
        ...state,
        allPhyPtIssues: { result: (action.data) }
      };
    case 'PHYPTISSUES_GET_DATE':
      return {
        ...state,
        allPhyPtIssuesofDate: { result: (action.data) }
      };

    default:
      return state;
  }
};

const copyArray = items => {
  return items.map(item => {
    return Object.assign({}, item);
  });
};
