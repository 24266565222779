import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../../style';

import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  Hidden
} from '@material-ui/core';
import { connect } from 'react-redux';
import MessageBox from 'util/messageBox';
import validate from 'validate.js';
import schema from './schema';
import { resetpassword } from 'services/user/action';
import { Prompt } from 'react-router-dom';
const Create = props => {
  const { classes, className, ehrID, userID, username, ...rest } = props;
  let [isBlocking, setIsBlocking] = useState(false);
  const [values, setValues] = useState({
    id: 0,
    username: '',
    password: '',
    passwordconfirmation: '',
    succMsg: false,
    touched: {},
    errors: {},
    isValid: false
  });

  const hasError = field =>
    values &&
      values.touched &&
      values.errors &&
      values.touched[field] &&
      values.errors[field]
      ? true
      : false;

  const handleFieldChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
    if (values) {
      setIsBlocking(true);
    }
  };

  const handleCloseAll = () => {
    setValues({ ...values, succMsg: false });
  };

  const validateForm = () => {
    const newState = { ...values };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
    return newState.isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setValues({ ...values, isLoading: true });
      props
        .resetpassword(ehrID, userID, { password: btoa(values.password) })
        .then(status => {
          if (status) {
            setValues({ ...values, succMsg: true });
          } else {
            setValues({ ...values, isLoading: false });
          }
        });
    }
    setIsBlocking(false);
  };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <Prompt
        when={isBlocking}
        message="There are unsaved changes, do you wish to discard them?"
      />
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.cardHeader}
          style={{ borderBottom: '1px solid #f1f1f1' }}>
          <Typography variant="h2">Password Settings</Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              disabled
              fullWidth
              label="Username"
              name="uName"
              required
              variant="outlined"
              color="Primary"
              // error={hasError('username')}
              value={username}
            // onChange={event =>
            //   handleFieldChange('username', event.target.value)
            // }
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={6}></Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              type="password"
              label="New Password"
              name="npw"
              required
              variant="outlined"
              color="Primary"
              helperText={
                hasError('password') ? values.errors.password[0] : null
              }
              // error={hasError('password')}
              error={values.errors && values.errors.password}
              value={values.password}
              onChange={event =>
                handleFieldChange('password', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              type="password"
              label="Confirm New Password"
              name="cnpw"
              required
              variant="outlined"
              color="Primary"
              helperText={
                hasError('passwordconfirmation')
                  ? values.errors.passwordconfirmation[0]
                  : null
              }
              error={values.errors && values.errors.passwordconfirmation}
              value={values.passwordconfirmation}
              onChange={event =>
                handleFieldChange('passwordconfirmation', event.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <MessageBox
        open={values.succMsg}
        variant="success"
        onClose={handleCloseAll}
        message="Password has been changed successfully"
      />
    </Card>
  );
};

Create.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { resetpassword };
const mapStateToProps = state => ({
  titles: state.util.titles,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  username: state.profile && state.profile.name
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
