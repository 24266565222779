/* eslint-disable linebreak-style */
import { errorHandler } from '../error/action';
import http from 'services/http';

export const getUser = id => async dispatch => {
  try {
    const res = await http.get(`/ehr/${id}`);

    if (res) {
      dispatch({
        type: 'USER_GET',
        data: res
      });

      return { status: true, user: res };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const forgotUsername = (id, email) => async () => {
  try {
    const res = await http.get(`/ehr/${id}/forgotusername?email=${email}`);
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'This email is not associated with an account.';
    }
    errorHandler(error, message);
    return false;
  }
};

export const forgotPassword = (id, email, username) => async () => {
  try {
    const res = await http.get(
      `/ehr/${id}/forgotpassword?email=${email}&username=${username}`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Username or Email is incorrect';
    }
    errorHandler(error, message);
    return false;
  }
};

export const register = ehr => async dispatch => {
  try {
    const res = await http.post(`/register`, ehr);
    if (res) {
      dispatch({
        type: 'USER_REGISTER',
        data: res
      });
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Username already exist';
    }
    errorHandler(error, message);
    return false;
  }
};

export const addUser = (ehrID, userId, user) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/users?patientID=${userId}&roles=${user.accounttype}&isNew=true`,
      user
    );
    if (res) {
      dispatch({
        type: 'ACCOUNT_USER_REGISTER',
        data: res
      });
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Username already exist';
    }
    errorHandler(error, message);
    return false;
  }
};

export const getUserDetails = (ehrID, page, searchText = '', limit) => async dispatch => {
  try {
    const
      order = 'ASC',
      sortby = 'lastname';

    const res = await http.get(`/ehr/${ehrID}/users/?&page=${page}&limit=${limit}&order=${order}&search=${searchText}&sortby=${sortby}`);

    if (res) {
      dispatch({
        type: 'USERS',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteUser = (ehrID, userId) => async () => {
  try {
    const res = await http.delete(`/ehr/${ehrID}/users/${userId}`);
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};

export const deactivateUser = (ehrID, userId) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/deactivateaccount?userID=${userId}`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};

export const reactivateUser = (ehrID, userId) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/reactivateaccount?userID=${userId}`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'The user has not activated the account yet.';
    }
    errorHandler(error, message);
    return false;
  }
};

export const getProviderResource = (ehrID, userID) => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/providers/${userID}`);
    if (res) {
      dispatch({
        type: 'PROVIDER_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateUserProfile = (ehrID, userID, data) => async () => {
  try {
    const res = await http.post(`/ehr/${ehrID}/providers/${userID}`, data);

    if (res) {
      // dispatch({
      //   type: 'ORGANIZATION_GET',
      //   data: res.data
      // });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const resetpassword = (ehrID, userID, data) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/resetpassword?providerID=${userID}`,
      data
    );

    if (res) {
      // dispatch({
      //   type: 'ORGANIZATION_GET',
      //   data: res.data
      // });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const EHRresetpassword = token => async () => {
  try {
    const res = await http.get(`passwordresetNw?token=${token}`, { method: 'GET', redirect: 'follow' });//
    if (res) {
      return res;
    }
    return true;
  } catch (error) {
    console.log(error);
    if (error.status === 404) {
      window.location.href = '/';
      return;
    }
    errorHandler(error);
    return false;
  }
};

export const EHRresetpasswordsubmit = (ehrid, token, data) => async ehrID => {
  try {
    const res = await http.post(
      `ehr/${ehrid}/activateaccountnew?agree=true&token=${token}`,
      data
    );
    //return true;
    if (res) {
      return true;
    }

  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getUserByUsername = (ehrID, username) => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/getuser?username=${username}`);
    if (res) {
      return res;
    }

  } catch (error) {
    errorHandler(error);
    return false;
  }
};



export const activateAccount = (ehrID, token, data) => async () => {
  try {
    const res = await http.post(`/ehr/${ehrID}/activateaccount?agree=true&token=${token}`, data);

    if (res) {
      // dispatch({
      //   type: 'ORGANIZATION_GET',
      //   data: res.data
      // });

      return res;
    }
    return true;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateLicenseStatus = (user, userId, ehrID) => async () => {
  try {
    const res = await http.post(`/ehr/${ehrID}/user/${userId}/updateLicenseStatus`);

    if (res) {
      return true;
    }
    return true;
  } catch (error) {
    errorHandler(error);
    return false;
  }
}




