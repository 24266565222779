export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  textGrey: {
    color: '#a2a2a2'
  },
  textGreen: {
    color: theme.palette.success.main
  },
  tabWrap: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '0px 4px 4px 4px',
    background: '#fff',
    padding: 10
  },
  tabLabel: { marginTop: 20, marginLeft: 18, fontWeight: 600, marginBottom: 0 },
  templateWrap: {
    marginBottom: 10,
    flex: 1
  },
  templateHead: {
    background: '#EFF5FC',
    border: '1px solid #D4E5F9',
    fontWeight: 600,
    fontSize: 14,
    padding: 16,
    '& p': {
      fontSize: 14,
      fontWeight: 600
    }
  },
  textBox: {
    width: '100%',
    '& input': {
      padding: '7px 10px;',
      '&::placeholder': {
        fontFamily: 'Lato',
        fontSize: 12,
        color: '#B9B9B9',
        fontWeight: 400
      }
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC'
    }
  },
  templateBody: {
    border: '1px solid #D4E5F9',
    borderTop: 'none'
  },
  templateTable: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '& td': {
      padding: '8px 10px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px'
    },
    '& th': {
      padding: '8px 10px'
    },
    '& td a': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline'
      },
      '&:active': {
        color: theme.palette.primary.main
      }
    }
  },
  icdLeft: {
    flexBasis: '44.66%',
    maxWidth: '44.66%'
  },
  icdRight: {
    flexBasis: '44.66%',
    maxWidth: '44.66%'
  },
  icdCtrls: {
    border: '1px solid #D4E5F9',
    height: 'calc(100% - 10px)',
    bottom: 17,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20
  },
  icdCtrlBtn1: {
    backgroundColor: '#03A484',
    marginBottom: 20,
    maxWidth: 65,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      padding: 5,
      minWidth: 20
    },
    '&:hover': {
      backgroundColor: '#0dbb99'
    },
    '& svg': {
      color: '#fff'
    }
  },
  icdCtrlBtn2: {
    backgroundColor: '#B9B9B9',
    marginBottom: 20,
    maxWidth: 65,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      padding: 5,
      minWidth: 20
    },
    '& svg': {
      color: '#fff'
    }
  },
  icdCtrlBtn3: {
    maxWidth: 65,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      padding: 5,
      minWidth: 20
    }
  },
  icdCenter: {
    flexBasis: '10.66%',
    maxWidth: '10.66%'
  },
  fullWidth: {
    width: '100%'
  },
  addButton: {
    minWidth: '20px',
    padding: '5px 10px',
    marginLeft: '-10px'
  },
  multiSel: {
    width: '100%',
    '&::before': {
      borderColor: 'transparent!important'
    },
    '& select': {
      overflow: 'auto',
      height: 'calc(100vh - 400px)!important',
      overflowX: 'hidden',
      '&:focus': {
        backgroundColor: 'transparent'
      },
      '& option': {
        padding: '5px 0',
        fontSize: 14,
        fontFamily: 'Lato'
      },
      '&::-webkit-scrollbar': {
        width: 6
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'white'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dedede',
        borderRadius: 3
      }
    },
  },
  underline: {
    borderBottom: '2px solid transparent',
    '&:after': {
      borderBottom: '2px solid transparent'
    }
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalBody: {
    padding: '10px 0'
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalFooter: {
    padding: '20px 0px 10px 0px',
    textAlign: 'right'
  },
  generalTable: {
    '& td': {
      padding: '8px 5px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px',
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    '& th': {
      padding: '8px 5px',
      fontWeight: 600,
      background: 'transparent',
      borderBottom: '1px solid #a2a2a2'
    },
    '& thead': {
      backgroundColor: 'transparent'
    }
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  letterFont: {
    fontFamily: 'Arial',
    fontSize: 13.3333
  },
});
