import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import empty from '../../../assets/images/empty.svg';

const EmptyState = props => {
  const { classes } = props;
  return (
    <Box className={classes.emptyWrap}>
      <img src={empty} />
      <Typography>No appointments to display.</Typography>
    </Box>
  );
};

EmptyState.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(EmptyState));
