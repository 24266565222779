export default (state = null, action) => {
  switch (action.type) {

    case "LOGIN_SUCCESS":
      const { name, roles, userID, ehrID, firstname, lastname, title, role, expirydate, licensetype, licensestatus } = action.data

      return {
        ...state,
        name, roles, userID, ehrID, firstname, lastname, title, role, expirydate, licensetype, licensestatus
      };

    case "LOGIN_FAILURE":
      return null;

    case "LOGOUT":
      return {};

    case 'UPDATE_LICENSE':
      return {
        ...state,
         expirydate: action.data.expiryDay, licensestatus: action.data.licenseStatus, licensetype: action.data.licenseType
      };

    default:
      return state;
  }
};
