/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Button,
  Grid,
  CircularProgress} from '@material-ui/core';
import {
  DeleteOutlineOutlined,
  Check,
  CancelOutlined,
  AddCircleOutline,
  FileCopyOutlined,
  DoubleArrowOutlined
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  getUserCPTCodes,
  getSystemCPTCodes,
  addSystemCPTCode,
  deleteUserCPTCode,
  addUserCPTCode
} from 'services/cpt/action';
import {
  getUserICD10Codes,
  getSystemICD10Codes,
  addSystemICD10Code,
  addUserICD10Code,
  deleteUserICD10Code
} from 'services/icd10/action';
import { savePastDiagnosis } from 'services/soap/action';
import schema from './schema';
import validate from 'validate.js';
import { saveTempData } from 'services/soap/action';

const UserDefined = props => {
  const {
    classes, 
    isCPT,
    userID,
    ehrID,
    fromLabOrder,
    isDxUpdate, 
  } = props;
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);

  const [cptUserCodeMasterList, setCPTUserCodeMasterList] = useState([]);
  const [icdUserCodeMasterList, setICDUserCodeMasterList] = useState([]);
  const [cptCodeMasterList, setCPTCodeMasterList] = useState([]);
  const [icdCodeMasterList, setICDCodeMasterList] = useState([]);

  const [cptCode, setCPTCode] = useState([]);
  const [icdCode, setICDCode] = useState([]);

  const [selectedVal, setSelectedVal] = useState([]);
  const [searchVal, setSearchVal] = useState('');

  const initialUserState = {
    code: '',
    description: '',
    keyid: userID,
    isValid: false,
    touched: {},
    errors: {}
  };
  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    if (isCPT) {
      loadUserCPT();
      loadSystemCPT();
    } else {
      loadUserICD10();
      loadSystemICD10();
    }
  }, [isCPT]);

  const loadUserICD10 = () => {
    setLoading(true);
    props.getUserICD10Codes(ehrID).then(res => {
      if (res && res.data.length > 0) {
        setICDCode(res.data);
        setICDUserCodeMasterList(res.data);
        setLoading(false);
      }
    });
  };

  const loadUserCPT = () => {
    setLoading(true);
    props.getUserCPTCodes(ehrID).then(res => {
      if (res && res.data.length > 0) {
        setCPTCode(res.data);
        setCPTUserCodeMasterList(res.data);
        setLoading(false);
      }
    });
  };

  const loadSystemICD10 = () => {
    setLoading(true);
    props.getSystemICD10Codes(ehrID).then(res => {
      if (res && res.data.length > 0) {
        if (icdCode.length === 0) {
          setICDCode(res.data);
          setLoading(false);
        }

        setICDCodeMasterList(res.data);
      }
    });
  };

  const loadSystemCPT = () => {
    setLoading(true);
    props.getSystemCPTCodes(ehrID).then(res => {
      if (res && res.data.length > 0) {
        if (cptCode.length === 0) {
          setCPTCode(res.data);
          setLoading(false);
        }

        setCPTCodeMasterList(res.data);
      }
    });
  };

  const handleChange = async (field, value) => {
    if (field === 'searchVal') {
      setSearchVal(value);
      let searchResult = null;
      if (isCPT) {
        if (value) {
          searchResult = findSearchVals(cptUserCodeMasterList, value);
          if (searchResult.length === 0) {
            searchResult = findSearchVals(cptCodeMasterList, value);
          }
          setCPTCode(searchResult);
        } else {
          setCPTCode(cptUserCodeMasterList);
        }
      } else {
        if (value) {
          searchResult = findSearchVals(icdUserCodeMasterList, value);
          if (searchResult.length === 0) {
            searchResult = findSearchVals(icdCodeMasterList, value);
          }
          setICDCode(searchResult);
        } else {
          setICDCode(icdUserCodeMasterList);
        }
      }
    } else {
      const newState = { ...values };
      newState[field] = value;
      newState.touched[field] = true;
      const errors = validate(newState, schema);
      newState.errors = errors || {};
      newState.isValid = errors ? false : true;
      setValues(newState);
    }
  };

  const findSearchVals = (items, text) => {
    text = text && text.toLowerCase().split(' ');
    return (
      items &&
      items.filter(function (item) {
        return (
          text &&
          text.every(function (el) {
            return (
              (item.code && item.code.toLowerCase().indexOf(el) > -1) ||
              (item.description &&
                item.description.toLowerCase().indexOf(el) > -1)
            );
          })
        );
      })
    );
  };

  const handleSelected = (value, isSearchVal = false) => {
    const newState = [...selectedVal];
    if (isSearchVal) {
      let isSame =
        newState &&
        newState.length > 0 &&
        newState.filter(c => c.description === value);
      if (!isSame || (isSame && isSame.length === 0)) {
        newState.push({
          description: value,
          codeType: isCPT ? 'cpt' : 'diagnosis'
        });
      }
    } else {
      let isExist =
        newState &&
        newState.length > 0 &&
        newState.filter(c => c.id === value.id);
      if (!isExist) {
        newState.push({
          code: value.code,
          description: value.description,
          codeType: isCPT ? 'cpt' : 'diagnosis'
        });
      } else if (isExist && isExist.length === 0) {
        newState.push({
          code: value.code,
          description: value.description,
          codeType: isCPT ? 'cpt' : 'diagnosis'
        });
      }
    }
    setSelectedVal(newState);
  };

  const handleListCPTICD = async () => {

    if (fromLabOrder) {
      props.handleList('labOrderDiagnosis', selectedVal);
    } else if (isDxUpdate) {
      if (isCPT) {
        const res = await props.addUserCPTCode(selectedVal);
        if (res) {
          // const cptState = res.data;
          if (selectedVal && selectedVal.length > 0) {
            let newState = [];
            selectedVal.map(item => {
              let newItem = Object.assign({}, item);
              newItem.codeType = 'cpt';
              newState.push(newItem);
            });

            props.handleSaveDiagnosis(newState);
          }
        }
      } else {
        const res = await props.addUserICD10Code(selectedVal);
        if (res) {
          let newState;
          if (selectedVal && selectedVal.length > 0) {
            newState = [];
            selectedVal.map(item => {
              let newItem = Object.assign({}, item);
              newItem.codeType = 'diagnosis';
              newState.push(newItem);
            });
            props.handleSaveDiagnosis(newState);
          }
        }
      }
    } else {
      let val;
      if (isCPT) {
        props.handleFieldChange('cptList', selectedVal);
        val = props.getValuesforCopy(false, '', '', '', '', selectedVal,);
      } else {
        props.handleFieldChange('diagnosisList', selectedVal);
        val = props.getValuesforCopy(false, '', '', '', '', '', selectedVal);
      }
      props.saveTempData(val);
    }
    props.onCancel();
  };

  const add = async () => {
    const codes = [];
    codes.push({
      code: values.code,
      description: values.description,
      keyid: values.keyid
    });
    if (values.isValid) {
      if (isCPT) {
        await props.addUserCPTCode(codes).then(res => {
          if (res) {
            setEdit();
            props.getUserCPTCodes().then(res => {
              if (res && res.data.length > 0) {
                setCPTUserCodeMasterList(res.data);
                if (searchVal === '') {
                  setCPTCode(res.data);
                } else {
                  let searchResult = null;
                  searchResult = findSearchVals(res.data, searchVal);
                  setCPTCode(searchResult);
                }
              }
            });
            setValues(initialUserState);
          }
        });
        await props.addSystemCPTCode(ehrID, codes).then(res => {
          if (res) {
            setEdit();
            props.getSystemCPTCodes(ehrID).then(res => {
              if (res && res.data.length > 0) {
                setCPTUserCodeMasterList(res.data);
              }
            });
          }
        });
      } else {
        await props.addUserICD10Code(codes).then(res => {
          if (res) {
            setEdit();
            props.getUserICD10Codes().then(res => {
              if (res && res.data.length > 0) {
                setICDUserCodeMasterList(res.data);
                if (searchVal === '') {
                  setICDCode(res.data);
                } else {
                  let searchResult = null;
                  searchResult = findSearchVals(res.data, searchVal);
                  setICDCode(searchResult);
                }
              }
            });
            setValues(initialUserState);
          }
        });

        await props.addSystemICD10Code(ehrID, codes).then(res => {
          if (res) {
            setEdit();
            props.getSystemICD10Codes(ehrID).then(res => {
              if (res && res.data.length > 0) {
                setICDUserCodeMasterList(res.data);
              }
            });
          }
        });
      }
    }
  };

  const setEdit = () => {
    setIsEdit(!isEdit);
  };

  const removeSelectedVal = idx => {
    let newVals = [...selectedVal];
    newVals.splice(idx, 1);
    setSelectedVal(newVals);
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        {loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              zIndex: 1000000,
              marginTop: '20%'
            }}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <div className={classes.templateWrap}>
            <div className={classes.templateBody}>
              <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
                <Table
                  className={classes.templateTable}
                  style={{ overflow: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '100%' }}>
                        <TextField
                          className={[classes.textBox, classes.fullWidth]}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleChange('searchVal', event.target.value)
                          }
                          placeholder="Search user codes and descriptions"
                          value={searchVal}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap' }}>
                        {!isEdit ? (
                          <>
                            {searchVal &&
                              ((cptCode && cptCode.length === 0) ||
                                (icdCode && icdCode.length === 0)) && (
                                <Button
                                  className={classes.addButton}
                                  color="primary"
                                  onClick={() =>
                                    handleSelected(searchVal, true)
                                  }
                                  size="small"
                                  style={{ marginRight: 5 }}
                                  variant="contained">
                                  <DoubleArrowOutlined />
                                </Button>
                              )}

                            <Button
                              className={classes.addButton}
                              color="primary"
                              onClick={() => setEdit()}
                              size="small"
                              variant="contained">
                              <AddCircleOutline />
                            </Button>
                          </>
                        ) : (
                          <Button
                            className={classes.addButton}
                            color="primary"
                            onClick={() => setEdit()}
                            size="small"
                            style={{ backgroundColor: '#B9B9B9' }}
                            variant="contained">
                            <CancelOutlined />
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {isEdit ? (
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ width: '100%', whiteSpace: 'nowrap' }}>
                          <TextField
                            className={classes.textBox}
                            id="outlined-basic"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline
                              }
                            }}
                            onChange={event =>
                              handleChange('code', event.target.value)
                            }
                            placeholder="Input Code"
                            style={{ width: 80 }}
                            value={values.code}
                            variant="outlined"
                          />
                          <TextField
                            className={classes.textBox}
                            id="outlined-basic"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline
                              }
                            }}
                            onChange={event =>
                              handleChange('description', event.target.value)
                            }
                            placeholder="Input Description"
                            style={{
                              width: 'calc(100% - 85px)',
                              marginLeft: 5
                            }}
                            value={values.description}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: 'nowrap' }}>
                          <IconButton
                            color="primary"
                            disabled={!values.isValid}
                            onClick={() => {
                              add();
                            }}
                            size="small"
                            style={{ marginLeft: -15, color: '#03A484' }}>
                            <Check />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : null}

                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        style={{
                          borderColor: 'transparent',
                          height: 'calc(100vh - 400px)'
                        }}>
                        <PerfectScrollbar
                          style={{
                            height: 'calc(100vh - 400px)'
                          }}>
                          <ul className={classes.cptList}>
                            {isCPT
                              ? cptCode &&
                              cptCode.length > 0 &&
                              cptCode.map(item => (
                                <li key={item.id} value={item.id}>
                                  <IconButton
                                    onClick={() => handleSelected(item)}
                                    size="small" color="primary">
                                    <FileCopyOutlined />
                                  </IconButton>
                                  {item.code} {item.description}
                                </li>
                              ))
                              : icdCode &&
                              icdCode.length > 0 &&
                              icdCode.map(item => (
                                <li key={item.id} value={item.id}>
                                  <IconButton
                                    onClick={() => handleSelected(item)}
                                    size="small" color="primary">
                                    <FileCopyOutlined />
                                  </IconButton>
                                  {item.code} {item.description}
                                </li>
                              ))}
                          </ul>
                        </PerfectScrollbar>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        )}
      </Grid>

      <Grid item md={6} xs={12}>
        <div className={classes.templateWrap}>
          <div className={classes.templateBody}>
            <div style={{ maxHeight: 'calc(100vh - 298px)', }}>
              <Table
                className={classes.templateTable}
                style={{ overflow: 'hidden' }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '100%', height: 49 }}>
                      <b>Selected {isCPT ? 'CPT' : 'Diagnosis'}</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      style={{
                        borderColor: 'transparent',
                        height: 'calc(100vh - 400px)'
                      }}>
                      <PerfectScrollbar
                        style={{
                          height: 'calc(100vh - 400px)'
                        }}>
                        <ul className={classes.cptListRightIcon}>
                          {selectedVal && selectedVal.length > 0
                            ? selectedVal.map((val, idx) => (
                              <li>
                                {val.code ? val.code : ''}{' '}
                                {val.description ? val.description : ''}
                                <IconButton
                                  onClick={() => {
                                    removeSelectedVal(idx);
                                  }}
                                  size="small">
                                  <DeleteOutlineOutlined />
                                </IconButton>
                              </li>
                            ))
                            : ''}
                        </ul>
                      </PerfectScrollbar>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </Grid>
      <Grid className={classes.modalFooter} item xs={12}>
        <Button onClick={props.onCancel} size="small" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => handleListCPTICD()}
          size="small"
          style={{ margin: '0 10px' }}
          variant="contained">
          Save
        </Button>
      </Grid>
    </Grid>
  );
};
UserDefined.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  getUserICD10Codes,
  getSystemICD10Codes,
  addSystemCPTCode,
  deleteUserICD10Code,
  getUserCPTCodes,
  getSystemCPTCodes,
  addSystemICD10Code,
  addUserICD10Code,
  deleteUserCPTCode,
  addUserCPTCode,
  savePastDiagnosis,
  saveTempData
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  userICD10: state.providers && state.icd10.userICD10,
  systemICD10: state.providers && state.cpt && state.cpt.systemICD10,
  userCPT: state.providers && state.cpt && state.cpt.userCPT,
  systemCPT: state.providers && state.cpt && state.cpt.systemCPT
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(UserDefined));
