import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import logo from '../../assets/images/logo.png';
import schema from './schema';
import MessageBox from 'util/messageBox';
import {
  Grid,
  Button,
  Box,
  TextField,
  Link,
  Typography,
  withStyles,
  AppBar,
  Toolbar,
  InputBase,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import {
  AddOutlined,
  SearchOutlined,
  Visibility,
  VisibilityOff
} from '@material-ui/icons';
import styles from './style';

import { login } from 'services/profile/action';
import { search } from 'services/ehr/action';
import { connect } from 'react-redux';

const SignIn = props => {
  const { classes } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    showPassword: false,
    isLoading: false
  });

  const [search, setSearch] = useState(null);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSearchChange = (_field, value) => {
    setSearch(value);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  //#region error
  const [errmsg, setErrMsg] = useState({ err: false, errMsg: '' });

  // const showErrorMsg = () => {
  //   setErrMsg({ err: true, msg: '' });
  // };

  const renderError = () => {
    return (
      <MessageBox
        message={errmsg.msg}
        onClose={handleClose}
        open
        variant="error"
      />
    );
  };

  const handleClose = () => {
    setErrMsg({ err: false });
  };

  //#endregion

  const handleSubmit = async () => {
    const { values } = formState;
    if (formState.isValid) {
      setFormState({ ...formState, isLoading: true });
      const status = await props.login(
        values.username,
        values.password,
        true,
        false
      );
      if (status) {
        props.history.push('/default');
      } else {
        setErrMsg({ err: true, msg: 'Username or password is incorrect.' });
      }
      setFormState({ ...formState, isLoading: false });
    }
  };

  const showForgotPwd = () => {
    props.history.push('/forgot/:pw?');
  };

  const showForgotUsername = () => {
    props.history.push('/forgot');
  };

  const showPatientLogin = () => {
    props.history.push('/pt-login');
  };

  const handleClickShowPassword = () => {
    setFormState({ ...formState, showPassword: !formState.showPassword });
  };

  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const _handleSearchKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    //  if (search !== null && search.length) {
    props.history.push({
      pathname: '/searchclinic',
      state: { search: search }
    });
    //  }
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.headerWrap} position="relative">
        <Toolbar style={{ padding: 0 }}>
          <Box className={classes.naviWrap}>
            <Box>
              <RouterLink to="/">
                <img alt="Logo" src={logo} className={classes.logoImg} />
              </RouterLink>
            </Box>
            <Box className={classes.rightCol}>
              <Box className={classes.searchWrap}>
                <InputBase
                  className={classes.searchInput}
                  value={search}
                  placeholder="Search clinic"
                  label="search"
                  name="search"
                  onKeyPress={_handleSearchKeyDown}
                  onChange={event =>
                    handleSearchChange('search', event.target.value)
                  }
                />
                <IconButton
                  className={classes.searchBtn}
                  onClick={() => {
                    handleSearch();
                  }}>
                  <SearchOutlined />
                </IconButton>
              </Box>
              <Button
                className={classes.loginButton}
                color="secondary"
                variant="contained"
                component={RouterLink}
                to="/sign-up"
                startIcon={<AddOutlined />}>
                Clinic Account
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid className={classes.grid} container style={{ marginTop: '10vh' }}>
        <Grid className={classes.quoteContainer} item md={7} xs={12}>
          <div className={classes.quote}>
            <Typography className={classes.quoteText} variant="h1">
              Electronic Health Record of Tomorrow{' '}
              <span className={classes.line}></span>
            </Typography>
            <div className={classes.person}>
              <Typography className={classes.name} variant="body1">
                Created by Doctor, Built for Doctor
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.contentSignin} item md={5} xs={12}>
          <div className={classes.contentBody}>
            <form className={classes.form}>
              <Typography className={classes.title} variant="h2">
                PROVIDER PORTAL
              </Typography>
              <TextField
                className={classes.textField}
                InputProps={{
                  classes: {
                    root: classes.inputOutlined,
                    focused: classes.inputFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                error={hasError('username')}
                fullWidth
                helperText={
                  hasError('username') ? formState.errors.username[0] : null
                }
                label="Username"
                name="username"
                onChange={handleChange}
                type="text"
                value={formState.values.username || ''}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                InputProps={{
                  classes: {
                    root: classes.inputOutlined,
                    focused: classes.inputFocused,
                    notchedOutline: classes.notchedOutline
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}>
                        {formState.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                error={hasError('password')}
                fullWidth
                helperText={
                  hasError('password') ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                type={formState.showPassword ? 'text' : 'password'}
                value={formState.values.password || ''}
                variant="outlined"
                onKeyPress={_handleKeyDown}
              />
              <Typography className={classes.forgot}>
                <Link
                  component={RouterLink}
                  onClick={showForgotUsername}
                  className={classes.forgotTxt}>
                  Forgot Username
                </Link>
                <Link className={classes.forgotTxt}>
                  &nbsp; &nbsp; | &nbsp; &nbsp;
                </Link>
                <Link
                  component={RouterLink}
                  onClick={showForgotPwd}
                  className={classes.forgotTxt}>
                  Forgot Password?
                </Link>
              </Typography>
              <Grid container>
                <Grid
                  item
                  md={8}
                  sm={8}
                  xs={12}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption">
                    Are you a patient? &nbsp;
                    <Link component={RouterLink} onClick={showPatientLogin}>
                      Patient Portal
                    </Link>
                  </Typography>
                </Grid>
                {formState.isLoading ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  <Grid item md={4} sm={4} xs={12}>
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      onClick={handleSubmit}
                      type="submit"
                      variant="contained">
                      Login
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
          {errmsg.err && renderError()}
        </Grid>
      </Grid>
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  login,
  search
};

const mapStateToProps = state => ({
  searchsss: state.search
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignIn))
);
