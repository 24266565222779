import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import styles from '../../../SignIn/style';
import {
  Grid,
  TextField,
  Typography,
  withStyles,
  Slide
} from '@material-ui/core';
import validate from "validate.js";
import schema from "./schema";
import { connect } from "react-redux";
import { getStates, getTitles } from "services/util/action"

const StepOne = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      validateForm
    };
  });

  const [values, setValues] = useState({
    firstname: "",
    lastname: "",
    organizationname: "",
    websitename: "",
    businessphone: "",
    title: "",
    emailaddress: "",
    address: "",
    city: "",
    state: "",
    zip: ""

  });
  const [errors, setErrors] = useState({
    firstname: null,
    lastname: null,
    emailaddress: null,
  });
  const [, setValid] = useState(false);

  const validateForm = () => {
    let errors = validate(values, schema);
    setErrors(errors || {});
    let valid = errors ? false : true;
    setValid(valid);
    return { valid: valid, data: values };
  }

  const handleFieldChange = (field, value) => {
    const newState = { ...values };
    const regex = /^[0-9\b]+$/;
    if ((field === "businessphone" || field === "zip") && value && !regex.test(value)) {
      value = newState[field] || "";
    }
    setValues({ ...values, [field]: value });
  };

  useEffect(() => {
    props.getStates();
    props.getTitles();
    if (props.datas && (Object.keys(props.datas).length !== 0) && props.isback) {
      setValues(props.datas);
    }


  }, []);


  const { classes, states, titles } = props;

  return ( 
      <div>
        <Typography className={classes.formSubTitle}>
          Step 1 - Account Owner Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="First Name"
              name="firstname"
              required
              variant="outlined"
              color="secondary"
              error={errors.firstname}
              onChange={event =>
                handleFieldChange("firstname", event.target.value)
              }
              value={values.firstname}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              color="secondary"
              label="Last name"
              name="lastname"
              required
              variant="outlined"
              error={errors.lastname}
              onChange={event =>
                handleFieldChange("lastname", event.target.value)
              }
              value={values.lastname}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              //label="Title"
              name="title"
              //onChange={handleChange}
              select
              SelectProps={{ native: true }}
              //value={values.state}
              variant="outlined"

              onChange={event =>
                handleFieldChange("title", event.target.value)
              }
              value={values.title}>
              {
                titles &&
                titles.map((item) => (
                  <option value={item.value}>
                    {item.key}
                  </option>
                ))
              }
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Organization Name"
              name="organizationname"
              variant="outlined"
              onChange={event =>
                handleFieldChange("organizationname", event.target.value)
              }
              value={values.organizationname}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Organization Website"
              name="websitename"
              variant="outlined"

              onChange={event =>
                handleFieldChange("websitename", event.target.value)
              }
              value={values.websitename}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Business Phone"
              name="businessphone"
              variant="outlined"
              onChange={event =>
                handleFieldChange("businessphone", event.target.value)
              }
              value={values.businessphone}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Email Address"
              name="emailaddress"
              required
              variant="outlined"
              error={errors.emailaddress}
              onChange={event =>
                handleFieldChange("emailaddress", event.target.value)
              }
              value={values.emailaddress}
 helperText={
  errors.emailaddress ? errors.emailaddress[0] : null
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Address"
              name="address"
              variant="outlined"
              onChange={event =>
                handleFieldChange("address", event.target.value)
              }
              value={values.address}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="City"
              name="city"
              variant="outlined"
              onChange={event =>
                handleFieldChange("city", event.target.value)
              }
              value={values.city}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              //label="Select State"
              name="state"
              select
              SelectProps={{ native: true }}
              variant="outlined"
              value={values.state}

              onChange={event =>
                handleFieldChange("state", event.target.value)
              }>

              {
                states &&
                states.map((item) => (
                  <option value={item.value}>
                    {item.key}
                  </option>
                ))
              }

            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Zip"
              name="zip"
              variant="outlined"
              onChange={event =>
                handleFieldChange("zip", event.target.value)
              }
              value={values.zip}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
        </Grid>
      </div> 
  );
});

StepOne.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getStates, getTitles
};
const mapStateToProps = state => ({
  titles: state.util.titles,
  states: state.util.states
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(StepOne));
