export default (state = {}, action) => {
  switch (action.type) {
    case 'USER_CPT':
      return {
        ...state,
        userCPT: action.data
      };

    case 'SYSTEM_CPT':
      return {
        ...state,
        systemCPT: action.data
      };

    default:
      return state;
  }
};
