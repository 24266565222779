import _ from 'lodash';
export default (state = {}, action) => {
  switch (action.type) {
    case 'CASEFILES_GET':
      const {
        content,
        totalPages,
        totalElements,
        last,
        size,
        sort,
        first,
        numberOfElements
      } = action.data;
      return {
        ...state,
        list: {
          result: copyArray(content),
          totalPages,
          totalElements,
          last,
          size,
          sort,
          first,
          numberOfElements
        }
      };
    case 'DOCUMENT_GET':
      return {
        ...state,
        documentInfo: action.data
      };
    case 'MISC_DOCUMENT_GET':
      return {
        ...state,
        miscDocumentInfo: action.data
      };
    case 'SELECTED_PATIENT_GET':
      return {
        ...state,
        patientInfo: action.data
      };
    case 'MHx_SELECTED_PATIENT_GET':
      return {
        ...state,
        getMHx: action.data
      };
    case 'REMARKS_GET':
      return {
        ...state,
        documentInfo: action.data
      };
    case 'ALL_DOCUMENTS_GET':
      return {
        ...state,
        documentsList: action.data
      };
    case 'RECAPS_GET':
      return {
        ...state,
        recapsData: action.data
      };
      // case 'PATIENT_CHART_DETAILS':
      //   return {
      //     ...state,
      //     patientChartDetails: action.data
      //   };
    case 'PATIENT_CHART_DETAILS':
      let ptpastSoapnote =!action.data.clearData && state.patientCharts ? [...state.patientCharts, ...action.data.chart.content]:action.data.chart.content;
      return {  
        ...state,   
        patientCharts:   _.uniqBy(ptpastSoapnote, function (e) {
          return e.id;
        })
      }; 

      case 'PATIENT_CLINICAL_DOCUMENTS':
      return {
        ...state,
        clinicalDoc: action.data
      };

      case 'PATIENT_MISCELLANEOUS_DOCUMENTS':
      return {
        ...state,
        miscDoc: action.data
      };

    case 'PATIENT_DOCUMENTS_DETAILS_UPDATED':
      return {
        ...state,
        patientDocuments: {
          ...state.patientDocuments,
          [action.cat]: action.data
        }
      };

    case 'CATEGORIES':
      return {
        ...state,
        categories: action.data
      };
    case 'UNSIGNED_CHARTS':
      return {
        ...state,
        unsignedCharts: action.data
      };
    case 'RECAP_SOAP_NOTE_DETAILS':
      return {
        ...state,
        recapSoapDetails: action.data
      };
    case 'CHARTVIEW_SOAP_PATIENT_DETAILS':
      return {
        ...state,
        chartSoapPatientDetails: action.data
      };
      case 'CLEAR_PTDOC':
        return {
          ...state,
          chartSoapPatientDetails: [],
          patientCharts: [],
          clinicalDoc: null,
          miscDoc:  []
        };
    default:
      return state;
  }
};

const copyArray = items => {
  return items.map(item => {
    return Object.assign({}, item);
  });
};
