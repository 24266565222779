/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
import { errorHandler } from '../error/action';
import axios from 'axios';
import http from 'services/http';
import store from 'store';
import { Roles } from 'util/enum';
import _ from 'lodash';

const authenticate = async credentials => {
  const headers = credentials
    ? {
      authorization:
        'Basic ' + btoa(credentials.username + ':' + credentials.password),
      'X-Requested-By': 'X-Requested-By'
    }
    : {};
  return axios({
    method: 'get',
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/user',
    headers: headers,
    withCredentials: true
  }).then(function (response) {
    return response.data;
  });
};

export const login = (
  email,
  password,
  isPhysician,
  isPatient
) => async dispatch => {
  //use axios to authenticate
  try {
    const res = await authenticate({
      username: email,
      password: password
    });
    if (res) {
      if (res.userID) {
        if (isPatient && (res.role === Roles.provider || res.role === Roles.owner)) {
          return false;
        }
        if (isPhysician && res.role === Roles.patient) {
          return false;
        } else {
          dispatch({
            type: 'LOGIN_SUCCESS',
            data: res
          });
          return true;
        }
      }
    }
    return false;
  } catch (error) {
    let message;
    if (
      (error && error.response && error.response.status === 401) ||
      (error && error.response && error.response.status === 409)
    ) {
      message = 'Username or password is incorrect.';
    }
    if (error === 'Network Error') {
      message = '';
    }
    errorHandler(error, message);
    return false;
  }
};

export const isAlive = async () => {
  try {
    const res = await http.get('/user/isalive');
    if (res && !_.isEmpty(res.data)) {
      store.dispatch({
        type: 'LOGIN_SUCCESS',
        data: res.data
      });
      return true;
    }

    store.dispatch({
      type: 'LOGIN_FAILURE',
      data: null
    });
    return false;
  } catch (error) {
    store.dispatch({
      type: 'LOGIN_FAILURE',
      data: null
    });
    return false;
  }
};

export const logout = ehrID => async dispatch => {
  try {
    const res = await http.post(`/logout?${ehrID}`);
    dispatch({
      type: 'LOGOUT'
    });
    return true;
  } catch (error) {
    return false;
  }
};
export const changepassword = (ehrID, userID, data) => async () => {
  try {
    const res = await http.post(
      // `/ehr/${ehrID}/changepassword?providerID=${userID}`,

      `/ehr/${ehrID}/ptResetpassword?patientID=${userID}`,
      data
    );

    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const updateAnnualLicense = (ehrID, userId, data) => async dispatch => {
  try {
    const res = await http.post(`/ehr/${ehrID}/user/${userId}/annualLicense`, data);

    if (res) {
      return res.data;
    }
    return true;
  } catch (error) {
    errorHandler(error);
    return false;
  }
}
