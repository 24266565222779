import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Typography,
  TextField,
  IconButton,
  Box,
  Button,
  Modal,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableBody,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import {
  FirstPage,
  CloseOutlined,
  DescriptionOutlined,
  EditOutlined,
  DeleteOutlined,
  CalendarToday
} from '@material-ui/icons';
import styles from './../../../style';
import { VSHx, ApRx, ApLabOrders, MyTemplates, CPT, SoapPast } from './';
import {
  downloadPDFRx,
  downloadPDFLab,
  downloadPDFImage,
  downloadPDFPhysician,
  saveSOAPPatientData,
  savePrescription,
  savelabOrders,
  saveImagingOrders,
  savePhysicianOrders,
  saveDiagnosis,
  getPastSoapNoteById,
  deleteSoap,
  deleteDiagnosis,
  resetValue, clearSoap
} from 'services/soap/action';
import { connect } from 'react-redux';
import { getProviders } from 'services/util/action';
import MessageBox from 'util/messageBox';
import schema from './schemaEntry';
import validate from 'validate.js';
import { Roles } from 'util/enum';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { saveTempData } from 'services/soap/action';

const SoapEntry = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getValuesforCopy,
      saveTempData,
      loadCopyEditSoapDetails
    };
  });
  const {
    classes,
    soap,
    ehrID,
    userID,
    patientId,
    patientInfo,
    providers,
    role,
    user
  } = props;
  const [pros, setPros] = useState([]);
  const [succMsg, setSuccMsg] = useState(false);
  let [editSoap, setEditSoap] = useState([]);
  let [isCPT, setIsCPT] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isPast, setIsPast] = useState(false);

  const initialUserState = {
    id: 0,
    patientId: patientId,
    bloodpressure: '',
    temperature: '',
    heartrate: '',
    respiratoryrate: '',
    weight: '',
    bodymassindex: '',
    height: '',
    subjective: '',
    objective: '',
    assessmentplan: '',
    diagnosis: '',
    visitdate: new Date(),
    providerId: userID,
    signed: false,
    isValid: false,
    cptList: [],
    addendum: '',
    diagnosisList: [],
    medicationComment: '',
    touched: {},
    errors: {}
  };
  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    if (providers === '' || providers === null || providers === undefined) {
      props.getProviders(ehrID);
    }
  }, []);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providers.forEach(function (item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const loadCopyEditSoapDetails = (
    soap,
    isEdit = false,
    isCopy = false,
    isPast = false
  ) => {
    setIsPast(isPast);
    setIsCopy(isCopy);
    setIsEdit(isEdit);
    if (soap && soap.soap) {
      if (isEdit || isCopy) {
        if (isEdit) {
          if (soap.editSoap) {
            setEditSoap(soap.editSoap);
          } else {
            setEditSoap(soap.soap);
          }
        }
        let newState = { ...values };
        newState.patientId =
          soap.soap && soap.soap.patient && soap.soap.patient.id;
        newState.isCopyNeglect = soap.isCopyNeglect;
        if (!isCopy) {
          if (soap.soap && soap.soap.soapnote) {
            newState.bloodpressure = soap.soap.soapnote.bloodpressure;
            newState.temperature = soap.soap.soapnote.temperature;
            newState.heartrate = soap.soap.soapnote.heartrate;
            newState.respiratoryrate = soap.soap.soapnote.respiratoryrate;
            newState.weight = soap.soap.soapnote.weight;
            newState.bodymassindex = soap.soap.soapnote.bodymassindex;
            newState.height = soap.soap.soapnote.height;
          } else {
            newState.bloodpressure = soap.soap.bloodpressure;
            newState.temperature = soap.soap.temperature;
            newState.heartrate = soap.soap.heartrate;
            newState.respiratoryrate = soap.soap.respiratoryrate;
            newState.weight = soap.soap.weight;
            newState.bodymassindex = soap.soap.bodymassindex;
            newState.height = soap.soap.height;
          }
        } else if (soap.isCopyNeglect) {
          newState.bloodpressure = soap.soap.bloodpressure;
          newState.temperature = soap.soap.temperature;
          newState.heartrate = soap.soap.heartrate;
          newState.respiratoryrate = soap.soap.respiratoryrate;
          newState.weight = soap.soap.weight;
          newState.bodymassindex = soap.soap.bodymassindex;
          newState.height = soap.soap.height;
        }
        if (soap.soap && soap.soap.soapnote) {
          newState.id = isEdit ? soap.soap.soapnote.id : 0;
          newState.subjective = soap.soap.soapnote.subjective;
          newState.objective = soap.soap.soapnote.objective;
          newState.assessmentplan = soap.soap.soapnote.assessmentplan;
          newState.diagnosis = soap.soap.soapnote.diagnosis;
          newState.providerId = soap.soap.provider.id
            ? soap.soap.provider.id
            : userID;
          newState.signed = isEdit ? soap.soap.soapnote.signed : false;
          newState.visitdate = isEdit ? soap.soap.visitdate : new Date();
          newState.medicationComment = isEdit
            ? soap.soap.soapnote.medicationComment
            : '';
          if (
            soap.soap &&
            soap.soap.soapnote &&
            soap.soap.soapnote.medicationComment &&
            isEdit
          ) {
            setMedicationComment(soap.soap.soapnote.medicationComment);
          }
        } else {
          newState.id = isEdit ? soap.soap.id : 0;
          newState.subjective = soap.soap.subjective;
          newState.objective = soap.soap.objective;
          newState.assessmentplan = soap.soap.assessmentplan;
          newState.diagnosis = soap.soap.diagnosis;
          newState.providerId = soap.soap.providerId;
          newState.visitdate = soap.soap.visitdate;
          newState.medicationComment = soap && soap.soap && soap.soap.medicationComment;
          if (soap.soap && soap.soap.medicationComment) {
            setMedicationComment(soap.soap.medicationComment);
          }
        }
        if (isEdit) {
          if (
            soap &&
            soap.pastSoapNoteDetails &&
            soap.pastSoapNoteDetails.diagnosisList &&
            soap.pastSoapNoteDetails.diagnosisList.length > 0
          ) {
            newState.diagnosisList = soap.pastSoapNoteDetails.diagnosisList;
          }
          if (
            soap &&
            soap.pastSoapNoteDetails &&
            soap.pastSoapNoteDetails.cptList &&
            soap.pastSoapNoteDetails.cptList.length > 0
          ) {
            newState.cptList = soap.pastSoapNoteDetails.cptList;
          }
        } else {
          if (
            soap &&
            soap.pastSoapNoteDetails &&
            soap.pastSoapNoteDetails.diagnosisList &&
            soap.pastSoapNoteDetails.diagnosisList.length > 0
          ) {
            let diadata = [];
            soap.pastSoapNoteDetails.diagnosisList &&
              soap.pastSoapNoteDetails.diagnosisList.forEach(function (item) {
                diadata.push({
                  code: item.code,
                  description: item.description,
                  codeType: item.codeType
                });
              });
            newState.diagnosisList = diadata;
          }
          if (
            soap &&
            soap.pastSoapNoteDetails &&
            soap.pastSoapNoteDetails.cptList &&
            soap.pastSoapNoteDetails.cptList.length > 0
          ) {
            let cptdata = [];
            soap.pastSoapNoteDetails.cptList &&
              soap.pastSoapNoteDetails.cptList.forEach(function (item) {
                cptdata.push({
                  code: item.code,
                  description: item.description,
                  codeType: item.codeType
                });
              });
            newState.cptList = cptdata;
          }
        }
        setValues(newState);
      } else if (soap && soap.soap && !isEdit && !isCopy) {
        let newState = { ...values };
        let soapData = soap.soap;
        newState.patientId = soapData.patientId;
        if (!isCopy) {
          newState.bloodpressure = soapData.bloodpressure;
          newState.temperature = soapData.temperature;
          newState.heartrate = soapData.heartrate;
          newState.respiratoryrate = soapData.respiratoryrate;
          newState.weight = soapData.weight;
          newState.bodymassindex = soapData.bodymassindex;
          newState.height = soapData.height;
        }
        newState.subjective = soapData.subjective;
        newState.objective = soapData.objective;
        newState.assessmentplan = soapData.assessmentplan;
        newState.diagnosis = soapData.diagnosis;
        newState.providerId = soapData.providerId;
        newState.visitdate = soapData.visitdate;
        newState.medicationComment = soapData && soapData.medicationComment;
        if (soap.soap && soap.soap.medicationComment) {
          setMedicationComment(soap.soap.medicationComment);
        }
        if (
          soap.pastSoapNoteDetails.diagnosisList &&
          soap.pastSoapNoteDetails.diagnosisList.length > 0
        ) {
          newState.diagnosisList = soap.pastSoapNoteDetails.diagnosisList;
        }
        if (
          soap.pastSoapNoteDetails.cptList &&
          soap.pastSoapNoteDetails.cptList.length > 0
        ) {
          newState.cptList = soap.pastSoapNoteDetails.cptList;
        }
        setValues(newState);
      }
    }
    if (soap && soap.pastSoapNoteDetails) {
      if (soap.fromIsMHx) {
        if (soap.pastSoapNoteDetails.prescriptions) {
          setPrescriptions(soap.pastSoapNoteDetails.prescriptions);
        }

        if (
          (soap.pastSoapNoteDetails.labOrders &&
            soap.pastSoapNoteDetails.labOrders.length > 0) ||
          (soap.pastSoapNoteDetails.labOrders.labOrder &&
            soap.pastSoapNoteDetails.labOrders.labOrder.length > 0) ||
          (soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis.length > 0)
        ) {
          let data = [];
          data.labOrder = [];
          data.labOrderDiagnosis = [];
          soap.pastSoapNoteDetails.labOrders.labOrder &&
            soap.pastSoapNoteDetails.labOrders.labOrder.forEach(function (item) {
              data.labOrder.push({
                text: item.text
              });
            });
          soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis.forEach(
              function (item) {
                data.labOrderDiagnosis.push({
                  code: item.code,
                  description: item.description
                });
              }
            );
          setLabOrders(data);
        }
        if (
          (soap.pastSoapNoteDetails.imagingOrders &&
            soap.pastSoapNoteDetails.imagingOrders.length > 0) ||
          (soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis.length > 0)
        ) {
          let imgdata = [];
          imgdata.labOrder = [];
          imgdata.labOrderDiagnosis = [];
          soap.pastSoapNoteDetails.imagingOrders.labOrder &&
            soap.pastSoapNoteDetails.imagingOrders.labOrder.forEach(function (
              item
            ) {
              imgdata.labOrder.push({
                text: item.text
              });
            });
          soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis.forEach(
              function (item) {
                imgdata.labOrderDiagnosis.push({
                  code: item.code,
                  description: item.description
                });
              }
            );
          setImagingOrders(imgdata);
        }
        if (
          (soap.pastSoapNoteDetails.physicianOrders &&
            soap.pastSoapNoteDetails.physicianOrders.length > 0) ||
          (soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis.length >
            0)
        ) {
          let phydata = [];
          phydata.labOrder = [];
          phydata.labOrderDiagnosis = [];
          soap.pastSoapNoteDetails.physicianOrders.labOrder &&
            soap.pastSoapNoteDetails.physicianOrders.labOrder.forEach(function (
              item
            ) {
              phydata.labOrder.push({
                text: item.text
              });
            });
          soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis.forEach(
              function (item) {
                phydata.labOrderDiagnosis.push({
                  code: item.code,
                  description: item.description
                });
              }
            );
          setPhysicianOrders(phydata);
        }
      }

      if (
        soap.pastSoapNoteDetails.prescriptions &&
        (!isCopy || soap.isCopyNeglect)
      ) {
        setPrescriptions(soap.pastSoapNoteDetails.prescriptions);
      }
      if (
        ((soap.pastSoapNoteDetails.labOrders &&
          soap.pastSoapNoteDetails.labOrders.length > 0) ||
          (soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis.length > 0)) &&
        isEdit &&
        (!isCopy || soap.isCopyNeglect)
      ) {
        let data = [];
        data.labOrder = [];
        data.labOrderDiagnosis = [];
        soap.pastSoapNoteDetails.labOrders &&
          soap.pastSoapNoteDetails.labOrders.length > 0
          ? soap.pastSoapNoteDetails.labOrders.forEach(function (item) {
            data.labOrder.push({
              id: item[0],
              text: item[1]
            });
          })
          : soap.pastSoapNoteDetails.labOrders.labOrder &&
          soap.pastSoapNoteDetails.labOrders.labOrder.length > 0 &&
          soap.pastSoapNoteDetails.labOrders.labOrder.forEach(function (item) {
            data.labOrder.push({
              id: item.id,
              text: item.text
            });
          });
        soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis &&
          soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis.length > 0 &&
          soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis.forEach(function (
            item
          ) {
            data.labOrderDiagnosis.push({
              id: item[0] !== undefined ? item[0] : item.id,
              code: item[2] !== undefined ? item[2] : item.code,
              description: item[1] !== undefined ? item[1] : item.description
            });
          });
        setLabOrders(data);
      } else if (
        soap.pastSoapNoteDetails.labOrders &&
        (!isCopy || soap.isCopyNeglect) &&
        !isEdit
      ) {
        let data = [];
        data.labOrder = [];
        data.labOrderDiagnosis = [];
        soap.pastSoapNoteDetails.labOrders.labOrder &&
          soap.pastSoapNoteDetails.labOrders.labOrder.forEach(function (item) {
            data.labOrder.push({
              text: item.text
            });
          });
        soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis &&
          soap.pastSoapNoteDetails.labOrders.labOrderDiagnosis.forEach(function (
            item
          ) {
            data.labOrderDiagnosis.push({
              code: item.code,
              description: item.description
            });
          });
        setLabOrders(data);
      }
      if (
        ((soap.pastSoapNoteDetails.imagingOrders &&
          soap.pastSoapNoteDetails.imagingOrders.length > 0) ||
          (soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis.length >
            0)) &&
        isEdit &&
        (!isCopy || soap.isCopyNeglect)
      ) {
        let imgdata = [];
        imgdata.labOrder = [];
        imgdata.labOrderDiagnosis = [];
        soap.pastSoapNoteDetails.imagingOrders &&
          soap.pastSoapNoteDetails.imagingOrders.length > 0
          ? soap.pastSoapNoteDetails.imagingOrders.forEach(function (item) {
            imgdata.labOrder.push({
              id: item[0],
              text: item[1]
            });
          })
          : soap.pastSoapNoteDetails.imagingOrders.labOrder &&
          soap.pastSoapNoteDetails.imagingOrders.labOrder.length > 0 &&
          soap.pastSoapNoteDetails.imagingOrders.labOrder.forEach(function (
            item
          ) {
            imgdata.labOrder.push({
              id: item.id,
              text: item.text
            });
          });
        soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis &&
          soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis.length > 0 &&
          soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis.forEach(
            function (item) {
              imgdata.labOrderDiagnosis.push({
                id: item[0] !== undefined ? item[0] : item.id,
                code: item[2] !== undefined ? item[2] : item.code,
                description: item[1] !== undefined ? item[1] : item.description
              });
            }
          );
        setImagingOrders(imgdata);
      } else if (
        soap.pastSoapNoteDetails.imagingOrders &&
        (!isCopy || soap.isCopyNeglect) &&
        !isEdit
      ) {
        let imgdata = [];
        imgdata.labOrder = [];
        imgdata.labOrderDiagnosis = [];
        soap.pastSoapNoteDetails.imagingOrders.labOrder &&
          soap.pastSoapNoteDetails.imagingOrders.labOrder.forEach(function (
            item
          ) {
            imgdata.labOrder.push({
              text: item.text
            });
          });
        soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis &&
          soap.pastSoapNoteDetails.imagingOrders.labOrderDiagnosis.forEach(
            function (item) {
              imgdata.labOrderDiagnosis.push({
                code: item.code,
                description: item.description
              });
            }
          );
        setImagingOrders(imgdata);
      }
      if (
        ((soap.pastSoapNoteDetails.physicianOrders &&
          soap.pastSoapNoteDetails.physicianOrders.length > 0) ||
          (soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis &&
            soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis.length >
            0)) &&
        isEdit &&
        (!isCopy || soap.isCopyNeglect)
      ) {
        let phydata = [];
        phydata.labOrder = [];
        phydata.labOrderDiagnosis = [];
        soap.pastSoapNoteDetails.physicianOrders &&
          soap.pastSoapNoteDetails.physicianOrders.length > 0
          ? soap.pastSoapNoteDetails.physicianOrders.forEach(function (item) {
            phydata.labOrder.push({
              id: item[0],
              text: item[1]
            });
          })
          : soap.pastSoapNoteDetails.physicianOrders.labOrder &&
          soap.pastSoapNoteDetails.physicianOrders.labOrder.length > 0 &&
          soap.pastSoapNoteDetails.physicianOrders.labOrder.forEach(function (
            item
          ) {
            phydata.labOrder.push({
              id: item.id,
              text: item.text
            });
          });
        soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis &&
          soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis.length >
          0 &&
          soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis.forEach(
            function (item) {
              phydata.labOrderDiagnosis.push({
                id: item[0] !== undefined ? item[0] : item.id,
                code: item[2] !== undefined ? item[2] : item.code,
                description: item[1] !== undefined ? item[1] : item.description
              });
            }
          );
        setPhysicianOrders(phydata);
      } else if (
        soap.pastSoapNoteDetails.physicianOrders &&
        (!isCopy || soap.isCopyNeglect) &&
        !isEdit
      ) {
        let phydata = [];
        phydata.labOrder = [];
        phydata.labOrderDiagnosis = [];
        soap.pastSoapNoteDetails.physicianOrders.labOrder &&
          soap.pastSoapNoteDetails.physicianOrders.labOrder.forEach(function (
            item
          ) {
            phydata.labOrder.push({
              text: item.text
            });
          });
        soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis &&
          soap.pastSoapNoteDetails.physicianOrders.labOrderDiagnosis.forEach(
            function (item) {
              phydata.labOrderDiagnosis.push({
                code: item.code,
                description: item.description
              });
            }
          );
        setPhysicianOrders(phydata);
      }
    }
  };

  useEffect(() => {
    loadCopyEditSoapDetails(
      soap,
      soap && soap.isEdit ? soap.isEdit : false,
      soap && soap.isCopy ? soap.isCopy : false,
      soap && soap.isPast ? soap.isPast : false
    );
  }, [soap]);

  const saveTempData = async (isCopyNeglect = false) => {
    let vals = getValuesforCopy(isCopyNeglect);
    props.saveTempData(vals);
  };

  const handleFieldChange = (field, value, isTemplate = false) => {

    const newState = { ...values };
    if (field === 'providerId') {
      newState['providerId'] = value;
    } else if (field === 'height' || field === 'weight') {
      newState[field] = value;
      let bodymi = calculateBMI(
        field === 'height' ? value : values.height,
        field === 'weight' ? value : values.weight
      );
      newState['bodymassindex'] = bodymi ? Number(bodymi).toFixed(2) : '';
    } else if (
      field === 'diagnosisList' &&
      newState[field] &&
      newState[field].length > 0
    ) {
      let newData = [...newState[field]];
      value &&
        value.length > 0 &&
        value.map(item => {
          newData.push(item);
        });
      newState[field] = newData;
      setDiagnosisList(newData);
    } else if (
      field === 'cptList' &&
      newState[field] &&
      newState[field].length > 0
    ) {
      let newData = [...newState[field]];
      value &&
        value.length > 0 &&
        value.map(item => {
          newData.push(item);
        });
      newState[field] = newData;
      setCPTList(newData);
    } else if (field === 'subjective' && isTemplate) {
      newState[field] = values.subjective ? values.subjective + ' \n' + value : value;
    } else if (field === 'objective' && isTemplate) {
      newState[field] = values.objective ? values.objective + ' \n' + value : value;
    } else if (field === 'assessmentplan' && isTemplate) {
      newState[field] = values.assessmentplan ? values.assessmentplan + ' \n' + value : value;
    } else {
      newState[field] = value;
    }
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  //#region VsHx

  const [openVsHx, setOpenVsHx] = useState(false);

  const handleOpenVsHx = () => {
    setOpenVsHx(true);
  };
  const handleCloseVsHx = () => {
    setOpenVsHx(false);
  };

  const handleCopyVsHx = item => {
    let copyVals = {
      ...values,
      bloodpressure: item.bloodpressure,
      temperature: item.temperature,
      heartrate: item.heartrate,
      respiratoryrate: item.respiratoryrate,
      weight: item.weight,
      height: item.height,
      bodymassindex: Number(item.bodymassindex).toFixed(2)
    };
    setValues(copyVals);
    let vals = getValuesforCopy(
      isCopy ? true : false,
      '',
      '',
      '',
      '',
      '',
      '',
      copyVals
    );
    props.saveTempData(vals);
  };

  const calculateBMI = (heightVal, weightVal) => {
    let bmi;
    let footToInches = 12;
    let imperialBMIFactor = 703;
    let heightInFeetAndInchesPattern = new RegExp(
      '^([0-9]+)\'?[ ]?([0-9]{1,2})?(["])?|$'
    );
    let weightInPoundsPattern = new RegExp('^([0-9]+)[ ]*([lbs])?|$');

    // extract components
    let matchHeight = heightInFeetAndInchesPattern.exec(heightVal);

    let matchWeight = weightInPoundsPattern.exec(weightVal);

    // test if pattern matches and if they do calculate the BMI
    if (
      heightInFeetAndInchesPattern.test(heightVal) &&
      weightInPoundsPattern.test(weightVal)
    ) {
      // convert height to inches
      let inches = parseInt(matchHeight[2] ? matchHeight[2] : '0', 10);
      let height = parseInt(matchHeight[1], 10) * footToInches + inches;

      // square the height
      var heightSquared = Math.pow(height, 2);

      // divide the weight by the height squared to calculate the BMI
      bmi = (parseInt(matchWeight[1], 10) * imperialBMIFactor) / heightSquared;
    }

    return bmi;
  };

  const renderVsHx = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseVsHx}
        open={openVsHx}>
        <Box className={classes.modalWrap} style={{ width: 750 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">VS Hx</Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseVsHx}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <VSHx
            handleCopyVsHx={handleCopyVsHx}
            onCancel={handleCloseVsHx}
            patientId={patientId}
            saveTempData={saveTempData}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  //#region Rx
  const [openRx, setOpenRx] = useState(false);
  const [prescriptions, setPrescriptions] = useState(
    props.unSavedPrescription || {}
  );
  const [medicationComment, setMedicationComment] = useState(
    props.unSavedMedicationComment || ''
  );

  useEffect(() => {
    if (props.prescriptions && props.prescriptions.length > 0) {
      setPrescriptions(props.prescriptions);
    }
  }, [props.prescriptions]);

  useEffect(() => {
    if (props.medicationComment && props.medicationComment.length > 0) {
      setMedicationComment(props.medicationComment);
    }
  }, [props.medicationComment]);

  const handleOpenRx = () => {
    setOpenRx({ open: true, isNewRx: true });
  };

  const handleCloseRx = () => {
    setOpenRx(false);
  };

  const handlePrescription = prescriptions => {
    setPrescriptions(prescriptions);
    let vals = getValuesforCopy(false, prescriptions);
    props.saveTempData(vals);
  };

  const handleMedicationComment = medComment => {
    setMedicationComment(medComment);
    setValues({ ...values, medicationComment: medComment });
  };

  const handleESignPrint = (patientId, prescriptions) => {
    setOpenRx(false);
    props
      .downloadPDFRx(ehrID, patientId, userID, prescriptions, medicationComment)
      .then(res => {
        if (res.status) {
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
          //window.print(fileURL, '_blank');
        }
      });
  };

  const editMedication = () => {
    setOpenRx({ open: true, isNewRx: false });
  };

  const renderRx = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseRx}
        open={openRx}>
        <Box
          className={classes.modalWrap}
          style={{ width: '90%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">Prescription</Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseRx}>
              <CloseOutlined />
            </IconButton>
          </Box>
          {patientInfo && (
            <ApRx
              handleESignPrint={handleESignPrint}
              handleMedicationComment={handleMedicationComment}
              handlePrescription={handlePrescription}
              isNewRx={openRx.isNewRx}
              medicationComment={medicationComment}
              onCancel={handleCloseRx}
              patientId={patientId}
              patientInfo={patientInfo}
              prescriptions={prescriptions}
            />
          )}
        </Box>
      </Modal>
    );
  };

  //#endregion

  //#region Lab Orders

  const [openOrders, setOpenOrders] = useState(false);
  const [labOrders, setLabOrders] = useState({});
  const [imagingOrders, setImagingOrders] = useState({});
  const [physicianOrders, setPhysicianOrders] = useState({});

  useEffect(() => {
    if (values && values.labOrder) {
      setLabOrders(values.labOrder);
    }
  }, [values && values.labOrder]);

  useEffect(() => {
    if (values && values.imagingOrder) {
      setImagingOrders(values.imagingOrder);
    }
  }, [values && values.imagingOrder]);

  useEffect(() => {
    if (values && values.physicianOrder) {
      setPhysicianOrders(values.physicianOrder);
    }
  }, [values && values.physicianOrder]);

  const handleOpenOrders = typeOrder => {
    setOpenOrders({ open: true, typeOrder: typeOrder, isNewLabOrder: true });
  };

  const handleCloseOrders = () => {
    setOpenOrders(false);
  };

  const editLabOrder = typeOrder => {
    setOpenOrders({ open: true, typeOrder: typeOrder, isNewLabOrder: false });
  };

  const handleLabESignPrint = async (patientId, labOrders) => {
    let data = [];
    labOrders &&
      labOrders.labOrder &&
      labOrders.labOrder.forEach(function (item) {
        data.push({
          labDetails: item.text
        });
      });
    labOrders &&
      labOrders.labOrderDiagnosis &&
      labOrders.labOrderDiagnosis.forEach(function (item) {
        data.push({
          code: item.code,
          diagnosisDetails: item.description
        });
      });

    await props.downloadPDFLab(ehrID, patientId, userID, data).then(res => {
      if (res.status) {
        let file = new Blob([res.result.data], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        try {
          window.open(fileURL, '_blank');
          return
        } catch (e) {
          return
        }
        // window.open(fileURL, '_blank');
        // return
        //window.print(fileURL, '_blank');
      }
    });
  };
  const handleLabESignPrintImage = async (patientId, imagingOrders) => {
    let data = [];
    imagingOrders &&
      imagingOrders.labOrder &&
      imagingOrders.labOrder.forEach(function (item) {
        data.push({
          imagingDetails: item.text
        });
      });
    imagingOrders &&
      imagingOrders.labOrderDiagnosis &&
      imagingOrders.labOrderDiagnosis.forEach(function (item) {
        data.push({
          code: item.code,
          diagnosisDetails: item.description
        });
      });

    await props.downloadPDFImage(ehrID, patientId, userID, data).then(res => {
      if (res.status) {
        let file = new Blob([res.result.data], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        try {
          window.open(fileURL, '_blank');
          return
        } catch (e) {
          return
        }
        //window.print(fileURL, '_blank');
      }
    });
  };
  const handleLabESignPrintPhysician = async (patientId, physicianOrders) => {
    let data = [];
    physicianOrders &&
      physicianOrders.labOrder &&
      physicianOrders.labOrder.forEach(function (item) {
        data.push({
          physicianDetails: item.text
        });
      });
    physicianOrders &&
      physicianOrders.labOrderDiagnosis &&
      physicianOrders.labOrderDiagnosis.forEach(function (item) {
        data.push({
          code: item.code,
          diagnosisDetails: item.description
        });
      });

    await props
      .downloadPDFPhysician(ehrID, patientId, userID, data)
      .then(res => {
        if (res.status) {
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          try{
            window.open(fileURL, '_blank');
            return
          }catch(e){
            return
          }
          //window.print(fileURL, '_blank');
        }
      });
  };

  const renderOrders = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseOrders}
        open={openOrders.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '90%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              {openOrders.typeOrder === 1
                ? 'Lab '
                : openOrders.typeOrder === 2
                  ? 'Imaging '
                  : 'Physician '}
              Orders
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseOrders}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <ApLabOrders
            handleFieldChange={handleFieldChange}
            handleLabESignPrint={
              openOrders.typeOrder === 1
                ? handleLabESignPrint
                : openOrders.typeOrder === 2
                  ? handleLabESignPrintImage
                  : openOrders.typeOrder === 3
                    ? handleLabESignPrintPhysician
                    : ''
            }
            isNewLabOrder={openOrders.isNewLabOrder}
            labOrders={
              openOrders.typeOrder === 1
                ? labOrders
                : openOrders.typeOrder === 2
                  ? imagingOrders
                  : openOrders.typeOrder === 3
                    ? physicianOrders
                    : ''
            }
            onCancel={handleCloseOrders}
            patientId={patientId}
            patientInfo={patientInfo}
            typeOrder={openOrders.typeOrder}
            getValuesforCopy={getValuesforCopy}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  //#region success

  const handleCloseSuccess = () => {
    setSuccMsg(false);
  };

  const renderSuccess = () => {
    return (
      <MessageBox
        message={
          isEdit ? 'Soap updated successfully' : 'SOAP created successfully'
        }
        onClose={handleCloseSuccess}
        open={succMsg}
        variant="success"
      />
    );
  };

  //#endregion

  //#region Subjective,Objective,AssessmentPlan
  const [opentemplates, setOpenTemplates] = useState(false);

  const handleOpenTemplates = type => {
    setOpenTemplates({ open: true, type: type });
  };

  const handleCloseTemplates = () => {
    setOpenTemplates(false);
  };

  const renderTemplates = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseTemplates}
        open={opentemplates.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '90%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              {opentemplates.type === 1
                ? 'Subjective '
                : opentemplates.type === 2
                  ? 'Objective '
                  : 'Assessment Plan '}
              Templates
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseTemplates}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <MyTemplates
            handleFieldChange={handleFieldChange}
            onCancel={handleCloseTemplates}
            type={opentemplates.type}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  //#region CPT,ICD
  const [openCPTICD, setOpenCPTICD] = useState(false);
  const [diagnosisList, setDiagnosisList] = useState([]);
  const [cptList, setCPTList] = useState([]);

  useEffect(() => {
    if (values && values.diagnosisList && values.diagnosisList.length > 0) {
      setDiagnosisList(values.diagnosisList);
    }
  }, [values && values.diagnosisList]);

  useEffect(() => {
    if (values && values.cptList && values.cptList.length > 0) {
      setCPTList(values.cptList);
    }
  }, [values && values.cptList]);

  const handleOpenCPTICD = isCPT => {
    setIsCPT(isCPT);
    setOpenCPTICD(true);
  };
  const handleCloseCPTICD = () => {
    setOpenCPTICD(false);
  };

  const renderCPTICD = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseCPTICD}
        open={openCPTICD}>
        <Box
          className={classes.modalWrap}
          style={{ width: '90%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              {isCPT ? 'CPT Database' : 'Diagnosis Database'}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseCPTICD}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <CPT
            handleFieldChange={handleFieldChange}
            isCPT={isCPT}
            onCancel={handleCloseCPTICD}
            getValuesforCopy={getValuesforCopy}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  //#region Delete Soap

  const [opendelete, setOpenDelete] = useState(false);
  const [succDeleteMsg, setSuccDeleteMsg] = useState(false);

  const renderDeleteSuccess = () => {
    console.log(' soap deleted successfully2');
    return (
      console.log(' soap deleted successfully3'),
      (
        <MessageBox
          message="SOAP deleted successfully"
          onClose={handleCloseDeleteSuccess}
          open={succDeleteMsg}
          variant="success"
        />
      )
    );
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleDeleteOK = async () => {
    setOpenDelete(false);
    if (values.id === undefined || values.id === 0) {
      setTimeout(() => {
        props.clearSoap()
        clearList();
      }, 1000);
    } else {
      const res = await props.deleteSoap(ehrID, userID, patientId, editSoap.id);

      if (res) {
        setTimeout(() => {
          props.clearSoap()
          clearList();

        }, 1000);
        setSuccDeleteMsg(true);
      }
    }
  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  const handleCloseDeleteSuccess = () => {
    setSuccDeleteMsg(false);
  };
  const renderDeleteDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={opendelete}>
        <DialogTitle id="confirmation-dialog-title">
          Delete Soap Note
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and delete the soap note ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleDeleteOK()}>
            OK
          </Button>
          <Button color="primary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  //#region Delete CPTICD

  const [openDeleteCPTICD, setOpenDeleteCPTICD] = useState({
    showPopUp: false,
    idx: 0,
    id: 0
  });
  const [succDeleteMsgCPTICD, setSuccDeleteMsgCPTICD] = useState({
    succ: false,
    succMsg: ''
  });

  const renderDeleteCPTICDSuccess = () => {
    return (
      <MessageBox
        message={succDeleteMsgCPTICD.succMsg}
        onClose={handleCloseDeleteCPTICDSuccess}
        open={succDeleteMsgCPTICD.succ}
        variant="success"
      />
    );
  };

  const handleOpenDeleteCPTICD = (isCPT, idx, id) => {
    setIsCPT(isCPT);
    setOpenDeleteCPTICD({ showPopUp: true, idx: idx, id: id });
  };

  const handleDeleteCPTICDCancel = () => {
    setOpenDeleteCPTICD({ showPopUp: false });
  };

  const handleCloseDeleteCPTICDSuccess = () => {
    setOpenDeleteCPTICD({ showPopUp: false });
    setSuccDeleteMsgCPTICD({ succ: false });
  };

  const handleDeleteDiagnosisList = async () => {
    setOpenDeleteCPTICD({ showPopUp: false });
    let idx = openDeleteCPTICD.idx;
    let newDia = [...diagnosisList];
    if (newDia[idx] && newDia[idx].id) {
      await props.deleteDiagnosis(patientId, newDia[idx].id);
    }
    newDia.splice(idx, 1);
    setDiagnosisList(newDia);
    let newSta = { ...values };
    newSta.diagnosisList = newDia;
    setValues(newSta);
    await props.handleCopy(newSta.diagnosisList, cptList, true, false);
    setSuccDeleteMsgCPTICD({ succ: true, succMsg: 'Diagnosis deleted.' });
  };

  const handleDeleteCPTList = async () => {
    setOpenDeleteCPTICD({ showPopUp: false });
    let idx = openDeleteCPTICD.idx;
    let newCPT = [...cptList];
    if (newCPT[idx] && newCPT[idx].id) {
      await props.deleteDiagnosis(patientId, newCPT[idx].id);
    }
    newCPT.splice(idx, 1);
    setCPTList(newCPT);
    let newSta = { ...values };
    newSta.cptList = newCPT;
    setValues(newSta);
    await props.handleCopy(diagnosisList, newSta.cptList, false, true);
    setSuccDeleteMsgCPTICD({ succ: true, succMsg: 'CPT deleted.' });
  };

  const renderDeleteCPTICDDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={openDeleteCPTICD.showPopUp}>
        <DialogTitle id="confirmation-dialog-title">
          Delete {isCPT ? 'CPT' : 'ICD'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and delete the {isCPT ? 'CPT' : 'ICD'} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() =>
              isCPT ? handleDeleteCPTList() : handleDeleteDiagnosisList()
            }>
            OK
          </Button>
          <Button color="primary" onClick={() => handleDeleteCPTICDCancel()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  const clearList = async () => {
    await props.clearLocationandEditChartView();
    props.refreshData()
    setValues(initialUserState);
    setPrescriptions([]);
    setLabOrders([]);
    setImagingOrders([]);
    setPhysicianOrders([]);
    setDiagnosisList([]);
    setCPTList([]);
    setEditSoap([]);
  };

  const getValuesforCopy = (
    isCopyNeglect,
    newPrescriptions,
    newlabOrders,
    newimagingOrders,
    newphysicianOrders,
    newcptList,
    newdiagnosisList,
    copyValsforVsHx
  ) => {
    let nData = {
      soap: copyValsforVsHx ? copyValsforVsHx : values,
      pastSoapNoteDetails: {
        prescriptions: newPrescriptions ? newPrescriptions : prescriptions,
        labOrders: newlabOrders ? newlabOrders : labOrders,
        imagingOrders: newimagingOrders ? newimagingOrders : imagingOrders,
        physicianOrders: newphysicianOrders
          ? newphysicianOrders
          : physicianOrders,
        diagnosisList:
          newdiagnosisList && newdiagnosisList.length > 0
            ? [...newdiagnosisList, ...diagnosisList]
            : diagnosisList,
        cptList:
          newcptList && newcptList.length > 0
            ? [...newcptList, ...cptList]
            : cptList
      },
      isCopy: isCopy,
      isEdit: isEdit,
      isPast: isPast,
      editSoap: editSoap,
      isCopyNeglect: isCopyNeglect || values.isCopyNeglect,
      fromIsMHx:
        newPrescriptions ||
          newlabOrders ||
          newimagingOrders ||
          newphysicianOrders ||
          newcptList ||
          newdiagnosisList
          ? true
          : false
    };
    return nData;
  };

  const btnSaveSOAPPatient = async () => {
    if (values.subjective || values.objective || values.assessmentplan) {
      let newstate;
      if (isEdit) {
        newstate = { ...editSoap };
        newstate.visitdate = values.visitdate;
        newstate.soapnote = {};
        newstate.soapnote.medicationComment = medicationComment;
        newstate.soapnote.id = values.id;
        newstate.soapnote.bloodpressure = values.bloodpressure;
        newstate.soapnote.temperature = values.temperature;
        newstate.soapnote.heartrate = values.heartrate;
        newstate.soapnote.respiratoryrate = values.respiratoryrate;
        newstate.soapnote.weight = values.weight;
        newstate.soapnote.bodymassindex = values.bodymassindex;
        newstate.soapnote.height = values.height;
        newstate.soapnote.subjective = values.subjective;
        newstate.soapnote.objective = values.objective;
        newstate.soapnote.assessmentplan = values.assessmentplan;
        //newstate.soapnote.diagnosis = values.diagnosis;
        newstate.soapnote.visitdate = values.visitdate;
        newstate.soapnote.signed = false;
      } else {
        newstate = {
          soapnote: {
            bloodpressure: values.bloodpressure,
            temperature: values.temperature,
            heartrate: values.heartrate,
            respiratoryrate: values.respiratoryrate,
            weight: values.weight,
            bodymassindex: values.bodymassindex,
            height: values.height,
            subjective: values.subjective,
            objective: values.objective,
            assessmentplan: values.assessmentplan,
            //diagnosis: values.diagnosis,
            visitdate: values.visitdate,
            signed: false,
            medicationComment: medicationComment
          },
          visitdate: values.visitdate,
          signed: false
        };
      }
      const res = await props.saveSOAPPatientData(
        ehrID,
        values.providerId,
        patientId,
        false,
        newstate
      );
      if (res.status) {
        if (
          prescriptions &&
          prescriptions.length > 0 &&
          prescriptions.filter(c => !c.hasOwnProperty('id'))
        ) {
          await props.savePrescription(
            ehrID,
            patientId,
            values.providerId,
            res.soapId,
            prescriptions
          );
        }
        if (labOrders) {
          let labOrderData = [];
          labOrders &&
            labOrders.labOrder &&
            labOrders.labOrder
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                labOrderData.push({
                  labDetails: item.text
                });
              });
          labOrders &&
            labOrders.labOrderDiagnosis &&
            labOrders.labOrderDiagnosis
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                labOrderData.push({
                  code: item.code,
                  diagnosisDetails: item.description
                });
              });
          if (labOrderData && labOrderData.length > 0) {
            await props.savelabOrders(
              ehrID,
              patientId,
              values.providerId,
              res.soapId,
              labOrderData
            );
          }
        }
        if (imagingOrders) {
          let imagingOrderData = [];
          imagingOrders &&
            imagingOrders.labOrder &&
            imagingOrders.labOrder
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                imagingOrderData.push({
                  imagingDetails: item.text
                });
              });
          imagingOrders &&
            imagingOrders.labOrderDiagnosis &&
            imagingOrders.labOrderDiagnosis
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                imagingOrderData.push({
                  code: item.code,
                  diagnosisDetails: item.description
                });
              });
          if (imagingOrderData.length > 0) {
            await props.saveImagingOrders(
              ehrID,
              patientId,
              values.providerId,
              res.soapId,
              imagingOrderData
            );
          }
        }
        if (physicianOrders) {
          let physicianOrderData = [];
          physicianOrders &&
            physicianOrders.labOrder &&
            physicianOrders.labOrder
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                physicianOrderData.push({
                  physicianDetails: item.text
                });
              });
          physicianOrders &&
            physicianOrders.labOrderDiagnosis &&
            physicianOrders.labOrderDiagnosis
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                physicianOrderData.push({
                  code: item.code,
                  diagnosisDetails: item.description
                });
              });
          if (physicianOrderData.length > 0) {
            await props.savePhysicianOrders(
              ehrID,
              patientId,
              values.providerId,
              res.soapId,
              physicianOrderData
            );
          }
        }

        if (
          (diagnosisList && diagnosisList.length > 0) ||
          (cptList && cptList.length > 0)
        ) {
          let diagnosis = [];
          diagnosisList &&
            diagnosisList.length > 0 &&
            diagnosisList
              .filter(c => !c.hasOwnProperty('id'))
              .forEach(function (item) {
                diagnosis.push(item);
              });
          cptList &&
            cptList.length > 0 &&
            cptList
              .filter(c => !c.hasOwnProperty('id'))
              .forEach(function (item) {
                diagnosis.push(item);
              });
          if (diagnosis && diagnosis.length > 0) {
            await props.saveDiagnosis(
              ehrID,
              patientId,
              values.providerId,
              res.soapId,
              diagnosis
            );
          }
        }
        setSuccMsg(true);
        setTimeout(() => {
          props.getPastSoapNoteById(patientId);
          props.refreshData();
          props.resetValue();
          clearList();
          setIsEdit(false);
          setIsCopy(false);
          setIsPast(false);
        }, 2000);
        //await props.setPast(true);
      }
    }
  };

  const btnSaveSOAPPatientSign = async () => {
    if (
      values.subjective !== '' ||
      values.objective !== '' ||
      values.assessmentplan !== ''
    ) {
      let newstate;
      if (isEdit) {
        let addendaVal = [];
        if (values.addendum) {
          addendaVal.push({
            addendum: values.addendum,
            signature: {
              firstname: user.firstname,
              lastname: user.lastname,
              title: user.title
            }
          });
        }
        newstate = { ...editSoap };
        newstate.visitdate = values.visitdate;
        newstate.signed = true;

        newstate.soapnote = {};
        newstate.soapnote.id = values.id;
        newstate.soapnote.bloodpressure = values.bloodpressure;
        newstate.soapnote.temperature = values.temperature;
        newstate.soapnote.heartrate = values.heartrate;
        newstate.soapnote.respiratoryrate = values.respiratoryrate;
        newstate.soapnote.weight = values.weight;
        newstate.soapnote.bodymassindex = values.bodymassindex;
        newstate.soapnote.height = values.height;
        newstate.soapnote.subjective = values.subjective;
        newstate.soapnote.objective = values.objective;
        newstate.soapnote.assessmentplan = values.assessmentplan;
        newstate.soapnote.medicationComment = medicationComment;
        newstate.soapnote.visitdate = values.visitdate;
        newstate.soapnote.signed = true;
        newstate.soapnote.signedby = values.providerId;
        newstate.soapnote.signedbydate = new Date();
        newstate.soapnote.addenda = addendaVal;
      } else {
        newstate = {
          soapnote: {
            bloodpressure: values.bloodpressure,
            temperature: values.temperature,
            heartrate: values.heartrate,
            respiratoryrate: values.respiratoryrate,
            weight: values.weight,
            bodymassindex: values.bodymassindex,
            height: values.height,
            subjective: values.subjective,
            objective: values.objective,
            assessmentplan: values.assessmentplan,
            //diagnosis: values.diagnosis,
            visitdate: values.visitdate,
            signed: true,
            signedby: values.providerId,
            medicationComment: medicationComment,
            signedbydate: new Date()
          },
          visitdate: values.visitdate,
          signed: true
        };
      }
      const res = await props.saveSOAPPatientData(
        ehrID,
        values.providerId,
        patientId,
        true,
        newstate,
        true
      );
      if (res.status) {
        if (
          prescriptions &&
          prescriptions.length > 0 &&
          prescriptions.filter(c => !c.hasOwnProperty('id'))
        ) {
          await props.savePrescription(
            ehrID,
            patientId,
            values.providerId,
            res.soapId.soapnote.id,
            prescriptions
          );
        }
        if (labOrders) {
          let labOrderData = [];
          labOrders &&
            labOrders.labOrder &&
            labOrders.labOrder
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                labOrderData.push({
                  labDetails: item.text
                });
              });
          labOrders &&
            labOrders.labOrderDiagnosis &&
            labOrders.labOrderDiagnosis
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                labOrderData.push({
                  code: item.code,
                  diagnosisDetails: item.description
                });
              });
          if (labOrderData && labOrderData.length > 0) {
            await props.savelabOrders(
              ehrID,
              patientId,
              values.providerId,
              res.soapId.soapnote.id,
              labOrderData
            );
          }
        }
        if (imagingOrders) {
          let imagingOrderData = [];
          imagingOrders &&
            imagingOrders.labOrder &&
            imagingOrders.labOrder
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                imagingOrderData.push({
                  imagingDetails: item.text
                });
              });
          imagingOrders &&
            imagingOrders.labOrderDiagnosis &&
            imagingOrders.labOrderDiagnosis
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                imagingOrderData.push({
                  code: item.code,
                  diagnosisDetails: item.description
                });
              });
          if (imagingOrderData.length > 0) {
            await props.saveImagingOrders(
              ehrID,
              patientId,
              values.providerId,
              res.soapId.soapnote.id,
              imagingOrderData
            );
          }
        }
        if (physicianOrders) {
          let physicianOrderData = [];
          physicianOrders &&
            physicianOrders.labOrder &&
            physicianOrders.labOrder
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                physicianOrderData.push({
                  physicianDetails: item.text
                });
              });
          physicianOrders &&
            physicianOrders.labOrderDiagnosis &&
            physicianOrders.labOrderDiagnosis
              .filter(c => !c.hasOwnProperty('id') || c.id === undefined)
              .forEach(function (item) {
                physicianOrderData.push({
                  code: item.code,
                  diagnosisDetails: item.description
                });
              });
          if (physicianOrderData.length > 0) {
            await props.savePhysicianOrders(
              ehrID,
              patientId,
              values.providerId,
              res.soapId.soapnote.id,
              physicianOrderData
            );
          }
        }
        if (
          (diagnosisList && diagnosisList.length > 0) ||
          (cptList && cptList.length > 0)
        ) {
          let diagnosis = [];
          diagnosisList &&
            diagnosisList.length > 0 &&
            diagnosisList
              .filter(c => !c.hasOwnProperty('id'))
              .forEach(function (item) {
                diagnosis.push(item);
              });
          cptList &&
            cptList.length > 0 &&
            cptList
              .filter(c => !c.hasOwnProperty('id'))
              .forEach(function (item) {
                diagnosis.push(item);
              });
          if (diagnosis && diagnosis.length > 0) {
            await props.saveDiagnosis(
              ehrID,
              patientId,
              values.providerId,
              res.soapId.soapnote.id,
              diagnosis
            );
          }
        }
        setSuccMsg(true);
        setTimeout(() => {
          props.getPastSoapNoteById(patientId);
          props.refreshData();
          props.resetValue();
          clearList();
          setIsEdit(false);
          setIsCopy(false);
          setIsPast(false);
        }, 2000);
        //await props.setPast(true);
      }
    }
  };

  return (
    <>
      <Grid item xs={!isPast ? 6 : 12}>
        <PerfectScrollbar
          style={{ height: 'calc(100vh - 80px)', paddingRight: 10 }}>
          <Box className={classes.soapEntryWrap}>
            <Box
              className={clsx(classes.soapEntryItem, classes.soapEntryTitle)}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  justifyContent: 'space-between'
                }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>Patient Visit Date: &nbsp; </Typography>

                  <div className={classes.datePickerWrap}>
                    <DatePicker
                      customInput={
                        <TextField
                          className={classes.textField}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <CalendarToday
                                  style={{ height: 16, width: 16 }}
                                />
                              </InputAdornment>
                            )
                          }}
                          label="Visit date"
                          margin="dense"
                          variant="outlined"
                        />
                      }
                      format="MM/DD/YYYY"

                      onBlur={saveTempData}
                      onChange={event =>
                        handleFieldChange('visitdate', event)
                      }
                      onFocus={e => (e.target.placeholder = '')}
                      placeholderText="Patient Visit Date"
                      // selected={
                      //   formstate.dob &&
                      //   new Date(formstate.dob)
                      // }
                      selected={
                        values && values.visitdate &&
                        new Date(values.visitdate)
                      }
                      showMonthDropdown="bool"
                      showYearDropdown="bool"
                    />
                  </div>
                </span>
                {isPast ? (
                  <IconButton onClick={() => props.setPast()} size="small">
                    <FirstPage />
                  </IconButton>
                ) : null}
              </div>
            </Box>
            <Box className={classes.soapEntryItem}>
              <div className={classes.vitalHead}>
                <Typography>Vital Signs: &nbsp; </Typography>
                {values && !values.signed && (
                  <Button
                    color="primary"
                    onClick={handleOpenVsHx}
                    size="small"
                    variant="text">
                    VS Hx
                  </Button>
                )}
              </div>
              <div className={classes.vitalBody}>
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="BP"
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('bloodpressure', event.target.value)
                  }
                  value={
                    values && values.bloodpressure ? values.bloodpressure : ''
                  }
                  variant="outlined"
                />
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="Temp"
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('temperature', event.target.value)
                  }
                  value={values && values.temperature ? values.temperature : ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="HR"
                  onChange={event =>
                    handleFieldChange('heartrate', event.target.value)
                  }
                  value={values && values.heartrate ? values.heartrate : ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="RR"
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('respiratoryrate', event.target.value)
                  }
                  value={
                    values && values.respiratoryrate
                      ? values.respiratoryrate
                      : ''
                  }
                  variant="outlined"
                />
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="WT (lb)"
                  onChange={event =>
                    handleFieldChange('weight', event.target.value)
                  }
                  value={values && values.weight ? values.weight : ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="HT (ft)"
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('height', event.target.value)
                  }
                  value={values && values.height ? values.height : ''}
                  variant="outlined"
                />
                <TextField
                  className={classes.TextVitals}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  label="BMI"
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('bodymassindex', event.target.value)
                  }
                  value={
                    values && values.bodymassindex
                      ? Number(values.bodymassindex).toFixed(2)
                      : ''
                  }
                  variant="outlined"
                />
              </div>
            </Box>
            <Box className={classes.soapEntryItem}>
              <div className={classes.soapEntryBtn}>
                {values && !values.signed && (
                  <Button
                    color="primary"
                    onClick={() => handleOpenTemplates(1)}
                    size="small"
                    variant="contained">
                    <DescriptionOutlined />
                  </Button>
                )}
              </div>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                InputProps={{
                  classes: {
                    input: classes.textfiledSize
                  },
                  readOnly: values && values.signed && isEdit ? true : false
                }}
                label="Subjective"
                multiline
                onBlur={saveTempData}
                onChange={event =>
                  handleFieldChange('subjective', event.target.value)
                }
                value={values && values.subjective ? values.subjective : ''}
                variant="outlined"
              />
            </Box>
            <Box className={classes.soapEntryItem}>
              <div className={classes.soapEntryBtn}>
                {values && !values.signed && (
                  <Button
                    color="primary"
                    onClick={() => handleOpenTemplates(2)}
                    size="small"
                    variant="contained">
                    <DescriptionOutlined />
                  </Button>
                )}
              </div>
              <TextField
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                InputProps={{
                  classes: {
                    input: classes.textfiledSize
                  },
                  readOnly: values && values.signed && isEdit ? true : false
                }}
                label="Objective"
                multiline
                onBlur={saveTempData}
                onChange={event =>
                  handleFieldChange('objective', event.target.value)
                }
                value={values && values.objective ? values.objective : ''}
                variant="outlined"
              />
            </Box>
            {role !== Roles.staff && (
              <Box className={classes.soapEntryItem}>
                <div
                  className={clsx(classes.soapEntryBtn2, classes.soapEntryBtn)}>
                  <span>
                    {values && !values.signed && (
                      <>
                        <Button
                          color="primary"
                          onClick={() => handleOpenRx()}
                          size="small"
                          variant="outlined">
                          Rx
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => handleOpenOrders(1)}
                          size="small"
                          variant="outlined">
                          Lab Orders
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => handleOpenOrders(2)}
                          size="small"
                          variant="outlined">
                          Imaging Orders
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => handleOpenOrders(3)}
                          size="small"
                          variant="outlined">
                          Physician Orders
                        </Button>
                      </>
                    )}
                  </span>
                </div>

                {prescriptions && prescriptions.length > 0 && (
                  <Box className={classes.soapEntryItem}>
                    <span
                      style={{
                        backgroundColor: 'rgb(51, 151, 243)',
                        padding: 5,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                      <Typography style={{ color: '#fff', fontWeight: 600 }}>
                        Rx
                      </Typography>
                      <IconButton
                        onClick={() => editMedication()}
                        size="small"
                        style={{ color: '#fff' }}>
                        <EditOutlined />
                      </IconButton>
                    </span>
                    <Table
                      className={classes.templateTable}
                      style={{
                        border: '1px solid #3397F3',
                        borderCollapse: 'initial'
                      }}>
                      <TableHead>
                        <TableRow>
                          <TableCell width="25%">Medication</TableCell>
                          <TableCell>Dose</TableCell>
                          <TableCell width="25%">Direction</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>Refill</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prescriptions &&
                          prescriptions.length > 0 &&
                          prescriptions.map((item) => {
                            return (
                              <TableRow>
                                <TableCell>
                                  {item.name ? item.name : ''}
                                </TableCell>
                                <TableCell>
                                  {item.dose ? item.dose : ''}
                                </TableCell>
                                <TableCell>
                                  {item.directions ? item.directions : ''}
                                </TableCell>
                                <TableCell>
                                  {item.quantity ? item.quantity : ''}
                                </TableCell>
                                <TableCell>
                                  {item.refill ? item.refill : ''}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        <TableRow>
                          <TableCell colSpan={5}>
                            {medicationComment
                              ? medicationComment
                                .split('\n')
                                .map(function (item, key) {
                                  return (
                                    <span key={key}>
                                      {item}
                                      <br />
                                    </span>
                                  );
                                })
                              : ''}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}

                {labOrders &&
                  ((labOrders.labOrder && labOrders.labOrder.length > 0) ||
                    (labOrders.labOrderDiagnosis &&
                      labOrders.labOrderDiagnosis.length > 0)) && (
                    <Box className={classes.soapEntryItem}>
                      <span
                        style={{
                          backgroundColor: 'rgb(51, 151, 243)',
                          padding: 5,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography style={{ color: '#fff', fontWeight: 600 }}>
                          Lab Orders
                        </Typography>
                        <IconButton
                          onClick={() => editLabOrder(1)}
                          size="small"
                          style={{ color: '#fff' }}>
                          <EditOutlined />
                        </IconButton>
                      </span>
                      <Table
                        className={classes.templateTable}
                        style={{
                          border: '1px solid #3397F3',
                          borderCollapse: 'initial'
                        }}>
                        <TableBody>
                          {labOrders &&
                            labOrders.labOrder &&
                            labOrders.labOrder.length > 0 &&
                            labOrders.labOrder.map((item) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {item.text ? item.text : ''}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                      <Table
                        className={classes.templateTable}
                        style={{
                          border: '1px solid #3397F3',
                          borderCollapse: 'initial',
                          marginTop: -1
                        }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Diagnosis Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Description</TableCell>
                          </TableRow>
                          {labOrders &&
                            labOrders.labOrderDiagnosis &&
                            labOrders.labOrderDiagnosis.length > 0 &&
                            labOrders.labOrderDiagnosis.map((item) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {item.code ? item.code : ''}
                                  </TableCell>
                                  <TableCell>
                                    {item.description ? item.description : ''}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Box>
                  )}

                {imagingOrders &&
                  ((imagingOrders.labOrder &&
                    imagingOrders.labOrder.length > 0) ||
                    (imagingOrders.labOrderDiagnosis &&
                      imagingOrders.labOrderDiagnosis.length > 0)) && (
                    <Box className={classes.soapEntryItem}>
                      <span
                        style={{
                          backgroundColor: 'rgb(51, 151, 243)',
                          padding: 5,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography style={{ color: '#fff', fontWeight: 600 }}>
                          Imaging Orders
                        </Typography>
                        <IconButton
                          onClick={() => editLabOrder(2)}
                          size="small"
                          style={{ color: '#fff' }}>
                          <EditOutlined />
                        </IconButton>
                      </span>
                      <Table
                        className={classes.templateTable}
                        style={{
                          border: '1px solid #3397F3',
                          borderCollapse: 'initial'
                        }}>
                        <TableBody>
                          {imagingOrders &&
                            imagingOrders.labOrder &&
                            imagingOrders.labOrder.length > 0 &&
                            imagingOrders.labOrder.map((item) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {item.text ? item.text : ''}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {imagingOrders && imagingOrders.labOrdertext && (
                            <TableRow>
                              <TableCell>
                                {imagingOrders.labOrdertext}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <Table
                        className={classes.templateTable}
                        style={{
                          border: '1px solid #3397F3',
                          borderCollapse: 'initial',
                          marginTop: -1
                        }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Diagnosis Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Description</TableCell>
                          </TableRow>
                          {imagingOrders &&
                            imagingOrders.labOrderDiagnosis &&
                            imagingOrders.labOrderDiagnosis.length > 0 &&
                            imagingOrders.labOrderDiagnosis.map((item) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {item.code ? item.code : ''}
                                  </TableCell>
                                  <TableCell>
                                    {item.description ? item.description : ''}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {imagingOrders && imagingOrders.diaOrdertext && (
                            <TableRow>
                              <TableCell>
                                {imagingOrders.diaOrdertext}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  )}

                {physicianOrders &&
                  ((physicianOrders.labOrder &&
                    physicianOrders.labOrder.length > 0) ||
                    (physicianOrders.labOrderDiagnosis &&
                      physicianOrders.labOrderDiagnosis.length > 0)) && (
                    <Box className={classes.soapEntryItem}>
                      <span
                        style={{
                          backgroundColor: 'rgb(51, 151, 243)',
                          padding: 5,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}>
                        <Typography style={{ color: '#fff', fontWeight: 600 }}>
                          Physician Orders
                        </Typography>
                        <IconButton
                          onClick={() => editLabOrder(3)}
                          size="small"
                          style={{ color: '#fff' }}>
                          <EditOutlined />
                        </IconButton>
                      </span>
                      <Table
                        className={classes.templateTable}
                        style={{
                          border: '1px solid #3397F3',
                          borderCollapse: 'initial'
                        }}>
                        <TableBody>
                          {physicianOrders &&
                            physicianOrders.labOrder &&
                            physicianOrders.labOrder.length > 0 &&
                            physicianOrders.labOrder.map((item) => {
                              return (
                                <TableRow>
                                  <TableCell>
                                    {item.text ? item.text : ''}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {physicianOrders && physicianOrders.labOrdertext && (
                            <TableRow>
                              <TableCell>
                                {physicianOrders.labOrdertext}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                      <Table
                        className={classes.templateTable}
                        style={{
                          border: '1px solid #3397F3',
                          borderCollapse: 'initial',
                          marginTop: -1
                        }}>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>Diagnosis Details</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Code</TableCell>
                            <TableCell>Description</TableCell>
                          </TableRow>
                          {physicianOrders &&
                            physicianOrders.labOrderDiagnosis &&
                            physicianOrders.labOrderDiagnosis.length > 0 &&
                            physicianOrders.labOrderDiagnosis.map(
                              (item) => {
                                return (
                                  <TableRow>
                                    <TableCell>
                                      {item.code ? item.code : ''}
                                    </TableCell>
                                    <TableCell>
                                      {item.description ? item.description : ''}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                          {physicianOrders && physicianOrders.diaOrdertext && (
                            <TableRow>
                              <TableCell>
                                {physicianOrders.diaOrdertext}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </Box>
                  )}
              </Box>
            )}
            {role !== Roles.staff && (
              <Box className={classes.soapEntryItem}>
                <div className={classes.soapEntryBtn}>
                  {values && !values.signed && (
                    <Button
                      color="primary"
                      onClick={() => handleOpenTemplates(3)}
                      size="small"
                      variant="contained">
                      <DescriptionOutlined />
                    </Button>
                  )}
                </div>

                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textfiledSize
                    },
                    readOnly: values && values.signed && isEdit ? true : false
                  }}
                  label="Assessment Plan"
                  multiline
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('assessmentplan', event.target.value)
                  }
                  value={
                    values && values.assessmentplan ? values.assessmentplan : ''
                  }
                  variant="outlined"
                />
              </Box>
            )}

            <Box className={classes.soapEntryItem}>
              <div
                className={clsx(classes.soapEntryBtn2, classes.soapEntryBtn)}>
                {values && !values.signed && (
                  <>
                    <Button
                      color="primary"
                      onClick={() => handleOpenCPTICD(true)}
                      size="small"
                      variant="outlined">
                      CPT
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => handleOpenCPTICD(false)}
                      size="small"
                      variant="contained">
                      ICD 10 &amp; Diagnosis
                    </Button>
                  </>
                )}
              </div>
            </Box>

            {cptList && cptList.length > 0 && (
              <Box className={classes.soapEntryItem}>
                <span
                  style={{
                    backgroundColor: 'rgb(51, 151, 243)',
                    padding: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Typography style={{ color: '#fff', fontWeight: 600 }}>
                    CPT
                  </Typography>
                </span>
                <Table
                  className={classes.templateTable}
                  style={{
                    border: '1px solid #3397F3',
                    borderCollapse: 'initial'
                  }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width="25%">Code</TableCell>
                      <TableCell colSpan={2}>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cptList &&
                      cptList.length > 0 &&
                      cptList.map((item, idx) => {
                        return (
                          <TableRow id={'cpt' + idx}>
                            <TableCell>{item.code ? item.code : ''}</TableCell>
                            <TableCell>
                              {item.description ? item.description : ''}
                            </TableCell>
                            <TableCell>
                              <DeleteOutlined
                                onClick={() =>
                                  handleOpenDeleteCPTICD(true, idx, item.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            )}
            {diagnosisList && diagnosisList.length > 0 && (
              <Box className={classes.soapEntryItem}>
                <span
                  style={{
                    backgroundColor: 'rgb(51, 151, 243)',
                    padding: 5,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                  <Typography style={{ color: '#fff', fontWeight: 600 }}>
                    Diagnosis
                  </Typography>
                </span>
                <Table
                  className={classes.templateTable}
                  style={{
                    border: '1px solid #3397F3',
                    borderCollapse: 'initial'
                  }}>
                  <TableHead>
                    <TableRow>
                      <TableCell width="25%">Code</TableCell>
                      <TableCell colSpan={2}>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {diagnosisList &&
                      diagnosisList.length > 0 &&
                      diagnosisList.map((item, idx) => {
                        return (
                          <TableRow id={'icd' + idx}>
                            <TableCell>{item.code ? item.code : ''}</TableCell>
                            <TableCell>
                              {item.description ? item.description : ''}
                            </TableCell>
                            <TableCell>
                              <DeleteOutlined
                                onClick={() =>
                                  handleOpenDeleteCPTICD(false, idx, item.id)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            )}
            {values && values.signed && isEdit && (
              <Box className={classes.soapEntryItem}>
                <TextField
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textfiledSize
                    }
                  }}
                  label="Addendum"
                  multiline
                  onBlur={saveTempData}
                  onChange={event =>
                    handleFieldChange('addendum', event.target.value)
                  }
                  value={values && values.addendum ? values.addendum : ''}
                  variant="outlined"
                />
              </Box>
            )}
            <Box className={classes.soapEntryAction}>
              {values.signed && isEdit ? (
                <Button
                  color="secondary"
                  onClick={() => setValues({ initialUserState })}
                  size="small"
                  variant="contained">
                  Cancel
                </Button>
              ) : (
                <>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined">
                    <Select
                      onChange={event =>
                        handleFieldChange('providerId', event.target.value)
                      }
                      value={values && values.providerId}>
                      <MenuItem value="">Route</MenuItem>
                      {pros &&
                        pros.map(item => (
                          <MenuItem value={item.value}>{item.key}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Button
                    color="primary"
                    onClick={() => handleOpenDelete()}
                    size="small"
                    style={{ backgroundColor: '#b9b9b9' }}
                    variant="contained">
                    Delete
                  </Button>
                  {role !== Roles.staff && (
                    <Button
                      color="primary"
                      disabled={
                        !(
                          values &&
                          (values.subjective !== '' ||
                            values.objective !== '' ||
                            values.assessmentplan !== '')
                        )
                      }
                      onClick={() => btnSaveSOAPPatient()}
                      size="small"
                      variant="contained">
                      Save
                    </Button>
                  )}
                </>
              )}
              {role !== Roles.staff && (
                <Button
                  color="secondary"
                  disabled={
                    values && values.signed
                      ? !values.addendum
                      : !(
                        values &&
                        (values.subjective !== '' ||
                          values.objective !== '' ||
                          values.assessmentplan !== '')
                      )
                  }
                  onClick={() => btnSaveSOAPPatientSign()}
                  size="small"
                  variant="contained">
                  Save &amp; E-Sign
                </Button>
              )}
            </Box>
            {openVsHx && renderVsHx()}
            {opentemplates && renderTemplates()}
            {openRx && renderRx()}
            {openOrders && renderOrders()}
            {openCPTICD && renderCPTICD()}
            {succMsg && renderSuccess()}
            {opendelete && renderDeleteDialogue()}
            {succDeleteMsg && renderDeleteSuccess()}
            {succDeleteMsgCPTICD && renderDeleteCPTICDSuccess()}
            {openDeleteCPTICD && renderDeleteCPTICDDialogue()}
          </Box>
        </PerfectScrollbar>
      </Grid>
      {!isPast ? (
        <Grid item xs={6}>
          <SoapPast
            handleCopyPast={props.handleCopyPast}
            handleEditPast={props.handleEditPast}
            isPast={isPast}
            // patientId={patientId}
            patientInfo={patientInfo}
            ref={props.childRef}
            setPast={props.setPast}
          />
        </Grid>
      ) : null}
    </>
  );
});

SoapEntry.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  downloadPDFRx,
  getProviders,
  saveSOAPPatientData,
  savePrescription,
  savelabOrders,
  saveImagingOrders,
  savePhysicianOrders,
  saveDiagnosis,
  downloadPDFLab,
  downloadPDFImage,
  downloadPDFPhysician,
  getPastSoapNoteById,
  deleteSoap,
  deleteDiagnosis,
  resetValue,
  saveTempData,
  clearSoap
};

const mapStateToProps = state => ({
  role: state.profile && state.profile.role,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  user: state.profile && state.profile,
  patientInfo: state.soap && state.soap.patientInfo,
  providers: state.util.providers,
  soap: state.soap && state.soap.unsavedData
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(SoapEntry));
