import React, { useState, useEffect } from 'react';

import {
Card,
Grid,
Button,
TextField,
Typography,
Box,
IconButton,
Modal,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Tooltip
} from '@material-ui/core';
import {
DomainOutlined,
LanguageOutlined,
AssignmentIndOutlined,
BallotOutlined,
RoomOutlined,
FlagOutlined,
CameraAlt
} from '@material-ui/icons';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../../style';
import orgLogo from './../../../../../assets/images/org_avatar.png';
import { connect } from 'react-redux';
import { getStates } from 'services/util/action';
import { uploadFile } from 'services/ehr/action';
import { b64toBlob } from 'util/helper';
import { ProfilePic } from 'views/Modals/';
import schemaforInfo from './schemaforInfo';
import validate from 'validate.js';
import MessageBox from 'util/messageBox';
import { Prompt } from 'react-router-dom';
import { getOrganizationbyId } from 'services/ehr/action';
const Info = props => {
const { classes, className, ehrID, organization, states, ...rest } = props;
const [isEdit, setIsEdit] = useState(false);
const [values, setValues] = useState({});
const [alert, setAlert] = useState(false);
const NA = 'Not Available';
const [avatarUpload, setAvatarUpload] = useState({
src: null,
isDialogOpen: false,
name: '',
type: ''
});

const [showCancelPopup, setShowCancelPopup] = useState(false);
let [isBlocking, setIsBlocking] = useState(false);
useEffect(() => {
props.getStates();
}, []);

useEffect(() => {
if (organization !== undefined) {
if (organization.billing === null) {
organization.billing = {
id: 0,
name: '',
creditcardnumber: '',
cvv: '',
expirationmonth: '',
expirationyear: '',
address: {
id: 0,
address: '',
city: '',
state: '',
zip: '',
address_Plain: '',
city_Plain: '',
state_Plain: '',
zip_Plain: ''
}
};
}
setValues(organization);
}
}, [organization]);

const validateForm = () => {
const newState = { ...values };
const errors = validate(values, schemaforInfo);
newState.errors = errors || {};
newState.isValid = errors ? false : true;
setValues(newState);
return newState.isValid;
};
const getValuesofConfig = () => {
if (validateForm()) {
props.getValuesofConfig(values);
setIsEdit(false);
}
};

const setEdit = () => {
setIsEdit(!isEdit);
};

const handleKeyDown = e => {

if (e.key === 'Backspace' && e.target.value=="https://" ) {
e.preventDefault();
}
};
const handleFieldChange = (field, value, isAddress = false) => {
const newState = { ...values };
const regex = /^[0-9\b]+$/;
if (field === 'companyTaxId' && value && !regex.test(value)) {
value = newState[field] || '';
}

if (isAddress) {
let billing = values.billing !== null ? values.billing : setValues();
if (billing) {
if (field === 'name') {
billing[field] = value;
newState['billing'] = billing;
}
let addr = billing && billing.address;

if (addr) {
if (field === 'zip' && value && !regex.test(value)) {
value = addr[field] || '';
}
addr[field] = value;
newState['address'] = addr;
}
}
} else {
newState[field] = value;
}
if (values) {
setIsBlocking(true);
}
setValues(newState);
};

const handleSaveOrg = async () => {
getValuesofConfig(values);
setIsBlocking(false);
};

const handleClose = () => {
setAlert(false);
};

/////image upload

const handleOpenAvatarModal = () => {
setAvatarUpload({ ...avatarUpload, isDialogOpen: true });
};

const handleAvatarCrop = preview => {
setAvatarUpload({ ...avatarUpload, preview: preview });
};

const handleAvatarClose = () => {
setAvatarUpload({ ...avatarUpload, preview: null });
};

const handleBeforeAvatarFileLoad = elem => {
const maxSize = 153600;
const file = elem.target.files[0];
console.log(file.size);
if (file.size > maxSize) {
setAlert('Logo file size cannot be more than 150Kb');
elem.target.value = '';
}

if (
!(file.type.includes('image/png') || file.type.includes('image/jpeg'))
) {
setAlert('Logo file should be of type png/jpeg');
elem.target.value = '';
}
setAvatarUpload({
...avatarUpload,
name: file.name,
type: file.type
});
};

const handleCloseAll = () => {
setValues({ ...values, succMsg: false });
};

const handleCancel = () => {
setShowCancelPopup(true);
};

const handleCancelYes = () => {
setShowCancelPopup(false);
props.getOrganizationbyId(ehrID);
setIsEdit(false);
};

const handleCancelNo = () => {
setShowCancelPopup(false);
setIsEdit(true);
};

const handleAvatarDialogClose = () => {
setAvatarUpload({ ...avatarUpload, isDialogOpen: false, preview: null });
};

const handleAvatarDialogOK = async () => {
setAvatarUpload({
...avatarUpload
//isLoading: true
});

const blob = b64toBlob(
avatarUpload.preview,
avatarUpload.name,
avatarUpload.type
);
const res = await props.uploadFile(blob, ehrID);

if (res) {
setAvatarUpload({
...avatarUpload,
isDialogOpen: false
});
setIsEdit(false);
}
};

return (
<Card {...rest} className={clsx(classes.cardRoot, className)}>
<Prompt
when={isBlocking}
message="There are unsaved changes, do you wish to discard them?"
/>
{!isEdit ? (
<Box className={classes.infoBoxWrap}>
<Box className={classes.infoBoxLeft}>
<img
src={
organization && organization.ehrLogoFileURI
? organization.ehrPublicLogoURL
: orgLogo
}
style={{ height: '100px', width: '100px' }}
/>
<Button
size="small"
variant="contained"
color="primary"
onClick={() => setEdit()}>
Edit
</Button>
</Box>
<Box className={classes.infoBoxRight}>
<Grid container spacing={0} className={classes.infoBoxItems}>
<Grid item sm={6} md={4}>
<DomainOutlined />
<Typography variant="h3">
{organization && organization.organizationname ? (
  organization.organizationname
) : (
  <i>{NA}</i>
)}
</Typography>
<Typography variant="h4">Organization Name</Typography>
</Grid>
<Grid item sm={6} md={4}>
<LanguageOutlined />
<Typography variant="h3" noWrap="true">
{organization && organization.websitename ? (
  organization.websitename
) : (
  <i>{NA}</i>
)}
</Typography>
<Typography variant="h4">Organization Website</Typography>
</Grid>
<Grid item sm={6} md={4}>
<BallotOutlined />
<Typography variant="h3">
{organization && organization.organizationNPI ? (
  organization.organizationNPI
) : (
  <i>{NA}</i>
)}
</Typography>
<Typography variant="h4">Organization NPI</Typography>
</Grid>
<Grid item sm={6} md={4}>
<AssignmentIndOutlined />
<Typography variant="h3">
{organization &&
organization.billing &&
organization.billing.name ? (
  organization.billing.name
) : (
  <i>{NA}</i>
)}
</Typography>
<Typography variant="h4">Billing Name</Typography>
</Grid>
<Grid item sm={6} md={4}>
<FlagOutlined />
<Typography variant="h3">
{organization && organization.companyTaxId ? (
  organization.companyTaxId
) : (
  <i>{NA}</i>
)}
</Typography>
<Typography variant="h4">
Company Tax ID/ Sole Proprietor SS#
</Typography>
</Grid>
<Grid item sm={6} md={4}>
<RoomOutlined />
<Typography variant="h3">
{organization &&
organization.billing &&
organization.billing.address ? (
  (organization.billing.address.address
    ? organization.billing.address.address + ', '
    : '') +
  (organization.billing.address.city
    ? organization.billing.address.city + ', '
    : ' ') +
  (organization.billing.address.state
    ? organization.billing.address.state + ' '
    : ' ') +
  (organization.billing.address.zip
    ? organization.billing.address.zip
    : ' ')
) : (
  <i>{NA}</i>
)}
</Typography>
<Typography variant="h4">Billing Address</Typography>
</Grid>
</Grid>
</Box>
</Box>
) : (
<Box className={classes.infoBoxWrap}>
<Box className={classes.infoBoxLeft}>
<Box style={{ width: 110, position: 'relative' }}>
<img
src={
organization && organization.ehrLogoFileURI
  ? organization.ehrPublicLogoURL
  : orgLogo
}
style={{ height: '100px', width: '100px' }}
/>
<Tooltip title="Upload EHR logo">
<IconButton
style={{ position: 'absolute', right: 0, bottom: 0 }}
color="primary"
size="small"
aria-label="upload picture"
component="span"
onClick={() => handleOpenAvatarModal()}>
<CameraAlt fontSize="inherit" />
</IconButton>
</Tooltip>
</Box>
</Box>
<Box className={classes.infoBoxRight}>
<Grid container spacing={0} className={classes.infoBoxItems}>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="Organization Name"
name="pName"
required
variant="outlined"
color="Primary"
error={values.errors && values.errors.organizationname}
value={values.organizationname}
onChange={event =>
  handleFieldChange('organizationname', event.target.value)
}
/>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="Organization Website"
name="pName"
variant="outlined"
color="Primary"
onKeyDown={handleKeyDown}

value={values?.websitename=="" && !values?.websitename.includes('https://')?
('https://'+values.websitename ):
(values.websitename)}


onChange={event =>
  handleFieldChange('websitename', event.target.value)
}
/>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="Organization NPI"
name="pName"
variant="outlined"
color="Primary"
// errors={values.errors.organizationNPI}
value={values.organizationNPI}
onChange={event =>
  handleFieldChange('organizationNPI', event.target.value)
}
/>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="Billing Name"
name="pName"
variant="outlined"
color="Primary"
value={values.billing && values.billing.name}
onChange={event =>
  handleFieldChange('name', event.target.value, true)
}
/>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="Company Tax ID/ Sole Proprietor SS#"
name="pName"
variant="outlined"
color="Primary"
inputProps={{ maxLength: 20 }}
value={values.companyTaxId}
onChange={event =>
  handleFieldChange('companyTaxId', event.target.value)
}
/>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="Billing Address - Street/P.O Box "
name="pName"
variant="outlined"
color="Primary"
value={values.billing && values.billing.address.address}
onChange={event =>
  handleFieldChange(
    'address',
    event.target.value,

    true
  )
}
/>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="City"
name="pName"
variant="outlined"
color="Primary"
value={values.billing && values.billing.address.city}
onChange={event =>
  handleFieldChange('city', event.target.value, true)
}
/>
</Grid>
<Grid item sm={6} md={4} className={classes.stateList}>
<TextField
fullWidth
name="State"
select
SelectProps={{ native: true }}
variant="outlined"
value={values.billing && values.billing.address.state}
onChange={event =>
  handleFieldChange('state', event.target.value, true)
}>
{states &&
  states.map(item => (
    <option value={item.value}>{item.key}</option>
  ))}
</TextField>
</Grid>
<Grid item sm={6} md={4}>
<TextField
InputLabelProps={{
  classes: {
    focused: classes.labelFocused
  }
}}
fullWidth
label="ZIP"
name="pName"
variant="outlined"
color="Primary"
inputProps={{ maxLength: 5 }}
value={values.billing && values.billing.address.zip}
onChange={event =>
  handleFieldChange('zip', event.target.value, true)
}
/>
</Grid>

<Grid item xs={12} style={{ textAlign: 'right' }}>
<Button
style={{ backgroundColor: '#c1c1c1', marginRight: 10 }}
variant="contained"
color="secondary"
onClick={() => handleCancel()}>
Cancel
</Button>
<Button
variant="contained"
color="primary"
onClick={handleSaveOrg}>
Save
</Button>
</Grid>
</Grid>
</Box>
</Box>
)}

<Dialog
disableBackdropClick
disableEscapeKeyDown
maxWidth="xs"
aria-labelledby="confirmation-dialog-title"
open={showCancelPopup}>
<DialogTitle id="confirmation-dialog-title">
Account Settings
</DialogTitle>
<DialogContent>
<DialogContentText id="alert-dialog-description">
Are you sure you want to cancel ?
</DialogContentText>
</DialogContent>
<DialogActions>
<Button onClick={() => handleCancelYes()} color="primary">
Yes
</Button>
<Button onClick={handleCancelNo} color="primary">
No
</Button>
</DialogActions>
</Dialog>

<MessageBox
open={values.succMsg}
variant="success"
onClose={handleCloseAll}
message="Organization profile saved successfully"
/>

<Dialog
open={alert}
onClose={handleClose}
mess
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description">
<DialogTitle id="alert-dialog-title">
{'Incomplete information'}
</DialogTitle>
<DialogContent>
<DialogContentText id="alert-dialog-description">
{alert}
</DialogContentText>
</DialogContent>
<DialogActions>
<Button onClick={handleClose} color="primary" autoFocus>
OK
</Button>
</DialogActions>
</Dialog>

<Modal
aria-labelledby="Profile photo"
aria-describedby="Profile photo"
open={avatarUpload.isDialogOpen}
onClose={handleAvatarDialogClose}>
<ProfilePic
onCrop={handleAvatarCrop}
onClose={handleAvatarClose}
onBeforeFileLoad={handleBeforeAvatarFileLoad}
onSubmit={handleAvatarDialogOK}
src={avatarUpload.src}
preview={avatarUpload.preview}
onDialogClose={handleAvatarDialogClose}
//isLoading={avatarUpload.isLoading}
/>
</Modal>
</Card>
);
};

Info.propTypes = {
className: PropTypes.string
};

const mapDispatchToProps = {
getStates,
uploadFile,
getOrganizationbyId
};

const mapStateToProps = state => ({
states: state.util.states,
ehrID: state.profile && state.profile.ehrID,
organization: state.ehr && state.ehr.organization
});

export default withRouter(
connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Info))
);
