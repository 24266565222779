import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Box,
  Tooltip
} from '@material-ui/core';
import {
  EditOutlined,
  GetAppOutlined,
  BlockOutlined,
  SaveOutlined
} from '@material-ui/icons';
import { computeAge } from 'util/helper';

const PrimaryInfo = props => {
  const { classes, className, patientinformation, ...rest } = props;

  return (
    <>
      <Grid className={classes.cardHeader} item xs={12}>
        <Typography variant="h2">Patient Information</Typography>
      </Grid>
      <Card {...rest} className={clsx(classes.cardRoot, className)}>
        <CardContent style={{ margin: -16 }}>
          <Grid container>
            <Grid
              className={classes.cardSubHeader}
              item
              style={{ borderTop: 'none' }}
              xs={12}>
              <Typography variant="h3">MR# {patientinformation.id}</Typography>
              <Box className={classes.cardSubHeaderActions}>
                <Tooltip title="Download">
                  <IconButton
                    onClick={() => props.handleDownloadPatientInsurance()}
                    size="small">
                    <GetAppOutlined />
                  </IconButton>
                </Tooltip>
                {/* <IconButton
                  onClick={() => props.handlePrintPatientInsurance()}
                  size="small">
                  <PrintOutlined />
                </IconButton> */}
                {props.isEdit ? (
                  <Tooltip title="Save">
                    <IconButton onClick={props.handleSave} size="small">
                      <SaveOutlined />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit">
                    <IconButton onClick={props.setEdit} size="small">
                      <EditOutlined />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={
                      props.isEdit && !props.isSaved
                        ? props.handleCancelSave
                        : props.goBack
                    }
                    size="small">
                    <BlockOutlined />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid className={classes.pInfoWrap} container item xs={12}>
              <Grid item xs={6}>
                <Typography variant="h2">
                  {patientinformation.lastname
                    ? patientinformation.lastname
                    : ''}
                  &nbsp;
                  {patientinformation.firstname
                    ? patientinformation.firstname
                    : ''}
                </Typography>
                <Typography variant="h4">
                  {patientinformation.gender
                    ? patientinformation.gender + ', '
                    : ''}
                  {patientinformation.dob && patientinformation.dob !== 'Invalid date' && patientinformation.dob !== ''
                    ? computeAge(patientinformation.dob) + ' yrs. '
                    : ''}

                  {patientinformation.address
                    ? (patientinformation?.address?.address
                      ? patientinformation?.address?.address
                      : '') + ' ' + (patientinformation?.address?.city
                        ? patientinformation?.address?.city
                        : '') + ' ' + (patientinformation?.address?.state
                          ? patientinformation?.address?.state
                          : '') + ' ' + (patientinformation?.address?.zip
                            ? patientinformation?.address?.zip
                            : '')
                    : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Service Location</Typography>
                <Typography variant="h4">
                  {patientinformation.practice
                    ? (patientinformation.practice.companyname
                      ? patientinformation.practice.companyname
                      : '') + ' ' + (patientinformation?.practice?.address?.address
                        ? patientinformation?.practice?.address?.address
                        : '') + ', ' + (patientinformation?.practice?.address?.city
                          ? patientinformation?.practice?.address?.city
                          : '') + ' ' + (patientinformation?.practice?.address?.state
                            ? patientinformation?.practice?.address?.state
                            : '')
                    : 'NA'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

PrimaryInfo.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(PrimaryInfo));
