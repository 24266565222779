/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/styles';
import styles from '../../Default/style';
import {
  IconButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress
} from '@material-ui/core';
import { ExpandMore, CheckCircleOutlineOutlined, BlockOutlined } from '@material-ui/icons';
import { computeAge } from 'util/helper';
import moment from 'moment';
import { connect } from 'react-redux';
import { 
  getValsByCategories,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  DeactivatePatientDocumentStatus,
  ActivatePatientDocumentStatus
} from 'services/home/action';
import {   downloadPDF } from 'services/orders/action';

const PatientDetails = props => {
  const {
    classes,
    patient,
    patientCharts,
    clinicalDocuments,
    miscDocuments,
    ehrID,
    userID,
    categories,
    allorders,
    isPtDoc, 
    dataDocViewer,
    isAllClinical,
    getPatientAllClinicalDocuments,
    getPatientAllMiscellaneousDocuments,
    getPatientCharts,
    patientDocuments,
    total
  } = props;
  const [page, setPage] = useState(0) 
  const [isFetching, setIsFetching] = useState(total>10) 

  const handleChangeCategory = async category => {
    await props.getValsByCategories(ehrID, patient.id, category);
  };

  const showPatientDocument = async (item, isClinical) => {
    props.handleOpenDoc(
      item.documentRoute.patientid,
      item.documentRoute.patientDocumentId,
      item.documentRoute.routeTo,
      isClinical
    );
  };

  const handleInactivateDocument = async (item, isActive, category = '') => {
    if (isActive) {
      const res = await props.DeactivatePatientDocumentStatus(patient.id, item.id, item)

      if (res) {

        getPatientAllClinicalDocuments(patient.id, ehrID);
        getPatientAllMiscellaneousDocuments(patient.id, ehrID);
        props.getValsByCategories(ehrID, patient.id, category);
      }
    }
    else {
      const res = await props.ActivatePatientDocumentStatus(patient.id, item.id, item)
      if (res) {

        getPatientAllClinicalDocuments(patient.id, ehrID)
        getPatientAllMiscellaneousDocuments(patient.id, ehrID);
        props.getValsByCategories(ehrID, patient.id, category);
      }
    }


  };



  const showMiscDocument = async (item, isClinical) => {
   
    props.handleOpenMiscDoc(ehrID, patient.id, item.id, item, isClinical);
  };

  const psn = props.pastSoapNotes;
  const handleView = async (type, providerId, orderHx) => {
    var orderIds;
    if (type === 'Prescription') {
      orderIds = orderHx.medIds;
    }
    if (type === 'Lab Order') {
      orderIds = orderHx.labOrderIds;
    }
    if (type === 'Imaging Order') {
      orderIds = orderHx.imgOrderIds;
    }
    if (type === 'Physician Order') {
      orderIds = orderHx.phyOrderIds;
    }

    props
      .downloadPDF(ehrID, patient.id, providerId, type, orderIds)
      .then(res => {
        if (res.status) {
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
      });
  };

  const loadFunc = async (e) => {
    if (isFetching) {
      const res = await getPatientCharts(patient.id, page)
      if (res.last) {
        setIsFetching(false);
      }
      if (!res.last) {
        setIsFetching(true);
        setPage(res.number + 1);
      }
    }
  };

  return (
    <div className={classes.PatientDetailsWrap}>
      <div className={classes.PatientDetailsHead}>
        <span className={classes.PatientDetailsHeadInner}>
          <Typography onClick={props.goBack}>
            Patient :
            <b>{patient && patient.firstname + ' ' + patient.lastname}</b>
          </Typography>
          <Typography>
            DOB :
            <b>
              {patient && patient.dob
                ? moment(patient.dob).format('MM/DD/YYYY') +' (' +
                (computeAge(patient.dob) + ' yrs)')
                : 'NA'}
            </b>
          </Typography>
          <Typography>
            MR# :<b>{patient && patient.id}</b>
          </Typography>
        </span>
        {!props.isSoapView && (
          <Button
            className={classes.backBtn}
            onClick={() => props.goBack()}
            size="small"
            variant="contained">
            Go Back
          </Button>
        )}
      </div>
      {!isPtDoc &&
        !props.isSoapView &&
        patientCharts &&
        patientCharts.length > 0 && (
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore color="color" />}
              id="panel1a-header">
              <Typography className={classes.heading}>
                PAST soap notes
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PerfectScrollbar style={{  flex: 1  }}  onYReachEnd={loadFunc}>
                <div style={{ maxHeight: 200 }}>
                  <Table className={classes.plainTable}>
                    <TableBody>
                      {patientCharts &&
                        patientCharts.length > 0 &&
                        patientCharts.map(item => (
                          <TableRow
                            onClick={() =>
                              props.handleOpenViewSoap(
                                item.patient.id,
                                item.id
                              )
                            }>
                            <TableCell>
                              <a>
                                {item.visitdate
                                  ? moment(item.visitdate).format(
                                    'MM/DD/YYYY'
                                  )
                                  : ''}
                              </a>
                            </TableCell>
                            <TableCell>
                              <a>
                                {item.soapnote &&
                                  item.soapnote.subjective &&
                                  item.soapnote.subjective.substring(0, 50)}
                              </a>
                            </TableCell>
                            <TableCell>
                              <a>
                                {item.routed && item.signed
                                  ? 'Signed by :' +(item.provider.firstname +
                                    ' ' +
                                    item.provider.lastname)
                                  : item.routed && !item.signed
                                    ? 'Routed to :' +
                                    item.provider.firstname +
                                    ' ' +
                                    item.provider.lastname
                                    : !item.routed && !item.signed
                                      ? 'Created by :' +
                                      item.provider.firstname +
                                      ' ' +
                                      item.provider.lastname
                                      : !item.routed && item.signed
                                        ? 'Signed by : ' + (item.provider.firstname +
                                          ' ' +
                                          item.provider.lastname)
                                        : ''}
                              </a>
                            </TableCell>
                          </TableRow>
                        ))}
                      {isFetching &&<div style={{display: 'flex', justifyContent: 'center', padding:10, position:'absolute', left:15, right:15,}}> <CircularProgress style={{alignContent:'center'}}/></div>}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
      {/* Patient Documents */}
      <ExpansionPanel
        className={classes.accordionWrap}
        defaultExpanded={psn ? false : true}>
        <ExpansionPanelSummary
          aria-controls="panel2a-content"
          className={classes.accordionHeader}
          expandIcon={<ExpandMore />}
          id="panel2a-header">
          <Typography className={classes.heading}>
            Patient Documents
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.accordionWrapInner}>
            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary
                aria-controls="panel1a-content"
                className={classes.accordionHeaderInner}
                expandIcon={<ExpandMore />}
                id="panel1a-header">
                <Typography className={classes.heading}>
                  All Clinical
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <PerfectScrollbar style={{ flex: 1 }}>
                  <div style={{ maxHeight: 200 }}>
                    <Table className={classes.plainTable}>
                      <TableBody>
                        {!clinicalDocuments && <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}> <CircularProgress style={{ alignContent: 'center' }} /></div>}
                        {clinicalDocuments &&
                          clinicalDocuments.length > 0 &&
                          clinicalDocuments.filter(c => c.isActive === true)
                            .map(item => (
                              <TableRow>
                                <TableCell onClick={() => showPatientDocument(item, true)} >
                                  <a style={{ color: item.documentRoute && item.documentRoute.signed ? '#3397f3' : 'red' ,cursor: 'pointer',}}>{item.title}</a>
                                </TableCell>

                                <TableCell>
                               {moment(item.documentCreated).format(
                                      'MM/DD/YYYY'
                                    )}
                                </TableCell>
                                <TableCell>
                                    {(item.providerInfo &&
                                      item.providerInfo.firstname) +
                                      ' ' +
                                      (item.providerInfo &&
                                        item.providerInfo.lastname)}
                                  </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => handleInactivateDocument(item, item.isActive,item.category.name)}
                                    size="small"
                                    title="Active"
                                  >
                                    <CheckCircleOutlineOutlined />
                                  </IconButton>
                                </TableCell>
                              </TableRow>

                            )

                            )}

                        {clinicalDocuments &&
                          clinicalDocuments.length > 0 &&
                          clinicalDocuments.filter(c => c.isActive === false)
                            .map(item => (


                              <TableRow  style={{ backgroundColor: '#e8e8e8' }}>

                                <TableCell onClick={() => showPatientDocument(item, true)}>
                                  <a style={{ color: item.documentRoute && item.documentRoute.signed ? '#3397f3' : 'red',cursor: 'pointer' }}>{item.title}</a>
                                </TableCell>
                               
                                <TableCell>
                             {moment(item.documentCreated).format(
                                      'MM/DD/YYYY'
                                    )}
                                 </TableCell>
                                <TableCell >
                                {(item.providerInfo &&
                                      item.providerInfo.firstname) +
                                      ' ' +
                                      (item.providerInfo &&
                                        item.providerInfo.lastname)}
                                  </TableCell>

                                <TableCell>

                                  <IconButton
                                    onClick={() => handleInactivateDocument(item, item.isActive,item.category.name)}
                                    size="small"
                                    title="Inctive"
                                    style={{ color: '#ccc' }}>
                                    <BlockOutlined />
                                  </IconButton>
                                </TableCell>

                              </TableRow>


                            )

                            )}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {categories &&
              categories.clinical &&
              categories.clinical.map(item => (
                <ExpansionPanel
                  onClick={() => handleChangeCategory(item.name)}>
                  <ExpansionPanelSummary
                    aria-controls="panel1a-content"
                    className={classes.accordionHeaderInner}
                    expandIcon={<ExpandMore />}
                    id="panel1a-header">
                    <Typography className={classes.heading}>
                      {item.name}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.plainTable}>  
                      <TableBody> 
                        {patientDocuments &&
                        patientDocuments[item?.name] &&
                        patientDocuments[item?.name].length > 0 &&
                        patientDocuments[item.name].filter(c => c.isActive === true).length > 0 &&
                        patientDocuments[item.name].filter(c => c.isActive === true).map(itemdetail => (
                          <TableRow
                            >
                            <TableCell onClick={() => showPatientDocument(itemdetail, true)}>
                              <a style={{ color: itemdetail.documentRoute && itemdetail.documentRoute.signed ? '#3397f3' : 'red' ,cursor: 'pointer'}}>{itemdetail.title}</a>
                            </TableCell>
                            <TableCell >
                                {moment(
                                  itemdetail.documentCreated
                                ).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell>
                                {(itemdetail?.providerInfo &&
                                  itemdetail?.providerInfo?.firstname) +
                                  ' ' +
                                  (itemdetail?.providerInfo &&
                                    itemdetail?.providerInfo?.lastname)}
                           </TableCell>
                            <TableCell>
                                  <IconButton
                                    onClick={() => handleInactivateDocument(itemdetail, itemdetail.isActive,item.name)}
                                    size="small"
                                    title="Active"
                                  >
                                    <CheckCircleOutlineOutlined />
                                  </IconButton>
                                </TableCell>
                          </TableRow>
                        ))}

                        {patientDocuments &&
                        patientDocuments[item?.name] &&
                        patientDocuments[item?.name].length > 0 &&
                        patientDocuments[item.name].filter(c => c.isActive === false).length > 0 &&
                        patientDocuments[item.name].filter(c => c.isActive === false).map(itemdetail => (
                          <TableRow style={{ backgroundColor: '#e8e8e8' }}
                            >
                            <TableCell onClick={() => showPatientDocument(itemdetail, true)}>
                              <a style={{ color: itemdetail.documentRoute && itemdetail.documentRoute.signed ? '#3397f3' : 'red',cursor: 'pointer' }}>{itemdetail.title}</a>
                            </TableCell>
                            <TableCell >
                                {moment(
                                  itemdetail.documentCreated
                                ).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell >
                            {(itemdetail?.providerInfo &&
                                  itemdetail?.providerInfo?.firstname) +
                                  ' ' +
                                  (itemdetail?.providerInfo &&
                                    itemdetail?.providerInfo?.lastname)}
                           </TableCell>
                            <TableCell>
                                  <IconButton
                                    onClick={() => handleInactivateDocument(itemdetail, itemdetail.isActive,item.name)}
                                    size="small"
                                    title="Inactive"
                                    style={{ color: '#ccc' }}>
                                    <BlockOutlined />
                                  </IconButton>
                                </TableCell>
                          </TableRow>
                        ))}
                        {  patientDocuments &&
                        patientDocuments[item?.name] &&
                        patientDocuments[item?.name].length ===0 && 'No ' +  [item.name] + ' documents for this patient'} 
                      </TableBody>
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}

            <ExpansionPanel defaultExpanded>
              <ExpansionPanelSummary
                aria-controls="panel1a-content"
                className={classes.accordionHeaderInner}
                expandIcon={<ExpandMore />}
                id="panel1a-header">
                <Typography className={classes.heading}>
                  All Miscellaneous
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <PerfectScrollbar style={{ flex: 1 }}>
                  <div style={{ maxHeight: 200 }}>
                    <Table className={classes.plainTable}>
                      <TableBody>
                        {miscDocuments &&
                          miscDocuments.length > 0 &&
                          miscDocuments.filter(c => c.isActive === true).map(item => (
                            <TableRow
                              >
                              <TableCell onClick={() => showMiscDocument(item, false)}>
                                <a style={{ color: '#3397f3',cursor: 'pointer' }}>{item.title}</a>
                              </TableCell>
                              <TableCell>
                                
                                  {moment(item.documentCreated).format(
                                    'MM/DD/YYYY'
                                  )}
                               
                              </TableCell>
                              <TableCell>
                                  <IconButton
                                    onClick={() => handleInactivateDocument(item, item.isActive,item.category.name)}
                                    size="small"
                                    title="Active"
                                  >
                                    <CheckCircleOutlineOutlined />
                                  </IconButton>
                                </TableCell>
                            </TableRow>
                          ))}
                          {miscDocuments &&
                          miscDocuments.length > 0 &&
                          miscDocuments.filter(c => c.isActive === false).map(item => (
                            <TableRow style={{ backgroundColor: '#e8e8e8' }}
                             >
                              <TableCell  onClick={() => showMiscDocument(item, false)}>
                                <a style={{ color: '#3397f3',cursor: 'pointer' }}>{item.title}</a>
                              </TableCell>
                              <TableCell >
                               
                                  {moment(item.documentCreated).format(
                                    'MM/DD/YYYY'
                                  )}
                               
                              </TableCell>
                              <TableCell>

                                  <IconButton
                                    onClick={() => handleInactivateDocument(item, item.isActive,item.category.name)}
                                    size="small"
                                    title="Inactive"
                                    style={{ color: '#ccc' }}>
                                    <BlockOutlined />
                                  </IconButton>
                                </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </PerfectScrollbar>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            {categories &&
              categories.miscellaneous &&
              categories.miscellaneous.map(item => (
                <ExpansionPanel
                  onClick={() => handleChangeCategory(item.name)}>
                  <ExpansionPanelSummary
                    aria-controls="panel1a-content"
                    className={classes.accordionHeaderInner}
                    expandIcon={<ExpandMore />}
                    id="panel1a-header">
                    <Typography className={classes.heading}>
                      {item.name}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table className={classes.plainTable}>
                      <TableBody>
                        {patientDocuments &&
                          patientDocuments[item.name] &&
                          patientDocuments[item.name].length > 0 &&
                          patientDocuments[item.name].filter(c => c.isActive === true).length > 0 &&
                           patientDocuments[item.name].filter(c => c.isActive === true).map(itemdetail => (
                            <TableRow
                              >
                              <TableCell onClick={() => showMiscDocument(itemdetail, false) } >
                                <a style={{ color: '#3397f3' ,cursor: 'pointer'}}>{itemdetail.title}</a>
                              </TableCell>
                              <TableCell >
                              
                                  {moment(
                                    itemdetail.documentCreated
                                  ).format('MM/DD/YYYY')}
                              
                              </TableCell>
                              <TableCell>
                                  <IconButton
                                    onClick={() => handleInactivateDocument(itemdetail, itemdetail.isActive,itemdetail.category.name)}
                                    size="small"
                                    title="Active"
                                  >
                                    <CheckCircleOutlineOutlined />
                                  </IconButton>
                                </TableCell>
                            </TableRow>
                          ))}

                          {patientDocuments &&
                          patientDocuments[item.name] &&
                          patientDocuments[item.name].length > 0 &&
                          patientDocuments[item.name].filter(c => c.isActive === false).length > 0 &&
                           patientDocuments[item.name].filter(c => c.isActive === false).map(itemdetail => (
                            <TableRow style={{ backgroundColor: '#e8e8e8' }}
                            >
                            <TableCell onClick={() =>   showMiscDocument(itemdetail, false) } >
                              <a style={{ color: '#3397f3',cursor: 'pointer' }}>{itemdetail.title}</a>
                            </TableCell>
                            <TableCell >
                            
                                {moment(
                                  itemdetail.documentCreated
                                ).format('MM/DD/YYYY')}
                   
                            </TableCell>
                            <TableCell>
                                <IconButton
                                  onClick={() => handleInactivateDocument(itemdetail, itemdetail.isActive,itemdetail.category.name)}
                                  size="small"
                                  title="Inctive"
                                  style={{ color: '#ccc' }}
                                >
                                    <BlockOutlined />
                                  
                                </IconButton>
                              </TableCell>
                          </TableRow>
                        ))} 
                        {  patientDocuments &&
                        patientDocuments[item?.name] &&
                        patientDocuments[item?.name].length ===0 && 'No ' +  [item.name] + ' documents for this patient'} 
                  </TableBody>
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {/* Order History */}
      <ExpansionPanel defaultExpanded className={classes.accordionWrap}>
        <ExpansionPanelSummary
          aria-controls="panel1a-content"
          className={classes.accordionHeader}
          expandIcon={<ExpandMore />}
          id="panel1a-header">
          <Typography className={classes.heading}>Order History</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <PerfectScrollbar style={{ flex: 1 }}>
            <div style={{ maxHeight: 200 }}>
              <Table className={classes.plainTable}>
                <TableBody>
                  {allorders && allorders.length > 0 ? (
                    allorders.map(itemOrder => (
                      <TableRow
                        onClick={() =>
                          handleView(
                            itemOrder.type,
                            itemOrder.providerId,
                            itemOrder
                          )
                        }>
                        <TableCell align="center">
                          <a style={{ color: itemOrder.documentRoute && itemOrder.documentRoute.signed ? '#3397f3' : 'red' }}>
                            {itemOrder.createdDate
                              ? moment(itemOrder.createdDate).format(
                                'MM/DD/YYYY'
                              )
                              : null}
                          </a>
                        </TableCell>
                        <TableCell align="center">
                          <a style={{ color: itemOrder.documentRoute && itemOrder.documentRoute.signed ? '#3397f3' : 'red' }}>{itemOrder.type}</a>
                        </TableCell>
                        <TableCell>
                          <a style={{ color: itemOrder.documentRoute && itemOrder.documentRoute.signed ? '#3397f3' : 'red' }}>{itemOrder.providerName} </a>
                        </TableCell>
                        <TableCell>
                          <a style={{ color: itemOrder.documentRoute.signed ? '#3397f3' : 'red' }}>{itemOrder.recap} </a>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        colSpan={10}
                        style={{ textAlign: 'center' }}
                        className={classes.tableCell}>
                        No orders available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

PatientDetails.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { 
  getValsByCategories, 
  downloadPDF, 
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  DeactivatePatientDocumentStatus,
  ActivatePatientDocumentStatus
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  categories: state.home && state.home.categories,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result,
  locations: state.util.locations,
  profile: state && state.profile,
  casefiles: state.home && state.home.list,
  documentInfo: state.home && state.home.documentInfo,
  patientCharts: state.home && state.home.patientCharts,
  clinicalDocuments: state.home && state.home.clinicalDoc,
  miscDocuments: state.home && state.home.miscDoc,
  patientDocuments: state.home && state.home.patientDocuments
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PatientDetails));
