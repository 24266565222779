import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import {
  TextField,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  
} from '@material-ui/core';

import { connect } from 'react-redux';
import { sendResponse } from 'services/ptmessages/action';
import { loadOrders, downloadPDF } from 'services/orders/action';
import moment from 'moment';
import _ from 'lodash';

const PtStaffResponse = props => {
  const { classes, ptResponsepopupdata, ehrID, allorders } = props;
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [poploading, setPopLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    deleteOrder: {}
  });
  const [valid, setValid] = useState({
    isValid: false,
    touched: {},
    errors: {}
  });

  
  useEffect(() => {
    if (ptResponsepopupdata !== undefined || ptResponsepopupdata.length >0 ||ptResponsepopupdata!== null ||ptResponsepopupdata!=='') {
      setData(ptResponsepopupdata);
      if (
        ptResponsepopupdata.providerResponse !== '' &&
        ptResponsepopupdata.providerResponse !== undefined &&
        ptResponsepopupdata.providerResponse !== null
      ) {
        setValid({ ...valid, isValid: true });
      }
        props.loadOrders(ptResponsepopupdata.item.patientId);
    }
    
  }, [ptResponsepopupdata]);

  useEffect(() => {
    if (allorders !== undefined) {
      let allItems = []; 
      !_.isEmpty(allorders.content) && allorders.content.length>0 && allorders.content.map(item => {
     item.shared =
        ptResponsepopupdata &&
        ptResponsepopupdata.item.ptMessageOrdersList &&
        ptResponsepopupdata.item.ptMessageOrdersList.filter(c => c.orderHxId === item.id)
            .length > 0;
        allItems.push(item);
        
      });
      setOrders(allItems);
    }
   
  }, [allorders]);



  const handleView = async (type, providerId, orderHx) => {
    var orderIds;
    if (type === 'Prescription') {
      orderIds = orderHx.medIds;
    }
    if (type === 'Lab Order') {
      orderIds = orderHx.labOrderIds;
    }
    if (type === 'Imaging Order') {
      orderIds = orderHx.imgOrderIds;
    }
    if (type === 'Physician Order') {
      orderIds = orderHx.phyOrderIds;
    }

    props
      .downloadPDF(ehrID, ptResponsepopupdata.item.patientId,providerId, type, orderIds)
      .then(res => {
        if (res.status) {
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
      });
  };

  return (
   
      <React.Fragment>
       
        <div>
        <Box className={classes.modalBody}>
          <TextField 
          disabled
            placeholder="Write a message here..."
            multiline
            rows="6"
            variant="outlined"
            style={{ width: '100%', marginBottom: 10 }}
         
            value={ptResponsepopupdata.item.providerResponse}
            
          />
        </Box>
        <PerfectScrollbar>
        <div style={{ maxHeight: 160 }}>
        {orders &&
            orders.length > 0 &&
            orders.filter(c => c.shared === true).length > 0 && (
                  <Table
                    className={classes.generalTable}
                    style={{ marginBottom: 10 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell style={{ minWidth: 122 }}>Type</TableCell>
                        <TableCell style={{ minWidth: 122 }}>
                          Phycisian
                        </TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                   
                    <TableBody>
                      {orders && orders
                      .map( sharedorder=> {
                        if(sharedorder.shared){
                       
                     return (  
                      <TableRow
                      //  style={{ cursor: 'pointer' }}
                      onClick={() =>
                       handleView(
                         sharedorder.type,
                         sharedorder.providerId,
                         sharedorder
                       )
                     }
                     style={{ cursor: 'pointer' }}>
                        
                       <TableCell>
                         {sharedorder.createdDate
                           ? moment(sharedorder.createdDate).format(
                               'MM/DD/YYYY'
                             )
                           : null}
                          
                       </TableCell>
                       <TableCell noWrap="true">
                         <div className={classes.ellipsisMode}>
                           <Typography>{sharedorder.type}</Typography>
                          </div>
                       </TableCell>
                       <TableCell noWrap="true">
                         <div className={classes.ellipsisMode}>
                           <Typography>
                             {sharedorder.providerName}
                           </Typography>
                           
                         </div>
                       </TableCell>
                       <TableCell noWrap="true">
                         <div className={classes.ellipsisMode}>
                        
                            <Typography>
                            {/* <RouterLink
                         to="#"
                         onClick={() =>
                           handleView(
                             sharedorder.type,
                             sharedorder.providerId,
                             sharedorder
                           )
                         }> */}
                              {sharedorder.recap}
                              {/* </RouterLink> */}
                              </Typography>
                         </div>
                       </TableCell>
                      
                       
                     </TableRow>
                     )
                              }
                                })} 
                    </TableBody>
                   
                  </Table>
                  )}      
                </div>
                </PerfectScrollbar>
       
   </div>
  
      </React.Fragment>
   
   
 
  );
};

PtStaffResponse.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  sendResponse,
  loadOrders,
  downloadPDF
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PtStaffResponse))
);
