/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Roles } from 'util/enum';
import {
  Typography,
  TextField,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import styles from './../../../style';
import { computeAge } from 'util/helper';
import moment from 'moment';
import { downloadPDFSoapHP } from 'services/soap/action';
import { connect } from 'react-redux'; 

const SoapEntry = props => {
  const {
    classes,
    role,
    soap,
    labOrders,
    imagingOrders,
    physicianOrders,
    ehrID,
    userID,
    diagnosisList,
    cptList,
    prescriptions
  } = props;

  const handlePrint = async printForm => {
    await props
      .downloadPDFSoapHP(ehrID, userID, soap.id, printForm)
      .then(res => {
        if (res.status) {
          let filename = printForm === 'shortform' ? 'soap.pdf' : 'H&P.pdf';
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
          //window.print(fileURL, '_blank');
        }
      });
  };

  return (
    <Box className={clsx(classes.soapEntryWrap, classes.soapViewWrap)}>
      <Box className={clsx(classes.soapEntryItem, classes.soapEntryTitle)}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            justifyContent: 'space-between'
          }}>
          <Typography style={{ fontWeight: 'bold' }}>
            {soap && soap.patient && soap.patient.lastname
              ? soap.patient.lastname
              : ''}
            &nbsp;
            {soap && soap.patient && soap.patient.firstname
              ? soap.patient.firstname
              : ''}
            &nbsp;
            {soap && soap.patient && soap.patient.middleinitial
              ? soap.patient.middleinitial
              : ''}
          </Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {soap && soap.patient && soap.patient.dob
              ? moment(soap.patient.dob).format('MM/DD/YYYY')
              : ' '}
            {soap && soap.patient && soap.patient.dob
              ? ' (' + computeAge(soap.patient.dob) + ' yrs) '
              : ''}
          </Typography>
          <span>
            <Button
              color="primary"
              onClick={() => handlePrint('shortform')}
              size="small"
              style={{ marginRight: 5 }}
              variant="outlined">
              SOAP
            </Button>
            {role !== Roles.patient && (
              <Button
                color="primary"
                onClick={() => handlePrint('longform')}
                size="small"
                variant="outlined">
                H &amp; P
              </Button>
            )}
          </span>
        </div>
      </Box>
      <Box className={classes.soapEntryItem}>
        <div className={classes.vitalBody}>
          <Typography style={{ fontWeight: '600' }}>VS</Typography>
          <TextField
            className={classes.textField}
            defaultValue="121/64"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize
              },
              readOnly: true
            }}
            label="BP"
            value={
              soap && soap.soapnote && soap.soapnote.bloodpressure
                ? soap.soapnote.bloodpressure
                : ''
            }
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            defaultValue="98.9"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize
              },
              readOnly: true
            }}
            label="Temp"
            value={
              soap && soap.soapnote && soap.soapnote.temperature
                ? soap.soapnote.temperature
                : ''
            }
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            defaultValue="64"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize
              },
              readOnly: true
            }}
            label="HR"
            value={
              soap && soap.soapnote && soap.soapnote.heartrate
                ? soap.soapnote.heartrate
                : ''
            }
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            defaultValue="---"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize
              },
              readOnly: true
            }}
            label="RR"
            value={
              soap && soap.soapnote && soap.soapnote.respiratoryrate
                ? soap.soapnote.respiratoryrate
                : ''
            }
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            defaultValue="130"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize
              },
              readOnly: true
            }}
            label="WT (lb)"
            value={
              soap && soap.soapnote && soap.soapnote.weight
                ? soap.soapnote.weight
                : ''
            }
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            defaultValue="178"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize,
                readOnly: true
              },
              readOnly: true
            }}
            label="HT (ft)"
            value={
              soap && soap.soapnote && soap.soapnote.height
                ? soap.soapnote.height
                : ''
            }
            variant="outlined"
          />
          <TextField
            className={clsx(classes.textField, classes.highlight)}
            defaultValue="BMI: 20"
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
                focused: classes.labelFocused
              }
            }}
            InputProps={{
              classes: {
                input: classes.textfiledSize,
                readOnly: true
              },
              readOnly: true
            }}
            style={{ backgroundColor: 'red' }}
            value={
              soap && soap.soapnote && soap.soapnote.bodymassindex
                ? Number(soap.soapnote.bodymassindex).toFixed(2)
                : ''
            }
            variant="outlined"
          />
        </div>
      </Box>
      <Box className={classes.soapEntryItem}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          InputProps={{
            classes: {
              input: classes.textfiledSize
            },
            readOnly: true
          }}
          label="Subjective"
          multiline
          value={
            soap && soap.soapnote && soap.soapnote.subjective
              ? soap.soapnote.subjective
              : ''
          }
          variant="outlined"
        />
      </Box>
      <Box className={classes.soapEntryItem}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          InputProps={{
            classes: {
              input: classes.textfiledSize
            },
            readOnly: true
          }}
          label="Objective"
          multiline
          value={
            soap && soap.soapnote && soap.soapnote.objective
              ? soap.soapnote.objective
              : ''
          }
          variant="outlined"
        />
      </Box>
      <Box className={classes.soapEntryItem}>
        <TextField
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes.labelRoot,
              focused: classes.labelFocused
            }
          }}
          InputProps={{
            classes: {
              input: classes.textfiledSize
            },
            readOnly: true
          }}
          label="Assessment Plan"
          multiline
          value={
            soap && soap.soapnote && soap.soapnote.assessmentplan
              ? soap.soapnote.assessmentplan
              : ''
          }
          variant="outlined"
        />
      </Box>

      {prescriptions && prescriptions.length > 0 && (
        <Box className={classes.soapEntryItem}>
          <span
            style={{
              backgroundColor: 'rgb(51, 151, 243)',
              padding: 5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography style={{ color: '#fff', fontWeight: 600 }}>
              Rx
            </Typography>
          </span>
          <Table
            className={classes.templateTable}
            style={{
              border: '1px solid #3397F3',
              borderCollapse: 'initial'
            }}>
            <TableHead>
              <TableRow>
                <TableCell width="25%">Medication</TableCell>
                <TableCell>Dose</TableCell>
                <TableCell width="25%">Direction</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Refill</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prescriptions &&
                prescriptions.length > 0 &&
                prescriptions.map((item, idx) => {
                  return (
                    <TableRow>
                      <TableCell>{item.name ? item.name : ''}</TableCell>
                      <TableCell>{item.dose ? item.dose : ''}</TableCell>
                      <TableCell>
                        {item.directions ? item.directions : ''}
                      </TableCell>
                      <TableCell>
                        {item.quantity ? item.quantity : ''}
                      </TableCell>
                      <TableCell>{item.refill ? item.refill : ''}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableBody>
              <TableCell>
                {soap && soap.soapnote && soap.soapnote.medicationComment
                  ? soap.soapnote.medicationComment
                      .split('\n')
                      .map(function(item, key) {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })
                  : ''}
              </TableCell>
            </TableBody>
          </Table>
        </Box>
      )}

      {labOrders &&
        (labOrders.length > 0 || labOrders.labOrderDiagnosis.length > 0) && (
          <Box className={classes.soapEntryItem}>
            <span
              style={{
                backgroundColor: 'rgb(51, 151, 243)',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography style={{ color: '#fff', fontWeight: 600 }}>
                Lab Orders
              </Typography>
            </span>
            <Table
              className={classes.templateTable}
              style={{
                border: '1px solid #3397F3',
                borderCollapse: 'initial'
              }}>
              <TableBody>
                {labOrders &&
                  labOrders.length > 0 &&
                  labOrders.map(item => {
                    return (
                      <TableRow>
                        <TableCell>{item[1] ? item[1] : ''}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {labOrders && labOrders.labOrderDiagnosis && (
              <Table
                className={classes.templateTable}
                style={{
                  border: '1px solid #3397F3',
                  borderCollapse: 'initial',
                  marginTop: -1
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Diagnosis Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                  {labOrders &&
                    labOrders.labOrderDiagnosis.length > 0 &&
                    labOrders.labOrderDiagnosis.map(item => {
                      return (
                        <TableRow>
                          <TableCell>{item[2] ? item[2] : ''}</TableCell>
                          <TableCell>{item[1] ? item[1] : ''}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </Box>
        )}
      {imagingOrders &&
        (imagingOrders.length > 0 ||
          imagingOrders.labOrderDiagnosis.length > 0) && (
          <Box className={classes.soapEntryItem}>
            <span
              style={{
                backgroundColor: 'rgb(51, 151, 243)',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography style={{ color: '#fff', fontWeight: 600 }}>
                Imaging Orders
              </Typography>
            </span>
            <Table
              className={classes.templateTable}
              style={{
                border: '1px solid #3397F3',
                borderCollapse: 'initial'
              }}>
              <TableBody>
                {imagingOrders &&
                  imagingOrders.length > 0 &&
                  imagingOrders.map(item => {
                    return (
                      <TableRow>
                        <TableCell>{item[1] ? item[1] : ''}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {imagingOrders && imagingOrders.labOrderDiagnosis && (
              <Table
                className={classes.templateTable}
                style={{
                  border: '1px solid #3397F3',
                  borderCollapse: 'initial',
                  marginTop: -1
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Diagnosis Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                  {imagingOrders &&
                    imagingOrders.labOrderDiagnosis &&
                    imagingOrders.labOrderDiagnosis.length > 0 &&
                    imagingOrders.labOrderDiagnosis.map(item => {
                      return (
                        <TableRow>
                          <TableCell>{item[2] ? item[2] : ''}</TableCell>
                          <TableCell>{item[1] ? item[1] : ''}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </Box>
        )}
      {physicianOrders &&
        (physicianOrders.length > 0 ||
          physicianOrders.labOrderDiagnosis.length > 0) && (
          <Box className={classes.soapEntryItem}>
            <span
              style={{
                backgroundColor: 'rgb(51, 151, 243)',
                padding: 5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
              <Typography style={{ color: '#fff', fontWeight: 600 }}>
                Physician Orders
              </Typography>
              {/* <IconButton
              onClick={() => editLabOrder()}
              size="small"
              style={{ color: '#fff' }}
            >
              <EditOutlined />
            </IconButton> */}
            </span>
            <Table
              className={classes.templateTable}
              style={{
                border: '1px solid #3397F3',
                borderCollapse: 'initial'
              }}>
              <TableBody>
                {physicianOrders &&
                  physicianOrders.length > 0 &&
                  physicianOrders.map(item => {
                    return (
                      <TableRow>
                        <TableCell>{item[1] ? item[1] : ''}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {physicianOrders && physicianOrders.labOrderDiagnosis && (
              <Table
                className={classes.templateTable}
                style={{
                  border: '1px solid #3397F3',
                  borderCollapse: 'initial',
                  marginTop: -1
                }}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>Diagnosis Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                  {physicianOrders &&
                    physicianOrders.labOrderDiagnosis &&
                    physicianOrders.labOrderDiagnosis.length > 0 &&
                    physicianOrders.labOrderDiagnosis.map(item => {
                      return (
                        <TableRow>
                          <TableCell>{item[2] ? item[2] : ''}</TableCell>
                          <TableCell>{item[1] ? item[1] : ''}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </Box>
        )}

      {diagnosisList && diagnosisList.length > 0 && (
        <Box className={classes.soapEntryItem}>
          <span
            style={{
              backgroundColor: 'rgb(51, 151, 243)',
              padding: 5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography style={{ color: '#fff', fontWeight: 600 }}>
              Diagnosis
            </Typography>
          </span>
          <Table
            className={classes.templateTable}
            style={{ border: '1px solid #3397F3', borderCollapse: 'initial' }}>
            <TableHead>
              <TableRow>
                <TableCell width="25%">Code</TableCell>
                <TableCell colSpan={2}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diagnosisList &&
                diagnosisList.length > 0 &&
                diagnosisList.map((item, idx) => {
                  return (
                    <TableRow>
                      <TableCell>{item.code ? item.code : ''}</TableCell>
                      <TableCell>
                        {item.description ? item.description : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      )}

      {cptList && cptList.length > 0 && (
        <Box className={classes.soapEntryItem}>
          <span
            style={{
              backgroundColor: 'rgb(51, 151, 243)',
              padding: 5,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography style={{ color: '#fff', fontWeight: 600 }}>
              CPT
            </Typography>
          </span>
          <Table
            className={classes.templateTable}
            style={{ border: '1px solid #3397F3', borderCollapse: 'initial' }}>
            <TableHead>
              <TableRow>
                <TableCell width="25%">Code</TableCell>
                <TableCell colSpan={2}>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cptList &&
                cptList.length > 0 &&
                cptList.map((item, idx) => {
                  return (
                    <TableRow>
                      <TableCell>{item.code ? item.code : ''}</TableCell>
                      <TableCell>
                        {item.description ? item.description : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

SoapEntry.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  downloadPDFSoapHP
};

const mapStateToProps = state => ({
  role: state.profile && state.profile.role,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  patientInfo: state.soap && state.soap.patientInfo,
  providers: state.util.providers
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SoapEntry))
);
