/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  saveAllergy,
  updateAllergy,
  deleteAllergy
} from 'services/soap/action';

const Allergies = props => {
  const { classes, patientId } = props;

  const initialUserState = {
    id: 0,
    name: '',
    reaction: ''
  };
  const [values, setValues] = useState(initialUserState);
  const [editvalues, setEditValues] = useState(initialUserState);
  const [allergy, setAllergy] = useState(props.allergies);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.allergies && props.allergies.length > 0) {
      setAllergy(props.allergies);
    }
  }, [props.allergies]);

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleEditFieldChange = (field, value) => {
    setEditValues({ ...editvalues, [field]: value });
  };

  const handleSaveAllergy = async () => {
    if (values.name || values.reaction) {
      const res = await props.saveAllergy(patientId, values);
      if (res.status) {
        setAllergy(res.allergies);
        setValues(initialUserState);
        props.getAllergy(res.allergies);
      }
    }
  };

  const handleUpdateAllergy = async idx => {
    if (editvalues.name || editvalues.reaction) {
      const res = await props.updateAllergy(patientId, editvalues);
      if (res.status) {
        const newState = [...allergy];
        newState[idx] = res.allergy;
        setAllergy(newState);
        setIsEdit(false);
        props.getAllergy(newState);
      }
    }
  };

  const handleDeleteAllergy = async id => {
    const res = await props.deleteAllergy(patientId, id);
    if (res.status) {
      setAllergy(res.allergies);
      setValues(initialUserState);
      props.getAllergy(res.allergies);
    }
  };

  const handleEditAllergy = async (data, index) => {
    setIsEdit(true);
    if (data) {
      setEditValues({
        id: data.id,
        name: data.name,
        reaction: data.reaction,
        editIndex: index
      });
    }
  };

  const clearAllergy = () => {
    setIsEdit(false);
    setValues(initialUserState);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody} style={{ borderTop: 'default' }}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('name', event.target.value)
                      }
                      placeholder="Input Name"
                      value={values.name}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('reaction', event.target.value)
                      }
                      placeholder="Input Reaction"
                      value={values.reaction}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      // disabled={values.name || values.reaction}
                      onClick={() => handleSaveAllergy()}
                      size="small"
                      style={{ color: '#03A484' }}>
                      <CheckOutlined />
                    </IconButton>
                    <IconButton onClick={() => clearAllergy()} size="small">
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allergy &&
                  allergy.length > 0 &&
                  allergy
                    .sort((a, b) => b.id - a.id)
                    .map((item, idx) => {
                      return (
                        <TableRow>
                          {isEdit && idx === editvalues.editIndex ? (
                            <>
                              <TableCell width="25%">
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChange(
                                      'name',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input Name"
                                  value={editvalues.name}
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChange(
                                      'reaction',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input Reaction"
                                  value={editvalues.reaction}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.reaction}</TableCell>
                            </>
                          )}
                          <TableCell align="center" width={72}>
                            {isEdit && idx === editvalues.editIndex ? (
                              <>
                                <IconButton
                                  // disabled={values.name || values.reaction}
                                  onClick={() => handleUpdateAllergy(idx)}
                                  size="small"
                                  style={{ color: '#03A484' }}>
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => clearAllergy()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() => handleEditAllergy(item, idx)}
                                  size="small">
                                  <Edit color="primary" />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleDeleteAllergy(item.id)}
                                  size="small">
                                  <DeleteOutline style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
Allergies.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = { saveAllergy, updateAllergy, deleteAllergy };

const mapStateToProps = () => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Allergies))
);
