export default {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 7,
      maximum: 128
    }
  },
  confirmpassword: {
    presence: { allowEmpty: false, message: '^Enter the password again' },
    length: {
      maximum: 128
    },
    equality: {
      attribute: 'password',
      message: '^Password and confirm password does not match',
      comparator: (v1, v2) => {
        return v1 === v2;
      }
    }
  }
};
