import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CalendarDaily from './CalendarDaily';
import { PatientSearch, PatientAdd } from '../../Common/components/';
import styles from '../style';
import moment from 'moment';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Grid,
  Box,
  Typography,
  Button,
  Modal,
  InputAdornment,
  IconButton,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { CloseOutlined, CalendarToday } from '@material-ui/icons/';
import { getProviders } from 'services/util/action';
import validate from 'validate.js';
import schema from './schemaSchedulePayment';
import { savePtSchedule } from 'services/ptschedules/action';
import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import { editPatient } from 'services/patient/action';
import MessageBox from 'util/messageBox';
import CalendarEmpty from './CalendarEmpty';
import { addPatientfromPtSchedule } from 'services/patient/action';
import { getAllPhyPtSchedules } from 'services/ptschedules/action';

const ScheduleAppo = props => {
  const {
    classes,
    className,
    ehrID,
    providers,
    data,
    appointment,
    allPhyPtSchedules,
    modalRef,
    ...rest
  } = props;

  const initialUserState = {
    id: 0,
    routePhysician: '',
    appointmentDate: moment(),
    date: '',
    duration: '',
    patientId: '',
    userId: 0,
    patientName: '',
    reasonForAppointment: '',
    timeFrom: '',
    timeTo: '',
    emailaddress: '',
    isValid: false,
    touched: {},
    errors: {},
    err: false,
    errMsg: ''
  };
  const ps = useRef();
  const [formstate, setFormstate] = useState(initialUserState);
  const [openAddPatient, setOpenAddPatient] = useState({
    showPopUp: false,
    name: ''
  });
  const [timeFrom, setTimeFrom] = useState(null);
  const [pros, setPros] = useState([]);
  const [duration, setDuration] = useState(null);
  const [slots, setSlots] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [open2, setOpen2] = useState(false);
  let isScheduleAppointment = true;
  const [ptScheduleList, setPtScheduleList] = useState(null);
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [openAddExistingPatient, setOpenAddExistingPatient] = useState({
    showPopUp: false,
    patient: null
  });
  const [poploading, setpoploading] = useState(false);

  useEffect(() => {
    props.getProviders(ehrID);
    loadTimeSlots();
    loadHours();
    loadDuration();
  }, []);

  useEffect(() => {
    setPtScheduleList(data);
  }, [data]);

  useEffect(() => {
    setPtScheduleList(allPhyPtSchedules);
  }, [allPhyPtSchedules]);

  useEffect(() => {
    if (formstate.appointmentDate) {
      props.getAllPhyPtSchedules(
        ehrID,
        moment(formstate.appointmentDate).format('MM/DD/YYYY')
      );
      setPtScheduleList(data);
    }
  }, [formstate.appointmentDate]);

  useEffect(() => {
    if (appointment) {
      setFormstate({
        ...formstate,
        id: appointment.id,
        routePhysician: appointment.routePhysician,
        appointmentDate: appointment.appointmentDate,
        date: appointment.appointmentDate,
        duration: appointment.duration + ' min',
        patientId: appointment.patientId,
        patientName: appointment.patientName,
        userId: appointment.userId,
        reasonForAppointment: appointment.reasonForAppointment,
        emailaddress: appointment.emailAddress,
        timeFrom: moment(appointment.timeFrom).format('h:mm A'),
        isValid: true
      });
    }
  }, [appointment]);

  const loadHours = () => {
    let time = [{ key: ' ', value: '' }];
    let hours, ampm;

    for (let i = 60; i <= 1380; i += 60) {
      hours = Math.floor(i / 60);
      ampm = hours < 12 ? ' AM' : ' PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      let slot = hours + ampm;
      time.push({ key: slot, value: slot });
    }
    setSlots(time);
  };

  const loadDuration = () => {
    let duration = [{ key: 'Select', value: ' ' }];
    let durations = ['15', '30', '45', '60'];
    for (let i = 0; i < durations.length; i++) {
      duration.push({
        key: durations[i] + ' min',
        value: durations[i] + ' min'
      });
    }
    setDuration(duration);
  };

  const loadTimeSlots = () => {
    let time = [{ key: 'Select', value: ' ' }];
    for (let i = 60; i <= 1380; i += 15) {
      let hours, minutes, ampm;
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = '0' + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      let slot = hours + ':' + minutes + ' ' + ampm;
      time.push({ key: slot, value: slot });
    }
    setTimeFrom(time);
  };

  const hasError = field =>
    formstate.touched[field] && formstate.errors[field] ? true : false;

  const handleScheduleAppointment = async (
    patientId,
    patientName,
    emailaddress,
    userId
  ) => {
    setFormstate({
      ...formstate,
      patientId: patientId,
      patientName: patientName,
      emailaddress: emailaddress || '',
      userId: userId
    });
  };

  useEffect(() => {
    if (ptScheduleList) {
      if (document.getElementById('frmSch7')) {
        document.getElementById('frmSch7').scrollIntoView();
      }
      if (document.getElementById('7')) {
        document.getElementById('7').scrollIntoView();
      }
      if (ps && ps.current) {
        const curr = ps.current;
        if (curr) {
          curr.scrollTop = 0;
        }
      }
      window.scrollTo(0, 0);
    }
  }, [ptScheduleList]);

  const handleFieldChange = (field, value) => {
    if (field === 'routePhysician') {
      props.getAllPhyPtSchedules(
        ehrID,
        formstate.appointmentDate
          ? moment(formstate.appointmentDate).format('MM/DD/YYYY')
          : moment().format('MM/DD/YYYY'),
        '',
        value
      );
    }
    const newState = { ...formstate };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setFormstate(newState);
  };

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providertopush.push({ key: 'Select', value: ' ' });
      providers.forEach(function (item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const handleSubmit = async () => {
    if (formstate.isValid) {
      setFormstate({ ...formstate, isLoading: true });
      const newState = { ...formstate };
      if (formstate.patientName === '') {
        newState.errors = {};
        newState.errors.patientName = [''];
        setFormstate({
          ...newState,
          err: true,
          errMsg: 'Please choose a patient',
          isLoading: false
        });
        return;
      }

      let currentAppointment = ptScheduleList;
      let existingAppointment = currentAppointment.filter(
        c => moment(c.timeFrom).format('h:mm A') === newState.timeFrom
      );
      if (existingAppointment.length > 0 && formstate.id === 0) {
        newState.errors = {};
        newState.errors.existingAppointment = ['Slot already taken'];
        setFormstate({
          ...newState,
          err: true,
          errMsg: 'The appointment slot is already taken',
          isLoading: false
        });
        return;
      }
      let appDate = moment(newState.appointmentDate).startOf('day');
      let myMomentConverted = moment(newState.timeFrom, ['h:mm A']).format(
        'HH:mm'
      );
      let myMoment = myMomentConverted.split(':'); //moment(newState.timeFrom, 'hh');
      let hours = myMoment[0];
      let minutes = myMoment[1].split(' ')[0];
      appDate = moment(appDate).add(hours, 'hours');
      appDate = moment(appDate).add(minutes, 'minutes');
      newState.appointmentDate = appDate;
      newState.date = appDate;
      newState.timeFrom = appDate;
      newState.duration = newState.duration.split(' ')[0];
      newState.timeTo = new Date(newState.timeFrom + newState.duration * 60000);

      if (newState.emailaddress) {
        let user = {};
        user.id = newState.userId;
        user.emailaddress = newState.emailaddress;
        user.enabled = true;
        const resNew = await props.editPatient(ehrID, newState.patientId, user);
        if (resNew) {
          props.handleSavePtSchedule(newState);
        } else {
          setFormstate({ ...newState, isLoading: false });
        }
      } else {
        props.handleSavePtSchedule(newState);
      }

      setFormstate({ ...formstate, isLoading: false });
    }
  };

  const handleClose = () => {
    setFormstate({ ...formstate, err: false, errMsg: '' });
    setSuccMsg({ succ: false });
  };

  //#region Add Patient

  const handleOpenAddPatient = search => {
    setOpenAddPatient({ showPopUp: true, name: search });
  };

  const handleCloseAddPatient = async () => {
    setOpenAddPatient({ showPopUp: false });
  };

  const handleSave = async (patient, isExisting = false) => {
    const newState = { ...patient };
    if (newState.practice && isExisting) {
      newState.practice =
        Object.keys(newState.practice).length > 0
          ? JSON.parse(newState.practice)
          : undefined;
    }
    const status = await props.addPatientfromPtSchedule(ehrID, newState);
    if (status) {
      setSuccMsg({ succ: true, succMsg: 'Patient created successfully' });
      setFormstate({
        ...formstate,
        patientId: status.data.id,
        patientName: status.data.lastname + ' ' + status.data.firstname,
        emailaddress: status.data.emailaddress
      });
      setOpenAddPatient({ showPopUp: false });
    } else {
      setpoploading(false);
      setOpenAddPatient({ showPopUp: true });
    }
  };

  const handleDeleteAppointment = itemId => {
    props.handleDeleteAppointment(itemId);
  };

  //#region Add  Patient

  const handleAddExistingPatient = item => {
    setOpenAddExistingPatient({ showPopUp: true, patient: item });
  };

  const handleAddExistingOK = async () => {
    setpoploading(true);
    if (openAddExistingPatient.patient) {
      await handleSave({ ...openAddExistingPatient.patient, id: 0 }, true);
    }
    setOpenAddExistingPatient({ showPopUp: false });
    setpoploading(false);
  };

  const handleAddExistingCancel = () => {
    setOpenAddExistingPatient({ showPopUp: false });
  };

  //#endregion

  //#endregion
  return (
    <Box
      className={classes.modalWrap}
      style={{ width: '95%', maxWidth: 1300, height: '90vh' }}>
      <Box className={classes.modalHead}>
        <Typography variant="h2">Schedule Appointment</Typography>
        <IconButton
          aria-label="delete"
          className={classes.modalClose}
          onClick={props.onCancel}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box className={classes.modalBody} style={{ height: '90%' }}>
        <PerfectScrollbar
          containerRef={el => (ps.current = el)}
          style={{ paddingRight: 15, marginRight: -15 }}>
          <Grid
            container
            style={{ padding: 0 }}
            //className={classes.contentWrap}
            xs={12}>
            <Grid className={classes.leftWrap} item md={5} xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <PatientSearch
                    fullWidth
                    handleOpenAddPatient={handleOpenAddPatient}
                    handleScheduleAppointment={handleScheduleAppointment}
                    isScheduleAppointment
                    isfromScheduleAppointment
                  />
                </Grid>
                <Grid
                  item
                  style={{ marginBottom: '-10px', marginTop: '0px' }}
                  xs={12}>
                  <Typography>
                    Patient Name: <strong>{formstate.patientName}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    SelectProps={{ native: true }}
                    className={classes.textField}
                    label="Appointment with Physician"
                    name="Appointment"
                    onChange={event =>
                      handleFieldChange('routePhysician', event.target.value)
                    }
                    select
                    style={{ width: '100%' }}
                    value={formstate.routePhysician}
                    variant="outlined">
                    {pros &&
                      pros.map(item => (
                        <option value={item.value}>{item.key}</option>
                      ))}
                  </TextField>
                  <div className={classes.datePickerWrap}>
                    <DatePicker
                      customInput={
                        <TextField
                          InputProps={{
                            endAdornment: (
                              <InputAdornment>
                                <CalendarToday
                                  style={{ height: 16, width: 16 }}
                                />
                              </InputAdornment>
                            )
                          }}
                          className={classes.textField}
                          error={
                            formstate.errors && formstate.errors.appointmentDate
                          }
                          fullWidth
                          label="Appointment Date"
                          margin="dense"
                          variant="outlined"
                        />
                      }
                      format="MM/DD/YYYY"
                      minDate={new Date()}
                      onBlur={e => (e.target.placeholder = 'Appointment Date')}
                      onChange={date =>
                        handleFieldChange('appointmentDate', date)
                      }
                      onFocus={e => (e.target.placeholder = '')}
                      placeholderText="Appointment Date"
                      selected={
                        formstate.appointmentDate &&
                        new Date(formstate.appointmentDate)
                      }
                      showMonthDropdown="bool"
                      showYearDropdown="bool"
                    />
                  </div>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    SelectProps={{ native: true }}
                    className={classes.textField}
                    label="Time"
                    name="time"
                    onChange={event =>
                      handleFieldChange('timeFrom', event.target.value)
                    }
                    select
                    style={{ width: '100%' }}
                    value={formstate.timeFrom}
                    variant="outlined">
                    {timeFrom &&
                      timeFrom.map(item => (
                        <option value={item.value}>{item.key}</option>
                      ))}
                  </TextField>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    SelectProps={{ native: true }}
                    className={classes.textField}
                    label="Duration"
                    name="duration"
                    onChange={event =>
                      handleFieldChange('duration', event.target.value)
                    }
                    select
                    style={{ width: '100%' }}
                    value={formstate.duration}
                    variant="outlined">
                    {duration &&
                      duration.map(item => (
                        <option value={item.value}>{item.key}</option>
                      ))}
                  </TextField>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    className={classes.textField}
                    color="Primary"
                    fullWidth
                    label="Reason for Appointment"
                    multiline
                    name="reason"
                    onChange={event =>
                      handleFieldChange(
                        'reasonForAppointment',
                        event.target.value
                      )
                    }
                    required
                    rows={3}
                    value={formstate.reasonForAppointment}
                    variant="outlined"
                  />
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    className={classes.textField}
                    color="Primary"
                    error={hasError('emailaddress')}
                    fullWidth
                    helperText={
                      hasError('emailaddress')
                        ? formstate.errors.emailaddress[0]
                        : null
                    }
                    label="Email Address of the Patient"
                    name="email"
                    onChange={event =>
                      handleFieldChange('emailaddress', event.target.value)
                    }
                    // required
                    value={formstate.emailaddress}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  style={{ textAlign: 'right', marginBottom: 20 }}
                  xs={12}>
                  {formstate.id !== 0 && (
                    <Button
                      onClick={() => handleDeleteAppointment(formstate.id)}
                      style={{ backgroundColor: '#c1c1c1', marginRight: 10 }}
                      variant="contained">
                      Delete
                    </Button>
                  )}
                  <Button
                    onClick={props.onCancel}
                    style={{ backgroundColor: '#c1c1c1', marginRight: 10 }}
                    variant="contained">
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={!formstate.isValid}
                    onClick={() => handleSubmit()}
                    variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.rightWrap} item md={7} xs={12}>
              {ptScheduleList && ptScheduleList.length > 0 ? (
                <CalendarDaily
                  data={ptScheduleList}
                  isfromScheduleAppointment
                  openSchedule={props.handleOpen1}
                  isModal={true}
                />
              ) : (
                <CalendarEmpty />
              )}
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Box>
      <Modal
       
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseAddPatient}
        open={openAddPatient.showPopUp}>
        <PatientAdd
          cancelExistingPatient={handleAddExistingCancel}
          handleAddExistingPatient={handleAddExistingPatient}
          handleSave={handleSave}
          onCancel={handleCloseAddPatient}
          searchText={openAddPatient.name}
        />
      </Modal>

      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />

      <MessageBox
        message={formstate.errMsg}
        onClose={handleClose}
        open={formstate.err}
        variant="error"
      />

      {/* Start Dialogue for Add Existing Patient */}
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={openAddExistingPatient.showPopUp}>
        <DialogTitle id="confirmation-dialog-title">
          Patient Save Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to proceed ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button color="primary" onClick={() => handleAddExistingOK()}>
              OK
            </Button>
          )}
          <Button color="primary" onClick={handleAddExistingCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Dialogue for Add Existing Patient */}
    </Box>
  );
};

ScheduleAppo.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getProviders,
  savePtSchedule,
  editPatient,
  addPatientfromPtSchedule,
  getAllPhyPtSchedules
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  providers: state.util.providers,
  allPhyPtSchedules:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedules &&
    state.ptschedules.allPhyPtSchedules.result,
  allPhyPtSchedulesofDate:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedulesofDate &&
    state.ptschedules.allPhyPtSchedulesofDate.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleAppo))
);
