import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Button,
  Select,
  Input,
  CircularProgress
} from '@material-ui/core';
import { Check, CancelOutlined, AddCircleOutline } from '@material-ui/icons';

import validate from 'validate.js';
import schema from './schema';
import { connect } from 'react-redux';
import {
  getUserCPTCodes,
  addUserCPTCode,
  deleteUserCPTCode
} from 'services/cpt/action';

const UserDefined = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      addSelectedCPT,
      deleteSelectedCPT,
      loadUserCPT
    };
  });
  // const UserDefined = props => {
  const { classes , userID } = props;
  const [loading, setLoading] = useState(true);
  const [userCode, setUserCode] = React.useState([]);
  const [cptCodeMasterList, setCPTCodeMasterList] = React.useState([]);
  const [userCPTCode, setUserCPTCode] = React.useState([]);
  const [cptUserQuery, setCPTUserQuery] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const initialUserState = {
    code: '',
    description: '',
    keyid: userID,
    isValid: false,
    touched: {},
    errors: {}
  };
  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    loadUserCPT();
  }, []);

  const loadUserCPT = () => {
    setLoading(true);
    props.getUserCPTCodes().then(res => {
      if (res && res.data.length > 0) {
        setUserCPTCode(res.data);
        setCPTCodeMasterList(res.data);
        setLoading(false);
      }
      else{
        setLoading(false);
      }
    });
  };

  const addSingleCPT = () => {
    const codes = [];
    codes.push({
      code: values.code,
      description: values.description,
      keyid: values.keyid
    });
    if (values.isValid) {
      props.addUserCPTCode(codes).then(res => {
        if (res) {
          setEdit();
          props.getUserCPTCodes().then(res => {
            if (res && res.data.length > 0) {
              setCPTCodeMasterList(res.data);
              if (cptUserQuery !== '') {
                let searchResult = null;
                searchResult = findCPTs(res.data, cptUserQuery);
                setUserCPTCode(searchResult);
              } else {
                setUserCPTCode(res.data);
              }
            }
          });
          setValues(initialUserState);
        }
      });
    }
  };

  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      addSingleCPT();
    }
  };

  const handleChange = (field, value) => {
    if (field === 'cptUserQuery') {
      setCPTUserQuery(value);
      let searchResult = null;
      searchResult = findCPTs(cptCodeMasterList, value);
      setUserCPTCode(searchResult);
    } else {
      const newState = { ...values };
      newState[field] = value;
      newState.touched[field] = true;
      const errors = validate(newState, schema);
      newState.errors = errors || {};
      newState.isValid = errors ? false : true;
      setValues(newState);
    }
  };

  const findCPTs = (items, text) => {
    text = text.toLowerCase().split(' ');
    return items.filter(function(item) {
      return text.every(function(el) {
        return (
          (item.code && item.code.toLowerCase().indexOf(el) > -1) ||
          (item.description && item.description.toLowerCase().indexOf(el) > -1)
        );
      });
    });
  };

  const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    const selectedVal = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
        selectedVal.push(
          cptCodeMasterList.filter(
            item => item.id === parseInt(options[i].value)
          )
        );
      }
    }
    setUserCode(value);
    props.setSelectedUserCodes(selectedVal, 'user');
  };

  const deleteSelectedCPT = async selectedCPT => {
    if (selectedCPT.length > 0) {
      props.deleteUserCPTCode(selectedCPT).then(res => {
        if (res) {
          props.getUserCPTCodes().then(res => {
            if (res && res.data.length > 0) {
              setUserCPTCode(res.data);
              setCPTCodeMasterList(res.data);
            }
          });
        }
      });
    }
  };

  const setEdit = () => {
    setIsEdit(!isEdit);
  };

  const addSelectedCPT = async selectedCPT => {
    const codes = [];
    codes.push({
      code: values.code,
      description: values.description,
      keyid: values.keyid
    });
    if (selectedCPT.length > 0) {
      props.addUserCPTCode(selectedCPT).then(res => {
        if (res) {
          props.getUserCPTCodes().then(res => {
            if (res && res.data.length > 0) {
              setUserCPTCode(res.data);
              setCPTCodeMasterList(res.data);
            }
          });
        }
      });
    }
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>User Defined CPT &amp; Descriptions</Typography>
      </div>

      <div className={classes.templateBody}>
        <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
          <Table
            className={classes.templateTable}
            style={{ overflow: 'hidden' }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '100%' }}>
                  <TextField
                    id="outlined-basic"
                    placeholder="Search user codes and descriptions"
                    variant="outlined"
                    className={[classes.textBox, classes.fullWidth]}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    value={cptUserQuery}
                    onChange={event =>
                      handleChange('cptUserQuery', event.target.value)
                    }
                  />
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                  {!isEdit ? (
                    <Button
                      onClick={() => setEdit()}
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.addButton}>
                      <AddCircleOutline />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setEdit()}
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ backgroundColor: '#B9B9B9' }}
                      className={classes.addButton}>
                      <CancelOutlined />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            {isEdit ? ( 
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '100%', whiteSpace: 'nowrap' }}>
                      <TextField
                        id="outlined-basic"
                        placeholder="Input Code"
                        variant="outlined"
                        className={classes.textBox}
                        style={{ width: 80 }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        value={values.code}
                        onChange={event =>
                          handleChange('code', event.target.value)
                        }
                      />
                      <TextField
                        id="outlined-basic"
                        placeholder="Input Description"
                        variant="outlined"
                        className={classes.textBox}
                        style={{ width: 'calc(100% - 85px)', marginLeft: 5 }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        onKeyPress={_handleKeyDown}
                        value={values.description}
                        onChange={event =>
                          handleChange('description', event.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                      <IconButton
                        disabled={!values.isValid}
                        onClick={() => {
                          addSingleCPT();
                        }}
                        size="small"
                        color="primary"
                        style={{ marginLeft: -15, color: '#03A484' }}>
                        <Check />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead> 
            ) : null}
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{
                    borderColor: 'transparent',
                    height: 'calc(100vh - 400px)'
                  }}>
                  
                    {loading ? (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={24} />
                      </div>
                    ) : (
                      userCPTCode && userCPTCode.length > 0
                        ?(
                        <Select
                          className={classes.multiSel}
                          multiple
                          disabledUnderline
                          native
                          variant="standard"
                          value={userCode}
                          onChange={handleChangeMultiple}
                          inputProps={{ size: '10' }}
                          input={
                            <Input
                              classes={{
                                underline: classes.underline
                              }}
                            />
                          }>
                          {userCPTCode && userCPTCode.length > 0
                            ? userCPTCode.map(cptcode => (
                                <option key={cptcode.id} value={cptcode.id}>
                                  {cptcode.code} {cptcode.description}
                                </option>
                              ))
                            : null}
                        </Select>):(
                          <Typography>
                              No records of user defined CPT &amp; descriptions
                          </Typography>
                        )
                        
                    )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
});

UserDefined.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getUserCPTCodes,
  addUserCPTCode,
  deleteUserCPTCode
};

const mapStateToProps = state => ({
  userID: state.profile && state.profile.userID,
  userCPT: state.providers && state.cpt && state.cpt.userCPT
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(UserDefined));
