import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../style';
import {
  Grid
} from '@material-ui/core';

import { Create, View } from './components';

//const StyledTab = withStyles(theme => ({

const UserSettings = props => {
  const { classes, className } = props;

  const [values, setValues] = useState({
    firstName: 'Shen',
    lastName: 'Zhi',
    email: 'shen.zhi@devias.io',
    phone: '',
    state: 'Alabama',
    country: 'USA'
  });



  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} className={classes.colWrap}>
        <Create />
      </Grid>
      <Grid item xs={12} md={8} className={classes.colWrap}>
        <View />
      </Grid>
    </Grid>
  );
};

UserSettings.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(UserSettings));
