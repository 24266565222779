import React, { Component } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.png';
import { ForgotUn, ForgotPw } from './components';
// import { ForgotPw } from "views/Terms/components/Forgots/ForgotPw";
// import { ForgotUn } from "views/Terms/components/Forgots/ForgotUn";
import { Grid, Box, withStyles, AppBar, Toolbar } from '@material-ui/core';
import styles from '../SignIn/style';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPwd: false,
      showForgotUname: false,
      success: false
    };
  }

  componentDidMount() {
    const { pw } = this.props.match.params;
    if (pw) {
      this.setState({ showForgotPwd: true });
    } else {
      this.setState({ showForgotUname: true });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.headerWrap} position="relative">
          <Toolbar style={{ padding: 0 }}>
            <Box
              className={classes.naviWrap}
              style={{ justifyContent: 'center' }}>
              <Box>
                <RouterLink to="/">
                  <img alt="Logo" src={logo} className={classes.logoImg} />
                </RouterLink>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Grid className={classes.grid} container>
          <Grid
            className={clsx(classes.content, classes.contentFrgt)}
            item
            xs={12}>
            {this.state.showForgotPwd && (
              <span>
                <ForgotPw />
              </span>
            )}
            {this.state.showForgotUname && (
              <span>
                <ForgotUn />
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

Forgot.propTypes = {
  history: PropTypes.object
};

export default withRouter(withStyles(styles)(Forgot));
