import { errorHandler } from '../error/action';
import http from 'services/http';

export const getPTInfo = (
  ehrID,
  page = 0,
  search = '',
  order = 'ASC',
  sortby = 'lastname+firstname+middleinitial'
) => async dispatch => {
  try {
    const limit = '10';
    const res = await http.get(
      `/ehr/${ehrID}/patients?insurance=true&limit=${limit}&order=${order}&page=${page}&search=${search}&sortby=${sortby}`
    );

    if (res) {
      dispatch({
        type: 'PTINFO_GET',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPTInfoDetail = (ehrID, patientid) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/patients/${patientid}/information`
    );
    if (res) {
      dispatch({
        type: 'PTINFO_DETAILS_GET',
        data: res.data
      });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSelectedPatient = patientid => async dispatch => {
  try {
    let medicalHistoryres,
      activeMedicationsres,
      pastDiagnosisres,
      inactiveMedicationsres,
      inactiveDiagnosisres,
      remarksres;
    const patres = await http.get(`/patients/${patientid}`);
    if (patres) {
      medicalHistoryres = await http.get(
        `/patients/${patientid}/medicalHistory`
      );
      if (medicalHistoryres) {
        activeMedicationsres = await http.get(
          `/patients/${patientid}/medicalHistory/activeMedications`
        );
        pastDiagnosisres = await http.get(
          `/patients/${patientid}/medicalHistory/pastDiagnosis`
        );
        inactiveMedicationsres = await http.get(
          `/patients/${patientid}/medicalHistory/inactiveMedications`
        );
        inactiveDiagnosisres = await http.get(
          `/patients/${patientid}/medicalHistory/inactiveDiagnosis`
        );
        remarksres = await http.get(
          `/patients/${patientid}/documents?category=All+Clinical`
        );
        if (remarksres.data.length > 0) {
          remarksres.data.forEach(function(item) {
            if (item.remarksUpdatedDate) {
              item.remarksUpdatedDate = new Date();
            }
          });
        }
      }

      if (remarksres) {
        dispatch({
          type: 'SELECTED_PATIENT_GET',
          data: {
            selectedPatient: patres.data,
            medicalHistory: medicalHistoryres.data,
            medications: activeMedicationsres.data,
            pastDiagnosis: pastDiagnosisres.data,
            inactiveMedications: inactiveMedicationsres.data,
            inactiveDiagnosis: inactiveDiagnosisres.data,
            remarks: remarksres.data
          }
        });

        return true;
      }
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const downloadPDF = url => async () => {
  try {
    const res = await http.get(url, { responseType: 'arraybuffer' });
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const addRemarks = (
  ehrID,
  patientId,
  documentId,
  providerId,
  data
) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(
      `/patients/${patientId}/documents/${documentId}?providerid=${providerId}&remarks=${data.remarks}`,
      data
    );
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteClinicalFindings = (patientId, documentId) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientId}/documents/${documentId}/deleteDocRemarks`
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};

export const signDocument = (
  ehrID,
  patientId,
  documentId,
  providerId,
  data
) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(
      `/patients/${patientId}/documents/${documentId}?providerid=${providerId}&sign=true`,
      data
    );
    if (res.data.documentRoute) {
      proRes = await http.get(
        `/ehr/${ehrID}/providers/${res.data.documentRoute.routeTo}`
      );
    }
    if (proRes) {
      return { patientdocument: res.data, signature: proRes.data };
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const routeToChange = (
  patientId,
  documentId,
  routeId,
  data
) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(
      `/patients/${patientId}/documents/${documentId}/route?routeTo=${routeId}`,
      data
    );
    // if (res.data.documentRoute) {
    //   proRes = await http.get(
    //     `/ehr/${ehrID}/providers/${res.data.documentRoute.routeTo}`
    //   );
    // }
    // if (proRes) {
    //   dispatch({
    //     type: 'REMARKS_GET',
    //     data: {
    //       patientdocument: res.data,
    //       signature: proRes.data
    //     }
    //   });
    //   return true;
    // }
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocuments = (
  ehrID,
  page = 0,
  search = ''
) => async dispatch => {
  try {
    const limit = '10',
      order = 'ASC',
      sortby = 'lastname+firstname+middleinitial';
    const res = await http.get(
      `/ehr/${ehrID}/patients?limit=${limit}&order=${order}&page=${page}&search=${search}&sortby=${sortby}`
    );

    if (res) {
      dispatch({
        type: 'ALL_DOCUMENTS_GET',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getRecaps = (ehrID, casefileID, page = 0) => async dispatch => {
  try {
    const limit = '10';
    const res = await http.get(
      `/ehr/${ehrID}/casefiles/${casefileID}/recaps?limit=${limit}&page=${page}`
    );
    if (res) {
      dispatch({
        type: 'RECAPS_GET',
        data: res.data
      });
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addDocuments = (patientId, data) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(`/patients/${patientId}/documents`, data);
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const uploadFilePDF = (ehrID, patientId, data) => async () => {
  try {
    const formData = new FormData();
    formData.append('file', data.file, data.file.filename);
    formData.append('categoryid', data.categoryid);
    formData.append('providerid', data.providerid);
    formData.append('title', data.title);
    formData.append('ehrid', ehrID);

    const res = await http.post(`/patients/${patientId}/documents`, formData);
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const getPatDetailsById = (
  ehrID,
  userID,
  patientid
) => async dispatch => {
  try {
    let categoryResAllClinical, categoryResAllMiscellaneous, providersRes;
    const res = await http.get(`/patients/${patientid}/patientcharts`);
    if (res && res.data && res.data.length > 0) {
      await Promise.all(
        res.data.map(async item => {
          if (item.signed) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.soapnote.signedby}`
            );
            if (providersRes) {
              item.signedby =
                providersRes.data.firstname + ' ' + providersRes.data.lastname;
            }
          }
          if (item.documentRoute.routeTo === userID) {
            item.routed = false;
          } else {
            item.routed = true;
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.firstname = providersRes.data.firstname;
              item.lastname = providersRes.data.lastname;
            }
          }
        })
      );
    }

    categoryResAllClinical = await http.get(
      `/patients/${patientid}/documents?category=All+Clinical`
    );
    if (
      categoryResAllClinical &&
      categoryResAllClinical.data &&
      categoryResAllClinical.data.length > 0
    ) {
      await Promise.all(
        categoryResAllClinical.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }

    categoryResAllMiscellaneous = await http.get(
      `/patients/${patientid}/documents?category=All+Miscellaneous`
    );
    if (
      categoryResAllMiscellaneous &&
      categoryResAllMiscellaneous.data &&
      categoryResAllMiscellaneous.data.length > 0
    ) {
      await Promise.all(
        categoryResAllMiscellaneous.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }

    if (res) {
      dispatch({
        type: 'PATIENT_CHART_DETAILS',
        data: {
          pastpatientcharts: res.data,
          AllClinical: categoryResAllClinical.data,
          AllMiscellaneous: categoryResAllMiscellaneous.data
        }
      });

      return true;
    }

    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getProviderByID = (ehrID, providerId) => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/providers/${providerId}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getValsByCategories = (
  ehrID,
  patientid,
  category
) => async dispatch => {
  let providersRes;
  try {
    const res = await http.get(
      `/patients/${patientid}/documents?category=${category}`
    );
    if (res && res.data && res.data.length > 0) {
      await Promise.all(
        res.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }
    if (res) {
      dispatch({
        type: 'PATIENT_DOCUMENTS_DETAILS_UPDATED',
        data: res.data,
        cat: category
      });

      return true;
    }

    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocumentCategories = ehrID => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/categories/info`);

    if (res) {
      const clinicalRes = res.data.filter(c => c.clinical == true);
      const miscellaneousRes = res.data.filter(
        c => c.clinical === undefined || c.clinical != true
      );
      dispatch({
        type: 'CATEGORIES',
        data: { clinical: clinicalRes, miscellaneous: miscellaneousRes }
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

//------------------------------------Chart Review--------------

export const getUnsignedCharts = (ehrID, providerID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/providers/${providerID}/documents?unsigned=true`
    );
    if (res) {
      dispatch({
        type: 'UNSIGNED_CHARTS',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePatientInsurance = (ehrID, data) => async dispatch => {
  try {
    let res;
    if (data.dob) {
      res = await http.post(
        `/ehr/${ehrID}/patients/${data.id}/information?date=${data.dob}`,
        data
      );
    } else {
      res = await http.post(
        `/ehr/${ehrID}/patients/${data.id}/information`,
        data
      );
    }

    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const downloadPatientInsuranceDF = (ehrID, patientId) => async () => {
  try {
    const res = await http.get(
      `ehr/${ehrID}/patients/${patientId}/information/pdf`,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};
