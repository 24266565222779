import { errorHandler } from '../error/action';
import http from 'services/http';

export const getCaseFiles = (
  ehrID,
  locationId = -1,
  page = 0,
  order = 'DESC',
  sortby = 'updatedDate'
) => async dispatch => {
  try {
    const limit = '10';
    const res = await http.get(
      `/ehr/${ehrID}/casefiles?limit=${limit}&locationID=${locationId}&order=${order}&page=${page}&sortby=${sortby}`
    );
    if (res) {
      dispatch({
        type: 'CASEFILES_GET',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocInfo = (
  patientid, patientDocumentId
) => async () => {
  const docinfo = await http.get(
    `/patients/${patientid}/documents/${patientDocumentId}/docInfo`
  );
  if (docinfo) {
    return docinfo
  }
  else {
    return false
  }
}

export const getPatientDocuments = (
  ehrID,
  patientid,
  patientDocumentId,
  providerID,
  id
) => async dispatch => {
  try {
    let docRouteRes, patRes;
    const docRes = await http.get(
      `/patients/${patientid}/documents/${patientDocumentId}/info`
    );
    if (docRes.data.documentRoute) {
      docRouteRes = await http.get(
        `/ehr/${ehrID}/providers/${docRes.data.documentRoute.routeTo}`
      );
    }
    patRes = await http.get(`/patients/${patientid}`);

    if (patRes) {
      dispatch({
        type: 'DOCUMENT_GET',
        data: {
          patientdocument: docRes.data,
          signature: docRouteRes?.data,
          patient: patRes.data
        }
      });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getPatientMiscDocuments = (
  ehrID,
  patientid,
  patientDocumentId,
  item
) => async dispatch => {
  try {
    let patRes;
    const miscDocRes = await http.get(
      `/patients/${patientid}/documents/${patientDocumentId}/info`
    );

    patRes = await http.get(`/patients/${patientid}`);

    if (patRes) {
      dispatch({
        type: 'MISC_DOCUMENT_GET',
        data: {
          patientdocument: item,
          patient: patRes.data
        }
      });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSelectedPatient = patientid => async dispatch => {
  try {
    let medicalHistoryres,
      activeMedicationsres,
      pastDiagnosisres,
      inactiveMedicationsres,
      inactiveDiagnosisres,
      remarksres;
    const patres = await http.get(`/patients/${patientid}`);
    if (patres) {
      medicalHistoryres = await http.get(
        `/patients/${patientid}/medicalHistory`
      );
      if (medicalHistoryres) {
        activeMedicationsres = await http.get(
          `/patients/${patientid}/medicalHistory/activeMedications`
        );
        pastDiagnosisres = await http.get(
          `/patients/${patientid}/medicalHistory/pastDiagnosis`
        );
        inactiveMedicationsres = await http.get(
          `/patients/${patientid}/medicalHistory/inactiveMedications`
        );
        inactiveDiagnosisres = await http.get(
          `/patients/${patientid}/medicalHistory/inactiveDiagnosis`
        );
        remarksres = await http.get(
          `/patients/${patientid}/documents?category=All+Clinical`
        );
        if (remarksres.data.length > 0) {
          remarksres.data.forEach(function (item) {
            if (item.remarksUpdatedDate) {
              item.remarksUpdatedDate = new Date();
            }
          });
        }
      }

      if (remarksres) {
        dispatch({
          type: 'SELECTED_PATIENT_GET',
          data: {
            selectedPatient: patres.data,
            medicalHistory: medicalHistoryres.data,
            medications: activeMedicationsres.data,
            pastDiagnosis: pastDiagnosisres.data,
            inactiveMedications: inactiveMedicationsres.data,
            inactiveDiagnosis: inactiveDiagnosisres.data,
            remarks: remarksres.data
          }
        });

        return true;
      }
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const downloadPDF = url => async () => {
  try {
    const res = await http.get(url, { responseType: 'arraybuffer' });
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const addRemarks = (
  ehrID,
  patientId,
  documentId,
  providerId,
  data
) => async dispatch => {
  try {
    let proRes;
    const remarks = encodeURIComponent(data.remarks);
    const res = await http.post(
      `/patients/${patientId}/documents/${documentId}?providerid=${providerId}&remarks=${remarks}`,
      data
    );
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteClinicalFindings = (patientId, documentId) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientId}/documents/${documentId}/deleteDocRemarks`
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};

export const signDocument = (
  ehrID,
  patientId,
  documentId,
  providerId,
  data
) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(
      `/patients/${patientId}/documents/${documentId}?providerid=${providerId}&sign=true`,
      data
    );
    if (res.data.documentRoute) {
      proRes = await http.get(
        `/ehr/${ehrID}/providers/${res.data.documentRoute.routeTo}`
      );
    }
    if (proRes) {
      return { patientdocument: res.data, signature: proRes.data };
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const routeToChange = (
  patientId,
  documentId,
  routeId,
  data
) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(
      `/patients/${patientId}/documents/${documentId}/route?routeTo=${routeId}`,
      data
    );
    // if (res.data.documentRoute) {
    //   proRes = await http.get(
    //     `/ehr/${ehrID}/providers/${res.data.documentRoute.routeTo}`
    //   );
    // }
    // if (proRes) {
    //   dispatch({
    //     type: 'REMARKS_GET',
    //     data: {
    //       patientdocument: res.data,
    //       signature: proRes.data
    //     }
    //   });
    //   return true;
    // }
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocuments = (
  ehrID,
  page = 0,
  search = ''
) => async dispatch => {
  try {
    const limit = '10',
      order = 'ASC',
      sortby = 'lastname+firstname+middleinitial';
    const res = await http.get(
      `/ehr/${ehrID}/patients?limit=${limit}&order=${order}&page=${page}&search=${search}&sortby=${sortby}`
    );

    if (res) {
      dispatch({
        type: 'ALL_DOCUMENTS_GET',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getRecaps = (ehrID, casefileID, page = 0) => async dispatch => {
  try {
    const limit = '10';
    const res = await http.get(
      `/ehr/${ehrID}/casefiles/${casefileID}/recaps?limit=${limit}&page=${page}`
    );
    if (res) {
      dispatch({
        type: 'RECAPS_GET',
        data: res.data
      });
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addDocuments = (patientId, data) => async dispatch => {
  try {
    let proRes;
    const res = await http.post(`/patients/${patientId}/documents`, data);
    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const uploadFilePDF = (ehrID, patientId, data) => async () => {
  try {
    const formData = new FormData();
    formData.append('file', data.file, data.file.filename ? data.file.filename : data.file.name);
    formData.append('categoryid', data.categoryid);
    formData.append('providerid', data.providerid);
    formData.append('title', data.title);
    formData.append('ehrid', ehrID);

    const res = await http.post(`/patients/${patientId}/documents`, formData);
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};

export const getPatientCharts = (patientid, page = 0, limit = 10) => async dispatch => {
  try {
    const res = await http.get(`/patients/${patientid}/patientcharts?&page=${page}&limit=${limit}`);
    if (res) {
      dispatch({
        type: 'PATIENT_CHART_DETAILS',
        data: { chart: res.data, clearData: page === 0 },

      });
      return res.data
    }
    return false
  } catch (error) {
    errorHandler(error);
    return false;
  }
}


export const getPatientAllClinicalDocuments = (patientid, ehrID) => async dispatch => {
  try {
    const res = await http.get(`/patients/${patientid}/documents?category=All+Clinical`);

    if (
      res &&
      res.data &&
      res.data.length > 0
    ) {
      await Promise.all(
        res.data.map(async item => {
          if (item.documentRoute) {
            const providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }
    dispatch({
      type: 'PATIENT_CLINICAL_DOCUMENTS',
      data: res.data
    });
    return true



  } catch (error) {
    errorHandler(error);
    return false;
  }
}


export const getPatientAllMiscellaneousDocuments = (patientid, ehrID) => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientid}/documents?category=All+Miscellaneous`
    );
    if (
      res &&
      res.data &&
      res.data.length > 0
    ) {
      await Promise.all(
        res.data.map(async item => {
          if (item.documentRoute) {
            const providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }
    dispatch({
      type: 'PATIENT_MISCELLANEOUS_DOCUMENTS',
      data: res.data
    });
    return true
  } catch (error) {
    errorHandler(error);
    return false;
  }
}

export const getPatDetailsById = (
  ehrID,
  userID,
  patientid
) => async dispatch => {
  try {
    let categoryResAllClinical, categoryResAllMiscellaneous, providersRes;

    const res = await http.get(`/patients/${patientid}/patientcharts`);

    // if (res && res.data && res.data.length > 0) {
    //   await Promise.all(
    //     res.data.map(async item => {
    //       if (item.signed && item.soapnote.signedby) {
    //         providersRes = await http.get(
    //           `/ehr/${ehrID}/providers/${item.soapnote.signedby}`
    //         );
    //         if (providersRes) {
    //           item.signedby =
    //             providersRes.data.firstname + ' ' + providersRes.data.lastname;
    //         }
    //       }
    //       if (item.documentRoute.routeTo === userID) {
    //         item.routed = false;
    //       } else {
    //         item.routed = true;
    //         providersRes = await http.get(
    //           `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
    //         );
    //         if (providersRes) {
    //           item.firstname = providersRes.data.firstname;
    //           item.lastname = providersRes.data.lastname;
    //         }
    //       }
    //     })
    //   );
    // }

    categoryResAllClinical = await http.get(
      `/patients/${patientid}/documents?category=All+Clinical`
    );
    if (
      categoryResAllClinical &&
      categoryResAllClinical.data &&
      categoryResAllClinical.data.length > 0
    ) {
      await Promise.all(
        categoryResAllClinical.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }

    categoryResAllMiscellaneous = await http.get(
      `/patients/${patientid}/documents?category=All+Miscellaneous`
    );
    if (
      categoryResAllMiscellaneous &&
      categoryResAllMiscellaneous.data &&
      categoryResAllMiscellaneous.data.length > 0
    ) {
      await Promise.all(
        categoryResAllMiscellaneous.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }

    if (res) {
      dispatch({
        type: 'PATIENT_CHART_DETAILS',
        data: {
          pastpatientcharts: res.data,
          AllClinical: categoryResAllClinical.data,
          AllMiscellaneous: categoryResAllMiscellaneous.data
        }
      });

      return true;
    }

    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getProviderByID = (ehrID, providerId) => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/providers/${providerId}`);
    if (res) {
      return res.data;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const clearPtDoc = () => async dispatch => {
  dispatch({
    type: 'CLEAR_PTDOC'
  });
};

export const getValsByCategories = (
  ehrID,
  patientid,
  category
) => async dispatch => {
  let providersRes;
  try {
    const res = await http.get(
      `/patients/${patientid}/documents?category=${category}`
    );
    if (res && res.data && res.data.length > 0) {
      await Promise.all(
        res.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }
    if (res) {
      dispatch({
        type: 'PATIENT_DOCUMENTS_DETAILS_UPDATED',
        data: res.data,
        cat: category
      });

      return true;
    }

    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getDocumentCategories = ehrID => async dispatch => {
  try {
    const res = await http.get(`/ehr/${ehrID}/categories/info`);

    if (res) {
      const clinicalRes = res.data.filter(c => c.clinical == true);
      const miscellaneousRes = res.data.filter(
        c => c.clinical === undefined || c.clinical != true
      );
      dispatch({
        type: 'CATEGORIES',
        data: { clinical: clinicalRes, miscellaneous: miscellaneousRes }
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

//------------------------------------Chart Review--------------

export const getUnsignedCharts = (ehrID, providerID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/providers/${providerID}/documents?unsigned=true`
    );
    if (res) {
      dispatch({
        type: 'UNSIGNED_CHARTS',
        data: res.data
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getSoapDetailsById = (
  ehrID,
  userID,
  patientid,
  soapid
) => async dispatch => {
  try {
    let categoryResAllClinical, categoryResAllMiscellaneous, providersRes;
    const res = await http.get(`/patients/${patientid}/soapnote/${soapid}`);
    if (res && res.data && res.data.length > 0) {
      await Promise.all(
        res.data.map(async item => {
          if (item.signed) {
            providersRes = await http.get(
              `/patients/${patientid}/soapnote/${soapid}`
            );
            if (providersRes) {
              item.signedby =
                providersRes.data.firstname + ' ' + providersRes.data.lastname;
            }
          }
          if (item.documentRoute.routeTo === userID) {
            item.routed = false;
          } else {
            item.routed = true;
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.firstname = providersRes.data.firstname;
              item.lastname = providersRes.data.lastname;
            }
          }
        })
      );
    }

    categoryResAllClinical = await http.get(
      `/patients/${patientid}/documents?category=All+Clinical`
    );
    if (
      categoryResAllClinical &&
      categoryResAllClinical.data &&
      categoryResAllClinical.data.length > 0
    ) {
      await Promise.all(
        categoryResAllClinical.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }

    categoryResAllMiscellaneous = await http.get(
      `/patients/${patientid}/documents?category=All+Miscellaneous`
    );
    if (
      categoryResAllMiscellaneous &&
      categoryResAllMiscellaneous.data &&
      categoryResAllMiscellaneous.data.length > 0
    ) {
      await Promise.all(
        categoryResAllMiscellaneous.data.map(async item => {
          if (item.documentRoute) {
            providersRes = await http.get(
              `/ehr/${ehrID}/providers/${item.documentRoute.routeTo}`
            );
            if (providersRes) {
              item.providerInfo = providersRes.data;
            }
          }
        })
      );
    }

    if (res) {
      dispatch({
        type: 'SOAP_DETAILS_BY_ID',
        data: {
          pastpatientcharts: res.data,
          AllClinical: categoryResAllClinical.data,
          AllMiscellaneous: categoryResAllMiscellaneous.data
        }
      });

      return true;
    }

    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getChartViewSoapandPatientDetails = (
  ehrID,
  patientid,
  providerId,
  soapId
) => async dispatch => {
  try {
    let soapres, remarksres;
    // const patres = await http.get(`/patients/${patientid}`);
    // if (patres) {
    //   medicalHistoryres = await http.get(
    //     `/patients/${patientid}/medicalHistory`
    //   );
    //   if (medicalHistoryres) {
    //     activeMedicationsres = await http.get(
    //       `/patients/${patientid}/medicalHistory/activeMedications`
    //     );
    //     pastDiagnosisres = await http.get(
    //       `/patients/${patientid}/medicalHistory/pastDiagnosis`
    //     );
    //     inactiveMedicationsres = await http.get(
    //       `/patients/${patientid}/medicalHistory/inactiveMedications`
    //     );
    //     inactiveDiagnosisres = await http.get(
    //       `/patients/${patientid}/medicalHistory/inactiveDiagnosis`
    //     );
    //     remarksres = await http.get(
    //       `/patients/${patientid}/documents?category=All+Clinical`
    //     );
    //     if (remarksres.data.length > 0) {
    //       remarksres.data.forEach(function (item) {
    //         if (item.remarksUpdatedDate) {
    //           item.remarksUpdatedDate = new Date();
    //         }
    //       });
    //     }
    //   }
    soapres = await http.get(
      `ehr/${ehrID}/providers/${providerId}/patientcharts/${soapId}`
    );

    if (soapres) {
      dispatch({
        type: 'CHARTVIEW_SOAP_PATIENT_DETAILS',
        data: {
          soap: soapres.data,
          // selectedPatient: patres.data,
          // medicalHistory: medicalHistoryres.data,
          // medications: activeMedicationsres.data,
          // pastDiagnosis: pastDiagnosisres.data,
          // inactiveMedications: inactiveMedicationsres.data,
          // inactiveDiagnosis: inactiveDiagnosisres.data,
          // remarks: remarksres.data
        }
      });

      return {
        status: true,
        chartSoapPatientDetails: {
          soap: soapres.data,
          // patientInfo: {
          //   selectedPatient: patres.data,
          //   medicalHistory: medicalHistoryres.data,
          //   medications: activeMedicationsres.data,
          //   pastDiagnosis: pastDiagnosisres.data,
          //   inactiveMedications: inactiveMedicationsres.data,
          //   inactiveDiagnosis: inactiveDiagnosisres.data,
          //   remarks: remarksres.data
          // }
        }
      };
    }
    // }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteDocuments = (patientId, documentId) => async () => {
  try {
    const res = await http.delete(
      `/patients/${patientId}/documents/${documentId}`
    );
    if (res) {
      return res;
    }
    return true;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};

export const DeactivatePatientDocumentStatus = (patientId, documentid, data) => async () => {
  try {

    const res = await http.post(
      `/patients/${patientId}/documents/${documentid}/deactivateDocStatus`,
      data
    );
    if (res) {
      return { status: true, inactivedocument: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const ActivatePatientDocumentStatus = (patientId, documentid, data) => async () => {
  try {
    const res = await http.post(
      `/patients/${patientId}/documents/${documentid}/activateDocStatus`,
      data
    );
    if (res) {
      return { status: true, activedocument: res.data };
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
