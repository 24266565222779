export default {
  bloodpressure: {
    presence: { allowEmpty: false }
  },
  temperature: {
    presence: { allowEmpty: false }
  },
  heartrate: {
    presence: { allowEmpty: false }
  },
  respiratoryrate: {
    presence: { allowEmpty: false }
  },
  weight: {
    presence: { allowEmpty: false }
  },
  bodymassindex: {
    presence: { allowEmpty: false }
  },
  height: {
    presence: { allowEmpty: false }
  },
  subjective: {
    presence: { allowEmpty: false }
  },
  objective: {
    presence: { allowEmpty: false }
  },
  assessmentplan: {
    presence: { allowEmpty: false }
  },
  diagnosis: {
    presence: { allowEmpty: false }
  },
  visitdate: {
    presence: { allowEmpty: false }
  },
};
