import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from '../../../style';

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
 Box,
  CircularProgress
} from '@material-ui/core';
import { getLocations } from 'services/util/action';
import { connect } from 'react-redux';
import {
  getCaseFiles,
  getPatientDocuments,
  getRecaps, 
} from 'services/home/action';

import moment from 'moment';

const Details = props => {
  const {
    classes,
    className,
    locations,
    ehrID,
    profile,
    userID,
    clinicalDocuments,
    ...rest
  } = props;

  const [data, setData] = useState([]);
  const [dataHx, setDataHX] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (
      clinicalDocuments &&
      clinicalDocuments.length > 0
    ) {
      let datatopush = [];
      clinicalDocuments &&
      clinicalDocuments.forEach(function(item) {
        if(item.isActive){
          datatopush.push({
            patientId: item.documentRoute.patientid,
            patientDocumentId: item.documentRoute.patientDocumentId,
            routeTo: item.documentRoute.routeTo,
            patient: item.patient,

            title: item.documentRoute.description,
            documentCreated: item.documentCreated
              ? moment(item.documentCreated).format('MM/DD/YYYY')
              : ''
          });
        }
        });
      setData(datatopush);
      setLoading(false);
    }  else{
      setLoading(false);
    }
  }, [clinicalDocuments]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '10px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientDocumentId',
      options: {
        display: 'excluded'
      }
    },

    {
      name: 'routeTo',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'dob',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patient',
      options: {
        display: 'excluded'
      }
    },

    {
      name: 'title',
      label: 'Description',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.handleOpenDoc(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1],
                  tableMeta.rowData[2],
                  tableMeta.rowData[3],
                  tableMeta.rowData[4],
                  tableMeta.rowData[5],
                  tableMeta.rowData[6]
                )
              }>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'documentCreated',
      label: 'Created On',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() =>
                props.handleOpenDoc(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1],
                  tableMeta.rowData[2],
                  tableMeta.rowData[3],
                  tableMeta.rowData[4],
                  tableMeta.rowData[5],
                  tableMeta.rowData[6]
                )
              }>
              {value}
            </span>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRows: false,
    serverSide: true,

    onRowClick: rowData =>
      props.handleOpenDoc(
        rowData[0],
        rowData[1],
        rowData[2],
        rowData[3],
        rowData[4],
        rowData[5],
        rowData[6]
      )
  };

  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      <Grid container>
        <Grid className={classes.cardHeader} item xs={8}>
          <Typography variant="h2">
            {(profile && profile.firstname ? profile.firstname : '') +
              ' ' +
              (profile && profile.lastname ? profile.lastname : ' ') +
              ' '}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        {loading ? (
          <div
            className={classes.progressWrapper}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              display: 'flex',
              bottom: 0,
              top: 0,
              alignItems: 'flex-start',
              paddingTop: 120,
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0,.2)',
              zIndex: 1000
            }}>
            <CircularProgress style={{ height: 30, width: 30 }} />
          </div>
        ) : data ? (
          <MuiThemeProvider theme={theme}>
            <div className={classes.fullWidthTable}> 
                <MUIDataTable
                  columns={columns}
                  data={data}
                  options={options}
                  responsive="scrollMaxHeight"
                /> 
            </div>
          </MuiThemeProvider>
        ):(
            
          <Box className={classes.noData}>
                <Typography variant="h4">No records found. There are no results matching your search.</Typography>
              </Box>
        )}
      </CardContent>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getLocations,
  getCaseFiles,
  getPatientDocuments,
  getRecaps, 
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  profile: state && state.profile,
  casefiles: state.home && state.home.list,
  documentInfo: state.home && state.home.documentInfo,
  clinicalDocuments: state.home && state.home.clinicalDoc,
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(Details));
