import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  CloseOutlined,
  EditOutlined,
  AssignmentOutlined,
  DeleteOutlineOutlined,
  AccountBoxOutlined,
  ContactsOutlined
} from '@material-ui/icons/';
import {clearSoap} from 'services/soap/action'

const ScheduleQuickView = props => {
  const { classes, className, item, ...rest } = props;

  const handleEditAppointment = item => {
    props.handleEditAppointment(item);
  };

  const handleDeleteAppointment = itemId => {
    props.handleDeleteAppointment(itemId);
  };

  const handleOpenSOAP = patientId => {
    props.clearSoap()
    props.history.push({
      pathname: '/default',
      state: { patientId: patientId }
    });
  };

  const getLabel = obj => {
    if (obj && obj.trim().length > 200) {
      return `${obj.substring(0, 200)}...`;
    }
    return obj;
  };
  return (
    <Box className={classes.modalWrap} style={{ width: 400 }}>
      <Box className={classes.modalHead}>
        <Typography variant="h2">&nbsp;</Typography>
        <Box className={classes.rightIconGroup}>
          <IconButton
            aria-label="Edit"
            onClick={() => handleEditAppointment(item.schedule)}>
            <EditOutlined />
          </IconButton>
          <IconButton aria-label="delete"  onClick={() => handleOpenSOAP(item.schedule.patientId)} >
            <AssignmentOutlined />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteAppointment(item.schedule.id)}>
            <DeleteOutlineOutlined />
          </IconButton>
          <IconButton aria-label="Close" onClick={props.onCancel}>
            <CloseOutlined />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.modalBody}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ContactsOutlined className={classes.leftIcon} />
          <div>
            <Typography variant="h5">
              <b> {item && item.schedule && item.schedule.patientName}</b>
            </Typography>
            <Typography variant="subtitle2">
              {item && item.schedule && item.schedule.timeFrom
                ? moment(item.schedule.timeFrom).format('LLLL')
                : null}
              {item && item.schedule && item.schedule.timeTo
                ? ' - ' + moment(item.schedule.timeTo).format('hh:mm A')
                : null}
            </Typography>
          </div>
        </div>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <AccountBoxOutlined className={classes.leftIcon} />
          <div>
            <Typography variant="h5">
              {item && item.schedule && item.schedule.routePhysicianName}
            </Typography>
            <Typography variant="subtitle2">
              {item &&
                item.schedule &&
                item.schedule.reasonForAppointment &&
                getLabel(item.schedule.reasonForAppointment)}
            </Typography>
          </div>
        </div>
      </Box>
    </Box>
  );
};

ScheduleQuickView.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
    clearSoap
};

const mapStateToProps = state => ({ 
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ScheduleQuickView)))
