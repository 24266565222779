import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: 0,
    paddingLeft: 24
  }
}));

const Footer = props => {
  const { className, organization, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="caption" className={classes.colorPrimary}>
        {
          organization && organization.websitename ?
            (<Typography style={{ fontSize: 11.5, color: '#808080' }}>
              <RouterLink
                style={{ color: '#3397f3' }}
                onClick={() => window.open(organization.websitename, "_blank")}>
                {organization && organization.organizationname + '.' + ' '}
              </RouterLink>
              All rights reserved.
            </Typography>) :
            (<Typography style={{ fontSize: 11.5, color: '#808080' }}>
              <span>
                {organization && organization.organizationname + '.' + ' '}
              </span>
              All rights reserved.
            </Typography>)
        }

      </Typography>
    </div>
  );
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  organization: state.ehr && state.ehr.organization,
});

Footer.propTypes = {
  className: PropTypes.string
};

export default withRouter(
  connect(mapStateToProps)((Footer))
);
