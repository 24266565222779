import React from 'react';
import { CheckCircleOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import styles from '../../../SignIn/style';

import { Fab, Typography, withStyles } from '@material-ui/core';

const Success = props => {
  const { classes,  values } = props;

  return (
    <div className={classes.contentBodyMsg} style={{ position: 'relative' }}>
      <div className={classes.contentHeader}>
        <CheckCircleOutlined />
        <Typography variant="h1">Thanks for signing up.</Typography>
      </div>
      <div className={classes.contentInner}>
        <Typography style={{ color: '#4EC1A5' }} variant="h6">
          Hi {values.name}
        </Typography>

        <Typography variant="body1">
          <br />
          An account activation email has been sent to{' '}
          {values.organizationname}
          registered email address. Please check your inbox and click on the
          link in the message to confirm and activate your account.
        </Typography>
        <br />
        <Fab
          aria-label="add"
          className={classes.roundButton}
          color="primary"
          component={RouterLink}
          size="medium"
          to="/sign-in"
          variant="extended">
          Click here for your EHR Login
        </Fab>
      </div>
    </div>
  );
};

Success.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(Success));
