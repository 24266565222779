import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './style';
import { resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks  } from 'services/soap/action'; 
import { ClinicalVisits, ClinicalDocuments, Ptinfo } from './components';
import { Details } from '../PtDashboard/components/Ptinfo/components';
import {
  HomeOutlined,
  DescriptionOutlined,
  InfoOutlined
} from '@material-ui/icons';
import { getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, } from 'services/home/action';
import { connect } from 'react-redux';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 4,
    marginBottom: -10,
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#e8e8e8',
      borderRadius: 2
    }
  },
  scroller: {
    overflow: 'visible!important'
  },
  root: {
    overflow: 'visible!important'
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#606060',
    fontWeight: 600,
    fontSize: 16,
    marginRight: 0,
    background: '#E3E3E3',
    borderRadius: '5px 5px 0 0',
    border: '1px solid #C8C8C8',
    minHeight: 50,
    minWidth: 150,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto'
    },
    letterSpacing: 0,
    '&:hover': {
      backgroundColor: '#ececec'
    },
    '&:focus': {
      opacity: 1,
      backgroundColor: '#f9f9f9'
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      border: 'transparent',
      boxShadow: '-1px -2px 2px rgba(0, 0, 0, 0.04)'
    },
    '& span': {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0!important',
        transform: 'scale(.8)'
      }
    }
  }
}))(props => <Tab {...props} />);

const Default = props => {
  const {
    classes,
    className,
    ehrID,
    userID,
    getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
    ...rest
  } = props;

  function TabContainer(props) {
    return (
      <Typography className={classes.tabWrap} component="div">
        {props.children}
      </Typography>
    );
  }

  TabContainer.propTypes = {
    children: PropTypes.node.isRequired
  };

  const [value, setValue] = useState(0); 

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const changeTab = (patientId, tabId) => {
    setValue(tabId);
  };

  useEffect(() => {
    if (ehrID && userID) {
      getPatientAllClinicalDocuments(userID, ehrID);
      getPatientAllMiscellaneousDocuments(userID, ehrID);
      getPatientCharts(userID);
      // getPatientActiveMedications(userID) 
      // getPatientInactiveDiagnosis(userID) 
      // getPatientInactiveMedication(userID)
      // getPatientMedicalHistory(userID)
      // getPatientPastDiagnosis(userID)
      // getPatientRemarks(userID) 
    }
  }, [userID]);

    return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledTabs
            className={classes.tabs}
            onChange={handleChange}
            scrollButtons="auto"
            value={value}
            variant="scrollable">
            <StyledTab icon={<HomeOutlined />} label="Clinical Visits" />
            <StyledTab
              icon={<DescriptionOutlined />}
              label="Clinical Documents"
            />
            <StyledTab icon={<InfoOutlined />} label="Registration" />
          </StyledTabs>
          {value === 0 && (
            <TabContainer>
              <ClinicalVisits changeTab={changeTab}  />
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <ClinicalDocuments changeTab={changeTab}  />
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer>
              <Details isDetails changeTab={changeTab} userID={userID} />
            </TabContainer>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Default))
);
