import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../../SignIn/style';

import { Typography, withStyles } from '@material-ui/core';

const Agree = props => {
  const { classes } = props;

  return (
    <span>
      <Typography className={classes.termsTitle} variant="h1">
        PATIENT PORTAL ACCESS REGISTRATION AGREEMENT
      </Typography>
      <div className={classes.contentBody}>
        <div className={classes.termsContent}>
          <PerfectScrollbar>
            <div>
              <p>
                Please read the following Patient Portal Registration Agreement
                (<strong>"Agreement"</strong>) carefully before registering for
                access to the non-public portions of this website (the <strong>“Portal"</strong>).
                Access to the Portal is provided to you by your health care provider
                (<strong>"Provider"</strong>) in conjunction with EHRZip. Use of the
                Portal will permit you to access certain of your identifiable health information
                ("Content ") and  other services that may be available from time to time
                (<strong>"Services"</strong>).
              </p>
              <p style={{ fontWeight: 'bold' }}>
                BY CLICKING “I AGREE” BELOW, YOU ARE COMPLETING YOUR REGISTRATION FOR ACCESS TO THE PORTAL ON BEHALF OF YOURSELF OR THE INDIVIDUAL PATIENT FOR WHOM YOU ARE THE LEGAL REPRESENTATIVE AND YOU ARE AGREEING, TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT WITH EHRZIP.</p>
              <p style={{ fontWeight: 'bold' }}>
                IF YOU DO NOT AGREE, YOU SHOULD CLICK “I DO NOT AGREE” AND YOU WILL NOT BE PERMITTED TO ACCESS THE PORTAL.
              </p>
              <p style={{ fontWeight: 'bold' }}>1. Portal Use</p>
              <p>
                Access to the Portal is a provided to you as a discretionary benefit by your Provider and EHRZip. <strong>The Portal should never be used for urgent health care matters and is not a substitute for consultation with your Health Care Provider.  If you are experiencing an urgent health matter or otherwise need immediate attention, you should phone your Provider or call 911</strong>.
              </p>
              <p style={{ fontWeight: 'bold' }}>2. Electronic Communications</p>
              <p>
                You understand by signing up to access the Portal, you are agreeing to engage in electronic transactions and that electronic agreements and electronic signatures are binding and meet any legal requirements that any document or signature be in writing.
              </p>
              <p style={{ fontWeight: 'bold' }}>3. Solicitation to Request Access</p>
              <p>
                Nothing on this Portal constitutes an "offer" on behalf of EHRZip or the Provider, but is rather an invitation for you to request to access the Portal to receive the Content and Services. EHRZip reserves the right to request additional verifications or information before granting any request to access the Portal or Services.
              </p>
              <p>
                All offers set forth on this Portal are void where prohibited.
              </p>

              <p style={{ fontWeight: 'bold' }}>4. Consent and Agreement</p>
              <p>
                Your use of this Portal is subject to the posting of any official terms or policies, including without limitation, our Privacy Policy.  However, because the Portal is a hosted online, EHRZip may occasionally need to notify all Portal users of announcements or changes related to the Portal, such as to the Terms and Conditions of Use or other policies.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                You confirm that you have authorized the Provider to send you the link to register to access the Portal by completing the Patient Portal Activation Request and you further confirm that you have the legal authority and capacity to execute the Patient Portal Activation Request.
                Both EHRZIP and Provider will collect, use and disclose your identifiable health information obtained via the Portal in accordance with the Health Insurance Portability and Accountability Act ("HIPAA") privacy and security rules, meaning on a need-to-know basis within our company in order to operate the Portal.  Any third party disclosures will only consist of de-identified information.  For certain uses and disclosures of your health information, such as to send you certain communications, you may be asked to execute a separate HIPAA Authorization form for such use and/or disclosure. Your execution of the HIPAA Authorization form permitting such uses and/or disclosures is voluntary.
              </p>
              <p style={{ fontWeight: 'bold' }}>5. User Names and Access</p>
              <p>
                If your registration is confirmed by EHRZip, you will be granted a limited license to use the Portal and provided with access to the Portal via a user name and password that you select during the registration process or one provided by the Provider. You are responsible for all activity under any user name assigned to your account. You have the sole responsibility for ensuring the confidentiality of the user name and password for your account. You agree to immediately notify EHRZip of any unauthorized use of your account or any user name assigned to your account and any other breach of security of which you become aware
              </p>
              <p style={{ fontWeight: 'bold' }}>6.  Services</p>
              <p>
                Subject to approval of your request for Portal access and your compliance with this Agreement, EHRZip grants to you a non-exclusive, non-transferable, limited license to access and use the Portal (including the Content and Services) solely for your personal use.
              </p>
              <p>
                We reserve the right to change, modify, suspend or discontinue any aspect of the Content or Services available, or the Portal in its entirety, at any time without notice.
              </p>
              <p>
                You acknowledge that you will be responsible for (i) obtaining and maintaining all computer hardware, software and communications equipment necessary to access the Portal, and (ii) paying all third-party telecommunications or other charges incurred by you while using the Portal.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                THE CONTENT MADE AVAILABLE TO YOU ON THIS PORTAL IS YOUR IDENTIFIABLE HEALTH INFORMATION MAINTAINED IN YOUR ELECTRONIC HEALTH RECORDS MAINTAINED BY YOUR PROVIDER AND, IF YOU ELECT TO RECEIVE IT, CERTAIN MATERIALS RELATED TO YOUR TREATMENT MADE AVAILABLE TO YOU BY EHRZIP AND YOUR PROVIDER.  YOU ACKNOWLEDGE THAT EHRZIP HAS NO RESPONSIBILITY OR LIABILITY FOR THE HEALTH INFORMATION MAINTAINED BY YOUR PROVIDER.  SHOULD YOU HAVE ANY QUESTIONS OR CONCERNS RELATING TO THE CONTENT, YOU MUST ADDRESS THEM TO YOUR PROVIDER.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                PLEASE CONTACT YOUR PROVIDER FOR MEDICAL ADVICE.  NEITHER EHRZIP, NOR ITS THEIR RESPECTIVE AFFILIATES GUARANTEES THE SEQUENCE, ACCURACY, OR COMPLETENESS OF THE CONTENT AND SHALL NOT BE LIABLE IN ANY WAY TO YOU, OR ANYONE ELSE TO WHOM THE CONTENT MAY BE FURNISHED, FOR ANY DELAYS, INACCURACIES, UNAVAILABILITY, ERRORS OR OMISSIONS IN THE CONTENT. SUCH CONTENT IS RELIED UPON AT YOUR OWN RISK AND SOLE DISCRETION.
              </p>
              <p>
                Neither EHRZip nor its affiliates shall be liable for any dispute that arises between you and the Provider or any third party.
              </p>
              <p style={{ fontWeight: 'bold' }}>7. Proprietary Ownership</p>
              <p>
                You acknowledge that the Portal and all Content, and underlying technology, names, logos, text, audio, forms, documents, materials, data, video, graphics, charts, photographs, icons, and the design, selection, and arrangement of content in any medium on this Portal are the sole property of the Provider, and/or EHRZip and may be protected by copyright, trademark, service mark, trade secret, trade dress, patent and other intellectual property laws, as applicable. You acquire no right, title or interest in the Portal, Content or Services, or any copyrighted works, trademarks or any other intellectual property except the limited license granted in Section 5 above.
              </p>
              <p>
                Any feedback, suggestions, solutions, improvements, corrections or other contributions you provide regarding the Content, Services or Portal shall become the sole property of EHRZip and you agree to assign and hereby assign any and all such rights to EHRZip without charge. You further agree to take all acts reasonably requested by EHRZip to confirm EHRZip's ownership of such items. Notwithstanding the above, certain portions of the Portal may eventually permit and facilitate secure communications via the Portal between patients' physicians and other clinicians and administrators participating in your care, which will be kept confidential as required by law.
              </p>
              <p style={{ fontWeight: 'bold' }}>8. Prohibited Uses</p>
              <p>
                Nothing in this Agreement may be construed as granting you any other right in the Portal outside of the limited license to access the Portal, Content, and Services. You may not otherwise use, reproduce, modify, mirror, transmit, display, make, offer to sell, sell, license, decompile, disassemble, reverse engineer, distribute, or create derivative works from the Portal or any part thereof, or any content or technology contained therein.
              </p>
              <p>
                EHRZip may, but shall have no obligation to, access and review all information and data provided by you for compliance with the terms of this Agreement.
              </p>
              <p style={{ fontWeight: 'bold' }}> 9. Cancellation and Termination</p>
              <p>
                EHRZip reserves the right to terminate or suspend your access to and use of the Portal, in part or in whole, in its discretion at any time, with or without cause.  Among other things, EHRZip may terminate or suspend access in the event of: (i) your breach or suspected breach of any of the provisions of this Agreement; (ii) your use or misuse of the Portal, Content or Services, for any activity or course of conduct that EHRZip deems to be unlawful; (iii) in the event EHRZip reasonably determines that such termination is necessary or proper or at the request of the Provider, or (iv) EHRZip ceasing to provide the Portal, Content or Services. In addition, if the relationship between your Provider and EHRZip is terminated or discontinued for any reason, your account may be deactivated within thirty (30) days after the termination or discontinuance with or without notice.
              </p>

              <p style={{ fontWeight: 'bold' }}> 10. No Liability</p>
              <p style={{ fontWeight: 'bold' }}>
                YOU UNDERSTAND AND EXPRESSLY AGREE THAT USE OF THE PORTAL IS AT YOUR OWN RISK. NEITHER EHRZIP, THE PROVIDER NOR THEIR RESPECTIVE AFFILIATES ARE RESPONSIBLE FOR ANY VIRUS OR DAMAGE TO YOUR COMPUTER, COMPUTER SOFTWARE, OR ANY LOSS OF DATA THAT MAY RESULT FROM USE OF THE PORTAL OR ACCESS TO ANY CONTENT OR SERVICES.
              </p>
              <p>
                IN NO EVENT SHALL EHRZIP, THE PROVIDER, OR ANY OF THEIR RESPECTIVE VENDORS, AFFILIATES, OFFICERS, MEMBERS, REPRESENTATIVES, SUPPLIERS, DIRECTORS, EMPLOYEES, CONSULTANTS, OWNERS, OR AGENTS BE LIABLE FOR ANY DAMAGES FOR LOSS OF USE, DATA, INFORMATION, PROFITS, BUSINESS, REVENUE, EXPECTED SAVINGS OR BUSINESS INTERRUPTION, OR ANY DIRECT, INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER ARISING OUT OF OR IN ANY WAY RELATED TO (I) THE PORTAL, CONTENT OR SERVICES, (II) THE USE OR PERFORMANCE OF THIS PORTAL OR ANY LINKED WEBSITE OR ANY CONTENT, SERVICES OR OTHER MATERIAL, SERVICES OR INFORMATION OBTAINED THROUGH THIS PORTAL, OR (III) OTHERWISE ARISING OUT OF THE PORTAL, CONTENT, OR SERVICES OR THE USE OR THE INABILITY TO USE THIS PORTAL, CONTENT OR SERVICES, WHETHER SUCH DAMAGES ARE BASED IN TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF EHRZIP, THE PROVIDER, OR ANY OF THEIR RESPECTIVE VENDORS OR AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES ARE OTHERWISE FORESEEABLE. YOUR SOLE AND EXCLUSIVE REMEDY IN THE EVENT OF SUCH DAMAGE IS TO DISCONTINUE YOUR ACCESS TO AND USE OF THE PORTAL, CONTENT AND SERVICES. NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE AGGREGATE LIABILITY OF EHRZIP AND ITS AFFILIATES FOR ANY REASON EXCEED $1,000.00 US DOLLARS, REGARDLESS OF WHETHER THE FOREGOING REMEDIES SHOULD FAIL OF THEIR ESSENTIAL PURPOSE.
              </p>
              <p>
                THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU TO THE EXTENT SUCH LIMITATION IS PROHIBITED BY APPLICABLE LAW.
              </p>
              <p style={{ fontWeight: 'bold' }}> 11. No Warranty</p>
              <p>
                <strong>THE PORTAL, CONTENT AND SERVICES ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS. EHRZIP, THE PROVIDER, AND THEIR RESPECTIVE AFFILIATES DISCLAIM ALL OTHER WARRANTIES WITH RESPECT TO THE PORTAL, CONTENT AND SERVICES, INCLUDING WITHOUT LIMITATION ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING FROM A COURSE OF DEALING OR USAGE IN TRADE.  EHRZIP AND THEIR RESPECTIVE AFFILIATES MAKE NO WARRANTY OR REPRESENTATION REGARDING (A) THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE PORTAL, CONTENT OR SERVICES, (B) ANY MATERIAL, INFORMATION OR DATA DOWNLOADED OR OTHERWISE OBTAINED FROM THE PORTAL OR (C) THE ACCURACY OR RELIABILITY OF ANY INFORMATION OBTAINED FROM THE PORTAL, CONTENT, OR SERVICES.</strong> Neither EHRZip, the Provider, nor their respective affiliates shall have any responsibility for the timeliness, deletion, mis-delivery or failure to store any user communication.
                EHRZip provides the Portal, Content and Services to you through the Internet, which is subject to outages, communication and data flow failures, interruptions and delays inherent in Internet communications. You recognize that problems with the Internet, including equipment, software and network failures, impairments or congestion or the configuration of your computer systems, may prevent, interrupt or delay your access to the Portal. None of EHRZip, the Provider, or their respective vendors or affiliates shall be liable for any delays, interruptions, suspensions or unavailability of the Portal, or any portion of the Portal, attributable to problems with the Internet or configuration of your computer systems. There is no warranty or guarantee that access or use of the Portal, Content or Services will be uninterrupted or error-free.
              </p>
              <p>
                No advice or information, whether oral or written, obtained by you from EHRZip, the Provider or their respective affiliates or vendors in any manner will create any warranty.
              </p>
              <p style={{ fontWeight: 'bold' }}> 12. Your Representations and Warranties</p>
              <p>
                By clicking "I Agree" below, you represent and warrant that (i) you are at least eighteen (18) years of age, (ii) you have the legal ability and authority to enter into this Agreement with EHRZip, (iii) the information you have provided to EHRZip in your registration application is accurate and complete (iv) you will comply with any and all laws applicable to your use of the Portal, (v) you will not interfere with a third party's use and enjoyment of the Portal, and (vi) you will not attempt to interfere with or disrupt EHRZip's security measures. If any information you provide to EHRZip becomes inaccurate, incomplete or otherwise false or misleading, you will immediately notify EHRZip.
              </p>
              <p>
                You also represent, warrant and covenant that your use of the Portal will be consistent with any and all applicable local, state, national, foreign and international laws and regulations, including, but not limited to any applicable export control laws. You will not engage in advertising to or solicitation of any other users of the Portal, nor will you transmit any spam, chain letters or other similar communications to other users via the Portal. You will promptly report any violation of this Agreement to EHRZip.
              </p>
              <p style={{ fontWeight: 'bold' }}> 13. Indemnification</p>
              <p>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS EHRZIP, THE PROVIDER AND THEIR RESPECTIVE VENDORS, AFFILIATES, AND THEIR OFFICERS, MEMBERS, REPRESENTATIVES, DIRECTORS, EMPLOYEES, CONSULTANTS, OWNERS, SUPPLIERS AND AGENTS FROM ANY AND ALL LOSSES, EXPENSES, THIRD-PARTY CLAIMS, LIABILITIES, DAMAGES AND COSTS (INCLUDING WITHOUT LIMITATION ATTORNEYS’ FEES) ARISING FROM YOUR USE OR MISUSE OF THE PORTAL, CONTENT OR SERVICES, YOUR USE OR MISUSE OF ANY MATERIAL, INFORMATION OR DATA DOWNLOADED OR OTHERWISE OBTAINED FROM THE PORTAL, ANY PORTAL ACTIVITY INVOLVING YOUR USER ID, YOUR VIOLATION OF THIS AGREEMENT, OR YOUR VIOLATION OF ANY APPLICABLE LAW, INCLUDING WITHOUT LIMITATION ANY PRIVACY OR DATA SECURITY LAW OR REGULATION. NOTWITHSTANDING THE FOREGOING, EHRZIP, THE PROVIDER AND THEIR RESPECTIVE VENDORS RESERVE THE RIGHT TO PARTICIPATE IN THE DEFENSE OF ANY SUCH CLAIM SUBJECT TO YOUR INDEMNIFICATION OBLIGATION.</p>
              <p style={{ fontWeight: 'bold' }}> 14. Interpretation and Disputes</p>
              <p>This Agreement shall be governed by and construed in accordance with the laws of the State of California and the federal laws of the United States, without regard to conflicts of laws principles. This Agreement and access to the Portal shall be deemed to have been performed and occurred in the State of California and the courts of that State shall have exclusive jurisdiction to entertain any action arising under this Agreement. You hereby irrevocably submit to the exclusive jurisdiction and venue of the state and federal courts located in or with jurisdiction over the State of California, and the County of Sacramento, as applicable, and waive any objections as to personal jurisdiction, venue and forum non conveniens.</p>
              <p>In the event of any dispute, the prevailing party shall be entitled to recovery of its reasonable attorneys’ fees and costs. If a court or arbitrator deems any provision of this Agreement unenforceable or invalid, then the court or arbitrator shall modify such provision to the minimum extent necessary to make such provision enforceable and valid. Should such modification prove impossible or impracticable then the provision shall be severed and the remaining terms of this Agreement shall be interpreted and read to give them maximum enforceability. Except as prohibited by applicable law, any cause of action or claim with respect to the Content or use of the Services, must be commenced within one (1) year after the action or claim arises.  Certain provisions, by their nature or as explicitly stated, will survive any termination or expiration of this Agreement.</p>
              <p style={{ fontWeight: 'bold' }}> 15. Contacting Us</p>
              <p>
                If you have any other questions or concerns regarding this Agreement, please contact us at:
              </p>
              <p>
                EHRZip
                <br />Attention:
                <br />Customer Support
                <br />6510 Lonetree Blvd., Ste 300
                <br />Rocklin, CA 95765
                <br />Main 916-672-6622
                <br />Fax: 916-872-1998
              </p>
              <p style={{ fontWeight: 'bold' }}> 16. Entire Agreement</p>
              <p>
                By clicking "I Agree", you hereby agree to the above Agreement. This Agreement supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding this subject matter. This Agreement will inure to the benefit of EHRZip's successors, assigns and licensees. It is recommended that you print a copy of this Agreement for your records.
              </p>
              <p style={{ fontWeight: 'bold' }}>
                BY CLICKING “I AGREE,” YOU CONFIRM YOU ARE IN AGREEMENT WITH THESE TERMS AND WILL BE BOUND BY THEM.
                IF YOU DO NOT AGREE, YOU SHOULD CLICK “I DO NOT AGREE” AND YOU WILL NOT BE PERMITTED TO ACCESS THE PORTAL.
              </p>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </span>
  );
};

Agree.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(Agree));
