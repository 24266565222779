import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import MessageBox from 'util/messageBox';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton
} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';
import { connect } from 'react-redux';
import { deleteSoapMedication } from 'services/soap/action';

const MyTemplates = props => {
  const { classes, className, patientId, ...rest } = props;

  const [retainRx, setRetainRx] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [newmedication, setNewMedication] = useState([]);
  const [medicationComment, setMedicationComment] = useState('');
  const [errmsg, setErrMsg] = useState({ err: false, errMsg: '' });
  const [isEdit, setIsEdit] = useState(false);
  const [editIdx, setEditIdx] = useState(null);
  const [isNewRx, setIsNewRx] = useState(props.isNewRx);
  useEffect(() => {
    if (props.prescriptions && !isNewRx) {
      setPrescriptions(props.prescriptions);
    } else if (
      props.prescriptions &&
      props.prescriptions.length > 0 &&
      isNewRx
    ) {
      setRetainRx(props.prescriptions);
    }
  }, [props.prescriptions]);

  useEffect(() => {
    if (props.medicationComment && props.medicationComment.length > 0) {
      setMedicationComment(props.medicationComment);
    }
  }, [props.medicationComment]);

  useEffect(() => {
    if (props.copyprescription) {
      setNewMedication(props.copyprescription);
    }
  }, [props.copyprescription]);

  const handleFieldChange = async (field, value) => {
    if (field === 'medicationComment') {
      setMedicationComment(value);
      await props.handleMedicationComment(value);
    } else {
      const newState = { ...newmedication };
      newState[field] = value;
      setNewMedication(newState);
    }
  };

  const handleClose = () => {
    setErrMsg({ err: false });
  };

  const showErrorMsg = msg => {
    setErrMsg({ err: true, errMsg: msg });
  };
  const renderError = () => {
    return (
      <MessageBox
        message={errmsg.errMsg}
        onClose={handleClose}
        open
        variant="error"
      />
    );
  };

  const addRxControl = () => {
    if (isNewRx) {
      let retainPrescription = [...retainRx];
      const newPrescriptions = [...prescriptions];
      if (newmedication.name) {
        // check whether the medication already exists
        let medicineName = newPrescriptions.filter(
          c => c.name.toLowerCase() === newmedication.name.toLowerCase()
        );
        // if updating an existing medication, find the medication from edit index and update the entry
        if (isEdit) {
          setIsEdit(false);
          newPrescriptions[editIdx] = {
            ...newPrescriptions[editIdx],
            name: newmedication.name,
            dose: newmedication.dose,
            directions: newmedication.directions,
            quantity: newmedication.quantity,
            refill: newmedication.refill
          };
          retainPrescription[editIdx] = {
            ...retainPrescription[editIdx],
            name: newmedication.name,
            dose: newmedication.dose,
            directions: newmedication.directions,
            quantity: newmedication.quantity,
            refill: newmedication.refill
          };
        } else {
          // check whether the medication dose and name already exist
          let medicineDose =
            medicineName && newmedication.dose
              ? newPrescriptions.filter(
                  c =>
                    c.dose &&
                    c.dose.toLowerCase() === newmedication.dose.toLowerCase() &&
                    c.name.toLowerCase() === newmedication.name.toLowerCase()
                )
              : newPrescriptions.filter(
                  c =>
                    c.name.toLowerCase() === newmedication.name.toLowerCase() &&
                    c.dose === newmedication.dose
                );
          // get the index of medication, if dose & name exists
          let medicineIndx = newmedication.dose
            ? newPrescriptions.findIndex(
                c =>
                  c.dose &&
                  c.dose.toLowerCase() === newmedication.dose.toLowerCase() &&
                  c.name.toLowerCase() === newmedication.name.toLowerCase()
              )
            : newPrescriptions.findIndex(c => c.dose === newmedication.dose);

          // if medication exists, update the remaining entries else, add new
          if (medicineName.length !== 0 && medicineDose.length !== 0) {
            newPrescriptions[medicineIndx] = {
              ...newPrescriptions[medicineIndx],
              dose: newmedication.dose,
              directions: newmedication.directions,
              quantity: newmedication.quantity,
              refill: newmedication.refill
            };
            retainPrescription[medicineIndx] = {
              ...retainPrescription[medicineIndx],
              dose: newmedication.dose,
              directions: newmedication.directions,
              quantity: newmedication.quantity,
              refill: newmedication.refill
            };
          } else {
            newPrescriptions.push(newmedication);
            retainPrescription.push(newmedication);
          }
        }

        setPrescriptions(newPrescriptions);
        setNewMedication({});
        props.handleValuetoPrint(newPrescriptions);
        props.handlePrescription(retainPrescription);
      }
    } else {
      let retainPrescription = [...retainRx];
      const newPrescriptions = [...prescriptions];
      if (newmedication.name) {
        let medicineName = newPrescriptions.filter(
          c => c.name.toLowerCase() === newmedication.name.toLowerCase()
        );
        if (isEdit) {
          setIsEdit(false);
          newPrescriptions[editIdx] = {
            ...newPrescriptions[editIdx],
            name: newmedication.name,
            dose: newmedication.dose,
            directions: newmedication.directions,
            quantity: newmedication.quantity,
            refill: newmedication.refill
          };
          retainPrescription[editIdx] = {
            ...retainPrescription[editIdx],
            name: newmedication.name,
            dose: newmedication.dose,
            directions: newmedication.directions,
            quantity: newmedication.quantity,
            refill: newmedication.refill
          };
        } else {
          let medicineDose =
            medicineName && newmedication.dose
              ? newPrescriptions.filter(
                  c =>
                    c.dose &&
                    c.dose.toLowerCase() === newmedication.dose.toLowerCase() &&
                    c.name.toLowerCase() === newmedication.name.toLowerCase()
                )
              : newPrescriptions.filter(
                  c =>
                    c.name.toLowerCase() === newmedication.name.toLowerCase() &&
                    c.dose === newmedication.dose
                );

          let medicineIndx = newmedication.dose
            ? newPrescriptions.findIndex(
                c =>
                  c.dose &&
                  c.dose.toLowerCase() === newmedication.dose.toLowerCase() &&
                  c.name.toLowerCase() === newmedication.name.toLowerCase()
              )
            : newPrescriptions.findIndex(c => c.dose === newmedication.dose);

          if (medicineName.length !== 0 && medicineDose.length !== 0) {
            newPrescriptions[medicineIndx] = {
              ...newPrescriptions[medicineIndx],
              dose: newmedication.dose,
              directions: newmedication.directions,
              quantity: newmedication.quantity,
              refill: newmedication.refill
            };
            retainPrescription[medicineIndx] = {
              ...retainPrescription[medicineIndx],
              dose: newmedication.dose,
              directions: newmedication.directions,
              quantity: newmedication.quantity,
              refill: newmedication.refill
            };
          } else {
            newPrescriptions.push(newmedication);
            retainPrescription.push(newmedication);
          }
        }
        setPrescriptions(newPrescriptions);
        setNewMedication({});
        props.handleValuetoPrint(newPrescriptions);
        props.handlePrescription(newPrescriptions);
      }
    }
  };

  const editRxControl = idx => {
    let newPrescriptions = [...prescriptions];

    setNewMedication(newPrescriptions[idx]);
    setIsEdit(true);
    setEditIdx(idx);
  };

  const clearRxControl = () => {
    setNewMedication({});
  };

  const removeRxControl = async idx => {
    let newPrescriptions = [...prescriptions];
    let res;
    if (newPrescriptions[idx] && newPrescriptions[idx].id) {
      res = await props.deleteSoapMedication(
        patientId,
        newPrescriptions[idx].id
      );
      if (res) {
        newPrescriptions.splice(idx, 1);
        setPrescriptions(newPrescriptions);
        setRetainRx(newPrescriptions);
      }
    } else {
      newPrescriptions.splice(idx, 1);
      setPrescriptions(newPrescriptions);
      setRetainRx(newPrescriptions);
    }
    props.handleValuetoPrint(newPrescriptions);
    props.handlePrescription(newPrescriptions);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>Medications</Typography>
      </div>

      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell width="25%">
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('name', event.target.value)
                      }
                      placeholder="Medication Name"
                      value={newmedication.name || ''}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('dose', event.target.value)
                      }
                      placeholder="Dosage"
                      value={newmedication.dose || ''}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell width="25%">
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('directions', event.target.value)
                      }
                      placeholder="Directions"
                      value={newmedication.directions || ''}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('quantity', event.target.value)
                      }
                      placeholder="Quantity"
                      value={newmedication.quantity || ''}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('refill', event.target.value)
                      }
                      placeholder="Refill"
                      value={newmedication.refill || ''}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton color="primary" size="small">
                      <CheckOutlined
                        onClick={() => {
                          newmedication.name
                            ? addRxControl()
                            : showErrorMsg('Please enter a medication name');
                          return;
                        }}
                      />
                    </IconButton>
                    <IconButton size="small">
                      <ClearOutlined
                        onClick={() => clearRxControl()}
                        style={{ color: '#DE5555' }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prescriptions &&
                  prescriptions.length > 0 &&
                  prescriptions.map((item, idx) => {
                    return (
                      <TableRow>
                        <TableCell>{item.name ? item.name : ''}</TableCell>
                        <TableCell>{item.dose ? item.dose : ''}</TableCell>
                        <TableCell>
                          {item.directions ? item.directions : ''}
                        </TableCell>
                        <TableCell>
                          {item.quantity ? item.quantity : ''}
                        </TableCell>
                        <TableCell>{item.refill ? item.refill : ''}</TableCell>
                        <TableCell align="center" width={72}>
                          <IconButton size="small">
                            <Edit
                              color="primary"
                              onClick={() => {
                                editRxControl(idx);
                              }}
                            />
                          </IconButton>
                          <IconButton size="small">
                            <DeleteOutline
                              onClick={() => {
                                removeRxControl(idx);
                              }}
                              style={{ color: '#DE5555' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                <TableRow>
                  <TableCell colspan={6}>
                    <div style={{ padding: '5px 0', fontWeight: 600 }}>
                      Comments:
                    </div>
                    <TextField
                      multiline
                      onChange={event =>
                        handleFieldChange(
                          'medicationComment',
                          event.target.value
                        )
                      }
                      placeholder="Add comments if any"
                      rows="3"
                      style={{ width: '100%' }}
                      value={medicationComment || ''}
                      variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        {errmsg.err && renderError()}
      </div>
    </div>
  );
};

MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { deleteSoapMedication };

const mapStateToProps = () => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
