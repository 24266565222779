export default {
  routePhysician: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  appointmentDate: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  timeFrom: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  duration: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  reasonForAppointment: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  // emailaddress: {
  //   presence: { allowEmpty: false, message: '^Email is required' },
  //   email: { required: true, message: '^Email is not valid' }
  // }
};
