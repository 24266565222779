/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DocViewer } from 'views/Common/components';
import styles from '../../../style';
import { DataHx } from './';
import { computeAge } from 'util/helper';
import MessageBox from 'util/messageBox';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton,
  Popover,
  Modal,
  TableCell,
  Tooltip,
  Box
} from '@material-ui/core';
import {
  GridOnOutlined,
  ChevronRightOutlined,
  ChevronLeftOutlined,
  CloseOutlined
} from '@material-ui/icons';
import { getLocations } from 'services/util/action';
import { connect } from 'react-redux';
import {
  getCaseFiles,
  getPatientDocuments,
  getDocInfo,
  getRecaps, 
  getSoapDetailsById,
  getChartViewSoapandPatientDetails
} from 'services/home/action';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import { EncounterSUmmary } from '../../Soap/components';
import { withRouter } from 'react-router-dom';
import { resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks  } from 'services/soap/action'; 
import Loader from '../../../../Common/components/Loader'

const Details = props => {
  const {
    classes,
    className,
    locations,
    ehrID,
    profile,
    casefiles,
    documentInfo,
    userID,
    patientInfo, 
    recapSoapDetails,
    resetValue, clearSoap,  
    getPatientActiveMedications, 
    getPatientInactiveDiagnosis, 
    getPatientInactiveMedication,
    getPatientMedicalHistory,
    getPatientPastDiagnosis,
    getPatientRemarks,
    ...rest
  } = props;
  const [locs, setLocs] = useState([]);
  const [data, setData] = useState([]);
  const [dataHx, setDataHX] = useState([]);
  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtervalues, setFilterValues] = useState(
    localStorage.getItem('filterHomeLocation')
      ? localStorage.getItem('filterHomeLocation')
      : -1
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [doc,setDoc]= useState([]);
  const [state, setState] = useState({ page: 0, count: 0 });
  const [order, setOrder] = useState(true);
  const [orderdate, setOrderDate] = useState(true);
  const [openDoc, setOpenDoc] = useState(false);
  const [openViewSoap, setOpenViewSoap] = useState(false);

  useEffect(() => {
    props.getLocations(ehrID);
    props.getCaseFiles(ehrID, filtervalues, state.page);
  }, []);

  useEffect(() => {
    if (locations != undefined) {
      let locationtopush = [];
      locationtopush.push({ key: 'All Locations', value: '' });
      locations.forEach(function(item) {
        locationtopush.push({
          value: item.id,
          key:
            (item.companyname !== null ? item.companyname : '') +
            ',' +
            (item.address && item.address.address ? item.address.address : '')
        });
      });
      setLocs(locationtopush);
    }
  }, [locations]);

  useEffect(() => {
    if (casefiles !== undefined) {
      setState({ ...state, count: casefiles.totalElements });
      let datatopush = [];
      casefiles &&
        casefiles.result.forEach(function(item) {
          datatopush.push({
            patientName:
              (item.patient.lastname ? item.patient.lastname : '') +
              ', ' +
              (item.patient.firstname ? item.patient.firstname : '') +
              ' ' +
              (item.patient.middleinitial ? item.patient.middleinitial : ''),
            patientId: item.patient.id,
            dob: item.patient.dob
              ? moment(item.patient.dob.split('T')[0]).format('MM/DD/YYYY') +
                ' (' +
                computeAge(item.patient.dob) +
                ' yrs)'
              : '',
            gender: item.patient.gender,
            updatedDate: moment(item.updatedDate).format('MM/DD/YYYY'),
            page: item.page.content,
            patient: item
          });
        });
      setData(datatopush);
      setLoading(false);
    }
  }, [casefiles]);

  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  const handleOpenDoc =  async (patientId, resourceID, routeTo, patient) => { 
    if ((dataHx.page.content.resourceID = resourceID)) { 
      const res = await props.getPatientDocuments(
        ehrID,
        patientId,
        resourceID,
        routeTo,
        patient
      ); 
      const doc = await props.getDocInfo(patientId,resourceID)
      if(doc)
      {
        setDoc(doc)
        setOpenDoc(true); 
      }
    getPatientActiveMedications(patientId) 
    getPatientInactiveDiagnosis(patientId) 
    getPatientInactiveMedication(patientId)
    getPatientMedicalHistory(patientId)
    getPatientPastDiagnosis(patientId)
    getPatientRemarks(patientId)   
    } else {
      console.log(' not existing');
    }
  };

  const location = useLocation();
  const history = useHistory();
  const replaceHistory = useCallback(() => {
    history.replace({ ...location, state: undefined });
  }, [history]);

  const handleOpenSOAP = patientId => {
    props.clearSoap();
    props.resetValue();
    props.changeTab(patientId, 1);
    replaceHistory();
  };

  const handleCloseDoc = () => {
    setOpenDoc(false);
    setDataDocViewer({});
  };

  const handleDeleteDoc = () => { 
    setOpenDoc(false);  
    setDataDocViewer([])
  }; 

  const handleOpenViewSoap = async (patientId, resourceID) => {
    const res = await props.getChartViewSoapandPatientDetails(
      ehrID,
      patientId,
      userID,
      resourceID
    );
    if (res.status) {
      setOpenViewSoap({
        open: true,
        patientId: patientId,
        soap: res.chartSoapPatientDetails.soap,
        patientInfo: res.chartSoapPatientDetails.patientInfo
      }); 
      getPatientActiveMedications(patientId) 
      getPatientInactiveDiagnosis(patientId) 
      getPatientInactiveMedication(patientId)
      getPatientMedicalHistory(patientId)
      getPatientPastDiagnosis(patientId)
      getPatientRemarks(patientId) 
    }
  };

  const handleCloseViewSoap = () => {
    props.clearSoap();
    setOpenViewSoap({ open: false });
  };

  const handleClick = (event, patId) => {

    setAnchorEl(event.currentTarget);
    const dtHx = data.filter(c => c.patientId === patId);
    setDataHX(dtHx[0].patient);
  };

  const handleDataHxChangePage = async (casefileId, page) => {
    const res = await props.getRecaps(ehrID, casefileId, page);
    if (res) {
      let newstate = { ...dataHx };
      newstate.page = res;
      setDataHX(newstate);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSort = async sort => {
    setLoading(true);
    if (sort === 'patient.lastname') {
      setOrder(!order);
      await props.getCaseFiles(
        ehrID,
        filtervalues,
        state.page,
        order ? 'DESC' : 'ASC',
        sort
      );
    } else {
      setOrderDate(!orderdate);
      await props.getCaseFiles(
        ehrID,
        filtervalues,
        state.page,
        orderdate ? 'ASC' : 'DESC',
        sort
      );
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45, 
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientName',
      label: 'Patient Name',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '40%' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customHeadRender: ({ index, ...column }) => {
          return (
            <Tooltip style={{ cursor: 'pointer' }} title="Sort">
              <TableCell
                key={index}
                onClick={() => handleChangeSort('patient.lastname')}>
                {column.label}
              </TableCell>
            </Tooltip>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handleOpenSOAP(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'dob',
      label: 'Date of Birth',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handleOpenSOAP(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'gender',
      label: 'Sex',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handleOpenSOAP(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'updatedDate',
      label: 'Date',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customHeadRender: ({ index, ...column }) => {
          return (
            <Tooltip style={{ cursor: 'pointer' }} title="Sort">
              <TableCell
                key={index}
                onClick={() => handleChangeSort('updatedDate')}>
                {column.label}
              </TableCell>
            </Tooltip>
          );
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handleOpenSOAP(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'patientId',
      label: 'Recap',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: value => {
          return (
            // <Tooltip title="Delete">
            <IconButton
              className={classes.tableIcon}
              color="secondary"
              component="span"
              onClick={e => handleClick(e, value)}
              size="small">
              <GridOnOutlined />
            </IconButton>
            // </Tooltip>
          );
        }
      }
    }
  ];

  const handleChangePage = async page => {
    setLoading(true);
    setState({ ...state, page: page });
    await props.getCaseFiles(ehrID, filtervalues, page);
  };


  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: state.count > 10,
    selectableRows: false,
    serverSide: true,
    count: state.count,
    page: state.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
      }
    }
    // onRowClick: rowData => handleOpenSOAP(rowData[0])
  };

  const handleDrpDownChange = (field, value) => {
    setLoading(true);
    const newState = { ...filtervalues };
    let location;
    if (field === 'location') {
      localStorage.setItem('filterHomeLocation', value);
      location = value;
    } else {
      location = newState.location;
    }
    setFilterValues(value);
    props.getCaseFiles(ehrID, location, state.page);
  };

  const handleOpenSoap = async patientId => { 
    props.clearSoap();
    props.resetValue();
    await props.history.push({
      pathname: '/default',
      state: { patientId: patientId }
    });
    // setTimeout(() => {
    //   props.resetValue(); 
    // }, 10000);
  };

  const handleCopyfromRecap = (soap, pastSoapNoteDetails) => {
    props.clearSoap();
    props.resetValue();
    props.history.push({
      pathname: '/default',
      state: {
        soap: soap,
        pastSoapNoteDetails: pastSoapNoteDetails,
        isAction: 'Copy'
      }
    });
  };

  const handleEditfromRecap = (soap, pastSoapNoteDetails) => {
    props.clearSoap();
    props.resetValue();
    props.history.push({
      pathname: '/default',
      state: {
        soap: soap,
        pastSoapNoteDetails: pastSoapNoteDetails,
        isAction: 'Edit'
      }
    });
  };

  //#region success
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });

  const showSuccessMsg = msg => {
    setSuccMsg({ succ: true, succMsg: msg });
  };

  const renderSuccess = () => {
    return (
      <MessageBox
        message={succmsg.succMsg}
        onClose={handleCloseSuccess}
        open={succmsg.succ}
        variant="success"
      />
    );
  };

  const handleCloseSuccess = () => {
    setSuccMsg({ succ: false });
  };

  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      <Grid container>
        <Grid className={classes.cardHeader} item xs={8}>
          <Typography variant="h2">
            {(profile && profile.firstname ? profile.firstname : '') +
              ' ' +
              (profile && profile.lastname ? profile.lastname : ' ') +
              ' ' +
              (profile && profile.title ? profile.title : '')}
          </Typography>
        </Grid>
        <Grid
          className={classes.cardHeader}
          item
          style={{ justifyContent: 'flex-end' }}
          xs={4}>
          <TextField
            margin="dense"
            name="title"
            onChange={event =>
              handleDrpDownChange('location', event.target.value)
            }
            select
            SelectProps={{ native: true }}
            style={{ margin: 0, minWidth: 175 }}
            value={filtervalues || ''}
            variant="outlined">
            {locs &&
              locs.map(item => <option value={item.value}>{item.key}</option>)}
          </TextField>
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        {loading ? (
            <Loader />
          // <div
          //   className={classes.progressWrapper}
          //   style={{
          //     position: 'absolute',
          //     left: 0,
          //     right: 0,
          //     display: 'flex',
          //     bottom: 0,
          //     top: 0,
          //     alignItems: 'flex-start',
          //     paddingTop: 120,
          //     justifyContent: 'center',
          //     backgroundColor: 'rgba(0, 0, 0,.2)',
          //     zIndex: 1000
          //   }}>
          //   <CircularProgress style={{ height: 30, width: 30 }} />
          // </div>
        ) : (
          <MuiThemeProvider theme={theme}>
            <div className={classes.fullWidthTable}> 
                <MUIDataTable
                  columns={columns}
                  data={data}
                  options={options}
                  responsive="scrollMaxHeight"
                /> 
            </div>
          </MuiThemeProvider>
        )}
      </CardContent>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        elevation={4}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <PerfectScrollbar>
          <DataHx
            dataHx={dataHx}
            handleOpenDoc={handleOpenDoc}
            handleOpenViewSoap={handleOpenViewSoap}
            isHome
            handleOpenSoap={handleOpenSoap}
          />
        </PerfectScrollbar>
        <div className={classes.popFoot}>
          <Typography variant="caption">
            The patient has {dataHx && dataHx.page && dataHx.page.totalElements}{' '}
            recap events
          </Typography>
          {dataHx && dataHx.page && dataHx.page.totalPages > 1 && (
            <span>
              <IconButton
                disabled={dataHx.page.first === true}
                onClick={() =>
                  handleDataHxChangePage(dataHx.id, dataHx.page.number - 1)
                }
                size="small">
                <ChevronLeftOutlined />
              </IconButton>
              <IconButton
                disabled={dataHx.page.last === true}
                onClick={() =>
                  handleDataHxChangePage(dataHx.id, dataHx.page.number + 1)
                }
                size="small">
                <ChevronRightOutlined />
              </IconButton>
            </span>
          )}
        </div>
      </Popover>
      {succmsg.succ && renderSuccess()}
      <Modal onClose={handleCloseDoc} open={openDoc}>
      <DocViewer dataDocViewer={dataDocViewer} onCancel={handleCloseDoc} doc={doc} handleDeleteDoc={handleDeleteDoc}/>
      </Modal>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseViewSoap}
        open={openViewSoap.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="h2">
              {openViewSoap && openViewSoap.soap && openViewSoap.soap.visitdate
                ? 'Encounter Date :' +
                  moment(openViewSoap.soap.visitdate).format('MM/DD/YYYY')
                : ''}
            </Typography>
            <span>
              <IconButton aria-label="delete" onClick={handleCloseViewSoap}>
                <CloseOutlined />
              </IconButton>
            </span>
          </Box>
          <EncounterSUmmary
            handleCopyPast={handleCopyfromRecap}
            handleEditPast={handleEditfromRecap}
            // isHistory={isHistory}
            onCancel={handleCloseViewSoap}
            patientInfo={openViewSoap && openViewSoap.patientInfo}
            // setHistory={setHistory}
            showSuccessMsg={showSuccessMsg}
            soap={openViewSoap && openViewSoap.soap}
          />
        </Box>
      </Modal>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getLocations,
  getCaseFiles,
  getPatientDocuments,
  getDocInfo,
  getRecaps,
  getSoapDetailsById, 
  getChartViewSoapandPatientDetails,
  resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis, 
  getPatientRemarks
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  profile: state && state.profile,
  casefiles: state.home && state.home.list,
  documentInfo: state.home && state.home.documentInfo,
  recapSoapDetails: state.home && state.home.recapSoapDetails
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(withStyles(styles)(Details))
);
