import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';

import { TextField, Box } from '@material-ui/core';
import { getLocations, getProviders } from 'services/util/action';
import { connect } from 'react-redux';

const PatientFilter = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      clearFilter
    };
  });
  
  const { locations, providers, ehrID, isptMessages } = props;
  const [locs, setLocs] = useState([]);
  const [pros, setPros] = useState([]);
  const [status, setStatus] = useState([]);

  const [filtervalues, setFilterValues] = useState({
    location: localStorage.getItem('filterPTMessageLocation')?localStorage.getItem('filterPTMessageLocation'):'',
    provider: '',
    status: localStorage.getItem('filterPTMessageStatus')?localStorage.getItem('filterPTMessageStatus'):''
  });

  const loadLocalStorage = async () => {
    if (isptMessages) {
      setFilterValues({
        location: localStorage.getItem('filterPTMessageLocation'),
        provider: localStorage.getItem('filterPTMessageProvider'),
        status: localStorage.getItem('filterPTMessageStatus')
      });
      // props.handleDrpDownChange(
      //   localStorage.getItem('filterPTMessageLocation'),
      //   localStorage.getItem('filterPTMessageProvider'),
      //   localStorage.getItem('filterPTMessageStatus')
      // );
    } else {
      setFilterValues({
        location: localStorage.getItem('filterPTIssueLocation'),
        provider: localStorage.getItem('filterPTIssueProvider'),
        status: localStorage.getItem('filterPTIssueStatus')
      });
      // props.handleDrpDownChangeptIssue(
      //   localStorage.getItem('filterPTIssueLocation'),
      //   localStorage.getItem('filterPTIssueProvider'),
      //   localStorage.getItem('filterPTIssueStatus')
      // );
    }
  };

  const loadItems = async () => {
    await props.getLocations(ehrID);
    await props.getProviders(ehrID);
  };

  useEffect(() => {
    loadItems();
    let statustopush = [];
    let statuses;
    if (isptMessages) {
      statuses = ['Completed', 'No Response'];
    } else {
      statuses = ['Completed', 'No Response/Click to Complete'];
    }

    statustopush.push({ key: 'All Status', value: '' });
    for (var i = 0; i < statuses.length; i++) {
      statustopush.push({ key: statuses[i], value: i });
    }
    setStatus(statustopush);
  }, []);

  useEffect(() => {
    if(props.isLocalStorage)
   { loadLocalStorage();}
  }, [props.isLocalStorage]);

  useEffect(() => {
    if (locations != undefined) {
      let locationtopush = [];
      locationtopush.push({ key: 'All Locations', value: '' });
      locations.forEach(function(item) {
        locationtopush.push({
          value: item.id,
          key:
            (item.companyname !== null ? item.companyname : '') +
            ',' +
            (item.address && item.address.address ? item.address.address : '')
        });
      });
      setLocs(locationtopush);
      // locationStore();
    }
  }, [locations]);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providertopush.push({ key: 'All Providers', value: '' });
      providers.forEach(function(item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const clearFilter = () => {
    setFilterValues({});
    if (isptMessages) {
      localStorage.setItem('filterPTMessageLocation', '');
      localStorage.setItem('filterPTMessageProvider', '');
      localStorage.setItem('filterPTMessageStatus', '');
      props.handleDrpDownChange('', '', '');
    } else {
      localStorage.setItem('filterPTIssueLocation', '');
      localStorage.setItem('filterPTIssueProvider', '');
      localStorage.setItem('filterPTIssueStatus', '');
      props.handleDrpDownChangeptIssue('', '', '');
    }
  };

  const handleDrpDownChange = (field, value) => {
    const newState = { ...filtervalues };
    let location, provider, status;
    if (field === 'location') {
      if (isptMessages) {
        localStorage.setItem('filterPTMessageLocation', value);
      } else {
        localStorage.setItem('filterPTIssueLocation', value);
      }
      location = value;
    } else {
      location = newState.location;
    }
    if (field === 'provider') {
      if (isptMessages) {
        localStorage.setItem('filterPTMessageProvider', value);
      } else {
        localStorage.setItem('filterPTIssueProvider', value);
      }
      provider = value;
    } else {
      provider = newState.provider;
    }
    if (field === 'status') {
      if (isptMessages) {
        localStorage.setItem('filterPTMessageStatus', value);
      } else {
        localStorage.setItem('filterPTIssueStatus', value);
      }
      status = value;
    } else {
      status = newState.status;
    }
    setFilterValues({ ...filtervalues, [field]: value });
    if (isptMessages) {
      props.handleDrpDownChange(location, provider, status);
    } else {
      props.handleDrpDownChangeptIssue(location, provider, status);
    }
  };

  return (
    <Box style={{ 
      left: "10px",
      position: "absolute",
      top: "6px"
    }}>
      <TextField 
          inputProps={{
            style: { 
              fontSize:12,
            }
        }}
        size='small'
        name="location"
        onChange={event => handleDrpDownChange('location', event.target.value)}
        select
        SelectProps={{ native: true }}
        style={{ maxWidth: 200 }}
        value={filtervalues.location || ''}
        variant="outlined">
        {locs &&
          locs.map(item => <option value={item.value}>{item.key}</option>)}
      </TextField>
      &nbsp;
      {isptMessages ? (
        ''
      ) : (
        <TextField
        inputProps={{
          style: { 
            fontSize:12
          }
       }}
        size='small'
          name="provider"
          onChange={event =>
            handleDrpDownChange('provider', event.target.value)
          }
          select
          SelectProps={{ native: true }}
          value={filtervalues.provider || ''}
          variant="outlined">
          {pros &&
            pros.map(item => <option value={item.value}>{item.key}</option>)}
        </TextField>
      )}
      &nbsp;
      <TextField
       inputProps={{
        style: {
          // padding: "5px 10px 8px 10px",
          fontSize:12, 
        }
     }}
       size='small'
        name="status"
        onChange={event => handleDrpDownChange('status', event.target.value)}
        select
        SelectProps={{ native: true }}
        value={filtervalues.status || ''}
        variant="outlined">
        {status &&
          status.map(item => <option value={item.value}>{item.key}</option>)}
      </TextField>
    </Box>
  );
});

PatientFilter.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getLocations,
  getProviders
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  locations: state.util.locations,
  providers: state.util.providers
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(PatientFilter);
