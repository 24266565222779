import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import { withRouter } from 'react-router-dom';
import MyTemplates from './MyTemplates';
import TemplateText from './TemplateText';
import { Button, Grid, CircularProgress } from '@material-ui/core';
import {
  getObjectiveTemplates,
  getSubjectiveTemplates,
  getAssessmentPlanTemplates
} from 'services/providers/action';
import { connect } from 'react-redux';
import {
  AddSubjectiveTemplate,
  AddObjectiveTemplate,
  AddAssessmentPlanTemplate,
  UpdateAssessmentPlanTemplate,
  UpdateObjectiveTemplate,
  UpdateSubjectiveTemplate,
  DeleteSubjectiveTemplate,
  DeleteObjectiveTemplate,
  DeleteAssessmentPlanTemplate
} from 'services/providers/action';

const VSHx = props => {
  const {
    classes,
    className,
    type,
    ehrID,
    userID,
    subjectiveTemplates,
    objectiveTemplates,
    assessmentPlanTemplates,
    fromLabOrder,
    ...rest
  } = props;

  const [subjectiveTopics, setSubjectiveTopics] = useState(null);
  const [objectiveTopics, setObjectiveTopics] = useState(null);
  const [assessmentPlanTopics, setAssessmentPlanTopics] = useState(null);

  const [subjectiveTopic, setSubjectiveTopic] = useState(null);
  const [objectiveTopic, setObjectiveTopic] = useState(null);
  const [assessmentPlanTopic, setAssessmentPlanTopic] = useState(null);

  const [subText, setsubText] = useState([]);

  useEffect(() => {
    if (type === 1) {
      props.getSubjectiveTemplates(ehrID, userID).then(res => {
        if (res && res.data.length > 0) {
          setSubjectiveTopics(res.data);
        }
      });
    } else if (type === 2) {
      props.getObjectiveTemplates(ehrID, userID).then(res => {
        if (res && res.data.length > 0) {
          setObjectiveTopics(res.data);
        }
      });
    } else if (type === 3) {
      props.getAssessmentPlanTemplates(ehrID, userID).then(res => {
        if (res && res.data.length > 0) {
          setAssessmentPlanTopics(res.data);
        }
      });
    }
  }, [type]);

  const setTopicText = topicText => {
    if (type === 1) {
      setSubjectiveTopic(topicText);
    } else if (type === 2) {
      setObjectiveTopic(topicText);
    } else {
      setAssessmentPlanTopic(topicText);
    }
  };

  const saveSubText = selectedItems => {
    setsubText(selectedItems);
  };

  const handleSaveSubtext = () => {
    if (type === 1 && subText && subText.topicText) {
      props.UpdateSubjectiveTemplate(ehrID, userID, subText).then(res => {
        let text = subText.topicText
          .filter(c => c.checked === true)
          .map(function(elem) {
            return elem.text;
          })
          .join(' \n');

        props.handleFieldChange('subjective', text,true);
        props.onCancel();
      });
    } else if (type === 2 && subText && subText.topicText) {
      props.UpdateObjectiveTemplate(ehrID, userID, subText).then(res => {
        let text = subText.topicText
          .filter(c => c.checked === true)
          .map(function(elem) {
            return elem.text;
          })
          .join(' \n');
        props.handleFieldChange('objective', text,true);
        props.onCancel();
      });
    } else if (type === 3 && subText && subText.topicText) {
      props.UpdateAssessmentPlanTemplate(ehrID, userID, subText).then(res => {
        let text = subText.topicText
          .filter(c => c.checked === true)
          .map(function(elem) {
            return elem.text;
          })
          .join(' \n');
        if (fromLabOrder) {
          props.handleList(
            'labOrder',
            subText.topicText.filter(c => c.checked === true)
          );
        } else {
          props.handleFieldChange('assessmentplan', text,true);
        }

        props.onCancel();
      });
    }
    else{
      props.onCancel();
    }
  };

  return (
    (assessmentPlanTopics !== null ||
      objectiveTopics !== null ||
      subjectiveTopics !== null) && (
      <Grid
        container
        spacing={3}
      >
        {(assessmentPlanTopics !== null ||
          objectiveTopics !== null ||
          subjectiveTopics !== null) && (
          <Grid
            item
            md={6}
            xs={12}
          >
            <MyTemplates
              assessmentPlans={assessmentPlanTopics}
              objectives={objectiveTopics}
              setTopicText={setTopicText}
              subjectives={subjectiveTopics}
              type={type}
            />
          </Grid>
        )}
        {(assessmentPlanTopic !== null ||
          objectiveTopic !== null ||
          subjectiveTopic !== null) && (
          <Grid
            item
            md={6}
            xs={12}
          >
            <TemplateText
              assessmentPlanTopic={assessmentPlanTopic}
              objectiveTopic={objectiveTopic}
              saveSubText={saveSubText}
              subjectiveTopic={subjectiveTopic}
              type={type}
            />
          </Grid>
        )}
        <Grid
          className={classes.modalFooter}
          item
          xs={12}
        >
          <Button
            onClick={props.onCancel}
            size="small"
            style={{ marginRight: 10 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => {
              handleSaveSubtext();
            }}
            size="small"
            style={{ borderRadius: 4 }}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    )
  );
};

VSHx.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getSubjectiveTemplates,
  getObjectiveTemplates,
  getAssessmentPlanTemplates,
  AddSubjectiveTemplate,
  AddObjectiveTemplate,
  AddAssessmentPlanTemplate,
  UpdateAssessmentPlanTemplate,
  UpdateObjectiveTemplate,
  UpdateSubjectiveTemplate,
  DeleteSubjectiveTemplate,
  DeleteObjectiveTemplate,
  DeleteAssessmentPlanTemplate
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  subjectiveTemplates: state.providers && state.providers.subjectiveTemplates,
  objectiveTemplates: state.providers && state.providers.objectiveTemplates,
  assessmentPlanTemplates:
    state.providers && state.providers.assessmentPlanTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VSHx))
);
