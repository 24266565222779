export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  colWrap: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: '1 0 auto'
    }
  },
  tabWrap: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '0px 4px 4px 4px',
    background: '#fff',
    padding: 10
  },
  cardHeader: {
    padding: 20,
    alignItems: 'center',
    display: 'flex',
    '& h2': {
      fontWeight: 600,
      fontSize: 16
    },
    '& select': {
      fontSize: 12,
      padding: '7px 30px 7px 10px;',
      lineHeight: '12px'
    }
  },
  cardHeaderSoap: {
    backgroundColor: '#CFE8FF',
    '& h2': {
      width: '100%',
      textAlign: 'center'
    }
  },
  cardSubHeader: {
    padding: '8px 20px',
    alignItems: 'center',
    display: 'flex',
    background: 'rgba(199, 199, 199, 0.21)',
    borderTop: '1px solid #DADADA',
    borderBottom: '1px solid #DADADA',
    justifyContent: 'space-between',
    '& h3': {
      fontWeight: 600,
      fontSize: 14,
      color: '#5698D4'
    },
    '& svg': {
      height: '.8em',
      width: '.8em'
    },
    '& button': {
      marginLeft: '3px'
    }
  },
  cardHeaderHalf: {
    padding: '10px',
    '& h2': {
      fontWeight: 600,
      fontSize: 16,
      textAlign: 'center'
    }
  },
  subTabRoot: {
    width: '100%',
    overflow: 'visible'
  },
  subTab: {
    backgroundColor: '#EFF5FC',
    '& .MuiTab-root': {
      backgroundColor: 'transparent',
      padding: '5px',
      textTransform: 'capitalize',
      fontWeight: 600,
      color: '#606060',
      minWidth: 'auto',
      '&:hover': {
        color: '#000'
      }
    },
    '& .Mui-selected': {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      '&:hover': {
        color: '#1473cc!important'
      }
    }
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px 0',
    '& h2': {
      fontSize: 24,
      fontWeight: 300,
      color: theme.palette.primary.main
    },
    '& h4': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.palette.primary.main
    },
    '& h6': {
      marginTop: 20
    },
    '& Button': {
      margin: '0 5px',
      textTransform: 'capitalize'
    }
  },
  iconBig: {
    color: '#3397F3',
    width: 125,
    height: 125,
    margin: 20,
    padding: 20,
    borderRadius: '50%',
    backgroundColor: 'rgba(196, 196, 196, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fullWidthTable: {
    margin: -16
  },
  tableIcon: {
    color: '#D8A85A',
    padding: 4,
    '& svg': {
      width: 20,
      height: 20
    }
  },
  textBox: {
    width: '100%',
    '& input': {
      padding: '7px 10px;',
      '&::placeholder': {
        fontFamily: 'Lato',
        fontSize: 12,
        color: '#B9B9B9',
        fontWeight: 400
      }
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC'
    }
  },
  searchBtn: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    width: 40,
    backgroundColor: '#82c3ff',
    boxShadow: 'none!important',
    border: '1px solid #3397F3',
    padding: 5,
    '&:hover': {
      backgroundColor: '#51a6f5',
      boxShadow: 'none'
    }
  },
  plainTable: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '& td': {
      padding: '8px 10px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px',
      '& a': {
        color: '#3397f3'
      },
      '& a:hover': {
        textDecoration: 'underline',
        cursor: 'pointer'
      }
    },
    '& th': {
      padding: '8px 10px'
    },
    '& tr:hover': {
      backgroundColor: '#EFF5FC'
    }
  },
  allergyTable: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#fffae9'
      }
    }
  },
  medicTable: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#cee6f0'
      }
    }
  },
  pastDiagTable: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#ececec'
      }
    }
  },
  socialHTable: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#e7f0ce'
      }
    }
  },
  healthMaint: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#fbefef'
      }
    }
  },
  vaccination: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#cad8ed'
      }
    }
  },
  FamilyHistory: {
    '& tbody': {
      '& tr': {
        backgroundColor: '#e6fafb'
      }
    }
  },
  blueBg: {
    '& tr': {
      backgroundColor: '#EFF5FC',
      '&:hover': {
        backgroundColor: '#CFE8FF',
        cursor: 'pointer'
      }
    }
  },
  PatientDetailsWrap: {
    margin: -16
  },
  PatientDetailsHead: {
    padding: '10px 20px',
    background: '#eff5fc',
    justifyContent: 'space-between',
    display: 'flex',
    '& p': {
      marginRight: 40
    }
  },
  PatientDetailsHeadInner: {
    display: 'flex',
    alignItems: 'center'
  },
  accordionWrap: {
    boxShadow: 'none',
    border: '1px solid #ccc',
    borderWidth: '0px',
    '&.Mui-expanded': {
      margin: 0
    },
    '& $accordionHeader': {
      backgroundColor: '#5698D4',
      marginBottom: 1,
      alignItems: 'center',
      '& p': {
        fontWeight: 600,
        fontSize: 16,
        color: '#fff',
        width: '100%',
        textAlign: 'center',
        textTransform: 'uppercase'
      },
      '& svg': {
        color: '#fff',
        height: 18,
        width: 18
      },
      '&.Mui-expanded': {
        minHeight: 48,
        height: 48
      }
    },
    '& .MuiExpansionPanelDetails-root': {
      padding: 0
    },
    '& $accordionWrapInner': {
      '& .Mui-expanded': {
        margin: 0
      },
      '& td': {
        borderBottom: 'none',
        fontSize: 12
      },
      '& th': {
        backgroundColor: '#f9e9ce',
        borderBottomColor: '#f3dcb6',
        fontSize: 12
      },
      '& $accordionHeaderInner': {
        backgroundColor: '#f8f8f8',
        marginBottom: 1,
        borderBottom: '1px solid #e2e2e2',
        '& .MuiButtonBase-root': {
          marginRight: 10
        },
        '& p': {
          fontWeight: 600,
          fontSize: 14
        },
        '& svg': {
          //marginRight: 20
        },
        '&.Mui-expanded': {
          minHeight: 48,
          height: 48,
          '& svg': {
            //marginLeft: 20
          }
        }
      },
      flexDirection: 'column',
      width: '100%'
    }
  },
  accordionHeader: {},
  accordionHeaderInner: {},
  accordionWrapInner: {},
  inlineLink: {
    cursor: 'pointer',
    color: '#3397f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    justifyContent: 'space-between',
    display: 'flex',
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28,
      '& span': {
        fontSize: 16,
        paddingLeft: 20
      }
    }
  },
  modalBody: {
    padding: '10px 0'
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalFooter: {
    padding: '20px 0px 10px 0px',
    textAlign: 'right'
  },
  rightIconGroup: {
    margin: -6,
    '& button': {
      marginLeft: 10
    }
  },
  MHXwrap: {
    [theme.breakpoints.down('md')]: {
      //marginTop: 20,
      //marginLeft: 0
    },
    //maxHeight: 'calc(100vh - 200px)',
    marginTop: 0,
    marginLeft: 20,
    border: '1px solid transparent',
    '& $accordionWrap': {
      borderWidth: '0 1px'
    },
    '& $accordionHeader': {
      backgroundColor: '#fff',
      borderBottom: '1px solid #ccc',
      padding: 10,
      height: 40,
      minHeight: 40,
      marginBottom: 1
    },
    '& svg': {
      color: '#606060!important'
    },
    '&.Mui-expanded': {
      minHeight: 40,
      height: 40
    },
    '& p': {
      color: '#606060!important',
      textAlign: 'left!important',
      textTransform: 'capitalize!important'
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: 0
    },
    '&~.ps__rail-x': {
      display: 'none'
    }
  },
  MHXsoapWrap: {
    marginLeft: 0,
    '& $accordionWrap': {
      border: 'none'
    }
  },
  iconRed: {
    '& svg': {
      color: '#DE5555!important'
    }
  },
  iconGreen: {
    '& svg': {
      color: '#03A484!important'
    }
  },
  sectionHeader: {
    backgroundColor: '#eff5fc',
    border: '1px solid #eff5fc',
    padding: '13px 10px',
    color: '#606060',
    fontFamily: 'Lato',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderLeftColor: '#ccc',
    borderRightColor: '#ccc',
    borderBottom: '1px solid #eee',
    marginTop: -1,
    '& button': {
      textTransform: 'capitalize',
      padding: '0 5px',
      color: '#606060',
      '& .MuiButton-label': {
        textDecoration: 'underline'
      }
    }
  },
  backBtn: {
    backgroundColor: '#D0D0D0',
    fontWeight: 'bold',
    boxShadow: 'none',
    marginRight: -10,
    textTransform: 'capitalize'
  },
  routeDropdown: {
    width: 'auto',
    '& select': {
      padding: 13
    }
  },
  inlineTabWrap: {
    padding: 0,
    '& .MuiBox-root': {
      padding: 0
    }
  },
  popFoot: {
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  docViewerFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  summryText: {
    maxWidth: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  editBtn: {
    height: 23,
    width: 23,
    border: '2px solid',
    borderRadius: '50%',
    padding: 1,
    marginTop: -2,
    marginLeft: 5
  },
  editField: {
    minWidth: 250,
    marginLeft: 10,
    marginTop: -5
  },
  plainBtn: {
    padding: 0,
    textTransform: 'none'
  },
  soapLeftWrap: {
    boxShadow: 'none',
    height: '100%',
    borderRight: '1px solid rgb(236 236 236)',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0
  },
  soapEntryWrap: {
    borderRight: '1px solid #D4E5F9',
    marginBottom: -24,
    height: 'calc(100% + 24px)'
  },
  TextVitals: {
    width: '12.5%!important',
    '& input': {
      textAlign: 'center',
      fontSize: 12,
      padding: '12px 5px'
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(8px, 15px) scale(1)',
      fontSize: 12,
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
        transform: 'translate(4px, 15px) scale(1)'
      },
      '&.MuiInputLabel-shrink': {
        transform: 'translate(12px, -6px) scale(.8)',
        [theme.breakpoints.down('md')]: {
          transform: 'translate(6px, -6px) scale(.7)'
        }
      }
    }
  },
  highlight: {},
  soapViewWrap: {
    border: '1px solid #D4E5F9',
    '& $vitalBody': {
      '& $textField': {
        width: '12%!important',
        '& input': {
          textAlign: 'center',
          fontSize: 15
        },
       
      },
      '& $highlight': {
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#eff5fc'
        }
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main + '!important',
      fontWeight: 600
    }
  },

  soapEntryAction: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px!important',
    '& .MuiButton-containedSizeSmall': {
      padding: '4px 16px',
      borderRadius: 3,
      marginLeft: 5,
      [theme.breakpoints.down('md')]: {
        padding: 4,
        fontSize: 12,
        textTransform: 'capitalize',
        minWidth: 40
      }
    },
    '& .MuiFormControl-root': {
      maxWidth: '22%'
    },
    '& .MuiSelect-selectMenu': {
      padding: '8px 30px 8px 10px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 2,
      borderColor: '#03A484'
    }
  },
  soapEntryBtn: {},
  soapEntryItem: {
    padding: '15px 10px!important',
    borderBottom: '1px solid #D4E5F9',
    '& $soapEntryBtn': {
      display: 'flex',
      justifyContent: 'flex-end',
      '& button': {
        padding: 2,
        minWidth: 30,
        height: 24,
        borderRadius: 5,
        margin: '0 0 10px 0',
        '& svg': {
          width: 16,
          height: 16
        }
      }
    },
    '& $labelRoot': {
      color: '#ccc',
      backgroundColor: 'transparent'
    },
    '& $labelFocused': {
      backgroundColor: 'white',
      color: theme.palette.primary.main + '!important',
      padding: '2px 5px!important',
      height: 25,
      fontWeight: 600
    },
    '& $textField': {
      fontSize: 12,
      borderColor: '#ff0000',
      width: '100%',
      borderRadius: 1,
      '&::placeholder': {
        color: 'blue',
        fontSize: 10
      },
      '& + & ': {
        //marginTop: theme.spacing(2)
      },
      '&:focus $notchedOutline': {
        borderColor: 'green'
      },
      '&:hover $notchedOutline': {
        borderColor: theme.palette.primary.main
      },
      '& $notchedOutline': {
        borderColor: '#E6E9EC',
        borderRadius: 1
      },
      '& input': {
        fontSize: 12,
        padding: '12px 10px'
      },
      '& select': {
        fontSize: 14
      },
      '& textarea': {
        fontSize: 14
      },
      '&:-webkit-calendar-picker-indicator' : {
        cursor: "pointer"
      },
    
    }
  },
  soapEntryBtn2: {
    justifyContent: 'space-between!important',
    '& button': {
      textTransform: 'capitalize'
    },
    '& .MuiButton-outlined': {
      fontSize: 12,
      padding: '2px 6px!important',
      marginRight: '4px!important',
      [theme.breakpoints.down('md')]: {
        fontSize: 10,
        padding: '2px 2px!important'
      }
    }
  },
  soapEntryTitle: {
    padding: '10px 10px!important'
  },
  labelRoot: {},
  labelFocused: {},
  textField: {},
  soapPastWrap: {
    height: 'calc(100% + 24px)',
    borderLeft: '1px solid #D4E5F9',
    marginBottom: -24
  },
  soapPastHeader: {
    padding: '15px 10px!important',
    borderBottom: '1px solid #D4E5F9',
    minHeight: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  chartReviewTable: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '& td': {
      padding: '8px 10px',
      color: '#4A4A4A',
      fontSize: 12,
      lineHeight: '16px',
      backgroundColor: '#EFF5FC'
    },
    '& th': {
      padding: '11px 10px',
      backgroundColor: '#fff',
      fontWeight: 600,
      fontSize: 12
    }
  },
  generalTable: {
    '& tr': {
      '&:nth-child(even)': {
        backgroundColor: '#f9f9f9'
      }
    },
    '& td': {
      padding: '8px 5px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px',
      '& span': {
        color: theme.palette.primary.main
      },
      '& a:hover': {
        textDecoration: 'underline'
      },
      '& .MuiIconButton-root': {
        '& svg': {
          width: 16,
          height: 16,
          color: '#717171'
        }
      }
    },
    '& th': {
      padding: '8px 5px',
      fontWeight: 600,
      background: 'transparent',
      borderBottom: '1px solid #a2a2a2'
    },
    '& thead': {
      backgroundColor: 'transparent'
    }
  },
  vitalHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    paddingBottom: 15
  },
  vitalBody: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between'
  },
  templateWrap: {
    marginBottom: 10,
    flex: 1
  },
  templateHead: {
    background: '#EFF5FC',
    border: '1px solid #D4E5F9',
    fontWeight: 600,
    fontSize: 14,
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      boxShadow: 'none',
      textTransform: 'capitalize'
    },
    '& p': {
      fontSize: 14,
      fontWeight: 600
    }
  },
  templateBody: {
    border: '1px solid #D4E5F9',
    borderTop: 'default',
    '& .ps__rail-x': {
      visibility: 'hidden'
    }
  },
  templateTable: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '& td': {
      padding: '8px 10px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px'
    },
    '& th': {
      padding: '8px 10px'
    },
    '& td a': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'underline'
      },
      '&:active': {
        color: theme.palette.primary.main
      }
    }
  },
  ellipsisMode: {
    display: 'grid',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    }
  },
  cptList: {
    lineHeight: '24px',
    '& svg': {
      width: '15px!important',
      height: '15px!important'
    }
  },
  cptListRightIcon: {
    lineHeight: '24px',
    '& li': {
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& svg': {
      width: '18px!important',
      height: '18px!important',
      color: theme.palette.error
    }
  },
  templatePop: {
    height: 'calc(100vh - 298px)',
    maxHeight: 650,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(50vh - 200px)'
    }
  },
  medicatn:{
    fontWeight: 600,
  }
});
