export default theme => ({
  header: {
    //boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    width: 140
  },
  header: {
    backgroundColor: '#5698D5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.11)'
  },
  headerWrap: {
    flexDirection: 'row',
    padding: 0
  },
  logoWrap: {
    backgroundColor: '#fff',
    display: 'flex',
    height: 64,
    width: '240px',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '1px 0px 5px 0px rgba(0, 0, 0, 0.170509)',
    zIndex: 1,
    '& a': {
      height: 40,
      overflow: 'hidden'
    }
  },
  naviWrap: {
    height: 64,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  naviBot: {
    display: 'flex',
    background: '#82C3FF',
    height: 32,
    '& li': {
      '& a': {
        color: '#fff',
        padding: '7px 15px 8px 15px',
        fontFamily: 'Lato',
        fontSize: 14,
        lineHeight: '32px',
        borderRight: '1px solid #7aafe0',
        '&:hover': {
          backgroundColor: '#9cd0ff'
        }
      },
      '& .active': {
        backgroundColor: '#84b7e6!important'
      }
    }
  },
  active: {},
  naviLinks: {
    margin: 0,
    padding: 0,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    '& .MuiBadge-root': {
      height: 31,
      lineHeight: '30px',
      '& .MuiBadge-badge': {
        top: 10,
        left: -7,
        right: 'auto',
        backgroundColor: '#ED2024'
      }
    }
  },
  naviSearchWrap: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    background: '#F7F7F7',
    display: 'flex',
    position: 'relative'
  },
  naviSearchBtn: {
    padding: '0 5px',
    color: '#606060',
    position: 'absolute',
    right: -1,
    top: -1,
    bottom: -1
  },
  naviSearchInput: {
    fontSize: 14,
    width: '100%',
    '& input': {
      paddingLeft: '10px',
      paddingRight: 35
    },
    '& input:focus': {
      backgroundColor: '#fff'
    }
  },
  timeStamp: {
    padding: '5px 20px',
    fontSize: 12,
    color: '#206bb1'
  },
  naviLinkWrap: {
    display: 'flex',
    alignItems: 'center'
  },
  naviTop: {
    display: 'flex',
    height: 32,
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  naviTopC2a: {},
  naviTopTit: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '& h4': {
      fontSize: 16,
      color: '#fff',
      fontWeight: 600,
      letterSpacing: '.5px'
    }
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalBody: {
    padding: '10px 0'
  },
  labelFocused: {
    backgroundColor: theme.palette.primary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },
  textField: {
    borderColor: '#D8D8D8',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: 1,
    '&::placeholder': {
      color: 'blue',
      fontSize: 10
    },
    '& + & ': {
      marginTop: theme.spacing(2)
    },
    '&:focus $notchedOutline': {
      borderColor: 'green'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC',
      borderRadius: 1
    }
  },
  contentWrap: {
    background: '#fff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: 10
  },
  c2aBtn: {
    border: 'none',
    boxShadow: 'none',
    height: 32,
    borderRadius: 0,
    padding: '5px 20px',
    textTransform: 'capitalize',
    fontWeight: 600,
    '&:hover': {
      boxShadow: 'none'
    }
  },
  naviTopAcc: {}
});
