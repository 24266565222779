import { errorHandler } from '../error/action';
import http from 'services/http';

export const search = (searchVal, page = 0, limit, order, sortby) => async dispatch => {
  try {
    const res = await http.get(`/search?clinicname=${searchVal}&page=${page}&limit=${limit}&order=${order}&sortby=${sortby}`);

    if (res) {
      dispatch({
        type: 'EHR_GET',
        data: res.data
      });

      return res.data;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getOrganizationbyId = id => async dispatch => {
  try {
    const res = await http.get(`/ehr/${id}`);

    if (res) {
      dispatch({
        type: 'ORGANIZATION_GET',
        data: res.data
      });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const saveOrg = data => async () => {
  try {
    const res = await http.post(`/ehr/${data.id}`, data);

    if (res) {
      // dispatch({
      //   type: 'ORGANIZATION_GET',
      //   data: res.data
      // });

      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const uploadFile = (file, ehrID) => async () => {
  try {
    const formData = new FormData();
    formData.append('file', file, file.filename);
    formData.append('ehrid', ehrID);

    return true;
  } catch (error) {
    errorHandler(error);
    return { status: false };
  }
};
