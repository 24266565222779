import { errorHandler } from '../error/action';
import http from 'services/http';

export const getStates = () => async dispatch => {
  try {
    http.get(`referencedata/states`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_STATES_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getTitles = () => async dispatch => {
  try {
    http.get(`referencedata/titles`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_TITLES_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getLocations = ehrID => async dispatch => {
  try {
    http.get(`/ehr/${ehrID}/locations`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_LOCATION_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getProviders = ehrID => async dispatch => {
  try {
    http.get(`/ehr/${ehrID}/providers`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_PROVIDER_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getCategories = ehrID => async dispatch => {
  try {
    http.get(`/ehr/${ehrID}/categories`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_CATEGORIES',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
export const getGender = () => async dispatch => {
  try {
    http.get(`referencedata/genders`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_GENDER_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getRaces = () => async dispatch => {
  try {
    http.get(`referencedata/races`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_RACES_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getLanguages = () => async dispatch => {
  try {
    http.get(`referencedata/languages`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_LANGUAGES_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getethnicities = () => async dispatch => {
  try {
    http.get(`referencedata/ethnicities`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_ETHNICCITIES_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getRelationships = () => async dispatch => {
  try {
    http.get(`referencedata/relationships`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_RELATIONSHIP_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getMaritalstatus = () => async dispatch => {
  try {
    http.get(`referencedata/maritalstatus`).then(res => {
      if (res.data) {
        dispatch({
          type: 'GET_MARITALSTATUS_SUCCESS',
          data: res.data
        });
        return true;
      }
      return false;
    });
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addCategory =( ehrID,category) => async dispatch => {
  try {
   const res= await http.post(`/ehr/${ehrID}/categories`,category);
      if (res) {
       
        return res;
      }
      return false;
    }
   catch (error) {
    errorHandler(error);
    return false;
  }
};