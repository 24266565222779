import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.png';
import { connect } from 'react-redux';
import { StepOne, StepTwo } from './components';
import { CircularProgress } from '@material-ui/core';

import {
  Grid,
  Button,
  Typography,
  withStyles,
  AppBar,
  Toolbar,
  Box,
  InputBase,
  IconButton
} from '@material-ui/core';
import { SearchOutlined, ArrowBackOutlined } from '@material-ui/icons';
import styles from '../SignIn/style';
import { register } from 'services/user/action';

const SignUp = props => {
  const { classes } = props;
  const childRef = useRef();
  const childRefNew = useRef();
  const initialUserState = {
    values: {
      div1: true,
      div2: false
    }
  };
  const [div, setDiv] = useState(initialUserState.values);
  let [data, setData] = useState({});
  let [nextdata, setNextData] = useState({});
  const [isback, setIsBack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);

  const handleBack = () => {
    setIsBack(true);
    setDiv({
      div1: true,
      div2: false
    });
    let data = childRefNew.current.fetchdata();
    setNextData(data);
  };

  const handleNext = () => {
    if (childRef.current.validateForm().valid) {
      setDiv({
        div1: false,
        div2: true
      });
      let data = childRef.current.validateForm().data;
      setData(data);
    }
  };
  const handleRegister = async () => {
    let datanew = await childRefNew.current.fetchdata();
    setNextData(datanew);
    if (childRefNew.current.validateForm().valid) {
      setLoading(true);
      setTimeout(() => {
        const newstate = {
          owner: {
            firstname: data.firstname,
            lastname: data.lastname,
            title: !data.title || data.title === null ? '' : data.title,
            credentials: {
              emailaddress: data.emailaddress,
              username: datanew.username,
              password: btoa(datanew.password),
              enabled: true,
              authorities: [
                { username: datanew.username, authority: 'ROLE_USER' },
                { username: datanew.username, authority: 'ROLE_ADMIN' },
                { username: datanew.username, authority: 'ROLE_OWNER' },
                { username: datanew.username, authority: 'ROLE_PROVIDER' }
              ]
            },
            medical: {
              medicallicense: datanew.medicallicense,
              individualnpi: datanew.individualnpi,
              dea: datanew.dea
            }
          },
          practice: [
            {
              businessphones: data.businessphone ? [data.businessphone] : [],
              address: {
                address: data.address,
                city: data.city,
                state: data.state,
                zip: data.zip
              },
              companyname:
                data.organizationname !== null
                  ? data.organizationname
                  : data.firstname + ' ' + data.lastname
            }
          ],
          organizationname: data.organizationname,
          websitename: data.websitename
        };
        props.register(newstate).then(status => {
          if (status) {
            setLoading(false);
            props.history.push({
              pathname: '/terms',
              state: {
                name: data.firstname + ' ' + data.lastname,
                organizationname: data.organizationname + ' '
              }
            });
          } else {
            setLoading(false);
          }
        });
      }, 1000);
    }
  };

  const handleSearchChange = (field, value) => {
    setSearch(value);
  };

  const _handleSearchKeyDown = e => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (search !== null && search.length) {
      props.history.push({
        pathname: '/searchclinic',
        state: { search: search }
      });
    }
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.headerWrap} position="relative">
        <Toolbar style={{ padding: 0 }}>
          <Box className={classes.naviWrap}>
            <Box>
              <RouterLink to="/">
                <img alt="Logo" src={logo} className={classes.logoImg} />
              </RouterLink>
            </Box>
            <Box className={classes.rightCol}>
              <Box className={classes.searchWrap}>
                <InputBase
                  className={classes.searchInput}
                  value={search}
                  placeholder="Search clinic"
                  label="search"
                  name="search"
                  onKeyPress={_handleSearchKeyDown}
                  onChange={event =>
                    handleSearchChange('search', event.target.value)
                  }
                />
                <IconButton
                  className={classes.searchBtn}
                  onClick={() => {
                    handleSearch();
                  }}>
                  <SearchOutlined />
                </IconButton>
              </Box>
              <Button
                className={classes.loginButton}
                color="primary"
                variant="contained"
                component={RouterLink}
                to="/sign-in">
                Login
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item xs={12}>
          <div className={classes.contentBody}>
            <Typography variant="h1" className={classes.formTitle}>
              EHR Registration
            </Typography>
            {div.div1 ? (
              <div>
                <StepOne
                  ref={childRef}
                  handleRegister={handleRegister}
                  datas={data}
                  isback={isback}
                />
                <Grid container className={classes.formButtonWrap}>
                  <Grid xs={6} item></Grid>
                  <Grid xs={6} item>
                    <Button
                      onClick={() => handleNext()}
                      variant="contained"
                      color="secondary">
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </div>
            ) : null}

            {div.div2 ? (
              <div>
                <StepTwo
                  ref={childRefNew}
                  datas={data}
                  isback={isback}
                  nextdatas={nextdata}
                />
                {loading ? (
                  <div
                    className={classes.progressWrapper}
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      marginBottom: 30
                    }}>
                    <CircularProgress style={{ height: 30, width: 30 }} />
                  </div>
                ) : (
                  <Grid container className={classes.formButtonWrap}>
                    <Grid xs={6} item>
                      <Button
                        onClick={() => handleBack()}
                        variant="contained"
                        className={classes.backButton}>
                        <ArrowBackOutlined />
                      </Button>
                    </Grid>
                    <Grid xs={6} item>
                      <Button
                        component={RouterLink}
                        //to="/terms"
                        variant="contained"
                        color="secondary"
                        onClick={handleRegister}>
                        Register
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

//export default withRouter(withStyles(styles)(SignUp));
const mapDispatchToProps = {
  register
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(SignUp));
