/* eslint-disable linebreak-style */
import React from 'react';
import { Switch, Redirect, BrowserRouter } from 'react-router-dom';

import { RouteWithLayout } from 'components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  Minimalistic as MinimalisticLayout,
  PatientMinimal as PatientMinimalLayout,
  PatientMain as PatientMainLayout,
  SearchLayout as SearchLayout
} from 'layouts';

import ProtectedRoute from 'routes/protectedRouteWithLayout';
import RouteContainer from 'routes/routeContainer';
import {
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  NotFoundOrg as NotFoundOrgView,
  Terms as TermsView,
  Forgot as ForgotView,
  Search as SearchView,
  Migration as Migration,
  Default as DefaultView,
  AccountSettings as AccountSettingsView,
  PTIssues as PTIssuesView,
  PTMessage as PTMessageView,
  PTSchedule as PTScheduleView,
  Templates as TemplatesView,
  PtSignIn as PtSignInView,
  PtDashboard as PtDashboardView,
  PatientPTSchedule as PatientPTScheduleView,
  PatientPTMessage as PatientPTMessageView
} from 'views';
import { Modal_ChangePassword } from 'layouts/PatientMain/components/Topbar';
import { Passwordreset } from 'views/Terms/components/Forgots/index';
import { ActivateAccount } from 'views/Terms/components/Forgots/index';
const Routes = () => {
  return (
    <BrowserRouter basename="/" history="browserHistory">
      <RouteContainer>
        <Switch>
          <Redirect exact from="/" to="/sign-in" />

          <RouteWithLayout
            component={SignUpView}
            exact
            layout={MinimalLayout}
            path="/sign-up"
          />
          <RouteWithLayout
            component={SignInView}
            exact
            layout={MinimalLayout}
            path="/sign-in"
          />
          <RouteWithLayout
            component={NotFoundView}
            exact
            layout={MinimalLayout}
            path="/not-found"
          />
          <RouteWithLayout
          component={NotFoundOrgView}
          exact
          layout={MinimalLayout}
          path="/organization-not-found"
        />
          <RouteWithLayout
            component={TermsView}
            exact
            layout={MinimalisticLayout}
            path="/terms"
          />
          <RouteWithLayout
            component={ForgotView}
            exact
            layout={MinimalisticLayout}
            path="/forgot/:pw?"
          />

          <RouteWithLayout
            component={Passwordreset}
            exact
            layout={MinimalisticLayout}
            path="/passwordresetnew/:username?/:ehrid?/:token?"
          />
          <RouteWithLayout
            component={ActivateAccount}
            exact
            layout={MinimalisticLayout}
            path="/activateaccountnew/:username?/:ehrid?/:token?"
          />

          <RouteWithLayout
            component={SearchView}
            exact
            layout={SearchLayout}
            path="/searchclinic/:clinicname?"
          />

          <ProtectedRoute
            component={Migration}
            exact
            layout={SearchLayout}
            path="/migration"
            
          />

          <ProtectedRoute
            component={DefaultView}
            exact
            layout={MainLayout}
            path="/default"
          />
          <ProtectedRoute
            component={AccountSettingsView}
            exact
            layout={MainLayout}
            path="/account-settings"
          />
          <ProtectedRoute
            component={PTIssuesView}
            exact
            layout={MainLayout}
            path="/pt-issues"
          />
          <ProtectedRoute
            component={PTScheduleView}
            exact
            layout={MainLayout}
            path="/pt-schedule"
          />
          <ProtectedRoute
            component={PTMessageView}
            exact
            layout={MainLayout}
            path="/pt-message"
          />
          <ProtectedRoute
            component={TemplatesView}
            exact
            layout={MainLayout}
            path="/templates"
          />
          <RouteWithLayout
            component={PtSignInView}
            exact
            layout={PatientMinimalLayout}
            path="/pt-login"
          />
          <RouteWithLayout
            component={Modal_ChangePassword}
            exact
            layout={MinimalisticLayout}
            // layout={MainLayout}
            path="/change-password"
          />
          <ProtectedRoute
            component={PtDashboardView}
            exact
            layout={PatientMainLayout}
            path="/pt-default"
          />
          <ProtectedRoute
            component={PatientPTMessageView}
            exact
            layout={PatientMainLayout}
            path="/patient-pt-message"
          />
          <ProtectedRoute
            component={PatientPTScheduleView}
            exact
            layout={PatientMainLayout}
            path="/patient-pt-schedule"
          />

          <Redirect to="/not-found" />
        </Switch>
      </RouteContainer>
    </BrowserRouter>
  );
};

export default Routes;
