import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { withStyles } from '@material-ui/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../../style';
import { connect } from 'react-redux';
import { Providers, PatientInfo } from './';
import { getPTInfo, getPTInfoDetail } from 'services/ptinfo/action';
import { computeAge } from 'util/helper';
import moment from 'moment';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField,
  Typography,
  Popover,
  Slide} from '@material-ui/core';
import {
  SearchOutlined} from '@material-ui/icons';

const Details = props => {
  const [search, setSearch] = useState('');
  const {
    classes,
    className,
    profile,
    ehrID,
    ptInfoList,
    patientInfoDetail,
    ...rest
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDetails, setIsDetails] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);
  const [state, setState] = useState({ page: 0, count: 0 });
  const [openUpload, setOpenUpload] = useState(false);

  useEffect(() => {
    props.getPTInfo(ehrID, state.page, search);
  }, []);

  useEffect(() => {
    props.getPTInfo(ehrID, state.page, search);
  }, [search]);

  useEffect(() => {
    if (ptInfoList !== undefined) {
      setState({ ...state, count: ptInfoList.totalElements });
      let datatopush = [];
      ptInfoList &&
        ptInfoList.result.forEach(function(item) {
          datatopush.push({
            patientName:
              (item.lastname ? item.lastname : '') +
              ', ' +
              (item.firstname ? item.firstname : '') +
              ' ' +
              (item.middleinitial ? item.middleinitial : ''),
            patientId: item.id,
            dob: item.dob
              ? moment(item.dob).format('MM/DD/YYYY') +
                ' (' +
                computeAge(item.dob) +
                ' yrs)'
              : '',
            // mr:"%08d"|fmt:patient.id,
            primaryInsurance: item.primaryInsurance
              ? item.primaryInsurance.grouppolicy &&
                item.primaryInsurance.grouppolicy.insurancecompany
                ? item.primaryInsurance.grouppolicy.insurancecompany
                : ''
              : '',
            secondaryInsurance: item.secondaryInsurance
              ? item.secondaryInsurance.grouppolicy &&
                item.secondaryInsurance.grouppolicy.insurancecompany
                ? item.secondaryInsurance.grouppolicy.insurancecompany
                : ''
              : '',
            phonenumber: item.phonenumber
          });
        });
      setData(datatopush);
      setLoading(false);
    }
  }, [ptInfoList]);

  const handleChangePage = async page => {
    setLoading(true);
    setState({ ...state, page: page });
    await props.getPTInfo(ehrID, page, search);
  };

  const handleFieldChange = async (field, value) => {
    setLoading(true);
    setSearch(value);
    await props.getPTInfo(ehrID, state.page, search);
  };

  const handlePatientInfo = async (id = 0) => {
    setIsDetails(!isDetails);
    if (id > 1) {
      await props.getPTInfoDetail(ehrID, id);
    } else {
      await props.getPTInfo(ehrID, state.page, search);
    }
  };

  const handleSave = async () => {
    // alert('ok');
    // console.log(data);
  };

  const handleOpenDoc = () => {
    setOpenDoc(true);
  };
  const handleCloseDoc = () => {
    setOpenDoc(false);
  };
  const handleOpenUpload = () => {
    setOpenUpload(true);
  };
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  // const setDetails = () => {
  //   setIsDetails(!isDetails);
  // };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '10px 10px',
          fontSize: 12
        }
      },
      MUIDataTableBodyCell: {
        root: {
          cursor: 'pointer'
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientName',
      label: 'Patient Name'
    },
    {
      name: 'dob',
      label: 'Date of Birth',
      options: {
        sort: false,
        setCellProps: value => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: value => ({
          style: { width: '150px', textAlign: 'center' }
        })
      }
    },
    {
      name: 'patientId',
      label: 'MR#',
      options: {
        sort: false,
        setCellProps: value => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: value => ({
          style: { width: '150px', textAlign: 'center' }
        })
      }
    },
    {
      name: 'primaryInsurance',
      label: 'Primary Insurance',
      options: {
        sort: false
      }
    },
    {
      name: 'secondaryInsurance',
      label: 'Secondary Insurance',
      options: {
        sort: false
      }
    },
    {
      name: 'phonenumber',
      label: 'Phone Number',
      options: {
        sort: false,
        setCellProps: value => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: value => ({
          style: { width: '150px', textAlign: 'center' }
        })
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'select',
    responsive: 'scroll',
    elevation: 0,
    rowsPerPageOptions: [],
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    pagination: state.count > 10,
    selectableRows: false,
    serverSide: true,
    count: state.count,
    page: state.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
      }
    },
    onRowClick: rowData => handlePatientInfo(rowData[2])
  };
  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      {!isDetails ? (
        <>
          <Grid container>
            <Grid className={classes.cardHeader} item md={8} sm={4} xs={12}>
              <Typography variant="h2">
                {' '}
                {(profile && profile.firstname ? profile.firstname : '') +
                  ' ' +
                  (profile && profile.lastname ? profile.lastname : ' ') +
                  ' ' +
                  (profile && profile.title ? profile.title : '')}
              </Typography>
            </Grid>
            <Grid
              className={classes.cardHeader}
              item
              md={4}
              sm={8}
              style={{ justifyContent: 'flex-end' }}
              xs={12}>
              <div style={{ position: 'relative', width: '100%' }}>
                <TextField
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  className={classes.textBox}
                  id="outlined-basic"
                  onChange={event =>
                    handleFieldChange('search', event.target.value)
                  }
                  placeholder="Patient Search"
                  value={search}
                  variant="outlined"
                />
                <Button
                  className={classes.searchBtn}
                  color="primary"
                  variant="contained">
                  <SearchOutlined />
                </Button>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <CardContent>
            <MuiThemeProvider theme={theme}>
              <div className={classes.fullWidthTable}> 
                  <MUIDataTable
                    columns={columns}
                    data={data}
                    options={options}
                    responsive="scrollMaxHeight"
                  /> 
              </div>
            </MuiThemeProvider>
          </CardContent>
        </>
      ) : (
        patientInfoDetail && (
          <CardContent>
            <PatientInfo
              goBack={handlePatientInfo}
              handleOpenDoc={handleOpenDoc}
              pastSoapNotes={false}
              patientInfoDetail={patientInfoDetail}
            />
          </CardContent>
        )
      )}

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        elevation={4}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <PerfectScrollbar>
          <Providers handleOpenDoc={handleOpenDoc} />
        </PerfectScrollbar>
      </Popover>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { getPTInfo, getPTInfoDetail };

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  //userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  profile: state && state.profile,
  ptInfoList: state.ptinfo && state.ptinfo.list,
  patientInfoDetail: state.ptinfo && state.ptinfo.patientInfo
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(Details));
