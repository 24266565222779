import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../style';
import { Grid } from '@material-ui/core';
import { Roles } from 'util/enum';
import { Profile, Settings } from './components';
import { connect } from 'react-redux';
//const StyledTab = withStyles(theme => ({

const UserSettings = props => {
  const { classes, role } = props;

  return (
    <>
      {role !== Roles.staff ? (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={classes.colWrap}>
            <Profile />
          </Grid>
          <Grid item xs={12} md={6} className={classes.colWrap}>
            <Settings />
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12} md={6} className={classes.colWrap}>
          <Settings />
        </Grid>
      )}
    </>
  );
};

UserSettings.propTypes = {
  className: PropTypes.string
};
const mapStateToProps = state => ({
  role: state.profile && state.profile.role
});

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(UserSettings))
);
