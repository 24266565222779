export default {

  username: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
      maximum: 128
    },
    // format: {
    //   pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    //   message: " must contain an uppercase,lowercase and number"
    // }
  },
  confirmpassword: {
    presence: { allowEmpty: false, message: "^Enter the password again" },
    length: {
      maximum: 128
    },
    equality: {
      attribute: "password",
      message: "^Password and confirm password does not match",
      comparator: (v1, v2) => {
        return v1 === v2;
      }
    }
  }


};
