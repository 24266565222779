import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Result } from './components';
import Logo from '../../assets/images/logo.png'

import {
  withStyles} from '@material-ui/core';
import './style.css'

import styles from '../SignIn/style';
import { connect } from 'react-redux';
import { search } from 'services/ehr/action';
import { makeStyles } from '@material-ui/styles';

const Search = props => { 
  const state = props.location.state;
  const initialState = {
    rowsPerPage: 10,
    page: 0
  };
  const useStyles = makeStyles(() => ({

    header: {
      backgroundColor: '#fff',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      zIndex: 100
    },
    search: {
      justifyContent: 'center',
      backgroundColor: '#fff',
      position: 'fixed',
      width: '100%',
      zIndex: 100,
      left: 0,
      top: 70
    },
    content: {
      position: 'relative',
      top: 160,
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
      }
    },

  }));
  const [values, setValues] = useState({
    
    search: state && state.search || "",
    searchterm: (state && state.search) || null,
    limit: 10,
    order: 'DESC',
    sortBy: 'updatedDate',
    showResult: null,
    isLoading: false,
    isInitialScreen:true
  });
  const [result, setResult] = useState({
    searchResult: null
  });
  const [rowsPerPage, setRowsPerPage] = useState(initialState.rowsPerPage);
  const [page, setPage] = useState(initialState.page);

  useEffect(() => {
    handleSearch(values.search);
  }, []);

  const handleSearchChange = (field, value, initial = false) => {
    if (!initial) {
      if (field === "searchbtn") {
               setValues({ ...values, search: value || values.search, searchterm: value || values.searchterm, isInitialScreen:false });
     
      } else {
        setValues({ ...values, search: value, searchterm: value , isInitialScreen:false });
       
      }
      
    } else{
      setValues({ ...values,searchterm: value, });
     
    }
   
  };

  const handleSearch = async () => {
   
    let search = values.search || values.searchterm;
    if (search && search.length > 0) {
      setValues({ ...values, search: search, isLoading: true, isInitialScreen:false  });
      props
        .search(
          search,
          page,
          values.limit,
          values.order,
          values.sortBy
        )
        .then(res => {
          if (res && res.totalElements > 0) {
            setResult({ searchResult: res });
          }
        });
    }
  };

  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      let search = values.search || values.searchterm;
      handleSearch(search);
    }
  };

  const handleChangePage = (rowsPerPage, page) => {
    setRowsPerPage(rowsPerPage);
    setPage(page);
    let showResult = false;
    props.search(values.search, page, rowsPerPage, values.order, values.sortBy)
      .then(res => {
        if (res && res.totalElements > 0) {
          setResult({ searchResult: res });
          console.log(result.searchResult);
          showResult = true;
        } else {
          showResult = false;
          //setValues({ ...values, showResult: showResult });
        }
        setValues({ ...values, showResult: showResult });
      });
  };

  return (
   
    <>
      <div className="header-contents">
        <div className="logo-box">
          <img src={Logo} alt="" className="logo-img" />
        </div>
        <div className="header-btns">
          <Link to="/sign-in" className="login-btns mr-10">Provider Login</Link>
          <Link to="/pt-login" className="login-btns">Patient Login</Link>
        </div>
      </div>
      {!values.isInitialScreen &&
        <div className="serachresultpg">
          <div className="search-area">
            <div className="search-bar">
              <input type="search" className="input-search" placeholder="Search clinic" value={values.search} onKeyPress={_handleKeyDown} onChange={event => handleSearchChange('search', event.target.value)} />
              <button className="search-btn" onClick={() => handleSearch('searchbtn')}><span className="iconify search-icon" data-icon="fe:search"></span></button>
            </div>
          </div>
          <Result result={result?.searchResult} onChange={handleChangePage} />
        </div>}
      {values.isInitialScreen &&
        <div className="search-space">
          <div className="search-bar">
            <input type="search" className="input-search" placeholder="Search clinic" onKeyPress={_handleKeyDown} onChange={event => handleSearchChange('search', event.target.value, true)} />
            <button className="search-btn" onClick={() => handleSearch('searchbtn', values.searchterm)}><span className="iconify search-icon" data-icon="fe:search"></span></button>
          </div>
        </div>
      }
    </>
  );
};

Search.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  search
};

const mapStateToProps = state => ({
  list: (state.ehr && state.ehr.search && state.ehr.search.result) || null
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Search))
);
