import React, { 
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { Link as RouterLink } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import PatientFilter from 'views/Common/components/PatientFilter';
import { Grid, Button, IconButton } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { AttachmentOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { loadOrders, downloadPDF } from 'services/orders/action';
import {clearSoap} from 'services/soap/action';

const PTList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      clearFilterValue,
      setLocationFilterValues
    };
  });
  const { classes, data, state } = props;
  const childRef = useRef();
  const handleOpenResponse = staffMessage => {
    props.handleOpenResponse('', staffMessage);
  };

  const handleDrpDownChange = (location, provider, selstatus) => {
    props.handleDrpDownChange(location, provider, selstatus);
  };

  const handleOpenSOAP = patientId => {
    props.clearSoap()
    props.history.push({
      pathname: '/default',
      state: { patientId: patientId }
    });
  };

  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none !important'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: { 
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9',
        },
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          // padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      toolButton:{

      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientName',
      label: 'Patient Name',
      options: {
        setCellProps: () => ({
          style: { width: '20%', minWidth: 110 }
        }),
        setCellHeaderProps: () => ({}),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handleOpenSOAP(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Patient Message',
      options: {
        setCellProps: () => ({
          style: { width: '35%' }
        }),
        setCellHeaderProps: () => ({}),
        customBodyRender: value => {
          const staffMessage = data.filter(c => c.id === value);
          return staffMessage[0].message &&
            !staffMessage[0].providerResponse ? (
            <RouterLink style={{ color: '#a94442', cursor: 'auto' }}>
              {staffMessage[0].message}
            </RouterLink>
          ) : staffMessage[0].message && staffMessage[0].providerResponse ? (
            <RouterLink style={{ color: '#000000', cursor: 'auto' }}>{staffMessage[0].message}</RouterLink>
          ) : null;
        }
      }
    },
    {
      name: 'id',
      label: 'Staff Response',
      options: {
        setCellProps: () => ({}),
        setCellHeaderProps: () => ({
          style: { width: '40%' }
        }),
        customBodyRender: value => {
          const staffMessage = data.filter(c => c.id === value);
          return staffMessage[0].status === 1 ? (
            staffMessage[0].providerResponse === null ? (
              <Button
                onClick={() => handleOpenResponse(staffMessage[0])}
                size="small"
                variant="contained">
                Click to respond
              </Button>
            ) : (
              <React.Fragment>
                <RouterLink
                  onClick={() => handleOpenResponse(staffMessage[0])}
                  style={{ cursor: 'pointer' }}>
                  {staffMessage[0].providerResponse
                    ? staffMessage[0].providerResponse + ', '
                    : ''}
                  <br />
                  {staffMessage[0].providerName
                    ? staffMessage[0].providerName + ', '
                    : ''}

                  {staffMessage[0].responseCreatedDate
                    ? moment(staffMessage[0].responseCreatedDate).format(
                      'MMM DD, YYYY'
                    )
                    : null}
                </RouterLink>
                <span>
                  {staffMessage[0].ptMessageOrdersList.length > 0 ? (
                    <IconButton
                      color="primary"
                      component="span"
                      size="small"
                      onClick={() => handleOpenResponse(staffMessage[0])}
                    >
                      <AttachmentOutlined />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </span>
              </React.Fragment>
            )
          ) : (
            <React.Fragment>
              <RouterLink
                  onClick={() => handleOpenResponse(staffMessage[0])}>
              
                {staffMessage[0].providerResponse
                  ? staffMessage[0].providerResponse + ', '
                  : ''}
                <br />
                {staffMessage[0].providerName
                  ? staffMessage[0].providerName + ', '
                  : ''}
                {staffMessage[0].responseCreatedDate
                  ? moment(staffMessage[0].responseCreatedDate).format(
                    'MMM DD, YYYY'
                  )
                  : null}
              </RouterLink>
              <span>
                {staffMessage[0].ptMessageOrdersList.length > 0 ? (
                  <IconButton
                    color="primary"
                    component="span"
                    size="small"
                    onClick={() => handleOpenResponse(staffMessage[0])}
                  >
                    <AttachmentOutlined />
                  </IconButton>
                ) : (
                  ''
                )}
              </span>
            </React.Fragment>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Status',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '10%', minWidth: 90, textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '10%', minWidth: 90, textAlign: 'center' }
        }),
        customBodyRender: value => {
          const status = data.filter(c => c.id === value);
          return status[0].status === 1 ? (
            <span style={{ color: '#ED2024' }}>No Response</span>
          ) : (
            <span style={{ color: '#3397F3' }}>Completed</span>
          );
        }
      }
    }
  ];

  const handleChangePage = async page => {
    props.handleChangePage(page);
  };

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: state.count > 20,
    selectableRows: false,
    serverSide: true,
    count: state.count,
    page: state.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
      }
    },
    customToolbar: () => {
      return (
        <PatientFilter
          handleDrpDownChange={handleDrpDownChange}
          isLocalStorage={props.isLocalStorage}
          isptMessages
          ref={childRef}
        />
      );
    }
  };

  const clearFilterValue = () => {
    if (childRef.current) {
      childRef.current.clearFilter();
    }
  };

  const setLocationFilterValues = () => {
    if (childRef.current) {
      childRef.current.loadLocalStorage();
    }
  };

  return (
    <Grid container item spacing={0} style={{ borderTop: '1px solid #D4E5F9' }}>
      <Grid item xs={12}>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            className={classes.simpleTable}
            columns={columns}
            data={data}
            options={options}
            responsive="scrollMaxHeight"
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );
});

PTList.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { loadOrders, downloadPDF, clearSoap };

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(withStyles(styles)(PTList))
);
