import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../style';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons/';
import { ptScheduleListTimeElapsed } from 'util/helper';

const ScheduleHistory = props => {
  const { classes, className, data, patientName, ...rest } = props;
  const [isEdit, setIsEdit] = useState(false);
  const setEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <Box className={classes.modalWrap} style={{ width: '90%', maxWidth: 750 }}>
      <Box className={classes.modalHead}>
        <Typography variant="h2">{patientName}</Typography>
        <IconButton
          aria-label="delete"
          className={classes.modalClose}
          onClick={props.onCancel}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box className={classes.modalBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 250 }}>
            <Table
              className={classes.generalTable}
              style={{ marginBottom: 10 }}>
              <TableHead>
                <TableRow>
                  <TableCell width={92}>Date</TableCell>
                  <TableCell width={122}>Time</TableCell>
                  <TableCell>Reason for appointment</TableCell>
                  <TableCell>Provider Name </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((appointment, idx) => (
                    <TableRow
                      className={
                        ptScheduleListTimeElapsed(appointment)
                          ? classes.disabledRow
                          : ''
                      }>
                      <TableCell>
                        {appointment.appointmentDate
                          ? moment(appointment.appointmentDate).format(
                              'MM/DD/YYYY'
                            )
                          : null}
                      </TableCell>
                      <TableCell noWrap="true">
                        <div className={classes.noWrap}>
                          <Typography>
                            {appointment.timeFrom
                              ? moment(appointment.timeFrom).format('hh:mm A')
                              : null}

                            {appointment.timeTo
                              ? ' - ' +
                                moment(appointment.timeTo).format('hh:mm A')
                              : null}
                          </Typography>
                        </div>
                      </TableCell>
                      <TableCell noWrap="true">
                        <Typography>
                          {appointment.reasonForAppointment}
                        </Typography>
                      </TableCell>
                      <TableCell noWrap="true">
                        <div className={classes.ellipsisMode}>
                          <Typography>
                            {appointment.routePhysicianName}
                          </Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      colSpan={10}
                      style={{ textAlign: 'center' }}
                      className={classes.tableCell}>
                      No appointments taken
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </Box>
    </Box>
  );
};

ScheduleHistory.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(ScheduleHistory));
