import { errorHandler } from '../error/action';
import http from 'services/http';

export const getAllPhyPtSchedules = (
  ehrID,
  date = '',
  location = '',
  provider = ''
) => async dispatch => {
  try {
    let loc = (location === 'undefined' || location === null) ? '' : location;
    let pro = (provider === 'undefined' || provider === null) ? '' : provider;
    const res = await http.get(
      `/ehr/${ehrID}/ptSchedule?date=${date}&limit=&location=${loc}&order=DESC&physician=${pro}&sortby=appointmentDate`
    );
    if (res) {
      dispatch({
        type: 'PHYPTSCHEDULES_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAllPhyPtSchedulesWeekly = (
  ehrID,
  fromdate = '',
  todate = '',
  location = '',
  provider = ''
) => async dispatch => {
  try {
    let loc = (location === 'undefined' || location === null) ? '' : location;
    let pro = (provider === 'undefined' || provider === null) ? '' : provider;
    const res = await http.get(
      `/ehr/${ehrID}/ptSchedule/Weekly/?fromdate=${fromdate}&todate=${todate}&limit=&location=${loc}&order=DESC&physician=${pro}&sortby=appointmentDate`
    );
    if (res) {
      dispatch({
        type: 'PHYPTSCHEDULES_GET_WEEKLY',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAppointmentsByPatientId = (
  ehrID,
  patientID
) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/patients/${patientID}/patientSchedule?date=&limit=20&order=DESC&sortby=appointmentDate`
    );
    if (res) {
      dispatch({
        type: 'GET_APPOINTMENTS_ID',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePtSchedule = (ehrID, data) => async () => {
  try {
    let res;
    if (data.id > 0) {
      res = await http.post(
        `/patient/${data.patientId}/updatePtSchedule`,
        data
      );
    } else {
      res = await http.post(`/patient/${data.patientId}/savePtSchedule`, data);
    }
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deletePtSchedule = (
  ehrID,
  data,
  isPatient = false
) => async dispatch => {
  try {
    const res = await http.delete(`/ehr/${ehrID}/ptSchedule/${data}`);

    if (res) {
      if (isPatient) {
        dispatch({
          type: 'DELETE_APPOINTMENT',
          data: data
        });
        return true;
      } else {
        return true;
      }
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

// export const sendResponse = (ehrID, patientID, user, orderHxId) => async () => {
//   try {
//     const res = await http.post(
//       `/ehr/${ehrID}/patient/${patientID}/savePhyPtMessage?orderHxId=${orderHxId}`,
//       user
//     );
//     if (res) {
//       return true;
//     }
//   } catch (error) {
//     errorHandler(error);
//     return false;
//   }
// };

// export const sendInvite = (ehrID, patientId, user) => async () => {
//   try {
//     const res = await http.post(
//       `/ehr/${ehrID}/users?patientID=${patientId}&roles=ROLE_USER+ROLE_PATIENT`,
//       user
//     );
//     if (res) {
//       return true;
//     }
//   } catch (error) {
//     errorHandler(error);
//     return false;
//   }
// };

// export const resendInvite = (ehrID, userID) => async () => {
//   try {
//     const res = await http.post(
//       `/ehr/${ehrID}/resendActivationEmail?userID=${userID}`
//     );
//     if (res) {
//       // dispatch({
//       //   type: "PATIENT_GET",
//       //   data: res.data
//       // });
//       return true;
//     }
//   } catch (error) {
//     errorHandler(error);
//     return false;
//   }
// };

export const getAllPatPtSchedules = (
  ehrID,
  patientId
) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/patients/${patientId}/allPtSchedule`
    );
    if (res) {
      dispatch({
        type: 'PATPTSCHEDULES_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
