export default (state = {}, action) => {
  switch (action.type) {
    // case 'EHR_GET':
    //   const {
    //     content,
    //     totalPages,
    //     totalElements,
    //     last,
    //     size,
    //     sort,
    //     first,
    //     numberOfElements
    //   } = action.data;
    //   return {
    //     ...state,
    //     search: {
    //       result: copyArray(content),
    //       totalPages,
    //       totalElements,
    //       last,
    //       size,
    //       sort,
    //       first,
    //       numberOfElements
    //     }
    //   };
    case 'DELETE_APPOINTMENT': //same dispatch name
      return {
        ...state,

        allAppointments: {
          result: state.allAppointments.result.filter(c => c.id !== action.data)
        }
      };

    case 'GET_APPOINTMENTS_ID':
      return {
        ...state,
        allAppointments: { result: copyArray(action.data.content) }
      };
    case 'PHYPTSCHEDULES_GET':
      return {
        ...state,
        allPhyPtSchedules: { result: copyArray(action.data) }
      };
    case 'PHYPTSCHEDULES_GET_WEEKLY':
      return {
        ...state,
        allPhyPtSchedulesWeekly: { result: copyArray(action.data) }
      };

      case 'PATPTSCHEDULES_GET':
        return {
          ...state,
          allPatPtSchedules: { result: copyArray(action.data) }
        };

    default:
      return state;
  }
};

const copyArray = items => {
  return items.map(item => {
    return Object.assign({}, item);
  });
};
