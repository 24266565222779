import { errorHandler } from '../error/action';
import http from 'services/http';

export const getSubjectiveTemplates = (ehrID, providerID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/providers/${providerID}/subjectives`
    );
    if (res) {
      dispatch({
        type: 'SUBJECTIVE_TEMPLATES',
        data: res
      });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const UpdateSubjectiveTemplate = (
  ehrID,
  providerID,
  subjective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/subjectives/${subjective.id}`,
      subjective
    );
    if (res) {
      // dispatch({
      //     type: 'UPDATE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const DeleteSubjectiveTemplate = (
  ehrID,
  providerID,
  subjectiveID
) => async dispatch => {
  try {
    const res = await http.delete(
      `/ehr/${ehrID}/providers/${providerID}/subjectives/${subjectiveID}`
    );
    if (res) {
      // dispatch({
      //     type: 'UPDATE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const AddSubjectiveTemplate = (
  ehrID,
  providerID,
  subjective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/subjectives`,
      subjective
    );
    if (res) {
      // dispatch({
      //     type: 'ADD_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const AddSubjectiveTopicText = (
  ehrID,
  providerID,
  subjective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/subjectives/${subjective.id}`,
      subjective
    );
    if (res) {
      // dispatch({
      //     type: 'ADD_TOPIC_TEXT',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const UpdateSubjectiveTopicText = (
  ehrID,
  providerID,
  subjective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/subjectives/${subjective.id}`,
      subjective
    );
    if (res) {
      // dispatch({
      //     type: 'UPDATE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

//Objective
export const getObjectiveTemplates = (ehrID, providerID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/providers/${providerID}/objectives`
    );
    if (res) {
      dispatch({
        type: 'OBJECTIVE_TEMPLATES',
        data: res
      });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const AddObjectiveTemplate = (
  ehrID,
  providerID,
  objective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/objectives`,
      objective
    );
    if (res) {
      // dispatch({
      //     type: 'ADD_OBJECTIVE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const UpdateObjectiveTemplate = (
  ehrID,
  providerID,
  objective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/objectives/${objective.id}`,
      objective
    );
    if (res) {
      // dispatch({
      //     type: 'UPDATE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const DeleteObjectiveTemplate = (
  ehrID,
  providerID,
  objectiveID
) => async dispatch => {
  try {
    const res = await http.delete(
      `/ehr/${ehrID}/providers/${providerID}/objectives/${objectiveID}`
    );
    if (res) {
      // dispatch({
      //     type: 'UPDATE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const AddObjectiveTopicText = (
  ehrID,
  providerID,
  objective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/objectives/${objective.id}`,
      objective
    );
    if (res) {
      // dispatch({
      //     type: 'ADD_TOPIC_TEXT',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const UpdateObjectiveTopicText = (
  ehrID,
  providerID,
  objective
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/objectives/${objective.id}`,
      objective
    );
    if (res) {
      // dispatch({
      //     type: 'UPDATE_TEMPLATES',
      //     data: res
      // });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

//Assessment&Plan
export const getAssessmentPlanTemplates = (
  ehrID,
  providerID
) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/providers/${providerID}/assessmentplans`
    );
    if (res) {
      dispatch({
        type: 'ASSESSMENTPLAN_TEMPLATES',
        data: res
      });
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const AddAssessmentPlanTemplate = (
  ehrID,
  providerID,
  assessmentplan
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/assessmentplans`,
      assessmentplan
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const UpdateAssessmentPlanTemplate = (
  ehrID,
  providerID,
  assessmentplan
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/assessmentplans/${assessmentplan.id}`,
      assessmentplan
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const DeleteAssessmentPlanTemplate = (
  ehrID,
  providerID,
  assessmentplanID
) => async dispatch => {
  try {
    const res = await http.delete(
      `/ehr/${ehrID}/providers/${providerID}/assessmentplans/${assessmentplanID}`
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const AddAssessmentPlanTopicText = (
  ehrID,
  providerID,
  assessmentplan
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/assessmentplans/${assessmentplan.id}`,
      assessmentplan
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const UpdateAssessmentPlanTopicText = (
  ehrID,
  providerID,
  assessmentplan
) => async dispatch => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/providers/${providerID}/assessmentplans/${assessmentplan.id}`,
      assessmentplan
    );
    if (res) {
      return res;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
