import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  TextField,
  Box,
  Typography,
  Button,
  CircularProgress
} from '@material-ui/core';
import { updatePtIssue } from 'services/ptissues/action';
import validate from 'validate.js';
import schema from './schema';

const PatientIssues = props => {
  const { classes, patientIssuepopupdata, ehrID, providers } = props;
  const [data, setData] = useState([]);
  const [pros, setPros] = useState([]);
  const [poploading, setPopLoading] = useState(false);
  const [valid, setValid] = useState({
    isValid: false,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    if (patientIssuepopupdata !== undefined) {
      setData(patientIssuepopupdata);
      if (patientIssuepopupdata.ptIssue !== '') {
        setValid({ ...valid, isValid: true });
      }
    }
  }, [patientIssuepopupdata]);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providertopush.push({ key: 'Select', value: '' });
      providers.forEach(function(item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const handleFieldChange = (field, value) => {
    setData({ ...data, [field]: value });
    const newState = { ...valid };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(data, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValid(newState);
  };

  const hasError = field =>
    valid.touched && valid.errors && valid.touched[field] && valid.errors[field]
      ? true
      : false;

  const handleUpdatePtIssue = async () => {
    if (valid.isValid) {
      setPopLoading(true);
      const res = await props.updatePtIssue(data);
      if (res) {
        props.onCancel();
      }
      setPopLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Typography className={classes.patientName}>
        {data.patientName}
      </Typography>
      <Box className={classes.modalBody}>
        <TextField
          placeholder="Write patient issues here..."
          multiline
          rows="6"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event => handleFieldChange('ptIssue', event.target.value)}
          value={data.ptIssue}
          error={hasError('ptIssue')}
          helperText={hasError('ptIssue') ? valid.errors.ptIssue[0] : null}
        />
        <TextField
          name="Title"
          select
          style={{ width: '100%' }}
          SelectProps={{ native: true }}
          variant="outlined"
          onChange={event =>
            handleFieldChange('issueReportedBy', event.target.value)
          }
          value={data.issueReportedBy}>
          {pros &&
            pros.map(item => <option value={item.value}>{item.key}</option>)}
        </TextField>
      </Box>
      <Box className={classes.modalFooter}>
        <Button
          onClick={props.onCancel}
          style={{ marginRight: 10, marginTop: 35 }}
          variant="outlined"
          size="small">
          Cancel
        </Button>
        {poploading ? (
          <CircularProgress className={classes.progress} size={20} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={data.ptIssue === undefined}
            style={{ borderRadius: 4, marginTop: 35 }}
            onClick={() => handleUpdatePtIssue()}>
            Save
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
};

PatientIssues.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { updatePtIssue };

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  providers: state.util.providers
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PatientIssues))
);
