export default {
    categoryid: {
      
      presence: { allowEmpty: false, message: 'is required' }
    },
   
    title: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    file: {
      presence: { allowEmpty: false, message: 'is required' }
    }
  };
  