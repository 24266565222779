import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../../style';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { getStates } from 'services/util/action';
import schema from './schema';
import validate from 'validate.js';
import { Prompt } from 'react-router-dom';
import { getOrganizationbyId } from 'services/ehr/action';

const Create = props => {
  const { classes, className, states, ehrID, datapractice, ...rest } = props;

  const [values, setValues] = useState({
    id: 0,
    companyname: '',
    facilityNPI: '',
    primaryPractice: null,
    businessphones: [''],
    faxnumbers: [''],
    address: {
      id: 0,
      address: '',
      city: '',
      state: '',
      zip: ''
    },
    errors: {},
    isValid: false
  });

  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [enablecancel, setEnableCancel] = useState(true);
  let [isBlocking, setIsBlocking] = useState(false);
  const validateForm = () => {
    const newState = { ...values };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
    return newState.isValid;
  };

  useEffect(() => {
    props.getStates();
  }, []);

  useEffect(() => {
    if (datapractice) {
      setValues(datapractice);
      setEnableCancel(false);
    }
  }, [datapractice]);

  const handleAddPhone = () => {
    const pvalues = [...values.businessphones];
    pvalues.push('');
    setValues({ ...values, businessphones: pvalues });
  };

  const handleAddFax = () => {
    const fvalues = [...values.faxnumbers];
    fvalues.push('');
    setValues({ ...values, faxnumbers: fvalues });
  };

  const handleFieldChange = (field, value, isAddress = false) => {
    const newState = { ...values };
    const regex = /^[0-9\b]+$/;
    if (field === 'zip' && value && !regex.test(value)) {
      value = newState[field] || '';
    }
    if (isAddress) {
      let addr = values.address;
      addr[field] = value;
      newState['address'] = addr;
    } else {
      newState[field] = value;
    }
    setValues(newState);
    if (values) {
      setIsBlocking(true);
      setEnableCancel(false);
    }
  };

  const handleFieldChangeforPhone = (idx, field, value) => {
    const newState = { ...values };
    const re = /^[+?0-9\b]+$/;
    if (field === 'businessphones' && value && !re.test(value)) {
      value = newState[field][idx] || '';
    }
    setValues({
      ...values,
      businessphones: Object.assign(
        [],
        { ...values.businessphones },
        { [idx]: value }
      )
    });
    if (values) {
      setIsBlocking(true);
      setEnableCancel(false);
    }
  };

  const handleFieldChangeforFax = (idx, field, value) => {
    const newState = { ...values };
    const re = /^[+?0-9\b]+$/;
    if (field === 'faxnumbers' && value && !re.test(value)) {
      value = newState[field][idx] || '';
    }
    setValues({
      ...values,
      faxnumbers: Object.assign([], { ...values.faxnumbers }, { [idx]: value })
    });
    if (values) {
      setIsBlocking(true);
      setEnableCancel(false);
    }
  };

  const handleCancel = () => {
    setShowCancelPopup(true);
  };

  const handleCancelYes = () => {
    setShowCancelPopup(false);
    clearValues();
    props.getOrganizationbyId(ehrID);
    setEnableCancel(true);
  };

  const clearValues = () => {
    setValues({
      id: 0,
      companyname: '',
      facilityNPI: '',
      primaryPractice: null,
      businessphones: [''],
      faxnumbers: [''],
      address: {
        id: 0,
        address: '',
        city: '',
        state: '',
        zip: '',
        address_Plain: '',
        city_Plain: '',
        zip_Plain: '',
        state_Plain: ''
      }
    });
  };

  const handleCancelNo = () => {
    setShowCancelPopup(false);
  };

  const handleSavePractice = () => {
    if (validateForm()) {
      values.businessphones = values.businessphones.filter(function (el) {
        return el !== '';
      });
      values.faxnumbers = values.faxnumbers.filter(function (el) {
        return el !== '';
      });
      props.geteditValuesofPractice(values);
      setIsBlocking(false);
      setEnableCancel(true);
    }
  };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <Grid container>
        <Prompt
          when={isBlocking}
          message="There are unsaved changes, do you wish to discard them?"
        />
        <Grid
          item
          xs={12}
          className={classes.cardSubHeader}
          style={{ borderTop: 'none' }}>
          <Typography variant="h3">Add/Edit Practice Information</Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="Practice Name"
              name="pName"
              required
              variant="outlined"
              color="Primary"
              error={values.errors && values.errors.companyname}
              value={values.companyname}
              onChange={event =>
                handleFieldChange('companyname', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="Facility NPI Number"
              name="npi"
              variant="outlined"
              color="Primary"
              value={values.facilityNPI}
              onChange={event =>
                handleFieldChange('facilityNPI', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="Street/P.O. Box"
              name="street"
              variant="outlined"
              color="Primary"
              value={values.address && values.address.address}
              onChange={event =>
                handleFieldChange('address', event.target.value, true)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="City"
              name="city"
              variant="outlined"
              color="Primary"
              value={values.address && values.address.city}
              onChange={event =>
                handleFieldChange('city', event.target.value, true)
              }
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.stateList}>
            <TextField
              name="State"
              select
              style={{ width: '100%' }}
              SelectProps={{ native: true }}
              variant="outlined"
              value={values.address && values.address.state}
              onChange={event =>
                handleFieldChange('state', event.target.value, true)
              }>
              {states &&
                states.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="ZIP"
              name="zip"
              variant="outlined"
              color="Primary"
              inputProps={{ maxLength: 5 }}
              value={values.address && values.address.zip}
              onChange={event =>
                handleFieldChange('zip', event.target.value, true)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {values.businessphones && values.businessphones.length > 0 ? (
              values.businessphones.map((field, idx) => {
                return (
                  <TextField
                    style={{ marginTop: 10 }}
                    key={idx}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            variant="contained"
                            aria-label="Phone Number"
                            onClick={() => handleAddPhone()}>
                            <AddBox />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    variant="outlined"
                    color="Primary"
                    inputProps={{ maxLength: 10 }}
                    value={field}
                    onChange={event =>
                      handleFieldChangeforPhone(
                        idx,
                        'businessphones',
                        event.target.value
                      )
                    }
                  />
                );
              })
            ) : (
                <TextField
                  style={{ marginTop: 10 }}
                  key={0}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          variant="contained"
                          aria-label="Phone Number"
                          onClick={() => handleAddPhone()}>
                          <AddBox />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  variant="outlined"
                  color="Primary"
                  inputProps={{ maxLength: 10 }}
                  onChange={event =>
                    handleFieldChangeforPhone(
                      0,
                      'businessphones',
                      event.target.value
                    )
                  }
                />
              )}
          </Grid>
          <Grid item xs={12} md={6}>
            {values.faxnumbers && values.faxnumbers.length > 0 ? (
              values.faxnumbers.map((field, idx) => {
                return (
                  <TextField
                    style={{ marginTop: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            color="primary"
                            variant="contained"
                            aria-label="Fax Number"
                            onClick={() => handleAddFax()}>
                            <AddBox />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      classes: {
                        focused: classes.labelFocused
                      }
                    }}
                    fullWidth
                    label="Fax Number"
                    name="fax"
                    variant="outlined"
                    color="Primary"
                    inputProps={{ maxLength: 10 }}
                    value={field}
                    onChange={event =>
                      handleFieldChangeforFax(
                        idx,
                        'faxnumbers',
                        event.target.value
                      )
                    }
                  />
                );
              })
            ) : (
                <TextField
                  style={{ marginTop: 10 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          variant="contained"
                          aria-label="Fax Number"
                          onClick={() => handleAddFax()}>
                          <AddBox />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  fullWidth
                  label="Fax Number"
                  name="fax"
                  variant="outlined"
                  color="Primary"
                  inputProps={{ maxLength: 10 }}
                  onChange={event =>
                    handleFieldChangeforFax(0, 'faxnumbers', event.target.value)
                  }
                />
              )}
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              style={{ backgroundColor: '#c1c1c1', marginRight: 10 }}
              variant="contained"
              color="secondary"
              disabled={enablecancel}
              onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSavePractice()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showCancelPopup}>
        <DialogTitle id="confirmation-dialog-title">
          Account Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelYes()} color="primary">
            Yes
          </Button>
          <Button onClick={handleCancelNo} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

Create.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getStates,
  getOrganizationbyId
};

const mapStateToProps = state => ({
  states: state.util.states,
  ehrID: state.profile && state.profile.ehrID
  // organization: state.ehr && state.ehr.organization
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
