/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  saveAdvanceDirective,
  updateAdvanceDirective,
  deleteAdvanceDirective
} from 'services/soap/action';

const AdvanceDirective = props => {
  const { classes,  patientId  } = props;

  const initialUserState = {
    id: 0,
    description: ''
  };
  const [values, setValues] = useState(initialUserState);
  const [editvalues, setEditValues] = useState(initialUserState);
  const [AD, setAD] = useState(props.advanceDirectives);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.advanceDirectives && props.advanceDirectives.length > 0) {
      setAD(props.advanceDirectives);
    }
  }, [props.advanceDirectives]);

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleEditFieldChange = (field, value) => {
    setEditValues({ ...editvalues, [field]: value });
  };

  const handleSaveAdvanceDirective = async () => {
    if (values.description) {
      const res = await props.saveAdvanceDirective(patientId, values);
      if (res.status) {
        setAD(res.advanceDirective);
        setValues(initialUserState);
        props.getAdvanceDirectives(res.advanceDirective);
      }
    }
  };

  const handleUpdateAdvanceDirective = async idx => {
    if (editvalues.description) {
      const res = await props.updateAdvanceDirective(
        patientId,
        editvalues
      );
      if (res.status) {
        const newState = [...AD];
        newState[idx] = res.advanceDirective;
        setAD(newState);
        setIsEdit(false);
        props.getAdvanceDirectives(newState);
      }
    }
  };

  const handleDeleteAdvanceDirective = async id => {
    const res = await props.deleteAdvanceDirective(patientId, id);
    if (res.status) {
      setAD(res.advanceDirective);
      setValues(initialUserState);
      props.getAdvanceDirectives(res.advanceDirective);
    }
  };

  const handleEditAdvanceDirective = async (data, index) => {
    setIsEdit(true);
    if (data) {
      setEditValues({
        id: data.id,
        description: data.description,
        editIndex: index
      });
    }
  };

  const clearAdvanceDirective = () => {
    setIsEdit(false);
    setValues(initialUserState);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody} style={{ borderTop: 'default' }}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('description', event.target.value)
                      }
                      placeholder="Input description"
                      value={values.description}
                      variant="outlined"
                    />
                  </TableCell>

                  <TableCell
                    width="10%"
                    align="center"
                    style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      // disabled={values.description || values.reaction}
                      onClick={() => handleSaveAdvanceDirective()}
                      size="small"
                      style={{ color: '#03A484' }}>
                      <CheckOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => clearAdvanceDirective()}
                      size="small">
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AD &&
                  AD.length > 0 &&
                  AD.map((item, idx) => {
                    return (
                      <TableRow>
                        {isEdit && idx === editvalues.editIndex ? (
                          <>
                            <TableCell>
                              <TextField
                                className={classes.textBox}
                                id="outlined-basic"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                onChange={event =>
                                  handleEditFieldChange(
                                    'description',
                                    event.target.value
                                  )
                                }
                                placeholder="Input description"
                                value={editvalues.description}
                                variant="outlined"
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{item.description}</TableCell>
                          </>
                        )}
                        <TableCell align="center" width={72}>
                          {isEdit && idx === editvalues.editIndex ? (
                            <>
                              <IconButton
                                // disabled={values.description || values.reaction}
                                onClick={() =>
                                  handleUpdateAdvanceDirective(idx)
                                }
                                size="small"
                                style={{ color: '#03A484' }}>
                                <CheckOutlined />
                              </IconButton>
                              <IconButton
                                onClick={() => clearAdvanceDirective()}
                                size="small">
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              <IconButton
                                onClick={() =>
                                  handleEditAdvanceDirective(item, idx)
                                }
                                size="small">
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  handleDeleteAdvanceDirective(item.id)
                                }
                                size="small">
                                <DeleteOutline style={{ color: '#DE5555' }} />
                              </IconButton>
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
AdvanceDirective.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  saveAdvanceDirective,
  updateAdvanceDirective,
  deleteAdvanceDirective
};

const mapStateToProps = () => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AdvanceDirective))
);
