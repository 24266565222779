import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from 'views/AccountSettings/style';
import { Grid } from '@material-ui/core';

import { Create, View } from './components';

const UserSettings = props => {
  const { classes, className } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} className={classes.colWrap}>
        <Create />
      </Grid>
      <Grid item xs={12} md={8} className={classes.colWrap}>
        <View />
      </Grid>
    </Grid>
  );
};

UserSettings.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(UserSettings));
