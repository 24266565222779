import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../../style';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { loadOrders, downloadPDF } from 'services/orders/action';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  downloadPDFRx,
} from 'services/soap/action';

const OrderHx = props => {
  const { classes, patientInfo, allorders, ehrID, soap } = props;
  const initialState = {
    loading: true,
    rowsPerPage: 10,
    page: 0
  };
  const [rowsPerPage, setRowsPerPage] = useState(initialState.rowsPerPage);
  const [page, setPage] = useState(initialState.page);
  const [totalElements, setTotalElements] = useState(null);
  const [result, setResult] = useState({});


  useEffect(() => {
    if (patientInfo) {
      props.loadOrders(patientInfo.id, rowsPerPage, page);
    }
  }, [patientInfo]);

  useEffect(() => {
    if (allorders !== undefined) {
      setResult(allorders.content);
      setTotalElements(allorders.totalElements);
    }
  }, [allorders]);



  const handleView = async (type, providerId, orderHx) => {
    var orderIds;
    if (type === 'Prescription') {
      orderIds = orderHx.medIds;

      
      if (orderHx.medIds !== "") {
       
        props
          .downloadPDF(ehrID,
            patientInfo.id,
            providerId, type, orderIds)
          .then(res => {
            if (res.status) {
              let file = new Blob([res.result.data], { type: 'application/pdf' });
              let fileURL = URL.createObjectURL(file);
              window.open(fileURL, '_blank');
              //window.print(fileURL, '_blank');
            }
          });
        return true;
      }
      // else {
      //   props.onCancel()
      // }


    }
    if (type === 'Lab Order') {
      orderIds = orderHx.labOrderIds;
    }
    if (type === 'Imaging Order') {
      orderIds = orderHx.imgOrderIds;
    }
    if (type === 'Physician Order') {
      orderIds = orderHx.phyOrderIds;
    }

    props
      .downloadPDF(
        ehrID,
        patientInfo.id,
        providerId,
        type,
        orderIds
      )
      .then(res => {
        if (res.status) {
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
      });
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    props.loadOrders(patientInfo.id, rowsPerPage, page);
  };

  const handleChangeRowsPerPage = event => {
    const rowsPerPage = event.target.value;
    setRowsPerPage(rowsPerPage);
    props.loadOrders(patientInfo.id, rowsPerPage, page);
  };

  return (
    <React.Fragment>
      <PerfectScrollbar>
        <div style={{ maxHeight: 575 }}>
          <Table className={classes.generalTable}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell style={{ minWidth: 122 }}>Type</TableCell>
                <TableCell style={{ minWidth: 122 }}>Phycisian</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result && result.length > 0 ? (
                result.map(itemOrder => (
                  <TableRow
                    onClick={() =>
                      handleView(
                        itemOrder.type,
                        itemOrder.providerId,
                        itemOrder
                      )
                    }
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell >
                      {itemOrder.createdDate
                        ? moment(itemOrder.createdDate).format('MM/DD/YYYY')
                        : null}
                    </TableCell>
                    <TableCell >{itemOrder.type}</TableCell>
                    <TableCell >
                      {itemOrder.providerName}
                    </TableCell>
                    <TableCell >{itemOrder.recap}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={10}
                    style={{ textAlign: 'center' }}
                  >
                    No records found
                  </TableCell>
                </TableRow>
              )}
             
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      {result && result.length > 0 ? (
                <TablePagination
                  backIconButtonProps={{
                    'aria-label': 'Previous Page'
                  }}
                  className={classes.paginationWrap}
                  
                  component="div"
                  count={totalElements}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page'
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5,10,15]}
                />
              ) : (
                ''
              )}
      <Box className={classes.modalFooter}>
        <Button
          color="primary"
          onClick={props.onCancel}
          size="small"
          style={{ borderRadius: 4 }}
          variant="contained"
        >
          Close
        </Button>
      </Box>
    </React.Fragment>
  );
};

OrderHx.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  loadOrders,
  downloadPDF,
  downloadPDFRx
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result,
  soap: state.soap
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrderHx));
