import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { connect } from 'react-redux';
import Loader from '../../Common/components/Loader'

import moment from 'moment';
import { getUnsignedCharts, getPatientDocuments } from 'services/home/action';

const ChartReview = props => {
  const {
    classes,
    ehrID,
    userID,
    unSignedCharts, 
    documentInfo
  } = props;

  useEffect(() => {
    props.getUnsignedCharts(ehrID, userID);
  }, []);

  const showPatientDocument = async (item, isClinical) => {
    props.handleOpenDoc(
      item.patientid,
      item.patientDocumentId,
      item.routeTo,
      isClinical
    );
  };

  return (
    <>
      <div className={classes.chartReviewWrap}>
        <div className={classes.chartReviewBody}>
        {!unSignedCharts && <Loader type="bullets"/>}
        {unSignedCharts && <Table className={classes.chartReviewTable}>
            <TableHead>
              <TableRow>
                <TableCell>Patient Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell style={{ textAlign: 'center' }}>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody> 
              
                {unSignedCharts.map(item => (
                  <TableRow
                    onClick={() =>
                      item.patientChartID
                        ? props.handleOpenViewSoap(
                          item.patientid,
                          item.patientChartID
                        )
                        : showPatientDocument(item, true)
                    }
                    style={{ cursor: 'pointer' }}>
                    <TableCell> {item.patientname}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {item.createdDate
                        ? moment(item.createdDate).format('MM/DD/YYYY')
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>}
        </div>
      </div>
    </>
  );
};
ChartReview.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getUnsignedCharts,
  getPatientDocuments
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  unSignedCharts: state.home && state.home.unsignedCharts, 
  documentInfo: state.home && state.home.documentInfo
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChartReview))
);
