import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../style';
import {
  Grid,
  Button,
  Modal,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import {
  ChevronLeft,
  DeleteOutlineOutlined,
  GetAppOutlined,
  CloseOutlined
} from '@material-ui/icons';

import { UserDefined, SystemDefined, ImportDB } from './components';
import { connect } from "react-redux";
import {
  uploadFile
} from 'services/icd10/action';

const ICD10 = props => {
  const { classes, ehrID, userID } = props;
  const [userCode, setUserCode] = React.useState([]);
  // const [deleteUserCode, setDeleteUserCode] = React.useState([]);
  const [codeType, setCodeType] = React.useState("");
  const childRef = useRef(null);
  const userChildRef = useRef(null);
  const [handleUploadOpen, setUploadOpen] = React.useState({
    showUploadModal: false
  });
  const openUploadModal = () => {
    setUploadOpen({ showUploadModal: true });
  };

  const closeUploadModal = () => {
    setUploadOpen({ showUploadModal: false });
  };

  const setSelectedUserCodes = (selectedCodes, type) => {
    setCodeType(type);
    // if (type === "user") {
    //   setDeleteUserCode(selectedCodes);
    // }
    // else {
    setUserCode(selectedCodes);
    // }

  }

  const addSelectedICD10 = () => {
    const keyId = codeType === "user" ? userID : ehrID;
    if (userCode.length > 0) {
      var simplecodes = userCode.map(function (item) {
        var icd = item[0];
        if (icd) {
          return { code: icd.code, description: icd.description, keyid: keyId };
        }
        return null;
      });
      userChildRef.current && userChildRef.current.addSelectedICD10(simplecodes);
    }
  }

  const deleteSelectedICD10 = () => {
    if (userCode.length > 0) {
      if (codeType === "user") {
        var simplecodes = userCode.map(function (item) {
          var icd = item[0];
          if (icd) {
            return icd.id;
          }
          return null;
        });
        userChildRef.current && userChildRef.current.deleteSelectedICD10(simplecodes);

      }
      else {
        var simplecodes = userCode.map(function (item) {
          var icd = item[0];
          return icd.id;
        });
        childRef.current && childRef.current.deleteSelectedICD10(simplecodes);
      }
    }
  }

  const handleFileUpload = async (data) => {
    let res = await props.uploadFile(ehrID, data);
    if (res) {
      if (data.icdType === "user") {
        userChildRef.current && userChildRef.current.loadUserICD10();
      }
      if (data.icdType === "system") {
        childRef.current && childRef.current.loadSystemICD10();
      }
    }
  };

  return ( 
      <Grid container spacing={3} className={classes.icdWrap}>
        <Grid item xs={12}>
          <p className={classes.tabLabel}>ICD10 Database</p>
        </Grid>
        <Grid item xs={12} md={5} className={classes.icdLeft}>
          <UserDefined ref={userChildRef} setSelectedUserCodes={setSelectedUserCodes} />
        </Grid>
        <Grid item xs={12} md={2} className={classes.icdCenter}>
          <div className={classes.icdCtrls}>
            <Button
              title="Add"
              // disabled={userCode.length === 0} 
              variant="contained" className={classes.icdCtrlBtn1}
              onClick={addSelectedICD10}
            >
              <ChevronLeft />
            </Button>
            <Button
              title="Remove"
              // disabled={deleteUserCode.length === 0} 
              variant="contained" className={classes.icdCtrlBtn2}
              onClick={deleteSelectedICD10}>
              <DeleteOutlineOutlined />
            </Button>
            <Button
              title="Import"
              onClick={openUploadModal}
              color="primary"
              variant="contained"
              className={classes.icdCtrlBtn3}>
              <GetAppOutlined />
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} md={5} className={classes.icdRight}>
          <SystemDefined ref={childRef}
            setSelectedUserCodes={setSelectedUserCodes}
          />
        </Grid>
        <Modal
         disableBackdropClick
          open={handleUploadOpen.showUploadModal}
          onClose={closeUploadModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Box className={classes.modalWrap} style={{ width: 650 }}>
            <Box className={classes.modalHead}>
              <Typography variant="h2">ICD10 Database Uploader</Typography>
              <IconButton
                aria-label="delete"
                className={classes.modalClose}
                onClick={closeUploadModal}>
                <CloseOutlined />
              </IconButton>
            </Box>
            <ImportDB onCancel={closeUploadModal} handleFileUpload={handleFileUpload} />
          </Box>
        </Modal>
      </Grid> 
  );
};

ICD10.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  uploadFile
};
const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ICD10));
