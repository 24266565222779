export default (state = {}, action) => {
  switch (action.type) {
    case 'REGISTER':
      return {
        ...state,
        uid: { ...action.uid }
      };
    case 'ACCOUNT_USER_REGISTER':
      return {
        ...state,
        uid: { ...action.uid }
      };
    case 'USERS':
      return {
        ...state,
        users: action.data
      };
    case 'USER_GET':
      return {
        ...state,
        search: { result: copyArray(action.data) }
      };
    case 'PROVIDER_GET':
      return {
        ...state,
        providerresource: { ...action.data }
      };
    // case "LOGIN_SUCCESS":
    //   const { name, roles, userID, firstname, lastname, title, role } = action.data

    //   return {
    //     ...state,
    //     profile: { name, roles, userID, firstname, lastname, title, role }
    //   };

    // case "LOGIN_FAILURE":
    //   return {
    //     ...state,
    //     profile: null
    //   };

    case 'LOGOUT':
      return {};

    default:
      return state;
  }
};

const copyArray = items => {
  return items.map(item => {
    return Object.assign({}, item);
  });
};
