import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Box,
  Hidden,
  IconButton,
  withStyles,
  Button,
  Typography,
  InputBase,
  Badge,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AddCircleOutlineOutlined,
  AccountCircleOutlined,
  ExpandMoreOutlined,
  SearchOutlined,
  ExitToAppOutlined,
  SettingsOutlined
} from '@material-ui/icons/';
import styles from './style';
import moment from 'moment';
import MessageBox from 'util/messageBox';
import logo from '../../../../assets/images/logo.png';
import { logout } from '../../../../services/profile/action';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPhyPtIssues } from 'services/ptissues/action';
import { getAllPhyPtMessages } from 'services/ptmessages/action';
import { GlobalSearch, PatientAdd } from 'views/Common/components/';
import { addPatientfromPtSchedule } from 'services/patient/action';
import { getOrganizationbyId } from 'services/ehr/action';
import { getCaseFiles } from 'services/home/action';
import { resetValue } from 'services/soap/action';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 0,
    borderColor: '#3397F3',
    '& ul': {
      padding: 0
    },
    '& svg': {
      width: 18,
      height: 18
    },
    '& .MuiListItemIcon-root': {
      minWidth: '24px!important'
    }
  }
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white
    //   }
    // },
    '& .MuiListItemIcon-root': {
      minWidth: 30
    }
  },
  ListItemIcon: {}
}))(MenuItem);

const Topbar = props => {
  const {
    className,
    onSidebarOpen,
    classes,
    ehrID,
    allPhyPtMessages,
    allPhyPtIssues,
    organization,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuActive, setMenuState] = useState(null);
  const [incompeltePtMessageCount, setIncompeltePtMessageCount] = useState(0);
  const [incompeltePtIssueCount, setIncompeltePtIssueCount] = useState(0);
  const [openAddPatient, setOpenAddPatient] = useState({
    showPopUp: false,
    name: ''
  });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [formstate, setFormstate] = useState({});
  const [poploading, setpoploading] = useState(false);
  const [openAddExistingPatient, setOpenAddExistingPatient] = useState({
    showPopUp: false,
    patient: null
  });

  useEffect(() => {
    props.getAllPhyPtMessages(ehrID);
    props.getAllPhyPtIssues(ehrID);
    props.getOrganizationbyId(ehrID);
  }, []);

  useEffect(() => {
    if (
      props.allPhyPtMessages &&
      props.allPhyPtMessages.content !== undefined
    ) {
      allPhyPtMessages.content.forEach(function(item) {
        if (item.providerResponse === null || item.status === 1) {
          let i = incompeltePtMessageCount + 1;
          setIncompeltePtMessageCount(i);
        }
      });
    }
  }, [props.allPhyPtMessages]);

  useEffect(() => {
    if (props.allPhyPtIssues && props.allPhyPtIssues.content !== undefined) {
      props.allPhyPtIssues.content.forEach(function(item) {
        if (item.providerResponse === null || item.status === 1) {
          let i = incompeltePtMessageCount + 1;
          setIncompeltePtIssueCount(i);
        }
      });
    }
  }, [props.allPhyPtIssues]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMsg = () => {
    setSuccMsg({ succ: false, succMsg: '' });
  };

  const handleSignOut = () => {
    props.logout(ehrID);
    const { history } = props;
    history.push('/sign-in');
  };

  const handleClass = menu => {
    setMenuState(menu);
  };

  //#region Add Patient

  const handleOpenAddPatient = search => {
    setOpenAddPatient({ showPopUp: true, name: search });
  };

  const handleCloseAddPatient = async () => {
    setOpenAddPatient({ showPopUp: false });
  };

  const handleSave = async (patient, isExisting = false) => {
    const newState = { ...patient };
    if (newState.practice && isExisting) {
      newState.practice =
        Object.keys(newState.practice).length > 0
          ? JSON.parse(newState.practice)
          : undefined;
    }
    const status = await props.addPatientfromPtSchedule(ehrID, newState);
    if (status) {
      setSuccMsg({ succ: true, succMsg: 'Patient created successfully' });
      setFormstate({
        ...formstate,
        patientId: status.data.id,
        patientName: status.data.firstname + ' ' + status.data.lastname,
        emailaddress: status.data.emailaddress
      });
      setOpenAddPatient({ showPopUp: false });
      props.getCaseFiles(ehrID);
    } else {
      setpoploading(false);
      setOpenAddPatient({ showPopUp: true });
    }
  };

  const handleAddExistingCancel = () => {
    setOpenAddExistingPatient({ showPopUp: false });
  };

  const handleAddExistingPatient = item => {
    setOpenAddExistingPatient({ showPopUp: true, patient: item });
  };

  const handleAddExistingOK = async () => {
    setpoploading(true);
    if (openAddExistingPatient.patient) {
      await handleSave({ ...openAddExistingPatient.patient, id: 0 }, true);
    }
    setOpenAddExistingPatient({ showPopUp: false });
    setpoploading(false);
  };

  const handleOpenSoap = async () => {
    await props.resetValue();
    await props.history.push({
      pathname: '/default',
      state: { value: 0 }
    });
  };

  return (
    <AppBar {...rest} className={classes.header}>
      <Toolbar className={classes.headerWrap}>
        <div className={classes.logoWrap}>
          <RouterLink onClick={() => handleOpenSoap()}>
            <img alt="Logo" className={classes.logo} src={logo} />
          </RouterLink>
        </div>
        <div className={classes.naviWrap}>
          <div className={classes.naviTop}>
            <Hidden smDown>
              <Box className={classes.naviTopC2a}>
                <Button
                  className={classes.c2aBtn}
                  color="secondary"
                  onClick={() => handleOpenAddPatient()}
                  size="small"
                  startIcon={<AddCircleOutlineOutlined />}
                  variant="contained">
                  Add Patient
                </Button>
              </Box>
              <Box className={classes.naviTopTit}>
                <Typography variant="h4">
                  {organization && organization.organizationname}
                </Typography>
              </Box>
            </Hidden>
            <Box className={classes.naviTopAcc}>
              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                className={classes.c2aBtn}
                color="inherit"
                endIcon={<ExpandMoreOutlined />}
                onClick={handleClick}
                size="small"
                startIcon={<AccountCircleOutlined />}
                variant="text">
                Account
              </Button>
              <StyledMenu
                anchorEl={anchorEl}
                id="customized-menu"
                keepMounted
                onClose={handleClose}
                open={Boolean(anchorEl)}>
                <StyledMenuItem
                  component={RouterLink}
                  onClick={handleClose}
                  to="/account-settings">
                  <ListItemIcon>
                    <SettingsOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Account Settings" />
                </StyledMenuItem>
                <StyledMenuItem
                  component={RouterLink}
                  onClick={handleClose}
                  to="/sign-in">
                  <ListItemIcon>
                    <ExitToAppOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText onClick={handleSignOut} primary="Logout" />
                </StyledMenuItem>
              </StyledMenu>
            </Box>
          </div>
          <div className={classes.naviBot}>
            <Box className={classes.naviSearchWrap}>
              <GlobalSearch isGlobal={true} />
            </Box>
            <Box className={classes.naviLinkWrap}>
              <Hidden smDown>
                <ul className={classes.naviLinks}>
                  <li>
                    {incompeltePtIssueCount > 0 ? (
                      <RouterLink
                        className={menuActive === 'ptissue' ? 'active' : ''}
                        onClick={() => handleClass('ptissue')}
                        to="/pt-issues">
                        <Badge color="secondary" variant="dot">
                          PT Issues
                        </Badge>
                      </RouterLink>
                    ) : (
                      <RouterLink
                        className={menuActive === 'ptissue' ? 'active' : ''}
                        onClick={() => handleClass('ptissue')}
                        to="/pt-issues">
                        PT Issues
                      </RouterLink>
                    )}
                  </li>
                  <li>
                    <RouterLink
                      className={menuActive === 'ptschedule' ? 'active' : ''}
                      onClick={() => handleClass('ptschedule')}
                      to="/pt-schedule">
                      PT Schedule
                    </RouterLink>
                  </li>
                  <li>
                    {incompeltePtMessageCount > 0 ? (
                      <RouterLink
                        className={menuActive === 'ptmessage' ? 'active' : ''}
                        onClick={() => handleClass('ptmessage')}
                        to="/pt-message">
                        <Badge color="secondary" variant="dot">
                          PT Message
                        </Badge>
                      </RouterLink>
                    ) : (
                      <RouterLink
                        className={menuActive === 'ptmessage' ? 'active' : ''}
                        onClick={() => handleClass('ptmessage')}
                        to="/pt-message">
                        PT Message
                      </RouterLink>
                    )}
                  </li>
                  <li>
                    <RouterLink
                      className={menuActive === 'templates' ? 'active' : ''}
                      onClick={() => handleClass('templates')}
                      to="/templates">
                      Templates
                    </RouterLink>
                  </li>
                  <Hidden mdDown>
                    <li>
                      <Typography className={classes.timeStamp}>
                        {moment().format('DD MMMM YYYY, h:mm a')}
                      </Typography>
                    </li>
                  </Hidden>
                </ul>
              </Hidden>
              <Hidden mdUp>
                <IconButton color="inherit" onClick={onSidebarOpen}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Box>
          </div>
          <Modal
            aria-describedby="simple-modal-description"
            aria-labelledby="simple-modal-title"
            disableBackdropClick
            onClose={handleCloseAddPatient}
            open={openAddPatient.showPopUp}>
            <PatientAdd
              cancelExistingPatient={handleAddExistingCancel}
              handleAddExistingPatient={handleAddExistingPatient}
              handleSave={handleSave}
              onCancel={handleCloseAddPatient}
              searchText={openAddPatient.name}
            />
          </Modal>

          <MessageBox
            message={succmsg.succMsg}
            onClose={handleCloseMsg}
            open={succmsg.suhandleCloseMsgcc}
            variant="success"
          />

          <MessageBox
            message={formstate.errMsg}
            onClose={handleClose}
            open={formstate.err}
            variant="error"
          />

          {/* Start Dialogue for Add Existing Patient */}
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={openAddExistingPatient.showPopUp}>
            <DialogTitle id="confirmation-dialog-title">
              Patient Save Information
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to proceed ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {poploading ? (
                <CircularProgress className={classes.progress} size={20} />
              ) : (
                <Button color="primary" onClick={() => handleAddExistingOK()}>
                  OK
                </Button>
              )}
              <Button color="primary" onClick={handleAddExistingCancel}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Dialogue for Add Existing Patient */}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

const mapDispatchToProps = {
  logout: logout,
  getAllPhyPtMessages: getAllPhyPtMessages,
  getAllPhyPtIssues: getAllPhyPtIssues,
  addPatientfromPtSchedule: addPatientfromPtSchedule,
  getOrganizationbyId: getOrganizationbyId,
  getCaseFiles: getCaseFiles,
  resetValue: resetValue
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  organization: state.ehr && state.ehr.organization,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allPhyPtIssues:
    state.ptissues &&
    state.ptissues.allPhyPtIssues &&
    state.ptissues.allPhyPtIssues.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Topbar))
);
