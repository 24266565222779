/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withStyles } from '@material-ui/styles';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import styles from '../../../style';
import { SoapView, MHx } from './'; 
import {  
  getPastSoapNoteDetails,
  deleteSoap,
  saveSOAPPatientData,
  getPatientInfo, 
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
  getPastSoapNoteById
} from 'services/soap/action';   
import {  
  getCaseFiles
} from 'services/home/action';  
import { Roles } from 'util/enum';
import { connect } from 'react-redux';

const PastSoapSummary = props => {
  const {
    classes, 
    patientInfo,
    ptActiveMedication,
    ptInactiveMedication,
    ptMedicalHistory,
    ptPastDiagnosis, 
    ehrID,
    userID,
    pastSoapNoteDetails,  
    patientId,
    role, 
    getCaseFiles,
    chartSoapPatientDetails,
    getPastSoapNoteById,
    page, 
  } = props;

  const [soap, setSoap] = useState(null);

  useEffect(()=>{
    setSoap(props.soap)
  },[props.soap])

  useEffect(()=>{
    if(chartSoapPatientDetails && chartSoapPatientDetails.soap && !props.soap)
    {
      setSoap(chartSoapPatientDetails.soap)
    } 
  },[chartSoapPatientDetails])

  useEffect(() => {
   if(soap){  
    props.getPastSoapNoteDetails(
      ehrID,
      soap.patient.id,
      userID,
      soap.soapnote.id
    ); 
  }
  }, [soap]);

  const handleCopyPast = () => {
    props.handleCopyPast(soap, pastSoapNoteDetails);
  };

  const handleEditPast = () => {
    props.handleEditPast(soap, pastSoapNoteDetails);
  };

  const handleSign = async () => {
    let newState = { ...soap };
    newState.soapnote.signed = true;
    newState.soapnote.signedby = userID;
    newState.soapnote.signedbydate = new Date();
    newState.signed = true;
    const res = await props.saveSOAPPatientData(
      ehrID,
      userID,
      patientId,
      true,
      newState,
      true
    );
    props.onCancel();
    if (res.status) {
      getPastSoapNoteById(patientId, page) 
      const newRes = await props.getPastSoapNoteDetails(
        ehrID,
        patientId,
        userID,
        res.soapId.soapnote.id
      );
      if (newRes) {
        setSoap(res.soapId);
        setTimeout(() => {
          getCaseFiles(ehrID); 
        }, 3000);
        
      }
      
    }
   
  };

  //#region Delete Soap
  const [opendelete, setOpenDelete] = useState(false);

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleDeleteOK = async () => {
    const res = await props.deleteSoap(ehrID, userID, soap.patient.id, soap.id);
    if (res) {
      getPastSoapNoteById(patientId, page)
      await props.showSuccessMsg('Soap deleted successfully');
    }
    props.onCancel();
  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };

  const renderDeleteDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={opendelete}>
        <DialogTitle id="confirmation-dialog-title">
          Delete Soap Note
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and delete the soap note ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleDeleteOK()}>
            OK
          </Button>
          <Button color="primary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  return (
    <Grid container spacing={3}>
      {role !== Roles.patient ? (
        <span>
          <Grid container spacing={3}>
            <Grid
              item
              style={{ paddingRight: 0 }}
              xs={!props.isHistory ? 7 : 12}>
              <PerfectScrollbar>
                <div
                  style={{ height: '70vh', maxHeight: 615, paddingRight: 10 }}>
                  <SoapView
                    cptList={pastSoapNoteDetails && pastSoapNoteDetails.cptList}
                    diagnosisList={
                      pastSoapNoteDetails && pastSoapNoteDetails.diagnosisList
                    }
                    ehrID={ehrID}
                    imagingOrders={
                      pastSoapNoteDetails && pastSoapNoteDetails.imagingOrders
                    }
                    labOrders={
                      pastSoapNoteDetails && pastSoapNoteDetails.labOrders
                    }
                    pastSoapNoteDetails={pastSoapNoteDetails}
                    patientId={  patientInfo && patientInfo.id}
                    physicianOrders={
                      pastSoapNoteDetails && pastSoapNoteDetails.physicianOrders
                    }
                    prescriptions={
                      pastSoapNoteDetails && pastSoapNoteDetails.prescriptions
                    }
                    setHistory={props.setHistory}
                    soap={soap}
                    userID={userID}
                  />
                </div>
              </PerfectScrollbar>
            </Grid>

            {!props.isHistory ? (
              <Grid item style={{ paddingLeft: 0 }} xs={5}>
                <PerfectScrollbar>
                  <div
                    className={classes.MHx}
                    style={{
                      height: '70vh',
                      maxHeight: 615,
                      paddingRight: 10
                    }}>
                    <MHx
                      additionalItems={ptMedicalHistory && ptMedicalHistory.additionalItems}
                      advanceDirectives={ptMedicalHistory && ptMedicalHistory.advanceDirective}
                      allergies={ptMedicalHistory && ptMedicalHistory.allergies}
                      cpt={ptPastDiagnosis && ptPastDiagnosis.length > 0 && ptPastDiagnosis.filter(c => c.codeType === 'cpt') || []}
                      diagnosis={ptPastDiagnosis && ptPastDiagnosis.length > 0 && ptPastDiagnosis.filter(c => c.codeType === 'diagnosis')|| []}
                      familyHistory={ptMedicalHistory && ptMedicalHistory.familyHistory}
                      healthMaintenance={ptMedicalHistory && ptMedicalHistory.healthMaintenance}
                      inactivemedication={ptInactiveMedication}
                      isViewSoap
                      medication={ptActiveMedication}
                      ptInfo={patientInfo}
                      setHistory={props.setHistory}
                      socialHistory={ptMedicalHistory && ptMedicalHistory.socialHistory}
                      vaccinations={ptMedicalHistory && ptMedicalHistory.vaccinations}
                    />
                  </div>
                </PerfectScrollbar>
              </Grid>
            ) : null}
          </Grid>
        </span>
      ) : (
        <Grid>
          <PerfectScrollbar>
            <div style={{ height: '70vh', maxHeight: 615, paddingRight: 10 }}>
              <SoapView
                cptList={pastSoapNoteDetails && pastSoapNoteDetails.cptList}
                diagnosisList={
                  pastSoapNoteDetails && pastSoapNoteDetails.diagnosisList
                }
                ehrID={ehrID}
                imagingOrders={
                  pastSoapNoteDetails && pastSoapNoteDetails.imagingOrders
                }
                labOrders={pastSoapNoteDetails && pastSoapNoteDetails.labOrders}
                pastSoapNoteDetails={pastSoapNoteDetails}
                patientId={patientInfo &&  patientInfo.id}
                physicianOrders={
                  pastSoapNoteDetails && pastSoapNoteDetails.physicianOrders
                }
                prescriptions={
                  pastSoapNoteDetails && pastSoapNoteDetails.prescriptions
                }
                setHistory={props.setHistory}
                soap={soap}
                userID={userID}
              />
            </div>
          </PerfectScrollbar>
        </Grid>
      )}

      <Grid
        className={classes.soapEntryAction}
        style={{ justifyContent: 'space-between', padding: '20px!important' }}
        xs={12}>
        <Typography>
          {soap && (soap.routed && soap.signed
            ? 'Signed by : ' +
              (soap.provider.firstname ? soap.provider.firstname : '') +
              ' ' +
              (soap.provider.lastname ? soap.provider.lastname : '')
            : soap.routed && !soap.signed
            ? 'Routed to : ' +
              (soap.provider.firstname ? soap.provider.firstname : '') +
              ' ' +
              (soap.provider.lastname ? soap.provider.lastname : '')
            : !soap.routed && !soap.signed
            ? 'Created by : ' +
              (soap.provider.firstname ? soap.provider.firstname : '') +
              ' ' +
              (soap.provider.lastname ? soap.provider.lastname : '')
            : !soap.routed && soap.signed
            ? 'Signed by : ' +
              (soap.provider.firstname ? soap.provider.firstname : '') +
              ' ' +
              (soap.provider.lastname ? soap.provider.lastname : '')
            : '')}
        </Typography>

        {role !== Roles.staff && role !== Roles.patient && (
          <span>
            <Button
              color="primary"
              onClick={() => handleCopyPast()}
              size="small"
              style={{ backgroundColor: '#03A484', marginRight: 5 }}
              variant="contained">
              Copy
            </Button>
{soap && !soap.signed ?
            <Button
              color="primary"
              onClick={() => handleEditPast()}
              size="small"
              variant="contained">
                Edit
             
            </Button>
            :null
        }
            {soap && (!soap.signed && (
              <>
                <Button
                  color="primary"
                  onClick={() => handleOpenDelete()}
                  size="small"
                  style={{ backgroundColor: '#b9b9b9', margin: '0 5px' }}
                  variant="contained">
                  Delete
                </Button>
                <Button
                  color="secondary"
                  onClick={() => handleSign()}
                  size="small"
                  variant="contained">
                  Sign
                </Button>
              </>
            ))}
          </span>
        )}
        {opendelete && renderDeleteDialogue()}
      </Grid>
    </Grid>
  );
};
const mapDispatchToProps = {
  getPastSoapNoteDetails,
  deleteSoap, 
  saveSOAPPatientData, 
  getPatientInfo, 
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
  getCaseFiles,
  getPastSoapNoteById
};

const mapStateToProps = state => ({
  role: state.profile && state.profile.role,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  subjectiveTemplates: state.providers && state.providers.subjectiveTemplates,
  objectiveTemplates: state.providers && state.providers.objectiveTemplates,
  assessmentPlanTemplates: state.providers && state.providers.assessmentPlanTemplates,
  pastSoapNoteDetails: state.soap && state.soap.pastSoapNoteDetails, 
  ptInactiveMedication: state.soap && state.soap.ptInactiveMedication,
  ptActiveMedication: state.soap && state.soap.ptActiveMedication,
  ptMedicalHistory: state.soap && state.soap.ptMedicalHistory,
  ptPastDiagnosis: state.soap && state.soap.ptPastDiagnosis,
  patientInfo: state.soap && state.soap.patientInfo, 
  chartSoapPatientDetails: state.home && state.home.chartSoapPatientDetails
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PastSoapSummary))
);
