export default (state = {}, action) => {
  switch (action.type) {
    case 'ADD_PATIENT':
      return {
        ...state,
        uid: { ...action.uid }
      };
    case 'PATIENT_GET':
      const {
        content,
        totalPages,
        totalElements,
        last,
        size,
        sort,
        first,
        numberOfElements
      } = action.data;
      return {
        ...state,
        list: {
          result: copyArray(content),
          totalPages,
          totalElements,
          last,
          size,
          sort,
          first,
          numberOfElements
        }
      };

    case 'PATIENT_SEARCH':
      //const { content, totalPgs, totalElmnts, lst, sze, srt, frst, numberOfElmnts } = action.data
      return {
        ...state,
        search: { result: copyArray(action.data.content) }
      };
    case 'LIST_SEARCH_PATIENTS':
      //const { content, totalPgs, totalElmnts, lst, sze, srt, frst, numberOfElmnts } = action.data
      return {
        ...state,
        searchList: { result: copyArray(action.data.content) }
      };

    default:
      return state;
  }
};

const copyArray = items => {
  return items.map(item => {
    return Object.assign({}, item);
  });
};
