import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './style';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';

const Create = props => {
  const {
    classes,
    isptMessages,
    isptSchedules,
    allPhyPtMessages,
    allPhyPtIssues,
    seldate,
    isWeekly
  } = props;
  const [value, setValue] = useState(undefined);

  useEffect(() => {
    if (seldate) {
      setValue(new Date(seldate));
    }
  }, [seldate]);

  const onClickDay = date => {
    setValue(date);
    if (isptMessages) {
      props.handleDateChange(moment(date).format('MM/DD/YYYY'));
    } else if (isptSchedules) {
      props.handleDateChangeptSchedule(
        moment(date).format('MM/DD/YYYY'),
        isWeekly
      );
    } else {
      props.handleDateChangeptIssue(moment(date).format('MM/DD/YYYY'));
    }
  };

  const tileClassName = tileDtl => {
    let classes = [];
    if (isptMessages) {
      if (props.allPhyPtMessages !== undefined) {
        allPhyPtMessages.content.forEach(function(item) {
          if (item.providerResponse == null) {
            if (
              moment(tileDtl.date).format('MM/DD/YYYY') ===
              moment(item.updatedDate).format('MM/DD/YYYY')
            ) {
              classes.push('react-calendar__tile--danger');
            }
          }
        });
      }
    } else if (isptSchedules) {
      if (
        moment(tileDtl.date).format('MM/DD/YYYY') ===
        moment(seldate).format('MM/DD/YYYY')
      ) {
        classes.push('react-calendar__tile--active');
      }
    } else {
      if (props.allPhyPtIssues && props.allPhyPtIssues.content !== undefined) {
        props.allPhyPtIssues.content.forEach(function(item) {
          if (item.providerResponse == null) {
            if (
              moment(tileDtl.date).format('MM/DD/YYYY') ===
              moment(item.updatedDate).format('MM/DD/YYYY')
            ) {
              classes.push('react-calendar__tile--danger');
            }
          }
        });
      }
    }
    return classes;
  };

  return (
    <div className={classes.dateWrap}>
      <div className={classes.dateHead}>
        <Typography> {'Date: ' + moment().format('MM/DD/YYYY')}</Typography>
      </div>
      <div className={classes.dateBody}>
        <Calendar
          calendarType="US"
          value={value}
          // activeStartDate={
          //   value && new Date(value.getFullYear(), value.getMonth(), 1)
          // }
          onClickDay={onClickDay}
          onActiveStartDateChange={({ activeStartDate, value, view }) =>
            setValue(value)
          }
          tileClassName={tileClassName}
        />
      </div>
    </div>
  );
};
Create.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
