/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../../../SignIn/style';
import { LockOpenOutlined } from '@material-ui/icons';
import validate from 'validate.js';
import schema from './schemaActivateAccount';
import {
  TextField,
  Button,
  Typography,
  withStyles} from '@material-ui/core';
import {
  EHRresetpassword,
  EHRresetpasswordsubmit,
  getUserByUsername
} from 'services/user/action';

import { login } from 'services/profile/action';

import { connect } from 'react-redux';

const Passwordreset = props => {
  const { classes } = props;

  const [values, setValues] = useState({
    token: '',
    username: '',
    password: '',
    confirmpassword: '',
    succMsg: false,
    touched: {},
    errors: {},
    isValid: false
  });

  useEffect(() => {
    // let ntoken =
    //   props.history && props.history.location && props.history.location.search ;
    // ntoken = ntoken.slice(7);
    // setValues({...values,token:ntoken});
     fetchUser();
  }, []);

  const parseQueryString = url => {
    var objURL = {};
    url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), function(
      _$0,
      $1,
      _$2,
      $3
    ) {
      objURL[$1] = $3;
    });
    return objURL;
  };

  const hasError = field =>
    values &&
    values.touched &&
    values.errors &&
    values.touched[field] &&
    values.errors[field]
      ? true
      : false;

  const fetchUser = async () => {
    let url = window.location.href;
    var params = parseQueryString(url);
    const res = await props.EHRresetpassword(params['token']);
    if (res.status === 200) {
      let newurl = res.data;
      var loginparams = parseQueryString(newurl);
      if (loginparams['username']) {
        // const userRes = await props.getUserByUsername(
        //   loginparams['ehrid'],
        //   loginparams['username']
        // );
        // if(userRes.status){
        // const loginStatus = await props.login(
        //   userRes.data.username,
        //   userRes.data.password,
        //   false,
        //   true
        // );
        // if (userRes.status) {
        setValues({
          ...values,
          username: loginparams['username'],
          ehrid: loginparams['ehrid'],
          token: params['token']
        });
        //   }
        // }
      }
    }
  };

  const handleFieldChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
    if (values) {
      // setIsBlocking(true);
    }
  };
  const validateForm = () => {
    const newState = { ...values };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);

    return newState.isValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      let newState = {
        username: values.username,
        password: btoa(values.password),
        confirmpassword: values.password,
        ehrid:values.ehrid,
        token:values.token
      };
      props.getUserByUsername(values.ehrid,values.username).then(res=>{
        if(res){
          props.history.push({
            pathname: '/terms',
            state: { data: newState }
          });
        }
      })
      
    }

    // setIsBlocking(false);
    // props.onCancel();
  };

  return ( 
      <span>
        <div
          className={classes.contentBodyMsg}
          style={{ position: 'relative' }}
        >
          <div className={classes.contentHeader}>
            <LockOpenOutlined />
            <Typography variant="h1">EHR Password Reset</Typography>
          </div>
          <div className={classes.contentInner}>
            <Typography variant="h6">User Name</Typography>
            <div style={{ marginBottom: 5 }}>
              <TextField
                color="Primary"
                disabled
                InputLabelProps={{
                  classes: {
                    focused: classes.labelFocused
                  }
                }}
                label="Username"
                name="uName"
                required
                style={{ width: '550px' }}
                value={values.username}
                variant="outlined"
              />
              <div style={{ marginTop: 20 }}>
                <TextField
                  color="Primary"
                  error={values.errors && values.errors.password}
                  helperText={
                    hasError('password') ? values.errors.password[0] : null
                  }
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  label="New Password"
                  name="npw"
                  onChange={event =>
                    handleFieldChange('password', event.target.value)
                  }
                  required
                  style={{ width: '550px' }}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: 20 }}>
                <TextField
                  color="Primary"
                  error={values.errors && values.errors.confirmpassword}
                  helperText={
                    hasError('confirmpassword')
                      ? values.errors.confirmpassword[0]
                      : null
                  }
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  label="Confirm New Password"
                  name="cnpw"
                  onChange={event =>
                    handleFieldChange('confirmpassword', event.target.value)
                  }
                  required
                  style={{ width: '550px' }}
                  type="password"
                  value={values.confirmpassword}
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: '20px' }}>
                <Button
                  color="primary"
                  onClick={() => handleSubmit()}
                  variant="contained"
                >
                  Activate account
                </Button>
              </div>
            </div>
          </div>
        </div>
      </span> 
  );
};

Passwordreset.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  EHRresetpassword,
  EHRresetpasswordsubmit,
  login,
  getUserByUsername
};

const mapStateToProps = _state => ({
  //user:state.user
  // titles: state.util.titles,
  //ehrID: state.profile && state.profile.ehrID,
  // // userID: state.profile && state.profile.userID,
  //username: state.profile && state.profile.name
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Passwordreset))
);
