import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal
} from '@material-ui/core';
import moment from 'moment';
import { ScheduleQuickView } from './';
import { extendMoment } from 'moment-range';
import { ptScheduleListTimeElapsed } from 'util/helper';
import { connect } from 'react-redux';
import { getAllPhyPtSchedules } from 'services/ptschedules/action';

const PTList = props => {
  const { classes, data, seldate, ehrID } = props;
  const [ptScheduleList, setPtScheduleList] = useState(null);
  // const [timeFrom, setTimeFrom] = useState(null);
  // const [duration, setDuration] = useState(null);
  const [slots, setSlots] = useState(null);
  const [weekDate, setWeekDate] = useState(null);
  const [openQV, setOpenQV] = React.useState({ showQV: false, item: null });
  const momentRange = extendMoment(moment);

  const loadInitialData = async () => {
    // await loadTimeSlots();
    // await loadDuration();
    await loadHours();
    await getWeekDates();
  };

  const handleOpenQV = item => {
    setOpenQV({ showQV: true, item: item });
  };
  const handleCloseQV = () => {
    setOpenQV({ showQV: false });
  };

  useEffect(() => {
    if (ehrID) {
      loadInitialData();
    }
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && slots && slots.length > 0) {
      const ptScheduleList = groupPtSchedule(data);
      setPtScheduleList(ptScheduleList);
    }
  }, [data, slots]);

  useEffect(() => {
    if (seldate) {
      getWeekDates();
    }
  }, [seldate]);

  const getWeekDates = () => {
    let dateArr = [];
    let startDate = moment(seldate)
      .startOf('week')
      .format('MM/DD/YYYY');
    let endDate = moment(seldate)
      .endOf('week')
      .format('MM/DD/YYYY');
    let range = momentRange().range(startDate, endDate);
    let array = Array.from(range.by('days'));
    array.map(date => dateArr.push(moment(date).format('MM/DD/YYYY')));
    setWeekDate(dateArr);
  };

  // const loadTimeSlots = () => {
  //   let time = [{ key: 'From', value: '' }];
  //   for (let i = 480; i <= 1380; i += 15) {
  //     let hours, minutes, ampm;
  //     hours = Math.floor(i / 60);
  //     minutes = i % 60;
  //     if (minutes < 10) {
  //       minutes = '0' + minutes; // adding leading zero
  //     }
  //     ampm = hours % 24 < 12 ? 'AM' : 'PM';
  //     hours = hours % 12;
  //     if (hours === 0) {
  //       hours = 12;
  //     }
  //     var slot = hours + ':' + minutes + ' ' + ampm;
  //     time.push({ key: slot, value: slot });
  //   }
  //   setTimeFrom(time);
  // };

  // const loadDuration = () => {
  //   let duration = [{ key: 'Duration', value: '' }];
  //   let durations = ['15', '30', '45', '60'];
  //   for (let i = 0; i < durations.length; i++) {
  //     duration.push({
  //       key: durations[i] + ' min',
  //       value: durations[i] + ' min'
  //     });
  //   }
  //   setDuration(duration);
  // };

  const loadHours = () => {
    let time = [];
    let hours, ampm;

    for (let i = 60; i <= 1380; i += 60) {
      hours = Math.floor(i / 60);
      ampm = hours < 12 ? ' AM' : ' PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      let slot = hours + ampm;
      time.push({ key: slot, value: slot });
    }
    setSlots(time);
  };

  const groupPtSchedule = ptScheduleList => {
    let groupedCollection = {};
    let ptScheduleCollection = {};

    const scheduleList = ptScheduleList.map(item => {
      let hours = moment(item.timeFrom).format('HH');
      let ampm = hours >= 12 ? ' PM' : ' AM';
      hours = hours % 12;
      hours = hours ? hours + ampm : '12' + ampm; // the hour '0' should be '12'

      if (ptScheduleCollection[hours] === undefined) {
        ptScheduleCollection[hours] = [];
      }
      if (
        item.duration === 60 &&
        moment(item.timeFrom).format('HH') !==
          moment(item.timeTo).format('HH') &&
        moment(item.timeFrom).format('mm') !== '00'
      ) {
        let a = { ...item };
        a.timeTo = moment(a.timeTo).toDate();
        a.timeTo.setMinutes(0);
        ptScheduleCollection[hours].push({ schedule: a, key: hours });
        let b = { ...item };
        b.timeFrom = moment(b.timeTo).toDate();
        b.timeFrom.setMinutes(0);
        let h1 = hours.split(' ');
        let hourNew = parseInt(h1[0]) + 1 + ' ' + h1[1];
        if (ptScheduleCollection[hourNew] == undefined) {
          ptScheduleCollection[hourNew] = [];
        }
        ptScheduleCollection[hourNew].push({
          schedule: b,
          key: hourNew
        });
      } else {
        ptScheduleCollection[hours].push({ schedule: item, key: hours });
      }
    });

    const ptKeys = Object.keys(ptScheduleCollection);

    slots &&
      slots.map(item => {
        groupedCollection[item.value] = [];

        if (ptKeys.indexOf(item.value) != -1) {
          groupedCollection[item.value] = ptScheduleCollection[item.value];
        } else {
          groupedCollection[item.value].push({ key: item.value });
        }
      });
    return groupedCollection;
  };

  const handleEditAppointment = item => {
    setOpenQV({ showQV: false });
    props.handleEditAppointment(item);
  };

  const handleDeleteAppointment = item => {
    setOpenQV({ showQV: false });
    props.handleDeleteAppointment(item);
  };

  return (
    ptScheduleList && (
      <React.Fragment>
        <Table
          className={[classes.tableCalendar, classes.tableCalendarWeekly]}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {moment().format('ddd')} <br />
                {moment().format('DD')}
              </TableCell>
              {weekDate &&
                weekDate.length > 0 &&
                weekDate.map(date => (
                  <TableCell align="right">
                    <span>
                      {moment(date)
                        .format('ddd')
                        .toUpperCase()}
                      <br />
                      {moment(date).format('DD')}
                    </span>
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableScrollBody}>
            {Object.entries(ptScheduleList).map(([key, value]) =>
              value ? (
                <TableRow>
                  <TableCell align="right">{key}</TableCell>
                  {weekDate &&
                    weekDate.length > 0 &&
                    weekDate.map(date => (
                      <TableCell className={classes.scheduleTd}>
                        <Box
                          className={[
                            classes.scheduleBoxWrap,
                            classes.scheduleBoxWrapWeekly
                          ]}>
                          {value.map(item => {
                            if (item && item.schedule) {
                              let itemdate = moment(
                                item.schedule.timeFrom
                              ).format('MM/DD/YYYY');

                              let timeFrom = item.schedule.timeFrom
                                ? moment(item.schedule.timeFrom)
                                    .format('HH:mm')
                                    .split(':')
                                : null;
                              let timeTo = item.schedule.timeTo
                                ? moment(item.schedule.timeTo)
                                    .format('HH:mm')
                                    .split(':')
                                : null;

                              let clsColor =
                                (timeFrom[1] === '00' && timeTo[1] === '15') ||
                                (timeFrom[1] === '15' && timeTo[1] === '30') ||
                                (timeFrom[1] === '30' && timeTo[1] === '45') ||
                                (timeFrom[1] === '45' && timeTo[1] === '00')
                                  ? classes.color_1
                                  : (timeFrom[1] === '30' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '00' &&
                                      timeTo[1] === '30') ||
                                    (timeFrom[1] === '15' && timeTo[1] === '45')
                                  ? classes.color_2
                                  : (timeFrom[1] === '15' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '00' && timeTo[1] === '45')
                                  ? classes.color_3
                                  : timeFrom[1] === '00' && timeTo[1] === '00'
                                  ? classes.color_4
                                  : '';
                              return (
                                date === itemdate && (
                                  <Box
                                    onClick={() => handleOpenQV(item)}
                                    className={[
                                      classes.scheduleBox,
                                      clsColor,
                                      ptScheduleListTimeElapsed(item.schedule)
                                        ? classes.color_5
                                        : ''
                                    ]}>
                                    <Typography
                                      noWrap
                                      className={classes.labelOne}>
                                      {item &&
                                        item.schedule &&
                                        item.schedule.patientName}
                                    </Typography>
                                  </Box>
                                )
                              );
                            }
                          })}
                        </Box>
                      </TableCell>
                    ))}
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell align="right">{key}</TableCell>
                  <TableCell className={classes.scheduleTd}>
                    <Box
                      className={[
                        classes.scheduleBoxWrap,
                        classes.scheduleBoxWrapWeekly
                      ]}></Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <Modal
          disableBackdropClick
          open={openQV.showQV}
          onClose={handleCloseQV}>
          <ScheduleQuickView
            onCancel={handleCloseQV}
            item={openQV.item}
            handleEditAppointment={handleEditAppointment}
            handleDeleteAppointment={handleDeleteAppointment}
          />
        </Modal>
      </React.Fragment>
    )
  );
};

PTList.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,

  allPhyPtSchedulesofDate:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedulesofDate &&
    state.ptschedules.allPhyPtSchedulesofDate.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PTList))
);
