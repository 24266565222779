/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline,
  CheckCircleOutlineOutlined,
  BlockOutlined
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  saveMedication,
  updateMedication,
  inactivateMedication,
  activateMedication,
  deleteMedication, 
  getPatientInactiveMedication,
  getPatientActiveMedications,
} from 'services/soap/action';

const Medications = props => {
  const { classes, patientId ,getPatientInactiveMedication,
    getPatientActiveMedications } = props;

  const initialUserState = {
    id: 0,
    name: '',
    dose: '',
    directions: ''
  };
  const [values, setValues] = useState(initialUserState);
  const [editvalues, setEditValues] = useState(initialUserState);
  const [medication, setMedication] = useState(props.medication);
  const [inactivemedication, setInactiveMedication] = useState(
    props.inactivemedication
  );
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.medication && props.medication.length > 0) {
      setMedication(props.medication);
    }
  }, [props.medication]);

  useEffect(() => {
    if (props.inactivemedication && props.inactivemedication.length > 0) {
      setInactiveMedication(props.inactivemedication);
    }
  }, [props.inactivemedication]);

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleEditFieldChange = (field, value) => {
    setEditValues({ ...editvalues, [field]: value });
  };

  const handleSaveMedication = async () => {
    if (values.name || values.dose || values.directions) {
      const res = await props.saveMedication(patientId, values); 
      if (res.status) { 
        getPatientInactiveMedication(patientId)
        getPatientActiveMedications(patientId)
        // let newres=await props.getSoapActiveInactiveMedication(patientId);
        // if(newres.status){
        //   setMedication(newres.medications);
        //   props.getMedication(newres.medications);
        // }
        setValues(initialUserState);
      }
    }
  };

  const handleUpdateMedication = async idx => {
    if (editvalues.name || editvalues.reaction) {
      const res = await props.updateMedication(patientId, editvalues);
      if (res.status) {
        const newState = [...medication];
        newState[idx] = res.medication;
        setMedication(newState);
        setIsEdit(false); 
          getPatientInactiveMedication(patientId)
          getPatientActiveMedications(patientId)
        props.getMedication(newState);
      }
    }
  };

  const handleInactivateMedication = async item => {
    const res = await props.inactivateMedication(patientId, item);
    if (res.status) {
      setInactiveMedication(res.inactiveMedication);
      props.getInactiveMedication(res.inactiveMedication);
      if (medication) {
        let newState = [...medication];
        setMedication(newState.filter(c => c.id !== item.id)); 
          getPatientInactiveMedication(patientId)
          getPatientActiveMedications(patientId)  
        props.getMedication(newState.filter(c => c.id !== item.id));
      }
    }
  };

  const handleActivateMedication = async item => {
    const res = await props.activateMedication(patientId, item);
    if (res.status) {
      setMedication(res.activeMedication);
      props.getMedication(res.activeMedication);
      if (inactivemedication) {
        let newState = [...inactivemedication];
        setInactiveMedication(newState.filter(c => c.id !== item.id));
          getPatientInactiveMedication(patientId)
          getPatientActiveMedications(patientId)
        props.getInactiveMedication(newState.filter(c => c.id !== item.id));
      }
    }
  };

  const handleDeleteMedication = async id => {
    const res = await props.deleteMedication(patientId, id);
    if (res.status) {
      if (inactivemedication) {
        let newState = [...inactivemedication];
        setInactiveMedication(newState.filter(c => c.id !== id));
         getPatientInactiveMedication(patientId)
          getPatientActiveMedications(patientId)
        props.getInactiveMedication(newState.filter(c => c.id !== id));
      }
      setValues(initialUserState);
    }
  };

  const handleEditMedication = async (data, index) => {
    setIsEdit(true);
    if (data) {
      setEditValues({
        id: data.id,
        name: data.name,
        dose: data.dose,
        directions: data.directions,
        editIndex: index
      });
    }
  };

  const clearMedication = () => {
    setIsEdit(false);
    setValues(initialUserState);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody} style={{ borderTop: 'default' }}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('name', event.target.value)
                      }
                      placeholder="Input Name"
                      value={values.name}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('dose', event.target.value)
                      }
                      placeholder="Input dose"
                      value={values.dose}
                      variant="outlined"
                    />
                  </TableCell>

                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange('directions', event.target.value)
                      }
                      placeholder="Input directions"
                      value={values.directions}
                      variant="outlined"
                    />
                  </TableCell>

                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      // disabled={values.name || values.reaction}
                      onClick={() => handleSaveMedication()}
                      size="small"
                      style={{ color: '#03A484' }}>
                      <CheckOutlined />
                    </IconButton>
                    <IconButton onClick={() => clearMedication()} size="small">
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {medication &&
                  medication.length > 0 &&
                  medication
                    .sort((a, b) => b.id - a.id)
                    .map((item, idx) => {
                      return (
                        <TableRow>
                          {isEdit && idx === editvalues.editIndex ? (
                            <>
                              <TableCell width="25%">
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChange(
                                      'name',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input name"
                                  value={editvalues.name}
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChange(
                                      'dose',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input dose"
                                  value={editvalues.dose}
                                  variant="outlined"
                                />
                              </TableCell>

                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChange(
                                      'directions',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input directions"
                                  value={editvalues.directions}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.dose}</TableCell>
                              <TableCell>{item.directions}</TableCell>
                            </>
                          )}
                          <TableCell align="center" width={72}>
                            {isEdit && idx === editvalues.editIndex ? (
                              <>
                                <IconButton
                                  // disabled={values.name || values.reaction}
                                  onClick={() => handleUpdateMedication(idx)}
                                  size="small"
                                  style={{ color: '#03A484' }}>
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => clearMedication()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleInactivateMedication(item, idx)
                                  }
                                  size="small"
                                  title="Active">
                                  <CheckCircleOutlineOutlined />
                                </IconButton>
                                {/* <IconButton
                                size="small"
                                title="Inactive"
                              ><BlockOutlined style={{color: '#ccc'}} /></IconButton> */}
                                <IconButton
                                  onClick={() =>
                                    handleEditMedication(item, idx)
                                  }
                                  size="small">
                                  <Edit color="primary" />
                                </IconButton>
                                {/* <IconButton
                                onClick={() => handleDeleteMedication(item.id)}
                                size="small"
                              >
                                <DeleteOutline style={{ color: '#DE5555' }} />
                              </IconButton> */}
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                {inactivemedication &&
                  inactivemedication.length > 0 &&
                  inactivemedication.map((item, idx) => {
                    return (
                      <TableRow>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.dose}</TableCell>
                        <TableCell>{item.directions}</TableCell>
                        {/* {isEdit && idx === editvalues.editIndex ? (
                          <>
                            <TableCell width="25%">
                              <TextField
                                className={classes.textBox}
                                id="outlined-basic"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                onChange={event =>
                                  handleEditFieldChange(
                                    'name',
                                    event.target.value
                                  )
                                }
                                placeholder="Input name"
                                value={editvalues.name}
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                className={classes.textBox}
                                id="outlined-basic"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                onChange={event =>
                                  handleEditFieldChange(
                                    'dose',
                                    event.target.value
                                  )
                                }
                                placeholder="Input dose"
                                value={editvalues.dose}
                                variant="outlined"
                              />
                            </TableCell>

                            <TableCell>
                              <TextField
                                className={classes.textBox}
                                id="outlined-basic"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                onChange={event =>
                                  handleEditFieldChange(
                                    'directions',
                                    event.target.value
                                  )
                                }
                                placeholder="Input directions"
                                value={editvalues.directions}
                                variant="outlined"
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.dose}</TableCell>
                            <TableCell>{item.directions}</TableCell>
                          </>
                        )} */}
                        <TableCell align="center" width={72}>
                          {/* {isEdit && idx === editvalues.editIndex ? (
                            <>
                              <IconButton
                                // disabled={values.name || values.reaction}
                                onClick={() => handleUpdateMedication(idx)}
                                size="small"
                                style={{ color: '#03A484' }}
                              >
                                <CheckOutlined />
                              </IconButton>
                              <IconButton
                                onClick={() => clearMedication()}
                                size="small"
                              >
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </>
                          ) : ( */}
                          <>
                            {/* <IconButton
                                size="small"
                                title="Active"
                              ><CheckCircleOutlineOutlined /></IconButton> */}
                            <IconButton
                              onClick={() =>
                                handleActivateMedication(item, idx)
                              }
                              size="small"
                              title="Inactive">
                              <BlockOutlined style={{ color: '#ccc' }} />
                            </IconButton>
                            {/* <IconButton
                                onClick={() => handleEditMedication(item, idx)}
                                size="small"
                              >
                                <Edit color="primary" />
                              </IconButton> */}
                            <IconButton
                              onClick={() => handleDeleteMedication(item.id)}
                              size="small">
                              <DeleteOutline style={{ color: '#DE5555' }} />
                            </IconButton>
                          </>
                          {/* )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
Medications.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  saveMedication,
  updateMedication,
  inactivateMedication,
  activateMedication,
  deleteMedication,
  getPatientInactiveMedication,
  getPatientActiveMedications,
};

const mapStateToProps = () => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Medications))
);
