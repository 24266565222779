import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import { TextField, Box, Button } from '@material-ui/core';
import { savePtMessage } from 'services/ptmessages/action';
import { connect } from 'react-redux';

const NewMessage = props => {
  const { classes, newmsgdata, userID } = props;

  const [data, setData] = useState({
    message: '',
    patientId: userID,
    status: 1
  });
  const [poploading, setPopLoading] = useState(false);

  useEffect(() => {
    if (newmsgdata !== undefined) {
      setData(newmsgdata);
    }
  }, [newmsgdata]);

  const handleFieldChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleSavePtMessage = async () => {
    setPopLoading(true);
    const res = await props.savePtMessage(userID, data);
    if (res) {
      props.showSuccess();
    }
    setPopLoading(false);
    props.onCancel();
  };

  return (
    <React.Fragment>
      <Box className={classes.modalBody}>
        <TextField
          multiline
          onChange={event => handleFieldChange('message', event.target.value)}
          placeholder="Please enter your message here"
          rows="3"
          style={{ width: '100%', marginBottom: 10 }}
          value={data.message}
          variant="outlined"
        />
      </Box>
      <Box className={classes.modalFooter}>
        <Button
          onClick={() => props.onCancel()}
          size="small"
          style={{ marginRight: 10 }}
          variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!data.message}
          onClick={() => handleSavePtMessage()}
          size="small"
          style={{ borderRadius: 4 }}
          variant="contained">
          Send
        </Button>
      </Box>
    </React.Fragment>
  );
};

NewMessage.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  savePtMessage
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewMessage))
);
