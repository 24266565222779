export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  textGrey: {
    color: '#a2a2a2'
  },
  textGreen: {
    color: theme.palette.success.main
  },
  tabWrap: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '0px 4px 4px 4px',
    background: '#fff',
    padding: 10
  },
  colWrap: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: '1 0 auto'
    }
  },
  cardRoot: {
    width: '100%'
  },
  cardHeader: {
    padding: '8px 20px',
    alignItems: 'center',
    display: 'flex',
    '& h2': {
      fontWeight: 600,
      fontSize: 16
    }
  },
  cardSubHeader: {
    padding: '8px 20px',
    alignItems: 'center',
    display: 'flex',
    background: 'rgba(199, 199, 199, 0.21)',
    borderTop: '1px solid #DADADA',
    borderBottom: '1px solid #DADADA',
    justifyContent: 'space-between',
    '& h3': {
      fontWeight: 600,
      fontSize: 14,
      color: '#5698D4'
    },
    '& svg': {
      height: '.8em',
      width: '.8em'
    },
    '& button': {
      marginLeft: '3px'
    }
  },
  typeBox: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    height: 95
  },
  typeBoxOption: {
    width: '48%',
    borderRadius: 2,
    overflow: 'hidden',
    border: '1px solid #E0DFDF',
    paddingLeft: 60,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: 16,
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.25)'
    },
    '&:hover span': {
      backgroundColor: '#5698D4'
    },
    '& span': {
      backgroundColor: '#c7c7c7',
      width: 50,
      position: 'absolute',
      borderRadius: '2px 0 0 2px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      bottom: 0,
      top: 0,
      left: 0,
      '& img': {
        transform: 'scale(.7)'
      }
    }
  },
  typeBoxOptionSelected: {
    border: 'none',
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.25)',
    '& span': {
      backgroundColor: '#5698D4'
    }
  },
  labelFocused: {
    backgroundColor: theme.palette.primary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },
  textField: {
    borderColor: '#D8D8D8',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: 1,
    '&::placeholder': {
      color: 'blue',
      fontSize: 10
    },
    '& + & ': {
      marginTop: theme.spacing(2)
    },
    '&:focus $notchedOutline': {
      borderColor: 'green'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC',
      borderRadius: 1
    }
  },
  infoBoxWrap: {
    display: 'flex'
  },
  infoBoxLeft: {
    width: 150,
    minWidth: 150,
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    '& button': {
      padding: '4px 5px',
      lineHeight: '10px',
      fontSize: 12,
      textTransform: 'capitalize'
    }
  },
  infoBoxRight: {
    flexGrow: 1
  },
  infoBoxItems: {
    padding: 12,
    '& .MuiGrid-item': {
      padding: 10,
      '& svg': {
        float: 'left',
        display: 'flex',
        height: '100%',
        width: 50,
        padding: 10,
        color: theme.palette.primary.main
      },
      '& h3': {
        fontSize: 16,
        lineHeight: '22px'
      },
      '& h4': {
        fontSize: 12
      },
      '& $stateList': {
        '& svg': {
          width: '25px!important',
          height: '25px!important',
          padding: '0px!important'
        }
      }
    }
  },
  stateList: {},
  ClinicDetailsWrap: {
    padding: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    marginBottom: 20,
    '& .MuiGrid-item': {
      '&:first-Child': {
        borderLeft: 'none',
        paddingLeft: 0
      },
      borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '0px 15px 0 15px',
      '& h2': {
        fontSize: 12,
        color: theme.palette.primary.main,
        lineHeight: '18px',
        '& svg': {
          width: 14,
          height: 14,
          marginBottom: -3,
          marginRight: 3
        }
      },
      '& h4': {
        fontSize: 14,
        lineHeight: '20px'
      }
    }
  },
  titleIcon: {
    marginBottom: -6,
    marginRight: 5,
    marginLeft: -6
  },
  cardSubHeaderActions: {
    marginRight: -10
  },
  rowStyleOverlay: {
    position: 'absolute',
    zIndex: 1000,
    left: 12,
    right: 12,
    background: '#fff',
    overflowY: 'auto',
    overflowX: 'auto',
    maxHeight: 300,
    minHeight: 100,
    border: '1px solid #ccc',
    borderTop: 'none',
    boxShadow: '0px 5px 5px 1px rgba(0,0,0,.03)'
  },
  tootltipStyle: {
    position: 'absolute',
    right: 10,
    top: 10,
    height: 20,
    width: 20,
    color: '#a7a7a7'
  },
  hidden: { display: 'none' }
});
