import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../../style';
import MessageBox from 'util/messageBox';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import { getProviderResource, updateUserProfile } from 'services/user/action';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
const Create = props => {
  const {
    classes,
    className,
    ehrID,
    userID,
    providerResource,
    ...rest
  } = props;
  const [, setChecked] = React.useState(false);
  let [isBlocking, setIsBlocking] = useState(false);
  const [values, setValues] = useState({
    id: 0,
    licenseNumber: '',
    individualNPI: '',
    specialityTaxonomy: '',
    deaNumber: '',
    isLoading: false,
    succMsg: false
  });

  useEffect(() => {
    if (ehrID && userID) {
      props.getProviderResource(ehrID, userID);
    }
  }, []);

  useEffect(() => {
    if (providerResource) {
      setValues(providerResource);
    }
  }, [providerResource]);

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
    if (values) {
      setIsBlocking(true);
    }
  };

  const handleCloseAll = () => {
    setValues({ ...values, succMsg: false });
  };

  const handleSubmit = async () => {
    setValues({ ...values, isLoading: true });
    props.updateUserProfile(ehrID, userID, { ...values }).then(status => {
      if (status) {
        setValues({ ...values, succMsg: true });
      } else {
        setValues({ ...values, isLoading: false });
      }
    });
    setIsBlocking(false);
  };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <Prompt
        when={isBlocking}
        message="There are unsaved changes, do you wish to discard them?"
      />
      <Grid container>
        <Grid
          item
          xs={12}
          className={classes.cardHeader}
          style={{ borderBottom: '1px solid #f1f1f1' }}>
          <Typography variant="h2">Provider Profile</Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="License Number"
              name="Lno"
              variant="outlined"
              color="Primary"
              value={values.licenseNumber}
              onChange={event =>
                handleFieldChange('licenseNumber', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="Individual NPI"
              name="npi"
              variant="outlined"
              color="Primary"
              value={values.individualNPI}
              onChange={event =>
                handleFieldChange('individualNPI', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="Provider Speciality Taxonomy"
              name="taxonomy"
              variant="outlined"
              color="Primary"
              value={values.specialityTaxonomy}
              onChange={event =>
                handleFieldChange('specialityTaxonomy', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              fullWidth
              label="DEA #"
              name="dea"
              variant="outlined"
              color="Primary"
              value={values.deaNumber}
              onChange={event =>
                handleFieldChange('deaNumber', event.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}>
              Save
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <MessageBox
        open={values.succMsg}
        variant="success"
        onClose={handleCloseAll}
        message="Provider profile saved successfully"
      />
    </Card>
  );
};

Create.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getProviderResource,
  updateUserProfile
};
const mapStateToProps = state => ({
  titles: state.util.titles,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  providerResource: state.user && state.user.providerresource
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
