import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';

import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
const PrimaryInfo = props => {
  const { classes, className, patientinformation, ...rest } = props;

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <CardContent style={{ margin: -16 }}>
        <Grid container>
          <Grid className={classes.pMetaWrap} container item xs={12}>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Date of Birth</Typography>
              <Typography variant="h6">
                {patientinformation.dob && patientinformation.dob !== 'Invalid date' && patientinformation.dob !== ''
                  ? moment(patientinformation.dob).format('MM/DD/YYYY')
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Gender</Typography>
              <Typography variant="h6">
                {patientinformation.gender ? patientinformation.gender : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">SSN</Typography>
              <Typography variant="h6">
                {patientinformation.socialsecurity
                  ? patientinformation.socialsecurity
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Marital Status</Typography>
              <Typography variant="h6">
                {patientinformation.maritalstatus
                  ? patientinformation.maritalstatus
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Language</Typography>
              <Typography variant="h6">
                {patientinformation.language
                  ? patientinformation.language
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Race</Typography>
              <Typography variant="h6">
                {patientinformation.race ? patientinformation.race : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Ethnicity</Typography>
              <Typography variant="h6">
                {patientinformation.ethnicity
                  ? patientinformation.ethnicity
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Cell Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.phonenumber
                  ? patientinformation.phonenumber
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Home Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.homephone
                  ? patientinformation.homephone
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Work Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.workphone
                  ? patientinformation.workphone
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={2} sm={4} xs={6}>
              <Typography variant="h5">Email</Typography>
              <Typography variant="h6">
                {patientinformation.emailaddress
                  ? patientinformation.emailaddress
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PrimaryInfo.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(PrimaryInfo));
