export default (state = {}, action) => {
  switch (action.type) {
    case "SHOW_ERROR":
      let { message, status, statusText, data, config: { url, method } } = action.data;
      // console.log(process.env);
      // if (message === "Socket connection timed out") {
      //   message = "Sorry, failed to connect server";
      // }
      // if (name === "Timeout") {
      //   message = "Sorry, server request timed out";
      // }
      return {
        message,
        status,
        statusText,
        data: { ...data },
        url,
        method
      };
    case "REMOVE_ERROR":
      return {
        ...action.data
      };
    default:
      return state;
  }
};
