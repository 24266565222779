import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { CPT } from './';
import styles from './../../../style';
import { connect } from 'react-redux';
import MessageBox from 'util/messageBox';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Box,
  Modal,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@material-ui/core';
import {
  CloseOutlined,
  CheckCircleOutlineOutlined,
  BlockOutlined,
  DeleteOutlined
} from '@material-ui/icons';

import {
  inactivateDiagnosis,
  activateDiagnosis,
  deleteInactiveDiagnosis,
  savePastDiagnosis
} from 'services/soap/action';

const DxUpdate = props => {
  const { classes, patientId } = props;

  // #region CPT,ICD

  let [isCPT, setIsCPT] = useState(false);
  const [openCPTICD, setOpenCPTICD] = useState(false);
  const [activeDiagnosis, setActiveDiagnosis] = useState(props.diagnosis);
  const [inactiveDiagnosis, setInactiveDiagnosis] = useState(
    props.inactivediagnosis
  );
  const [activeCpt, setActiveCpt] = useState(props.cpt);
  const [inactiveCpt, setInactiveCpt] = useState(props.inactivecpt);
  const [errorMsg, setErrorMsg] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  useEffect(() => {
    if (props.diagnosis && props.diagnosis.length > 0) {
      setActiveDiagnosis(props.diagnosis);
    }
  }, [props.diagnosis]);

  useEffect(() => {
    if (props.inactivediagnosis && props.inactivediagnosis.length > 0) {
      setInactiveDiagnosis(props.inactivediagnosis);
    }
  }, [props.inactivediagnosis]);

  useEffect(() => {
    if (props.cpt && props.cpt.length > 0) {
      setActiveCpt(props.cpt);
    }
  }, [props.cpt]);

  useEffect(() => {
    if (props.inactivecpt && props.inactivecpt.length > 0) {
      setInactiveCpt(props.inactivecpt);
    }
  }, [props.inactivecpt]);

  const handleOpenCPTICD = isCPT => {
    setIsCPT(isCPT);
    setOpenCPTICD(true);
  };
  const handleCloseCPTICD = () => {
    setOpenCPTICD(false);
  };

  const handleSaveDiagnosis = async value => {
    const res = await props.savePastDiagnosis(
      patientId,
      value,
      props.activeTab
    );
    if (res.status) {
      if (isCPT) {
        setActiveCpt(
          res.pastDiagnosis.filter(
            c => c.codeType === 'cpt' && c.isActive === true
          )
        );
        props.getCpt(
          res.pastDiagnosis.filter(
            c => c.codeType === 'cpt' && c.isActive === true
          )
        );
      } else {
        setActiveDiagnosis(
          res.pastDiagnosis.filter(
            c => c.codeType === 'diagnosis' && c.isActive === true
          )
        );
        props.getDiagnosis(
          res.pastDiagnosis.filter(
            c => c.codeType === 'diagnosis' && c.isActive === true
          )
        );
      }
    }
  };

  const handleInactivateDiagnosis = async (item,isCPTval) => {
    const res = await props.inactivateDiagnosis(
      patientId,
      item,
      props.activeTab
    );
    if (res.status) {
      if (isCPTval) {
        setInactiveCpt(
          res.inactiveDiagnosis.filter(
            c => c.codeType === 'cpt' && c.isActive === false
          )
        );
        props.getInactiveCpt(
          res.inactiveDiagnosis.filter(
            c => c.codeType === 'cpt' && c.isActive === false
          )
        );
        if (activeCpt) {
          let newState = [...activeCpt];
          setActiveCpt(newState.filter(c => c.id !== item.id));
          props.getCpt(newState.filter(c => c.id !== item.id));
        }
      } else {
        setInactiveDiagnosis(
          res.inactiveDiagnosis.filter(
            c => c.codeType === 'diagnosis' && c.isActive === false
          )
        );
        props.getInactiveDiagnosis(
          res.inactiveDiagnosis.filter(
            c => c.codeType === 'diagnosis' && c.isActive === false
          )
        );
        if (activeDiagnosis) {
          let newState = [...activeDiagnosis];
          setActiveDiagnosis(newState.filter(c => c.id !== item.id));
          props.getDiagnosis(newState.filter(c => c.id !== item.id));
        }
      }
    }
  };

  const handleActivateDiagnosis = async (item,isCPTval) => {
    const res = await props.activateDiagnosis(patientId, item, props.activeTab);
    if (res.status) {
      if (isCPTval) {
        setActiveCpt(
          res.activePastDiagnosis.filter(
            c => c.codeType === 'cpt' && c.isActive === true
          )
        );
        props.getCpt(
          res.activePastDiagnosis.filter(
            c => c.codeType === 'cpt' && c.isActive === true
          )
        );
        if (activeCpt) {
          let newState = [...inactiveCpt];
          setInactiveCpt(newState.filter(c => c.id !== item.id));
          props.getInactiveCpt(newState.filter(c => c.id !== item.id));
        }
      } else {
        setActiveDiagnosis(
          res.activePastDiagnosis.filter(
            c => c.codeType === 'diagnosis' && c.isActive === true
          )
        );
        props.getDiagnosis(
          res.activePastDiagnosis.filter(
            c => c.codeType === 'diagnosis' && c.isActive === true
          )
        );
        if (inactiveDiagnosis) {
          let newState = [...inactiveDiagnosis];
          setInactiveDiagnosis(newState.filter(c => c.id !== item.id));
          props.getInactiveDiagnosis(newState.filter(c => c.id !== item.id));
        }
      }
    }
  };

  const handleDeleteInactiveDiagnosis = async (id,isCPTval) => {
    const res = await props.deleteInactiveDiagnosis(
      patientId,
      id,
      props.activeTab
    );
    if (res.status) {
      setSuccessMsg(true);
      if (isCPTval) {
        if (inactiveCpt) {
          let newState = [...inactiveCpt];
          setInactiveCpt(newState.filter(c => c.id !== id));
          props.getInactiveCpt(newState.filter(c => c.id !== id));
        }
      } else {
        if (inactiveDiagnosis) {
          let newState = [...inactiveDiagnosis];
          setInactiveDiagnosis(newState.filter(c => c.id !== id));
          props.getInactiveDiagnosis(newState.filter(c => c.id !== id));
        }
      }
      setHandleDeletePopup({ showDeletePopup: false });
    } else {
      setErrorMsg(true);
      setHandleDeletePopup({ showDeletePopup: false });
    }
  };

  const renderCPTICD = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseCPTICD}
        open={openCPTICD}>
        <Box
          className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              {isCPT ? 'CPT Database' : 'Diagnosis Database'}
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseCPTICD}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <CPT
            handleSaveDiagnosis={handleSaveDiagnosis}
            isCPT={isCPT}
            isDxUpdate
            onCancel={handleCloseCPTICD}
            patientId={patientId}
          />
        </Box>
      </Modal>
    );
  };

  // #endregion

  // #region dialog box

  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    itemId: ''
  });
  const [poploading, setPopLoading] = useState(false);
  const [isDiagnosisDelete, setisDiagnosisDelete] = useState(false);

  const handleDeletePopUp = (item, isDiagnosis) => {
    if (isDiagnosis === true) {
      setisDiagnosisDelete(true);
    } else {
      setisDiagnosisDelete(false);
    }
    setHandleDeletePopup({
      showDeletePopup: true,
      itemId: item
    });
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup({ showDeletePopup: false });
  };

  const renderDeleteDialogBox = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeletepopup.showDeletePopup}>
        <DialogTitle id="confirmation-dialog-title">
          {isDiagnosisDelete
            ? 'Diagnosis Delete Confirmation'
            : 'CPT Delete Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isDiagnosisDelete
              ? 'Are you sure you want to delete this Diagnosis?'
              : 'Are you sure you want to delete this CPT?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() =>
                handleDeleteInactiveDiagnosis(handledeletepopup.itemId,isDiagnosisDelete?false:true)
              }>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  //#region error

  const handleCloseError = () => {
    setErrorMsg(false);
  };

  const renderError = () => {
    return (
      <MessageBox
        message="This item cannot be deleted"
        onClose={handleCloseError}
        open={errorMsg}
        variant="error"
      />
    );
  };

  //#endregion

  //#region , message
  const handleCloseSuccess = () => {
    setSuccessMsg(false);
  };

  const renderSuccess = () => {
    return (
      <MessageBox
        message="Item Deleted Succesfully"
        onClose={handleCloseSuccess}
        open={successMsg}
        variant="success"
      />
    );
  };
  //#endregion

  return (
    <Box className={classes.soapPastWrap}>
      <Box
        className={classes.soapPastHeader}
        style={{ justifyContent: 'flex-end' }}>
        <Button
          color="primary"
          disableElevation="true"
          onClick={() => handleOpenCPTICD(false)}
          size="small"
          variant="outlined">
          Diagnosis Template
        </Button>
      </Box>
      <Box>
        <Table className={classes.plainTable}>
          <TableHead>
            <TableRow>
              <TableCell width="30%">Patient Diagnosis</TableCell>
              <TableCell colSpan={2}>ICD-10</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeDiagnosis &&
              activeDiagnosis.length > 0 &&
              activeDiagnosis
                .sort((a, b) => b.id - a.id)
                .map((item, idx) => {
                  return (
                    <TableRow>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => handleInactivateDiagnosis(item, false)}
                          size="small"
                          title="Active">
                          <CheckCircleOutlineOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}

            {inactiveDiagnosis &&
              inactiveDiagnosis.length > 0 &&
              inactiveDiagnosis
                .sort((a, b) => b.id - a.id)
                .map((item, idx) => {
                  return (
                    <TableRow className={classes.inactiveRow}>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="center">
                        <IconButton size="small" title="Inactive">
                          <BlockOutlined
                            onClick={() => handleActivateDiagnosis(item, false)}
                            style={{ color: '#ccc' }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleDeletePopUp(item.id, true)}
                          size="small"
                          title="Delete">
                          <DeleteOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </Box>

      {handledeletepopup && renderDeleteDialogBox()}
      {errorMsg && renderError()}
      {successMsg && renderSuccess()}
      <Box
        className={classes.soapPastHeader}
        style={{ justifyContent: 'flex-end' }}>
        <Button
          color="primary"
          disableElevation="true"
          onClick={() => handleOpenCPTICD(true)}
          size="small"
          variant="outlined">
          CPT Template
        </Button>
      </Box>
      <Box>
        <Table className={classes.plainTable}>
          <TableHead>
            <TableRow>
              <TableCell width="30%">Patient CPT</TableCell>
              <TableCell colSpan={2}>CPT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeCpt &&
              activeCpt.length > 0 &&
              activeCpt
                .sort((a, b) => b.id - a.id)
                .map((item, idx) => (
                  <TableRow>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.description}</TableCell>

                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleInactivateDiagnosis(item, true)}
                        size="small"
                        title="Active">
                        <CheckCircleOutlineOutlined />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

            {inactiveCpt &&
              inactiveCpt.length > 0 &&
              inactiveCpt
                .sort((a, b) => b.id - a.id)
                .map((item, idx) => {
                  return (
                    <TableRow className={classes.inactiveRow}>
                      <TableCell>{item.code}</TableCell>
                      <TableCell>{item.description}</TableCell>
                      <TableCell align="center">
                        <IconButton size="small" title="Inactive">
                          <BlockOutlined
                            onClick={() => handleActivateDiagnosis(item, true)}
                            style={{ color: '#ccc' }}
                          />
                        </IconButton>
                        <IconButton size="small" title="Delete">
                          <DeleteOutlined
                            onClick={() => handleDeletePopUp(item.id, false)}
                            style={{ color: '#DE5555' }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </Box>

      {handledeletepopup && renderDeleteDialogBox()}
      {errorMsg && renderError()}
      {successMsg && renderSuccess()}
      {openCPTICD && renderCPTICD()}
    </Box>
  );
};

DxUpdate.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  inactivateDiagnosis,
  activateDiagnosis,
  deleteInactiveDiagnosis,
  savePastDiagnosis
};
const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DxUpdate))
);
