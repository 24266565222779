import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChangePassword from './Modal_ChangePassword';
import {
  AppBar,
  Toolbar,
  Box,
  Hidden,
  IconButton,
  withStyles,
  Button,
  Modal,
  Typography,
  Badge
} from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AccountCircleOutlined,
  ExpandMoreOutlined,
  SettingsOutlined,
  ExitToAppOutlined
} from '@material-ui/icons/';
import styles from './style';
import moment from 'moment';
import MessageBox from 'util/messageBox';
import logo from '../../../../assets/images/logo.png';
import { logout } from '../../../../services/profile/action';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAllPatforPtMessages } from 'services/ptmessages/action';
import { Grid } from '@material-ui/core';
import { getOrganizationbyId } from 'services/ehr/action';
import { getAppointmentsByPatientId } from 'services/ptschedules/action';
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 0,
    borderColor: '#3397F3',
    '& ul': {
      padding: 0
    },
    '& svg': {
      width: 18,
      height: 18
    },
    '& .MuiListItemIcon-root': {
      minWidth: '24px!important'
    }
  }
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    elevation={0}
    getContentAnchorEl={null}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    // '&:focus': {
    //   backgroundColor: theme.palette.primary.main,
    //   '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
    //     color: theme.palette.common.white
    //   }
    // },
    '& .MuiListItemIcon-root': {
      minWidth: 30
    }
  },
  ListItemIcon: {}
}))(MenuItem);

const Topbar = props => {
  const {
    userID,
    onSidebarOpen,
    classes,
    ehrID,
    organization,
    allPatforPtMessages,
    allAppointments,
    refreshTopBar,
    ...rest
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuActive, setMenuState] = useState(null);
  const [alert, setAlert] = useState(false);
  const [futureAppointment, setFutureAppointment] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [succmsg1, setSuccMsg1] = React.useState(false);
  //const [succmsg1, setSuccMsg1] = useState({ showmsg: false });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [formstate, setFormstate] = useState({});

  useEffect(() => {
    props.getAllPatforPtMessages(ehrID, userID);
    props.getOrganizationbyId(ehrID);
    props.getAppointmentsByPatientId(ehrID, userID)
  }, []);

  useEffect(() => {
    handleGetCount();
    handleGetFutureAppointment();
  }, [refreshTopBar]);

  useEffect(() => {
    handleGetCount();
  }, [props.allPatforPtMessages]);

  useEffect(() => {
    handleGetFutureAppointment();
  }, [props.allAppointments]);

  const handleGetCount = () => {
    if (props.allPatforPtMessages !== undefined) {
      const status = allPatforPtMessages.filter(c => c.status === 1);
      if (status && status.length > 0) {
        setAlert(true);
      } else {
        setAlert(false);
      }
    }
  };
  const handleGetFutureAppointment = () => {
    if (props.allAppointments !== undefined) {
      const futureAppointments = props.allAppointments.filter(c => new Date(c.appointmentDate) > new Date())
      if (futureAppointments && futureAppointments.length > 0) {
        setFutureAppointment(true);
      } else {
        setFutureAppointment(false);
      }
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMsg = () => {
    setSuccMsg({ succ: false, succMsg: '' });
  };

  const handleSignOut = () => {
    props.logout(ehrID);
  };
  const handleClose2 = () => {
    setOpen2(false);
    setAnchorEl(false);
  };

  const handleClass = menu => {
    setMenuState(menu);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleCloseMsg1 = () => {
    setSuccMsg1(false);
  };
  const handleOpenMsg1 = () => {
    setSuccMsg1(true);
  };
  const renderSuccMsg = () => {
    return (
      <MessageBox
        open={succmsg1}
        variant="success"
        onClose={handleCloseMsg1}
        message="Password has been changed successfully"
      />
    );
  };

  return (
    <Grid>
      <AppBar {...rest} className={classes.header}>
        <Toolbar className={classes.headerWrap}>
          <div className={classes.logoWrap}>
            <RouterLink to="/pt-default">
              <img alt="Logo" className={classes.logo} src={logo} />
            </RouterLink>
          </div>
          <div className={classes.naviWrap}>
            <div className={classes.naviTop}>
              <Hidden smDown>
                <Box className={classes.naviTopTit}>
                  <Typography variant="h4">
                    {organization && organization.organizationname}
                  </Typography>
                </Box>
              </Hidden>
              <Box className={classes.naviTopAcc}>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  className={classes.c2aBtn}
                  color="inherit"
                  endIcon={<ExpandMoreOutlined />}
                  // onClose={handleClose}
                  onClick={handleClick}
                  size="small"
                  startIcon={<AccountCircleOutlined />}
                  variant="text">
                  Account
                </Button>
                <StyledMenu
                  anchorEl={anchorEl}
                  id="customized-menu"
                  keepMounted
                  onClose={handleClose}
                  open={Boolean(anchorEl)}>
                  <StyledMenuItem
                    onClose={handleClose}
                    component={RouterLink}
                    onClick={handleOpen2}>
                    <ListItemIcon>
                      <SettingsOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                    {/* <Modal_ChangePassword /> */}
                  </StyledMenuItem>
                  <StyledMenuItem
                    component={RouterLink}
                    onClick={handleClose}
                    to="/pt-login">
                    <ListItemIcon>
                      <ExitToAppOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText onClick={handleSignOut} primary="Logout" />
                  </StyledMenuItem>
                </StyledMenu>
              </Box>
            </div>
            <div className={classes.naviBot}>
              <Box className={classes.naviSearchWrap}>
                {/* <InputBase className={classes.naviSearchInput} />
              <IconButton className={classes.naviSearchBtn}>
                <SearchOutlined />
              </IconButton> */}
              </Box>
              <Box className={classes.naviLinkWrap}>
                <Hidden smDown>
                  <ul className={classes.naviLinks}>
                    <li>
                      {futureAppointment ? (
                        <RouterLink
                          className={menuActive === 'ptschedule' ? 'active' : ''}
                          onClick={() => handleClass('ptschedule')}
                          to="/patient-pt-schedule">
                          <Badge color="secondary" variant="dot">
                            PT Schedule
                          </Badge>
                        </RouterLink>
                      ) : (
                        <RouterLink
                          className={menuActive === 'ptschedule' ? 'active' : ''}
                          onClick={() => handleClass('ptschedule')}
                          to="/patient-pt-schedule">

                          PT Schedule

                        </RouterLink>)
                      }
                    </li>
                    <li>
                      {alert ? (
                        <div>
                          <span
                            style={{
                              height: 5,
                              width: 5,
                              borderRadius: 3,
                              backgroundColor: 'red',
                              display: 'inline-block',
                              position: 'absolute',
                              left: 6
                            }}
                          />
                          <RouterLink
                            className={
                              menuActive === 'ptmessage' ? 'active' : ''
                            }
                            onClick={() => handleClass('ptmessage')}
                            // to=""
                            to="/patient-pt-message"
                          // alert={
                          //   setAlert
                          >
                            <Badge color="secondary" variant="dot">
                              PT Message
                            </Badge>
                          </RouterLink>
                        </div>
                      ) : (
                        <RouterLink
                          className={menuActive === 'ptmessage' ? 'active' : ''}
                          onClick={() => handleClass('ptmessage')}
                          to="/patient-pt-message">
                          PT Message
                        </RouterLink>
                      )}
                    </li>

                    <Hidden mdDown>
                      <li>
                        <Typography className={classes.timeStamp}>
                          {moment().format('DD MMMM YYYY, h:mm a')}
                        </Typography>
                      </li>
                    </Hidden>
                  </ul>
                </Hidden>
                <Hidden mdUp>
                  <IconButton color="inherit" onClick={onSidebarOpen}>
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Box>
            </div>

            <MessageBox
              message={succmsg.succMsg}
              onClose={handleCloseMsg}
              open={succmsg.suhandleCloseMsgcc}
              variant="success"
            />

            <MessageBox
              message={formstate.errMsg}
              onClose={handleClose}
              open={formstate.err}
              variant="error"
            />
          </div>
        </Toolbar>
        <Grid>
          <Modal open={open2} onClose={handleClose2}>
            <ChangePassword
              onCancel={handleClose2}
              showSuccesmsg={handleOpenMsg1}
            />
          </Modal>
        </Grid>
      </AppBar>
      {succmsg1 && renderSuccMsg()}
    </Grid>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

const mapDispatchToProps = {
  logout: logout,
  getAllPatforPtMessages: getAllPatforPtMessages,
  getOrganizationbyId: getOrganizationbyId,
  getAppointmentsByPatientId,
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  organization: state.ehr && state.ehr.organization,
  userID: state.profile && state.profile.userID,
  allPatforPtMessages:
    state.ptmessages &&
    state.ptmessages.allPatforPtMessages &&
    state.ptmessages.allPatforPtMessages.result,
  refreshTopBar: state.ptmessages && state.ptmessages.refreshTopBar,
  allAppointments:
    state.ptschedules &&
    state.ptschedules.allAppointments &&
    state.ptschedules.allAppointments.result,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Topbar))
);
