export default (state = {}, action) => {
  switch (action.type) {
    case 'SUBJECTIVE_TEMPLATES':
      return {
        ...state,
        subjectiveTemplates: action.data
      };

    case 'OBJECTIVE_TEMPLATES':
      return {
        ...state,
        objectiveTemplates: action.data
      };

    case 'ASSESSMENTPLAN_TEMPLATES':
      return {
        ...state,
        assessmentPlanTemplates: action.data
      };

    default:
      return state;
  }
};
