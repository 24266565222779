import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import { getStates, getRelationships } from 'services/util/action';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import {
  CalendarToday
} from '@material-ui/icons/';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  Box,
  FormControlLabel,
  Switch, InputAdornment
} from '@material-ui/core';
import moment from 'moment';

const PrimaryInsuranceEdit = props => {
  const {
    classes,
    className,
    patientinformation,
    ehrID,
    relationships,
    states,
    ...rest
  } = props;

  const [checked, setChecked] = React.useState(false);
  const [values, setValues] = useState({});

  const loadinitialdata = async () => {
    await props.getStates();
    await props.getRelationships(ehrID);
  };

  useEffect(() => {
    loadinitialdata();
  }, [ehrID]);

  useEffect(() => {
    if (patientinformation) {
     
      setValues(patientinformation);
    }
  }, [patientinformation]);

  const handleFieldChange = (
    field,
    value,
    isAddress = false,
    isSpouse = false,
    isEmergency = false,
    isPrimary = false,
    isPrimaryAddress = false,
    isPrimaryGroup = false,
    isPrimaryGroupAddress = false,
    isSecondary = false,
    isSecondaryAddress = false,
    isSecondaryGroup = false,
    isSecondaryGroupAddress = false
  ) => {
    
    props.handleFieldChange(
      field,
      value,
      isAddress,
      isSpouse,
      isEmergency,
      isPrimary,
      isPrimaryAddress,
      isPrimaryGroup,
      isPrimaryGroupAddress,
      isSecondary,
      isSecondaryAddress,
      isSecondaryGroup,
      isSecondaryGroupAddress
    );
  };

  const toggleChecked = () => {
    setChecked(prev => !prev);
  };
  const insuranceType = props.insuranceType;
  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <Grid className={classes.cardSubHeader} item xs={12}>
        <Typography variant="h3">
          {insuranceType == 'Primary' ? 'Primary' : 'Secondary'} Insurance
        </Typography>
        <Box className={classes.cardSubHeaderActions}>
          {insuranceType == 'Primary' ? (
            <FormControlLabel
              control={
                <Switch
                  checked={
                    patientinformation &&
                    patientinformation.primaryInsurance &&
                    patientinformation.primaryInsurance.primaryholder
                  }
                  color="primary"
                  onChange={event =>
                    handleFieldChange(
                      'primaryholder',
                      event.target.value,
                      false,
                      false,
                      false,
                      true
                    )
                  }
                  size="small"
                  value={
                    patientinformation &&
                    patientinformation.primaryInsurance &&
                    patientinformation.primaryInsurance.primaryholder
                  }
                />
              }
              label="Primary Insurance Holder"
              labelPlacement="start"
            />
          ) : null}
        </Box>
      </Grid>
      <CardContent style={{ padding: 10 }}>
        <Grid className={classes.formElements} container spacing={0}>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Last Name"
              name="lName"
              onChange={event =>
                insuranceType == 'Primary'
                  ? handleFieldChange(
                    'lastname',
                    event.target.value,
                    false,
                    false,
                    false,
                    true
                  )
                  : handleFieldChange(
                    'lastname',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance && values.primaryInsurance.lastname
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.lastname
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="First Name"
              name="fName"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'firstname',
                    event.target.value,
                    false,
                    false,
                    false,
                    true
                  )
                  : handleFieldChange(
                    'firstname',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance && values.primaryInsurance.firstname
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.firstname
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Middle Name"
              name="mName"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'middleinitial',
                    event.target.value,
                    false,
                    false,
                    false,
                    true
                  )
                  : handleFieldChange(
                    'middleinitial',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.middleinitial
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.middleinitial
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              label="Relationship"
              name="relationship"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'relationship',
                    event.target.value,
                    false,
                    false,
                    false,
                    true
                  )
                  : handleFieldChange(
                    'relationship',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              select
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.relationship
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.relationship
              }
              variant="outlined">
              <option>Select</option>
              {relationships &&
                relationships.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <DatePicker
              wrapperClassName='myDatePickerWrapper'
              className="reactdatepicker_insurance"
              customInput={
                <TextField
                  fullWidth
                  id="date"
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                      focused: classes.labelFocused
                    }
                  }}
                  InputProps={{
                    classes: {
                      input: classes.textfiledSize
                    },
                    endAdornment: (
                      <InputAdornment>
                        <CalendarToday
                          style={{ height: 16, width: 16 }}
                        />
                      </InputAdornment>
                    )
                  }}
                  defaultValue={moment(new Date()).format('YYYY-MM-DD')}
                  label="Date of birth"
                  margin="dense"
                  variant="outlined"
                />
              }
              format="MM/DD/YYYY"
              onBlur={e => (e.target.placeholder = 'Date of birth')}

              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'dob',
                    event,
                    false,
                    false,
                    false,
                    true
                  )
                  : handleFieldChange(
                    'dob',
                    event,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              onFocus={e => (e.target.placeholder = 'Date of birth')}
              placeholderText="Date of birth"
              selected={
                insuranceType === 'Primary'
                  ? values.primaryInsurance && values.primaryInsurance.dob ? new Date(values.primaryInsurance.dob) : ''
                  : values.secondaryInsurance && values.secondaryInsurance.dob ? new Date(values.secondaryInsurance.dob) : ''


              }
              showMonthDropdown="bool"
              showYearDropdown="bool"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Social Security Number"
              name="ssn"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'socialsecurity',
                    event.target.value,
                    false,
                    false,
                    false,
                    true
                  )
                  : handleFieldChange(
                    'socialsecurity',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.socialsecurity
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.socialsecurity
              }
              variant="outlined"
            />
          </Grid>

          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Address"
              name="ssn"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'address',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    true,
                    false,
                    false
                  )
                  : handleFieldChange(
                    'address',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.address &&
                  values.primaryInsurance.address.address
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.address &&
                  values.secondaryInsurance.address.address
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="City"
              name="city"
              onChange={event =>
                handleFieldChange(
                  insuranceType === 'Primary'
                    ? handleFieldChange(
                      'city',
                      event.target.value,
                      false,
                      false,
                      false,
                      true,
                      true,
                      false,
                      false
                    )
                    : handleFieldChange(
                      'city',
                      event.target.value,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      false,
                      true,
                      true
                    )
                )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.address &&
                  values.primaryInsurance.address.city
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.address &&
                  values.secondaryInsurance.address.city
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              label="State"
              name="State"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'state',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    true,
                    false,
                    false
                  )
                  : handleFieldChange(
                    'state',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    true
                  )
              }
              select
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.address &&
                  values.primaryInsurance.state
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.address &&
                  values.secondaryInsurance.state
              }
              variant="outlined">
              <option>Select</option>
              {states &&
                states.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="ZIP"
              name="zip"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'zip',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    true,
                    false,
                    false
                  )
                  : handleFieldChange(
                    'zip',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.address &&
                  values.primaryInsurance.address.zip
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.address &&
                  values.secondaryInsurance.address.zip
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Name of Employer"
              name="eName"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'nameofemployer',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    false,
                    false
                  )
                  : handleFieldChange(
                    'nameofemployer',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.nameofemployer
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.nameofemployer
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Insurance Company"
               name="ic"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'insurancecompany',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'insurancecompany',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values?.primaryInsurance &&
                 values?.primaryInsurance?.grouppolicy &&
                  values?.primaryInsurance?.grouppolicy?.insurancecompany
                  : values?.secondaryInsurance &&
                   values?.secondaryInsurance?.grouppolicy &&
                  values?.secondaryInsurance?.grouppolicy?.insurancecompany
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Group Number"
              name="gn"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'groupnumber',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'groupnumber',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.groupnumber
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.groupnumber
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="ID Number"
              name="home"
              onChange={event =>
                insuranceType == 'Primary'
                  ? handleFieldChange(
                    'groupid',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'groupid',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                   values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.groupid
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.groupid
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Insurance Company Address"
              name="ica"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'address',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'address',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.address &&
                  values.primaryInsurance.grouppolicy.address.address
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.address &&
                  values.secondaryInsurance.grouppolicy.address.address
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="City"
              name="city"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'city',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true
                  )
                  : handleFieldChange(
                    'city',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.address &&
                  values.primaryInsurance.grouppolicy.address.city
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.address &&
                  values.secondaryInsurance.grouppolicy.address.city
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              label="State"
              name="State"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'state',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'state',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true
                  )
              }
              select
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.address &&
                  values.primaryInsurance.grouppolicy.address.state
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.address &&
                  values.secondaryInsurance.grouppolicy.address.state
              }
              variant="outlined">
              <option>Select</option>
              {states &&
                states.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="ZIP"
              name="zip"
              onChange={event =>
                insuranceType === 'Primary'
                  ? handleFieldChange(
                    'zip',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'zip',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    true
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.address &&
                  values.primaryInsurance.grouppolicy.address.zip
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.address &&
                  values.secondaryInsurance.grouppolicy.address.zip
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Insurance Phone Number"
              name="splname"
              onChange={event =>
                insuranceType == 'Primary'
                  ? handleFieldChange(
                    'phonenumber',
                    event.target.value,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    false
                  )
                  : handleFieldChange(
                    'phonenumber',
                    event.target.value,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    true,
                    false,
                    true,
                    false
                  )
              }
              value={
                insuranceType === 'Primary'
                  ? values.primaryInsurance &&
                  values.primaryInsurance.grouppolicy &&
                  values.primaryInsurance.grouppolicy.phonenumber
                  : values.secondaryInsurance &&
                  values.secondaryInsurance.grouppolicy &&
                  values.secondaryInsurance.grouppolicy.phonenumber
              }
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PrimaryInsuranceEdit.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getStates,
  getRelationships
};
const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  states: state.util.states,
  relationships: state.util.relationships
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PrimaryInsuranceEdit))
);
