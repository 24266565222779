import { errorHandler } from '../error/action';
import http from 'services/http';

export const loadOrders = (
  patientId,
  rowsPerPage = 10,
  page = 0
) => async dispatch => {
  try {
    const res = await http.get(
      `/patients/${patientId}/orderHx?limit=${rowsPerPage}&page=${page}`
    );
    if (res) {
      dispatch({
        type: 'ORDERS_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const downloadPDF = (
  ehrID,
  patientId,
  providerId,
  type,
  orderId
) => async () => {
  try {
    const res = await http.get(
      `ehr/${ehrID}/patients/${patientId}/provider/${providerId}/orderhx/pdf?type=${type}&orderIds=${orderId}`,
      { responseType: 'arraybuffer' }
    );
    if (res) {
      return { status: true, result: { ...res } };
    }
    return { status: false };
  } catch (error) {
    // errorHandler(error);
    return { status: false };
  }
};
