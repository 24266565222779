import palette from '../palette';
export default {
  root: {
    backgroundColor: 'white',
    '& $notchedOutline': {
      borderRadius: 1
    },
    '&:hover $notchedOutline': {
      borderColor: palette.primary.light
    },
    '&$focused  $notchedOutline': {
      borderColor: palette.primary.main,
      borderWidth: 1
    },
    '&$focused$error  $notchedOutline': {
      borderColor: 'red'
    }
  },
  notchedOutline: {}
};
