export default {
  root: {
    color: '#ccc',
    '&$focused  ': {
      color: 'white',
      //backgroundColor: palette.secondary.main,
      padding: '5px 10px',
      marginLeft: -4,
      height: 25,
      marginTop: -2,
      borderRadius: 1
    },
    '&$focused$error  ': {
      backgroundColor: 'red'
    }
  }
};
