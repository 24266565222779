import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  withStyles,
  Slide,
  Grid,
  Button,
  Box
} from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style';
import {
  SearchOutlined,
  CancelOutlined,
  PlaylistAddCheckOutlined,
  PageviewOutlined
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPatientsOnSearch } from 'services/patient/action';
import {
  clearPtDoc, 
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, 
} from 'services/home/action';
import moment from 'moment';  
import { loadOrders } from 'services/orders/action';

const PatientSearch = props => {
  const {
    classes,
    className,
    ehrID,
    isptMessages,
    isptSchedule,
    isScheduleAppointment,
    isfromScheduleAppointment,
    fullWidth,
    fromHomeSearch,
    clearPtDoc, 
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, loadOrders,
    ...rest
  } = props;
  const [search, setSearch] = useState(null);
  const [showPatientsList, setShowPatientsList] = useState(false);
  const [patients, setPatients] = useState({});

  const handleOpenResponse = patient => {
    setShowPatientsList(false);
    props.handleOpenResponse(patient);
  };

  const handleOpenSendInvite = patient => {
    setShowPatientsList(false);
    props.handleOpenSendInvite(patient);
  };

  const handleOpenResendInvite = patient => {
    setShowPatientsList(false);
    props.handleOpenResendInvite(patient);
  };

  useEffect(() => {
    if (props.searchList && search !== '' && search !== null) {
      setPatients(props.searchList);
      setShowPatientsList(true);
    } else {
      setPatients(null);
      setShowPatientsList(false);
    }
  }, [props.searchList]);

  const handleChange = (field, value) => {
    setSearch(value);
    props.getPatientsOnSearch(ehrID, value); 
  };

 
    async function fetchData(patientId) { 
      clearPtDoc()
      getPatientAllClinicalDocuments(patientId, ehrID)
      getPatientAllMiscellaneousDocuments(patientId, ehrID)
      loadOrders(patientId);
      getPatientCharts(patientId, 0) 
    }
  
  const clear = () => {
      props.clear();
  };

  const checkPatientActiveorInactive = patient => {
    //  const staffMessage = data.filter(c => c.patientId === patient.id);
    if (patient.credentials == null) {
      handleOpenSendInvite(patient);
    } else if (!patient.credentials.enabled) {
      // handleOpenResendInvite(patient, staffMessage[0]);
      handleOpenResendInvite(patient);
    } else {
      // handleOpenResponse(staffMessage[0]);
      handleOpenResponse(patient);
    }
  };

  const handleOpenPatientIssue = patient => {
    setShowPatientsList(false);
    props.handleOpenPatientIssue('', patient); 
  };

  const handleOpenAddPatient = () => {
    setShowPatientsList(false);
    props.handleOpenAddPatient(search);
  };

  const handleAppointmentHistory = patient => {
    setShowPatientsList(false);
    props.handleAppointmentHistory(
      patient.id,
      (patient.lastname || '') + ' ' + (patient.firstname || '')
    );
  };

  const handleScheduleAppointment = patient => {
    setSearch('');
    setShowPatientsList(false);
    props.handleScheduleAppointment(
      patient.id,
      (patient.lastname || '') + ' ' + (patient.firstname || ''),
      patient.emailaddress,
      patient.credentials ? patient.credentials.id : 0
    );
  };

  const handleChoosePatient = patient => { 
    setSearch('');
    setShowPatientsList(false);
    props.handleChoosePatient(patient);
    fetchData(patient.id)
  };

  return (
    <Grid
      item
      container
      xs={12}
      className={classes.searchWrap}
      style={{ padding: fullWidth ? '0px' : '10px', flexDirection: isptSchedule ? 'row-reverse' : 'inherit' }}>
      <Grid className={classes.searchCol1} item xs={12} md={fullWidth ? 12 : 7}>
        <Typography>{fullWidth}</Typography>
        {showPatientsList ? ( 
            <div className={classes.popSearch}>
              <PerfectScrollbar>
                <div style={{ maxHeight: 200 }}>
                  <Table className={classes.reminderTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Patient ID</TableCell>
                        <TableCell>Patient Name</TableCell>
                        <TableCell align="center">DOB</TableCell>
                        <TableCell align="center">Sex</TableCell>
                        <TableCell align="center" noWrap="true">
                          {isptMessages
                            ? 'New Message'
                            : isptSchedule
                            ? 'View'
                            : isScheduleAppointment
                            ? 'Add'
                            : fromHomeSearch
                            ? 'Choose'
                            : 'New Issue'}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {patients && patients.length > 0 ? (
                        patients.map(patient => (
                          <TableRow>
                            <TableCell width={92}>{patient.id}</TableCell>
                            <TableCell>
                              {patient.lastname}, {patient.firstname}{' '}
                              {patient.middleinitial}
                            </TableCell>
                            <TableCell width={72} align="center">
                              {patient.dob
                                ? moment(patient.dob).format('MM/DD/YYYY')
                                : ''}
                            </TableCell>
                            <TableCell width={72} align="center">
                              {patient.gender && patient.gender.charAt(0)}
                            </TableCell>
                            <TableCell width={72} align="center">
                              <IconButton
                                // onClick={props.onClose}
                                size="small"
                                color="primary"
                                title={
                                  isptMessages
                                    ? 'Add Patient Message'
                                    : isptSchedule
                                    ? 'View'
                                    : isScheduleAppointment
                                    ? 'Add'
                                    : fromHomeSearch
                                    ? 'Choose'
                                    : 'Add Patient Issue'
                                }
                                onClick={() => {
                                  isptMessages
                                    ? checkPatientActiveorInactive(patient)
                                    : isptSchedule
                                    ? handleAppointmentHistory(patient)
                                    : isScheduleAppointment
                                    ? handleScheduleAppointment(patient)
                                    : fromHomeSearch
                                    ? handleChoosePatient(patient)
                                    : handleOpenPatientIssue(patient);
                                }}>
                                {isptSchedule ? (
                                  <PageviewOutlined />
                                ) : (
                                  <PlaylistAddCheckOutlined />
                                )}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow className={classes.tableRow}>
                          <TableCell
                            colSpan={10}
                            style={{ textAlign: 'center' }}
                            className={classes.tableCell}>
                            No patients available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
              <Box className={classes.popButtonWrap}>
                <Typography>
                  Organization has {patients && patients.length} patients
                </Typography>
                <IconButton
                  title="CLOSE"
                  onClick={() => {
                    setShowPatientsList(false);
                    setSearch('');
                  }}
                  aria-label="upload picture"
                  component="span">
                  <CancelOutlined />
                </IconButton>
              </Box>
            </div> 
        ) : null}
        <div style={{ position: 'relative', width: '100%' }}>
          <TextField
            id="outlined-basic"
            placeholder={isptSchedule ? 'Appointment Search' : 'Patient Search'}
            variant="outlined"
            className={classes.textBox}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline
              }
            }}
            onChange={event => handleChange('searchVal', event.target.value)}
            value={search}
          />
          <Button
            onClick={() => {
              search !== '' && search !== null
                ? setShowPatientsList(true)
                : setShowPatientsList(false);
            }}
            variant="contained"
            color="primary"
            className={classes.searchBtn}>
            <SearchOutlined />
          </Button>
        </div>
        {!isptSchedule && !isfromScheduleAppointment && !fromHomeSearch && (
          <Button
            variant="contained"
            color="primary"
            className={classes.viewBtn}
            onClick={() => clear()}>
            View All
          </Button>
        )}
        {isScheduleAppointment && (
          <Button
            onClick={() => handleOpenAddPatient()}
            variant="contained"
            color="primary"
            style={{
              width: '125px',
              backgroundColor: '#5698D4',
              color: '#fff'
            }}
            className={classes.viewBtn}>
            Add Patient
          </Button>
        )}
      </Grid>
      {isptSchedule && (
        <Grid item xs={4} lg={5}>
          <Button
          style={{float:'left',marginLeft: 0}}
            onClick={props.handleOpenScheduleAppointment}
            variant="contained"
            color="primary"
            className={clsx(classes.viewBtn, classes.SchBtn)}>
            Schedule Appointment
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

PatientSearch.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getPatientsOnSearch,clearPtDoc, 
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, loadOrders
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  searchVal: state.search,
  searchList:
    state.patient && state.patient.searchList && state.patient.searchList.result
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PatientSearch))
);
