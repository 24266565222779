import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedRouteWithLayout = ({ layout: Layout, component: Component, ...rest }) => {

  const isAuth = rest.profile ? true : false;

  return (
    <Route
      {...rest}
      render={props =>
        isAuth ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
            <Redirect
              to={{
                pathname: "/sign-in",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
};


const mapDispatchToProps = {

};

const mapStateToProps = state => ({
  profile: state.profile
});


export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRouteWithLayout)
