import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MessageBox from 'util/messageBox';
import validate from 'validate.js';
import schema from './schema';
import { CloseOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import styles from './style';
// import { Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Box,
  Button,
  TextField,
  Modal,
  IconButton,
  Grid,
  Hidden
  //   CircularProgress
} from '@material-ui/core';
//  import { changePassword } from '../../../../store/user/action';
import { changepassword } from '../../../../services/profile/action';
import { Prompt } from 'react-router-dom';
const ChangePassword = props => {
  const { classes, className, ehrID, userID, username, ...rest } = props;
  let [isBlocking, setIsBlocking] = useState(false);
  const [values, setValues] = useState({
    id: 0,
    username: '',
    password: '',
    passwordconfirmation: '',
    succMsg: false,
    touched: {},
    errors: {},
    isValid: false
  });

  const hasError = field =>
    values &&
    values.touched &&
    values.errors &&
    values.touched[field] &&
    values.errors[field]
      ? true
      : false;
  const handleFieldChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
    if (values) {
      setIsBlocking(true);
    }
  };
  // const handleCloseAll = () => {
  //   setValues({ ...values, succMsg: false });
  //   // props.onCancel();
  // };

  const validateForm = () => {
    const newState = { ...values };
    const errors = validate(values, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);

    return newState.isValid;
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      setValues({ ...values, isLoading: true });
      props
        .changepassword(ehrID, userID, { password: btoa(values.password) })
        .then(status => {
          if (status) {
            // props.onCancel();
            setValues({ ...values, succMsg: true });
            // props.handleOpenMsg1();
            props.showSuccesmsg();
            props.onCancel();
          } else {
            setValues({ ...values, isLoading: false, succMsg: false });
          }
        });
    }

    setIsBlocking(false);
    // props.onCancel();
  };

  return (
    <Grid>
      <Prompt
        when={isBlocking}
        message="There are unsaved changes, do you wish to discard them?"
      />

      <Box
        className={classes.modalWrap}
        style={{ width: '95%', maxWidth: 650 }}>
        <Box className={classes.modalHead}>
          <Typography variant="h2">Change Password</Typography>
          <IconButton
            aria-label="delete"
            className={classes.modalClose}
            onClick={props.onCancel}>
            <CloseOutlined />
          </IconButton>
        </Box>

        <Box className={classes.modalBody} style={{ height: '90%' }}>
          <Grid
            container
            spacing={3}
            style={{ paddingRight: 15, marginRight: -15 }}
            className={classes.contentWrap}
            xs={12}>
            <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
              <TextField
                InputLabelProps={{
                  classes: {
                    focused: classes.labelFocused
                  }
                }}
                disabled
                label="Username"
                name="uName"
                required
                variant="outlined"
                color="Primary"
                value={username}
                style={{ width: '550px' }}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
              <TextField
                InputLabelProps={{
                  classes: {
                    focused: classes.labelFocused
                  }
                }}
                type="password"
                label="New Password"
                name="npw"
                required
                variant="outlined"
                color="Primary"
                helperText={
                  hasError('password') ? values.errors.password[0] : null
                }
                error={values.errors && values.errors.password}
                value={values.password}
                onChange={event =>
                  handleFieldChange('password', event.target.value)
                }
                style={{ width: '550px' }}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: 20 }}>
              <TextField
                InputLabelProps={{
                  classes: {
                    focused: classes.labelFocused
                  }
                }}
                type="password"
                label="Confirm New Password"
                name="cnpw"
                required
                variant="outlined"
                color="Primary"
                helperText={
                  hasError('passwordconfirmation')
                    ? values.errors.passwordconfirmation[0]
                    : null
                }
                style={{ width: '550px' }}
                error={values.errors && values.errors.passwordconfirmation}
                value={values.passwordconfirmation}
                onChange={event =>
                  handleFieldChange('passwordconfirmation', event.target.value)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'right',
                marginTop: '25px'
              }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>

        {/* <MessageBox
          open={values.succMsg}
          variant="success"
          onClose={handleCloseAll}
          message="Password has been changed successfully"
        /> */}
      </Box>
    </Grid>
  );
};
ChangePassword.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  changepassword
};

const mapStateToProps = state => ({
  titles: state.util.titles,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  username: state.profile && state.profile.name
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ChangePassword))
);
