import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../../style';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import {
  DeleteOutline,
  EditOutlined,
  FeaturedPlayListOutlined,
  AddLocationOutlined,
  PhoneOutlined,
  DescriptionOutlined,
  BusinessOutlined
} from '@material-ui/icons';
import { formatPhoneNumber } from 'util/helper';
const View = props => {
  const { classes, className, ehrID, organization, ...rest } = props;

  const [values, setValues] = useState({});
  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    deletePracticeId: ''
  });

  useEffect(() => {
    if (organization !== undefined) {
      setValues(organization);
    }
  }, [organization]);

  const editPractice = data => {
    if (data) {
      if (data.businessphones && data.businessphones.length == 0) {
        data.businessphones = [''];
      }
      if (data.faxnumbers && data.faxnumbers.length == 0) {
        data.faxnumbers = [''];
      }
      props.editPractice(data);
    }
  };

  const handleDelete = practiceID => {
    setHandleDeletePopup({
      showDeletePopup: true,
      deletePracticeId: practiceID
    });
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup({ showDeletePopup: false });
  };

  const getValuesofConfig = () => {
    props.getValuesofConfig(values, true);
  };

  const handleDeletePractice = deletePracticeId => {
    if (deletePracticeId > 0) {
      const newState = { ...values };
      let practice = newState.practice;
      let index = practice.findIndex(c => c.id === deletePracticeId);
      newState.practice.splice(index, 1);
      setValues(newState);
      getValuesofConfig();
      setHandleDeletePopup({ showDeletePopup: false });
    }
  };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <CardContent style={{ margin: -16 }}>
        <Grid container>
          <Grid item xs={12} className={classes.cardHeader}>
            <Typography variant="h2">
              View/Manage Practice Information
            </Typography>
          </Grid>
          {organization &&
            organization.practice &&
            organization.practice.map(item => (
              <React.Fragment>
                <Grid item xs={12} className={classes.cardSubHeader}>
                  <Typography variant="h3">
                    <BusinessOutlined className={classes.titleIcon} />
                    {item.companyname}
                  </Typography>
                  <Box className={classes.cardSubHeaderActions}>
                    <Tooltip title="Edit a practice">
                      <IconButton
                        size="small"
                        onClick={() => editPractice(item)}>
                        <EditOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete a practice">
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(item.id)}>
                        <DeleteOutline />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.ClinicDetailsWrap}>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h2">
                      <FeaturedPlayListOutlined />
                      Facility NPI Number
                    </Typography>
                    <Typography variant="h4">{item.facilityNPI}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h2">
                      <AddLocationOutlined />
                      Address
                    </Typography>
                    <Typography variant="h4">
                      {item.address &&
                        (item.address.address
                          ? item.address.address + ', '
                          : '') +
                          (item.address.city ? item.address.city + ', ' : ' ') +
                          (item.address.state
                            ? item.address.state + ' '
                            : ' ') +
                          (item.address.zip ? item.address.zip : ' ')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h2">
                      <PhoneOutlined />
                      Phone Number
                    </Typography>
                    <Typography variant="h4">
                      {item.businessphones &&
                        item.businessphones.map(phone => (
                          <p>{formatPhoneNumber(phone)} </p>
                        ))}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography variant="h2">
                      <DescriptionOutlined />
                      Fax
                    </Typography>
                    {item.faxnumbers &&
                      item.faxnumbers.map(fax => <p>{fax}</p>)}
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
        </Grid>
      </CardContent>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={handledeletepopup.showDeletePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Account Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this practice location?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : ( */}
          <Button
            onClick={() =>
              handleDeletePractice(handledeletepopup.deletePracticeId)
            }
            color="primary">
            Yes
          </Button>
          {/* )} */}
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

View.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  states: state.util.states,
  ehrID: state.profile && state.profile.ehrID,
  organization: state.ehr && state.ehr.organization
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(View))
);
