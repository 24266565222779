import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import { TextField, Box, Button } from '@material-ui/core';
import { savePtIssueResponse } from 'services/ptissues/action';
import { connect } from 'react-redux';
const ProviderResponse = props => {
  const { classes, providerresponsedata } = props;

  const [data, setData] = useState([]);
  // const [poploading, setPopLoading] = useState(false);

  useEffect(() => {
    if (providerresponsedata !== undefined) {
      setData(providerresponsedata);
    }
  }, [providerresponsedata]);

  const handleFieldChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleSavePtIssueResponse = async () => {
    // setPopLoading(true);
    const res = await props.savePtIssueResponse(data);
    if (res) {
      props.onCancel();
    }
    // setPopLoading(false);
  };

  return (
    <React.Fragment>
      <Box className={classes.modalBody}>
        <TextField
          placeholder="Provider Response"
          multiline
          rows="6"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event =>
            handleFieldChange('providerResponse', event.target.value)
          }
          value={data.providerResponse}
        />
      </Box>
      <Box className={classes.modalFooter}>
        <Button
          style={{ marginRight: 10 }}
          variant="outlined"
          size="small"
          onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          onClick={() => handleSavePtIssueResponse()}
          variant="contained"
          color="primary"
          size="small"
          disabled={
            data.providerResponse === null ||
            data.providerResponse === undefined ||
            data.providerResponse.trim() === ''
          }
          style={{ borderRadius: 4 }}>
          Save
        </Button>
      </Box>
    </React.Fragment>
  );
};

ProviderResponse.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  savePtIssueResponse
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProviderResponse))
);
