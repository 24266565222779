import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../../../style';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  TablePagination
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons/';
import { getVitalSigns } from 'services/soap/action';
import { connect } from 'react-redux';
import moment from 'moment'; 

const VSHx = props => {
  const { classes, patientId, vitalSigns, totalElements } = props;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    props.getVitalSigns(patientId, page, rowsPerPage);
  }, []);

  const handleCopyVsHx = item => {
    props.handleCopyVsHx(item);
  };

  // const handlefiledchange = bodymassindex => {
  //   let bodymi = bodymassindex;
  //   return bodymi;

  //   bodymi = bodymi ? Number(bodymi.toPrecision(3)).toFixed(1) : '';
  // };
  const handleChangePage = (event, page) => {
    setPage(page);
    props.getVitalSigns(patientId, page, rowsPerPage);
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = event => {
    const rowsPerPage = event.target.value;
    setRowsPerPage(rowsPerPage);
    props.getVitalSigns(patientId, page, rowsPerPage);
  };

  return (
    <div>
      <PerfectScrollbar>
        <div style={{ maxHeight: 280 }}>
          <Table className={classes.generalTable}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center" colSpan={8}>
                  Vital Signs
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vitalSigns && vitalSigns.length > 0 ? (
                vitalSigns.map((item, idx) => {
                  return (
                    <TableRow>
                      <TableCell align="center">
                        {item.createdDate
                          ? moment(item.createdDate).format('MM/DD/YYYY')
                          : ''}
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.bloodpressure ? 'BP' : ''}
                        </Typography>
                        <Typography>
                          {item.bloodpressure ? item.bloodpressure : ''}
                        </Typography>
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.temperature ? 'Temp' : ''}
                        </Typography>
                        <Typography>
                          {item.temperature ? item.temperature : ''}
                        </Typography>
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.heartrate ? 'HR' : ''}
                        </Typography>
                        <Typography>
                          {item.heartrate ? item.heartrate : ''}
                        </Typography>
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.respiratoryrate ? 'RR' : ''}
                        </Typography>
                        <Typography>
                          {item.respiratoryrate ? item.respiratoryrate : ''}
                        </Typography>
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.weight ? 'Wt' : ''}
                        </Typography>
                        <Typography>
                          {item.weight ? item.weight : ''}
                        </Typography>
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.height ? 'Ht' : ''}
                        </Typography>
                        <Typography>
                          {item.height ? item.height : ''}
                        </Typography>
                      </TableCell>
                      <TableCell width="80">
                        <Typography variant="caption">
                          {item.bodymassindex ? 'BMI' : ''}
                        </Typography>
                        <Typography>
                          {item.bodymassindex &&
                            Number(item.bodymassindex).toFixed(2)}
                        </Typography>
                      </TableCell>
                      <TableCell width={40}>
                        <IconButton>
                          <FileCopyOutlined
                            onClick={() => handleCopyVsHx(item)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={10}
                    style={{ textAlign: 'center' }}>
                    No vital information available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      {vitalSigns && vitalSigns.length > 0 ? (
        <TablePagination
          backIconButtonProps={{
            'aria-label': 'Previous Page'
          }}
          component="div"
          className={classes.paginationWrap}
          count={totalElements}
          nextIconButtonProps={{
            'aria-label': 'Next Page'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      ) : (
        ''
      )}
    </div>
  );
};

VSHx.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getVitalSigns
};

const mapStateToProps = state => ({
  vitalSigns:
    state.soap &&
    state.soap.userVitalSigns &&
    state.soap.userVitalSigns.content,
  totalElements:
    state.soap &&
    state.soap.userVitalSigns &&
    state.soap.userVitalSigns.totalElements
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VSHx))
);
