import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { Provider } from "react-redux";
import store from "./store";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './routes';
import { isAlive } from "services/profile/action";
import './css/style.css';
import './css/modal.css';
import "bootstrap/dist/js/bootstrap.js";
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import "../src/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const browserHistory = createBrowserHistory();

// Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
//   draw: chartjs.draw
// });

// Bind persist store with redux store
const persistor = persistStore(store);

validate.validators = {
  ...validate.validators,
  ...validators
};

export default class App extends Component {

  onBeforeLift = async () => {
    const res = await isAlive();
    if (res) {
      const path = browserHistory.location.pathname;
      if (path === "/" || path === "/sign-in") {
        // browserHistory.push("/ez/default");
      }
    }
    return true;
  };

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <PersistGate
            persistor={persistor}
            onBeforeLift={() => {
              return this.onBeforeLift();
            }}
          // loading={this.loader()}
          >
            <Routes />
          </PersistGate>
        </ThemeProvider>
      </Provider>
    );
  }
}
