export default theme => ({
  dateWrap: {
    marginBottom: 10,
    flex: 1,
    '& .react-calendar': {
      border: 'none',
      paddingBottom: 10,
      width: '100%',
      fontFamily: 'Arial',
      fontSize: 13.3333
    }
  },
  dateHead: {
    background: '#EFF5FC',
    border: '1px solid #D4E5F9',
    fontWeight: 600,
    fontSize: 14,
    padding: 16,
    '& p': {
      fontSize: 14,
      fontWeight: 600
    }
  },
  textBox: {
    width: '100%',
    '& input': {
      padding: '8px 10px;',
      '&::placeholder': {
        fontFamily: 'Lato',
        fontSize: 12,
        color: '#B9B9B9',
        fontWeight: 400
      }
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC'
    }
  },
  textBoxGlobalSearch: {
    '& input': {
      padding: '6px 10px;',
      backgroundColor: '#fbfbfb',
      '&:focus': {
        backgroundColor: '#fff',
      },
    },
    '& $notchedOutline': {
      border: 'none'
    }
  },
  naviSearchBtn: {
    position: 'absolute',
    right: 0,
  },
  dateBody: {
    border: '1px solid #D4E5F9',
    borderTop: 'none',
    '& .react-calendar__navigation': {
      borderBottom: '1px solid #D4E5F9',
      height: 50,
      marginBottom: 5,
      '& button:enabled:hover': {
        background: '#EFF5FC'
      }
    },
    '& .react-calendar__navigation__label': {
      color: '#0D256E',
      fontSize: 16,
      fontFamily: 'Lato'
    },
    '& .react-calendar__navigation__arrow': {
      color: '#0D256E',
      fontSize: 20,
      fontFamily: 'Lato'
    },
    '& .react-calendar__month-view__weekdays__weekday abbr': {
      color: '#B2B2B2',
      fontSize: 11,
      textDecoration: 'none',
      textTransform: 'capitalize',
      fontWeight: '400',
      fontFamily: 'Lato',
      cursor: 'auto'
    },
    '& .react-calendar__month-view__days__day abbr': {
      color: '#0D256E',
      fontFamily: 'Lato',
      fontSize: '13.3333px'
    },
    '& .react-calendar__tile--now': {
      background: '#488DFF',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#3478e8'
      },
      '& abbr': {
        color: '#fff',
        fontWeight: '600'
      }
    },
    '& .react-calendar__tile--danger': {
      background: '#ff0000',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: '#ff9900'
      },
      '& abbr': {
        color: '#fff',
        fontWeight: '600'
      }
    },
    '& .react-calendar__tile--active': {
      background: '#DDAC5D!important',
      '& abbr': {
        color: '#fff'
      }
    },
    '& .react-calendar__month-view__days__day--weekend abbr': {
      color: '#d10000!important'
    },
    '& .react-calendar__tile--rangeBothEnds': {
      background: '#DDAC5D'
    },
    '& .react-calendar__tile--now.react-calendar__month-view__days__day--weekend abbr': {
      color: '#fff!important'
    }
  },
  notchedOutline: {},
  reminderTable: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '& td': {
      padding: '8px 10px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px'
    },
    '& th': {
      padding: '8px 10px'
    }
  },
  searchWrap: {
    padding: 10,
    maxHeight: 53
  },
  searchCol1: {
    position: 'relative',
    display: 'flex'
  },
  searchBtn: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    top: 0,
    width: 40,
    backgroundColor: '#82c3ff',
    boxShadow: 'none!important',
    border: '1px solid #3397F3',
    padding: 5,
    '&:hover': {
      backgroundColor: '#51a6f5',
      boxShadow: 'none'
    }
  },
  popSearch: {
    border: '1px solid #3397F3',
    position: 'absolute',
    top: 32,
    left: 0,
    right: 0,
    zIndex: 111,
    background: '#fff',
    boxShadow: '0px 6px 6px rgba(0,0,0,.2)',
    maxWidth: 600,
    minWidth: 470
  },
  popSearchGlobal: {
    width: '100%',
    borderTop: 'none',
    maxWidth: 'inherit',
    borderRadius: '0 0 6px 6px'
  },
  globalSearchWrap: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  popButtonWrap: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'right',
    '& p': {
      color: '#ababab',
      fontSize: 13,
      fontStyle: 'italic'
    }
  },
  SchBtn: {
    width: '175px!important',
    backgroundColor: '#5698D4!important',
    color: '#fff!important',
    float: 'right'
  },
  viewBtn: {
    height: 33,
    padding: '2px 10px',
    borderRadius: 0,
    marginLeft: 10,
    boxShadow: 'none',
    background: '#D0D0D0',
    color: '#000',
    textTransform: 'capitalize',
    width: 84,
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: '#e0dfdf',
      boxShadow: 'none'
    }
  },
  inlineLink: {
    cursor: 'pointer',
    color: '#3397f3',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalBody: {
    padding: '10px 0'
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalFooter: {
    padding: '20px 0px 10px 0px',
    textAlign: 'right'
  },
  labelRoot: {
    color: '#ccc',
    backgroundColor: 'transparent'
  },
  labelFocused: {
    backgroundColor: theme.palette.primary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },

  textField: {
    fontSize: 12,
    borderColor: '#ff0000',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: 1,
    '&::placeholder': {
      color: 'blue',
      fontSize: 10
    },
    '& + & ': {
      marginTop: theme.spacing(2)
    },
    '&:focus $notchedOutline': {
      borderColor: 'green'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC',
      borderRadius: 1
    },
    '& input': {
      fontSize: 14
    },
    '& select': {
      fontSize: 14
    },
    '& textarea': {
      fontSize: 14
    }
  },
  chartReviewTable: {
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20
    },
    '& td': {
      padding: '8px 10px',
      color: '#4A4A4A',
      fontSize: 12,
      lineHeight: '16px',
      backgroundColor: '#EFF5FC'
    },
    '& th': {
      padding: '11px 10px',
      backgroundColor: '#fff',
      fontWeight: 600,
      fontSize: 12
    }
  },
  rowStyleOverlay: {
    top: 80,
    border: '1px solid' + theme.palette.primary.main,
    position: 'absolute',
    zIndex: 5,
    width: 600,
    maxHeight: 200,
    background: '#fff',
    left: 12,
    overflow: 'hidden',
    overflowY: 'scroll',
    '& .MuiGrid-container': {
      '&:hover': {
        backgroundColor: '#f5f5f5',
        cursor: 'pointer'
      }
    },
    '&::-webkit-scrollbar': {
      width: 8,
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#ccc',
      borderRadius: 4
    }
  },
  datePickerWrap: {
    position: 'relative',
    width: '100%',
    '& .react-datepicker-wrapper': {
      display: 'block',
      '& input': {
        padding: '18px 15px'
      }
    },
    '& .react-datepicker-popper': {
      zIndex: 1000,
      marginTop: 0,
      '& .react-datepicker__triangle': {
        left: '200px!important'
      }
    },
    '& .MuiFormLabel-root.Mui-focused': {
      backgroundColor: theme.palette.primary.main,
      color: 'white!important',
      padding: '5px 10px',
      marginLeft: -4,
      height: 25,
      paddingTop: 5,
      marginTop: -2
    }
  }
});
