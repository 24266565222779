import { errorHandler } from '../error/action';
import http from 'services/http';

export const getUserCPTCodes = () => async dispatch => {

    try {
        const res = await http.get(`/user/cpt`);
        if (res) {
            dispatch({
                type: 'USER_CPT',
                data: res
            });
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const getSystemCPTCodes = (ehrID) => async dispatch => {

    try {
        const res = await http.get(`/ehr/${ehrID}/cpt`);
        if (res) {
            dispatch({
                type: 'SYSTEM_CPT',
                data: res
            });
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const addUserCPTCode = (cpt) => async dispatch => {
    try {
        const res = await http.post(`/user/cpt`, cpt);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const addSystemCPTCode = (ehrID, cpt) => async dispatch => {
    try {
        const res = await http.post(`/ehr/${ehrID}/systemcpt`, cpt);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const deleteSystemCPTCode = (ehrID, cpt) => async dispatch => {
    try {
        const res = await http.delete(`/ehr/${ehrID}/cpt?codes=${cpt}`);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const deleteUserCPTCode = (cpt) => async dispatch => {
    try {
        const res = await http.delete(`/user/cpt?codes=${cpt}`);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const uploadFile = (ehrID, data) => async dispatch => {
    try {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('cpttype', data.icdType);

        const res = await http.post(`/ehr/${ehrID}/cpt`, formData);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};





