import {
  Button, Card,
  CardContent, CircularProgress, Divider,
  Grid, IconButton, Modal, TextField,
  Typography
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CloudUploadOutlined, SearchOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getDocInfo, getDocuments, getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments, clearPtDoc, getDocumentCategories,
  getPatientCharts, getPatientDocuments, getPatientMiscDocuments, uploadFilePDF
} from 'services/home/action';
import { loadOrders } from 'services/orders/action';
import { computeAge } from 'util/helper';
import { DocViewer, PatientDetails } from 'views/Common/components';
import styles from '../../../style';
import { UploadDoc } from './';

const Details = props => {
  const {
    classes,
    className,
    locations,
    ehrID,
    profile,
    documentsList,
    documentInfo,
    miscDocumentInfo,
    userID, getPatientAllClinicalDocuments,
    getPatientAllMiscellaneousDocuments,
    getPatientCharts,
    clearPtDoc,
    getDocumentCategories,
    ...rest
  } = props;
  let [isClinical, setIsClinical] = useState(false);
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [miscDataDocViewer, setMiscDataDocViewer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [miscDoc, setMiscDoc] = useState([]);
  const [doc,setDoc]= useState([]);
 const [anchorEl] = useState(null);
  const [total, setTotal] = useState(null)
  const [state, setState] = useState({ page: 0, count: 0 });
  const [openUpload, setOpenUpload] = useState({
    show: false,
    patientId: 0
  });
  const [isDetails, setIsDetails] = useState({ show: false, patient: null });
  const [openDoc, setOpenDoc] = useState(false);

  useEffect(() => {
    props.getDocumentCategories(ehrID);
  }, []);

  const handleDeleteDoc = (ptid) => { 
    setOpenDoc(false);
    refreshDoc(ptid)
    setDataDocViewer([])
  };

  const handleCloseDoc = () => {
    setOpenDoc(false);
    setDataDocViewer([])
  };

  const refreshDoc =  (ptid) => {
   clearPtDoc() 
   getPatientAllClinicalDocuments(ptid, ehrID)
   getPatientAllMiscellaneousDocuments(ptid, ehrID)
   props.loadOrders(ptid);
  }



  useEffect(() => {
    props.getDocuments(ehrID, state.page, search);
  }, []);

  useEffect(() => {
    props.getDocuments(ehrID, state.page, search);
  }, [search]);

  useEffect(() => {
    if (documentsList !== undefined) {
      setState({ ...state, count: documentsList.totalElements });
      let datatopush = [];
      documentsList &&
        documentsList.content.forEach(function (item) {
          datatopush.push({
            patientName:
              (item.lastname ? item.lastname : '') +
              ', ' +
              (item.firstname ? item.firstname : '') +
              ' ' +
              (item.middleinitial ? item.middleinitial : ''),
            patientId: item.id,
            dob: item.dob
              ? moment(item.dob).format('MM/DD/YYYY') +
              ' (' +
              computeAge(item.dob) +
              ' yrs)'
              : '',
            gender: item.gender,
            updatedDate: moment(item.updatedDate).format('MM/DD/YYYY'),
            patient: item
          });
        });
      setData(datatopush);
      setLoading(false);
    }
  }, [documentsList]);

  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  useEffect(() => {
    if (miscDocumentInfo !== undefined) {
      setMiscDataDocViewer(miscDocumentInfo);
    }
  }, [miscDocumentInfo]);

  const handleOpenUpload = patId => {
    setOpenUpload({ show: true, patientId: patId });
  };
  const handleCloseUpload = async () => {
    setOpenUpload({ show: false });
    getPatientCharts(openUpload.patientId);
    getPatientAllClinicalDocuments(openUpload.patientId, ehrID);
    getPatientAllMiscellaneousDocuments(openUpload.patientId, ehrID);
    await props.loadOrders(openUpload.patientId);
  };

  const handleChangePage = async page => {
    setLoading(true);
    setState({ ...state, page: page });
    await props.getDocuments(ehrID, page, search);
  };

  const handleOpenDoc = async (
    patientId,
    resourceID,
    routeTo,
    isClinical,
    patient
  ) => {
    setDataDocViewer([])
    setIsClinical(isClinical);
    setOpenDoc(true);
    const doc = await props.getDocInfo(patientId,resourceID)
    setDoc(doc)
    await props.getPatientDocuments(
      ehrID,
      patientId,
      resourceID,
      routeTo,
      isClinical,
      patient
    );
    
  };

  const handleOpenMiscDoc = async (
    ehrID,
    patientId,
    resourceID,
    item,
    isClinical
  ) => {
    setIsClinical(isClinical);
    setDataDocViewer([])
    const miscDoc = await props.getDocInfo(patientId, resourceID)
    setMiscDoc(miscDoc)
    setOpenDoc(true);
    await props.getPatientMiscDocuments(ehrID, patientId, resourceID, item);
  };

  const handleFieldChange = async (field, value) => {
    setSearch(value);
    await props.getDocuments(ehrID, state.page, search);
  };

  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton: {
          fontSize: 12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16,
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientName',
      label: 'Patient Name',
      options: {
        setCellProps: () => ({
          style: { width: '40%' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handlePatientDetailsById(tableMeta.rowData[0])}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'dob',
      label: 'Date of Birth',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handlePatientDetailsById(tableMeta.rowData[0])}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'patientId',
      label: 'MR#',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: value => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handlePatientDetailsById(value)}>
              {value}
            </span>
          );
        }
      }
    },

    {
      name: 'patientId',
      label: 'Upload Document',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: value => {
          return (
            <IconButton
              className={classes.tableIcon}
              color="secondary"
              component="span"
              onClick={() => handleOpenUpload(value)}
              size="small">
              <CloudUploadOutlined />
            </IconButton>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: state.count > 10,
    selectableRows: false,
    serverSide: true,
    count: state.count,
    page: state.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
      }
    }
    // onRowClick: (rowData) => handlePatientDetailsById(rowData[0])
  };

  const handlePatientDetailsById = async(id) => {
    if (documentsList) {
      const patient = documentsList.content.filter(c => c.id === id);
      if (patient.length > 0) {
        setIsDetails({ show: true, patient: patient[0] });
      }
      clearPtDoc()
      getPatientAllClinicalDocuments(id, ehrID)
      getPatientAllMiscellaneousDocuments(id, ehrID)
      props.loadOrders(id);
      const res = await getPatientCharts(id, 0)
      setTotal(res.totalElements) 
    }
  };

  const handlegoBack = () => {
    setIsDetails({ ...isDetails, show: false });
    // props.getDocuments(ehrID, state.page, search);
  };

  // const handleShowDetail = async patient => {
  //   setIsDetails({ show: true, patient: patient });
  // };

  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      <Grid container>
        <Grid className={classes.cardHeader} item md={8} sm={4} xs={12}>
          <Typography variant="h2">
            {' '}
            {(profile && profile.firstname ? profile.firstname : '') +
              ' ' +
              (profile && profile.lastname ? profile.lastname : ' ') +
              ' ' +
              (profile && profile.title ? profile.title : '')}
          </Typography>
        </Grid>
        <Grid
          className={classes.cardHeader}
          item
          md={4}
          sm={8}
          style={{ justifyContent: 'flex-end' }}
          xs={12}>
          {!isDetails.show ? (
            <div style={{ position: 'relative', width: '100%' }}>
              <TextField
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                className={classes.textBox}
                id="outlined-basic"
                onChange={event =>
                  handleFieldChange('search', event.target.value)
                }
                placeholder="Patient Search"
                value={search}
                variant="outlined"
              />
              <Button
                className={classes.searchBtn}
                color="primary"
                variant="contained">
                <SearchOutlined />
              </Button>
            </div>
          ) : (
            <Button
              color="secondary"
              disableElevation="true"
              onClick={() => handleOpenUpload(isDetails.patient.id)}
              size="small"
              startIcon={<CloudUploadOutlined />}
              variant="contained">
              Upload Document
            </Button>
          )}
        </Grid>
      </Grid>

      <Divider />
      <CardContent>
        {!isDetails.show ? (
          <>
            {loading ? (
              <div
                className={classes.progressWrapper}
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  display: 'flex',
                  bottom: 0,
                  top: 0,
                  alignItems: 'flex-start',
                  paddingTop: 120,
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0,.2)'
                }}>
                <CircularProgress style={{ height: 30, width: 30 }} />
              </div>
            ) : (
              data && (
                <MuiThemeProvider theme={theme}>
                  <div className={classes.fullWidthTable}>
                    <MUIDataTable
                      columns={columns}
                      data={data}
                      options={options}
                      responsive="scrollMaxHeight"
                    />
                  </div>
                </MuiThemeProvider>
              )
            )}
          </>
        ) : (
          <PatientDetails
            goBack={handlegoBack}
            handleOpenDoc={handleOpenDoc}
            handleOpenMiscDoc={handleOpenMiscDoc}
            isPtDoc
            pastSoapNotes={false}
            isClinical={isClinical}
            patient={isDetails.patient}
          />
        )}
      </CardContent>

      <Modal onClose={handleCloseUpload} open={openUpload.show}>
        <UploadDoc
          //  handleSubmit={handleSubmit}
          onCancel={handleCloseUpload}
          patientId={openUpload.patientId}
        />
      </Modal>

      <Modal onClose={handleCloseDoc} open={openDoc}>
        <DocViewer
          dataDocViewer={dataDocViewer}
          miscDataDocViewer={miscDataDocViewer}
          isClinical={isClinical}
          onCancel={handleCloseDoc}
          doc={doc}
          miscDoc={miscDoc}
          handleDeleteDoc={handleDeleteDoc}
        
        />
      </Modal>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getDocuments,
  getPatientDocuments,
  uploadFilePDF,
  getPatientMiscDocuments, getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, loadOrders, getDocInfo, getDocumentCategories, clearPtDoc,getDocInfo
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  profile: state && state.profile,
  documentsList: state.home && state.home.documentsList,
  documentInfo: state.home && state.home.documentInfo,
  miscDocumentInfo: state.home && state.home.miscDocumentInfo
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(Details));
