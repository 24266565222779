import React from 'react'
import {
  Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextField, Typography
} from '@material-ui/core';
import {
  AddCircleOutline, AttachFileOutlined, CancelOutlined,
  Check, CloseOutlined
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addDocuments, uploadFilePDF } from 'services/home/action';
import { getPatientRemarks } from 'services/soap/action';
import { addCategory, getCategories, getProviders } from 'services/util/action';
import validate from 'validate.js';
import styles from '../style';
import schema from './schema';
import schemaMisc from './schemaMisc';

const UploadDoc = props => {
  const {
    classes,
    ehrID,
    providers,
    categories,
    patientId,
    getPatientRemarks  } = props;
  const [cats, setCats] = useState([]);
  const [pros, setPros] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ isValid: false, isclinical: true });
  const [dataCategory, setDataCategory] = useState({
    name: '',
    clinical: true
  });

  useEffect(() => {
    props.getCategories(ehrID);
    props.getProviders(ehrID);
  }, []);

  useEffect(() => {

    if (categories != undefined) {
      let categoriestopush = [];
      categoriestopush.push({ key: 'Select', value: '' });
      categories.forEach(function (item) {
        categoriestopush.push({
          value: item.id,
          key: item.name,
          clinical: item.clinical
        });
      });
      setCats(categoriestopush);

    }

  }, [categories]);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providertopush.push({ key: 'All Providers', value: '' });
      providers.forEach(function (item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const setEdit = () => {
    setIsEdit(!isEdit);
  };

  const handleFieldChange = (field, event) => {
    const newState = { ...data };
    if (field === 'file') {
      newState.file = event.target.files[0];
    } else {
      newState[field] = event.target.value;
    }
    if (newState?.isclinical == false && newState.isValid == false) {

      const errors = validate(newState, schemaMisc);
      newState.errors = errors || {};

      newState.isValid = errors ? false : true;
      newState.providerid = ''
    } else {
      const errors = validate(newState, schema);
      newState.errors = errors || {};

      newState.isValid = errors ? false : true;
    }

    if (field === 'categoryid') {
      newState.isclinical = categories.filter(c => c.id === parseInt(event.target.value))[0]?.clinical
    }
    setData(newState);
  };
  const handleFieldChangeCategory = (field, value) => {
    let fieldValue = value;
    if (field === 'clinical') {
      fieldValue = !dataCategory.clinical
    }
    setDataCategory({ ...dataCategory, [field]: fieldValue })

  };

  const handleSubmit = async () => {

    setLoading(true);
    const res = await props.uploadFilePDF(ehrID, patientId, data);
    if (res) {
      setLoading(false);
      getPatientRemarks(patientId)
      props.onCancel(patientId);
      props.refreshDoc()
    }

  };


  const handleSubmitCategory = async () => {

    const res = await props.addCategory(ehrID, dataCategory);
    if (res) {


      const newdata = { ...data, ...res.data, categoryid: res.data.id, isclinical: res.data.clinical };
      props.getCategories(ehrID);
      if (!newdata.isclinical) {
        const errors = validate(newdata, schemaMisc);
        newdata.isValid = errors ? false : true;
        newdata.providerid = '';

      }
      setData(newdata)

      setDataCategory('', true);
      setIsEdit(false);
    }
  };

  return ( <Box className={classes.modalWrap} style={{ width: 650 }}>
      <Box className={classes.modalHead}>
        <Typography variant="h2">Upload Document</Typography>
        <IconButton
          aria-label="delete"
          className={classes.modalClose}
          onClick={props.onCancel}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box className={classes.modalBody}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 10
          }}>
          <TextField
            SelectProps={{ native: true }}
            name="Title"
            onChange={event => handleFieldChange('categoryid', event)}
            select
            style={{ width: '100%' }}
            value={data && data.categoryid}

            variant="outlined">
            {cats &&
              cats.map(item => <option value={item.value}>{item.key}</option>)}
          </TextField>
          {!isEdit ? (
            <Button
              className={classes.addButton}
              color="primary"
              onClick={() => setEdit()}
              size="small"
              style={{ borderRadius: 0, boxShadow: 'none' }}
              variant="contained">
              <AddCircleOutline />
            </Button>
          ) : (
            <Button
              className={classes.addButton}
              color="primary"
              onClick={() => setEdit()}
              size="small"
              style={{
                backgroundColor: '#B9B9B9',
                borderRadius: 0,
                boxShadow: 'none'
              }}
              variant="contained">
              <CancelOutlined />
            </Button>
          )}
        </div>
        {isEdit ? (
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 10
              }}>
              <TextField
                name="Title"
                onChange={event =>
                  handleFieldChangeCategory('name', event.target.value)
                }
                placeholder="Name of new category"
                style={{ width: '100%' }}
                value={dataCategory && dataCategory.name}
                variant="outlined"
              />

              <Button
                className={classes.addButton}
                color="primary"

                onClick={(event) => handleSubmitCategory('categoryid', event)}

                size="small"
                style={{
                  borderRadius: 0,
                  boxShadow: 'none',
                  backgroundColor: '#03A484'
                }}
                variant="contained">
                <Check />
              </Button>
            </div>
            <FormControlLabel
              control={

                <Checkbox
                  checked={dataCategory.clinical}
                  color="primary"
                  name="checkedA"
                  onChange={event =>
                    handleFieldChangeCategory('clinical', event.target.value)
                  }
                  value={dataCategory && dataCategory.clinical}
                />
              }
              label="Clinical Category"
              style={{ marginTop: -10 }}
            />
          </>
        ) : null}
        {data && data.categoryid && data.isclinical && data.categoryid > 0 && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: 10
            }}>

            <TextField
              SelectProps={{ native: true }}
              name="Title"
              onChange={event => handleFieldChange('providerid', event)}
              select
              style={{ width: '100%' }}
              value={data && data.providerid}
              variant="outlined">
              {pros &&
                pros.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>

          </div>
        )}
        <TextField
          onChange={event => handleFieldChange('title', event)}
          placeholder="Document Title"
          style={{ width: '100%', marginBottom: 10 }}
          value={data && data.title}
          variant="outlined"
        />
        <input
          className={classes.input}
          id="raised-button-file"
          multiple
          onChange={event => handleFieldChange('file', event)}
          style={{ display: 'none' }}
          type="file"
        />
        <label htmlFor="raised-button-file">
          <Button component="span" variant="outlined" style={{ marginRight: 10 }}>
            <AttachFileOutlined /> Select Document
          </Button>
          {data && data.file && data.file.name}
        </label>
      </Box>
      <Box className={classes.modalFooter}>
        <Button
          onClick={props.onCancel}
          size="small"
          style={{ marginRight: 10 }}
          variant="outlined">
          Cancel
        </Button>
        {loading ? (
          <CircularProgress className={classes.progress} size={20} />
        ) : (
          <Button
            color="primary"
            disabled={data && !data.isValid}
            onClick={() => handleSubmit()}
            size="small"
            style={{ borderRadius: 4 }}
            variant="contained">
            Submit
          </Button>
        )}
      </Box>
    </Box>
  );
};

const mapDispatchToProps = {
  getCategories,
  getProviders,
  addCategory,
  addDocuments,
  uploadFilePDF,
  getPatientRemarks
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  providers: state.util.providers,
  categories: state.util.categories
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UploadDoc));
