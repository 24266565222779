import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Card, CardContent, Divider, Grid } from '@material-ui/core';
import styles from './../../style';
import { SoapMeta, SoapLeft, MHx } from './components';
import { connect } from 'react-redux';
import { saveTempData } from 'services/soap/action';

const AccountDetails = props => {
  const {
    classes,
    className,
    patientId,
    patientInfo,
    fetchPatientInfo,
    editfromChartReview,
    ptActiveMedication,
    ptInactiveMedication,
    ptMedicalHistory,
    ptPastDiagnosis,
    ptInactiveDiagnosis,
    ...rest
  } = props;
  const childRefSoap = useRef();
  // const [soapEntryData, setSoapEntryData] = useState([]);
  const [copySoap, setCopySoap] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  const [inactivediagnosis, setInactiveDiagnosis] = useState([]);
  const [cpt, setCpt] = useState();
  const [inactivecpt, setInactiveCpt] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [medication, setMedication] = useState([]);
  const [inactivemedication, setInactiveMedication] = useState([]);
  const [healthMaintenance, setHealthMaintenance] = useState([]);
  const [vaccinations, setVaccinations] = useState([]);
  const [socialHistory, setSocialHistory] = useState([]);
  const [familyHistory, setFamilyHistory] = useState([]);
  const [advanceDirectives, setAdvanceDirectives] = useState([]);
  const [additionalItems, setAdditionalItems] = useState([]);

  useEffect(() => {
    if (ptMedicalHistory) {
      setAllergies(ptMedicalHistory.allergies);
    }
    if (ptMedicalHistory && ptMedicalHistory.healthMaintenance) {
      setHealthMaintenance(ptMedicalHistory.healthMaintenance);
    }
    if (ptMedicalHistory && ptMedicalHistory.vaccinations) {
      setVaccinations(ptMedicalHistory.vaccinations);
    }
    if (ptMedicalHistory && ptMedicalHistory.socialHistory) {
      setSocialHistory(ptMedicalHistory.socialHistory);
    }
    if (ptMedicalHistory && ptMedicalHistory.familyHistory) {
      setFamilyHistory(ptMedicalHistory.familyHistory);
    }
    if (ptMedicalHistory && ptMedicalHistory.advanceDirective) {
      setAdvanceDirectives(ptMedicalHistory.advanceDirective);
    }
    if (ptMedicalHistory && ptMedicalHistory.additionalItems) {
      setAdditionalItems(ptMedicalHistory.additionalItems);
    } 
  }, [ptMedicalHistory]); 

  useEffect(()=>{
    if (ptActiveMedication && ptActiveMedication.length > 0 ) { 
      setMedication(ptActiveMedication);
    } 
  },[ptActiveMedication])

  useEffect(()=>{
    if (ptInactiveMedication && ptInactiveMedication.length > 0 ) { 
      setInactiveMedication(ptInactiveMedication);
    } 
  },[ptInactiveMedication])

  useEffect(()=>{
    if (ptInactiveDiagnosis && ptInactiveDiagnosis.length > 0 ) {
      if (ptInactiveDiagnosis.filter(c => c.codeType === 'cpt' && c.isActive === false)) {
        setInactiveCpt(ptInactiveDiagnosis.filter(c => c.codeType === 'cpt' && c.isActive === false));
      }
      if (ptInactiveDiagnosis.filter(c => c.codeType === 'diagnosis' && c.isActive === false)) {
        setInactiveDiagnosis(ptInactiveDiagnosis.filter(c => c.codeType === 'diagnosis' && c.isActive === false));
      }
    } 
  },[ptInactiveDiagnosis])

  useEffect(()=>{
    if (ptInactiveMedication && ptInactiveMedication.length > 0 ) {
      if (ptInactiveMedication.filter(c => c.codeType === 'cpt' && c.isActive === false)) {
        setInactiveCpt(ptInactiveMedication.filter(c => c.codeType === 'cpt' && c.isActive === false));
      }
      if (ptInactiveMedication.filter(c => c.codeType === 'diagnosis' && c.isActive === false)) {
        setInactiveDiagnosis(ptInactiveMedication.filter(c => c.codeType === 'diagnosis' && c.isActive === false));
      }
    } 
  },[ptInactiveMedication])

  useEffect(()=>{
    if (ptPastDiagnosis && ptPastDiagnosis.length > 0) {
      if (ptPastDiagnosis.filter(c => c.codeType === 'cpt' && c.isActive === true)) {
        setCpt(ptPastDiagnosis.filter(c => c.codeType === 'cpt' && c.isActive === true));
      }
      if (ptPastDiagnosis.filter(c => c.codeType === 'diagnosis' && c.isActive === true)) {
        setDiagnosis(ptPastDiagnosis.filter(c => c.codeType === 'diagnosis' && c.isActive === true));
      }
    }
  },[ptPastDiagnosis])
 
  const handleCopy = async (
    dia,
    cpt,
    isClearDiagnosis = false,
    isClearCPT = false
  ) => {
    let datanew = await childRefSoap.current.getValuesforCopy();
      let newState = datanew ? datanew : [];
      let newStateSoap,
        newStatedia,
        newStatecpt,
        newStatePastSoapNoteDetails = [];
      if (datanew && (datanew.soap || datanew.pastSoapNoteDetails)) {
        newStateSoap = newState.soap ? newState.soap : [];
        newStatePastSoapNoteDetails = newState.pastSoapNoteDetails
          ? newState.pastSoapNoteDetails
          : [];
        newStatedia = newState.pastSoapNoteDetails.diagnosisList
          ? newState.pastSoapNoteDetails.diagnosisList
          : [];

        newStatecpt = newState.pastSoapNoteDetails.cptList
          ? newState.pastSoapNoteDetails.cptList
          : [];

        if (isClearDiagnosis) {
          newStatePastSoapNoteDetails.diagnosisList = dia;
         // setDiagnosis(dia);
        }

        if (isClearCPT) {
          newStatePastSoapNoteDetails.cptList = cpt;
        //  setCpt(cpt);
        }
        if (dia && dia.length > 0 && !isClearDiagnosis) {
          if (
            newStatePastSoapNoteDetails.diagnosisList &&
            newStatePastSoapNoteDetails.diagnosisList.length > 0
          ) {
            //  newStatedia = [...newStatePastSoapNoteDetails.diagnosisList];
            dia.map(item => {
              if (!newStatedia.some(c => c.description === item.description)) {
                newStatedia.push({
                  code: item.code,
                  codeType: item.codeType,
                  description: item.description,
                  oldPastDiagnosisId: item.oldPastDiagnosisId,
                  soapId: item.soapId,
                  sortOrder: item.sortOrder
                });
              }
            });
            newStatePastSoapNoteDetails.diagnosisList = newStatedia;
          } else {
            if (newStatePastSoapNoteDetails.diagnosisList === undefined) {
              newStatePastSoapNoteDetails.diagnosisList = [];
            }
            newStatePastSoapNoteDetails.diagnosisList.push({
              code: dia[0].code,
              codeType: dia[0].codeType,
              description: dia[0].description,
              oldPastDiagnosisId: dia[0].oldPastDiagnosisId,
              soapId: dia[0].soapId,
              sortOrder: dia[0].sortOrder
            });
          }
        }
        if (cpt && cpt.length > 0 && !isClearCPT) {
          if (
            newStatePastSoapNoteDetails.cptList &&
            newStatePastSoapNoteDetails.cptList.length > 0
          ) {
            // newStatecpt = [...newStatePastSoapNoteDetails.cptList];
            cpt.map(item => {
              if (!newStatecpt.some(c => c.description === item.description)) {
                newStatecpt.push({
                  code: item.code,
                  codeType: item.codeType,
                  description: item.description,
                  oldPastDiagnosisId: item.oldPastDiagnosisId,
                  soapId: item.soapId,
                  sortOrder: item.sortOrder
                });
              }
            });
            newStatePastSoapNoteDetails.cptList = newStatecpt;
          } else {
            if (newStatePastSoapNoteDetails.cptList === undefined) {
              newStatePastSoapNoteDetails.cptList = [];
            }
            newStatePastSoapNoteDetails.cptList.push({
              code: cpt[0].code,
              codeType: cpt[0].codeType,
              description: cpt[0].description,
              oldPastDiagnosisId: cpt[0].oldPastDiagnosisId,
              soapId: cpt[0].soapId,
              sortOrder: cpt[0].sortOrder
            });
          }
        }
      } else if (Object.entries(datanew).length > 0) {
        newState = { soap: datanew, pastSoapNoteDetails: {} };
        if (dia && dia.length > 0) {
          newState.pastSoapNoteDetails.diagnosisList =
            newState.soap.diagnosisList &&
            newState.soap.diagnosisList.length > 0
              ? newState.soap.diagnosisList
              : [];
          newState.pastSoapNoteDetails.diagnosisList.push({
            code: dia[0].code,
            codeType: dia[0].codeType,
            description: dia[0].description,
            oldPastDiagnosisId: dia[0].oldPastDiagnosisId,
            soapId: dia[0].soapId,
            sortOrder: dia[0].sortOrder
          });
        } else if (cpt && cpt.length > 0) {
          newState.pastSoapNoteDetails.cptList =
            newState.soap.cptList && newState.soap.cptList.length > 0
              ? newState.soap.cptList
              : [];
          newState.pastSoapNoteDetails.cptList.push({
            code: cpt[0].code,
            codeType: cpt[0].codeType,
            description: cpt[0].description,
            oldPastDiagnosisId: cpt[0].oldPastDiagnosisId,
            soapId: cpt[0].soapId,
            sortOrder: cpt[0].sortOrder
          });
        }
      } else {
        newState.soap = [];
        newState.pastSoapNoteDetails = [];
        if (dia && dia.length > 0) {
          newState.pastSoapNoteDetails.diagnosisList = [];
          newState.pastSoapNoteDetails.diagnosisList.push({
            code: dia[0].code,
            codeType: dia[0].codeType,
            description: dia[0].description,
            oldPastDiagnosisId: dia[0].oldPastDiagnosisId,
            soapId: dia[0].soapId,
            sortOrder: dia[0].sortOrder
          });
        } else if (cpt && cpt.length > 0) {
          newState.pastSoapNoteDetails.cptList = [];
          newState.pastSoapNoteDetails.cptList.push({
            code: cpt[0].code,
            codeType: cpt[0].codeType,
            description: cpt[0].description,
            oldPastDiagnosisId: cpt[0].oldPastDiagnosisId,
            soapId: cpt[0].soapId,
            sortOrder: cpt[0].sortOrder
          });
        }
      }
      newState.fromIsMHx=true;
     await props.saveTempData(newState);
      childRefSoap.current.loadCPT(newState);
  };

  const refreshData = () => {
    props.fetchPatientInfo(patientId);
  };

  const clearLocationandEditChartView = async () => {
    props.clearLocationandEditChartView();
  };

  const getAllergy = allergy => {
    setAllergies(allergy);
  };

  const getMedication = medication => {
    setMedication(medication);
  };

  const getInactiveMedication = inactivemedication => {
    setInactiveMedication(inactivemedication);
  };

  const getHealthMaintenance = healthMaintenance => {
    setHealthMaintenance(healthMaintenance);
  };

  const getVaccination = vaccination => {
    setVaccinations(vaccination);
  };

  const getSocialHistory = socialhistory => {
    setSocialHistory(socialhistory);
  };

  const getFamilyHistory = familyhistory => {
    setFamilyHistory(familyhistory);
  };

  const getAdvanceDirectives = advancedirectives => {
    setAdvanceDirectives(advancedirectives);
  };

  const getAdditionalItems = additionalitems => {
    setAdditionalItems(additionalitems);
  };

  const getCpt = cpt => {
    setCpt(cpt);
  };

  const getInactiveCpt = inactivecpt => {
    setInactiveCpt(inactivecpt);
  };

  const getDiagnosis = diagnosis => {
    setDiagnosis(diagnosis);
  };

  const getInactiveDiagnosis = inactivediagnosis => {
    setInactiveDiagnosis(inactivediagnosis);
  };

  return (
    <Grid
      container
      isRender
      spacing={3}
    >
      <Grid
        className={classes.colWrap}
        item
        md={12}
        xs={12}
      >
        <Card
          {...rest}
          className={clsx(classes.subTabRoot, className)}
        >
          <SoapMeta 
            patientId={patientId}
          />
          <Divider />
          <CardContent style={{ padding: 0 }}>
            <Grid container>
              <Grid
                item
                md={8}
                xs={12}
              >
                <SoapLeft
                  additionalItems={additionalItems}
                  advanceDirectives={advanceDirectives}
                  allergies={allergies}
                  clearLocationandEditChartView={clearLocationandEditChartView}
                  copySoap={copySoap}
                  cpt={cpt}
                  diagnosis={diagnosis}
                  editfromChartReview={editfromChartReview}
                  familyHistory={familyHistory}
                  fetchPatientInfo={fetchPatientInfo}
                  getAdditionalItems={getAdditionalItems}
                  getAdvanceDirectives={getAdvanceDirectives}
                  getAllergy={getAllergy}
                  getCpt={getCpt}
                  getDiagnosis={getDiagnosis}
                  getFamilyHistory={getFamilyHistory}
                  getHealthMaintenance={getHealthMaintenance}
                  getInactiveCpt={getInactiveCpt}
                  getInactiveDiagnosis={getInactiveDiagnosis}
                  getInactiveMedication={getInactiveMedication}
                  getMedication={getMedication}
                  getSocialHistory={getSocialHistory}
                  getVaccination={getVaccination}
                  handleCopy={handleCopy}
                  healthMaintenance={healthMaintenance}
                  inactivecpt={inactivecpt}
                  inactivediagnosis={inactivediagnosis}
                  inactivemedication={inactivemedication}
                  isRender
                  medication={medication}
                  patientId={patientId} 
                  ref={childRefSoap}
                  socialHistory={socialHistory}
                  vaccinations={vaccinations}
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <PerfectScrollbar
                  style={{ height: 'calc(100vh - 32px)', paddingRight: 10 }}
                >
                  <MHx
                    additionalItems={additionalItems}
                    advanceDirectives={advanceDirectives}
                    allergies={allergies}
                    cpt={cpt}
                    diagnosis={diagnosis}
                    familyHistory={familyHistory}
                    getAdditionalItems={getAdditionalItems}
                    getAdvanceDirectives={getAdvanceDirectives}
                    getAllergy={getAllergy}
                    getFamilyHistory={getFamilyHistory}
                    getHealthMaintenance={getHealthMaintenance}
                    getInactiveMedication={getInactiveMedication}
                    getMedication={getMedication}
                    getSocialHistory={getSocialHistory}
                    getVaccination={getVaccination}
                    handleCopy={handleCopy}
                    healthMaintenance={healthMaintenance}
                    inactivecpt={inactivecpt}
                    inactivediagnosis={inactivediagnosis}
                    inactivemedication={inactivemedication}
                    medication={medication} 
                    refreshData={refreshData}
                    socialHistory={socialHistory}
                    vaccinations={vaccinations}
                  />
                </PerfectScrollbar>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {saveTempData};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  patientInfo: state.soap && state.soap.patientInfo,
  ptInactiveMedication: state.soap && state.soap.ptInactiveMedication,
  ptActiveMedication: state.soap && state.soap.ptActiveMedication,
  ptMedicalHistory: state.soap && state.soap.ptMedicalHistory,
  ptPastDiagnosis: state.soap && state.soap.ptPastDiagnosis,
  ptInactiveDiagnosis: state.soap && state.soap.ptInactiveDiagnosis,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountDetails))
);
