
import http from 'services/http';
import { errorHandler } from '../error/action';

export const Analyze = (limit) => async () => {
    
 
   const page=0;
   var rundate = new Date();
       const res = await http.post(`/getpatientsdocuments?&page=${page}&limit=${limit}`, rundate);
       if (res) {
         return res;
       }
       return false;
    
   };

   export const Execute = (data) => async dispatch =>{
    
    try {
      const res = await http.post(`/Executemigration`,data);
  
      if (res) {
        return res;
      }
    } catch (error) {
      errorHandler(error);
      return false;
    }
   
 }

 export const Rerun = (data) => async dispatch =>{
   
  try {
    const res = await http.post(`/Rerunmigration`,data);

    if (res) {
      return res;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
 
}
 
  export const AnalyzeRundate =(rundate) => async () => {
    
   
    //  const page=0;
    //  var rundate = new Date();
         const res = await http.get(`/getdetailsbyrundate?&rundate=${rundate}`);
         if (res) {
           return res;
         }
         return false;
      
     };
