/* eslint-disable react/prop-types */
/* eslint-disable linebreak-style */
/* eslint-disable no-console,func-names,react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import ReactDragListView from 'react-drag-listview/lib/index.js';
import {
  Edit,
  DragIndicatorOutlined,
  CheckOutlined
} from '@material-ui/icons';
import { Checkbox, IconButton, withStyles, TextField } from '@material-ui/core';

const styles = theme => ({
  dragListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 5px',
    borderBottom: '1px solid #eee'
  },
  dragListTextWrap: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%'
  },
  dragListText: {
    whiteSpace: 'nowrap',
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 'calc(100vw/2 - 300px)',
    maxWidth: 400,
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100vw/2 - 300px)'
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw/1.8 - 300px)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 300px)'
    }
  },
  dragListAction: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  }
});

const Dragger = props => {
  const { classes,data } = props;
  const [datas, setDatas] = useState([]);
  const [handleEditTopicText, setHandleEditTopicText] = useState({
    showTopicTextEdit: false
  });
  const [isIndexChange, setIsIndexChange] = useState(false);
  const [subText, setsubText] = useState(null);

  useEffect(() => {
    if (data) {
      
      setDatas(props.data);
    }
  }, [data]);

  useEffect(() => {
    if (isIndexChange) {
      let newState = [...datas];
      let selectedItems = [];
      newState.forEach(function(item, index) {
        item.sortOrder = index;
        selectedItems.push(item);
      });
      props.saveSubText(selectedItems);
    }
  }, [isIndexChange]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const data = [...datas];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      setDatas(data);
      setIsIndexChange(true);
    },
    nodeSelector: 'li',
    handleSelector: 'a',
    lineClassName: 'dragLine'
  };

  const handleCheckboxChange = (event, idx) => {
    props.handleCheckboxChange(event, idx);
  };

  const handleTextChange = async (field, value, idx) => {
    props.handleChange(field, value, idx);
  };

  const editTopicText = idx => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit
      ? handleEditTopicText.showTopicTextEdit.slice()
      : [];
    showTopicTextEdit[idx] = true;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });
  };

  const closeEditTopicText = idx => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit
      ? handleEditTopicText.showTopicTextEdit.slice()
      : [];
    showTopicTextEdit[idx] = false;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });
  };

  const updateText = async (topic, idx) => {
    await props.updateText(topic, idx);
    closeEditTopicText(idx);
  };

  return (
    <div className="simple simple1">
      <div className="simple-inner">
        <ReactDragListView {...dragProps}>
          <ul>
            {datas &&
              datas.length > 0 &&
              datas.map((item, index) => (
                <li
                  className={classes.dragListItem}
                  key={index}
                >
                  <span className={classes.dragListTextWrap}>
                    <Checkbox
                      defaultChecked={false}
                       checked={item.checked || false}
                      color="primary"
                      onClick={event => handleCheckboxChange(event, index)}
                      value={item.checked}
                    />
                    {handleEditTopicText.showTopicTextEdit &&
                    handleEditTopicText.showTopicTextEdit[index] ? (
                      <TextField
                        className={props.classes.textBox}
                        fullWidth
                        id="outlined-basic"
                        InputProps={{
                          classes: {
                            notchedOutline: props.classes.notchedOutline
                          }
                        }}
                        onChange={event =>
                          handleTextChange(
                            'topicText',
                            event.target.value,
                            index
                          )
                        }
                        placeholder="Add New Text"
                        value={item.text}
                        variant="outlined"
                      />
                    ) : (
                      <span
                        className={classes.dragListText}
                        style={{
                          textOverflow: 'inherit',
                          whiteSpace: 'inherit'
                        }}
                      >
                        {item.text}
                      </span>
                    )}
                  </span>

                  <span className={classes.dragListAction}>
                    {handleEditTopicText.showTopicTextEdit &&
                    handleEditTopicText.showTopicTextEdit[index] ? (
                      <IconButton
                        onClick={() => {
                          updateText(item, index);
                        }}
                        size="small"
                      >
                        <CheckOutlined color="primary" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => {
                          editTopicText(index);
                        }}
                        size="small"
                      >
                        <Edit color="primary" />
                      </IconButton>
                    )}
                    <Link
                      to={""}
                      onClick={e=>e.preventDefault()}
                      style={{ margin: '0 10px' }}
                    >
                      <DragIndicatorOutlined style={{ color: '#a9a9a9' }} />
                    </Link>
                  </span>
                </li>
              ))}
          </ul>
        </ReactDragListView>
      </div>
    </div>
  );
};

export default withStyles(styles)(Dragger);
