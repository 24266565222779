export default (state = {}, action) => {
  switch (action.type) {
    case 'GET_STATES_SUCCESS':
      return {
        ...state,
        states: action.data.map(i => ({ ...i }))
      };
    case 'GET_TITLES_SUCCESS':
      return {
        ...state,
        titles: action.data.map(i => ({ ...i }))
      };
    case 'GET_LOCATION_SUCCESS':
      return {
        ...state,
        locations: action.data.map(i => ({ ...i }))
      };
    case 'GET_PROVIDER_SUCCESS':
      return {
        ...state,
        providers: action.data.map(i => ({ ...i }))
      };
    case 'GET_CATEGORIES':
      return {
        ...state,
        categories: action.data.map(i => ({ ...i }))
      };

    case 'GET_GENDER_SUCCESS':
      return {
        ...state,
        genders: action.data.map(i => ({ ...i }))
      };
    case 'GET_RACES_SUCCESS':
      return {
        ...state,
        races: action.data.map(i => ({ ...i }))
      };
    case 'GET_LANGUAGES_SUCCESS':
      return {
        ...state,
        languages: action.data.map(i => ({ ...i }))
      };
    case 'GET_ETHNICCITIES_SUCCESS':
      return {
        ...state,
        ethnicCities: action.data.map(i => ({ ...i }))
      };
    case 'GET_RELATIONSHIP_SUCCESS':
      return {
        ...state,
        relationships: action.data.map(i => ({ ...i }))
      };
    case 'GET_MARITALSTATUS_SUCCESS':
      return {
        ...state,
        maritalStatus: action.data.map(i => ({ ...i }))
      };

    default:
      return state;
  }
};
