/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import MUIDataTable from 'mui-datatables';
import {
  Grid,
  Button,
  Modal,
  Box,
  Typography,
  IconButton
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setPtMessageComplete } from 'services/ptmessages/action';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { CloseOutlined, AttachmentOutlined } from '@material-ui/icons';
import NewMessage from './Modal_NewMessage';
import MessageBox from 'util/messageBox';
import {
  getAllPatforPtMessages
} from 'services/ptmessages/action';
import PtStaffResponse from 'views/PTMessage/components/Modal_Ptstaffresponse';


const PTList = props => {
  const { classes, data, ehrID,userID } = props;
  const [successMsg, setSuccessMsg] = useState(false);
  const [ptResponsepopupdata, setPtResponsePopupData] = useState([]);
  const [openPtresponse, setOpenPtResponse] = React.useState(false);
  const handleAcknowledge = async ptMessage => {
    const res = await props.setPtMessageComplete(userID, ptMessage.item);
    if (res) {
      props.fetchData();
    }
  };
  const handleopenPtresponse = ptMessage => {
     if(ptMessage !== '' ){
     
    setPtResponsePopupData(ptMessage);
  } else {
   
    setPtResponsePopupData({
 
      // patientName: patient.patientName,
      patientName:
        (ptMessage.patientName) ,
       
      dob: ptMessage.dob ? ptMessage.dob : '',

      patientId: ptMessage.patientId,
      status: 1,
      providerResponseBy: ptMessage.providerResponseBy
    });
  }
  setOpenPtResponse(true);
    // props.handleOpenResponse('', ptMessage)
  };
  const handleOpenResponse = ptMessage => {

    props.handleOpenResponse('', ptMessage)
  };
  //#region , message
  const handleCloseSuccess = () => {
    setSuccessMsg(false);
  };

  const showSuccess = () => {
    setSuccessMsg(true);
  };

  const renderSuccess = () => {
    return (
      <MessageBox
        open={successMsg}
        variant="success"
        onClose={handleCloseSuccess}
        message="Message Sent Succesfully"
      />
    );
  };
  //#endregion

  //#region New Message
  const [newmsgdata, setNewMsgData] = useState([]);
  const [opennewmsg, setOpenNewMsg] = useState(false);

  const handleOpenNewMessage = ptmessage => {
    setNewMsgData(ptmessage);
    setOpenNewMsg(true);
  };

  const handleCloseNewMessage = () => {
    setOpenNewMsg(false);
    props.fetchData();
  };
  const handleCloseResponse = () => {
     setPtResponsePopupData('');
     setOpenPtResponse(false);
      // props.getAllPatforPtMessages(ehrID, userID);
  };
  const computeAge = dob => {
    var magicNumber = 1000 * 60 * 60 * 24 * 365;
    var age = Math.abs(new Date() - new Date(dob));
    age = Math.floor(age / magicNumber);
    return age;
  };


  const renderNewMsg = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseNewMessage}
        open={opennewmsg}>
        <Box className={classes.modalWrap} style={{ width: 500 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">New Message</Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseNewMessage}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <NewMessage
            newmsgdata={newmsgdata}
            onCancel={handleCloseNewMessage}
            showSuccess={showSuccess}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '10px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          // padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'updatedDate',
      label: 'Date',
      options: {
        setCellProps: () => ({
          style: { width: '20%', minWidth: 110 }
        }),
        setCellHeaderProps: () => ({})
      }
    },
    {
      name: 'id',
      label: 'Patient Message',
      options: {
        setCellProps: () => ({
          style: { width: '40%' }
        }),
        setCellHeaderProps: () => ({}),
        customBodyRender: value => {
         
          const ptMessage = data.filter(c => c.id === value)[0];
          return (ptMessage.message && !ptMessage.providerResponse) ||
            (ptMessage &&
              ptMessage.message &&
              ptMessage.providerResponse &&
              ptMessage.status === 1) ? (
            <span onClick={() => handleOpenNewMessage(ptMessage)}>
              {ptMessage.message}
            </span>
          ) :ptMessage &&
           ptMessage.message &&
            ptMessage.providerResponse &&
            ptMessage.status === 0 ? (
            <span>{ptMessage.message}</span>
          ) : null;
        }
      }
    },
    {
      name: 'id',
      label: 'Staff Response',
      options: {
        setCellProps: () => ({}),
        setCellHeaderProps: () => ({
          style: { width: '30%' }
        }),
       customBodyRender: value => {
   
          const ptMessage = data.filter(c => c.id === value)[0];
          // return (ptMessage.message && !ptMessage.providerResponse) ||
          // (ptMessage.message &&
          //   ptMessage.providerResponse &&
          //   ptMessage.status == 1) ? (
            return  (
            <React.Fragment
            >
              <RouterLink
                onClick={() => handleopenPtresponse(ptMessage)}
              >
                {ptMessage && ptMessage.providerResponse ?
                  ptMessage.providerResponse : ''
                }

              </RouterLink>

              <span>
                {ptMessage &&
                ptMessage?.item &&
                  ptMessage?.item?.ptMessageOrdersList?.length > 0

                  ? (
                    <IconButton
                      
                      component="span"
                      size="small"
                      onClick={() => handleopenPtresponse(ptMessage)}
                   
                    >
                      <AttachmentOutlined />
                    </IconButton>
                  ) : null
                }
              </span>
            </React.Fragment>
          ) 
         
        }


      }
    },

    {
      name: 'id',
      label: 'Status',
      options: {
        sort: false,
        setCellProps: value => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: value => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: value => {
        
          const ptMessage = data.filter(c => c.id === value)[0];
          return (!ptMessage.message &&
            ptMessage.providerResponse &&
            ptMessage.status === 1) ||
            (ptMessage.message &&
              ptMessage.providerResponse &&
              ptMessage.status === 1) ? (
            <Button
              onClick={() => handleAcknowledge(ptMessage)}
              size="small"
              variant="contained">
              Click to acknowledge
            </Button>
          ) : (!ptMessage.message &&
            ptMessage.providerResponse &&
            ptMessage.status === 0) ||
            (ptMessage.message &&
              ptMessage.providerResponse &&
              ptMessage.status === 0) ? (
            <span>Completed</span>
          ) : null;
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'select',
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false
  };

  return (
    <Grid container item spacing={0}>
      <Grid item xs={12}>
        <Grid item lg={5} xs={4} style={{ padding: '0 0 10px 0' }}>
          <Button
            className={clsx(classes.viewBtn, classes.SchBtn)}
            color="primary"
            onClick={() => handleOpenNewMessage()}
            variant="contained">
            New Message
          </Button>
        </Grid>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            className={classes.simpleTable}
            columns={columns}
            data={data}
            options={options}
            responsive="scrollMaxHeight"
          />
        </MuiThemeProvider>
      </Grid>
      {opennewmsg && renderNewMsg()}
      {successMsg && renderSuccess()}
      <Modal
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
              disableBackdropClick
               onClose={handleCloseResponse}
              open={openPtresponse}>
              <Box className={classes.modalWrap} style={{ width: 650 }}>
                <Box className={classes.modalHead}>
                  <Typography variant="h2">
                    Staff Response to
                    {
                    data[0]?.item?.patientName
                      ? ' ' +data[0]?.item?.patientName
                      : ''}
                   
                   {data[0]?.item?.dob !== null &&
                    data[0]?.item?.dob !== ''
                      ? '(' + computeAge(data[0]?.item?.dob) + ')'
                      : ''}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    className={classes.modalClose}
                     onClick={() => handleCloseResponse()}
                    >
                    <CloseOutlined />
                  </IconButton>
                </Box>
                
                <PtStaffResponse
                  // onCancel={handleCloseResponse}
                  ptResponsepopupdata={ptResponsepopupdata}
                  // showSuccessMsg={showSuccessMsg}
                />
              </Box>
            </Modal>
    </Grid>
  );
};

PTList.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { setPtMessageComplete ,getAllPatforPtMessages};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PTList));


