import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './style';
import {
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  Grid,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import { CloseOutlined, CalendarToday } from '@material-ui/icons';
import { connect } from 'react-redux';
import validate from 'validate.js';
import schema from './schema';
import { getLocations } from 'services/util/action';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { PatientSearchList } from '../../Common/components/';
import { getSearchPatients, checkpatient } from 'services/patient/action';
import moment from 'moment';

const PatientAdd = props => {
  const { classes, ehrID, locations, searchText, patients, ...rest } = props;

  const initialUserState = {
    lastname: searchText ? searchText.split(',')[0] : '',
    firstname:
      searchText && searchText.split(',').length > 1
        ? searchText.split(',')[1]
        : '',
    middleinitial: '',
    dob: '',
    gender: '',
    location: '',
    phonenumber: '',
    emailaddress: null,
    practice: null,
    isValid: false,
    touched: {},
    errors: {},
    isLoading: false,
    limit: '',
    order: 'ASC',
    sortBy: 'lastname'
  };

  const [formstate, setFormstate] = useState(initialUserState);
  const [locs, setLocs] = useState([]);
  const [showpatientlistfirstname, setShowPatientListFirstName] = useState(
    false
  );
  const [showpatientlistlastname, setShowPatientListLastName] = useState(false);

  useEffect(() => {
    props.getLocations(ehrID);
  }, []);

  useEffect(() => {
    if (locations != undefined) {
      let locationtopush = [];
      locationtopush.push({ key: 'Select a location', value: '' });
      locations.forEach(function (item) {
        locationtopush.push({
          value: item,
          key:
            (item.companyname !== null ? item.companyname : '') +
            ',' +
            (item.address && item.address.address ? item.address.address : '')
        });
      });
      setLocs(locationtopush);
    }
  }, [locations]);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (patients !== undefined) {
      setData(props.patients);
      if (data && data.length > 0) {
        if (showpatientlistfirstname) {
          setShowPatientListFirstName(true);
        } else {
          setShowPatientListLastName(true);
        }
      } else {
        if (showpatientlistfirstname) {
          setShowPatientListFirstName(false);
        } else {
          setShowPatientListLastName(false);
        }
      }
    }
  }, [patients]);

  const handlePrefillPatient = selPat => {
    setFormstate({
      ...formstate,
      id: selPat.id,
      lastname: selPat.lastname ? selPat.lastname : '',
      firstname: selPat.firstname ? selPat.firstname : '',
      middleinitial: selPat.middleinitial ? selPat.middleinitial : '',
      dob: selPat.dob ? selPat.dob : '',
      gender: selPat.gender ? selPat.gender : '',
      location: selPat.location ? selPat.location : '',
      phonenumber: selPat.phonenumber ? selPat.phonenumber : '',
      emailaddress: selPat.emailaddress ? selPat.emailaddress : '',
      practice: selPat.practice ? selPat.practice : null,
      isValid: true
    });
    if (showpatientlistfirstname) {
      setShowPatientListFirstName(false);
    } else {
      setShowPatientListLastName(false);
    }
  };

  const hasError = field =>
    formstate.touched[field] && formstate.errors[field] ? true : false;

  const handleFieldChange = (field, value) => {
    const newState = { ...formstate };
    newState[field] = value;
    newState.touched[field] = true;
    if (field === 'firstname') {
      if (value && value.length > 0) {
        const list = props.getSearchPatients(ehrID, value);
        if (list) {
          setShowPatientListFirstName(true);
          setShowPatientListLastName(false);
        }
      }
    }
    if (field === 'lastname') {
      if (value && value.length > 0) {
        const list = props.getSearchPatients(ehrID, value);
        if (list) {
          setShowPatientListLastName(true);
          setShowPatientListFirstName(false);
        }
      }
    }
    if (field === 'dob') {
      newState[field] = value
        ? moment(value).format('YYYY-MM-DDT00:00:00')
        : '';
    }
    setShowPatientListLastName(false);
    setShowPatientListFirstName(false);
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setFormstate(newState);
  };

  const handleAddExistingPatient = () => {
    props.handleAddExistingPatient(formstate, true);
  };

  const handleSave = async () => {
    setFormstate({ ...formstate, isLoading: true });
    if (formstate.isValid) {
      const newState = { ...formstate };
      newState.ehrID = ehrID;
      if (newState.practice) {
        newState.practice =
          Object.keys(newState.practice).length > 0
            ? JSON.parse(newState.practice)
            : undefined;
      }
      const res = await props.checkpatient(newState);
      if (res.data.length > 0) {
        handleAddExistingPatient();
      } else {
        await props.handleSave(newState);
      }
      setFormstate({ ...formstate, isLoading: false });
    }
  };

  return (
    <Box
      className={classes.modalWrap}
      style={{ width: '85%', maxWidth: 1000 }}
    >
      <Box className={classes.modalHead}>
        <Typography variant="h2">Add New Patient</Typography>
        <IconButton
          aria-label="delete"
          className={classes.modalClose}
          onClick={props.onCancel}
        >
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box className={classes.modalBody}>
        <PerfectScrollbar style={{ paddingRight: 15, marginRight: -15 }}>
          <Grid
            className={classes.contentWrap}
            container
            style={{ padding: 0 }}
            xs={12}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={4}
                style={{ position: 'relative' }}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  className={classes.textField}
                  color="Primary"
                  error={hasError('lastname')}
                  fullWidth
                  helperText={
                    hasError('lastname') ? formstate.errors.lastname[0] : null
                  }
                  label="Last Name"
                  name="lname"
                  onChange={event =>
                    handleFieldChange('lastname', event.target.value)
                  }
                  required
                  value={formstate.lastname || ''}
                  variant="outlined"
                />
                {/* {showpatientlistlastname && (
                  <PatientSearchList
                    data={data}
                    handlePrefillPatient={handlePrefillPatient}
                  />
                )} */}
              </Grid>

              <Grid
                item
                md={4}
                style={{ position: 'relative' }}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  className={classes.textField}
                  color="Primary"
                  error={hasError('firstname')}
                  fullWidth
                  helperText={
                    hasError('firstname') ? formstate.errors.firstname[0] : null
                  }
                  label="First Name"
                  name="fname"
                  onChange={event =>
                    handleFieldChange('firstname', event.target.value)
                  }
                  required
                  value={formstate.firstname || ''}
                  variant="outlined"
                />
                {/* {showpatientlistfirstname && (
                  <PatientSearchList
                    data={data}
                    handlePrefillPatient={handlePrefillPatient}
                  />
                )} */}
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  className={classes.textField}
                  color="Primary"
                  fullWidth
                  label="Middle Name"
                  name="mname"
                  onChange={event =>
                    handleFieldChange('middleinitial', event.target.value)
                  }
                  value={formstate.middleinitial}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <div className={classes.datePickerWrap}>
                  <DatePicker
                    customInput={
                      <TextField
                        className={classes.textField}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <CalendarToday
                                style={{ height: 16, width: 16 }}
                              />
                            </InputAdornment>
                          )
                        }}
                        label="Date of birth"
                        margin="dense"
                        variant="outlined"
                      />
                    }
                    format="MM/DD/YYYY"
                    maxDate={new Date()}
                    onBlur={e => (e.target.placeholder = 'Date of birth')}
                    onChange={date =>
                      handleFieldChange('dob', date)
                    }
                    onFocus={e => (e.target.placeholder = '')}
                    placeholderText="Date of birth"
                    selected={
                      formstate.dob &&
                      new Date(formstate.dob)
                    }
                    showMonthDropdown="bool"
                    showYearDropdown="bool"
                  />
                </div>
              </Grid>
              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  SelectProps={{ native: true }}
                  className={classes.textField}
                  label="Gender"
                  name="gender"
                  onChange={event =>
                    handleFieldChange('gender', event.target.value)
                  }
                  select
                  style={{ width: '100%' }}
                  value={formstate.gender}
                  variant="outlined"
                >
                  <option>Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Others">Others</option>
                </TextField>
              </Grid>

              <Grid
                item
                md={4}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  SelectProps={{ native: true }}
                  className={classes.textField}
                  label="Location"
                  name="loc"
                  onChange={event =>
                    handleFieldChange('practice', event.target.value)
                  }
                  select
                  style={{ width: '100%' }}
                  value={formstate.practice}
                  variant="outlined"
                >
                  {locs &&
                    locs.map(item => (
                      <option value={JSON.stringify(item.value)}>
                        {item.key}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  className={classes.textField}
                  color="Primary"
                  fullWidth
                  label="Cell Phone Number"
                  name="phone"
                  onChange={event =>
                    handleFieldChange('phonenumber', event.target.value)
                  }
                  value={formstate.phonenumber}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  className={classes.textField}
                  color="Primary"
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={event =>
                    handleFieldChange('emailaddress', event.target.value)
                  }
                  value={formstate.emailaddress}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                style={{ textAlign: 'right' }}
                xs={12}
              >
                <Button
                  onClick={props.onCancel}
                  style={{ backgroundColor: '#c1c1c1', marginRight: 10 }}
                  variant="contained"
                >
                  Cancel
                </Button>
                {formstate.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    size={20}
                  />
                ) : (
                  <Button
                    color="primary"
                    disabled={!formstate.isValid}
                    onClick={() => handleSave()}
                    variant="contained"
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Box>
    </Box>
  );
};
PatientAdd.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  getLocations,
  getSearchPatients,
  checkpatient
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  locations: state.util.locations,
  patients: state.patient && state.patient.search && state.patient.search.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PatientAdd))
);
