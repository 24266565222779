import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../style';
import {
  Grid,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Details } from './components';
import { Review } from 'views/Common/components';
import moment from 'moment';
import {
  getAllPhyPtSchedules,
  deletePtSchedule,
  savePtSchedule
} from 'services/ptschedules/action';
import ScheduleAppointment from '../../../PTSchedule/components/Modal_ScheduleAppointment';
import MessageBox from 'util/messageBox';

const AccountDetails = props => {
  const {
    classes,
    className,
    ehrID,
    allPhyPtSchedules,
    patientInfo,
    ...rest
  } = props;

  const [openScheduleAppointment, setOpenScheduleAppointment] = useState({
    showPopUp: false,
    appointment: null
  });
  const [openDeleteAppointment, setOpenDeleteAppointment] = useState({
    showPopUp: false,
    id: 0
  });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [isloading, setisloading] = useState(true);

  const [isHomeLocalStorage, setIsHomeLocalStorage] = useState(false);
  const [filvals, setFilVals] = useState({
    location: '',
    provider: ''
  });

  useEffect(() => {
    setIsHomeLocalStorage(true);
    props.getAllPhyPtSchedules(
      ehrID,
      moment().format('MM/DD/YYYY'),
      localStorage.getItem('filterHomeScheduleLocation') ? localStorage.getItem('filterHomeScheduleLocation') : '',
      localStorage.getItem('filterHomeScheduleProvider') ? localStorage.getItem('filterHomeScheduleProvider') : ''
    );
  }, []);

  useEffect(() => {
    setisloading(false);
  }, [allPhyPtSchedules]);

  //#region Schedule

  const handleOpenScheduleAppointment = () => {
    setOpenScheduleAppointment({ showPopUp: true });
  };

  const handleCloseScheduleAppointment = async () => {
    setOpenScheduleAppointment({ showPopUp: false });
    await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
  };

  const handleSavePtSchedule = async ptSchedule => {
    const res = await props.savePtSchedule(ehrID, ptSchedule);
    if (res) {
      setOpenScheduleAppointment({ showPopUp: false });
      setSuccMsg({ succ: true, succMsg: 'Appointment scheduled successfully' });
    }
    await props.getAllPhyPtSchedules(
      ehrID,
      moment().format('MM/DD/YYYY'),
      localStorage.getItem('filterHomeScheduleLocation'),
      localStorage.getItem('filterHomeScheduleProvider'));
  };

  const handleClose = async () => {
    setSuccMsg({ succ: false });
  };

  //#endregion

  //#region Edit & Delete Appointment
  const handleEditAppointment = item => {
    setOpenScheduleAppointment({ showPopUp: true, appointment: item });
  };

  const handleOpenDeleteAppointment = appointmentId => {
    setOpenDeleteAppointment({ showPopUp: true, appointmentId: appointmentId });
  };

  const handleDeleteOK = async () => {
    if (openDeleteAppointment.appointmentId) {
      const res = await props.deletePtSchedule(
        ehrID,
        openDeleteAppointment.appointmentId
      );
      if (res) {
        setSuccMsg({ succ: true, succMsg: 'Appointment cancelled successfully' });
      }
      setOpenDeleteAppointment({ showPopUp: false });
      setOpenScheduleAppointment({ showPopUp: false });
      await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteAppointment({ showPopUp: false });
  };

  //#endregion

  const handleDrpDownChange = (location, provider) => {
    setFilVals({
      location: location,
      provider: provider
    });
    setisloading(true);
    props.getAllPhyPtSchedules(
      ehrID,
      moment().format('MM/DD/YYYY'),
      location,
      provider
    );
  };

  const changeTab = (patientId, tabId) => {
    props.changeTab(patientId, tabId);
  };

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        className={classes.colWrap}
        item
        md={7}
        xs={12}
      >
        <Details
          changeTab={changeTab}
          patientInfo={patientInfo}
        />
      </Grid>
      <Grid
        className={classes.colWrap}
        item
        md={5}
        xs={12}
      >
        <Review
          data={allPhyPtSchedules}
          handleDeleteAppointment={handleOpenDeleteAppointment}
          handleDrpDownChange={handleDrpDownChange}
          handleEditAppointment={handleEditAppointment}
          handleOpenScheduleAppointment={handleOpenScheduleAppointment}
          isHomeLocalStorage={isHomeLocalStorage}
          isloading={isloading}
        />
      </Grid>

      {/* Start Dialogue for Delete Appointments */}
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={openDeleteAppointment.showPopUp}
      >
        <DialogTitle id="confirmation-dialog-title">
          PT Schedule Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and delete the whole appointment ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => handleDeleteOK()}
          >
            OK
          </Button>
          <Button
            color="primary"
            onClick={handleDeleteCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Dialogue for Delete Appointments */}

      {/* Start Create Appointments */}
      <Modal
        disableBackdropClick
        onClose={handleCloseScheduleAppointment}
        open={openScheduleAppointment.showPopUp}
      >
        <ScheduleAppointment
          appointment={openScheduleAppointment.appointment}
          data={allPhyPtSchedules}
          handleDeleteAppointment={handleOpenDeleteAppointment}
          handleSavePtSchedule={handleSavePtSchedule}
          onCancel={handleCloseScheduleAppointment}
        />
      </Modal>

      {/* End Create Appointments */}

      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />
    </Grid>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  deletePtSchedule,
  savePtSchedule
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,

  allPhyPtSchedules:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedules &&
    state.ptschedules.allPhyPtSchedules.result
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountDetails))
);
