import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../../style';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';

import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TextField
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  DeleteOutline,
  EditOutlined,
  HistoryOutlined
} from '@material-ui/icons';

import { connect } from 'react-redux';
import {
  getPatients,
  editPatient,
  resendActivationEmail
} from 'services/patient/action';
import {
  deleteUser,
  deactivateUser,
  reactivateUser
} from 'services/user/action';
import MessageBox from 'util/messageBox';
import Loader from '../../../../Common/components/Loader';

const View = props => {
  const { classes, className, ehrID, list, ...rest } = props;
  const [data, setData] = useState([]);
  const [succMsg, setSuccMsg] = useState(false);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [poploading, setPopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    deleteUserId: ''
  });

  const [handledeactivatepopup, setHandleDeactivatePopup] = useState({
    showDeactivatePopup: false,
    deactivateUserId: ''
  });

  const [handleEdit, setHandleEdit] = useState({
    showEdit: false,
    editUserId: ''
  });

  useEffect(() => {
    props.getPatients(ehrID, 0, '', rowsPerPage);
  }, []);

  useEffect(() => {
    if (props.list !== undefined) {
      let datatopush = [];
      list.result.forEach(function (item) {
        //const actions = <IconButton size="small" onClick={() => handleDelete(item.id)}><DeleteOutline style={{ color: '#ED2024' }} /></IconButton>;
        datatopush.push({
          patient: item,
          name:
            (item.lastName ? item.lastName : '') +
            ', ' +
            (item.firstName ? item.firstName : ''),
          patientId: item.patientId,
          id: item.id,
          username: item.userName,
          email: item.emailAddress,
          status: item.enabled ? 'Active' : 'Inactive',
          enabled: item.enabled,
          showEdit: false
        });
      });
      setData(datatopush);
      setLoading(false);
    }
  }, [props.list]);

  ///Delete patient
  const handleDelete = userID => {
    setHandleDeletePopup({ showDeletePopup: true, deleteUserId: userID });
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup({ showDeletePopup: false });
  };

  const handleDeleteUser = deleteUserId => {
    if (deleteUserId > 0) {
      props.deleteUser(ehrID, deleteUserId);
      setHandleDeletePopup({ showDeletePopup: false });
    }
  };
  const handleChangePage = async page => {
    setLoading(true);
    setPage(page);
    await props.getPatients(ehrID, page, '', rowsPerPage);
    window.scrollTo(0, 0);
  };
  const handleChangeRowsPerPage = event => {
    setLoading(true);
    const rowsPerPage = event;
    setRowsPerPage(rowsPerPage);
    props.getPatients(ehrID, page, '', rowsPerPage);
    window.scrollTo(0, 0);
  };

  const handleSearch = async (search, isClear) => {
    setSearch(search);
    if ((search && search !== '') || isClear) {
      await props.getPatients(ehrID, 0, search, rowsPerPage);
    }
  };
  const handleEditChange = email => {
    let allPatients = [...data];

    const index = data.findIndex(c => c.email === email);
    if (index != -1) {
      let selectedData = data[index];
      selectedData.showEdit = true;
      allPatients[index] = selectedData;
      setData(allPatients);
    }
    setHandleEdit({ showEdit: true });
  };

  const handleEmailChange = (field, value, id) => {
    let allPatients = [...data];
    const index = data.findIndex(c => c.id === id);
    if (index != -1) {
      let selectedData = data[index];
      selectedData.email = value;
      allPatients[index] = selectedData;
      setData(allPatients);
    }
  };

  const handleUpdatePatient = patient => {
    const user = {};
    user.emailaddress = patient.email;
    user.ehrid = patient.ehrId;
    user.firstname = patient.firstName;
    user.lastname = patient.lastName;
    user.enable = patient.enabled;
    user.role = patient.role;
    user.username = patient.userName;
    user.id = patient.id;
    props.editPatient(ehrID, patient.id, user).then(res => {
      if (res) {
        setHandleEdit({ showEdit: false });
        props.getPatients(ehrID,page,'',rowsPerPage);
      }
    });
  };

  const handleCancelEmailEdit = id => {
    props.getPatients(ehrID,page,'',rowsPerPage);
    let allPatients = data;
    const index = data.findIndex(c => c.id === id);
    if (index != -1) {
      let selectedData = data[index];
      selectedData.showEdit = false;
      allPatients[index] = selectedData;
      setData(allPatients);
    }
    setHandleEdit({ showEdit: false });
  };

  ///Resend activation
  const handleResendActivation = resendActivationUserId => {
    if (resendActivationUserId > 0) {
      props.resendActivationEmail(ehrID, resendActivationUserId).then(res => {
        if (res) {
          setSuccMsg(true);
        }
      });
    }
  };

  const handleCloseAll = () => {
    setSuccMsg(false);
  };

  ///For deactivating a user

  const handleDeactivate = userID => {
    setHandleDeactivatePopup({
      showDeactivatePopup: true,
      deactivateUserId: userID
    });
  };

  const handleCancelDeactivate = () => {
    setHandleDeactivatePopup({ showDeactivatePopup: false });
  };

  const handleDeactivateUser = async deactivateUserId => {
    if (deactivateUserId > 0) {
      setPopLoading(true);
      const res = await props.deactivateUser(ehrID, deactivateUserId);
      if (res) {
        let alldatas = data;
        const index = data.findIndex(c => c.id === deactivateUserId);
        if (index != -1) {
          let selData = data[index];
          selData.enabled = false;
          alldatas[index] = selData;
          setData(alldatas);
        }
      }
    }
    setPopLoading(false);
    setHandleDeactivatePopup({ showDeactivatePopup: false });
  };

  ///For reactivating a user
  const handleReactivateUser = async reactivateUserId => {
    if (reactivateUserId > 0) {
      setPopLoading(true);
      const res = await props.reactivateUser(ehrID, reactivateUserId);
      if (res) {
        let alldatas = data;
        const index = data.findIndex(c => c.id === reactivateUserId);
        if (index != -1) {
          let selData = data[index];
          selData.enabled = true;
          alldatas[index] = selData;
          setData(alldatas);
        }
      }
      setPopLoading(false);
    }
  };

  const theme = createTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none !important'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      }
    }
  });

  const columns = [
    {
      name: 'name',
      label: 'Patients'
    },
    {
      name: 'patientId',
      label: 'Patient ID'
    },
    {
      name: 'username',
      label: 'Username'
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const id = data.filter(c => c.email === value)[0].id;
          const patient = data.filter(c => c.id === id)[0];
          const showEdit =
            data.filter(c => c.email === value)[0] &&
            data.filter(c => c.email === value)[0].showEdit;
          return showEdit ? (
            <Grid item xs={12}>
              <TextField
                name="email"
                required
                variant="outlined"
                color="Primary"
                size="small"
                onChange={event =>
                  handleEmailChange('email', event.target.value, id)
                }
                defaultValue={value}
                //style={{ height: 30, padding: 0, lineHeight: 20 }}
                inputProps={{ style: { height: 30, padding: '0px 10px' } }}
              />
              <div>
                <Button
                  color="primary"
                  onClick={() => handleUpdatePatient(patient)}
                  size="small"
                  style={{ textTransform: 'capitalize', padding: '2px' }}>
                  Update
                </Button>{' '}
                /
                <Button
                  onClick={() => handleCancelEmailEdit(patient.id)}
                  size="small"
                  style={{ textTransform: 'capitalize', padding: '2px' }}>
                  Cancel
                </Button>
              </div>
            </Grid>
          ) : (
            <span>{value}</span>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Status',
      options: {
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const enabled = data.filter(c => c.id === value)[0].enabled;
          return enabled ? (
            <Button
              title="Click to  deactivate user."
              color="primary"
              onClick={() => handleDeactivate(value)}
              size="small"
              style={{ textTransform: 'capitalize', padding: '2px' }}>
              Active
            </Button>
          ) : (
            // <span title="Click to  deactivate user."
            //   className={classes.textGreen}
            //   onClick={() => handleDeactivate(value)}>
            //   Active
            // </span>
            <Button
              title="Click to reactivate a deactivated user."
              onClick={() => handleReactivateUser(value)}
              size="small"
              style={{ textTransform: 'capitalize', padding: '2px' }}>
              Inactive
            </Button>
            //   <span title="Click to reactivate a deactivated user."
            //     className={classes.textGrey}
            //     onClick={() => handleReactivateUser(value)}>
            //     Inactive
            // </span>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const email = data.filter(c => c.id === value)[0].email;
          const enabled = data.filter(c => c.id === value)[0].enabled;
          const showEdit =
            data.filter(c => c.id === value)[0] &&
            data.filter(c => c.id === value)[0].showEdit;
          return (
            <React.Fragment>
              <IconButton
                style={{ visibility: enabled ? 'hidden' : 'visible' }}
                title="Resend Activation"
                size="small"
                onClick={() => handleResendActivation(value)}>
                <HistoryOutlined />
              </IconButton>
              &nbsp;
              <IconButton
                style={{ visibility: showEdit ? 'hidden' : 'visible' }}
                title="Edit"
                size="small"
                onClick={() => handleEditChange(email)}>
                <EditOutlined />
              </IconButton>
              &nbsp;
              <IconButton
                title="Delete"
                size="small"
                onClick={() => handleDelete(value)}>
                <DeleteOutline style={{ color: '#ED2024' }} />
              </IconButton>
            </React.Fragment>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    download: false,
    print: false,
    search: true,
    viewColumns: false,
    pagination: props.list && props.list.totalElements > 20,
    serverSide: true,
    count: props.list && props.list.totalElements,
    page: page,
    filterType: 'select',
    selectableRows: false, // <===== will turn off checkboxes in rows
    searchPlaceholder: 'Search for patients',
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          return;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(tableState.rowsPerPage);
          return;
        case 'search':
          handleSearch(tableState.searchText);
          return;
        default:
          return;
      }
    },
    searchText: search,
    onSearchClose: action => {
      handleSearch('', true);
    }
  };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <CardContent style={{ margin: -16 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {loading ? (
              <Loader type="table" />
            ) : (
              // <div
              //   className={classes.progressWrapper}
              //   style={{
              //     position: 'absolute',
              //     left: 0,
              //     right: 0,
              //     display: 'flex',
              //     bottom: 0,
              //     top: 0,
              //     alignItems: 'flex-start',
              //     paddingTop: 120,
              //     justifyContent: 'center',
              //     backgroundColor: 'rgba(0, 0, 0,.2)'
              //   }}>
              //   <CircularProgress style={{ height: 30, width: 30 }} />
              // </div>
              <MuiThemeProvider theme={theme}>
                <MUIDataTable
                  title={'View/Manage Patients'}
                  data={data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            )}
          </Grid>
        </Grid>
      </CardContent>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={handledeletepopup.showDeletePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Account Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteUser(handledeletepopup.deleteUserId)}
            color="primary">
            Yes
          </Button>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
      <MessageBox
        open={succMsg}
        variant="success"
        onClose={handleCloseAll}
        message="Account activation mail sent successfully"
      />
      {/* patient deactivate */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={handledeactivatepopup.showDeactivatePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Account Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to de-activate the user account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              handleDeactivateUser(handledeactivatepopup.deactivateUserId)
            }
            color="primary">
            Yes
          </Button>

          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button onClick={handleCancelDeactivate} color="primary">
              No
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Card>
  );
};

View.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getPatients,
  deleteUser,
  editPatient,
  resendActivationEmail,
  deactivateUser,
  reactivateUser
};
const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  list: state.patient && state.patient.list
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(View))
);
