import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';

import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getSubjectiveTemplates,
  AddSubjectiveTopicText,
  UpdateSubjectiveTopicText
} from 'services/providers/action';

const MyTemplates = props => {
  const { classes, ehrID, userID, subjectiveTopic } = props;
  const [loading, setLoading] = useState(true);
  const [subjective, setSubjective] = useState(null);
  // const [topicText, setTopicText] = useState(null);
  const [handleEditTopicText, setHandleEditTopicText] = useState({
    showTopicTextEdit: false
  });
  const initialUserState = {
    id: null,
    referencedata: false,
    topic: '',
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topicText: {
      presence: { allowEmpty: false }
    }
  };

  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    if (props.subjectiveTemplates && subjectiveTopic) {
      const subjective = props.subjectiveTemplates.data.filter(
        item => item.topic === subjectiveTopic
      );
      setSubjective(subjective[0]);
      setLoading(false);
    }
  }, [subjectiveTopic]);

  const editTopicText = idx => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit
      ? handleEditTopicText.showTopicTextEdit.slice()
      : [];
    showTopicTextEdit[idx] = true;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });
  };

  const handleChange = (field, value, idx) => {
    const topic = { ...subjective };
    let allTexts = topic.topicText;
    let selectedData = allTexts[idx];
    selectedData.text = value;
    allTexts[idx] = selectedData;
    topic.topicText = allTexts;
    setSubjective(topic);
  };

  const updateText = (subjective, idx) => {
    props.UpdateSubjectiveTopicText(ehrID, userID, subjective).then(res => {
      closeEditTopicText(subjective.id, idx);
    });
  };

  const closeEditTopicText = (id, idx) => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit
      ? handleEditTopicText.showTopicTextEdit.slice()
      : [];
    showTopicTextEdit[idx] = false;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });

    props.getSubjectiveTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        const subjective = res.data.filter(item => item.id === id);
        setSubjective(subjective[0]);
      }
    });
  };

  const deleteTopicText = (subjective, index) => {
    const topic = { ...subjective };
    topic.topicText.splice(index, 1);
    props.UpdateSubjectiveTopicText(ehrID, userID, topic).then(res => {
      setSubjective(topic);
    });
  };

  const handleTextChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const addTopicText = () => {
    if (values.isValid) {
      const topic = { ...subjective };
      topic.topicText.push({ text: values.topicText });
      props.AddSubjectiveTopicText(ehrID, userID, topic).then(res => {
        setSubjective(topic);
        setValues(initialUserState);
      });
    }
  };

  return (
    <div>
      {subjectiveTopic !== null ? (
        <div className={classes.templateWrap}>
          <div className={classes.templateHead}>
            <Typography>{subjectiveTopic}</Typography>
          </div>
          <div className={classes.templateBody}>
            <PerfectScrollbar>
              <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
                <Table className={classes.templateTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '100%' }}>
                        <TextField
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          className={classes.textBox}
                          id="outlined-basic"
                          onChange={event =>
                            handleTextChange('topicText', event.target.value)
                          }
                          placeholder="Add New Text"
                          value={values.topicText}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: 'nowrap', maxWidth: 100 }}
                      >
                        <IconButton
                          color="primary"
                          disabled={!values.isValid}
                          onClick={() => {
                            addTopicText();
                          }}
                          size="small"
                        >
                          <SaveOutlined />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setValues(initialUserState);
                          }}
                          size="small"
                        >
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress size={24} />
                    </div>
                  ) : (
                    
                      subjective &&
                      subjective.topicText &&
                      subjective.topicText.length > 0
                        ? subjective.topicText.map((text, idx) => (
                          <TableHead>
                            <TableRow>
                              {handleEditTopicText.showTopicTextEdit &&
                                handleEditTopicText.showTopicTextEdit[idx]
                                ? [
                                  <TableCell>
                                    <TextField
                                      InputProps={{
                                        classes: {
                                          notchedOutline:
                                                classes.notchedOutline
                                        }
                                      }}
                                      className={classes.textBox}
                                      id="outlined-basic"
                                      onChange={event =>
                                        handleChange(
                                          'text.text',
                                          event.target.value,
                                          idx
                                        )
                                      }
                                      placeholder="Add New Topic"
                                      value={text.text}
                                      variant="outlined"
                                    />
                                  </TableCell>,
                                  <TableCell
                                    align="center"
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    <IconButton
                                      color="primary"
                                      onClick={() => {
                                        updateText(subjective, idx);
                                      }}
                                      size="small"
                                    >
                                      <SaveOutlined />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        closeEditTopicText(
                                          subjective.id,
                                          idx
                                        );
                                      }}
                                      size="small"
                                    >
                                      <ClearOutlined
                                        style={{ color: '#DE5555' }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                ]
                                : [
                                  <TableCell>{text.text}</TableCell>,
                                  <TableCell
                                    align="center"
                                    width={72}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        editTopicText(idx);
                                      }}
                                      size="small"
                                    >
                                      <Edit color="primary" />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        deleteTopicText(subjective, idx);
                                      }}
                                      size="small"
                                    >
                                      <DeleteOutline
                                        style={{ color: '#DE5555' }}
                                      />
                                    </IconButton>
                                  </TableCell>
                                ]}
                            </TableRow>
                          </TableHead>
                        ))
                        : (
                          <TableRow className={classes.tableRow}>
                          <TableCell
                            className={classes.tableCell}
                            colSpan={10}
                            style={{ textAlign: 'center' }}
                          >
                              No records found
                          </TableCell>
                        </TableRow>)
                    
                  )}
                 
                </Table>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      ) : null}
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getSubjectiveTemplates,
  AddSubjectiveTopicText,
  UpdateSubjectiveTopicText
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  subjectiveTemplates: state.providers && state.providers.subjectiveTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
