/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import DatePicker from 'react-datepicker';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline,
  CalendarToday
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  saveHealthMaintenance,
  updateHealthMaintenance,
  deleteHealthMaintenance
} from 'services/soap/action';

const HealthMaintenance = props => {
  const { classes, patientId } = props;

  const initialUserState = {
    id: 0,
    name: '',
    recordedDate: new Date(),
    findings: ''
  };
  const [values, setValues] = useState(initialUserState);
  const [editvalues, setEditValues] = useState(initialUserState);
  const [HM, setHM] = useState(props.healthMaintenance);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (props.healthMaintenance && props.healthMaintenance.length > 0) {
      setHM(props.healthMaintenance);
    }
  }, [props.healthMaintenance]);

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleEditFieldChange = (field, value) => {
    setEditValues({ ...editvalues, [field]: value });
  };

  const handleSaveHealthMaintenance = async () => {
    if (values.name || values.findings) {
      const res = await props.saveHealthMaintenance(patientId, values);
      if (res.status) {
        setHM(res.healthMaintenance);
        setValues(initialUserState);
        setIsEdit(false);
        props.getHealthMaintenance(res.healthMaintenance);
      }
    }
  };

  const handleUpdateHealthMaintenance = async idx => {
    if (editvalues.name || editvalues.findings) {
      const res = await props.updateHealthMaintenance(patientId, editvalues);
      if (res.status) {
        const newState = [...HM];
        newState[idx] = res.healthMaintenance;
        setHM(newState);
        setIsEdit(false);
        props.getHealthMaintenance(newState);
      }
    }
  };

  const handleDeleteHealthMaintenance = async id => {
    const res = await props.deleteHealthMaintenance(patientId, id);
    if (res.status) {
      setHM(res.healthMaintenance);
      setValues(initialUserState);
      props.getHealthMaintenance(res.healthMaintenance);
    }
  };

  const handleEditHealthMaintenance = async (data, index) => {
    setIsEdit(true);
    if (data) {
      setEditValues({
        id: data.id,
        name: data.name,
        recordedDate: data.recordedDate,
        findings: data.findings,
        editIndex: index
      });
    }
  };

  const clearHealthMaintenance = () => {
    setIsEdit(false);
    setValues(initialUserState);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody} style={{ borderTop: 'default' }}>
        {/* <PerfectScrollbar> */}
        <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
          <Table className={classes.templateTable}>
            <TableHead>
              <TableRow>
                <TableCell width="20%">
                  <TextField
                    className={classes.textBox}
                    id="outlined-basic"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    onChange={event =>
                      handleFieldChange('name', event.target.value)
                    }
                    placeholder="Input Name"
                    value={values.name}
                    variant="outlined"
                  />
                </TableCell>

                <TableCell width="30%">

                  <DatePicker
                    customInput={
                      <TextField
                        className={classes.textField}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <CalendarToday
                                style={{ height: 16, width: 16 }}
                              />
                            </InputAdornment>
                          )
                        }}
                        // label="Date Recorded"
                        margin="dense"
                        variant="outlined"
                      />
                    }
                    format="MM/DD/YYYY"
                    onBlur={e => (e.target.placeholder = 'recordedDate')}
                    onChange={date =>
                      handleFieldChange(
                        'recordedDate',
                        date
                      )
                    }
                    onFocus={e => (e.target.placeholder = 'Date Recorded')}
                    placeholderText="dategiven"
                    selected={
                      values.recordedDate &&
                      new Date(values.recordedDate)
                    }
                    showMonthDropdown="bool"
                    showYearDropdown="bool"
                  />
                </TableCell>

                <TableCell width="60%">
                  <TextField
                    className={classes.textBox}
                    id="outlined-basic"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    onChange={event =>
                      handleFieldChange('findings', event.target.value)
                    }
                    placeholder="Input Findings"
                    value={values.findings}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                  <IconButton
                    // disabled={values.name || values.findings}
                    onClick={() => handleSaveHealthMaintenance()}
                    size="small"
                    style={{ color: '#03A484' }}>
                    <CheckOutlined />
                  </IconButton>
                  <IconButton
                    onClick={() => clearHealthMaintenance()}
                    size="small">
                    <ClearOutlined style={{ color: '#DE5555' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {HM &&
                HM.length > 0 &&
                HM.map((item, idx) => {
                  return (
                    <TableRow>
                      {isEdit && idx === editvalues.editIndex ? (
                        <>
                          <TableCell width="20%">
                            <TextField
                              className={classes.textBox}
                              id="outlined-basic"
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline
                                }
                              }}
                              onChange={event =>
                                handleEditFieldChange(
                                  'name',
                                  event.target.value
                                )
                              }
                              placeholder="Input Name"
                              value={editvalues.name}
                              variant="outlined"
                            />
                          </TableCell>

                          <TableCell width="30%">

                            <DatePicker
                              customInput={
                                <TextField
                                  className={classes.textField}
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                        <CalendarToday
                                          style={{ height: 16, width: 16 }}
                                        />
                                      </InputAdornment>
                                    )
                                  }}
                                  // label="Date Recorded"
                                  margin="dense"
                                  variant="outlined"
                                />
                              }
                              format="MM/DD/YYYY"
                              onBlur={e => (e.target.placeholder = 'recordedDate')}
                              onChange={date =>
                                handleEditFieldChange(
                                  'recordedDate',
                                  date
                                )
                              }
                              onFocus={e => (e.target.placeholder = 'Date Recorded')}
                              placeholderText="dategiven"
                              selected={
                                editvalues.recordedDate &&
                                new Date(editvalues.recordedDate)
                              }
                              showMonthDropdown="bool"
                              showYearDropdown="bool"
                            />
                          </TableCell>

                          <TableCell width="60%">
                            <TextField
                              className={classes.textBox}
                              id="outlined-basic"
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline
                                }
                              }}
                              onChange={event =>
                                handleEditFieldChange(
                                  'findings',
                                  event.target.value
                                )
                              }
                              placeholder="Input Findings"
                              value={editvalues.findings}
                              variant="outlined"
                            />
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{item && item.name}</TableCell>
                          <TableCell>
                            {item && item.recordedDate
                              ? moment(item.recordedDate).format('MM/DD/YYYY')
                              : ''}
                          </TableCell>
                          <TableCell>{item && item.findings}</TableCell>
                        </>
                      )}
                      <TableCell align="center" width={72}>
                        {isEdit && idx === editvalues.editIndex ? (
                          <>
                            <IconButton
                              // disabled={values.name || values.reaction}
                              onClick={() =>
                                handleUpdateHealthMaintenance(idx)
                              }
                              size="small"
                              style={{ color: '#03A484' }}>
                              <CheckOutlined />
                            </IconButton>
                            <IconButton
                              onClick={() => clearHealthMaintenance()}
                              size="small">
                              <ClearOutlined style={{ color: '#DE5555' }} />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton
                              onClick={() =>
                                handleEditHealthMaintenance(item, idx)
                              }
                              size="small">
                              <Edit color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                handleDeleteHealthMaintenance(item.id)
                              }
                              size="small">
                              <DeleteOutline style={{ color: '#DE5555' }} />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {/* </PerfectScrollbar> */}
      </div>
    </div>
  );
};
HealthMaintenance.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  saveHealthMaintenance,
  updateHealthMaintenance,
  deleteHealthMaintenance
};

const mapStateToProps = () => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(HealthMaintenance))
);
