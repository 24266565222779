import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../../../SignIn/style';
import { LockOpenOutlined } from '@material-ui/icons';
import validate from 'validate.js';

import {
  TextField,
  Link,
  Typography,
  withStyles,
  Fab,
  CircularProgress
} from '@material-ui/core';

import { forgotPassword } from 'services/user/action';
import { connect } from 'react-redux';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 4,
      maximum: 64
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: { required: true, message: '^Email is not valid' },
    length: {
      minimum: 4,
      maximum: 64
    }
  }
};

const ForgotPw = props => {
  const { classes } = props;

  const [values, setValues] = useState({
    username: '',
    email: '',
    ehrId: 0,
    isValid: false,
    touched: {},
    errors: {},
    isForgotPwdView: true,
    isSentPwdView: false,
    isLoading: false
  });

  const hasError = field =>
    values.touched[field] && values.errors[field] ? true : false;
  // const [errors, setErrors] = useState(values.errors);
  // const showUsernameError =
  //   values.touched.username && errors.username ? errors.username[0] : false;
  // const showEmailError =
  //   values.touched.email && errors.email ? errors.email[0] : false;

  const requestPassword = async () => {
    if (values.isValid) {
      setValues({ ...values, isLoading: true });
      let { isForgotPwdView, isSentPwdView } = values;
      const status = await props.forgotPassword(
        values.ehrId,
        values.email,
        values.username
      );
      if (status) {
        isForgotPwdView = false;
        isSentPwdView = true;
      }
      setValues({
        ...values,
        isForgotPwdView: isForgotPwdView,
        isSentPwdView: isSentPwdView,
        isLoading: false
      });
    }
  };

  const showLogin = async () => {
    props.history.push('/sign-in');
  };

  const handleChange = (field, value) => {
    const newState = { ...values };

    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    setValues(newState);
  };

  return ( 
      <span>
        {values.isForgotPwdView ? (
          <div
            className={classes.contentBodyMsg}
            style={{ position: 'relative' }}>
            <div className={classes.contentHeader}>
              <LockOpenOutlined />
              <Typography variant="h1">Forgot your password?</Typography>
            </div>
            <div className={classes.contentInner}>
              <Typography variant="h6">Please enter your username</Typography>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.inputOutlined,
                    focused: classes.inputFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                error={hasError('username')}
                fullWidth
                helperText={
                  hasError('username') ? values.errors.username[0] : null
                }
                label="Username"
                name="username"
                onChange={event => handleChange('username', event.target.value)}
                style={{ maxWidth: 400, width: '100%' }}
                type="text"
                value={values.username || ''}
                variant="outlined"
              />
              <br />
              &nbsp;
              <Typography variant="h6">
                Please enter the email associated with the account
              </Typography>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                    focused: classes.labelFocused
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.inputOutlined,
                    focused: classes.inputFocused,
                    notchedOutline: classes.notchedOutline
                  }
                }}
                error={hasError('email')}
                fullWidth
                helperText={hasError('email') ? values.errors.email[0] : null}
                label="Email"
                name="email"
                onChange={event => handleChange('email', event.target.value)}
                style={{ maxWidth: 400, width: '100%' }}
                type="email"
                value={values.email || ''}
                variant="outlined"
              />
              <br />
              <br />
              {values.isLoading ? (
                <CircularProgress className={classes.progress} />
              ) : (
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="primary"
                  disabled={!values.isValid}
                  //component={RouterLink}
                  //to="/sign-in"
                  onClick={requestPassword}
                  size="medium"
                  style={{ width: '100px !important', marginBottom: 5 }}
                  variant="extended">
                  Reset Password
                </Fab>
              )}
              <Typography className={classes.forgotTxt}>
                Did you remember your password?{' '}
                <Link component={RouterLink} onClick={showLogin}>
                  Try Logging in
                </Link>
              </Typography>
            </div>
          </div>
        ) : null}
        {values.isSentPwdView ? (
          <div
            className={classes.contentBodyMsg}
            style={{ position: 'relative' }}>
            <div className={classes.contentHeader}>
              <LockOpenOutlined />
              <Typography variant="h1">Forgot your password?</Typography>
            </div>
            <div className={classes.contentInner}>
              <Typography>
                To ensure your security, a confirmation email has been sent to
                the registered email address. <br />
                Please check your mailbox and follow the instruction in the
                email.{' '}
              </Typography>
              <br />
              <Typography>
                Having problems? <br />
                If you do not receive your password recovery email within a few
                minutes, please check your spam folder.{' '}
              </Typography>
              <br />
              <Fab
                aria-label="add"
                className={classes.roundButton}
                color="primary"
                component={RouterLink}
                onClick={showLogin}
                size="medium"
                style={{ width: '100px !important', marginBottom: 5 }}
                variant="extended">
                Go Back
              </Fab>
            </div>
          </div>
        ) : null}
      </span> 
  );
};

ForgotPw.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = {
  forgotPassword
};

const mapStateToProps = () => ({
  //user:state.user
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPw))
);
