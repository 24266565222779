import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import validate from 'validate.js';
import schema from './schemaSecondaryInfoEdit';
import spouseSchema from './schemaSecondaryInfoEditSpouse'
import {
  PrimaryInfo,
  SecondaryInfo,
  SecondaryInfoEdit,
  PrimaryInsurance,
  InsuranceEdit,
  SecondaryInsurance
} from './';
import { connect } from 'react-redux';
import {
  savePatientInsurance,
  downloadPatientInsuranceDF
} from 'services/ptinfo/action';
import moment from 'moment';
import MessageBox from 'util/messageBox';

const UserSettings = props => {
  const { classes, className, patientInfoDetail, ehrID, ...rest } = props;
  const [isEdit, setIsEdit] = React.useState(false);
  const [data, setData] = useState(patientInfoDetail);
  const [primaryholder, setPrimaryholder] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isSavedPopUp, setIsSavedPopUp] = useState(false);
  const [errmsg, setErrMsg] = useState({ err: false, errMsg: '' });

  useEffect(() => {
    if (patientInfoDetail) {
      if (
        patientInfoDetail &&
        patientInfoDetail.primaryInsurance &&
        patientInfoDetail.primaryInsurance.primaryholder === true
      ) {
        setPrimaryholder(true);
      }
      setData({ ...patientInfoDetail, touched: {}, errors: {} });
    }
  }, [patientInfoDetail]);

  const setEdit = () => {
    setIsEdit(!isEdit);
    document.getElementById('top').scrollIntoView();
  };

  const handleFieldChange = (
    field,
    value,
    isAddress,
    isSpouse,
    isEmergency,
    isPrimary,
    isPrimaryAddress,
    isPrimaryGroup,
    isPrimaryGroupAddress,
    isSecondary,
    isSecondaryAddress,
    isSecondaryGroup,
    isSecondaryGroupAddress
  ) => {
    const newState = { ...data };
    setIsSaved(false);
    if (isAddress) {
      let addr = data.address;
      if (addr) {
        addr[field] = value;
      } else {
        addr = {};
        addr[field] = value;
      }
      newState['address'] = addr;
    } else if (isPrimary) {
      let primary = data.primaryInsurance || {};
      let primaryAddress =
        data.primaryInsurance && data.primaryInsurance.address
          ? data.primaryInsurance.address
          : '';
      let primaryGroup =
        data.primaryInsurance && data.primaryInsurance.grouppolicy
          ? data.primaryInsurance.grouppolicy
          : '';
      let primaryGroupAddress =
        data.primaryInsurance &&
          data.primaryInsurance.grouppolicy &&
          data.primaryInsurance.grouppolicy.address
          ? data.primaryInsurance.grouppolicy.address
          : '';

      if (isPrimaryAddress) {
        if (primaryAddress) {
        } else {
          primary.address = {};
        }
        primary.address[field] = value;
      } else if (isPrimaryGroup) {
        if (!primary.grouppolicy) {
          primary.grouppolicy = {}
        }
        if (!primary.grouppolicy.address) {
          primary.grouppolicy.address = {};
        }
        if (isPrimaryGroupAddress) {
          if (primaryGroupAddress) {
          } else {
            primary.grouppolicy.address = {};
          }
          primary.grouppolicy.address[field] = value;
        } else {
          if (primaryGroup) {
          } else {
            primary.grouppolicy = {};
          }
          primary.grouppolicy[field] = value;
        }
      } else {
        if (primary) {
        } else {
          primary = {};
        }
        if (field === 'primaryholder') {
          let prmryhlder = value === 'true' ? false : true;
          primary[field] = prmryhlder;
          setPrimaryholder(prmryhlder);
        } else {
          primary[field] = value;
        }
      }

      newState['primaryInsurance'] = primary;
    } else if (isSecondary) {
      let secondary = data.secondaryInsurance || {};
      let secondaryAddress =
        data.secondaryInsurance && data.secondaryInsurance.address
          ? data.secondaryInsurance.address
          : '';
      let secondaryGroup =
        data.secondaryInsurance && data.secondaryInsurance.grouppolicy
          ? data.secondaryInsurance.grouppolicy
          : '';
      let secondaryGroupAddress =
        data.secondaryInsurance &&
          data.secondaryInsurance.grouppolicy &&
          data.secondaryInsurance.grouppolicy.address
          ? data.secondaryInsurance.grouppolicy.address
          : '';

      if (isSecondaryAddress) {
        if (secondaryAddress) {
        } else {
          secondary.address = {};
        }
        secondary.address[field] = value;
      } else if (isSecondaryGroup) {
        if (isSecondaryGroupAddress) {
          if (!secondary.grouppolicy) {
            secondary.grouppolicy = {}
          }
          if (!secondary.grouppolicy.address) {
            secondary.grouppolicy.address = {};
          }
          if (secondaryGroupAddress) {
          } else {
            secondary.grouppolicy.address = {};
          }
          secondary.grouppolicy.address[field] = value;
        } else {
          if (secondaryGroup) {
          } else {
            secondary.grouppolicy = {};
          }
          secondary.grouppolicy[field] = value;
        }
      } else {
        if (secondary) {
        } else {
          secondary = {};
        }
        secondary[field] = value;
      }
      newState['secondaryInsurance'] = secondary;
    } else if (isEmergency) {
      let emergncy = data.emergencyContact || {};
      if (emergncy) {
        emergncy[field] = value;
      } else {
        emergncy = {};
        emergncy[field] = value;
      }
      newState['emergencyContact'] = emergncy;
    } else if (isSpouse) {
      let spouseDtl = data.spouse;
      if (spouseDtl) {
        if (field === 'dob') {
          spouseDtl[field] = value ? moment(value).format('MM/DD/YYYY') : null;

        } else {
          spouseDtl[field] = value;
        }
      } else {
        spouseDtl = {};
        if (field === 'dob') {
          spouseDtl[field] = value ? moment(value).format('MM/DD/YYYY') : null;

        } else {
          spouseDtl[field] = value;
        }
      }
      newState['spouse'] = spouseDtl;
    } else if (field === 'dob') {
      newState[field] = value ? moment(value).format('MM/DD/YYYY') : null;

    } else if (field === 'id') {
      let pra = data.practice;
      if (pra) {
        pra[field] = value;
      } else {
        pra = {};
        pra[field] = value;
      }
      newState['practice'] = pra;
    } else {
      newState[field] = value;
    }

    setData(newState);
  };

  const validateForm = () => {
    const newState = { ...data };
    const errors = validate(data, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setData(newState);
    return newState.isValid;
  };

  const validateFormSpouse = () => { 
    const newState = { ...data };
    const errors = validate(data?.spouse, spouseSchema);
    newState.spouse.errors = errors || {};
    newState.isValidSpouse = errors ? false : true;
    setData(newState);
    return newState.isValidSpouse;
  };

  const handleClose = () => {
    setErrMsg({ err: false });
  };

  const showErrorMsg = msg => {
    setErrMsg({ err: true, errMsg: msg });
  };

  const renderError = () => {
    return (
      <MessageBox
        message={errmsg.errMsg}
        onClose={handleClose}
        open
        variant="error"
      />
    );
  };

  const handleCancelSave = () => {
    setIsSavedPopUp(true);
  };

  const hidePopUp = () => {
    setIsSavedPopUp(false);
  };

  const handleSave = async () => {
    if (validateForm()) {
      if (data?.spouse &&
        (data?.spouse?.dob && data?.spouse?.dob !== 'Invalid date' && data?.spouse?.dob !== "") ||
        (data?.spouse?.phonenumber && data?.spouse?.phonenumber !== "") ||
        (data?.spouse?.middleinitial && data?.spouse?.middleinitial !== "")) {
        if (validateFormSpouse()) {
          const newState = { ...data };
          if (newState.dob) {
            newState.dob = newState.dob
              ? moment(newState.dob).format('YYYY-MM-DD')
              : '';
          }
          if (newState.spouse && newState.spouse.dob) {
            newState.spouse.dob = newState.spouse.dob
              ? moment(newState.spouse.dob).format('YYYY-MM-DD')
              : '';
          }
          const res = await props.savePatientInsurance(ehrID, newState);
          if (res) {
            setIsEdit(!isEdit);
            setIsSaved(true);
          }
        }
        else {
          showErrorMsg("Please fill the required fields")
        }
      } else {
        const newState = { ...data };
        if (!newState?.spouse?.firstname || !newState?.spouse?.lastname || newState?.spouse?.firstname === "" || newState?.spouse?.lastname === "") {
          newState.spouse = null;
          setData({ ...data, spouse: null });
        }
        if (newState.dob && newState?.dob !== 'Invalid date' && newState?.dob !== "") {
          newState.dob = newState.dob
            ? moment(newState.dob).format('YYYY-MM-DD')
            : '';
        } else {
          newState.dob = null;
          setData({ ...data, spouse: null });
        }
        const res = await props.savePatientInsurance(ehrID, newState);
        if (res) {
          setIsEdit(!isEdit);
          setIsSaved(true);
        }
      }
    }
  };

  const handleDownloadPatientInsurance = async () => {
    props.downloadPatientInsuranceDF(ehrID, patientInfoDetail.id).then(res => {
      if (res.status) {
        let file = new Blob([res.result.data], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank');
      }
    });
  };

  // const handlePrintPatientInsurance = async () => {
  //   props.downloadPatientInsuranceDF(ehrID, patientInfoDetail.id).then(res => {
  //     if (res.status) {
  //       let file = new Blob([res.result.data], { type: 'application/pdf' });
  //       let fileURL = URL.createObjectURL(file);
  //       // window.open(fileURL, 'PRINT', 'height=400,width=600');
  //     }
  //   });
  // };

  return (
    <Grid
      container
      id="top"
      spacing={3}
    >
      <Grid
        item
        md={12}
        xs={12}
      >
        <PrimaryInfo
          goBack={props.goBack}
          handleCancelSave={handleCancelSave}
          handleDownloadPatientInsurance={handleDownloadPatientInsurance}
          handleSave={handleSave}
          isEdit={isEdit}
          isSaved={isSaved}
          patientinformation={data}
          setEdit={setEdit}
        />
      </Grid>
      {!isEdit ? (
        <>
          <Grid
            item
            md={12}
            xs={12}
          >
            <SecondaryInfo patientinformation={data} />
          </Grid>
          <Grid
            className={classes.colWrap}
            item
            md={6}
            xs={12}
          >
            <PrimaryInsurance patientinformation={data} />
          </Grid>
          <Grid
            className={classes.colWrap}
            item
            md={6}
            xs={12}
          >
            <SecondaryInsurance patientinformation={data} />
          </Grid>
        </>
      ) : (
        <>
          <Grid
            item
            md={12}
            xs={12}
          >
            <SecondaryInfoEdit
              handleFieldChange={handleFieldChange}
              patientinformation={data}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <InsuranceEdit
              handleFieldChange={handleFieldChange}
              insuranceType={'Primary'}
              patientinformation={data}
            />
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <InsuranceEdit
              handleFieldChange={handleFieldChange}
              insuranceType={'Secondary'}
              patientinformation={data}
            />
          </Grid>
          <Grid
            item
            style={{ textAlign: 'right' }}
            xs={12}
          >
            <Button
              color="secondary"
              onClick={setEdit}
              style={{ backgroundColor: '#c1c1c1', marginRight: 10 }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => handleSave()}
              variant="contained"
            >
              Save
            </Button>
          </Grid>
          {/* Start Dialogue */}
          <Dialog
            aria-labelledby="confirmation-dialog-title"
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            open={isSavedPopUp}
          >
            <DialogTitle id="confirmation-dialog-title">
              Unsaved patient information
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to exit without saving?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={() => { props.goBack(); setEdit(); hidePopUp(); }}
              >
                OK
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  hidePopUp();
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* End Dialogue */}
        </>
      )}
      {errmsg.err && renderError()}
    </Grid>
  );
};

UserSettings.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = { savePatientInsurance, downloadPatientInsuranceDF };

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(UserSettings));
