import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import styles from '../style';
import { Link as RouterLink } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import PatientFilter from 'views/Common/components/PatientFilter';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import moment from 'moment';
import {clearSoap} from 'services/soap/action';

const PTList = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      clearFilterValue,
      setLocationFilterValues
    };
  });

  const { classes,  data,state } = props;
  const [filvals, setFilVals] = useState({
    location: '',
    provider: '',
    status: ''
  });
  const handleChangePage = async page => {
    props.handleChangePage(page);
  };

  const handleOpenPatientIssue = ptIssue => {
    props.handleOpenPatientIssue(ptIssue);
  };

  const handleOpenProviderResponse = ptIssue => {
    props.handleOpenProviderResponse(ptIssue);
  };

  const handleOpenStatusComplete = ptIssue => {
    props.handleOpenStatusComplete(ptIssue);
  };

  const handleOpenSOAP = patientId => {
    props.clearSoap();
    props.history.push({
      pathname: '/default',
      state: { patientId: patientId }
    });
  };

  const childRef = useRef();

  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none !important'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: { 
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: '#EFF5FC!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          // backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          // backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          // padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientName',
      label: 'Patient Name',
      options: {
        sort: false,
        setCellProps: value => ({
          style: { width: '20%', minWidth: 110 }
        }),
        setCellHeaderProps: value => ({
          // style: { textAlign: 'center' }
        }),

        customBodyRender: (value, tableMeta) => {
          return (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenSOAP(tableMeta.rowData[0])}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Patient Issues',
      options: {
        setCellProps: () => ({
          style: { width: '40%' }
        }),
        setCellHeaderProps: () => ({
          //style: { textAlign: 'center' }
        }),
        customBodyRender: value => {
          const status = data.filter(c => c.id === value);
          return status[0].ptIssue && !status[0].providerResponse ? (
            <RouterLink
              style={{ color: '#FF0000', cursor: 'pointer' }}
              onClick={() => handleOpenPatientIssue(status[0])}>
              {status[0].ptIssue}
              <br /> Route: {status[0].issueReportedPerson}
              {status[0].issueReportedDate
                ? '(' +
                  moment(status[0].issueReportedDate).format('MM/DD/YYYY') +
                  ')'
                : null}
            </RouterLink>
          ) : status[0].ptIssue &&
            status[0].providerResponse &&
            status[0].status === 0 ? (
            <span
            // onClick={() => handleOpenPatientIssue(status[0])}
            >
              {status[0].ptIssue} <br />
              Route: {status[0].issueReportedPerson}
              {status[0].issueReportedDate
                ? '(' +
                  moment(status[0].issueReportedDate).format('MM/DD/YYYY') +
                  ')'
                : null}
            </span>
          ) : status[0].ptIssue &&
            status[0].providerResponse &&
            status[0].status === 1 ? (
            <span
              style={{ color: '#0000FF' }}
              // onClick={() => handleOpenPatientIssue(status[0])}
            >
              {status[0].ptIssue} <br />
              Route: {status[0].issueReportedPerson}
              {status[0].issueReportedDate
                ? '(' +
                  moment(status[0].issueReportedDate).format('MM/DD/YYYY') +
                  ')'
                : null}
            </span>
          ) : (
            ''
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Provider Response',
      options: {
        setCellProps: () => ({
          //style: { width: '130px' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '40%' }
        }),
        customBodyRender: value => {
          const status = data.filter(c => c.id === value);
          return !status[0].ptIssue ? (
            <span style={{ color: '#ED2024' }}>No Response</span>
          ) : status[0].ptIssue && !status[0].providerResponse ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleOpenProviderResponse(status[0])}>
              Click to respond
            </Button>
          ) : status[0].ptIssue &&
            status[0].providerResponse &&
            status[0].status === 1 ? (
            <RouterLink
              style={{ color: '#0000FF', cursor: 'pointer' }}
              onClick={() => handleOpenProviderResponse(status[0])}>
              {status[0].providerResponse}
              <br /> {status[0].responseReportedPerson}
              {status[0].responseReportedDate
                ? '(' +
                  moment(status[0].responseReportedDate).format('MM/DD/YYYY') +
                  ')'
                : null}
            </RouterLink>
          ) : status[0].ptIssue &&
            status[0].providerResponse &&
            status[0].status === 0 ? (
            <span>
              {status[0].providerResponse}
              <br /> {status[0].responseReportedPerson}
              {status[0].responseReportedDate
                ? '(' +
                  moment(status[0].responseReportedDate).format('MM/DD/YYYY') +
                  ')'
                : null}
            </span>
          ) : (
            ''
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Status',
      options: {
        setCellProps: () => ({
          style: { width: '10%', minWidth: 90, textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '10%', minWidth: 90, textAlign: 'center' }
        }),
        customBodyRender: value => {
          const status = data.filter(c => c.id === value);
          return status[0].ptIssue && !status[0].providerResponse ? (
            <span style={{ color: '#FF0000' }}>No Response</span>
          ) : status[0].ptIssue &&
            status[0].providerResponse &&
            status[0].status === 1 ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleOpenStatusComplete(status[0])}>
              Click to complete
            </Button>
          ) : status[0].ptIssue &&
            status[0].providerResponse &&
            status[0].status === 0 ? (
            <span>
              Completed
              <br />
              {status[0].responseReportedPerson}
              {status[0].responseReportedDate
                ? '(' +
                  moment(status[0].responseReportedDate).format('MM/DD/YYYY') +
                  ')'
                : null}
            </span>
          ) : (
            ''
          );
        }
      }
    }
  ];

  const handleDrpDownChangeptIssue = (location, provider, selstatus) => {
    setFilVals({
      location: location,
      provider: provider,
      status: selstatus
    });
    props.handleDrpDownChangeptIssue(location, provider, selstatus);
  };

  const clearFilterValue = () => {
    if (childRef.current) {
      childRef.current.clearFilter();
    }
  };

  const setLocationFilterValues = () => {
    if (childRef.current) {
      childRef.current.loadLocalStorage();
    }
  };

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 20,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: state.count > 20,
    selectableRows: false,
    serverSide: true,
    count: state.count,
    page: state.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page,filvals.location, filvals.provider, filvals.status);
          break;
      }
    },
    customToolbar: () => {
      return (
        <PatientFilter
          handleDrpDownChangeptIssue={handleDrpDownChangeptIssue}
          isptMessages={false}
          ref={childRef}
          isLocalStorage={props.isLocalStorage}
        />
      );
    }
  };

  return (
    <Grid container spacing={0} style={{ borderTop: '1px solid #D4E5F9' }}>
      <Grid item xs={12}>
        <MuiThemeProvider theme={theme}>
          {/* <PerfectScrollBar></PerfectScrollBar> */}
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
            responsive="scrollMaxHeight"
            className={classes.simpleTable}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  );
});

PTList.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {clearSoap};
const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(withStyles(styles)(PTList))
);
