import palette from '../palette';

export default {
  tooltip: {
    backgroundColor: 'white',
    padding: '10px 12px',
    color: 'rgba(0, 0, 0, 0.65)',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.45)',
    fontSize: 11
  }
};
