import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';

import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getObjectiveTemplates,
  AddObjectiveTopicText,
  UpdateObjectiveTopicText
} from 'services/providers/action';

const MyTemplates = props => {
  const { classes, ehrID, userID, objectiveTopic } = props;
  const [loading, setLoading] = useState(true);
  const [objective, setObjective] = useState(null);
  // const [topicText, setTopicText] = useState(null);
  const [handleEditTopicText, setHandleEditTopicText] = useState({ showTopicTextEdit: false });
  const initialUserState = {
    id: null,
    referencedata: false,
    topic: "",
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topicText: {
      presence: { allowEmpty: false }
    }
  }
  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    if (props.objectiveTemplates && objectiveTopic) {
      const objective = props.objectiveTemplates.data.filter((item) => item.topic === objectiveTopic);
      setObjective(objective[0]);
      setLoading(false);
    }
  }, [objectiveTopic])

  const editTopicText = (idx) => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit ? handleEditTopicText.showTopicTextEdit.slice() : [];
    showTopicTextEdit[idx] = true;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });
  }

  const handleChange = (field, value, idx) => {
    const topic = { ...objective };
    let allTexts = topic.topicText;
    let selectedData = allTexts[idx];
    selectedData.text = value;
    allTexts[idx] = selectedData;
    topic.topicText = allTexts;
    setObjective(topic);
  };

  const updateText = (objective, idx) => {
    props.UpdateObjectiveTopicText(ehrID, userID, objective).then((res) => {
      closeEditTopicText(objective.id, idx);
    });
  }

  const closeEditTopicText = (id, idx) => {
    let showTopicTextEdit = handleEditTopicText.showTopicTextEdit ? handleEditTopicText.showTopicTextEdit.slice() : [];
    showTopicTextEdit[idx] = false;
    setHandleEditTopicText({ showTopicTextEdit: showTopicTextEdit });

    props.getObjectiveTemplates(ehrID, userID).then((res => {
      if (res && res.data.length > 0) {
        const objective = res.data.filter((item) => item.id === id);
        setObjective(objective[0]);
      }
    }));
  }

  const deleteTopicText = (objective, index) => {
    const topic = { ...objective };
    topic.topicText.splice(index, 1);
    props.UpdateObjectiveTopicText(ehrID, userID, topic).then((res) => {
      setObjective(topic);
    });
  }

  const handleTextChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  }

  const addTopicText = () => {
    if (values.isValid) {
      const topic = { ...objective };
      topic.topicText.push({ text: values.topicText });
      props.AddObjectiveTopicText(ehrID, userID, topic).then((res) => {
        setObjective(topic);
        setValues(initialUserState);
      });
    }
  }

  return (
    <div>
      {objectiveTopic !== null ?
        (<div className={classes.templateWrap}>
          <div className={classes.templateHead}>
            <Typography>{objectiveTopic}</Typography>
          </div>
          <div className={classes.templateBody}>
            <PerfectScrollbar>
              <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
                <Table className={classes.templateTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '100%' }}>
                        <TextField
                          id="outlined-basic"
                          placeholder="Add New Text"
                          variant="outlined"
                          className={classes.textBox}
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          value={values.topicText}
                          onChange={event =>
                            handleTextChange("topicText", event.target.value)
                          }
                        />
                      </TableCell>
                      <TableCell align="center" style={{ whiteSpace: 'nowrap', maxWidth: 100 }}>
                        <IconButton disabled={!values.isValid} size="small" color="primary" onClick={() => { addTopicText() }}>
                          <SaveOutlined />
                        </IconButton>
                        <IconButton size="small" onClick={() => { setValues(initialUserState); }}>
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody> */}
                  {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress size={24} />
                    </div>) : [objective && objective.topicText && objective.topicText.length > 0 ?
                      (objective.topicText.map((text, idx) => (
                        <TableHead>
                          <TableRow>
                            {handleEditTopicText.showTopicTextEdit && handleEditTopicText.showTopicTextEdit[idx] ? [<TableCell>
                              <TextField
                                id="outlined-basic"
                                placeholder="Add New Topic"
                                variant="outlined"
                                className={classes.textBox}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                value={text.text}
                                onChange={event =>
                                  handleChange("text", event.target.value, idx)
                                }
                              />
                            </TableCell>,
                            <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                              <IconButton size="small" color="primary" onClick={() => { updateText(objective, idx) }}>
                                <SaveOutlined />
                              </IconButton>
                              <IconButton size="small" onClick={() => { closeEditTopicText(objective.id, idx) }}>
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>] :
                              [<TableCell>
                                {text.text}
                              </TableCell>,
                              <TableCell width={72} align="center">
                                <IconButton size="small" onClick={() => { editTopicText(idx) }}>
                                  <Edit color="primary" />
                                </IconButton>
                                <IconButton size="small" onClick={() => { deleteTopicText(objective, idx) }}>
                                  <DeleteOutline style={{ color: '#DE5555' }} />
                                </IconButton>
                              </TableCell>]
                            }
                          </TableRow>
                        </TableHead>
                      ))
                      ) : null,]}
                  {/* </TableBody> */}
                </Table>
              </div>
            </PerfectScrollbar>
          </div>
        </div>) : null}
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getObjectiveTemplates,
  AddObjectiveTopicText,
  UpdateObjectiveTopicText
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  objectiveTemplates: state.providers && state.providers.objectiveTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
