import store from "../../store";

export const errorHandler = async (error, message) => {
  console.log(error);
  const { response } = error

  if (!message) {
    message = response && response.data && response.data[0] ? response.data[0].message : (response && response.data && response.data.message ? response && response.data && response.data.message : "Unknown error");
  }

  switch (response.status) {
    case 401: // auth failed
      // if (message === "Bad credentials") {
      //   message = "The username or password is incorrect.";
      // }
      await store.dispatch({
        type: "LOGOUT"
      });

      break;
    case 400: //validation error

      break;
    case 404: // server error
      message = "Opps... something went wrong";
      break;
    case 408: // timesout
      message = "Request timed out, please try again";
      break;
    default:
      break;
  }

  store.dispatch({
    type: "SHOW_ERROR",
    data: { ...response, message }
  });
};

export const removeError = () => {
  return store.dispatch({
    type: "REMOVE_ERROR",
    data: {}
  });
};
