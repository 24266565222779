export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  textGrey: {
    color: '#a2a2a2'
  },
  textGreen: {
    color: theme.palette.success.main
  },
  contentWrap: {
    background: '#fff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: 10
  },
  leftWrap: {
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      flexDirection: 'row',
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      display: 'flex'
    }
  },
  rightWrap: {
    border: '1px solid #D4E5F9'
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalBody: {
    padding: '10px 0'
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalFooter: {
    padding: '20px 0px 10px 0px',
    textAlign: 'right'
  },
  patientName: {
    fontWeight: 600,
    fontSize: 16,
    marginTop: 20,
    marginBottom: 5
  },
  clickable: {
    cursor: 'pointer'
  },
  dialTitle: {
    '& h2': {
      fontWeight: '800'
    }
  },
  generalTable: {
    '& td': {
      padding: '8px 5px',
      color: '#4A4A4A',
      fontSize: 13,
      lineHeight: '16px',
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    '& th': {
      padding: '8px 5px',
      fontWeight: 600,
      background: 'transparent',
      borderBottom: '1px solid #a2a2a2'
    },
    '& thead': {
      backgroundColor: 'transparent'
    }
  },
  ellipsisMode: {
    display: 'grid',
    '& p': {
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  },
  simpleTable: {
    '& td a': {
      color: '#263238',
      '&:hover': {
        color: '#000'
      },
      '&:active': {
        color: '#BE8F44'
      }
    }
  },
  leftButton: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});
