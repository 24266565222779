import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Typography,
  CircularProgress,
  Modal,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import styles from './style';
import { connect } from 'react-redux';
import { PatientList } from './components';
import { Calendars, Reminders, PatientSearch } from '../Common/components';
import { CloseOutlined } from '@material-ui/icons';
import PatientIssues from 'views/PTIssues/components/Modal_PatientIssues';
import ProviderResponse from 'views/PTIssues/components/Modal_ProviderResponse';
import {
  getAllPhyPtIssues,
  setPtIssueComplete
} from 'services/ptissues/action';

const PTIssues = props => {
  const {
    classes,
    ehrID,
    allPhyPtIssues,
    allPhyPtIssuesofDate,
    userID
  } = props;
  const ptIssueChildRef = useRef(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [patientissuepopupdata, setPatientIssuePopupData] = useState([]);
  const [openpatientissue, setOpenPatientIssue] = React.useState(false);
  const [openproviderresponse, setOpenProviderResponse] = React.useState(false);
  const [providerresponsedata, setProviderResponseData] = useState([]);
  const [openstatuscomplete, setOpenStatusComplete] = React.useState(false);
  const [statusdata, setStatusData] = useState([]);
  const [poploading, setPopLoading] = useState(false);
  const [state, setState] = useState({ page: 0, count: 0 });
  const [isLocalStorage, setIsLocalStorage] = useState(false);
  const [filvals, setFilVals] = useState({
    location: localStorage.getItem('filterPTIssueLocation') ? localStorage.getItem('filterPTIssueLocation') : '',
    provider: localStorage.getItem('filterPTIssueProvider') ? localStorage.getItem('filterPTIssueProvider') : '',
    status: localStorage.getItem('filterPTIssueStatus') ? localStorage.getItem('filterPTIssueStatus') : ''
  });

  useEffect(() => {
    props.getAllPhyPtIssues(ehrID,
      '',
      localStorage.getItem('filterPTIssueLocation') ? localStorage.getItem('filterPTIssueLocation') : '',
      localStorage.getItem('filterPTIssueProvider') ? localStorage.getItem('filterPTIssueProvider') : '',
      localStorage.getItem('filterPTIssueStatus') ? localStorage.getItem('filterPTIssueStatus') : '');
    setIsLocalStorage(true)
  }, []);

  useEffect(() => {
    if (allPhyPtIssues && allPhyPtIssues.content !== undefined) {
      setState({ ...state, count: allPhyPtIssues.totalElements });
      let datatopush = [];
      allPhyPtIssues.content.forEach(function (item) {
        datatopush.push({
          id: item.id,
          patientId: item.patientId,
          patientName: item.patientName,
          ptIssue: item.ptIssue,
          issueReportedBy: item.issueReportedBy,
          issueReportedPerson: item.issueReportedPerson,
          issueReportedDate: item.issueReportedDate,
          providerResponse: item.providerResponse,
          responseReportedPerson: item.responseReportedPerson,
          responseReportedDate: item.responseReportedDate,
          status: item.status,
          completedPerson: item.completedPerson,
          completedDate: item.completedDate,
          updatedDate: item.updatedDate
        });
      });
      setData(datatopush);
      setLoading(false);
    }
  }, [allPhyPtIssues]);

  useEffect(() => {
    if (allPhyPtIssuesofDate && allPhyPtIssuesofDate.content !== undefined) {
      setState({ ...state, count: allPhyPtIssuesofDate.totalElements });
      let datatopush = [];
      allPhyPtIssuesofDate.content.forEach(function (item) {
        datatopush.push({
          id: item.id,
          patientId: item.patientId,
          patientName: item.patientName,
          ptIssue: item.ptIssue,
          issueReportedBy: item.issueReportedBy,
          issueReportedPerson: item.issueReportedPerson,
          issueReportedDate: item.issueReportedDate,
          providerResponse: item.providerResponse,
          responseReportedPerson: item.responseReportedPerson,
          responseReportedDate: item.responseReportedDate,
          status: item.status,
          completedPerson: item.completedPerson,
          completedDate: item.completedDate,
          updatedDate: item.updatedDate
        });
      });
      setData(datatopush);
      setLoading(false);
    }
  }, [allPhyPtIssuesofDate]);

  const handleChangePage = async page => {
    setLoading(true);
    setState({ ...state, page: page });
    await props.getAllPhyPtIssues(ehrID, '', filvals.location, filvals.provider, filvals.status, '', page);
    window.scrollTo(0, 0);
  };

  //Patient Issue

  const handleOpenPatientIssue = (ptIssue, patient = '') => {
    if (ptIssue) {
      setPatientIssuePopupData(ptIssue);
    } else {
      setPatientIssuePopupData({
        id: 0,
        patientId: patient.id,
        status: 1
      });
    }
    setOpenPatientIssue(true);
  };

  const handleClosePatientIssue = () => {
    setOpenPatientIssue(false);
    props.getAllPhyPtIssues(ehrID, '', filvals.location, filvals.provider, filvals.status, '', state.page);
  };

  // Provider Response

  const handleOpenProviderResponse = ptissue => {
    setProviderResponseData({ ...ptissue, responseReportedBy: userID });
    setOpenProviderResponse(true);
  };
  const handleCloseProviderResponse = () => {
    setOpenProviderResponse(false);
    props.getAllPhyPtIssues(ehrID, '', filvals.location, filvals.provider, filvals.status, '', state.page);
  };

  //Status

  const handleOpenStatusComplete = ptissue => {
    setStatusData(ptissue);
    setOpenStatusComplete(true);
  };

  const handleCloseStatusComplete = () => {
    setOpenStatusComplete(false);
    props.getAllPhyPtIssues(ehrID, '', filvals.location, filvals.provider, filvals.status, '', state.page);
  };

  const handleStatusComplete = async () => {
    setPopLoading(true);
    const res = await props.setPtIssueComplete(statusdata);
    if (res) {
      handleCloseStatusComplete();
    }
    setPopLoading(false);
  };

  const handleDateChangeptIssue = async date => {

    setIsLocalStorage(false);
    setLoading(true);
    await props.getAllPhyPtIssues(ehrID, date);
  };

  const clearValues = async () => {
    if (ptIssueChildRef && ptIssueChildRef.current) {
      ptIssueChildRef.current.clearFilterValue();
    }
  };

  const handleDrpDownChangeptIssue = async (location, provider, selstatus) => {
    setFilVals({
      location: location,
      provider: provider,
      status: selstatus
    });
    setLoading(true);
    setState({ page: 0, count: 0 })
    await props.getAllPhyPtIssues(ehrID, '', location, provider, selstatus);
  };

  return (
    <div className={classes.root}>
      <Grid
        className={classes.contentWrap}
        container
        xs={12}
      >
        <Grid
          className={classes.leftWrap}
          item
          md={3}
          xs={12}
        >
          <Calendars
            allPhyPtIssues={allPhyPtIssues}
            handleDateChangeptIssue={handleDateChangeptIssue}
            isptMessages={false}
          />
          <Reminders />
        </Grid>
        {loading ? (
          <div
            className={classes.progressWrapper}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              display: 'flex',
              bottom: 0,
              top: 0,
              alignItems: 'flex-start',
              paddingTop: 120,
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0,.2)'
            }}
          >
            <CircularProgress style={{ height: 30, width: 30 }} />
          </div>
        ) : (
          <Grid
            className={classes.rightWrap}
            item
            md={9}
            xs={12}
          >
            <PatientSearch
              clear={clearValues}
              data={data}
              handleOpenPatientIssue={handleOpenPatientIssue}
              isptMessages={false}
              plane

            />
            <PatientList
              data={data}
              handleChangePage={handleChangePage}
              handleDrpDownChangeptIssue={handleDrpDownChangeptIssue}
              handleOpenPatientIssue={handleOpenPatientIssue}
              handleOpenProviderResponse={handleOpenProviderResponse}
              handleOpenStatusComplete={handleOpenStatusComplete}
              isLocalStorage={isLocalStorage}
              isptMessages={false}
              ref={ptIssueChildRef}
              state={state}
            />
            {/* Start Modal for StaffResponse */}
            <Modal
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
              disableBackdropClick
              onClose={handleClosePatientIssue}
              open={openpatientissue}
            >
              <Box
                className={classes.modalWrap}
                style={{ width: 500 }}
              >
                <Box className={classes.modalHead}>
                  <Typography variant="h2">Patient Issue</Typography>
                  <IconButton
                    aria-label="delete"
                    className={classes.modalClose}
                    onClick={handleClosePatientIssue}
                  >
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <PatientIssues
                  onCancel={handleClosePatientIssue}
                  patientIssuepopupdata={patientissuepopupdata}
                />
              </Box>
            </Modal>
            {/* End Modal for StaffResponse */}

            {/* Start Modal for ProviderResponse */}
            <Modal
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
              disableBackdropClick
              onClose={handleCloseProviderResponse}
              open={openproviderresponse}
            >
              <Box
                className={classes.modalWrap}
                style={{ width: 500 }}
              >
                <Box className={classes.modalHead}>
                  <Typography variant="h2">Provider Response</Typography>
                  <IconButton
                    aria-label="delete"
                    className={classes.modalClose}
                    onClick={handleCloseProviderResponse}
                  >
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <ProviderResponse
                  onCancel={handleCloseProviderResponse}
                  providerresponsedata={providerresponsedata}
                />
              </Box>
            </Modal>
            {/* End Modal for ProviderResponse */}

            {/*Start Status Complete */}
            <Dialog
              aria-describedby="are-you-sure"
              aria-labelledby="Complete"
              keepMounted={false}
              onClose={handleCloseStatusComplete}
              open={openstatuscomplete}
            >
              <DialogTitle
                className={classes.dialTitle}
                id="complete"
              >
                {'Complete'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="are-you-sure">
                  Are you sure, you want to sign off?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseStatusComplete}
                  size="small"
                  style={{ boxShadow: 'none' }}
                  variant="contained"
                >
                  No
                </Button>
                {poploading ? (
                  <CircularProgress
                    className={classes.progress}
                    size={20}
                  />
                ) : (
                  <Button
                    autoFocus
                    color="primary"
                    onClick={() => handleStatusComplete()}
                    size="small"
                    variant="contained"
                  >
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            {/* End Status Complete */}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  getAllPhyPtIssues,
  setPtIssueComplete
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtIssues:
    state.ptissues &&
    state.ptissues.allPhyPtIssues &&
    state.ptissues.allPhyPtIssues.result,
  allPhyPtIssuesofDate:
    state.ptissues &&
    state.ptissues.allPhyPtIssuesofDate &&
    state.ptissues.allPhyPtIssuesofDate.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PTIssues))
);
