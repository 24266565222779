/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState
} from 'react';
import { DocViewer, PatientDetails } from 'views/Common/components';
import { UploadDoc } from '../../Ptdoc/components';
import { DxUpdate, MHxSoap, SoapEntry } from './';
import styles from './../../../style';

import {
  Box, Button, Card,
  CardContent,
  Divider,
  Grid, Modal, Tab, Tabs, Typography
} from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  clearPtDoc, getDocInfo, getDocumentCategories, getDocuments, getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, getPatientDocuments, getPatientMiscDocuments, uploadFilePDF
} from 'services/home/action';
import { loadOrders } from 'services/orders/action';

import _ from 'lodash';
import {
  getPastSoapNoteById, resetValue, saveTempData
} from 'services/soap/action';
const Review = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      getValuesforCopy,loadCPT
    };
  });
  const {
    classes,
    className,
    patientId,
    ehrID,
    patientInfo,
    allergies,
    socialHistory,
    documentInfo,
    miscDocumentInfo,
    profile,
    copySoap,
    editfromChartReview,
    ptRemarks,
    clearPtDoc,
    getPatientAllClinicalDocuments,
    getPatientAllMiscellaneousDocuments,
    getPatientCharts,
    getDocumentCategories,
    loadOrders,
    ...rest
  } = props;
  const [loading, setLoading] = useState(true);
  const [isPast, setIsPast] = useState(false);
  const [openDoc, setOpenDoc] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState({
    show: false,
    patientId: 0
  });
  let [isClinical, setIsClinical] = useState(false);
  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [miscDataDocViewer, setMiscDataDocViewer] = useState([]);
  const [soap, setSoap] = useState([]);
  const [doc,setDoc]= useState([]);
  const [miscDoc,setmiscDoc]=useState([]);
  const [total, setTotal] = useState(null)
  const childRef = useRef();
  const [incompeltePtDocCount, setIncompeltePtDocCount] = useState(0);

  useEffect(() => {
    if (ptRemarks) { 
      let count =
      ptRemarks &&
      ptRemarks.filter(c => c.documentRoute.signed == false) &&
      ptRemarks.filter(c => c.documentRoute.signed == false).length;
      setIncompeltePtDocCount(count);
    }
  }, [ptRemarks]);

    useEffect(() => {
    props.getDocumentCategories(ehrID);
  }, []);

  useEffect(() => {
    async function fetchData(patientId,ehrID) { 
      clearPtDoc()
      getPatientAllClinicalDocuments(patientId, ehrID)
      getPatientAllMiscellaneousDocuments(patientId, ehrID)
      props.loadOrders(patientId);
      const res = await getPatientCharts(patientId, 0)
      setTotal(res.totalElements) 
    }
    if (!_.isEmpty(patientInfo)) {

      fetchData(patientInfo.id, ehrID)
    }
  }, []);

  useEffect(() => {
    // if (patientId) { 
    //   props.getPastSoapNoteById(patientId);
    // }
    if (
      editfromChartReview &&
      editfromChartReview.soap &&
      editfromChartReview.soap.patient.id !== patientId
    ) {
      props.clearLocationandEditChartView();
    }
  }, []);

  const loadCPT = async (copySoap) => {
 handleCopySoap(copySoap);
  };

  useEffect(() => {
    handleCopySoap(copySoap);
  }, [copySoap]);

  const handleCopySoap=(copySoap)=>{
    if (copySoap && (copySoap.soap || copySoap.pastSoapNoteDetails)) {
      if (childRef.current && childRef.current.loadCopyEditSoapDetails) {
        childRef.current.loadCopyEditSoapDetails(
          {
            soap: copySoap.soap,
            pastSoapNoteDetails: copySoap.pastSoapNoteDetails,
            fromIsMHx: copySoap.fromIsMHx ? copySoap.fromIsMHx : false,
 			      isCopyNeglect : copySoap.isCopy?true:false,
             editSoap:copySoap.editSoap
          },
          copySoap.isEdit ?copySoap.isEdit : false,
          copySoap.isCopy ?copySoap.isCopy : false,
          copySoap.isPast ?copySoap.isPast : false
        );
      }
    }
  }

  const clearSoap = async () => { 
    props.resetValue();
  };

  const clearLocationandEditChartView = async () => {
    props.clearLocationandEditChartView();
  };

  useEffect(() => {
    if (
      editfromChartReview &&
      (editfromChartReview.soap || editfromChartReview.pastSoapNoteDetails)
    ) {
      props.saveTempData(editfromChartReview);
    }
  }, [editfromChartReview]);

  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  useEffect(() => {
    if (miscDocumentInfo !== undefined) {
      setMiscDataDocViewer(miscDocumentInfo);
    }
  }, [miscDocumentInfo]);

  const handleOpenDoc = async (
    patientId,
    resourceID,
    routeTo,
    isClinical,
    patient
  ) => {
    setIsClinical(isClinical);
    setDataDocViewer([])
    setOpenDoc(true);
    const doc = await props.getDocInfo(patientId,resourceID)
    if (doc !== null && doc.data!=="") {
      setDoc(doc)
    } else {
      setDoc(null)
    }
    await props.getPatientDocuments(
      ehrID,
      patientId,
      resourceID,
      routeTo,
      patient
    );
    
  };

  const handleOpenMiscDoc = async (
    ehrID,
    patientId,
    resourceID,
    item,
    isClinical
  ) => {
    setDataDocViewer([])
    setIsClinical(isClinical);
    setOpenDoc(true);
    const miscDoc = await props.getDocInfo(patientId,resourceID)
    if (miscDoc !== null && miscDoc.data!=="") {
      setmiscDoc(miscDoc)
    } else {
      setmiscDoc(null)
    }
    await props.getPatientMiscDocuments(ehrID, patientId, resourceID, item);
  };

  const handleCloseDoc = () => {
      setOpenDoc(false); 
      setDataDocViewer([])  
  };

  const handleDeleteDoc = () => {
    setOpenDoc(false);  
    refreshDoc()
    setDataDocViewer([])
   };

   const refreshDoc = () =>{
    clearPtDoc()
    getPatientAllClinicalDocuments(patientInfo.id, ehrID)
    getPatientAllMiscellaneousDocuments(patientInfo.id, ehrID)
    props.loadOrders(patientInfo.id); 
   }

  const handleOpenUpload = patId => {
    setOpenUpload({ show: true, patientId: patId });
  };
  const handleCloseUpload = () => {
    setOpenUpload({ show: false });
  };


  const setPast = async (isAuto = false) => {
   // if (!isAuto) {
      let values;
      if (childRef && childRef.current) {
        values = await childRef.current.getValuesforCopy();
        await childRef.current.loadCopyEditSoapDetails(
          {
            soap: values.soap,
            pastSoapNoteDetails: values.pastSoapNoteDetails,
            editSoap:values.editSoap
          },
          values.isEdit,
          values.isCopy,
         ! values.isPast
        );
      }
    //}
    //setIsPast(!isPast);
  };

  const getValuesforCopy = async () => {
    let values;
    if (childRef && childRef.current) {
      values = await childRef.current.getValuesforCopy();
    } else {
      values = soap;
    }
    return values;
  };

  // const getValuesforMhx = async () => {
  //   let data;
  //   let newState = await childRef.current.getValuesforCopy();
  //   console.log()
  //   if (newState.isEdit || newState.isCopy) { 
  //     let compdata = soap;
  //     var diaequals =
  //     soap.pastSoapNoteDetails && soap.pastSoapNoteDetails.diagnosisList && soap.pastSoapNoteDetails.diagnosisList.length ===
  //     compdata.pastSoapNoteDetails && compdata.pastSoapNoteDetails.diagnosisList &&  compdata.pastSoapNoteDetails.diagnosisList.length &&
  //       soap.pastSoapNoteDetails.diagnosisList.every(
  //         (e, i) =>
  //           e.code === compdata.pastSoapNoteDetails.diagnosisList[i].code &&
  //           e.description ===
  //             compdata.pastSoapNoteDetails.diagnosisList[i].description
  //       );
  //     var cptequals =
  //     soap.pastSoapNoteDetails && soap.pastSoapNoteDetails.cptList && soap.pastSoapNoteDetails.cptList.length ===
  //     compdata.pastSoapNoteDetails && compdata.pastSoapNoteDetails.cptList &&  compdata.pastSoapNoteDetails.cptList.length &&
  //       soap.pastSoapNoteDetails.cptList.every(
  //         (e, i) =>
  //           e.code === compdata.pastSoapNoteDetails.cptList[i].code &&
  //           e.description ===
  //             compdata.pastSoapNoteDetails.cptList[i].description
  //       );
  //     if (!cptequals || !diaequals) {
  //       newState.pastSoapNoteDetails.cptList =
  //         compdata.pastSoapNoteDetails.cptList;
  //       newState.pastSoapNoteDetails.diagnosisList =
  //         compdata.pastSoapNoteDetails.diagnosisList;
  //     } else {
  //     }

  //    data = newState;
  //   } else {
  //   data = await childRef.current.getValuesforCopy();
  //   }
  //   return data;
  // };

  const [value, setValue] = useState(0);

  // useEffect(() => {
  //   if (patientInfo && patientInfo.activeTab) {
  //     setValue(patientInfo.activeTab);
  //   }
  // }, [patientInfo && patientInfo.activeTab]);

  const handleChange = async (event, newValue) => {
    if (childRef.current && childRef.current.saveTempData) {
      await childRef.current.saveTempData(true);
    }
    setValue(newValue);
  };

  const handlegoBack = () => {
    setOpenDoc(false);
  };

  const handleCopy = async (soap, pastSoapNoteDetails) => {
    // if (childRef.current && childRef.current.loadCopyEditSoapDetails) {
    //   await childRef.current.loadCopyEditSoapDetails(
    //     { soap: soap, pastSoapNoteDetails: pastSoapNoteDetails },
    //     false,
    //     true,
    //     true
    //   );
    // }
    props.saveTempData({ soap: soap, pastSoapNoteDetails: pastSoapNoteDetails,isEdit:false,isCopy:true,isPast:true });

  
    // setIsPast(!isPast);
  };

  const handleEdit = async (soap, pastSoapNoteDetails) => {
    // if (childRef.current && childRef.current.loadCopyEditSoapDetails) {
    //   await childRef.current.loadCopyEditSoapDetails(
    //     { soap: soap, pastSoapNoteDetails: pastSoapNoteDetails },
    //     true,
    //     false,
    //     true
    //   );
    // }
    props.saveTempData({ soap: soap, pastSoapNoteDetails: pastSoapNoteDetails,isEdit:true,isCopy:false,isPast:true });
    // setIsPast(!isPast);
  };

  // const setEdit = async () => {
  //   setIsEdit(false);
  // };

  // const setCopy = async () => {
  //   setIsCopy(false);
  // };

  const refreshData = () => {
    props.fetchPatientInfo(patientId);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        aria-labelledby={`simple-tab-${index}`}
        component="div"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        role="tabpanel"
        {...other}>
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.subTabRoot, classes.soapLeftWrap, className)}>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            aria-label="simple tabs example"
            className={classes.subTab}
            indicatorColor="primary"
            onChange={handleChange}
            value={value}
            variant="fullWidth">
            <Tab label="SOAP" {...a11yProps(0)} />
            <Tab label="Med Hx Update" {...a11yProps(1)} />
            <Tab label="Diagnosis Update" {...a11yProps(2)} />
            <Tab
              label={
                incompeltePtDocCount > 0 ? (
                  <div>
                    <span
                      style={{
                        height: 5,
                        width: 5,
                        borderRadius: 3,
                        backgroundColor: 'red',
                        display: 'inline-block',
                        marginBottom: 7,
                        marginRight: -1
                      }}
                    />{' '}
                    PT Document
                  </div>
                ) : (
                  'PT Document'
                )
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Divider />
      <CardContent className={classes.inlineTabWrap}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TabPanel index={0} style={{ padding: 0 }} value={value}>
              <Grid container spacing={3}>
                <SoapEntry
                  activeTab={value}
                  clearLocationandEditChartView={clearLocationandEditChartView}
                  clearSoap={clearSoap}
                  // copySoap={copySoap}
                  handleCopy={props.handleCopy}
                  patientId={patientId}
                  patientInfo={patientInfo}
                  ref={childRef}
                  refreshData={refreshData}
                  setPast={setPast}
                  // soap={soap}
                  handleCopyPast={handleCopy}
                  handleEditPast={handleEdit}
                />

                {/* {!isPast ? (
                  <Grid item xs={6}>
                    <SoapPast
                      handleCopy={handleCopy}
                      handleEdit={handleEdit}
                      isPast={isPast}
                      patientId={patientId}
                      patientInfo={patientInfo}
                      ref={childRef}
                      setPast={setPast}
                    />
                  </Grid>
                ) : null} */}
              </Grid>
            </TabPanel>
            <TabPanel index={1} value={value}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MHxSoap
                    activeTab={value}
                    additionalItems={props.additionalItems}
                    advanceDirectives={props.advanceDirectives}
                    allergies={props.allergies}
                    familyHistory={props.familyHistory}
                    getAdditionalItems={props.getAdditionalItems}
                    getAdvanceDirectives={props.getAdvanceDirectives}
                    getAllergy={props.getAllergy}
                    getFamilyHistory={props.getFamilyHistory}
                    getHealthMaintenance={props.getHealthMaintenance}
                    getInactiveMedication={props.getInactiveMedication}
                    getMedication={props.getMedication}
                    getSocialHistory={props.getSocialHistory}
                    getVaccination={props.getVaccination}
                    handleChange={handleChange}
                    healthMaintenance={props.healthMaintenance}
                    inactivemedication={props.inactivemedication}
                    medication={props.medication}
                    patientId={patientId}
                    socialHistory={props.socialHistory}
                    vaccinations={props.vaccinations}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel index={2} value={value}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DxUpdate
                    cpt={props.cpt}
                    diagnosis={props.diagnosis}
                    getCpt={props.getCpt}
                    getDiagnosis={props.getDiagnosis}
                    getInactiveCpt={props.getInactiveCpt}
                    getInactiveDiagnosis={props.getInactiveDiagnosis}
                    inactivecpt={props.inactivecpt}
                    inactivediagnosis={props.inactivediagnosis}
                    patientId={patientId}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel index={3} value={value}>
              <Grid container spacing={3}>
                <Grid
                  className={classes.cardHeader}
                  item
                  md={6}
                  sm={4}
                  style={{ padding: 28, paddingBottom: 0 }}
                  xs={12}>
                  <Typography variant="h2">
                    {(profile && profile.firstname ? profile.firstname : '') +
                      ' ' +
                      (profile && profile.lastname ? profile.lastname : ' ') +
                      ' ' +
                      (profile && profile.title ? profile.title : '')}
                  </Typography>
                </Grid>
                <Grid
                  className={classes.cardHeader}
                  item
                  md={6}
                  sm={8}
                  style={{
                    justifyContent: 'flex-end',
                    padding: 28,
                    paddingBottom: 0
                  }}
                  xs={12}>
                  <Button
                    color="secondary"
                    disableElevation="true"
                    onClick={() =>
                      patientInfo &&  handleOpenUpload(patientInfo.id)
                    }
                    size="small"
                    startIcon={<CloudUploadOutlined />}
                    style={{ whiteSpace: 'nowrap' }}
                    variant="contained">
                    Upload Document
                  </Button>
                </Grid>
                <Grid item style={{ padding: 28 }} xs={12}>
                  <PatientDetails
                    activeTab={value}
                    goBack={handlegoBack}
                    handleOpenDoc={handleOpenDoc}
                    handleOpenMiscDoc={handleOpenMiscDoc}
                    isSoapView
                    patient={patientInfo}
                    soap
                    total={total}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </CardContent>
      <Modal onClose={handleCloseDoc} open={openDoc}>
        <DocViewer
          dataDocViewer={dataDocViewer}
          isClinical={isClinical}
          miscDataDocViewer={miscDataDocViewer}
          onCancel={handleCloseDoc}
          doc={doc}
          miscDoc={miscDoc}
          handleDeleteDoc={handleDeleteDoc}
        
        />
      </Modal>

      <Modal onClose={handleCloseDoc} open={openUpload.show}>
        <UploadDoc
          //  handleSubmit={handleSubmit}
          onCancel={handleCloseUpload}
          patientId={openUpload.patientId}
          refreshDoc={refreshDoc}
        />
      </Modal>
    </Card>
  );
});

Review.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getDocuments,
  getPatientDocuments,
  getDocInfo,
  uploadFilePDF,
  getDocInfo,
  getPatientMiscDocuments,
  saveTempData,
  getPastSoapNoteById,
  resetValue,
  clearPtDoc,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  loadOrders,
  getDocumentCategories,
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  profile: state && state.profile,
  documentsList: state.home && state.home.documentsList,
  documentInfo: state.home && state.home.documentInfo,
  miscDocumentInfo: state.home && state.home.miscDocumentInfo,
  patientInfo: state.soap && state.soap.patientInfo,
  ptRemarks:state.soap && state.soap.ptRemarks
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(Review));
