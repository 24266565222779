import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Button,
  Select,
  Input,
  CircularProgress
} from '@material-ui/core';
import { Check, CancelOutlined, AddCircleOutline } from '@material-ui/icons';

import validate from 'validate.js';
import schema from './schema';
import { connect } from 'react-redux';
import {
  getSystemICD10Codes,
  addSystemICD10Code,
  deleteSystemICD10Code
} from 'services/icd10/action';

const System = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => {
    return {
      deleteSelectedICD10,
      loadSystemICD10
    };
  });
  // const System = props => {
  const { classes, ehrID } = props;
  const [loading, setLoading] = useState(true);
  const [userCode, setUserCode] = React.useState([]);
  const [icdCodeMasterList, setICDCodeMasterList] = React.useState([]);
  const [systemICDCode, setSystemICDCode] = React.useState([]);
  const [icdSystemQuery, setICDSystemQuery] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const initialUserState = {
    code: '',
    description: '',
    keyid: ehrID,
    isValid: false,
    touched: {},
    errors: {}
  };
  const [values, setValues] = useState(initialUserState);

  useEffect(() => {
    loadSystemICD10();
  }, []);

  const loadSystemICD10 = () => {
    setLoading(true);
    props.getSystemICD10Codes(ehrID).then(res => {
      if (res && res.data.length > 0) {
        setSystemICDCode(res.data);
        setICDCodeMasterList(res.data);
        setLoading(false);
      }
    });
  };

  const addSingleICD10 = () => {
    const codes = [];
    codes.push({
      code: values.code,
      description: values.description,
      keyid: values.keyid
    });
    if (values.isValid) {
      props.addSystemICD10Code(ehrID, codes).then(res => {
        if (res) {
          setEdit();
          props.getSystemICD10Codes(ehrID).then(res => {
            if (res && res.data.length > 0) {
              setICDCodeMasterList(res.data);
              if (icdSystemQuery !== '') {
                let searchResult = null;
                searchResult = findICDs(res.data, icdSystemQuery);
                setSystemICDCode(searchResult);
              } else {
                setSystemICDCode(res.data);
              }
            }
          });
          setValues(initialUserState);
        }
      });
    }
  };

  const _handleKeyDown = e => {
    if (e.key === 'Enter') {
      addSingleICD10();
    }
  };

  const handleChange = (field, value) => {
    if (field === 'icdSystemQuery') {
      setICDSystemQuery(value);
      let searchResult = null;
      searchResult = findICDs(icdCodeMasterList, value);
      setSystemICDCode(searchResult);
    } else {
      const newState = { ...values };
      newState[field] = value;
      newState.touched[field] = true;
      const errors = validate(newState, schema);
      newState.errors = errors || {};
      newState.isValid = errors ? false : true;
      setValues(newState);
    }
  };

  const findICDs = (items, text) => {
    text = text.toLowerCase().split(' ');
    return items.filter(function(item) {
      return text.every(function(el) {
        return (
          (item.code && item.code.toLowerCase().indexOf(el) > -1) ||
          (item.description && item.description.toLowerCase().indexOf(el) > -1)
        );
      });
    });
  };

  const handleChangeMultiple = event => {
    const { options } = event.target;
    const value = [];
    const selectedVal = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
        selectedVal.push(
          icdCodeMasterList.filter(
            item => item.id === parseInt(options[i].value)
          )
        );
      }
    }
    setUserCode(value);
    props.setSelectedUserCodes(selectedVal, 'system');
  };

  const deleteSelectedICD10 = async selectedICD10 => {
    const codes = [];
    codes.push({
      code: values.code,
      description: values.description,
      keyid: values.keyid
    });
    if (selectedICD10.length > 0) {
      props.deleteSystemICD10Code(ehrID, selectedICD10).then(res => {
        if (res) {
          props.getSystemICD10Codes(ehrID).then(res => {
            if (res && res.data.length > 0) {
              setSystemICDCode(res.data);
              setICDCodeMasterList(res.data);
            }
          });
        }
      });
    }
  };

  const setEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>System ICD &amp; Descriptions</Typography>
      </div>

      <div className={classes.templateBody}>
        <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
          <Table
            className={classes.templateTable}
            style={{ overflow: 'hidden' }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '100%' }}>
                  <TextField
                    className={[classes.textBox, classes.fullWidth]}
                    id="outlined-basic"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.notchedOutline
                      }
                    }}
                    onChange={event =>
                      handleChange('icdSystemQuery', event.target.value)
                    }
                    placeholder="Search system codes and descriptions"
                    value={icdSystemQuery}
                    variant="outlined"
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {!isEdit ? (
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={() => setEdit()}
                      size="small"
                      variant="contained"
                    >
                      <AddCircleOutline />
                    </Button>
                  ) : (
                    <Button
                      className={classes.addButton}
                      color="primary"
                      onClick={() => setEdit()}
                      size="small"
                      style={{ backgroundColor: '#B9B9B9' }}
                      variant="contained"
                    >
                      <CancelOutlined />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            {isEdit ? ( 
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '100%', whiteSpace: 'nowrap' }}>
                      <TextField
                        className={classes.textBox}
                        id="outlined-basic"
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        onChange={event =>
                          handleChange('code', event.target.value)
                        }
                        placeholder="Input Code"
                        style={{ width: 80 }}
                        value={values.code}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textBox}
                        id="outlined-basic"
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        onChange={event =>
                          handleChange('description', event.target.value)
                        }
                        onKeyPress={_handleKeyDown}
                        placeholder="Input Description"
                        style={{ width: 'calc(100% - 85px)', marginLeft: 5 }}
                        value={values.description}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <IconButton
                        color="primary"
                        disabled={!values.isValid}
                        onClick={() => addSingleICD10()}
                        size="small"
                        style={{ marginLeft: -15, color: '#03A484' }}
                      >
                        <Check />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead> 
            ) : null}
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  style={{
                    borderColor: 'transparent',
                    height: 'calc(100vh - 400px)'
                  }}
                >
                 
                    {loading ? (
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <CircularProgress size={24} />
                      </div>
                    ) :systemICDCode && systemICDCode.length > 0
                    ?(
                        <Select
                          className={classes.multiSel}
                          disabledUnderline
                          input={
                            <Input
                              classes={{
                                underline: classes.underline
                              }}
                            />
                          }
                          inputProps={{ size: '10' }}
                          multiple
                          native
                          onChange={handleChangeMultiple}
                          value={userCode}
                          variant="standard"
                        >
                          {systemICDCode && systemICDCode.length > 0
                            ? systemICDCode.map(icdcode => (
                              <option
                                code={icdcode.code}
                                description={icdcode.description}
                                key={icdcode.id}
                                value={icdcode.id}
                              >
                                {icdcode.code} {icdcode.description}
                              </option>
                            ))
                            : null}
                        </Select>
                      ):(
                        <Typography>
                        No records of system ICD &amp; descriptions
                      </Typography>
                      )
                    }
                 
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
});

System.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getSystemICD10Codes,
  addSystemICD10Code,
  deleteSystemICD10Code
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  systemICD10: state.providers && state.icd10.systemICD10
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(System));
