import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Grid, Modal
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  deletePtSchedule, getAllPhyPtSchedules, savePtSchedule
} from 'services/ptschedules/action';
import { DocViewer, PtReview } from 'views/Common/components';
import styles from './../../style';
import { Details } from './components';

import _ from 'lodash';
import {
  clearPtDoc, getDocInfo, getDocumentCategories, getDocuments, getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts, getPatientDocuments, getPatientMiscDocuments, uploadFilePDF
} from 'services/home/action';
import { loadOrders } from 'services/orders/action';
import MessageBox from 'util/messageBox';
import ScheduleAppointment from '../../../PTSchedule/components/Modal_ScheduleAppointment';
const ClinicalDocuments = props => {
  const {
    classes,
    ehrID, 
    userID,
    documentInfo,
    miscDocumentInfo,
    clearPtDoc,
    getPatientAllClinicalDocuments,
    getPatientAllMiscellaneousDocuments,
    getPatientCharts  } = props;
  const [total, setTotal] = useState(null)
  const [openScheduleAppointment, setOpenScheduleAppointment] = useState({
    showPopUp: false,
    appointment: null
  });
  const [openDeleteAppointment, setOpenDeleteAppointment] = useState({
    showPopUp: false,
    id: 0
  });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [isloading, setisloading] = useState(true);
  const [openDoc, setOpenDoc] = React.useState(false);
  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [miscDataDocViewer, setMiscDataDocViewer] = useState([]);
  const [state, setStasetIsClinicalte] = useState({ page: 0, count: 0 });
  let [isClinical, setIsClinical] = useState(false);
  let [isClinicalDocuments, setIsClinicalDocuments] = useState(false);
  const [doc,setDoc]= useState([]);

   useEffect(() => {
    props.getDocumentCategories(ehrID);
  }, []);

  useEffect(() => {
    async function fetchData(patientId,ehrID) { 
      clearPtDoc()
      getPatientAllClinicalDocuments(patientId, ehrID)
      getPatientAllMiscellaneousDocuments(patientId, ehrID)
      props.loadOrders(patientId);
      const res = await getPatientCharts(patientId, 0)
      setTotal(res.totalElements) 
    }
    if (!_.isEmpty(userID)) {

      fetchData(userID, ehrID)
    }
  }, []);

  const handleDeleteDoc = () => {
    setOpenDoc(false);  
    refreshDoc()
    setDataDocViewer([])
   };



  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  useEffect(() => {
    if (miscDocumentInfo !== undefined) {
      setMiscDataDocViewer(miscDocumentInfo);
    }
  }, [miscDocumentInfo]);

  //#region Schedule


  const handleCloseScheduleAppointment = async () => {
    setOpenScheduleAppointment({ showPopUp: false });
    await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
  };

  const handleSavePtSchedule = async ptSchedule => {
    const res = await props.savePtSchedule(ehrID, ptSchedule);
    if (res) {
      setOpenScheduleAppointment({ showPopUp: false });
      setSuccMsg({ succ: true, succMsg: 'Appointment scheduled successfully' });
    }
    await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
  };

  const handleClose = async () => {
    setSuccMsg({ succ: false });
  };

  //#endregion

  //#region Edit & Delete Appointment

  const handleOpenDeleteAppointment = appointmentId => {
    setOpenDeleteAppointment({ showPopUp: true, appointmentId: appointmentId });
  };

  const handleDeleteOK = async () => {
    if (openDeleteAppointment.appointmentId) {
      const res = await props.deletePtSchedule(
        ehrID,
        openDeleteAppointment.appointmentId
      );
      if (res) {
        setSuccMsg({ succ: true, succMsg: 'Appointment cancelled successfully' });
      }
      setOpenDeleteAppointment({ showPopUp: false });
      setOpenScheduleAppointment({ showPopUp: false });
      await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteAppointment({ showPopUp: false });
  };

  //#endregion

  const handleOpenDoc = async (patientId, resourceID, routeTo, patient) => {
    setIsClinical(isClinical);
    setIsClinicalDocuments(true);
    setOpenDoc(true);
    const doc = await props.getDocInfo(patientId,resourceID)
    setDoc(doc)
    await props.getPatientDocuments(
      ehrID,
      patientId,
      resourceID,
      routeTo,
      patient
    );
    
  };

  // const handleOpenMiscDoc = async (
  //   ehrID,
  //   patientId,
  //   resourceID,
  //   item,
  //   isClinical
  // ) => {
  //   setIsClinical(isClinical);
  //   setIsClinicalDocuments(isClinicalDocuments);
  //   setOpenDoc(true);
  //   await props.getPatientMiscDocuments(ehrID, patientId, resourceID, item);
  // };

  const handleCloseDoc = () => {
      setOpenDoc(false); 
      setDataDocViewer([]) 
  };

  const refreshDoc = () =>{
    clearPtDoc()
    getPatientAllClinicalDocuments(userID, ehrID)
    getPatientAllMiscellaneousDocuments(userID, ehrID)
    props.loadOrders(userID); 
   }

  const changeTab = (patientId, tabId) => {
    props.changeTab(patientId, tabId);
  };

  return (
    <Grid container spacing={3}>
      <Grid className={classes.colWrap} item md={7} xs={12}>
        <Details
          changeTab={changeTab} 
          handleOpenDoc={handleOpenDoc}
        />
      </Grid>
      <Grid className={classes.colWrap} item md={5} xs={12}>
        <PtReview
          userID={userID}
        // data={allPhyPtSchedules}
        // isloading={isloading}
        // handleEditAppointment={handleEditAppointment}
        // handleDeleteAppointment={handleOpenDeleteAppointment}
        // handleOpenScheduleAppointment={handleOpenScheduleAppointment}
        // handleDrpDownChange={handleDrpDownChange}
        />
      </Grid>

      {/* Start Dialogue for Delete Appointments */}
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={openDeleteAppointment.showPopUp}>
        <DialogTitle id="confirmation-dialog-title">
          PT Schedule Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and delete the whole appointment ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleDeleteOK()}>
            OK
          </Button>
          <Button color="primary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Dialogue for Delete Appointments */}

      {/* Start Create Appointments */}
      <Modal
        disableBackdropClick
        onClose={handleCloseScheduleAppointment}
        open={openScheduleAppointment.showPopUp}>
        <ScheduleAppointment
          appointment={openScheduleAppointment.appointment}
          // data={allPhyPtSchedules}
          handleDeleteAppointment={handleOpenDeleteAppointment}
          handleSavePtSchedule={handleSavePtSchedule}
          onCancel={handleCloseScheduleAppointment}
        />
      </Modal>

      <Modal onClose={handleCloseDoc} open={openDoc}>
        <DocViewer
          isClinical={isClinical}
          dataDocViewer={dataDocViewer}
          miscDataDocViewer={miscDataDocViewer}
          isClinicalDocuments={isClinicalDocuments}
          onCancel={handleCloseDoc}
          doc={doc}
          handleDeleteDoc={handleDeleteDoc}
        />
      </Modal>

      {/* End Create Appointments */}

      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />
    </Grid>
  );
};

ClinicalDocuments.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  deletePtSchedule,
  savePtSchedule,
  getDocuments,
  getPatientDocuments,
  getDocInfo,
  uploadFilePDF,
  getPatientMiscDocuments,
  getDocInfo,
  clearPtDoc,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  getDocumentCategories,
  loadOrders
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  documentsList: state.home && state.home.documentsList,
  documentInfo: state.home && state.home.documentInfo,
  miscDocumentInfo: state.home && state.home.miscDocumentInfo
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClinicalDocuments))
);
