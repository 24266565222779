import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  withStyles,
  Slide,
  Grid,
  Button,
  Box
} from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './style';
import {
  SearchOutlined,
  CancelOutlined,
  PlaylistAddCheckOutlined,
  PageviewOutlined
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getPatientsOnSearch } from 'services/patient/action';

import moment from 'moment';

const PatientSearch = props => {
  const { classes, className, data, ...rest } = props;

  const computeAge = dob => {
    var magicNumber = 1000 * 60 * 60 * 24 * 365;
    var age = Math.abs(new Date() - new Date(dob));
    age = Math.floor(age / magicNumber);
    return age;
  };

  const handlePrefillPatient = patient => {
    props.handlePrefillPatient(patient);
  };

  return (
    data &&
    data.length > 0 && (
      <div className={classes.rowStyleOverlay}>
        {data && data.length > 0
          ? data.map(patient => (
              <Grid
                onClick={() => handlePrefillPatient(patient)}
                container
                spacing={1}
                style={{ padding: 10 }}>
                <Grid item xs={3}>
                  <Typography style={{ fontSize: '13px' }}>
                    {patient.id}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography style={{ fontSize: '13px' }}>
                    {(patient.lastname || '') + ' ' + (patient.firstname || '')}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  {patient.dob && patient.dob !== null ? (
                    <Typography style={{ fontSize: '13px' }}>
                      {patient.dob
                        ? moment(patient.dob).format('MM/DD/YYYY')
                        : '--'}
                      ({computeAge(patient.dob)}yrs)
                    </Typography>
                  ) : (
                    '--'
                  )}
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'center' }}>
                  <Typography style={{ fontSize: '13px' }}>
                    {patient.gender}
                  </Typography>
                </Grid>
              </Grid>
            ))
          : ''}
      </div>
    )
  );
};

PatientSearch.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getPatientsOnSearch
};

const mapStateToProps = state => ({});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PatientSearch))
);
