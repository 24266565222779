/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import { withRouter } from 'react-router-dom';
import LabOrderEntry from './ApLo-Entry';
import PastDiag from './ApLo-PastDiag';
import {
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
  Grid
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons/';
import { MyTemplates, CPT } from './';
import { saveTempData } from 'services/soap/action';
import { connect } from 'react-redux';

const VSHx = props => {
  const {
    classes,
    typeOrder,
    patientId,
    patientInfo,
    labOrders,
    isNewLabOrder, 
  } = props;
  const [labValues, setLabValues] = useState([]);
  const [retainlabValues, setRetainLabValues] = useState({});
  const [labOrdertext, setLabOrderText] = useState('');
  const [diaOrdertext, setDiaOrderText] = useState('');

  useEffect(() => {
    if (labOrders && !isNewLabOrder) {
      setLabValues(labOrders);
    } else {
      setRetainLabValues(labOrders);
    }
  }, [labOrders]);

  const handleList = (field, value, isRemove = false) => {
    if (value) {
      const newState = { ...labValues };
      if (field === 'labOrder') {
        if (!isRemove && newState[field] && newState[field].length > 0) {
          let newData = [...newState[field]];
          value &&
            value.length > 0 &&
            value.map(item => {
              newData.push(item);
            });
          newState[field] = newData;
        } else {
          newState[field] = {};
          newState[field] = value;
        }
      } else if (field === 'labOrderDiagnosis') {
        if (!isRemove && newState[field] && newState[field].length > 0) {
          let newData = [...newState[field]];
          value &&
            value.length > 0 &&
            value.map(item => {
              newData.push(item);
            });
          newState[field] = newData;
        } else {
          newState[field] = {};
          newState[field] = value;
        }
      } else {
        newState[field] = value;
      }
      setLabValues(newState);
    }
  };

  const handleFieldChange = async (field, value) => {
    if (field === 'labOrder') {
      setLabOrderText(value);
    } else if (field === 'diagnosis') {
      setDiaOrderText(value);
    }
  };

  const handleCopy = item => {
    const newState = { ...labValues };
    if (newState.labOrderDiagnosis) {
      let labOrderDiagnosis = [...newState.labOrderDiagnosis];
      labOrderDiagnosis.push({
        code: item.code,
        description: item.description
      });
      newState.labOrderDiagnosis = labOrderDiagnosis;
    } else {
      newState.labOrderDiagnosis = [];
      newState.labOrderDiagnosis.push({
        code: item.code,
        description: item.description
      });
    }
    setLabValues(newState);
  };

  const handleLabESignPrint = async () => {
    let val;
    const newState = { ...labValues };
    if (isNewLabOrder) {
      let retainallvalues = { ...retainlabValues };
      if (labOrdertext) {
        if (newState.labOrder) {
          let labOrder = [...newState.labOrder];
          labOrder.push({ text: labOrdertext });
          newState.labOrder = labOrder;
        } else {
          newState.labOrder = [];
          newState.labOrder.push({ text: labOrdertext });
        }
      }
      if (diaOrdertext) {
        if (newState.labOrderDiagnosis) {
          let labOrderDiagnosis = [...newState.labOrderDiagnosis];
          labOrderDiagnosis.push({ description: diaOrdertext });
          newState.labOrderDiagnosis = labOrderDiagnosis;
        } else {
          newState.labOrderDiagnosis = [];
          newState.labOrderDiagnosis.push({ description: diaOrdertext });
        }
      }

      if (newState && newState.labOrderDiagnosis) {
        if (retainallvalues.labOrderDiagnosis) {
          let retainLabDiagnosis = [...retainallvalues.labOrderDiagnosis];
          Array.isArray(newState.labOrderDiagnosis) &&
            newState.labOrderDiagnosis.map(e => retainLabDiagnosis.push(e));
          retainallvalues.labOrderDiagnosis = retainLabDiagnosis;
        } else {
          retainallvalues.labOrderDiagnosis = [];
          newState.labOrderDiagnosis.map(e =>
            retainallvalues.labOrderDiagnosis.push(e)
          );
        }
      }
      if (newState && newState.labOrder) {
        if (retainallvalues.labOrder) {
          let retainLabOrder = [...retainallvalues.labOrder];
          Array.isArray(newState.labOrder) &&
            newState.labOrder.map(e => retainLabOrder.push(e));
          retainallvalues.labOrder = retainLabOrder;
        } else {
          retainallvalues.labOrder = [];
          newState.labOrder.map(e => retainallvalues.labOrder.push(e));
        }
      }


      if (typeOrder === 1) {
        await props.handleFieldChange('labOrder', retainallvalues);
        val = props.getValuesforCopy(false, '', retainallvalues, '', '');
      } else if (typeOrder == 2) {
        await props.handleFieldChange('imagingOrder', retainallvalues);
        val = props.getValuesforCopy(false, '', '', retainallvalues, '');
      } else if (typeOrder === 3) {
        await props.handleFieldChange('physicianOrder', retainallvalues);
        val = props.getValuesforCopy(false, '', '', '', retainallvalues);
      }
      props.saveTempData(val);
      await props.handleLabESignPrint(patientId, newState);
    }
    else {
      if (labOrdertext) {
        if (newState.labOrder) {
          let labOrder = [...newState.labOrder];
          labOrder.push({ text: labOrdertext });
          newState.labOrder = labOrder;
        } else {
          newState.labOrder = [];
          newState.labOrder.push({ text: labOrdertext });
        }
      }
      if (diaOrdertext) {
        if (newState.labOrderDiagnosis) {
          let labOrderDiagnosis = [...newState.labOrderDiagnosis];
          labOrderDiagnosis.push({ description: diaOrdertext });
          newState.labOrderDiagnosis = labOrderDiagnosis;
        } else {
          newState.labOrderDiagnosis = [];
          newState.labOrderDiagnosis.push({ description: diaOrdertext });
        }
      }
      if (typeOrder === 1) {
        await props.handleFieldChange('labOrder', newState);
        val = props.getValuesforCopy(false, '', newState, '', '');
      } else if (typeOrder == 2) {
        await props.handleFieldChange('imagingOrder', newState);
        val = props.getValuesforCopy(false, '', '', newState, '');
      } else if (typeOrder === 3) {
        await props.handleFieldChange('physicianOrder', newState);
        val = props.getValuesforCopy(false, '', '', '', newState);
      }
      props.saveTempData(val);
      await props.handleLabESignPrint(patientId, newState);
    }

    props.onCancel();
  };

  const handleLabESignClose = async () => {
    let val;
    if (isNewLabOrder) {
      let retainallvalues = { ...retainlabValues };
      const newState = { ...labValues };
      if (labOrdertext) {
        if (newState.labOrder) {
          let labOrder = [...newState.labOrder];
          labOrder.push({ text: labOrdertext });
          newState.labOrder = labOrder;
        } else {
          newState.labOrder = [];
          newState.labOrder.push({ text: labOrdertext });
        }
      }
      if (diaOrdertext) {
        if (newState.labOrderDiagnosis) {
          let labOrderDiagnosis = [...newState.labOrderDiagnosis];
          labOrderDiagnosis.push({ description: diaOrdertext });
          newState.labOrderDiagnosis = labOrderDiagnosis;
        } else {
          newState.labOrderDiagnosis = [];
          newState.labOrderDiagnosis.push({ description: diaOrdertext });
        }
      }
      if (newState && newState.labOrderDiagnosis) {
        if (retainallvalues.labOrderDiagnosis) {
          let retainLabDiagnosis = [...retainallvalues.labOrderDiagnosis];
          Array.isArray(newState.labOrderDiagnosis) &&
            newState.labOrderDiagnosis.map(e => retainLabDiagnosis.push(e));
          retainallvalues.labOrderDiagnosis = retainLabDiagnosis;
        } else {
          retainallvalues.labOrderDiagnosis = [];
          newState.labOrderDiagnosis.map(e =>
            retainallvalues.labOrderDiagnosis.push(e)
          );
        }
      }
      if (newState && newState.labOrder) {
        if (retainallvalues.labOrder) {
          let retainLabOrder = [...retainallvalues.labOrder];
          Array.isArray(newState.labOrder) &&
            newState.labOrder.map(e => retainLabOrder.push(e));
          retainallvalues.labOrder = retainLabOrder;
        } else {
          retainallvalues.labOrder = [];
          newState.labOrder.map(e => retainallvalues.labOrder.push(e));
        }
      }

      if (typeOrder === 1) {
        await props.handleFieldChange('labOrder', retainallvalues);
        val = props.getValuesforCopy(false, '', retainallvalues, '', '', true);
      } else if (typeOrder == 2) {
        await props.handleFieldChange('imagingOrder', retainallvalues);
        val = props.getValuesforCopy(false, '', '', retainallvalues, '', true);
      } else if (typeOrder === 3) {
        await props.handleFieldChange('physicianOrder', retainallvalues);
        val = props.getValuesforCopy(false, '', '', '', retainallvalues, true);
      }
      props.saveTempData(val);
    } else {
      const newState = { ...labValues };
      if (labOrdertext) {
        if (newState.labOrder) {
          let labOrder = [...newState.labOrder];
          labOrder.push({ text: labOrdertext });
          newState.labOrder = labOrder;
        } else {
          newState.labOrder = [];
          newState.labOrder.push({ text: labOrdertext });
        }
      }
      if (diaOrdertext) {
        if (newState.labOrderDiagnosis) {
          let labOrderDiagnosis = [...newState.labOrderDiagnosis];
          labOrderDiagnosis.push({ description: diaOrdertext });
          newState.labOrderDiagnosis = labOrderDiagnosis;
        } else {
          newState.labOrderDiagnosis = [];
          newState.labOrderDiagnosis.push({ description: diaOrdertext });
        }
      }
      if (typeOrder === 1) {
        await props.handleFieldChange('labOrder', newState);
        val = props.getValuesforCopy(false, '', newState, '', '');
      } else if (typeOrder == 2) {
        await props.handleFieldChange('imagingOrder', newState);
        val = props.getValuesforCopy(false, '', '', newState, '');
      } else if (typeOrder === 3) {
        await props.handleFieldChange('physicianOrder', newState);
        val = props.getValuesforCopy(false, '', '', '', newState);
      }
      props.saveTempData(val);
    }

    props.onCancel();
  };

  //#region Template
  const [opentemplates, setOpenTemplates] = useState(false);

  const handleOpenTemplates = type => {
    setOpenTemplates({ open: true, type: type });
  };

  const handleCloseTemplates = () => {
    setOpenTemplates(false);
  };

  const renderTemplates = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseTemplates}
        open={opentemplates.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '90%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">
              {opentemplates.type === 1
                ? 'Subjective'
                : opentemplates.type === 2
                ? 'Objective'
                : 'Assessment Plan '}
              Templates
            </Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseTemplates}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <MyTemplates
            // diagnosis={diagnosis}
            fromLabOrder
            handleList={handleList}
            //  labOrder={labOrder}
            onCancel={handleCloseTemplates}
            type={opentemplates.type}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  //#region CPT,ICD
  const [openICD, setOpenICD] = useState(false);

  let [isCPT, setIsCPT] = useState(false);

  const handleOpenICD = isCPT => {
    setIsCPT(false);
    setOpenICD(isCPT);
  };
  const handleCloseCPTICD = () => {
    setOpenICD(false);
  };

  const renderICD = () => {
    return (
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseCPTICD}
        open={openICD}>
        <Box
          className={classes.modalWrap}
          style={{ width: '90%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">Diagnosis Database</Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={handleCloseCPTICD}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <CPT
            diagnosis={labValues.diagnosis}
            fromLabOrder
            handleFieldChange={props.handleFieldChange}
            handleList={handleList}
            isCPT={isCPT}
            onCancel={handleCloseCPTICD}
          />
        </Box>
      </Modal>
    );
  };

  //#endregion

  return (
    <Grid container spacing={3}>
      <Grid item md={8} xs={12}>
        <LabOrderEntry
          copydiagnosis={diaOrdertext}
          handleFieldChange={handleFieldChange}
          handleList={handleList}
          handleOpenICD={handleOpenICD}
          handleOpenTemplates={handleOpenTemplates}
          labOrders={labValues}
          patientId={patientId}
          patientInfo={patientInfo}
          typeOrder={typeOrder}
        />
      </Grid>

      <Grid item md={4} xs={12}>
        <PastDiag handleCopy={handleCopy} patientInfo={patientInfo} />
      </Grid>
      <Grid className={classes.modalFooter} item xs={12}>
        <Button onClick={props.onCancel} size="small" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => handleLabESignClose()}
          disabled={
            Object.keys(labValues).length === 0 &&
            labOrdertext === '' &&
            diaOrdertext === ''
          }
          size="small"
          style={{ margin: '0 10px' }}
          variant="contained">
          E-Sign &amp; Close
        </Button>
        <Button
          color="secondary"
          onClick={() => handleLabESignPrint()}
          disabled={
            Object.keys(labValues).length === 0 &&
            labOrdertext === '' &&
            diaOrdertext === ''
          }
          size="small"
          variant="contained">
          E-Sign &amp; Print
        </Button>
        {opentemplates && renderTemplates()}
        {openICD && renderICD()}
      </Grid>
    </Grid>
  );
};

VSHx.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  saveTempData
};

const mapStateToProps = () => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VSHx))
);
