import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import { Grid, CircularProgress } from '@material-ui/core';
import styles from './style';
import { PatientList } from './components';
import { connect } from 'react-redux';
import {
  getAppointmentsByPatientId
 
} from 'services/ptschedules/action';
import moment from 'moment';
const Default = props => {
  const { classes, ehrID, userID, allAppointments } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData =()=>{
    props.getAppointmentsByPatientId(ehrID, userID);
  }

  useEffect(() => {
    if (props.allAppointments !== undefined) {
      let datatopush = [];
      allAppointments.forEach(function(item) {
        datatopush.push({
          id:item.id,
          appointmentDate: (item.appointmentDate
            ? moment(item.appointmentDate).format('MM/DD/YYYY')
            : ''),
          appointmentTime: moment(item.timeFrom)
            .format('HH:mm')+' - '+ moment(item.timeTo)
            .format('HH:mm'),
          reasonForAppointment: item.reasonForAppointment,
          routePhysicianName: item.routePhysicianName,
          item:item
        });
      });
      setData(datatopush);
      setLoading(false);
    }
  }, [props.allAppointments]);



  return (
    <div className={classes.root}>
      <Grid className={classes.contentWrap} container xs={12}>
        {loading ? (
          <div
            className={classes.progressWrapper}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              display: 'flex',
              bottom: 0,
              top: 0,
              alignItems: 'flex-start',
              paddingTop: 120,
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0,.2)'
            }}
          >
            <CircularProgress style={{ height: 30, width: 30 }} />
          </div>
        ) : (
          <PatientList data={data} fetchData={fetchData}  />
        )}
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  getAppointmentsByPatientId,

};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allAppointments:
    state.ptschedules &&
    state.ptschedules.allAppointments &&
    state.ptschedules.allAppointments.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Default))
);
