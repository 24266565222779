import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import {
  TextField,
  Box,
  Button,
  CircularProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import validate from 'validate.js';
import schema from './schema';
import { sendInvite } from 'services/ptmessages/action';
const SendActivation = props => {
  const {
    classes,
    patient,
    ehrID  } = props;

  const [values, setValues] = useState({
    // id: 0,
    lastname: '',
    firstname: '',
    username: '',
    emailaddress: '',
    confirmEmailaddress: '',
    roles: 'ROLE_USER ROLE_PATIENT',
    isValid: false,
    touched: {},
    errors: {}
  });

  const [poploading, setPopLoading] = useState(false);

  useEffect(() => {
    if (patient !== undefined) {
      setValues({
        ...values,
        // id: patient.id,
        lastname: patient.lastname,
        firstname: patient.firstname
      });
    }
  }, [patient]);

  const handleSendActivation = () => {
    if (values.isValid) {
      setPopLoading(true);
      props.handleSendActivation(ehrID, patient.id, values);
    }
  };

  const hasError = field =>
    values &&
    values.touched &&
    values.errors &&
    values.touched[field] &&
    values.errors[field]
      ? true
      : false;

  const handleChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    setValues(newState);
  };

  return (
    <React.Fragment>
      <Box className={classes.modalBody}>
        <TextField
          placeholder="Last Name"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event => handleChange('lastname', event.target.value)}
          value={values.lastname}
          error={hasError('lastname')}
          helperText={hasError('lastname') ? values.errors.lastname[0] : null}
        />
        <TextField
          placeholder="First Name"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event => handleChange('firstname', event.target.value)}
          value={values.firstname}
          error={hasError('firstname')}
          helperText={hasError('firstname') ? values.errors.firstname[0] : null}
        />
        <TextField
          placeholder="Username"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event => handleChange('username', event.target.value)}
          value={values.username}
          error={hasError('username')}
          helperText={hasError('username') ? values.errors.username[0] : null}
        />
        <TextField
          placeholder="Email address"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event => handleChange('emailaddress', event.target.value)}
          value={values.emailaddress}
          error={hasError('emailaddress')}
          helperText={
            hasError('emailaddress') ? values.errors.emailaddress[0] : null
          }
        />
        <TextField
          placeholder="Confirm Email Address"
          variant="outlined"
          style={{ width: '100%', marginBottom: 10 }}
          onChange={event =>
            handleChange('confirmEmailaddress', event.target.value)
          }
          value={values.confirmEmailaddress}
          error={hasError('confirmEmailaddress')}
          helperText={
            hasError('confirmEmailaddress')
              ? values.errors.confirmEmailaddress[0]
              : null
          }
        />
      </Box>

      <Box className={classes.modalFooter}>
        <Button
          onClick={props.onCancel}
          style={{ marginRight: 10 }}
          variant="outlined"
          size="small">
          Cancel
        </Button>
        {poploading ? (
          <CircularProgress className={classes.progress} size={20} />
        ) : (
          <Button
            // onClick={props.onCancel}
            variant="contained"
            color="primary"
            size="small"
            style={{ borderRadius: 4 }}
            disabled={!values.isValid}
            onClick={() => handleSendActivation(values)}>
            Send Account Invite
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
};

SendActivation.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  sendInvite
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SendActivation))
);
