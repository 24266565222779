import React, { useState, useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import styles from './style';

import { Calendar, ScheduleHistory, ScheduleAppointment } from './components';
import { Calendars, Reminders, PatientSearch } from '../Common/components';
import MessageBox from 'util/messageBox';
import { connect } from 'react-redux';
import {
  getAllPhyPtSchedules,
  getAppointmentsByPatientId,
  getAllPhyPtSchedulesWeekly,
  deletePtSchedule,
  savePtSchedule
} from 'services/ptschedules/action';
import moment from 'moment';

import PerfectScrollbar from 'react-perfect-scrollbar';

const Default = props => {
  const scroll_ps = useRef();
  const { classes, ehrID, allAppointments, allPhyPtSchedules } = props;
  const [loading, setLoading] = useState(true);
  const [openschedulehistory, setOpenScheduleHistory] = useState({
    showPopUp: false,
    name: ''
  });
  const [schedulehistorydata, setScheduleHistoryData] = useState(false);
  const [selecteddate, setSelecteddate] = useState(
    moment().format('MM/DD/YYYY')
  );
  const [openScheduleAppointment, setOpenScheduleAppointment] = useState({
    showPopUp: false,
    appointment: null
  });
  const [openDeleteAppointment, setOpenDeleteAppointment] = useState({
    showPopUp: false,
    id: 0
  });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [isWeekly, setIsWeekly] = useState(false);
  const childRef = useRef();
  const [isLocalStorage, setIsLocalStorage] = useState(false);
  const [filvals, setFilVals] = useState({
    location: '',
    provider: ''
  });

  //#region Schedule History

  useEffect(() => {
    setIsLocalStorage(true);
    props.getAllPhyPtSchedules(
      ehrID,
      selecteddate ? selecteddate : moment().format('MM/DD/YYYY'),
      localStorage.getItem('filterPTScheduleLocation')
        ? localStorage.getItem('filterPTScheduleLocation')
        : '',
      localStorage.getItem('filterPTScheduleProvider')
        ? localStorage.getItem('filterPTScheduleProvider')
        : ''
    );
  }, []);

  useEffect(() => {
    if (allAppointments !== undefined) {
      setScheduleHistoryData(allAppointments);
      setLoading(false);
    }
  }, [allAppointments]);

  const handleCloseScheduleHistory = () => {
    setOpenScheduleHistory({ showPopUp: false });
  };

  const handleAppointmentHistory = async (patientId, patientName) => {
    if (patientId) {
      await props.getAppointmentsByPatientId(ehrID, patientId);
    }
    setOpenScheduleHistory({ showPopUp: true, name: patientName });
  };

  //#endregion

  //#region Schedule Appointment

  const handleOpenScheduleAppointment = () => {
    setOpenScheduleAppointment({ showPopUp: true });
  };

  const handleCloseScheduleAppointment = async () => {
    setOpenScheduleAppointment({ showPopUp: false });
    await props.getAllPhyPtSchedules(
      ehrID,
      selecteddate ? selecteddate : moment().format('MM/DD/YYYY'),
      filvals.location,
      filvals.provider
    );
  };

  const handleSavePtSchedule = async ptSchedule => {
    const res = await props.savePtSchedule(ehrID, ptSchedule);
    if (res) {
      setOpenScheduleAppointment({ showPopUp: false });
      setSuccMsg({ succ: true, succMsg: 'Appointment scheduled successfully' });
    }
    await props.getAllPhyPtSchedules(
      ehrID,
      moment().format('MM/DD/YYYY'),
      localStorage.getItem('filterPTScheduleLocation'),
      localStorage.getItem('filterPTScheduleProvider')
    );
  };

  const handleClose = async () => {
    setSuccMsg({ succ: false });
  };

  //#endregion

  ////#region Date Changes and Daily/Weekly

  const handleToday = isWeekly => {
    setSelecteddate(moment().format('MM/DD/YYYY'));
    if (isWeekly) {
      let startDate = moment()
        .startOf('week')
        .format('MM/DD/YYYY');
      let endDate = moment()
        .endOf('week')
        .format('MM/DD/YYYY');
      props.getAllPhyPtSchedulesWeekly(ehrID, startDate, endDate);
    } else {
      props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
    }
  };

  const handleNextPreviousDaily = date => {
    setSelecteddate(date);
    props.getAllPhyPtSchedules(ehrID, date);
  };

  const handleNextPreviousWeekly = (fromDate, toDate) => {
    setSelecteddate(fromDate);
    props.getAllPhyPtSchedulesWeekly(ehrID, fromDate, toDate);
  };

  const handleDateChangeptSchedule = (date, isWeekly = false) => {
    setSelecteddate(moment(date).format('MM/DD/YYYY'));
    setLoading(true);
    if (isWeekly) {
      let startDate = moment(date)
        .startOf('week')
        .format('MM/DD/YYYY');
      let endDate = moment(date)
        .endOf('week')
        .format('MM/DD/YYYY');
      props.getAllPhyPtSchedulesWeekly(ehrID, startDate, endDate);
    } else {
      props.getAllPhyPtSchedules(ehrID, date);
    }
    setLoading(false);
  };

  const handleDrpDownChangeptScheduleDaily = (location, provider, date) => {
    setFilVals({
      location: location,
      provider: provider
    });
    props.getAllPhyPtSchedules(ehrID, date, location, provider);
  };

  const handleDrpDownChangeptScheduleWeekly = (
    location,
    provider,
    fromdate,
    todate
  ) => {
    setFilVals({
      location: location,
      provider: provider
    });
    props.getAllPhyPtSchedulesWeekly(
      ehrID,
      fromdate,
      todate,
      location,
      provider
    );
  };

  //#endregion

  //#region Edit & Delete Appointment
  const handleEditAppointment = item => {
    setOpenScheduleAppointment({ showPopUp: true, appointment: item });
  };

  const handleOpenDeleteAppointment = appointmentId => {
    setOpenDeleteAppointment({ showPopUp: true, appointmentId: appointmentId });
  };

  const handleDeleteOK = async () => {
    if (openDeleteAppointment.appointmentId) {
      const res = await props.deletePtSchedule(
        ehrID,
        openDeleteAppointment.appointmentId
      );
      if (res) {
        setSuccMsg({ succ: true, succMsg: 'Appointment cancelled successfully' });
      }
      setOpenDeleteAppointment({ showPopUp: false });
      setOpenScheduleAppointment({ showPopUp: false });
      await props.getAllPhyPtSchedules(
        ehrID,
        selecteddate ? selecteddate : moment().format('MM/DD/YYYY')
      );
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteAppointment({ showPopUp: false });
  };

  //#endregion

  const handleWeekly = isWeekly => {
    setIsWeekly(isWeekly);
  };

  return (
    <PerfectScrollbar containerRef={el => (scroll_ps.current = el)}>
      <div className={classes.root}>
        <Grid container className={classes.contentWrap}>
          <Grid className={classes.leftWrap} item xs={12} md={3}>
            <Calendars
              isptSchedules={true}
              handleDateChangeptSchedule={handleDateChangeptSchedule}
              seldate={selecteddate}
              isWeekly={isWeekly}
            />
            <Reminders marginBottom={'0'} />
          </Grid>

          <Grid className={classes.rightWrap} item xs={12} md={9}>
            <PatientSearch
              plane={true}
              isptSchedule={true}
              handleAppointmentHistory={handleAppointmentHistory}
              handleOpenScheduleAppointment={handleOpenScheduleAppointment}
            />
            <Calendar
              scroll_ps={scroll_ps}
              handleDrpDownChangeptScheduleDaily={
                handleDrpDownChangeptScheduleDaily
              }
              handleDrpDownChangeptScheduleWeekly={
                handleDrpDownChangeptScheduleWeekly
              }
              handleToday={handleToday}
              handleNextPreviousDaily={handleNextPreviousDaily}
              handleNextPreviousWeekly={handleNextPreviousWeekly}
              handleEditAppointment={handleEditAppointment}
              handleDeleteAppointment={handleOpenDeleteAppointment}
              handleOpenScheduleAppointment={handleOpenScheduleAppointment}
              seldate={selecteddate}
              handleWeekly={handleWeekly}
              isLocalStorage={isLocalStorage}
            />
          </Grid>
        </Grid>

        {/* Start Modal for View Appointments */}

        <Modal
          disableBackdropClick
          open={openschedulehistory.showPopUp}
          onClose={handleCloseScheduleHistory}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <ScheduleHistory
            ref={childRef}
            onCancel={handleCloseScheduleHistory}
            patientName={openschedulehistory.name}
            data={schedulehistorydata}
          />
        </Modal>

        {/* End Modal for View Appointments */}

        {/* Start Dialogue for Delete Appointments */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={openDeleteAppointment.showPopUp}>
          <DialogTitle id="confirmation-dialog-title">
            PT Schedule Delete Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to confirm and delete the whole appointment ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDeleteOK()} color="primary">
              OK
            </Button>
            <Button onClick={handleDeleteCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        {/* End Dialogue for Delete Appointments */}

        {/* Start Create Appointments */}
        <Modal
          disableBackdropClick
          open={openScheduleAppointment.showPopUp}
          onClose={handleCloseScheduleAppointment}>
          <ScheduleAppointment
            onCancel={handleCloseScheduleAppointment}
            data={allPhyPtSchedules}
            appointment={openScheduleAppointment.appointment}
            handleSavePtSchedule={handleSavePtSchedule}
            handleDeleteAppointment={handleOpenDeleteAppointment}
          />
        </Modal>

        {/* End Create Appointments */}

        <MessageBox
          open={succmsg.succ}
          variant="success"
          onClose={handleClose}
          message={succmsg.succMsg}
        />
      </div>
    </PerfectScrollbar>
  );
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  getAppointmentsByPatientId,
  getAllPhyPtSchedulesWeekly,
  deletePtSchedule,
  savePtSchedule
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allAppointments:
    state.ptschedules &&
    state.ptschedules.allAppointments &&
    state.ptschedules.allAppointments.result,
  allPhyPtSchedules:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedules &&
    state.ptschedules.allPhyPtSchedules.result,
  allPhyPtSchedulesofDate:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedulesofDate &&
    state.ptschedules.allPhyPtSchedulesofDate.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Default))
);
