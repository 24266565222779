import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Sidebar, Topbar, Footer } from './components';
import MessageBox from "util/messageBox";
import { connect } from "react-redux";

import { removeError } from "services/error/action";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%',
    backgroundColor: '#f6f6f6'
  }
}));

const Main = props => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: false
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleCloseMsg = () => {
    props.removeError();
  };

  const showMsgBox = () => {
    return props.error.message && props.error.message !== "";
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? 'persistent' : 'temporary'}
      />

      <MessageBox
        open={showMsgBox()}
        variant="error"
        onClose={handleCloseMsg}
        message={props.error.message}
      />
      <main className={classes.content}>
        {/* <PerfectScrollbar> */}
          {children}
          <Footer />
        {/* </PerfectScrollbar> */}
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};


const mapDispatchToProps = {
  removeError
};
const mapStateToProps = state => ({
  error: state.error
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);

