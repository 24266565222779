import axios from "axios";


const httpApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    //timeout: 1000,
    headers: { "Content-Type": "application/json; charset=UTF-8" },
    withCredentials: true
});

// request interceptor
httpApi.interceptors.request.use(
    async function (config) {
        // const authToken = await AsyncStorage.getItem("authToken");
        // if (authToken) {
        //     config.headers.Authorization = `Bearer ${authToken}`;
        // }
        return config;
    },
    function (error) {
        //generalError(error);
        //console.log(error);
        return Promise.reject(error);
    }
);

// response interceptor
httpApi.interceptors.response.use(
    function (response) {
        // Do something with response data
        //console.log(response);
        return response;
    },
    function (error) {
        // Do something with response error
        //console.log(error);
        //generalError(error);
        return Promise.reject(error);
    }
);

export default httpApi;
