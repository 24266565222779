export const sandboxURLs = {
  AUTH_API_URL: "https://api-m.sandbox.paypal.com/v1/oauth2/token",
  ORDERS_API_URL: "https://api-m.sandbox.paypal.com/v2/checkout/orders",
  CLIENT_TOKEN_URL: "https://api-m.sandbox.paypal.com/v1/identity/generate-token",
  PAYMENT_AMOUNT: "1.00"
};

export const liveURLs = {
  AUTH_API_URL: "https://api-m.paypal.com/v1/oauth2/token",
  ORDERS_API_URL: "https://api-m.paypal.com/v2/checkout/orders",
  CLIENT_TOKEN_URL: "https://api-m.paypal.com/v1/identity/generate-token",
  PAYMENT_AMOUNT: "299.00"
};
