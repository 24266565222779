import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './style';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  getReminders,
  addReminders,
  deleteReminder
} from 'services/reminders/action';
import moment from 'moment';

const Create = props => {
  const { classes, className, ehrID, userID, reminders, isptMessages } = props;
  const initialUserState = {
    id: 0,
    reminderDesc: '',
    reminderDescforAdd: '',
    providerId: 0
  };
  const [values, setValues] = useState(initialUserState);
  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    deleteReminderId: ''
  });
  const [poploading, setPopLoading] = useState(false);
  const [isedit, setIsEdit] = useState(false);

  useEffect(() => {
    props.getReminders(ehrID, userID);
  }, []);

  const handleFieldChange = (field, value) => {
    if (field == 'reminderDescforAdd') {
      setValues({ ...values, [field]: value, reminderDesc: value });
    } else {
      setValues({ ...values, [field]: value });
    }
  };

  const handleSubmit = async () => {
    const newVals = { ...values, providerId: userID };
    if (newVals.reminderDesc !== '') {
      props.addReminders(ehrID, userID, newVals).then(status => {
        if (status) {
          setValues({
            id: 0,
            reminderDesc: '',
            reminderDescforAdd: '',
            providerId: 0
          });
          props.getReminders(ehrID, userID);
        }
      });
    }
  };

  const editReminder = (data, index) => {
    setIsEdit(true);
    if (data) {
      setValues({
        id: data[0],
        reminderDesc: data[1],
        providerId: data[4],
        editIndex: index
      });
    }
  };

  const handleDelete = reminderID => {
    setHandleDeletePopup({
      showDeletePopup: true,
      deleteReminderId: reminderID[0]
    });
  };

  const handleDeleteReminder = async deleteReminderId => {
    if (deleteReminderId > 0) {
      setPopLoading(true);
      const del = await props.deleteReminder(ehrID, userID, deleteReminderId);
      if (del) {
        setPopLoading(false);
        setHandleDeletePopup({ showDeletePopup: false });
      }
    }

    props.getReminders(ehrID, userID);
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup({ showDeletePopup: false });
  };

  const clearValues = () => {
    setValues(initialUserState);
  };

  return (
    <div className={classes.dateWrap}>
      {!props.noTitle ? (
        <div className={classes.dateHead}>
          <Typography>Reminders</Typography>
        </div>
      ) : null}
      <div className={classes.dateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 500 }}>
            <Table className={classes.reminderTable}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleFieldChange(
                          'reminderDescforAdd',
                          event.target.value
                        )
                      }
                      placeholder="Add New Reminder"
                      value={values.reminderDescforAdd}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      color="primary"
                      onClick={() => handleSubmit()}
                      size="small">
                      <SaveOutlined />
                    </IconButton>
                    <IconButton onClick={() => clearValues()} size="small">
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reminders && reminders.length > 0 ? (
                  reminders.map((reminder, idx) => (
                    <TableRow key={reminder[0]}>
                      {isedit && idx === values.editIndex ? (
                        <TableCell colSpan={2}>
                          <TextField
                            className={classes.textBox}
                            id="outlined-basic"
                            InputProps={{
                              classes: {
                                notchedOutline: classes.notchedOutline
                              }
                            }}
                            onChange={event =>
                              handleFieldChange(
                                'reminderDesc',
                                event.target.value
                              )
                            }
                            placeholder="Add New Reminder"
                            value={values.reminderDesc}
                            variant="outlined"
                          />
                        </TableCell>
                      ) : (
                        <React.Fragment>
                          <TableCell align="center">
                            {moment(reminder[5]).format('MM/DD/YYYY')}
                          </TableCell>
                          <TableCell>{reminder[1]}</TableCell>
                        </React.Fragment>
                      )}

                      <TableCell align="center" width={72}>
                        {isedit && idx === values.editIndex ? (
                          <React.Fragment>
                            <IconButton
                              color="primary"
                              onClick={() => handleSubmit()}
                              size="small">
                              <SaveOutlined />
                            </IconButton>
                            <IconButton
                              onClick={() => clearValues()}
                              size="small">
                              <ClearOutlined style={{ color: '#DE5555' }} />
                            </IconButton>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <IconButton
                              iconStyle={{ height: 10, width: 10 }}
                              onClick={() => editReminder(reminder, idx)}
                              size="small">
                              <Edit color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDelete(reminder)}
                              size="small">
                              <DeleteOutline style={{ color: '#DE5555' }} />
                            </IconButton>
                          </React.Fragment>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow className={classes.tableRow}>
                    <TableCell
                      className={classes.tableCell}
                      colSpan={10}
                      style={{ textAlign: 'center' }}>
                      No reminders available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeletepopup.showDeletePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Reminder Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the reminder?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() =>
                handleDeleteReminder(handledeletepopup.deleteReminderId)
              }>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
Create.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  getReminders,
  addReminders,
  deleteReminder
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  reminders:
    state.reminders &&
    state.reminders.loadreminders &&
    state.reminders.loadreminders.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
