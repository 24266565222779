/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './style';
import MessageBox from 'util/messageBox';
import { Home, Soap, Ptinfo, Ptdoc, Search } from './components';
import expiryDate from '../../assets/images/date_expiry.svg';
import { updateLicenseStatus } from 'services/user/action';
import {
  HomeOutlined,
  DescriptionOutlined,
  InfoOutlined,
  AccountBoxOutlined,
  SearchOutlined
} from '@material-ui/icons';
import {
  resetValue, clearSoap,
  getPatientInfo,
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
  getPastSoapNoteById
} from 'services/soap/action';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PaymentModal from 'views/Common/components/PaymentModal';
import moment from 'moment';
import { LicenseType } from 'util/enum';
import "../../../src/css/bootstrap.min.css"

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 4,
    marginBottom: -10,
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#e8e8e8',
      borderRadius: 2
    }
  },
  scroller: {
    overflow: 'visible!important'
  },
  root: {
    overflow: 'visible!important'
  }
})(props => <Tabs
  {...props}
  TabIndicatorProps={{ children: <div /> }}
/>);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#606060',
    fontWeight: 600,
    fontSize: 16,
    marginRight: 0,
    background: '#E3E3E3',
    borderRadius: '5px 5px 0 0',
    border: '1px solid #C8C8C8',
    minHeight: 50,
    minWidth: 150,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto'
    },
    letterSpacing: 0,
    '&:hover': {
      backgroundColor: '#ececec'
    },
    '&:focus': {
      opacity: 1,
      backgroundColor: '#f9f9f9'
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      border: 'transparent',
      boxShadow: '-1px -2px 2px rgba(0, 0, 0, 0.04)'
    },
    '& span': {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0!important',
        transform: 'scale(.8)'
      }
    }
  }
}))(props => <Tab {...props} />);

const Default = props => {
  const { classes, className, patientInfo, clearSoap, getPatientInfo,
    getPatientActiveMedications,
    getPatientInactiveDiagnosis,
    getPatientInactiveMedication,
    getPatientMedicalHistory,
    getPatientPastDiagnosis,
    getPatientRemarks,
    getPastSoapNoteById, ehrID,
    user,
    updateLicenseStatus,
    userID,
    ...rest } = props;

  function TabContainer(props) {
    return (
      <Typography
        className={classes.tabWrap}
        component="div"
      >
        {props.children}
      </Typography>
    );
  }

  TabContainer.propTypes = {
    children: PropTypes.node.isRequired
  };

  const [value, setValue] = useState(0);
  const [patientId, setPatientId] = useState(0);
  const [expired, setExpired] = useState(false);
  const [users, SetUsers] = useState();
  const [editfromChartReview, setEditfromChartReview] = useState({});


  useEffect(() => {
    SetUsers(user);
  }, []);

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.patientId
    ) {
      setPatientId(props.location.state.patientId);
      fetchPatientInfo(props.location.state.patientId);
      setValue(1);
    } else if (
      props.location &&
      props.location.state &&
      props.location.state.soap
    ) {
      setPatientId(props.location.state.soap.patient.id);
      fetchPatientInfo(props.location.state.soap.patient.id);
      setEditfromChartReview({
        soap: props.location.state.soap,
        pastSoapNoteDetails: props.location.state.pastSoapNoteDetails,
        isEdit: props.location.state.isAction === 'Edit' ? true : false,
        isCopy: props.location.state.isAction === 'Copy' ? true : false,
        isPast: true
      });
      setValue(1);
      delete props.location.state.soap;
      props.history.replace({ ...props.history.location, state: props.history.location.state })
    }
    else if (
      props.location &&
      props.location.state &&
      props.location.state.value === 0
    ) {
      setValue(0);
    }
  }, [props.location && props.location.state]);



  //#region error
  const [errmsg, setErrMsg] = useState({ err: false, errMsg: '' });

  const showErrorMsg = msg => {
    setErrMsg({ err: true, errMsg: msg });
  };

  const renderError = () => {
    return (
      <MessageBox
        message={errmsg.errMsg}
        onClose={handleClose}
        open
        variant="error"
      />
    );
  };

  const handleClose = () => {
    setErrMsg({ err: false });
  };

  //#endregion



  function handleChange(event, newValue) {
    if (
      newValue === 0 ||
      newValue === 1 ||
      newValue === 2 ||
      newValue === 3 ||
      newValue === 4
    ) {
      clearLocationandEditChartView();
      if (newValue === 1) {
        fetchPatientInfo(patientId);
        props.resetValue();

      }
    }
    if (newValue === 1 && patientId === 0) {
      showErrorMsg('Please select a patient to continue');
      return;
    }
    setValue(newValue);
  }

  const changeTab = (patientId, tabId) => {
    props.resetValue();
    setPatientId(patientId);
    fetchPatientInfo(patientId);
    setValue(tabId);
  };

  const fetchPatientInfo = async patientId => {
    if (patientId) {
      getPatientInfo(patientId);
      getPatientActiveMedications(patientId)
      getPatientInactiveDiagnosis(patientId)
      getPatientInactiveMedication(patientId)
      getPatientMedicalHistory(patientId)
      getPatientPastDiagnosis(patientId)
      getPatientRemarks(patientId)
      getPastSoapNoteById(patientId)
      setEditfromChartReview(null);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientInfo(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => replaceHistory);
    return () => {
      window.removeEventListener('beforeunload', replaceHistory);
    };
  }, []);

  const location = useLocation();
  const history = useHistory();
  let currentdate = moment().format("YYYY-MM-DD");
  let licenseExpiryDate = moment(user?.expirydate).format("YYYY-MM-DD");
  const replaceHistory = useCallback(() => {
    history.replace({ ...location, state: undefined });
  }, [history]);

  useEffect(() => {
    licenseExpiryDate = moment(user?.expirydate).format("YYYY-MM-DD");
  }, [user?.expirydate]);

  useEffect(() => {
    if (moment(currentdate).isAfter(moment(licenseExpiryDate))) {
      setExpired(true);
      window.$(`#paymentModal_expired`).modal("show");
      updateLicenseStatus(user, userID, ehrID);
    }
  }, []);

  const clearLocationandEditChartView = async () => {
    setEditfromChartReview({});
    replaceHistory();
  };

  const showModal = () => {
    setExpired(false);
    window.$(`#paymentModal_payment`).modal("show");
  };

  return (
    <div className={classes.root}>

      <PaymentModal expired={expired} ehrID={ehrID} userID={userID} />
      {/* <PaymentModal id={"_payment"} /> */}
      {/* <PaymentModal id={"_successful"} /> */}

      {/* <Dialog   open={errmsg.err}>
      <DialogTitle>Set backup account</DialogTitle> 
    </Dialog> */}
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
        >
          <StyledTabs
            className={classes.tabs}
            onChange={handleChange}
            scrollButtons="auto"
            value={value}
            variant="scrollable"
          >
            <StyledTab
              icon={<HomeOutlined />}
              label="Home"
            />
            <StyledTab
              icon={<DescriptionOutlined />}
              label="SOAP"
            />
            <StyledTab
              icon={<InfoOutlined />}
              label="PT Info"
            />
            <StyledTab
              icon={<AccountBoxOutlined />}
              label="PT Doc"
            />
            <StyledTab
              icon={<SearchOutlined />}
              label="Search"
            />
            {
              user.licensetype && moment(licenseExpiryDate).diff(moment(currentdate), 'days') <= 90 && moment(licenseExpiryDate).diff(moment(currentdate), 'days') >= 0 ?
                <div id="payment_Upgrade" class="container d-flex justify-content-end p-0 mb-1">
                  <div class="validity_wrapper">
                    <img src={expiryDate} alt="" class="validity_calendar" />
                    {
                      user.licensetype === LicenseType.annuallicense ?
                        moment(licenseExpiryDate).diff(moment(currentdate), 'days') === 1 ?
                          <h6>Annual License <span>{moment(licenseExpiryDate).diff(moment(currentdate), 'days')} day <br />remaining</span></h6> :
                          moment(licenseExpiryDate).diff(moment(currentdate), 'days') === 0 ?
                            <h6>Annual License <span>expires Today</span></h6> :
                            <h6>Annual License <span>{moment(licenseExpiryDate).diff(moment(currentdate), 'days')} days <br />remaining</span></h6>
                        :
                        moment(licenseExpiryDate).diff(moment(currentdate), 'days') === 1 ?
                          <h6>Free trial <span>{moment(licenseExpiryDate).diff(moment(currentdate), 'days')} day <br />remaining</span></h6> :
                          moment(licenseExpiryDate).diff(moment(currentdate), 'days') === 0 ?
                            <h6>Free trial <span>expires Today</span></h6> :
                            <h6>Free trial <span>{moment(licenseExpiryDate).diff(moment(currentdate), 'days')} days <br />remaining</span></h6>
                    }

                    <button
                      type="button"
                      class="btn_modal_open"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      style={{ cursor: "pointer" }}
                      onClick={() => showModal()}
                    >
                      Upgrade Now
                    </button>
                  </div>
                </div>
                : ""
            }

          </StyledTabs>
          {value === 0 && (
            <TabContainer>
              <Home
                changeTab={changeTab}
                patientInfo={patientInfo}
              />
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <Soap
                activeTab={value}
                clearLocationandEditChartView={clearLocationandEditChartView}
                editfromChartReview={editfromChartReview}
                fetchPatientInfo={fetchPatientInfo}
                patientId={patientId}
              />
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer>
              <Ptinfo />
            </TabContainer>
          )}
          {value === 3 && (
            <TabContainer>
              <Ptdoc />
            </TabContainer>
          )}
          {value === 4 && (
            <TabContainer>
              <Search patientInfo={patientInfo} />
            </TabContainer>
          )}
        </Grid>
        {errmsg.err && renderError()}
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  resetValue,
  clearSoap,
  getPatientInfo,
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
  getPastSoapNoteById,
  updateLicenseStatus
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  user: state && state.profile,
  patientInfo: state.soap && state.soap.patientInfo
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Default))
);
