import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography,
  Button,
  CardContent,
  InputAdornment,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Modal,
  Box,
  CircularProgress
 } from '@material-ui/core';
 import MessageBox from 'util/messageBox';
import '../../css/style.css'
 import moment from 'moment'; 
 import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
 import MUIDataTable from 'mui-datatables';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import Loader from '../../../../Common/components/Loader';
import { Analyze, Execute,Rerun,AnalyzeRundate } from '../../services/migration/action';
import DatePicker from 'react-datepicker';
import {
  CalendarToday
} from '@material-ui/icons/';
import {truncate} from '../../util/helper'
import { CloseOutlined} from '@material-ui/icons/';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    paddingTop: 150,
    textAlign: 'center'
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  },
  formSelect: {
 width: 105,
 fontSize: 12,
 height: 35,
  cursor: 'pointer',

                },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none',
    
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalBody: {
    padding: '10px 0',
    maxHeight:350,
    overflowY:'auto'
  },
 
}));
const theme = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'none'
        //height: 'calc(100% - 128px)'
      }
    },
    MuiTableCell: {
      root: {
        padding: '5px 10px',
        fontSize: 12,
        
      }
    },
    MuiToolbar: {
      root: {
        minHeight: '48px!important'
      }
    },
    MUIDataTableHeadRow: {
      root: {
        backgroundColor: '#EFF5FC!important'
      }
    },
    MUIDataTableHeadCell: {
      toolButton:{
        fontSize:12
      },
      fixedHeader: {
        backgroundColor: 'transparent!important',
        height: 45,
        borderTop: '1px solid #D4E5F9',
        borderBottom: '1px solid #D4E5F9'
      }
    },
    MUIDataTableSelectCell: {
      
      headerCell: {
        backgroundColor: 'transparent!important',
        height: 45,
        borderTop: '1px solid #D4E5F9',
        borderBottom: '1px solid #D4E5F9'
      }
    },
    MUIDataTableToolbar: {
      icon: {
        transform: 'scale(.8)'
      },
      titleText: {
        fontSize: '16px!important',
        fontWeight: '600!important',
        fontFamily: 'Lato!important'
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: 'rgba(255,0,0,.2)',
        boxShadow: 'none',
        borderRadius: '0px!important'
      }
    },
    MuiTableRow: {
      root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
    },
    // MuiTablePagination: {
    //   caption: {
    //     fontSize: 12
    //   }
    // }
  }
});



const Migration = (props) => {
  // const {ehrID}=props;

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [rundate, setRundate]=useState();
  // const [loading, setLoading] = useState(true);
  const [openModal, setopenModal] = useState(false); 
  const [log,setLog]= useState(false);
  // const [exceptionLog, setExceptionLog]=useState([]);
  const [field,setField]=useState(false);
  const [limit,setLimit]= useState(false);
  const [count,setCount]= useState(0);
  const [errmsg, setErrMsg] = useState({ err: false, errMsg: '' });
  const [isLoading, setIsLoading] = useState(false);



  useEffect(() => {
    var count=0;
    //  const  value=data;
     for( var i=0; i<data.length;i++){
      const value=data[i].processedcount
      count=count+value;
  
     }
     setCount(count);
  }, [data]);

  const showErrorMsg = msg => {
    setErrMsg({ err: true, errMsg: msg });
  };
  const renderError = () => {
    return (
      <MessageBox
        message={errmsg.errMsg}
        onClose={handleClose}
        open
        variant="error"
      />
    );
  };

  const handleClose = () => {
    setErrMsg({ err: false });
  };

 
 const handleAnalyze=async ()=>{
 
  if(limit){
 const res = await props.Analyze(limit);
    if (res.data) {
  
      
  setData(res.data);
  
 }
}else{
  showErrorMsg('Please select a limit to continue');
      return;
}
}

 const handleExecute = async()=>{
  setIsLoading(true);
    const res = await props.Execute(data);
    if (res.data) {
    setData(res.data);
  setIsLoading(false);
 }

 }





 const handleOpenModal = (field,log) => {
 
    setopenModal(true);
   const errorlog=log.split(("|"));
   
  
    setLog(errorlog);
  
    
    setField(field);
  
};
const handleCloseModal = () => {
   setopenModal(false);
  
};
const handleFieldChange = async ( field,value) => {
 
  if (field === 'rundate') {
    var rundate = value
      ? moment(value).format('YYYY-MM-DD')
      : '';
      setRundate(rundate);
      if (rundate){

        const res = await props.AnalyzeRundate(rundate);
    if (res.data) {
   setData(res.data);
 
 }
      }
  }else{
  setLimit(value);
  
  }
};

const renderOpenModal = (field,value) => {
  
 
  return (
   <Modal
    aria-describedby="simple-modal-description"
    aria-labelledby="simple-modal-title"
    disableBackdropClick
   onClose={handleCloseModal}
     open={openModal}
  >
     <Box className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200,  }}>
          <Box className={classes.modalHead}>
          <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="h2">
                {field}
                </Typography>
                <IconButton
          aria-label="delete"
          className={classes.modalClose}
          onClick={handleCloseModal}>
          <CloseOutlined />
        </IconButton>
       </Box>
      <Box className={classes.modalBody}>
      <PerfectScrollbar>
      <div style={{ maxHeight: 250 }}>
        <Table>
        
         <TableHead>
         <TableRow>
                <TableCell>Sl No.</TableCell>
               <TableCell style={{ textAlign: 'center' }}>{field}</TableCell>
              </TableRow>
        
         </TableHead>
       

        <TableBody>
          
          
        {value.map((item, index) => (  
        <TableRow>
          <TableCell>
            {index+1}
          </TableCell>
          <TableCell align="center">
          {item}
          </TableCell>
  
          </TableRow>
      ))}
          
   </TableBody>
        </Table>
        </div>
        </PerfectScrollbar>
      </Box>
</Box>
 </Modal>
     
  );
 
};

const columns = [
  {
    name: 'rundate',
    label: 'Run Date',
  options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
        return (
         
          <span
          
           >
            {moment(value).format('MM/DD/YYYY')}
         
          </span>
        );
      }
    }
  },
  {
    name: 'patient_id',
    label: 'Patient Id',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      })
    }
  },
  {
    name: 'originalcount',
    label: 'Original Count',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      })
    }
  },
  {
    name: 'processedcount',
    label: 'Processed Count',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      })
    }
  },
  {
    name: 'documentIds',
    label: 'Documents',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
        
        return (
          
          <span
          style={{ cursor: 'pointer' }}
            
            onClick={() => handleOpenModal("Documents",value)}>
            {truncate(value)}
          </span>
        );
      }
    }
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
        return (
         
          <span
          
         
            // onClick={() => handleOpenModal("Failed Documents",value)}
            >
           {value===1?"New":value===2?"Completed":value===3?"Failed":"Partial success"}
          </span>
        );
      }
    }
  },
  {
    name: 'remark',
    label: 'Remark',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value,tableMeta ) => {
        return (
         
          <span
            >
          {(tableMeta.rowData[5]==3 || tableMeta.rowData[5]==4) && (tableMeta.rowData[7]?.match(/NoSuchFileException/g) || []).length ==(tableMeta.rowData[8].split(",")).length?"Failed documents doesnot exist":null}
          </span>
        );
      }
    }
  },
  {
    name: 'log',
    label: 'log',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
      
        return (
         
          <span
          
            style={{ cursor: 'pointer' }}
            
            onClick={() => handleOpenModal("Log",value)}>
            {truncate(value)}
          </span>
        );
      }
    }
  },
  {
    name: 'failedDocids',
    label: 'Failed Documents',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
        return (
         
          <span
          
            style={{ cursor: 'pointer' }}
            onClick={() => handleOpenModal("Failed Documents",value)}>
            {truncate(value)}
          </span>
        );
      }
    }
  },
  {
    name: 'startTime',
    label: 'Start Time',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
        return (
         
          <span
          
           >
            {moment(value).format('MM/DD/YYYY, h:mm:ss a')}
         
          </span>
        );
      }
    }
  },
  {
    name: 'endTime',
    label: 'End Time',
    options: {
      sort: false,
      setCellProps: () => ({
        style: { textAlign: 'center' }
      }),
      setCellHeaderProps: () => ({
        style: { textAlign: 'center' }
      }),
      customBodyRender: (value) => {
        return (
         
          <span
          
           >
            {value?moment(value).format('MM/DD/YYYY, h:mm:ss a'):'-'}
         
          </span>
        );
      }
    }
  },
  
];

const options = {
  filterType: 'select',
  elevation: 0,
  download: true,
  print: false,
  selectableRows: false, // <===== will turn off checkboxes in rows
  filter: false,
  pagination: false,
  count:false,
  page: false,
  viewColumns: false,
 searchPlaceholder: false,
   search: false,
  serverSide: true,
};


  return (
    <div className={classes.root}>
      <div >
          <select
style={{padding:7}}
 aria-label="Default select example"

onChange={(event) =>
 handleFieldChange("limit",event.target.value)
}
>

<option>Select Limit</option>
  <option >10</option>
    <option >20</option>
    <option >50</option>
    <option >100</option>
</select>
  <Button
              color="primary"
              // onClick={() => handleAnalyze(ehrID)}
              
              size="small"
               style={{  left:155, position: 'absolute' ,color:'white', borderRadius:5}}
           
              variant="contained"
              type="submit"
              onClick={() => handleAnalyze()}>
              Analyze
            </Button>

            
            
            <Button
              color="danger"
               onClick={() => handleExecute()}
               disabled={isLoading}
              size="small"
              style={{ backgroundColor: '#b71c1c',  position: 'absolute',
                left: 260 , color:'white',
               borderRadius:5}}
              variant="contained">
                 {isLoading && (
                             <CircularProgress className={classes.progress} size={20} />
                        )}
              Execute
            </Button>


            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              inputProps={{
                style:{  padding:7, }
              }}
              disabled
             
              label="Count"
             
              variant="outlined"
              
              value={count}
              style={{ width: '63px',marginLeft:225}}
            />


            <div className='d-flex justify-content-end' style={{marginLeft:1027,marginTop:-40}}>
            <DatePicker
          
              popperClassName='react-datepicker-popper'
              customInput={
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.labelRoot,
                       focused: classes.labelFocused
                    }
                  }}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <CalendarToday
                          style={{ height: 16, width: 16 ,}}
                        />
                      </InputAdornment>
                    )
                  }}
                  label="Date filter"
                 id="date"
                  margin="dense"
                  variant="outlined"
                />
              }
              format="MM/DD/YYYY"
              maxDate={new Date()}
              
               onChange={event => handleFieldChange('rundate', event)}
              onFocus={e => (e.target.placeholder = 'Select run date')}
              placeholderText="rundate"
              selected={
              rundate ? new Date(rundate) : ''
              }
              showMonthDropdown="bool"
              showYearDropdown="bool"
            />
            </div>
            
           
            </div>
            <PerfectScrollbar>     
<CardContent >

<MuiThemeProvider theme={theme} >
                <MUIDataTable
                 
                  data={data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
{openModal && renderOpenModal(field,log)}
      {errmsg.err && renderError()}
      </CardContent>
</PerfectScrollbar>
    </div>
  );
};

Migration.propTypes = {
  className: PropTypes.string
};


const mapDispatchToProps = {Analyze, Execute, Rerun,AnalyzeRundate};

const mapStateToProps = state => ({ 
  ehrID: state.profile && state.profile.ehrID,
  patientInfo: state.soap && state.soap.patientInfo
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles()(Migration))
);
