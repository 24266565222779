import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import './style.css'
import {
  CalendarToday
} from '@material-ui/icons/';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  InputAdornment
} from '@material-ui/core';
import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getStates,
  getLocations,
  getGender,
  getMaritalstatus,
  getRelationships,
  getethnicities,
  getLanguages,
  getRaces,
  getCategories
} from 'services/util/action';
import moment from 'moment';

const SecondaryInfoEdit = props => {
  const {
    classes,
    className,
    patientinformation,
    ehrID,
    maritalstats,
    relationships,
    ethniccities,
    languages,
    races,
    genders,
    locations,
    states,
    ...rest
  } = props;

  const [values, setValues] = useState({});
  // const [date, setDate] = useState();

  const loadinitialdata = async () => {
    await props.getStates();
    await props.getLocations(ehrID);
    await props.getGender(ehrID);
    await props.getMaritalstatus(ehrID);
    await props.getRelationships(ehrID);
    await props.getethnicities(ehrID);
    await props.getLanguages(ehrID);
    await props.getRaces(ehrID);
    await props.getCategories(ehrID);
  };

  useEffect(() => {
    loadinitialdata();
  }, [ehrID]);

  useEffect(() => {
    if (patientinformation) {
      var patInfo = { ...patientinformation };

      setValues(patInfo);
    }
  }, [patientinformation]);

  const hasError = field =>
    values &&
      // values.touched &&
      values.errors &&
      // values.touched[field] &&
      values.errors[field]
      ? true
      : false;

  const handleFieldChange = (
    field,
    value,
    isAddress = false,
    isSpouse = false,
    isEmergency = false
  ) => {
    props.handleFieldChange(field, value, isAddress, isSpouse, isEmergency);
  };

  // validate.validators.array = (arrayItems, itemConstraints) => {
  //   const arrayItemErrors = arrayItems.reduce((errors, item, index) => {
  //     if (!item.mandatory) {
  //       const error = validate(item, itemConstraints);
  //       if (error) errors[index] = { error: error };
  //     } else {
  //       if (!item.name || item.name === '') {
  //         errors[index] = {
  //           error: { name: ["Name can't be blank"] }
  //         };
  //       }
  //     }
  //     return errors;
  //   }, {});

  //   return arrayItemErrors.length === 0 ? null : arrayItemErrors;
  // };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <CardContent style={{ padding: 10 }}>
        <Grid className={classes.formElements} container spacing={0}>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('lastname')}
              fullWidth
              helperText={
                hasError('lastname') ? values?.errors?.lastname[0] : null
              }
              label="Last Name"
              name="lName"
              onChange={event =>
                handleFieldChange('lastname', event.target.value)
              }
              required
              value={values.lastname}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('firstname')}
              fullWidth
              helperText={
                hasError('firstname') ? values?.errors?.firstname[0] : null
              }
              label="First Name"
              name="fName"
              onChange={event =>
                handleFieldChange('firstname', event.target.value)
              }
              required
              value={values.firstname}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Middle Name"
              name="mName"
              onChange={event =>
                handleFieldChange('middleinitial', event.target.value)
              }
              value={values.middleinitial}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <DatePicker
              wrapperClassName='myDatePickerWrapper'
              className="reactdatepicker_insurance"
              customInput={
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}

                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <CalendarToday
                          style={{ height: 16, width: 16 }}
                        />
                      </InputAdornment>
                    )
                  }}
                  label="Date of birth"
                  // id="date"
                  margin="dense"
                  variant="outlined"
                />
              }
              format="MM/DD/YYYY"
              onBlur={e => (e.target.placeholder = 'Date of birth')}
              onChange={event => handleFieldChange('dob', event)}
              onFocus={e => (e.target.placeholder = 'Date of birth')}
              placeholderText="Date of birth"
              selected={
                patientinformation.dob ? new Date(patientinformation.dob) : ''
              }
              showMonthDropdown="bool"
              showYearDropdown="bool"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Social Security Number"
              name="ssn"
              onChange={event =>
                handleFieldChange('socialsecurity', event.target.value)
              }
              value={values.socialsecurity}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Gender"
              onChange={event =>
                handleFieldChange('gender', event.target.value)
              }
              select
              value={values && values.gender}
              variant="outlined">
              <option value="">Select</option>
              {genders &&
                genders.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Service Facility Location"
              onChange={event => handleFieldChange('id', event.target.value)}
              select
              value={values.practice && values.practice.id}
              variant="outlined">
              <option value="">Select</option>
              {locations &&
                locations.map(item => (
                  <option value={item.id}>
                    {(item.companyname !== null ? item.companyname : '') +
                      ',' +
                      (item.address && item.address.address
                        ? item.address.address
                        : '') +
                      ',' +
                      (item.address && item.address.city
                        ? item.address.city
                        : '') +
                      ',' +
                      (item.address && item.address.state
                        ? item.address.state
                        : '')}
                  </option>
                ))}
            </TextField>
          </Grid>
          <Grid className={classes.stateList} item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Language"
              onChange={event =>
                handleFieldChange('language', event.target.value)
              }
              select
              value={values.language}
              variant="outlined">
              <option value="">Select</option>
              {languages &&
                languages.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Race"
              onChange={event => handleFieldChange('race', event.target.value)}
              select
              value={values.race}
              variant="outlined">
              <option value="">Select</option>
              {races &&
                races.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Ethnicity"
              onChange={event =>
                handleFieldChange('ethnicity', event.target.value)
              }
              select
              value={values.ethnicity}
              variant="outlined">
              <option value="">Select</option>
              {ethniccities &&
                ethniccities.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Address"
              name="ssn"
              onChange={event =>
                handleFieldChange('address', event.target.value, true)
              }
              value={values.address && values.address.address}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="City"
              name="city"
              onChange={event =>
                handleFieldChange('city', event.target.value, true)
              }
              value={values.address && values.address.city}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="State"
              onChange={event =>
                handleFieldChange('state', event.target.value, true)
              }
              select
              value={values.address && values.address.state}
              variant="outlined">
              <option value="">Select</option>
              {states &&
                states.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="ZIP"
              name="zip"
              onChange={event =>
                handleFieldChange('zip', event.target.value, true)
              }
              value={values.address && values.address.zip}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Email Address"
              name="email"
              onChange={event =>
                handleFieldChange('emailaddress', event.target.value)
              }
              value={values.emailaddress}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Cell Phone #"
              name="cell"
              onChange={event =>
                handleFieldChange('phonenumber', event.target.value)
              }
              value={values.phonenumber}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Home Phone #"
              name="home"
              onChange={event =>
                handleFieldChange('homephone', event.target.value)
              }
              value={values.homephone}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Work Phone #"
              name="work"
              onChange={event =>
                handleFieldChange('workphone', event.target.value)
              }
              value={values.workphone}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Marital Status"
              onChange={event =>
                handleFieldChange('maritalstatus', event.target.value)
              }
              select
              value={values.maritalstatus}
              variant="outlined">
              <option value="">Select</option>
              {maritalstats &&
                maritalstats.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={values?.spouse?.errors?.lastname}
              fullWidth
              helperText={
                hasError('lastname') ? values?.spouse?.errors?.lastname : null
              }
              label="Spouse's Last Name"
              name="splname"
              onChange={event =>
                handleFieldChange('lastname', event.target.value, false, true)
              }
              value={values.spouse && values.spouse.lastname}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Spouse's First Name"
              name="spfname"
              error={values?.spouse?.errors?.lastname}
              helperText={
                hasError('firstname') ? values?.spouse?.errors?.firstname : null
              }
              onChange={event =>
                handleFieldChange('firstname', event.target.value, false, true)
              }
              value={values.spouse && values.spouse.firstname}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Middle Name"
              name="spmname"
              onChange={event =>
                handleFieldChange(
                  'middleinitial',
                  event.target.value,
                  false,
                  true
                )
              }
              value={values.spouse && values.spouse.middleinitial}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>

            <DatePicker
              wrapperClassName='myDatePickerWrapper'
              className="reactdatepicker_insurance"
              customInput={
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocused
                    }
                  }}
                  fullWidth

                  InputProps={{

                    endAdornment: (
                      <InputAdornment>
                        <CalendarToday
                          style={{ height: 16, width: 16 }}
                        />
                      </InputAdornment>
                    )
                  }}
                  label="Date of birth"
                  id="date"
                  margin="dense"
                  variant="outlined"

                />
              }

              format="MM/DD/YYYY"
              onBlur={e => (e.target.placeholder = 'Date of birth')}
              onChange={event => handleFieldChange('dob', event, false, true)}
              onFocus={e => (e.target.placeholder = 'Date of birth')}
              placeholderText="Date of birth"
              selected={
                values.spouse && values.spouse.dob ? new Date(values.spouse.dob) : ''
              }
              showMonthDropdown="bool"
              showYearDropdown="bool"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Spouse's Phone #"
              name="spphn"
              onChange={event =>
                handleFieldChange(
                  'phonenumber',
                  event.target.value,
                  false,
                  true
                )
              }
              value={values.spouse && values.spouse.phonenumber}
              variant="outlined"
            />
          </Grid>
          <div className={classes.subHeader}>
            <Typography>Emergency Contact</Typography>
          </div>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Emergency Contact"
              name="ec"
              onChange={event =>
                handleFieldChange(
                  'name',
                  event.target.value,
                  false,
                  false,
                  true
                )
              }
              value={values.emergencyContact && values.emergencyContact.name}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              fullWidth
              label="Phone Number"
              name="ec"
              onChange={event =>
                handleFieldChange(
                  'phonenumber',
                  event.target.value,
                  false,
                  false,
                  true
                )
              }
              value={
                values.emergencyContact && values.emergencyContact.phonenumber
              }
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              SelectProps={{ native: true }}
              fullWidth
              name="Relationship"
              onChange={event =>
                handleFieldChange(
                  'relationship',
                  event.target.value,
                  false,
                  false,
                  true
                )
              }
              select
              value={
                values.emergencyContact && values.emergencyContact.relationship
              }
              variant="outlined">
              <option value="">Select</option>
              {relationships &&
                relationships.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SecondaryInfoEdit.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getStates,
  getLocations,
  getGender,
  getMaritalstatus,
  getRelationships,
  getethnicities,
  getLanguages,
  getRaces,
  getCategories
};
const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  states: state.util.states,
  locations: state.util.locations,
  genders: state.util.genders,
  maritalstats: state.util.maritalStatus,
  relationships: state.util.relationships,
  ethniccities: state.util.ethnicCities,
  languages: state.util.languages,
  races: state.util.races
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SecondaryInfoEdit))
);
