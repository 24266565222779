/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Link
} from '@material-ui/core';
import {
  CheckOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline,
  SaveOutlined
} from '@material-ui/icons';
import {
  getObjectiveTemplates,
  getSubjectiveTemplates,
  getAssessmentPlanTemplates,
  AddSubjectiveTemplate,
  AddObjectiveTemplate,
  AddAssessmentPlanTemplate,
  UpdateAssessmentPlanTemplate,
  UpdateObjectiveTemplate,
  UpdateSubjectiveTemplate,
  DeleteSubjectiveTemplate,
  DeleteObjectiveTemplate,
  DeleteAssessmentPlanTemplate
} from 'services/providers/action';
import validate from 'validate.js';
import { connect } from 'react-redux';

const MyTemplates = props => {
  const {
    classes,
    type,
    ehrID,
    userID,
    assessmentPlans,
    objectives,
    subjectives, 
  } = props;

  const initialUserState = {
    id: null,
    referencedata: false,
    topic: '',
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topic: {
      presence: { allowEmpty: false }
    }
  };

  // const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialUserState);

  const [objectiveTopics, setObjectiveTopics] = useState(null);
  const [handleEditObjectiveTopic, setHandleEditObjectiveTopic] = useState({
    showObjectiveTopicEdit: false
  });

  const [assessmentPlanTopics, setAssessmentPlanTopics] = useState(null);
  const [
    handleEditAssessmentPlanTopic,
    setHandleEditAssessmentPlanTopic
  ] = useState({ showAssessmentPlanTopicEdit: false });

  const [subjectiveTopics, setSubjectiveTopics] = useState(null);
  const [handleEditSubjectiveTopic, setHandleEditSubjectiveTopic] = useState({
    showSubjectiveTopicEdit: false,
    editId: 0
  });

  useEffect(() => {
    if (subjectives !== null) {
      setSubjectiveTopics(subjectives);
      // setLoading(false);
    }
  }, [subjectives]);

  useEffect(() => {
    if (objectives !== null) {
      setObjectiveTopics(objectives);
      // setLoading(false);
    }
  }, [objectives]);

  useEffect(() => {
    if (assessmentPlans !== null) {
      setAssessmentPlanTopics(assessmentPlans);
      // setLoading(false);
    }
  }, [assessmentPlans]);

  const handleTopicChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const addTopic = () => {
    if (values.isValid) {
      if (type === 1) {
        const data = {
          id: values.id,
          referencedata: values.referencedata,
          topic: values.topic,
          topicText: values.topicText
        };
        props.AddSubjectiveTemplate(ehrID, userID, data).then(() => {
          setValues(initialUserState);
          props.getSubjectiveTemplates(ehrID, userID).then(res => {
            if (res && res.data.length > 0) {
              setSubjectiveTopics(res.data);
            }
          });
        });
      } else if (type === 2) {
        props.AddObjectiveTemplate(ehrID, userID, values).then(() => {
          setValues(initialUserState);
          props.getObjectiveTemplates(ehrID, userID).then(res => {
            if (res && res.data.length > 0) {
              setObjectiveTopics(res.data);
            }
          });
        });
      } else if (type === 3) {
        props.AddAssessmentPlanTemplate(ehrID, userID, values).then(() => {
          setValues(initialUserState);
          props.getAssessmentPlanTemplates(ehrID, userID).then(res => {
            if (res && res.data.length > 0) {
              setAssessmentPlanTopics(res.data);
            }
          });
        });
      }
    }
  };

  const handleChange = (field, value, id) => { 
    if (type === 1) {
      let allTopics = [...subjectiveTopics];
      const index = allTopics.findIndex(c => c.id === id);
      if (index != -1) {
        let selectedData = allTopics[index];
        selectedData.topic = value;
        allTopics[index] = selectedData;
        setSubjectiveTopics(allTopics);
      }
    } else if (type === 2) {
      let allDatas = [...objectiveTopics];
      const index = objectiveTopics.findIndex(c => c.id === id);
      if (index != -1) {
        let selectedData = objectiveTopics[index];
        selectedData.topic = value;
        allDatas[index] = selectedData;
        setObjectiveTopics(allDatas);
      }
    } else if (type === 3) {
      let allDatas = [...assessmentPlanTopics];
      const index = assessmentPlanTopics.findIndex(c => c.id === id);
      if (index != -1) {
        let selectedData = assessmentPlanTopics[index];
        selectedData.topic = value;
        allDatas[index] = selectedData;
        setAssessmentPlanTopics(allDatas);
      }
    }
  };

  const updateTopic = topic => {
    if (type === 1) {
      props.UpdateSubjectiveTemplate(ehrID, userID, topic).then(() => {
        closeEditSubjectiveTopic(topic.id);
      });
    } else if (type === 2) {
      props.UpdateObjectiveTemplate(ehrID, userID, topic).then(() => {
        closeEditObjectiveTopic(topic.id);
      });
    } else if (type == 3) {
      props.UpdateAssessmentPlanTemplate(ehrID, userID, topic).then(() => {
        closeEditAssessmentPlanTopic(topic.id);
      });
    }
  };

  //#region Assessment Plan

  const editAssessmentPlanTopic = id => {
    let showAssessmentPlanTopicEdit = handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit
      ? handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit.slice()
      : [];
    showAssessmentPlanTopicEdit[id] = true;
    setHandleEditAssessmentPlanTopic({
      showAssessmentPlanTopicEdit: showAssessmentPlanTopicEdit,
      editId: id
    });
  };

  const closeEditAssessmentPlanTopic = id => {
    let showAssessmentPlanTopicEdit = handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit
      ? handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit.slice()
      : [];
    showAssessmentPlanTopicEdit[id] = false;
    setHandleEditAssessmentPlanTopic({
      showAssessmentPlanTopicEdit: showAssessmentPlanTopicEdit
    });

    props.getAssessmentPlanTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        setAssessmentPlanTopics(res.data);
      }
    });
  };

  const deleteAssessmentPlanTopic = id => {
    props.DeleteAssessmentPlanTemplate(ehrID, userID, id).then(() => {
      closeEditAssessmentPlanTopic(id);
    });
  };

  //#endregion

  //#region Assessment Plan

  const editObjectiveTopic = id => {
    let showObjectiveTopicEdit = handleEditObjectiveTopic.showObjectiveTopicEdit
      ? handleEditObjectiveTopic.showObjectiveTopicEdit.slice()
      : [];
    showObjectiveTopicEdit[id] = true;
    setHandleEditObjectiveTopic({
      showObjectiveTopicEdit: showObjectiveTopicEdit,
      editId: id
    });
  };

  const closeEditObjectiveTopic = id => {
    let showObjectiveTopicEdit = handleEditObjectiveTopic.showObjectiveTopicEdit
      ? handleEditObjectiveTopic.showObjectiveTopicEdit.slice()
      : [];
    showObjectiveTopicEdit[id] = false;
    setHandleEditObjectiveTopic({
      showObjectiveTopicEdit: showObjectiveTopicEdit
    });

    props.getObjectiveTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        setObjectiveTopics(res.data);
      }
    });
  };

  const deleteObjectiveTopic = id => {
    props.DeleteObjectiveTemplate(ehrID, userID, id).then(() => {
      closeEditObjectiveTopic(id);
    });
  };

  //#endregion

  //#region Subjective

  const editSubjectiveTopic = id => {
    let showSubjectiveTopicEdit = handleEditSubjectiveTopic.showSubjectiveTopicEdit
      ? handleEditSubjectiveTopic.showSubjectiveTopicEdit.slice()
      : [];
    showSubjectiveTopicEdit[id] = true;
    setHandleEditSubjectiveTopic({
      showSubjectiveTopicEdit: showSubjectiveTopicEdit,
      editId: id
    });
  };

  const closeEditSubjectiveTopic = subjective => {
    let showSubjectiveTopicEdit = handleEditSubjectiveTopic.showSubjectiveTopicEdit
      ? handleEditSubjectiveTopic.showSubjectiveTopicEdit.slice()
      : [];
    showSubjectiveTopicEdit[subjective] = false;
    setHandleEditSubjectiveTopic({
      showSubjectiveTopicEdit: showSubjectiveTopicEdit
    });

    props.getSubjectiveTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        setSubjectiveTopics(res.data);
      }
    });
  };

  const deleteSubjectiveTopic = id => {
    props.DeleteSubjectiveTemplate(ehrID, userID, id).then(() => {
      props.getSubjectiveTemplates(ehrID, userID).then(res => {
        if (res && res.data.length > 0) {
          setSubjectiveTopics(res.data);
        }
      });
      // closeEditSubjectiveTopic(id);
    });
  };

  //#endregion

  const setTopicText = topicText => { 
    props.setTopicText(topicText);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>My Templates</Typography>
      </div>

      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div className={classes.templatePop}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TextField
                      className={classes.textBox}
                      id="outlined-basic"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      onChange={event =>
                        handleTopicChange('topic', event.target.value)
                      }
                      placeholder="Add New Topic"
                      value={values.topic}
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    <IconButton
                      color="primary"
                      disabled={values.topic === ''}
                      onClick={() => addTopic()}
                      size="small"
                    >
                      <CheckOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setValues(initialUserState);
                      }}
                      size="small"
                    >
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              {type === 1
                ? [
                  subjectiveTopics && subjectiveTopics.length > 0
                    ? subjectiveTopics.map(subjective => (
                      <TableHead>
                        <TableRow>
                          {handleEditSubjectiveTopic.showSubjectiveTopicEdit &&
                              handleEditSubjectiveTopic.showSubjectiveTopicEdit[
                                subjective.id
                              ]
                            ? [
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline:
                                              classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleChange(
                                      'topic',
                                      event.target.value,
                                      subjective.id
                                    )
                                  }
                                  placeholder="Add New Topic"
                                  value={subjective.topic}
                                  variant="outlined"
                                />
                              </TableCell>,
                              <TableCell
                                align="center"
                                style={{ whiteSpace: 'nowrap' }}
                              >
                                <IconButton
                                  color="primary"
                                  disabled={subjective.topic === ''}
                                  onClick={() => {
                                    updateTopic(subjective);
                                  }}
                                  size="small"
                                >
                                  <SaveOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    closeEditSubjectiveTopic(
                                      subjective.id
                                    );
                                  }}
                                  size="small"
                                >
                                  <ClearOutlined
                                    style={{ color: '#DE5555' }}
                                  />
                                </IconButton>
                              </TableCell>
                            ]
                            : [
                              <TableCell>
                                <Link
                                  component={'button'}
                                  onClick={() => {
                                    setTopicText(subjective.topic);
                                  }}
                                >
                                  {subjective.topic}
                                </Link>
                              </TableCell>,
                              <TableCell
                                align="center"
                                width={72}
                              >
                                <IconButton
                                  onClick={() => {
                                    editSubjectiveTopic(subjective.id);
                                  }}
                                  size="small"
                                >
                                  <Edit color="primary" />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    deleteSubjectiveTopic(subjective.id);
                                  }}
                                  size="small"
                                  style={{
                                    visibility:
                                            subjective.topicText.length === 0
                                              ? 'visible'
                                              : 'hidden'
                                  }}
                                >
                                  <DeleteOutline
                                    style={{ color: '#DE5555' }}
                                  />
                                </IconButton>
                              </TableCell>
                            ]}
                        </TableRow>
                      </TableHead>
                    ))
                    : null
                ]
                : type === 2
                  ? [
                    objectiveTopics && objectiveTopics.length > 0
                      ? objectiveTopics.map(objective => (
                        <TableHead>
                          <TableRow>
                            {handleEditObjectiveTopic.showObjectiveTopicEdit &&
                              handleEditObjectiveTopic.showObjectiveTopicEdit[
                                objective.id
                              ]
                              ? [
                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline:
                                              classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleChange(
                                        'topic',
                                        event.target.value,
                                        objective.id
                                      )
                                    }
                                    placeholder="Add New Topic"
                                    value={objective.topic}
                                    variant="outlined"
                                  />
                                </TableCell>,
                                <TableCell
                                  align="center"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    maxWidth: 100
                                  }}
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      updateTopic(objective);
                                    }}
                                    size="small"
                                  >
                                    <SaveOutlined />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      closeEditObjectiveTopic(objective.id);
                                    }}
                                    size="small"
                                  >
                                    <ClearOutlined
                                      style={{ color: '#DE5555' }}
                                    />
                                  </IconButton>
                                </TableCell>
                              ]
                              : [
                                <TableCell>
                                  <Link
                                    component={'button'}
                                    onClick={() => {
                                      setTopicText(objective.topic);
                                    }}
                                  >
                                    {objective.topic}
                                  </Link>
                                </TableCell>,
                                <TableCell
                                  align="center"
                                  width={72}
                                >
                                  <IconButton
                                    onClick={() => {
                                      editObjectiveTopic(objective.id);
                                    }}
                                    size="small"
                                  >
                                    <Edit color="primary" />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      deleteObjectiveTopic(objective.id);
                                    }}
                                    size="small"
                                    style={{
                                      visibility:
                                            objective.topicText.length === 0
                                              ? 'visible'
                                              : 'hidden'
                                    }}
                                  >
                                    <DeleteOutline
                                      style={{ color: '#DE5555' }}
                                    />
                                  </IconButton>
                                </TableCell>
                              ]}
                          </TableRow>
                        </TableHead>
                      ))
                      : null
                  ]
                  : [
                    assessmentPlanTopics && assessmentPlanTopics.length > 0
                      ? assessmentPlanTopics.map(assessmentPlan => (
                        <TableHead>
                          <TableRow>
                            {handleEditAssessmentPlanTopic.showAssessmentPlanTopicEdit &&
                              handleEditAssessmentPlanTopic
                                .showAssessmentPlanTopicEdit[assessmentPlan.id]
                              ? [
                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline:
                                              classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleChange(
                                        'topic',
                                        event.target.value,
                                        assessmentPlan.id
                                      )
                                    }
                                    placeholder="Add New Topic"
                                    value={assessmentPlan.topic}
                                    variant="outlined"
                                  />
                                </TableCell>,
                                <TableCell
                                  align="center"
                                  style={{
                                    whiteSpace: 'nowrap',
                                    maxWidth: 100
                                  }}
                                >
                                  <IconButton
                                    color="primary"
                                    onClick={() => {
                                      updateTopic(assessmentPlan);
                                    }}
                                    size="small"
                                  >
                                    <SaveOutlined />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      closeEditAssessmentPlanTopic(
                                        assessmentPlan.id
                                      );
                                    }}
                                    size="small"
                                  >
                                    <ClearOutlined
                                      style={{ color: '#DE5555' }}
                                    />
                                  </IconButton>
                                </TableCell>
                              ]
                              : [
                                <TableCell>
                                  <Link
                                    component={'button'}
                                    onClick={() => {
                                      setTopicText(assessmentPlan.topic);
                                    }}
                                  >
                                    {assessmentPlan.topic}
                                  </Link>
                                </TableCell>,
                                <TableCell
                                  align="center"
                                  width={72}
                                >
                                  <IconButton
                                    onClick={() => {
                                      editAssessmentPlanTopic(
                                        assessmentPlan.id
                                      );
                                    }}
                                    size="small"
                                  >
                                    <Edit color="primary" />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => {
                                      deleteAssessmentPlanTopic(
                                        assessmentPlan.id
                                      );
                                    }}
                                    size="small"
                                    style={{
                                      visibility:
                                            assessmentPlan.topicText.length ===
                                            0
                                              ? 'visible'
                                              : 'hidden'
                                    }}
                                  >
                                    <DeleteOutline
                                      style={{ color: '#DE5555' }}
                                    />
                                  </IconButton>
                                </TableCell>
                              ]}
                          </TableRow>
                        </TableHead>
                      ))
                      : null
                  ]}
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  getSubjectiveTemplates,
  getObjectiveTemplates,
  getAssessmentPlanTemplates,
  AddSubjectiveTemplate,
  AddObjectiveTemplate,
  AddAssessmentPlanTemplate,
  UpdateAssessmentPlanTemplate,
  UpdateObjectiveTemplate,
  UpdateSubjectiveTemplate,
  DeleteSubjectiveTemplate,
  DeleteObjectiveTemplate,
  DeleteAssessmentPlanTemplate
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
