/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import MUIDataTable from 'mui-datatables';
import {
  Grid,
  Button,
  Modal,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setPtMessageComplete } from 'services/ptmessages/action';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { CloseOutlined, DeleteOutlineOutlined } from '@material-ui/icons';
import ScheduleAppointment from './Modal_ScheduleAppointment';
import moment from 'moment';
import MessageBox from 'util/messageBox';
import {
  savePtSchedule,
  deletePtSchedule,
  getAllPhyPtSchedules,
  getAllPatPtSchedules
} from 'services/ptschedules/action';

const PTList = props => {
  const { classes, data, ehrID, userID, allAppointments } = props;

  const handleAcknowledge = async ptMessage => {
    const res = await props.setPtMessageComplete(userID, ptMessage.item);
    if (res) {
      props.fetchData();
    }
  };

  //#region Schedule

  const [openScheduleAppointment, setOpenScheduleAppointment] = useState({
    showPopUp: false,
    appointment: null
  });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [errormsg, setShowErrorMsg] = useState({ error: false, errorMsg: '' });

  const [openDeleteAppointment, setOpenDeleteAppointment] = useState({
    showPopUp: false,
    id: 0
  });
  const [id, setID] = useState();
  const [appointmentTime, setAppointmentTime] = useState();

  const handleOpenScheduleAppointment = () => {
    setOpenScheduleAppointment({ showPopUp: true });
  };

  const handleCloseScheduleAppointment = async () => {
    setOpenScheduleAppointment({ showPopUp: false });
    props.fetchData();
  };

  const handleSavePtSchedule = async ptSchedule => {
    const res = await props.savePtSchedule(ehrID, ptSchedule);
    if (res) {
      setOpenScheduleAppointment({ showPopUp: false });
      setSuccMsg({ succ: true, succMsg: 'Appointment scheduled successfully' });
    }
    props.fetchData();
  };

  const handleClose = async () => {
    setSuccMsg({ succ: false });
  };
  const handleCloseError = async () => {
    setShowErrorMsg({ error: false });
  };
  const handleOpenDeleteAppointment = (itemId, appointmentTime) => {
    setID(itemId);
    setAppointmentTime(appointmentTime);
    var currentTime = moment().format('MM/DD/YYYY HH:mm');

    if (appointmentTime <= currentTime) {
      setShowErrorMsg({
        error: true,
        errorMsg: 'You can delete or cancel only future appointments.'
      });
    } else {
      setOpenDeleteAppointment({ showPopUp: true });
    }
  };
  const handleDeleteOK = async () => {
    if (id) {
      var currentTime = moment().format('MM/DD/YYYY HH:mm');

      if (appointmentTime <= currentTime) {
        setOpenDeleteAppointment({ showPopUp: false });
        setShowErrorMsg({
          error: true,
          errorMsg: 'You can delete or cancel only future appointments.'
        });
      } else {
        const res = await props.deletePtSchedule(ehrID, id, true);
        if (res) {
          setSuccMsg({
            succ: true,
            succMsg: 'Appointment cancelled successfully'
          });
        }
        setOpenDeleteAppointment({ showPopUp: false });
      }
    }
  };
  const handleDeleteCancel = () => {
    setOpenDeleteAppointment({ showPopUp: false });
  };
  const renderDeletepopup = (itemId, appointmentTime) => {
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={openDeleteAppointment.showPopUp}>
        <DialogTitle id="confirmation-dialog-title">
          PT Schedule Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the appointment ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDeleteOK(itemId, appointmentTime)}
            color="primary">
            OK
          </Button>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderScheduleAppointment = () => {
    return (
      <Modal
        disableBackdropClick
        onClose={handleCloseScheduleAppointment}
        open={openScheduleAppointment.showPopUp}>
        <ScheduleAppointment
          appointment={openScheduleAppointment.appointment}
          data={data}
          handleSavePtSchedule={handleSavePtSchedule}
          onCancel={handleCloseScheduleAppointment}
        />
      </Modal>
    );
  };

  //#endregion

  //#region success message

  const renderSuccess = () => {
    return (
      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />
    );
  };
  //#endregion
  const renderError = () => {
    return (
      <MessageBox
        message={errormsg.errorMsg}
        onClose={handleCloseError}
        open={errormsg.errorMsg}
        variant="error"
      />
    );
  };

  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '10px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton:{
          fontSize:12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          // padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16, 
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',

        setCellHeaderProps: () => ({})
      }
    },
    {
      name: 'appointmentDate',
      label: 'Date',
      options: {
        setCellProps: () => ({
          style: { width: '20%', minWidth: 110 }
        }),
        setCellHeaderProps: () => ({})
      }
    },
    {
      name: 'appointmentTime',
      label: 'Time',
      options: {
        setCellProps: () => ({
          style: { width: '40%' }
        }),
        setCellHeaderProps: () => ({})
      }
    },
    {
      name: 'reasonForAppointment',
      label: 'Reason for appointment',
      options: {
        setCellProps: () => ({
          style: { width: '20%', minWidth: 110 }
        }),
        setCellHeaderProps: () => ({})
      }
    },
    {
      name: 'routePhysicianName',
      label: 'Provider Name',
      options: {
        setCellProps: () => ({}),
        setCellHeaderProps: () => ({
          style: { width: '30%' }
        })
      }
    },
    {
      name: '',

      options: {
        customBodyRender: (value, tableMeta) => {
          var currentDateString = moment().format('MM/DD/YYYY HH:mm');

          var AppointmentTime = tableMeta.rowData[2];
          AppointmentTime =
            tableMeta.rowData[1] + ' ' + AppointmentTime.slice(0, 5);
          var AppointmentYear = tableMeta.rowData[1];
          AppointmentYear = AppointmentYear.slice(6);

          if (new Date(AppointmentTime) > new Date(currentDateString)) {
            return (
              <IconButton
                className={classes.tableIcon}
                onClick={() =>
                  handleOpenDeleteAppointment(
                    tableMeta.rowData[0],
                    AppointmentTime
                  )
                }
                size="small">
                <DeleteOutlineOutlined style={{ color: '#FF0000' }} />
              </IconButton>
            );
          }
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    filterType: 'select',
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false
  };

  return (
    <Grid container item spacing={0}>
      <Grid item xs={12}>
        <Grid item lg={5} xs={4} style={{ padding: '0 0 10px 0' }}>
          <Button
            className={clsx(classes.viewBtn, classes.SchBtn)}
            color="primary"
            onClick={() => handleOpenScheduleAppointment()}
            variant="contained">
            Schedule Appointment
          </Button>
        </Grid>
        <MuiThemeProvider theme={theme}>
          <MUIDataTable
            className={classes.simpleTable}
            columns={columns}
            data={data}
            options={options}
            responsive="scrollMaxHeight"
          />
        </MuiThemeProvider>
      </Grid>
      {openScheduleAppointment && renderScheduleAppointment()}
      {openDeleteAppointment && renderDeletepopup()}
      {succmsg.succ && renderSuccess()}
      {errormsg.error && renderError()}
    </Grid>
  );
};

PTList.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  setPtMessageComplete,
  savePtSchedule,
  deletePtSchedule,
  getAllPhyPtSchedules,
  getAllPatPtSchedules
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PTList));
