import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../../../SignIn/style';
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  withStyles,
  TablePagination
} from '@material-ui/core';
import { connect } from "react-redux";

const Result = props => {
  const { classes } = props;
  const initialState = {
    loading: true,
    rowsPerPage: 10,
    page: 0
  };
  const [rowsPerPage, setRowsPerPage] = useState(initialState.rowsPerPage);
  const [page, setPage] = useState(initialState.page);
  const [totalElements, setTotalElements] = useState(null);
  const [result, setResult] = useState(props.result);


  useEffect(() => {
    if (props.result) {
      setResult(props.result.content);
      document.getElementById('headerSpan').scrollIntoView();
      setTotalElements(props.result.totalElements);
    }
  }, [props.result]);

  const handleChangePage = (event, page) => {
    setPage(page);
    props.onChange(rowsPerPage, page);
    // window.scrollTo(0, 0);
  };

  // const handlePrev = () => {
  //   let p = page - 1;
  //   if (p < 0) {
  //     return
  //   }
  //   handleChangePage(p)
  // }

  // const handleNext = () => { 
  //   let endIndex = Math.min(1 + props.result.size - 1, props.result.totalElements - 1);
  //   let p = page + 1;
  //   if (p > props.result.totalElements) {
  //     return
  //   }
  //   handleChangePage(p)
  // }

  const handleChangeRowsPerPage = event => {
    const rowsPerPage = event.target.value;
    setRowsPerPage(rowsPerPage);
    props.onChange(rowsPerPage, page);
  };

  //   <Grid sx={12} item className={classes.searchResultsWrap}>
  //   <RouterLink >
  //     <Typography variant="h2" className={classes.test}>{ehr.organizationname}</Typography>
  //   </RouterLink>
  //   <Box className={classes.resultBody}>
  //     <Box className={classes.resultItem}>
  //       <Typography className={classes.colorGrey}>
  //         {ehr.companyname}
  //       </Typography>
  //     </Box>
  //     <Box className={classes.resultItem}>
  //       <Typography className={classes.colorGreen}>
  //         {ehr.providerNames}
  //       </Typography>
  //     </Box>
  //     <Box className={classes.resultItem}>
  //       <RouterLink onClick={() => window.open(ehr.websitename, "_blank")}>
  //         <Typography className={classes.colorPrimary}>
  //           {ehr.websitename}
  //         </Typography>
  //       </RouterLink>
  //     </Box>
  //   </Box>
  // </Grid>

  return (<>
    <div className="search-result-area" id="headerSpan">
      {result && result.length > 0 && (
        result.map((ehr) => (
          ehr.websitename?(
           
              <div className="result-item" onClick={() =>window.open(ehr.websitename, "_blank")}>
           

            <a href="" className="result-item-main">{ehr.organizationname}</a>
            <p className="sub1">{ehr.companyname}</p>
            <p className="sub2">{ehr.providerNames}</p>
            <p className="sub2">{ehr.websitename}</p>
           

           </div>  
          ):(
            
            <div className="result-item" >
           
 
          <Link to={"/organization-not-found"}>  
 
             <a href="" className="result-item-main">{ehr.organizationname}</a>
             </Link>
             <p className="sub1">{ehr.companyname}</p>
             <p className="sub2">{ehr.providerNames}</p>
             <p className="sub2">{ehr.websitename}</p>
              
 
            </div> 
          )

        ))
      )}  </div>
    {result && result.length > 0 && (
    <TablePagination
          backIconButtonProps={{
            "aria-label": "Previous Page"
          }}
          component="div"
          className={classes.paginationWrap}
          count={totalElements}
          nextIconButtonProps={{
            "aria-label": "Next Page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
    
    // <div className="result-pagination">
    //   <div className="pagination-items">
    //     <div className="page-num">
    //       <p>Rows per page:</p>
    //       <select name="" id="" className="form-select" onChange={handleChangeRowsPerPage}>
    //         <option value={10}>10</option>
    //         <option value={15}>15</option>
    //         <option value={20}>20</option>
    //       </select>
    //     </div>
    //     <div className="page-caption">
    //       <p>{page + 1}-{totalElements > 10 ? rowsPerPage : totalElements} of {totalElements}</p>
    //     </div>
    //     <div className="page-actions">
    //       <button className="action-left" onClick={handlePrev}><span className="iconify" data-icon="akar-icons:chevron-left"></span></button>
    //       <button onClick={handleNext}><span className="iconify" data-icon="akar-icons:chevron-left" data-rotate="180deg"></span></button>
    //     </div>
    //   </div>
    // </div>
    )}
  </>
  );
};

Result.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {

};

const mapStateToProps = () => ({
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Result))
);
