import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from 'views/AccountSettings/style';
import provider from './../../../../../assets/images/icon_provider.png';
import staff from './../../../../../assets/images/icon_staff.png';
import { getTitles } from 'services/util/action';
import { connect } from 'react-redux';
import { addUser, getUserDetails } from 'services/user/action';
import validate from 'validate.js';
import schema from './schema';
import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import {
  RadioButtonUnchecked,
  CheckCircle,
  InfoOutlined
} from '@material-ui/icons';
import { Roles } from 'util/enum';
import MessageBox from 'util/messageBox';

const Create = props => {
  const { classes, className, titles, ehrID, ...rest } = props;

  const initialUserState = {
    accounttype: Roles.user + '+' + Roles.provider,
    title: '',
    firstname: '',
    lastname: '',
    username: '',
    emailaddress: '',
    emailconfirm: '',
    isLoading: false,
    isValid: false,
    isAdmin: false,
    isMedicalProvider: true,
    isClinicalStaff: false,
    touched: {},
    errors: {}
  };
  const [values, setValues] = useState(initialUserState);
  const [succMsg, setSuccMsg] = useState(false);
  const [userdetails, setUserDetails] = useState([]);

  const hasError = field =>
    values &&
      values.touched &&
      values.errors &&
      values.touched[field] &&
      values.errors[field]
      ? true
      : false;

  useEffect(() => {
    props.getTitles();
  }, []);

  const handleFieldChange = (field, value) => {
    const newState = { ...values };

    if (field === 'isAdmin') {
      setValues({
        ...values,
        accounttype:
          value === 'false'
            ? values.accounttype + '+' + Roles.admin
            : values.accounttype,
        isAdmin: value === 'true' ? false : true
      });

      return;
    }

    if (field === 'accounttype') {
      setValues({
        ...values,
        accounttype:
          value === '0'
            ? Roles.user +
            '+' +
            Roles.provider +
            (values.isAdmin ? '+' + Roles.admin : '')
            : Roles.user +
            '+' +
            Roles.staff +
            (values.isAdmin ? '+' + Roles.admin : ''),
        isClinicalStaff: value === '0' ? false : true,
        isMedicalProvider: value === '0' ? true : false
      });

      return;
    }

    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const handleSendInvite = async () => {
    if (values.isValid) {
      setValues({ ...values, isLoading: true });
      props.addUser(ehrID, 0, { ...values }).then(status => {
        if (status) {
          setSuccMsg(true);
          setValues(initialUserState);
          const usrDtls = props.getUserDetails(ehrID, 1, '', 20).then(stat => {
            if (stat) {
              setUserDetails(usrDtls);
            }
          });
        } else {
          setValues({ ...values, isLoading: false });
        }
      });
    }
  };

  const handleCloseAll = () => {
    setSuccMsg(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.cardRoot, className)}
      style={{ borderTopLeftRadius: 0 }}>
      <Grid container>
        <Grid className={classes.cardHeader} item xs={12}>
          <Typography variant="h2">Create</Typography>
        </Grid>
        <Grid className={classes.cardSubHeader} item xs={12}>
          <Typography variant="h3">Choose Account Type</Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.typeBox}>
              <Box
                className={clsx(
                  classes.typeBoxOption,
                  values.isMedicalProvider ? classes.typeBoxOptionSelected : ''
                )}
                onClick={event => handleFieldChange('accounttype', '0')}
                value={values.isMedicalProvider}>
                <span>
                  <img src={provider} />
                  {values.isMedicalProvider ? (
                    <CheckCircle style={{ color: 'white' }} />
                  ) : (
                    <RadioButtonUnchecked style={{ color: 'white' }} />
                  )}
                </span>
                Medical
                <br />
                Provider
                <Tooltip
                  arrow="true"
                  placement="bottom-start"
                  title="Complete access to electronic health records and allowed to save and sign progress notes and clinical documents electronically.">
                  <InfoOutlined className={classes.tootltipStyle} />
                </Tooltip>
              </Box>
              <Box
                className={clsx(
                  classes.typeBoxOption,
                  values.isClinicalStaff ? classes.typeBoxOptionSelected : ''
                )}
                onClick={event => handleFieldChange('accounttype', '1')}
                value={values.isClinicalStaff}>
                <span>
                  <img src={staff} />
                  {values.isClinicalStaff ? (
                    <CheckCircle style={{ color: 'white' }} />
                  ) : (
                    <RadioButtonUnchecked style={{ color: 'white' }} />
                  )}
                </span>
                Clinical
                <br />
                Staff
                <Tooltip
                  arrow="true"
                  placement="bottom-start"
                  title="Access to Medical History Update, Diagnosis Update, and Patient Documents. Medical notes and clinical documents can be routed to providers for signatures. All medical notes and documents can be viewed, downloaded and printed for medical relevance.">
                  <InfoOutlined className={classes.tootltipStyle} />
                </Tooltip>
              </Box>
            </Box>
            <br />
            {values.isMedicalProvider && (
              <React.Fragment>
                <TextField
                  InputLabelProps={{
                    classes: {
                      focused: classes.labelFocusedS
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputOutlinedG,
                      focused: classes.inputFocused,
                      notchedOutline: classes.notchedOutline
                    }
                  }}
                  SelectProps={{ native: true }}
                  error={hasError('title')}
                  fullWidth
                  helperText={hasError('title') ? values.errors.title[0] : null}
                  name="title"
                  onChange={event =>
                    handleFieldChange('title', event.target.value)
                  }
                  select
                  value={values.title}
                  variant="outlined">
                  {titles &&
                    titles.map(item => (
                      <option value={item.value}>
                        {item.key}
                        {item.value ? ' - ' + item.value : ''}
                      </option>
                    ))}
                </TextField>
                <br />
              </React.Fragment>
            )}
            <div
              style={{ position: 'relative', backgroundColor: 'transparent' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.isAdmin}
                    color="primary"
                    onChange={event =>
                      handleFieldChange('isAdmin', event.target.value)
                    }
                    size="small"
                    value={values.isAdmin}
                  />
                }
                label="Admin Privilege &nbsp; &nbsp;"
                labelPlacement="start"
                style={{
                  marginLeft: 0,
                  marginTop: 20,
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              />
              <Tooltip
                arrow="true"
                placement="bottom-start"
                title="Privilege to add or delete users and patients in the system and edit organization profile.">
                <InfoOutlined
                  className={classes.tootltipStyle}
                  style={{ left: 100, marginTop: 5 }}
                />
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <Grid container>
        <Grid className={classes.cardSubHeader} item xs={12}>
          <Typography variant="h3">User Information</Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('lastname')}
              fullWidth
              helperText={
                hasError('lastname') ? values.errors.lastname[0] : null
              }
              label="Last Name"
              name="lastname"
              onChange={event =>
                handleFieldChange('lastname', event.target.value)
              }
              required
              value={values.lastname}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('firstname')}
              fullWidth
              helperText={
                hasError('firstname') ? values.errors.firstname[0] : null
              }
              label="First Name"
              name="firstname"
              onChange={event =>
                handleFieldChange('firstname', event.target.value)
              }
              required
              value={values.firstname}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('username')}
              fullWidth
              helperText={
                hasError('username') ? values.errors.username[0] : null
              }
              label="Username"
              name="username"
              onChange={event =>
                handleFieldChange('username', event.target.value)
              }
              required
              value={values.username}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('emailaddress')}
              fullWidth
              helperText={
                hasError('emailaddress') ? values.errors.emailaddress[0] : null
              }
              label="Email Address"
              name="emailaddress"
              onChange={event =>
                handleFieldChange('emailaddress', event.target.value)
              }
              required
              value={values.emailaddress}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              color="Primary"
              error={hasError('emailconfirm')}
              fullWidth
              helperText={
                hasError('emailconfirm') ? values.errors.emailconfirm[0] : null
              }
              label="Confirm Email Address"
              name="emailconfirm"
              onChange={event =>
                handleFieldChange('emailconfirm', event.target.value)
              }
              required
              value={values.emailconfirm}
              variant="outlined"
            />
          </Grid>
          {values.isLoading ? (
            <div
              className={classes.progressWrapper}
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginBottom: 30
              }}>
              <CircularProgress style={{ height: 30, width: 30 }} />
            </div>
          ) : (
            <Grid item style={{ textAlign: 'right' }} xs={12}>
              <Button
                color="primary"
                disabled={!values.isValid}
                onClick={handleSendInvite}
                variant="contained">
                Send Invite
              </Button>
            </Grid>
          )}
        </Grid>
        <MessageBox
          message="Account activation mail sent successfully"
          onClose={handleCloseAll}
          open={succMsg}
          variant="success"
        />
      </CardContent>
    </Card>
  );
};

Create.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getTitles,
  addUser,
  getUserDetails
};
const mapStateToProps = state => ({
  titles: state.util.titles,
  ehrID: state.profile && state.profile.ehrID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
