/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import { withRouter } from 'react-router-dom';
import MedicationsAdd from './ApRx-Medications';
import MedicationsList from './ApRx-MedicationList';
import { Button, Grid } from '@material-ui/core';

const VSHx = props => {
  const { classes, patientId, patientInfo, isNewRx } = props;
  const [medicationComment, setMedicationComment] = useState('');
  const [prescriptions, setPrescriptions] = useState([]);
  const [copyprescription, setCopyPrescription] = useState([]);
  const [valueToPrint, setValueToPrint] = useState([]);

  useEffect(() => {
    if (props.prescriptions) {
      setPrescriptions(props.prescriptions);
    }
  }, [props.prescriptions]);

  useEffect(() => {
    if (props.medicationComment && props.medicationComment.length > 0) {
      setMedicationComment(props.medicationComment);
    }
  }, [props.medicationComment]);

  const handlePrescription = prescriptions => {
    setPrescriptions(prescriptions);
    props.handlePrescription(prescriptions);
  };

  const handleMedicationComment = medComment => {
    setMedicationComment(medComment);
    props.handleMedicationComment(medComment);
  };

  const handleValuetoPrint = newitem => {
    setValueToPrint(newitem);
  };

  const handleESignPrint = () => {
    if (valueToPrint && valueToPrint.length > 0) {
      props.handleESignPrint(patientId, valueToPrint);
    }
    else if (!isNewRx && prescriptions && prescriptions.length > 0) {
      props.handleESignPrint(patientId, prescriptions);
    }
  };

  const handleCopy = item => {
    setCopyPrescription({
      name: item.name,
      dose: item.dose,
      directions: item.directions,
      quantity: item.quantity,
      refill: item.refill,
      oldMedicationId: item.oldMedicationId
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={8} xs={12}>
        <MedicationsAdd
          copyprescription={copyprescription}
          handleMedicationComment={handleMedicationComment}
          handlePrescription={handlePrescription}
          patientInfo={patientInfo}
          prescriptions={props.prescriptions}
          isNewRx={props.isNewRx}
          handleValuetoPrint={handleValuetoPrint}
          medicationComment={medicationComment}
          isCopy={props.isCopy}
          patientId={patientId}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MedicationsList handleCopy={handleCopy} patientId={patientId} />
      </Grid>
      <Grid className={classes.modalFooter} item xs={12}>
        <Button onClick={props.onCancel} size="small" variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={props.onCancel}
          disabled={(prescriptions && prescriptions.length === 0) && (medicationComment === '')}
          size="small"
          style={{ margin: '0 10px' }}
          variant="contained">
          E-Sign &amp; Close
        </Button>
        <Button
          color="secondary"
          onClick={() => handleESignPrint()}
          disabled={(prescriptions && prescriptions.length === 0) && (medicationComment === '')}
          size="small"
          variant="contained">
          E-Sign &amp; Print
        </Button>
      </Grid>
    </Grid>
  );
};

VSHx.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(VSHx));
