import {
  Box, Button, CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, IconButton, Table,
  TableBody,
  TableCell, TableRow, TextField, Tooltip, Typography
} from '@material-ui/core';
import {
  CheckCircleOutlineRounded, CloseOutlined, DeleteOutline, ExpandMore, GridOnOutlined
} from '@material-ui/icons/';
import { withStyles } from '@material-ui/styles';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  addRemarks,
  deleteClinicalFindings, deleteDocuments,downloadPDF, getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments, getPatientCharts, getUnsignedCharts, routeToChange, signDocument
} from 'services/home/action';
import { loadOrders } from 'services/orders/action';
import {
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication, getPatientInfo, getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks
} from 'services/soap/action';
import { getProviders } from 'services/util/action';
import { Roles } from 'util/enum';
import { b64toBlob1, computeAge, getUrl } from 'util/helper';
import styles from '../../Default/style';
pdfjs.GlobalWorkerOptions.workerSrc =
  '//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js';

const ScheduleAppo = props => {
  const {
    classes,
    role,
    className,
    dataDocViewer,
    doc,
    miscDoc,
    miscDataDocViewer,
    patientInfo,
    providers,
    ehrID,
    userID,
    // isClinical,
    isClinicalDocuments,
    ptActiveMedication,
    ptInactiveMedication,
    ptMedicalHistory,
    ptPastDiagnosis,
    ptInactiveDiagnosis,
    ptRemarks,
    getPatientInfo,
    getPatientActiveMedications,
    getPatientInactiveDiagnosis,
    getPatientInactiveMedication,
    getPatientMedicalHistory,
    getPatientPastDiagnosis,
    getPatientRemarks,
    getPatientCharts,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments  } = props;

  const [url, setUrl] = useState(null);
  const [pros, setPros] = useState([]);
  const [data, setData] = useState(null);
  const [values, setValues] = useState(null);
  const [loading,setLoading]=useState(true);
  const [signLoading,setSignLoading]=useState(false);
  const [isMHX, setIsMHX] = useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [isedit, setIsEdit] = React.useState(false);
  const [handledeletepopup, setHandleDeletePopup] = useState(false);
  const [handledocdeletepopup, setHandleDocDeletePopup] = useState(false);
  const [poploading, setPopLoading] = useState(false);
  const [popDocloading, setPopDocLoading] = useState(false);
  const [isClinical, setIsClinical] = useState(false)

  useEffect(() => {
    props.getProviders(ehrID);
  }, []);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      // providertopush.push({ key: 'All Providers', value: '' });
      providers.forEach(function (item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);
  

  useEffect(() => { 
       
  if (Object.entries(dataDocViewer).length > 0) {
      dataDocViewer.url =
        '/patients/' +
        dataDocViewer.patient.id +
        '/documents/' +
        dataDocViewer.patientdocument.id;
      setData(dataDocViewer);
      setIsClinical(dataDocViewer?.patientdocument?.category?.clinical)
      if(doc !== null && !_.isEmpty(doc?.data)&&!_.isUndefined(doc?.data)&&!_.isNull(doc?.data)) {
        const blob = b64toBlob1(doc?.data, 'application/pdf');
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);
          setUrl(blobUrl)
        }
      }else {
        if (!_.isEmpty(dataDocViewer.url)) { 
          setUrl(getUrl(dataDocViewer.url)); 
        } else {
          setUrl(null)
        }
      }
      setValues(dataDocViewer.patientdocument);
    }
  }, [dataDocViewer]);


  useEffect(() => {

    if (miscDataDocViewer && Object.entries(miscDataDocViewer).length > 0) {
      miscDataDocViewer.url =
        '/patients/' +
        miscDataDocViewer.patient.id +
        '/documents/' +
        miscDataDocViewer.patientdocument.id;
      setData(miscDataDocViewer);
      if (miscDoc !== null && !_.isEmpty(miscDoc?.data)&&!_.isUndefined(miscDoc?.data)&&!_.isNull(miscDoc?.data)) {
        const blob = b64toBlob1(miscDoc?.data, 'application/pdf');
        if (blob) {
          const blobUrl = URL.createObjectURL(blob);
          setUrl(blobUrl)
        }
      } else {
        if (!_.isEmpty(miscDataDocViewer.url)) { 
          setUrl(getUrl(miscDataDocViewer.url)); 
        } else {
          setUrl(null)
        }
      }
      setValues(miscDataDocViewer.patientdocument);
    }
  }, [miscDataDocViewer]);
  

  const handleOpenHxSummaryControl = async () => {
    // const patientId= data.patient.id;
    // getPatientInfo(patientId);
    // getPatientActiveMedications(patientId) 
    // getPatientInactiveDiagnosis(patientId) 
    // getPatientInactiveMedication(patientId)
    // getPatientMedicalHistory(patientId)
    // getPatientPastDiagnosis(patientId)
    // getPatientRemarks(patientId) 
    setIsMHX(!isMHX);
  };


  const hideSpinner = () => {
    setLoading(false);
  };

  const handleAddClinicalFindings = () => {
    setIsEdit(true);
  };

  const handleFieldChange = (field, value) => {
    setValues({ ...values, remarks: value });
  };

  const handleSubmit = async () => {
    let newState = { ...values };
    newState.remarksUpdatedDate = new Date();
    const res = await props.addRemarks(
      ehrID,
      data.patient.id,
      data.patientdocument.id,
      userID,
      newState
    );
    if (res) {
      await getPatientRemarks(data.patient.id)
      setValues({ ...values, remarksUpdatedDate: res.data.remarksUpdatedDate });
      setIsEdit(false);

    }
  };

  const handleSignDocument = async () => {
    setSignLoading(true);
    let newstate = { ...data };
    const res = await props.signDocument(
      ehrID,
      data.patient.id,
      data.patientdocument.id,
      userID,
      values
    );
    if (res) {
      newstate.patientdocument = res.patientdocument;
      newstate.signature = res.signature;
      setData(newstate);
      getPatientCharts(data.patient.id);
      getPatientAllClinicalDocuments(data.patient.id, ehrID);
      getPatientAllMiscellaneousDocuments(data.patient.id, ehrID);
      await props.loadOrders(data.patient.id);
      await getPatientRemarks(data.patient.id);
      props.getUnsignedCharts(ehrID, userID);
      setSignLoading(false);
    }
  };

  const handleRouteChange = async e => {
   const res = await props.routeToChange(
      data.patient.id,
      data.patientdocument.id,
      e.target.value,
      {}
    );
    if (res) {
      let data = {...values}
      data.documentRoute.routeTo =res.data.documentRoute.routeTo;
      setValues(data);
    }
    // .then(status => {
    //   if (status) {
    //     setValues({
    //       ...values,
    //       ...values.documentRoute,
    //       routeTo: e.target.value
    //     });
    //   }
    // });
  };

  //----Delete Clinical Findings

  const handleDeletePopUp = () => {
    setHandleDeletePopup(true);
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup(false);
  };

  const handleDeleteClinicalFindings = async () => {
    setPopLoading(true);
    const del = await props.deleteClinicalFindings(
      data.patient.id,
      data.patientdocument.id
    );
    if (del) {
      await getPatientRemarks(data.patient.id)
      setPopLoading(false);
      setHandleDeletePopup(false);
      setValues({ ...values, remarks: '' });
      setIsEdit(false);
    }
  };

  //----Delete Document

  const handleDocDeletePopUp = () => {
    setHandleDocDeletePopup(true);
  };

  const handleDocCancelDelete = () => {
    setHandleDocDeletePopup(false);
  };

  const handleDeleteDocuments = async () => {
    setPopDocLoading(true);
    const del = await props.deleteDocuments(
      data.patient.id,
      data.patientdocument.id
    ); 
 
    if (del.status === 200) { 
      await getPatientRemarks(data.patient.id) 
      setPopDocLoading(false);
      setHandleDocDeletePopup(false);
      setHandleDeletePopup(false);
      setValues({ ...values, remarks: '' });
      setIsEdit(false);
      props.handleDeleteDoc(data.patient.id);
    }
  };

  const handleCancel = () => {
    props.onCancel(data?.patient?.id);
  };

  return (
    <>
      <Box
        className={classes.modalWrap}
        style={{ width: '95%', maxWidth: 1300, height: 'calc(100vh - 100px)' }}>
        <Box className={classes.modalHead}>
          <Typography variant="h2">
            {data &&
              data.patient &&
              data.patient.lastname + ' ' + data.patient.firstname}
            <span>
              {data && data.patient && data.patient.dob
                ? moment(data.patient.dob).format('MM/DD/YYYY') +
                ' (' +
                computeAge(data.patient.dob) +
                ' yrs)'
                : ''}{' '}
            </span>
          </Typography>
          <Box className={classes.rightIconGroup}>
            {/* <IconButton
              aria-label="Edit"
              onClick={() => handleDownload()}
              title="Download">
              <CloudDownloadOutlined />
            </IconButton> */}
            {/* <IconButton
            aria-label="delete"
            onClick={() => handlePrint()}
            title="Print">
            <PrintOutlined />
          </IconButton> */}

            {/* <ReactToPrint
              trigger={() => (
                <IconButton
            aria-label="delete"
            title="Print">
            <PrintOutlined />
          </IconButton>
              )}
              //content={() => props.componentRef.current}
            /> */}
            {isClinicalDocuments ? (
              ''
            ) : (
              <IconButton
                aria-label="View Medical Hx"
                onClick={() => handleOpenHxSummaryControl()}
                title="View Medical Hx">
                <GridOnOutlined />
              </IconButton>
            )}

            <IconButton
              aria-label="Close"
              onClick={() => handleCancel()}
              title="Close Window">
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.modalBody}>
          {/* <PerfectScrollbar
            style={{
              paddingRight: 15,
              marginRight: -15,
              height: 'calc(100vh - 200px)'
            }}> */}
          <Grid container style={{ padding: 0 }} xs={12}>
            <Grid
              className={classes.leftWrap}
              item
              md={isMHX ? '8' : '12'}
              xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {/* <p
                      style={{
                        padding: 50,
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        lineHeight: '33px'
                      }}>
                      Your web browser doesn't have a PDF plugin. Instead you
                      can
                      <a href={url} target="_blank">
                        &nbsp;click here to download the PDF file.
                      </a>
                    </p> */}
                   {loading ? (
         
          <CircularProgress className={classes.progress} size={40} />
        ) : null }  
                    
              { url && <iframe  style={{
                      overflow: 'hidden',
                      width: '100%',
                      height: 'calc(100vh - 280px)',
                      display: loading?'none':'block'
                    }} src={url} 
                    onLoad={hideSpinner} 
                    > 

                    </iframe>}
           
                  {/* <object
                    data={"http://www.africau.edu/images/default/sample.pdf"}
                    id="pdfContents"
                    style={{
                      overflow: 'hidden',
                      width: '100%',
                      height: 'calc(100vh - 280px)'
                    }}
                    type="application/pdf"> */}
                  {/* <p
                      style={{
                        padding: 50,
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        lineHeight: '33px'
                      }}>
                      Your web browser doesn't have a PDF plugin. Instead you
                      can
                      <a href={url} target="_blank">
                        &nbsp;click here to download the PDF file.
                      </a>
                    </p>
                  </object> */}
                </Grid>
              </Grid>
            </Grid>
            {isMHX ? (
              <Grid
                className={[classes.rightWrap, classes.scrollFix]}
                item
                md={4}
                xs={12}>
                <PerfectScrollbar className={classes.scrollHeight}>
                  <div className={classes.MHXwrap}>
                    <div className={classes.sectionHeader}>
                      Medical Hx Summary
                    </div>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Allergies
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.allergies && ptMedicalHistory.allergies.length > 0 && ptMedicalHistory.allergies
                              .sort((a, b) => b.id - a.id)
                              .map(item => (
                                <TableRow>
                                  <TableCell>{item.name} </TableCell>
                                  <TableCell>{item.reaction}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Medications
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptActiveMedication && ptActiveMedication.length > 0 && ptActiveMedication
                              .sort((a, b) => b.id - a.id)
                              .map(item => (
                                <TableRow>
                                  <TableCell>{item.name} </TableCell>
                                  <TableCell>{item.dose} </TableCell>
                                  <TableCell>{item.directions}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Clinical Findings
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptRemarks &&
                              ptRemarks.length > 0 &&
                              ptRemarks.filter(
                                c => c.isActive === true && c.remarks !== null
                              )
                                .sort((a, b) => b.id - a.id)
                                .map(item => (
                                  <TableRow>
                                    <TableCell>{item.remarks} </TableCell>
                                    <TableCell>
                                      {item.updatedDate
                                        ? moment(item.updatedDate).format(
                                          'MM/DD/YYYY'
                                        )
                                        : ''}
                                    </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Health Maintenance
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.healthMaintenance && ptMedicalHistory.healthMaintenance.length > 0 && ptMedicalHistory.healthMaintenance
                              .sort((a, b) => b.id - a.id)
                              .map(item => (
                                <TableRow>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>
                                    {' '}
                                    {item.recordedDate
                                      ? moment(item.recordedDate).format(
                                        'MM/DD/YYYY'
                                      )
                                      : ''}
                                  </TableCell>
                                  <TableCell>{item.findings}</TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>

                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Past Diagnosis
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptPastDiagnosis && ptPastDiagnosis.length > 0 &&
                              ptPastDiagnosis
                                .filter(c => c.codeType === 'diagnosis')
                                .sort((a, b) => b.id - a.id)
                                .map(item => (
                                  <TableRow>
                                    <TableCell>{item.description} </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>CPT</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptPastDiagnosis && ptPastDiagnosis.length > 0 &&
                              ptPastDiagnosis
                                .filter(c => c.codeType === 'cpt')
                                .sort((a, b) => b.id - a.id)
                                .map(item => (
                                  <TableRow>
                                    <TableCell>{item.description} </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Vaccinations
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.vaccinations && ptMedicalHistory.vaccinations.length > 0 &&
                              ptMedicalHistory.vaccinations.sort((a, b) => b.id - a.id)
                                .map(item => (
                                  <TableRow>
                                    <TableCell>{item.name} </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Social History
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.socialHistory && ptMedicalHistory.socialHistory.length > 0
                              && ptMedicalHistory.socialHistory.sort((a, b) => b.id - a.id)
                                .map(item => (
                                  <TableRow>
                                    <TableCell>{item.description} </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Family History
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.familyHistory && ptMedicalHistory.familyHistory.length > 0 &&
                              ptMedicalHistory.familyHistory.sort((a, b) => b.id - a.id)
                                .map(item => (
                                  <TableRow>
                                    <TableCell>{item.description} </TableCell>
                                  </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Advance Directives
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.advanceDirective && ptMedicalHistory.advanceDirective.length > 0 && ptMedicalHistory.advanceDirective
                              .sort((a, b) => b.id - a.id)
                              .map(item => (
                                <TableRow>
                                  <TableCell>{item.description} </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                      className={classes.accordionWrap}
                      defaultExpanded="true">
                      <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        className={classes.accordionHeader}
                        expandIcon={<ExpandMore />}
                        id="panel1a-header">
                        <Typography className={classes.heading}>
                          Additional Items
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Table className={classes.plainTable}>
                          <TableBody>
                            {ptMedicalHistory && ptMedicalHistory.additionalItems && ptMedicalHistory.additionalItems.length > 0 && ptMedicalHistory.additionalItems
                              .sort((a, b) => b.id - a.id)
                              .map(item => (
                                <TableRow>
                                  <TableCell>{item.description} </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </PerfectScrollbar>
              </Grid>
            ) : null}
          </Grid>
          <Box className={classes.docViewerFooter}>
            <Box>
              {data &&
                data.patientdocument &&
                data.patientdocument.signature ? (
                <Typography>
                  <b>Signed By:</b>

                  {data.signature.firstname +
                    ' ' +
                    data.signature.lastname +
                    ' ' +
                    data.signature.title +
                    (data.patientdocument.signature.signedbydate
                      ? ' Date:' +
                      moment(
                        data.patientdocument.signature.signedbydate
                      ).format('MM/DD/YYYY')
                      : '')}
                </Typography>
              ) : (
                <Typography>
                  <b>Uploaded By: </b>
                  {data &&
                    data.patientdocument &&
                    data.patientdocument.createBy}
                </Typography>
              )}
              {(role !== Roles.staff && isClinical) && (role !== Roles.patient) ? (
                <Typography style={{ margin: '5px 0', display: 'flex' }}>
                  <b>Clinical Findings: &nbsp;</b>

                  {isedit ? (
                    <>
                      <TextField
                        onChange={event =>
                          handleFieldChange('remarks', event.target.value)
                        }
                        onClick={() => handleAddClinicalFindings()}
                        placeholder="Enter findings"
                        style={{
                          minWidth: 400,

                          marginLeft: 10,
                          marginTop: -5
                        }}
                        value={(values && values.remarks) || ''}
                      />
                      <IconButton
                        color="primary"
                        onClick={() => handleSubmit()}
                        size="small">
                        <CheckCircleOutlineRounded />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeletePopUp()}
                        size="small">
                        <DeleteOutline style={{ color: '#DE5555' }} />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <span style={{ display: 'flex', cursor: 'pointer' }}>
                        <Tooltip
                          placement="top"
                          title={
                            values && values.remarks
                              ? values.remarks +
                              ' (' +
                              data.patientdocument.updatedBy +
                              ') ' +
                              (values.remarksUpdatedDate
                                ? moment(values.remarksUpdatedDate).format(
                                  'MM/DD/YYYY'
                                )
                                : '')
                              : 'Click to add clinical findings'
                          }>
                          <Typography
                            className={classes.summryText}
                            onClick={() => handleAddClinicalFindings()}
                            title={
                              values && values.remarks
                                ? values.remarks +
                                ' (' +
                                data.patientdocument.updatedBy +
                                ') ' +
                                (values.remarksUpdatedDate
                                  ? moment(values.remarksUpdatedDate).format(
                                    'MM/DD/YYYY'
                                  )
                                  : '')
                                : 'Click to add clinical findings'
                            }>
                            {values && values.remarks
                              ? (
                                values.remarks +
                                ' (' +
                                data.patientdocument.updatedBy +
                                ') ' +
                                (values.remarksUpdatedDate
                                  ? moment(values.remarksUpdatedDate).format(
                                    'MM/DD/YYYY'
                                  )
                                  : '')
                              ).substring(0, isMHX ? 20 : 50) + '...'
                              : 'Click to add clinical findings'}
                          </Typography>
                        </Tooltip>
                        {values && values.remarks && (
                          <IconButton
                            onClick={() => handleDeletePopUp()}
                            size="small">
                            <DeleteOutline style={{ color: '#DE5555' }} />
                          </IconButton>
                        )}
                      </span>
                    </>
                  )}
                </Typography>
              ) : (
                ''
              )}
            </Box>
            {data && (data.patientdocument && data.patientdocument.signature ? (
              ''
            ) : (
              <Box>
                {isClinical && role !== Roles.patient ? (
                  <TextField
                    className={classes.routeDropdown}
                    name="Title"
                    onChange={e => handleRouteChange(e)}
                    select
                    SelectProps={{ native: true }}
                    value={
                      values &&
                      values.documentRoute &&
                      values.documentRoute.routeTo
                    }
                    variant="outlined">
                    {pros &&
                      pros.map(item => (
                        <option value={item.value}>{item.key}</option>
                      ))}
                  </TextField>
                ) : (
                  ''
                )}
                {isClinicalDocuments ? (
                  ''
                ) : (
                  <Button
                    onClick={() => handleDocDeletePopUp()}
                    style={{
                      backgroundColor: '#ccc',
                      margin: '0 10px'
                    }}
                    variant="contained">
                    Delete
                  </Button>
                )}

                {(role !== Roles.staff && isClinical) && (role !== Roles.patient) ? (
                  <sapn>
                  {signLoading ? (
         <CircularProgress className={classes.progress} size={20} />
       ) : (  
                 <Button
                   color="primary"
                   onClick={() => handleSignDocument()}
                   variant="contained">
                   Sign
                 </Button>
       )}
                 </sapn>
                ) : (
                  ''
                )}
              </Box>
            ))}
          </Box>
          {/* </PerfectScrollbar> */}
        </Box>
      </Box>
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeletepopup}>
        <DialogTitle id="confirmation-dialog-title">
          Clinical Findings Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the clinical findings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteClinicalFindings()}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledocdeletepopup}>
        <DialogTitle id="confirmation-dialog-title">
          Documents Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popDocloading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button color="primary" onClick={() => handleDeleteDocuments()}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleDocCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = {
  downloadPDF,
  getProviders,
  addRemarks,
  deleteClinicalFindings,
  signDocument,
  routeToChange,
  deleteDocuments,
  getPatientCharts,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  loadOrders,
  getUnsignedCharts,
  getPatientInfo,
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
};
ScheduleAppo.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => ({
  role: state.profile && state.profile.role,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  providers: state.util.providers,
  patientInfo: state.soap && state.soap.patientInfo,
  ptInactiveMedication: state.soap && state.soap.ptInactiveMedication,
  ptActiveMedication: state.soap && state.soap.ptActiveMedication,
  ptMedicalHistory: state.soap && state.soap.ptMedicalHistory,
  ptPastDiagnosis: state.soap && state.soap.ptPastDiagnosis,
  ptRemarks: state.soap && state.soap.ptRemarks,
  ptInactiveDiagnosis: state.soap && state.soap.ptInactiveDiagnosis,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduleAppo))
);

