import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from 'views/AccountSettings/style';
import { withRouter } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';

import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Check, DeleteOutline } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  getUserDetails,
  deleteUser,
  deactivateUser,
  reactivateUser
} from 'services/user/action';
import Loader from '../../../../Common/components/Loader';

const View = props => { 
  const { classes, className, ehrID, search, users, ...rest } = props;

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchText, setSearchText] = useState('');
  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    deleteUserId: ''
  });
  const [handledeactivatepopup, setHandleDeactivatePopup] = useState({
    showDeactivatePopup: false,
    deactivateUserId: ''
  });
  const [loading, setLoading] = useState(true);
  const [poploading, setPopLoading] = useState(false);

  const theme = createTheme({
    overrides: {
      MUIDataTable: {
        // responsiveScroll: {
        //   maxHeight: 'none'
        //   //height: 'calc(100% - 128px)'
        // }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC!important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton: {
          fontSize: 12
        },
        fixedHeader: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {

        headerCell: {
          backgroundColor: 'transparent!important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      }
    }
  });

  useEffect(() => {
    props.getUserDetails(ehrID, page, '', rowsPerPage);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [rowsPerPage]);


  useEffect(() => {
    if (users && users.content) {


      let datatopush = [];
      const trueIcon = <Check />;

      users.content
        .sort((a, b) => a.lastname && a.lastname.localeCompare(b.lastname))
        .forEach(function (item) {
          datatopush.push({
            id: item.id,
            enabled: item.enabled,
            name:
              (item.lastname ? item.lastname : '') +
              ', ' +
              (item.firstname ? item.firstname : ''),
            username: item.username,
            provider: item.authorities.some(
              el => el.authority === 'ROLE_PROVIDER'
            )
              ? trueIcon
              : '',
            staff: item.authorities.some(el => el.authority === 'ROLE_STAFF')
              ? trueIcon
              : '',
            admin: item.authorities.some(el => el.authority === 'ROLE_ADMIN')
              ? trueIcon
              : ''
          });
        });
      setData(datatopush);
      setLoading(false);
    }

  }, [users])


  const handleChangePage = (page) => {
    setLoading(true);
    setPage(page);
    props.getUserDetails(ehrID, page, '', rowsPerPage);
    window.scrollTo(0, 0);
  };
  const handleChangeRowsPerPage = event => {

    setLoading(true);
    const rowsPerPage = event;
    setRowsPerPage(rowsPerPage);
    props.getUserDetails(ehrID, page, '', rowsPerPage);
    window.scrollTo(0, 0);
  };


  const handleSearch = async (searchText, isClear) => {
    setSearchText(searchText);
    if ((searchText && searchText !== '') || isClear) {
      await props.getUserDetails(ehrID, page, searchText, rowsPerPage);
    }
  };


  const columns = [
    {
      name: 'name',
      label: 'Name'
    },
    {
      name: 'username',
      label: 'Username'
    },
    {
      name: 'provider',
      label: 'Provider',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        })
      }
    },
    {
      name: 'staff',
      label: 'Staff',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        })
      }
    },
    {
      name: 'admin',
      label: 'Admin',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        })
      }
    },
    {
      name: 'id',
      label: 'Status',
      options: {
        sort: false,
        customBodyRender: value => {
          const enabled = data.filter(c => c.id === value)[0].enabled;
          return enabled ? (
            <Tooltip title="Click to deactivate user">
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 400,
                  letterSpacing: '0.01071em',
                  background: 'transparent'
                }}
                className={classes.textGreen}
                size="small"
                onClick={() => handleDeactivate(value)}>
                Active
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Click to re-activate a deactivated user">
              <Button
                style={{
                  textTransform: 'capitalize',
                  fontWeight: 400,
                  letterSpacing: '0.01071em',
                  background: 'transparent'
                }}
                className={classes.textGrey}
                size="small"
                onClick={() => handleReactivateUser(value)}>
                Inactive
              </Button>
            </Tooltip>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: {
            textAlign: 'center'
          }
        }),
        customBodyRender: value => {
          return (
            <Tooltip title="Delete">
              <IconButton size="small" onClick={() => handleDelete(value)}>
                <DeleteOutline style={{ color: '#ED2024' }} />
              </IconButton>
            </Tooltip>
          );
        }
      }
    }
  ];

  const options = {
    filterType: 'select',

    elevation: 0,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 20, 50, 100],
    download: false,
    print: false,
    selectableRows: false, // <===== will turn off checkboxes in rows
    filter: false,
    pagination: users && users.totalElements > 20,
    count: users && users.totalElements,
    page: page,
    viewColumns: false,
    searchPlaceholder: 'Search for users',
    search: true,
    serverSide: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          return;
        case 'changeRowsPerPage':
          handleChangeRowsPerPage(tableState.rowsPerPage);
          return;

        case 'search':

          handleSearch(tableState.searchText);
          return;
        default:
          return;
      }
    },
    searchText: searchText,
    onSearchClose: action => {
      handleSearch('', true);
    }
  };
  ///For deleteing a user
  const handleDelete = userID => {
    setHandleDeletePopup({ showDeletePopup: true, deleteUserId: userID });
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup({ showDeletePopup: false });
  };

  const handleDeleteUser = deleteUserId => {
    if (deleteUserId > 0) {
      props.deleteUser(ehrID, deleteUserId);
      setHandleDeletePopup({ showDeletePopup: false });
    }
  };

  ///For deactivating a user

  const handleDeactivate = userID => {
    setHandleDeactivatePopup({
      showDeactivatePopup: true,
      deactivateUserId: userID
    });
  };

  const handleCancelDeactivate = () => {
    setHandleDeactivatePopup({ showDeactivatePopup: false });
  };

  const handleDeactivateUser = async deactivateUserId => {
    if (deactivateUserId > 0) {
      setPopLoading(true);
      const res = await props.deactivateUser(ehrID, deactivateUserId);
      if (res) {
        let alldatas = data;
        const index = data.findIndex(c => c.id === deactivateUserId);
        if (index != -1) {
          let selData = data[index];
          selData.enabled = false;
          alldatas[index] = selData;
          setData(alldatas);
        }
      }
    }
    setPopLoading(false);
    setHandleDeactivatePopup({ showDeactivatePopup: false });
  };

  ///For reactivating a user
  const handleReactivateUser = async reactivateUserId => {
    if (reactivateUserId > 0) {
      setPopLoading(true);
      const res = await props.reactivateUser(ehrID, reactivateUserId);
      if (res) {
        let alldatas = data;
        const index = data.findIndex(c => c.id === reactivateUserId);
        if (index != -1) {
          let selData = data[index];
          selData.enabled = true;
          alldatas[index] = selData;
          setData(alldatas);
        }
      }
      setPopLoading(false);
    }
  };

  return (
    <Card {...rest} className={clsx(classes.cardRoot, className)}>
      <CardContent style={{ margin: -16, }}>
        <Grid container spacing={0} >
          <Grid item xs={12}  >
            {loading ? (
              <Loader type="table" count={15} />

            ) : (
              <MuiThemeProvider theme={theme}>
                <MUIDataTable
                  title={'View/Manage Providers and Staff'}
                  data={data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            )}
          </Grid>
        </Grid>
      </CardContent>

      {/* for delete user */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={handledeletepopup.showDeletePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Account Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              onClick={() => handleDeleteUser(handledeletepopup.deleteUserId)}
              color="primary">
              Yes
            </Button>
          )}
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* for de-activate user */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={handledeactivatepopup.showDeactivatePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Account Settings
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to de-activate the user account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              onClick={() =>
                handleDeactivateUser(handledeactivatepopup.deactivateUserId)
              }
              color="primary">
              Yes
            </Button>
          )}
          <Button onClick={handleCancelDeactivate} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

View.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getUserDetails,
  deleteUser,
  deactivateUser,
  reactivateUser
};
const mapStateToProps = state => ({
  titles: state.util.titles,
  ehrID: state.profile && state.profile.ehrID,
  search: state.user && state.user.search,
  users: state.user && state.user.users
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(View))
);
