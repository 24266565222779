import {
  Box, Card,
  CardContent, CircularProgress, Divider,
  Grid, IconButton, Modal, Popover, Typography
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  ChevronLeftOutlined,
  ChevronRightOutlined,
  CloseOutlined, GridOnOutlined
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  clearPtDoc,
  getCaseFiles,
  getPatientDocuments,
  getDocInfo,getDocumentCategories,
  getPatientMiscDocuments,
  getRecaps,
  getChartViewSoapandPatientDetails,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
} from 'services/home/action';
import { loadOrders } from 'services/orders/action';
import {
  clearSoap,
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks, resetValue
} from 'services/soap/action';
import { getLocations } from 'services/util/action';
import { computeAge } from 'util/helper';
import MessageBox from 'util/messageBox';
import {
  DataHx,
  DocViewer,
  PatientDetails,
  PatientSearch
} from 'views/Common/components';
import styles from '../../../style';
import { EncounterSUmmary } from '../../Soap/components';
const Details = props => {
  const {
    classes,
    className,
    locations,
    ehrID,
    userID,
    profile,
    casefiles,
    documentInfo,
    miscDocumentInfo,
    pastSoapNoteDetails,
    patientInfo,
    recapSoapDetails,
    categories,
    resetValue, clearSoap, clearPtDoc,
    getPatientActiveMedications, 
    getPatientInactiveDiagnosis, 
    getPatientInactiveMedication,
    getPatientMedicalHistory,
    getPatientPastDiagnosis,
    getPatientRemarks,
    getPatientAllClinicalDocuments,
    getPatientAllMiscellaneousDocuments,
    getPatientCharts,
    ...rest
  } = props;
  let [isClinical, setIsClinical] = useState(false);
  const [patChartDetails, setPatChartDetails] = useState(null);
  const [data, setData] = useState([]);
  const [dataHx, setDataHX] = useState([]);
  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [miscDataDocViewer, setMiscDataDocViewer] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtervalues, setFilterValues] = useState(-1);
   const [total, setTotal] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useState({ page: 0, count: 0 });
  const [isDetails, setIsDetails] = useState({ show: false, patient: null });

  const [miscDoc,setmiscDoc]=useState([]);
  const [doc,setDoc]= useState([]);

  const [openDoc, setOpenDoc] = useState(false);
  const [openViewSoap, setOpenViewSoap] = useState({
    open: false,
    patientId: 0
  });

  useEffect(() => {
    props.getDocumentCategories(ehrID);
  }, []);

 

  const handleDeleteDoc = (ptid) => { 
    setOpenDoc(false);  
    refreshDoc(ptid)
    setDataDocViewer([])
   };



  useEffect(() => {
    props.getLocations(ehrID);
    props.getCaseFiles(ehrID, filtervalues, state.page);
  }, []);

  useEffect(() => {
    if (casefiles !== undefined) {
      setState({ ...state, count: casefiles.totalElements });
      let datatopush = [];
      casefiles &&
        casefiles.result.forEach(function (item) {
          datatopush.push({
            patientName:
              (item.patient.lastname ? item.patient.lastname : '') +
              ', ' +
              (item.patient.firstname ? item.patient.firstname : '') +
              ' ' +
              (item.patient.middleinitial ? item.patient.middleinitial : ''),
            patientId: item.patient.id,
            dob: item.patient.dob
              ? moment(item.patient.dob).format('MM/DD/YYYY') +
              ' (' +
              computeAge(item.patient.dob) +
              ' yrs)'
              : '',
            gender: item.patient.gender,
            updatedDate: moment(item.updatedDate).format('MM/DD/YYYY'),
            page: item.page.content,
            patient: item
          });
        });
      setData(datatopush);
      setLoading(false);
    }
  }, [casefiles]);

  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  useEffect(() => {
    if (miscDocumentInfo !== undefined) {
      setMiscDataDocViewer(miscDocumentInfo);
    }
  }, [miscDocumentInfo]);

  const handleOpenDoc = async (
    patientId,
    resourceID,
    routeTo,
    isClinical,
    patient
  ) => { 
    setIsClinical(isClinical);
    props.getPatientDocuments(
      ehrID,
      patientId,
      resourceID,
      routeTo,
      isClinical,
      patient
    );
    const doc = await props.getDocInfo(patientId,resourceID)
    if(doc){
      setDoc(doc);
      setOpenDoc(true);
    }
  };

  const handleCloseDoc = () => {
    setOpenDoc(false); 
    setDataDocViewer([]) 
};

const refreshDoc = (ptid) =>{
  clearPtDoc()
  getPatientAllClinicalDocuments(ptid, ehrID)
  getPatientAllMiscellaneousDocuments(ptid, ehrID)
  props.loadOrders(ptid); 
 }

  const handleOpenSoap = async (patientId) => {
    props.clearSoap(); 
    await props.history.push({
      pathname: '/default',
      state: { patientId: patientId }
    });
  };

  const handleOpenMiscDoc = async (
    ehrID,
    patientId,
    resourceID,
    item,
    isClinical
  ) => {
    setIsClinical(isClinical);
    setOpenDoc(true);
    const miscDoc = await props.getDocInfo(patientId, resourceID)
    setmiscDoc(miscDoc)
    await props.getPatientMiscDocuments(ehrID, patientId, resourceID, item);
  };

  const handleOpenViewSoap = async (patientId, resourceID) => {
    const res = await props.getChartViewSoapandPatientDetails(
      ehrID,
      patientId,
      userID,
      resourceID
    );
    if (res.status) {
      setOpenViewSoap({
        open: true,
        patientId: patientId,
        soap: res.chartSoapPatientDetails.soap,
        patientInfo: res.chartSoapPatientDetails.patientInfo
      });
      getPatientActiveMedications(patientId)
      getPatientInactiveDiagnosis(patientId)
      getPatientInactiveMedication(patientId)
      getPatientMedicalHistory(patientId)
      getPatientPastDiagnosis(patientId)
      getPatientRemarks(patientId)
    }
  };

  const handleCloseViewSoap = () => {
    setOpenViewSoap({ open: false });
  };

  const handleClick = (event, patId) => { 
    setAnchorEl(event.currentTarget);
    const dtHx = data.filter(c => c.patientId === patId);
    setDataHX(dtHx[0].patient);
  };

  const handleDataHxChangePage = async (casefileId, page) => {
    const res = await props.getRecaps(ehrID, casefileId, page);
    if (res) {
      let newstate = { ...dataHx };
      newstate.page = res;
      setDataHX(newstate);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlegoBack = () => {
    clearPtDoc();
    setIsDetails({ ...isDetails, show: false });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = createTheme({
    overrides: {
      MuiButton: {
        containedSizeSmall: {
          backgroundColor: '#03A484',
          color: 'white',
          fontSize: 10,
          boxShadow: 'none',
          textTransform: 'capitalize',
          whiteSpace: 'nowrap',
          '&:hover': {
            backgroundColor: '#13b998',
            boxShadow: 'none'
          }
        }
      },
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: 'none'
          //height: 'calc(100% - 128px)'
        }
      },
      MuiTableCell: {
        root: {
          padding: '5px 10px',
          fontSize: 12
        }
      },
      MuiToolbar: {
        root: {
          minHeight: '48px!important'
        }
      },
      MUIDataTableHeadRow: {
        root: {
          backgroundColor: '#EFF5FC !important'
        }
      },
      MUIDataTableHeadCell: {
        toolButton: {
          fontSize: 12
        },
        fixedHeader: {
          backgroundColor: 'transparent !important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: 'transparent !important',
          height: 45,
          borderTop: '1px solid #D4E5F9',
          borderBottom: '1px solid #D4E5F9'
        }
      },
      MUIDataTableToolbar: {
        icon: {
          transform: 'scale(.8)'
        },
        titleText: {
          fontSize: '16px!important',
          fontWeight: '600!important',
          fontFamily: 'Lato!important'
        }
      },
      MUIDataTableToolbarSelect: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'rgba(255,0,0,.2)',
          boxShadow: 'none',
          borderRadius: '0px!important'
        }
      },
      MuiTableRow: {
        root: { '&$hover:hover': { backgroundColor: '#EFF5FC' } }
      },
      MuiTablePagination: {
        caption: {
          fontSize: 12
        }
      },
      MuiOutlinedInput: {
        inputSelect: {
          padding: 0,
          padding: '5px 10px',
          fontSize: 12,
          height: 18,
          borderCOlor: 'red'
        }
      },
      MuiTableSortLabel: {
        icon: {
          height: 16,
          width: 16,
          color: '#5698d5!important'
        }
      }
    }
  });

  const columns = [
    {
      name: 'patientId',
      options: {
        display: 'excluded'
      }
    },
    {
      name: 'patientName',
      label: 'Patient Name',
      options: {
        setCellProps: () => ({
          style: { width: '40%' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handlePatientDetailsById(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'dob',
      label: 'Date of Birth',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' }
        }),
        customBodyRender: (value, tableMeta) => {
          return (
            <span
              onClick={() => handlePatientDetailsById(tableMeta.rowData[0])}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },
    {
      name: 'patientId',
      label: 'MR#',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: value => {
          return (
            <span
              onClick={() => handlePatientDetailsById(value)}
              style={{ cursor: 'pointer' }}>
              {value}
            </span>
          );
        }
      }
    },

    {
      name: 'patientId',
      label: 'Data Hx',
      options: {
        sort: false,
        setCellProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        setCellHeaderProps: () => ({
          style: { width: '20%', textAlign: 'center' }
        }),
        customBodyRender: value => {
          return (
            // <Tooltip title="Delete">
            <IconButton
              className={classes.tableIcon}
              color="secondary"
              component="span"
              onClick={e => handleClick(e, value)}
              size="small">
              <GridOnOutlined />
            </IconButton>
            // </Tooltip>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    responsive: 'scroll',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [],
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    pagination: state.count > 10,
    selectableRows: false,
    serverSide: true,
    count: state.count,
    page: state.page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          handleChangePage(tableState.page);
          break;
      }
    }
    // onRowClick: (rowData) =>
    //   handlePatientDetailsById(rowData[0])
  };

  const handleChangePage = async page => {
    setLoading(true);
    setState({ ...state, page: page });
    await props.getCaseFiles(ehrID, filtervalues, page);
  };

  //------get patients from search----
  const handleChoosePatient = async patient => {
    setIsDetails({ show: true, patient: patient });
  };

  //------get patients for on click----
  const handlePatientDetailsById = async(id) => { 
    if (casefiles) {
      const patient = casefiles.result.filter(
        c => c.patient && c.patient.id === id
      );
      if (patient.length > 0) {
        setIsDetails({ show: true, patient: patient[0].patient });
      }
    }
      clearPtDoc()
      getPatientAllClinicalDocuments(id, ehrID)
      getPatientAllMiscellaneousDocuments(id, ehrID)
      props.loadOrders(id);
      const res = await getPatientCharts(id, 0)
      setTotal(res.totalElements) 
  };

  const handleCopyfromRecap = (soap, pastSoapNoteDetails) => {
    props.clearSoap();
    props.history.push({
      pathname: '/default',
      state: {
        soap: soap,
        pastSoapNoteDetails: pastSoapNoteDetails,
        isAction: 'Copy'
      }
    });
  };

  const handleEditfromRecap = (soap, pastSoapNoteDetails) => {
    props.clearSoap();
    props.history.push({
      pathname: '/default',
      state: {
        soap: soap,
        pastSoapNoteDetails: pastSoapNoteDetails,
        isAction: 'Edit'
      }
    });
  };

  //#region success
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });

  const showSuccessMsg = msg => {
    setSuccMsg({ succ: true, succMsg: msg });
  };


  const handleCloseSuccess = () => {
    setSuccMsg({ succ: false });
  };

  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      <Grid container>
        <Grid className={classes.cardHeader} item lg={6} sm={4} xs={12}>
          <Typography variant="h2">
            {(profile && profile.firstname ? profile.firstname : '') +
              ' ' +
              (profile && profile.lastname ? profile.lastname : ' ') +
              ' ' +
              (profile && profile.title ? profile.title : '')}
          </Typography>
        </Grid>

        <Grid
          className={classes.cardHeader}
          item
          lg={6}
          sm={8}
          style={{ justifyContent: 'flex-end' }}
          xs={12}>
          <PatientSearch
            fromHomeSearch
            fullWidth
            handleChoosePatient={handleChoosePatient}
          />
        </Grid>
      </Grid>
      <Divider />
      <CardContent>
        {!isDetails.show ? (
          <>
            {loading ? (
              <div
                className={classes.progressWrapper}
                style={{
                  position: 'absolute',
                  left: 0,
                  right: 0,
                  display: 'flex',
                  bottom: 0,
                  top: 0,
                  alignItems: 'flex-start',
                  paddingTop: 120,
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0,.2)'
                }}>
                <CircularProgress style={{ height: 30, width: 30 }} />
              </div>
            ) : (
              <MuiThemeProvider theme={theme}>
                <div className={classes.fullWidthTable}>
                  <MUIDataTable
                    columns={columns}
                    data={data}
                    options={options}
                    responsive="scrollMaxHeight"
                  />
                </div>
              </MuiThemeProvider>
            )}
          </>
        ) : (
          <PatientDetails
            goBack={handlegoBack}
            handleOpenDoc={handleOpenDoc}
            handleOpenMiscDoc={handleOpenMiscDoc}
            handleOpenViewSoap={handleOpenViewSoap}
            pastSoapNotes={false}
            isClinical={isClinical}
            patient={isDetails.patient}
          />
        )}
      </CardContent>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        elevation={4}
        id={id}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <PerfectScrollbar>
          <DataHx
            dataHx={dataHx}
            handleOpenDoc={handleOpenDoc}
            handleOpenMiscDoc={handleOpenMiscDoc}
            handleOpenViewSoap={handleOpenViewSoap}
            handleOpenSoap={handleOpenSoap}
          />
        </PerfectScrollbar>
        <div className={classes.popFoot}>
          <Typography variant="caption">
            The patient has {dataHx && dataHx.page && dataHx.page.totalElements}{' '}
            recap events
          </Typography>
          {dataHx && dataHx.page && dataHx.page.totalPages > 1 && (
            <span>
              <IconButton
                disabled={dataHx.page.first === true}
                onClick={() =>
                  handleDataHxChangePage(dataHx.id, dataHx.page.number - 1)
                }
                size="small">
                <ChevronLeftOutlined />
              </IconButton>
              <IconButton
                disabled={dataHx.page.last === true}
                onClick={() =>
                  handleDataHxChangePage(dataHx.id, dataHx.page.number + 1)
                }
                size="small">
                <ChevronRightOutlined />
              </IconButton>
            </span>
          )}
        </div>
      </Popover>

      <Modal onClose={handleCloseDoc} open={openDoc}>
        <DocViewer
          dataDocViewer={dataDocViewer}
          miscDataDocViewer={miscDataDocViewer}
          isClinical={isClinical}
          onCancel={handleCloseDoc}
          doc={doc}
          miscDoc={miscDoc}
          handleDeleteDoc={handleDeleteDoc}
          
        />
      </Modal>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseViewSoap}
        open={openViewSoap.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="h2">
              {openViewSoap && openViewSoap.soap && openViewSoap.soap.visitdate
                ? 'Encounter Date :' +
                moment(openViewSoap.soap.visitdate).format('MM/DD/YYYY')
                : ''}
            </Typography>
            <span>
              <IconButton aria-label="delete" onClick={handleCloseViewSoap}>
                <CloseOutlined />
              </IconButton>
            </span>
          </Box>
          <EncounterSUmmary
            handleCopyPast={handleCopyfromRecap}
            handleEditPast={handleEditfromRecap}
            // isHistory={isHistory}
            onCancel={handleCloseViewSoap}
            patientInfo={openViewSoap && openViewSoap.patientInfo}
            // setHistory={setHistory}
            showSuccessMsg={showSuccessMsg}
            soap={openViewSoap && openViewSoap.soap}
          />
        </Box>
      </Modal>
    </Card>
  );
};

Details.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getLocations,
  getCaseFiles,
  getPatientDocuments,
  getPatientMiscDocuments,
  getRecaps,
  getChartViewSoapandPatientDetails, loadOrders,
  resetValue, clearSoap, clearPtDoc,
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
  getPatientAllClinicalDocuments,
  getPatientAllMiscellaneousDocuments,
  getPatientCharts,
  getDocInfo,
  getDocumentCategories
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  profile: state && state.profile,
  casefiles: state.home && state.home.list,
  documentInfo: state.home && state.home.documentInfo,
  categories: state.home && state.home.categories,
  recapSoapDetails: state.home && state.home.recapSoapDetails,
  miscDocumentInfo: state.home && state.home.miscDocumentInfo
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true
  })(withStyles(styles)(Details))
);
