// import React from 'react';
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from 'react-router-dom';
import { isAlive, logout } from '../../../services/profile/action';
import modalVector from '../../../assets/images/modalvector.png'
import circleTick from '../../../assets/images/charm_circle_tick.svg'
import speechBubble from '../../../assets/images/speech_bubble.svg'
import Logo from '../../../assets/images/logo.png'
import paymentFailed from '../../../assets/images/paymentfailed.svg'
import exclamation from '../../../assets/images/exclamation.svg'
import { useHistory } from 'react-router-dom';
import { LicenseType } from "util/enum";
import { updateAnnualLicense } from "../../../services/profile/action";
import { generateAccessToken } from "../PayPal/api/token";
import { Link } from '@material-ui/core';
import moment from 'moment';
import { PaymentForm } from "../PayPal/components/PaymentForm";
import { sandboxURLs, liveURLs } from "../PayPal/api/constants";


const PaymentModal = props => {
    const { ehrID, userID, logout, expired, updateAnnualLicense, user } = props;
    const history = useHistory();
    const [value, setValue] = useState({
        orderId: '',
        transactionId: '',
        transactionDate: '',
        transactionStatus: false,
        transactionAmount: '',
        licenseType: '',
        licenseStatus: false,
        expirydate: '',
    });
    const [res, setRes] = useState(true);
    const initialState = {
        values: {
            cardnumber: "",
            CSC: "",
            month: "",
            year: "",
        },
        errors: {
            cardnumber: "",
            CSC: "",
            month: "",
            year: "",
        }
    }

    const [values, setValues] = useState(initialState.values);
    const [errors, setErrors] = useState(initialState.errors);
    const [validation, setValidation] = useState(false);
    const [clientToken, setClientToken] = useState();
    const clientID = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const [orderID, setOrderID] = useState();

    useEffect(() => {
        fetchData();
        async function fetchData() {
            const accessToken = await generateAccessToken();
            const response = await fetch(liveURLs.CLIENT_TOKEN_URL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken
                },
            });
            const data = await response.json();
            const { client_token } = data;
            setClientToken(client_token);
        }
    }, [])

    // const setResponse = (orderId, transactionStatus) => {
    //     if (orderId) {
    //         handleRenewLicense(orderId, transactionStatus);
    //     }
    // }

    const handleRenewLicense = async (orderId, transactionStatus) => {
        const newState = { ...value }
        newState.licenseType = LicenseType.annuallicense;
        newState.licenseStatus = true;
        newState.transactionDate = moment().format('YYYY-MM-DD');
        newState.transactionStatus = transactionStatus;
        newState.transactionAmount = '299';
        newState.orderId = orderId;
        setValue(newState);
        const result = await updateAnnualLicense(ehrID, userID, newState);
        if (result) {
            openPaymentSuccessful();
            await isAlive()
        }
        else {
            openPaymentFailure();
        }
    }

    const setValidationError = (status) => {
        setValidation(status);
    }

    const handleCancel = () => {
        setValidationError(true);
        window.$(`#paymentModal_payment`).modal("hide");
         
    };
    const handleCancelSuccess = () => {
        window.$(`#paymentModal_successful`).modal("hide");
    };

    const handleCancelFailure = () => {
        window.$(`#paymentModal_failure`).modal("hide");
    };

    const handleLogOut = () => {
        logout(ehrID);
        window.$(`#paymentModal_expired`).modal("hide");
        history.push('/sign-in');
    }

    const openPayment = async () => {
        window.$(`#paymentModal_expired`).modal("hide");
        window.$(`#paymentModal_payment`).modal("show");
    }

    const openPaymentSuccessful = () => {
        window.$(`#paymentModal_payment`).modal("hide");
        window.$(`#paymentModal_successful`).modal("show");
    }

    const openPaymentExpired = () => {
        setValidationError(true);
        window.$(`#paymentModal_payment`).modal("hide");
        window.$(`#paymentModal_expired`).modal("show");
    }

    const openPaymentFailure = () => {
        window.$(`#paymentModal_payment`).modal("hide");
        window.$(`#paymentModal_failure`).modal("show");
    }

    const openPaymentFromRetry = () => {
        window.$(`#paymentModal_failure`).modal("hide");
        window.$(`#paymentModal_payment`).modal("show");
    }

    const renderPaymentExpired = () => {
        return <div class="upgrade_modal">
            <div
                class="modal fade"
                id={`paymentModal_expired`}
                tabindex="-1"
                aria-labelledby="paymentModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="head_wrap">
                                <span
                                    class="iconify icon_upgrade"
                                    data-icon="carbon:upgrade"
                                ></span>
                                <h6>{user?.licensetype === 3 ? "Your Annual license has expired!" : "Your 90-days trial has expired!"}</h6>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modal_body_wrap">
                                <img
                                    src={modalVector}
                                    alt=""

                                    class="img-fluid vector_time"
                                />
                                <h6>{user?.licensetype === 3 ? "Your Annual License Has Expired" : "Your Free Trial Has Expired"}</h6>
                                <p>
                                    Please upgrade your annual license to keep using the EHRZip
                                    platform
                                </p>
                                <div><button type="button" onClick={openPayment} class="btn_amount">$299/<i>year</i></button></div>
                                <div><Link component={RouterLink} class="tag_logout" onClick={() => handleLogOut()}>Logout</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderPayment = () => {
        return <div class="upgrade_modal paymentmodal">
            <div class="modal fade" id={`paymentModal_payment`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div class="paymentwrapper">
                                <div class="headerlogo">
                                    <img src={Logo} alt="" />
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={expired ? openPaymentExpired : handleCancel}

                                    ></button>
                                </div>

                                {clientToken && (
                                    <PaymentForm clientToken={clientToken} clientID={clientID} user={user} handleRenewLicense={handleRenewLicense} validation={validation} setValidationError={setValidationError} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderPaymentSuccess = () => {
        return <div class="upgrade_modal successfulmodal">
            <div class="modal fade" id={`paymentModal_successful`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="head_wrap">
                                <img src={circleTick} alt="" />
                                <h6>Payment Successful!</h6>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modal_body_wrap">
                                <img src={speechBubble} alt="" class="img-fluid vector_time" />
                                <h6>Thank you for renewing your annual license</h6>
                                <p>
                                    Your payment of $299 has been successful
                                </p>
                                <div><button type="button" class="btn_amount" onClick={() => handleCancelSuccess()}>Done</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    const renderPaymentFailure = () => {
        return <div class="upgrade_modal failure">
            <div
                class="modal fade"
                id={`paymentModal_failure`}
                tabindex="-1"
                aria-labelledby="paymentModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="head_wrap">
                                <img src={exclamation} alt="" />
                                <h6>Payment Failed!</h6>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={expired ? openPaymentFromRetry : handleCancel}

                                ></button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="modal_body_wrap">
                                <img src={paymentFailed} alt="" class="img-fluid vector_time" />
                                <h6>We couldn't process your payment</h6>
                                <p>
                                    Your payment of $299 has been unsuccessful
                                </p>
                                <div><button type="button" class="btn_amount" onClick={openPaymentFromRetry}>Retry</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    return (
        <div>
            {renderPaymentExpired()}
            {renderPayment()}
            {renderPaymentSuccess()}
            {renderPaymentFailure()}
        </div>
    );
};

const mapStateToProps = (state) => ({
    ehrID: state.profile && state.profile.ehrID,
    user: state && state.profile,
});

const mapDispatchToProps = {
    logout,
    updateAnnualLicense
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);









