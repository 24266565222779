import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import { Grid, CircularProgress } from '@material-ui/core';
import styles from './style';
import { PatientList } from './components';
import { connect } from 'react-redux';
import {
  getAllPatforPtMessages
} from 'services/ptmessages/action';
import moment from 'moment';
const Default = props => {
  const { classes, ehrID, userID, allPatforPtMessages } = props;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData =()=>{
      props.getAllPatforPtMessages(ehrID, userID);
  }

  useEffect(() => {
    if (props.allPatforPtMessages !== undefined && props.allPatforPtMessages.length >0 ) {
      let datatopush = [];
      allPatforPtMessages.forEach(function(item) {
        datatopush.push({
          id:item.id,
          updatedDate: (item.updatedDate
            ? moment(item.updatedDate).format('MM/DD/YYYY')
            : '')+' ,'+(item.updatedDate
            ? moment(item.updatedDate).format('hh:MM A')
            : ''),
          message: item.message,
          providerResponse: item.providerResponse,
          status:item.status,
          item:item
          
        } );
       
        
      });
      setData(datatopush);
      setLoading(false); 
    }
    else{
      setLoading(false)
    }
 }, [props.allPatforPtMessages]);



  return (
    <div className={classes.root}>
      <Grid className={classes.contentWrap} container xs={12}>
        {loading ? (
          <div
            className={classes.progressWrapper}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              display: 'flex',
              bottom: 0,
              top: 0,
              alignItems: 'flex-start',
              paddingTop: 120,
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0,.2)'
            }}
          >
            <CircularProgress style={{ height: 30, width: 30 }} />
          </div>
        ) : (
          <PatientList data={data} fetchData={fetchData} />
        )}
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  getAllPatforPtMessages,

};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPatforPtMessages:
    state.ptmessages &&
    state.ptmessages.allPatforPtMessages &&
    state.ptmessages.allPatforPtMessages.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Default))
);
