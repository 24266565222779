import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from '../style';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import {
  TextField,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import {
  AttachFileOutlined,
  ArrowBackIosOutlined,
  DeleteOutlineOutlined
} from '@material-ui/icons/';
import { connect } from 'react-redux';
import { sendResponse } from 'services/ptmessages/action';
import { loadOrders, downloadPDF } from 'services/orders/action';
import moment from 'moment';
import validate from 'validate.js';
import schema from './schemaStaffResponse';
import clsx from 'clsx';
const StaffResponse = props => {
  const { classes, responsedata, ehrID, userID, allorders } = props;
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState({});
  const [poploading, setPopLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [handledeletepopup, setHandleDeletePopup] = useState({
    showDeletePopup: false,
    deleteOrder: {}
  });
  const [valid, setValid] = useState({
    isValid: false,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    if (responsedata !== undefined) {
      setData(responsedata);
      if (
        responsedata.providerResponse !== '' &&
        responsedata.providerResponse !== undefined &&
        responsedata.providerResponse !== null
      ) {
        setValid({ ...valid, isValid: true });
      }
      props.loadOrders(responsedata.patientId);
    }
  }, [responsedata]);

  useEffect(() => {
    if (allorders !== undefined) {
      let allItems = [];
      allorders.content.map(item => {
        item.checked =
          responsedata &&
          responsedata.ptMessageOrdersList &&
          responsedata.ptMessageOrdersList.filter(c => c.orderHxId === item.id)
            .length > 0;
        allItems.push(item);
      });
      setOrders(allItems);
    }
  }, [allorders]);

  const setEdit = () => {
   
    setIsEdit(!isEdit);
  };

  const setEditAttach = () => {
    setIsEdit(!isEdit);
  };

  const setEditBack = () => {
    setIsEdit(!isEdit);
    if (allorders !== undefined) {
      let allItems = [];
      allorders.content.map(item => {
        item.checked =
          responsedata &&
          responsedata.ptMessageOrdersList &&
          responsedata.ptMessageOrdersList.filter(c => c.orderHxId === item.id)
            .length > 0;
        allItems.push(item);
      });
      setOrders(allItems);
    }
  };

  const handleChange = (field, newValue) => {
    setData({
      ...data,
      providerResponse: newValue,
      providerResponseBy: userID
    });
    const newState = { ...valid };
    newState[field] = newValue;
    newState.touched[field] = true;
    const errors = validate(data, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValid(newState);
  };

  const handleSend = async staffMessage => {
    setPopLoading(true);
    let orderHxIds = [];
    orders
      .filter(c => c.checked === true)
      .map(o => {
        orderHxIds = orderHxIds.concat(o.id);
      });

    const res = await props.sendResponse(
      ehrID,
      staffMessage.patientId,
      data,
      orderHxIds
    );
    if (res) {
      props.showSuccessMsg();
    }
    setPopLoading(false);
  };

  const handleCheckboxChange = (event, idx) => {
    let ordersdata = [...orders];
    if (event.target.checked) {
      ordersdata[idx].checked = true;
    } else {
      ordersdata[idx].checked = false;
    }
    setOrders(ordersdata);
  };

  const handleSelectAll = event => {
    let selectAllOrders = orders.map(t => ({
      ...t,
      checked: event.target.checked
    }));
    setOrders(selectAllOrders);
  };

  const handleDelete = id => {
    setHandleDeletePopup({
      showDeletePopup: true,
      deleteOrderId: id
    });
  };

  const handleCancelDelete = () => {
    setHandleDeletePopup({ showDeletePopup: false });
  };

  const handleRemove = () => {
    let activeorders = [...orders];
    let order = activeorders.filter(
      c => c.id === handledeletepopup.deleteOrderId
    );
    order[0].checked = false;
    setOrders(activeorders);

    handleCancelDelete();
  };

  const handleView = async (type, providerId, orderHx) => {
    var orderIds;
    if (type === 'Prescription') {
      orderIds = orderHx.medIds;
    }
    if (type === 'Lab Order') {
      orderIds = orderHx.labOrderIds;
    }
    if (type === 'Imaging Order') {
      orderIds = orderHx.imgOrderIds;
    }
    if (type === 'Physician Order') {
      orderIds = orderHx.phyOrderIds;
    }

    props
      .downloadPDF(ehrID, responsedata.patientId, providerId, type, orderIds)
      .then(res => {
        if (res.status) {
          let file = new Blob([res.result.data], { type: 'application/pdf' });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        }
      });
  };

  return (
    <React.Fragment>
      {!isEdit ? (
        <React.Fragment>
          <Box className={classes.modalBody}>
            <TextField
              placeholder="Write a message here..."
              multiline
              rows="6"
              variant="outlined"
              style={{ width: '100%', marginBottom: 10 }}
              onChange={event =>
                handleChange('providerResponse', event.target.value)
              }
              value={data.providerResponse}
              //  error={hasError('providerResponse')}
              // helperText={
              //   hasError('providerResponse')
              //     ? valid.errors.providerResponse[0]
              //     : null
              // }
            />
          </Box>
          {orders &&
            orders.length > 0 &&
            orders.filter(c => c.checked === true).length > 0 && (
              <PerfectScrollbar>
                <div style={{ maxHeight: 160 }}>
                  <Table
                    className={classes.generalTable}
                    style={{ marginBottom: 10 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell style={{ minWidth: 122 }}>Type</TableCell>
                        <TableCell style={{ minWidth: 122 }}>
                          Phycisian
                        </TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orders.map(selectedorder => {
                        if (selectedorder.checked) {
                          return (
                            <TableRow>
                              <TableCell>
                                {selectedorder.createdDate
                                  ? moment(selectedorder.createdDate).format(
                                      'MM/DD/YYYY'
                                    )
                                  : null}
                              </TableCell>
                              <TableCell noWrap="true">
                                <div className={classes.ellipsisMode}>
                                  <Typography>{selectedorder.type}</Typography>
                                </div>
                              </TableCell>
                              <TableCell noWrap="true">
                                <div className={classes.ellipsisMode}>
                                  <Typography>
                                    {selectedorder.providerName}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell noWrap="true">
                                <div className={classes.ellipsisMode}>
                                  <Typography>{selectedorder.recap}</Typography>
                                </div>
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    handleDelete(selectedorder.id)
                                  }>
                                  <DeleteOutlineOutlined />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </div>
              </PerfectScrollbar>
            )}
          <Box className={clsx(classes.modalFooter, classes.leftButton)}>
            <Button
              onClick={() => setEdit()}
              variant="outlined"
              size="small"
              style={{ textTransformm: 'capitalize' }}
              startIcon={<AttachFileOutlined />}>
              Attach Doctor's Order
            </Button>
            <span>
              <Button
                onClick={props.onCancel}
                style={{ marginRight: 10 }}
                variant="outlined"
                size="small">
                Cancel
              </Button>
              {poploading ? (
                <CircularProgress className={classes.progress} size={20} />
              ) : (
                <Button
                  // onClick={props.onCancel}
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    (data.providerResponse === null && !valid.isValid) ||
                    (data.providerResponse === undefined && !valid.isValid) ||
                    data.providerResponse.trim() === ''
                  }
                  style={{ borderRadius: 4 }}
                  onClick={() => handleSend(data)}>
                  Send
                </Button>
              )}
            </span>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PerfectScrollbar>
            <div style={{ maxHeight: 275 }}>
              <Table className={classes.generalTable}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 40 }}>
                      <Checkbox
                        value="selectAll"
                        style={{ padding: 0 }}
                        checked={
                          orders.length > 0 &&
                          orders.filter(c => c.checked === true).length ===
                            orders.length
                        }
                        color="primary"
                        size="small"
                        onChange={event => handleSelectAll(event)}
                      />
                    </TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell style={{ minWidth: 122 }}>Type</TableCell>
                    <TableCell style={{ minWidth: 122 }}>Phycisian</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders && orders.length > 0 ? (
                    orders.map((order, idx) => (
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            style={{ padding: 0 }}
                            color="primary"
                            size="small"
                            checked={order.checked === true}
                            onChange={event => handleCheckboxChange(event, idx)}
                          />
                        </TableCell>
                        <TableCell>
                          {order.createdDate
                            ? moment(order.createdDate).format('MM/DD/YYYY')
                            : null}
                        </TableCell>
                        <TableCell noWrap="true">
                          <div className={classes.ellipsisMode}>
                            <Typography>{order.type}</Typography>
                          </div>
                        </TableCell>
                        <TableCell noWrap="true">
                          <div className={classes.ellipsisMode}>
                            <Typography>{order.providerName}</Typography>
                          </div>
                        </TableCell>
                        <TableCell noWrap="true">
                          <div className={classes.ellipsisMode}>
                            <Typography>
                              <RouterLink
                                to="#"
                                onClick={() =>
                                  handleView(
                                    order.type,
                                    order.providerId,
                                    order
                                  )
                                }>
                                {order.recap}
                              </RouterLink>
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className={classes.tableRow}>
                      <TableCell
                        colSpan={10}
                        style={{ textAlign: 'center' }}
                        className={classes.tableCell}>
                        No orders available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
          <Box className={classes.modalFooter}>
            <Button
              startIcon={<ArrowBackIosOutlined />}
              onClick={() => setEditBack()}
              style={{ marginRight: 10 }}
              variant="outlined"
              size="small">
              Back
            </Button>
            <Button
              onClick={() => setEditAttach()}
              variant="contained"
              color="primary"
              size="small"
              style={{ borderRadius: 4 }}>
              Attach
            </Button>
          </Box>
        </React.Fragment>
      )}
      <Dialog
        open={handledeletepopup.showDeletePopup}
        onClose={handleCancelDelete}
        aria-labelledby="Complete"
        aria-describedby="are-you-sure"
        keepMounted={false}>
        <DialogTitle className={classes.dialTitle} id="complete">
          {'Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="are-you-sure">
            Are you sure you want to delete the document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleRemove()}
            color="primary"
            size="small"
            style={{ boxShadow: 'none' }}
            autoFocus>
            Yes
          </Button>
          <Button
            onClick={() => handleCancelDelete()}
            variant="contained"
            size="small">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

StaffResponse.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  sendResponse,
  loadOrders,
  downloadPDF
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allorders:
    state.orders && state.orders.allOrders && state.orders.allOrders.result
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(StaffResponse))
);
