import { errorHandler } from '../error/action';
import http from 'services/http';

export const getUserICD10Codes = () => async dispatch => {

    try {
        const res = await http.get(`/user/icd10`);
        if (res) {
            dispatch({
                type: 'USER_ICD10',
                data: res
            });
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const getSystemICD10Codes = (ehrID) => async dispatch => {

    try {
        const res = await http.get(`/ehr/${ehrID}/icd10`);
        if (res) {
            dispatch({
                type: 'SYSTEM_ICD10',
                data: res
            });
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const addUserICD10Code = (icd10) => async dispatch => {
    try {
        const res = await http.post(`/user/icd10`, icd10);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const addSystemICD10Code = (ehrID, icd10) => async dispatch => {
    try {
        const res = await http.post(`/ehr/${ehrID}/systemicd10`, icd10);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const deleteSystemICD10Code = (ehrID, icd10) => async dispatch => {
    try {
        const res = await http.delete(`/ehr/${ehrID}/icd10?codes=${icd10}`);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

export const deleteUserICD10Code = (icd10) => async dispatch => {
    try {
        const res = await http.delete(`/user/icd10?codes=${icd10}`);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};

const addImageProcess = (file) => {
    return new Promise((resolve, reject) => {
        let _URL = window.URL || window.webkitURL;
        let img = new Image()
        img.onload = () => resolve({ height: img.height, width: img.width })
        img.onerror = reject
        img.src = _URL.createObjectURL(file)
    })
}

export const uploadFile = (ehrID, data) => async dispatch => {
    try {
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('icdtype', data.icdType);

        const res = await http.post(`/ehr/${ehrID}/icd10`, formData);
        if (res) {
            return res;
        }
        return false;
    } catch (error) {
        errorHandler(error);
        return false;
    }
};





