import PropTypes from 'prop-types';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import styles from '../../../SignIn/style';

import { Typography, withStyles } from '@material-ui/core';

const Agree = props => {
    const { classes } = props;

    return (
        <span>
            <Typography className={classes.termsTitle} variant="h1">
                ELECTRONIC MEDICAL RECORDS PROVIDER AGREEMENT
            </Typography>
            <div className={classes.contentBody}>
                <div className={classes.termsContent}>
                    <PerfectScrollbar>
                        <div>
                            <p>
                                THIS IS A LEGALLY BINDING AGREEMENT between you (hereinafter
                                “You” or “Provider of Record”) and EHRZip., a California
                                corporation (hereinafter “We” or “Us”). You and we are
                                collectively referred to as the “Parties.”
                            </p>
                            <p style={{ fontWeight: 'bold' }}>
                                BY CLICKING “I AGREE,” OR BY OTHERWISE REGISTERING FOR AN
                                ACCOUNT, OR BY ACCESSING OR USING THE SERVICES (DEFINED
                                BELOW), YOU ARE ENTERING INTO THIS ELECTRONIC MEDICAL RECORDS
                                PROVIDER AGREEMENT (THIS “AGREEMENT”) AND YOU AGREE TO BE
                                BOUND BY ITS TERMS AND CONDITIONS.
                            </p>
                            <p>
                                Please read this Agreement carefully. Do not sign up for an
                                account or use the Services if you are unwilling or unable to
                                be bound by this Agreement.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>I. Definitions</h5>
                            <p>
                                For purposes of this Agreement, the terms set forth below have
                                the meanings assigned to them below. Terms not defined below
                                or in the body of this Agreement (whether or not capitalized)
                                have the definitions given to them in Health Insurance
                                Portability and Accountability Act of 1996, and the
                                regulations promulgated thereunder, including the Privacy Rule
                                and the Security Rule, as amended (hereinafter “HIPPA”).
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Administrative Rights”
                                </span>{' '}
                                means the rights to control and direct the use of Provider’s
                                account, including the rights to authorize and limit the
                                access rights to the Services and to Records by other
                                Authorized Users.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Authorized Patient”
                                </span>{' '}
                                means any patient for whom you, or an Authorized Worker have
                                identified as authorized to access the Services.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Authorized Referral Provider”
                                </span>{' '}
                                means any health care provider that you have identified in
                                your account as authorized to access the Services, to whom You
                                or an Authorized Worker refer a Consenting Patient.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Authorized User”
                                </span>{' '}
                                means any You, any of your Authorized Workers, any Authorized
                                Patient, and any Authorized Referral Provider who you have
                                authorized to use the Services in conjunction with your
                                account.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Authorized Worker”
                                </span>{' '}
                                means those members of your Authorized Users that who you have
                                identified in your account as authorized to access the
                                Services.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>“Consent”</span>{' '}
                                means the consent to, or authorization by, an Authorized User
                                of the Services allowing us to take actions described under
                                this Agreement. The Consent of an Authorized User may be given
                                by electronic communication to us and/or by use of the
                                Authorized User’s registering for an account or using the
                                Services.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Consenting Patient”
                                </span>{' '}
                                means any patient who has given their Consent to having their
                                Protected Health Information, disclosed to an Authorized User.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Credentials”
                                </span>{' '}
                                means any unique identifier, password, token, credential, any
                                combination thereof, or other means we may utilize from time
                                to time for authorizing access to all or any portion of the
                                Services.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Privacy Rule”
                                </span>{' '}
                                means the Standards for Privacy of Individually Identifiable
                                Health Information at 45 CFR Part 160 and Part 164, Subparts A
                                and E, as amended.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Protected Health Information”
                                </span>{' '}
                                has the meaning given it in the Privacy Rule.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Provider”
                                </span>{' '}
                                has the same meaning as “health care provider” given in 45 CFR
                                §160.103.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Provider of Record”
                                </span>{' '}
                                the Provider in whose name your account is established, who is
                                the owner of all of Your Records Authorized and Authorized
                                User accounts. The Provider of Record may be changed in
                                accordance with Section XIII.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Security Rule”
                                </span>{' '}
                                means the Security Standards for the Protection of electronic
                                Protected Health Information at 45 CFR Part 160 and Part 164,
                                Subparts A and C, as amended.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Services”
                                </span>{' '}
                                means our electronic health record services being provided
                                hereunder.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>“Term”</span>{' '}
                                means the initial term and all renewal terms of this
                                Agreement.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>“Worker”</span>{' '}
                                means the Provider of Record’s employees, volunteers,
                                trainees, authorized agents, and other persons whose conduct,
                                in the performance of work for Provider, is under the direct
                                control of such Provider, whether or not they are paid by the
                                Provider.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    “Your Health Records”
                                </span>{' '}
                                means and Protected Health Information that an Authorized User
                                inputs, uploads, or provides with relation to the Services.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>II. The Services</h5>
                            <p>
                                We grant to You, as the Provider of Record, and You accept, a
                                non-exclusive, personal, non-transferable (except as expressly
                                permitted elsewhere herein), revocable, limited license to use
                                any computer software furnished by us for access to or use of
                                the Services during the Term, and a limited right to access
                                and use the Services, subject to your full compliance with the
                                terms and conditions set forth in this Agreement. The purpose
                                of the Services is to store Your Health Records and (i) make
                                them available to you and your Authorized Users for any legal
                                purpose, including treatment, payment and health care
                                operations; (ii) to facilitate the sharing of individuals’
                                health information among Authorized Users and other authorized
                                parties, and (iii) to make health information available to
                                your Authorized Patients through the Patient Portal. You may
                                make Your Health Records accessible to other Authorized Users
                                of the Services. You authorize us, as your business associate,
                                to use and disclose Your Health Records to Authorized Users
                                and You are responsible for ensuring that the disclosure and
                                your use of Your Health Records is consistent with the
                                relevant legal restrictions and regulations. You acknowledge
                                that once access rights are granted to another Authorized
                                User, we have no control over the uses and disclosures that
                                such Authorized User makes of Your Health Records. You further
                                acknowledge that the Services only provide storage of, and
                                access to, Your Health Records and that You are solely
                                responsible for ensuring the accuracy and completeness of Your
                                Health Records.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>
                                III. Authorized User Access to the Services.
                            </h5>
                            <ol type="a" style={{marginLeft:'35px'}}>
                                <li>
                                    Provider of Record Access
                                    <p>
                                        The account must be established in the name of the
                                        Provider of Record. You agree that your use of the
                                        Services is subject to verification by us of your identity
                                        and credentials as a health care provider, and to your
                                        ongoing qualification as such. You agree that we may use
                                        and disclose your personal information for such purposes,
                                        including making inquiry of third parties concerning your
                                        identity and professional and practice credentials. You
                                        authorize such third parties to disclose to us such
                                        information as we may request for such purposes, and you
                                        agree to hold them and us harmless from any claim or
                                        liability arising from the request for or disclosure of
                                        such information. You agree that we may terminate your
                                        access to, or use of, the Services at any time if we are
                                        unable at any time to determine or verify your
                                        qualifications or credentials.
                                    </p>

                                    <p>Although You may delegate
                                        Administrative Rights to one or more Authorized Users, You
                                        remain responsible for all use of the Services by your
                                        Authorized Users. Although one of your Authorized User’s
                                        may have registered for an account to which you have
                                        granted Administrative Rights, only You as the Provider of
                                        Record is entitled to any of the rights, remedies or
                                        benefits under this Agreement and control over access to
                                        the Administrative Rights.</p>

                                    <p>
                                        With your Consent, we will make
                                        Your Health Records available to any Authorized User you
                                        designate. You may revoke your Consent with respect to any
                                        other Authorized User at any time. While your Consent is
                                        in effect, an approved Authorized User may view, edit or
                                        otherwise alter any health record you have designated for
                                        his or her use/access, based upon the access and
                                        permissions you authorize for that User. If you revoke
                                        your Consent, the approved Authorized User may continue to
                                        have the ability to view the health record in the form in
                                        which it existed at the time you revoked your Consent, but
                                        will not be able to view changes made to the record
                                        thereafter, and will not be able to edit the record. You
                                        and your Authorized Users are fully responsible for the
                                        information in any chart that you share. You or your
                                        Authorized Users should not share patient information that
                                        violates any state or federal laws, such as information
                                        relating to HIV testing. In any event, but especially in
                                        cases of potential fraud, misuse or abuse of the Services,
                                        we reserve the right, in our sole judgment, to revoke,
                                        remove, cancel or deny continued access to any health
                                        record or the Services.
                                    </p>

                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Authorized Worker Access
                                    </span>
                                    <p>
                                        The Provider of Record may authorize access to the
                                        Services to Authorized Workers who sign up for an account
                                        authorized by the Provider of Record. Generally Authorized
                                        Workers fall into three categories, administrators,
                                        authorized providers and staff.
                                    </p>
                                    <p>
                                        Administrators are the overseers of the Provider of Records account, and are
                                        responsible for managing the Authorized User accounts and
                                        granting or limiting access. Administrative Rights should
                                        be carefully controlled by the Provider of Record.
                                        Administrators will be given Administrative Rights only
                                        when authorized by the Provider of Record.
                                    </p>
                                    <p>
                                        Authorized providers are health care providers who will generally
                                        have access to view all of a patient’s records and enter,
                                        modify, and sign SOAP notes.
                                    </p>
                                    <p> Authorized staff will generally have more limited access a patient’s records and
                                        will not be able to sign off on SOAP notes.
                                    </p>
                                    <p>It is the responsibility of the Provider of Record to promptly
                                        terminate authorization and access when an Authorized
                                        Worker is no longer supposed to have access to the
                                        Services.
                                    </p>
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Authorized Referral Provider Access
                                    </span>
                                    <p>
                                        The Provider of Record may authorize temporary access to
                                        the Services to Authorized Referral Providers through a
                                        web-based referral portal (a “Referral Portal”).
                                        Authorized Referral Providers must be expressly authorized
                                        by the Provider of Record and must have their own access
                                        account. Such access may not include Administrative Rights
                                        and Referral Providers may not be permitted to or modify
                                        the Provider of Record’s patient records. You are solely
                                        responsible for the information that you make available
                                        through a Referral Portal.
                                    </p>
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Authorized Patient Access
                                    </span>
                                    <p>
                                        You may make available to patients portions of their
                                        medical records through a web-based personal health record
                                        portal that we operate on your behalf (a “Patient
                                        Portal”). You are responsible for granting Patient Portal
                                        access privileges to your patients, either on an
                                        individual basis or for your entire patient population
                                        (with each patient being granted access only to their own
                                        records). You are solely responsible for the information
                                        that you make available through a Patient Portal.
                                    </p>
                                </li>
                            </ol>
                            <h5 style={{ fontWeight: 'bold' }}>
                                IV.  Conditions for Use of Services
                            </h5>
                            <p>By signing up for access to the Services, you agree that:</p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    Cooperation.
                                </span>
                                You will cooperate with us in the administration of the
                                Services.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    Indemnification.
                                </span>
                                You hereby agree to indemnify, defend, and hold harmless us
                                and other Authorized Users, and our and their respective
                                affiliates, officers, directors, employees and agents, from
                                and against any claim, cost or liability, including reasonable
                                attorneys’ fees, arising out of or relating to: (a) the use of
                                the Services by you or your Authorized Users; (b) any breach
                                by you or your Authorized Users of any representations,
                                warranties or agreements contained in this Agreement; (c) the
                                actions of any person gaining access to the Services under
                                Credentials assigned to you or your Authorized Users; (d) the
                                actions of anyone using Credentials assigned to you or any
                                member of your Authorized Users that adversely affects the
                                Services or any information accessed through the Services; and
                                (e) your negligent or willful misconduct, or that of your
                                Authorized Users. Your indemnifications obligations in this
                                Agreement are cumulative, and are not intended to, nor do
                                they, limit your indemnification obligations elsewhere in this
                                Agreement or at law, even if such obligations arise or are
                                occasioned or triggered by a single assertion, claim,
                                circumstance, action, event or transaction.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    Prohibited Uses.
                                </span>
                                You agree that you and your Authorized Users will not, nor
                                attempt to, (i) gain unauthorized access to the Services, (ii)
                                alter or destroy information contained in the Services, except
                                in accordance with accepted practices; (iii) use the Services
                                in a manner that interferes with other Authorized Users’ use
                                of the Services; (iv) circumvent any technical measures we
                                have put in place to safeguard the Services or the
                                confidentiality, integrity or accessibility of any information
                                housed thereon, or any technical measures we have put in place
                                to restrict access to the Services.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    Safeguards.
                                </span>
                                You agree to implement and maintain appropriate
                                administrative, physical and technical safeguards to prevent
                                unauthorized access to the Services and the information
                                contained therein. Such safeguards shall comply with federal,
                                state, and local requirements, including the Privacy Rule and
                                the Security Rule. You will immediately notify us of any
                                breach or suspected breach of the security of the Services of
                                which you become aware, or any unauthorized use or disclosure
                                of information within or obtained from the Services, and you
                                will take such action to mitigate the breach, suspected
                                breach, or unauthorized use or disclosure of information
                                within or obtained from the Services as we may direct, and
                                will cooperate with us in investigating and mitigating the
                                same. Except as required by law, You will not permit any third
                                party other than Authorized Users to use or access the
                                Services without our prior written agreement.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    Responsibility for Misuse by Other Authorized Users.
                                </span>
                                You acknowledge that in granting access to the Services, we
                                will rely on the assurances of the recipients of the
                                information as to (i) their identity and credentials, (ii) the
                                purposes for which they are accessing the system, and (iii)
                                the nature and extent of the information to which they will
                                have access. You acknowledge that, while the Services will
                                contain certain technical safeguards against misuse of the
                                Services, it will rely to a substantial extent on the
                                representations and undertakings of Authorized Users of the
                                Services. You agree that we will not be responsible for any
                                unlawful access to or use of Your Health Records by any
                                Authorized User resulting from the Authorized User’s
                                misrepresentation to us, or breach of the Authorized User’s
                                Authorized User agreement or our Policies and Procedures.
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline' }}>
                                    Specially Protected Information.
                                </span>
                                We apply the standards of the Privacy Rule in permitting
                                access to the Services. You agree that you are solely
                                responsible for ensuring that Your Health Records may properly
                                be disclosed to Authorized Users and you: (i) will not make
                                available to other Authorized Users through the Services any
                                information in violation of any restriction on use or
                                disclosure (whether arising from your agreement with such
                                Authorized Users or under law); (ii) obtain all necessary
                                consents, authorizations or releases from individuals required
                                for making their health information available through the
                                Services for the purposes set forth herein; (iii) include such
                                statements (if any) in your notice of privacy practices as may
                                be required in connection with your use of the Services; and
                                (iv.) not place in the Services any information that you know
                                or have reason to believe is false or materially inaccurate.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>V. Individuals’ Rights</h5>
                            <p>
                                You are solely responsible for affording individuals their
                                rights with respect to relevant portions of Your Health
                                Records, such as the rights of access and amendment. You will
                                not undertake to afford an individual any rights with respect
                                to any information in the Services other than Your Health
                                Records.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>
                                VI. Business Associate Provisions
                            </h5>
                            <div>
                                <p>
                                    In maintaining, using and affording access to Your Health
                                    Records in accordance with this Agreement, we will:
                                </p>
                                <ol type="a" style={{marginLeft:'35px'}}>
                                    <li>
                                        Not use or disclose such information except as permitted
                                        or required by this Agreement or as required by law;
                                    </li>
                                    <li>
                                        Use appropriate safeguards and comply, where applicable,
                                        with the Security Rule with respect to electronic
                                        Protected Health Information, and to prevent the use or
                                        disclosure of such information other than as provided for
                                        by this Agreement;
                                    </li>
                                    <li>
                                        Report to you any use or disclosure of such information
                                        not provided for by this Agreement of which we become
                                        aware, including breaches of unsecured protected health
                                        information as required by §164.410 of HIPAA, and any
                                        security incident involving the information of which we
                                        become aware;
                                    </li>
                                    <li>
                                        In accordance with §§164.502(e)(1)(ii) and 164.308(b)(2)
                                        of HIPAA, as applicable, ensure that any subcontractors
                                        that create, receive, maintain or transmit Protected
                                        Health Information on our behalf agree to the same
                                        restrictions, conditions, and requirements that apply to
                                        us with respect to such information; and we obtain
                                        satisfactory assurances that such subcontractors will
                                        appropriately safeguard such information (it being
                                        understood, for the avoidance of doubt, that other
                                        Authorized Users of the Services are not our
                                        subcontractors);
                                    </li>
                                    <li>
                                        Make available Protected Health Information to you as
                                        necessary to satisfy your obligations under §164.524 of
                                        the Privacy Rule;
                                    </li>
                                    <li>
                                        Make available Protected Health Information for amendment
                                        and incorporate any amendments to Protected Health
                                        Information in accordance with §164.526 of the Privacy
                                        Rule;
                                    </li>
                                    <li>
                                        Maintain and make available such information required to
                                        provide an accounting of disclosures in accordance with
                                        §164.528 of the Privacy Rule;
                                    </li>
                                    <li>
                                        To the extent that we are to carry out your obligations
                                        under the Privacy Rule, comply with the requirements of
                                        the Privacy Rule that apply to you in the performance of
                                        such obligations;
                                    </li>
                                    <li>
                                        Make our internal practices, books, and records relating
                                        to the use and disclosure of Protected Health Information
                                        received from, or created or received by us on your
                                        behalf, available to the Secretary of the United States
                                        Department of Health and Human Services for purposes of
                                        determining your compliance with the Privacy Rule; and
                                    </li>
                                    <li>
                                        Allow you to terminate this Agreement if You determine
                                        that We have violated a material term of these business
                                        associate provisions.
                                    </li>
                                    <li>
                                        At termination (other than a “Mandatory Termination”) of
                                        this Agreement, upon receipt of a written request made by
                                        the Provider of Record, We will allow the Provider of
                                        Record 30 days access to Your Health Records through the
                                        Service (the “Transition Period”). Such access will be on
                                        a “read only” basis so that the Provider or Record may
                                        copy, but not modify the records. Upon expiration of the
                                        Transition Period We will, cancel such access and if
                                        feasible, destroy all Protected Health Information
                                        received from, or created or received by us on your behalf
                                        that we still maintain in any form, and retain no copies
                                        of such information; or, if such return or destruction is
                                        not feasible, extend the protections of this Agreement to
                                        the information and limit further uses and disclosures to
                                        those purposes that make the return or destruction of the
                                        information infeasible. You acknowledge that if you have
                                        approved, in accordance with the terms of this Agreement,
                                        other Authorized Users of our services (such as your
                                        patients, other providers, clinical laboratories or
                                        pharmacies) or their respective business associates, we
                                        may continue to make such information and data available
                                        to such Authorized Users pursuant to the terms of the
                                        agreements we have with them.
                                    </li>
                                </ol>
                            </div>

                            <h5 style={{ fontWeight: 'bold' }}>
                                VII. Disclaimer, Exclusion of Warranties, and Limitation of
                                Liability
                            </h5>
                            <ol type="a" style={{marginLeft:'35px'}} >
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Carrier Lines.
                                    </span>{' '}
                                    YOU ACKNOWLEDGE THAT ACCESS TO THE SERVICES WILL BE PROVIDED
                                    OVER VARIOUS COMMUNICATIONS LINES, AND THAT INFORMATION WILL
                                    BE TRANSMITTED OVER LOCAL EXCHANGE AND INTERNET BACKBONE
                                    CARRIER LINES AND THROUGH ROUTERS, SWITCHES, AND OTHER
                                    DEVICES (COLLECTIVELY, “CARRIER LINES”) OWNED, MAINTAINED,
                                    AND SERVICED BY THIRD-PARTY CARRIERS, UTILITIES, AND
                                    INTERNET SERVICE PROVIDERS, ALL OF WHICH ARE BEYOND OUR
                                    CONTROL. WE ASSUME NO LIABILITY FOR, OR RELATING TO, THE
                                    INTEGRITY, PRIVACY, SECURITY, CONFIDENTIALITY, OR USE OF ANY
                                    INFORMATION WHILE IT IS TRANSMITTED ON THE CARRIER LINES, OR
                                    ANY DELAY, FAILURE, INTERRUPTION, INTERCEPTION, LOSS,
                                    TRANSMISSION, OR CORRUPTION OF ANY DATA OR OTHER INFORMATION
                                    ATTRIBUTABLE TO TRANSMISSION ON THE CARRIER LINES. USE OF
                                    THE CARRIER LINES IS SOLELY AT YOUR RISK AND IS SUBJECT TO
                                    ALL APPLICABLE LOCAL, STATE, NATIONAL, AND INTERNATIONAL
                                    LAWS.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        No Warranties.
                                    </span>{' '}
                                    ACCESS TO THE SERVICES IS PROVIDED “AS IS” AND “AS
                                    AVAILABLE” WITHOUT ANY WARRANTY OF ANY KIND, AND WE DISCLAIM
                                    ALL WARRANTIES, EXPRESSED OR IMPLIED, STATUTORY OR
                                    OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED
                                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                                    PURPOSE, NONINFRINGEMENT AND TITLE. YOU ARE SOLELY
                                    RESPONSIBLE FOR ANY AND ALL ACTS OR OMISSIONS TAKEN OR MADE
                                    IN RELIANCE ON THE SERVICES OR THE INFORMATION IN THE
                                    SERVICES, INCLUDING INACCURATE OR INCOMPLETE INFORMATION. IT
                                    IS EXPRESSLY AGREED THAT IN NO EVENT SHALL WE BE LIABLE FOR
                                    ANY SPECIAL, INDIRECT, CONSEQUENTIAL, OR EXEMPLARY DAMAGES,
                                    INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR REVENUES,
                                    LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF INFORMATION OR
                                    DATA, WHETHER A CLAIM FOR ANY SUCH LIABILITY OR DAMAGES IS
                                    PREMISED UPON BREACH OF CONTRACT, BREACH OF WARRANTY,
                                    NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER THEORY OF
                                    LIABILITY, EVEN IF WE HAVE BEEN APPRISED OF THE POSSIBILITY
                                    OR LIKELIHOOD OF SUCH DAMAGES. WE DISCLAIM ANY AND ALL
                                    LIABILITY FOR ERRONEOUS TRANSMISSIONS AND LOSS OF SERVICE
                                    RESULTING FROM COMMUNICATION FAILURES BY TELECOMMUNICATION
                                    SERVICE PROVIDERS OR THE SERVICES.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Conditions for Breach.
                                    </span>{' '}
                                    We will not be deemed to be in violation of this Agreement
                                    unless you have first given us written notice specifying the
                                    nature of the default, and we have failed within thirty (30)
                                    days of receipt of the notice either to cure the default or,
                                    if cure within such period is not practicable, to be
                                    diligently proceeding to cure the default.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Other Authorized Users.
                                    </span>{' '}
                                    YOU ACKNOWLEDGE THAT OTHER AUTHORIZED USERS HAVE ACCESS TO
                                    AND ARE USING OUR SERVICES AND THE ACTIONS OF SUCH OTHER
                                    AUTHORIZED USERS ARE BEYOND OUR CONTROL. ACCORDINGLY, WE DO
                                    NOT ASSUME ANY LIABILITY FOR OR RELATING TO ANY IMPAIRMENT
                                    OF THE PRIVACY, SECURITY, CONFIDENTIALITY, INTEGRITY,
                                    AVAILABILITY, OR RESTRICTED USE OF ANY INFORMATION ON THE
                                    SERVICES RESULTING FROM ANY AUTHORIZED USER’S ACTIONS OR
                                    FAILURES TO ACT.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Unauthorized Access; Lost or Corrupt Data.
                                    </span>{' '}
                                    WE ARE NOT RESPONSIBLE FOR UNAUTHORIZED ACCESS TO YOUR DATA,
                                    FACILITIES OR EQUIPMENT BY PERSONS USING THE SERVICES OR FOR
                                    UNAUTHORIZED ACCESS TO, ALTERATION, THEFT, CORRUPTION, LOSS
                                    OR DESTRUCTION OF YOUR DATA FILES, PROGRAMS, PROCEDURES, OR
                                    INFORMATION THROUGH THE SERVICES, WHETHER BY ACCIDENT,
                                    FRAUDULENT MEANS OR DEVICES, OR ANY OTHER MEANS. YOU ARE
                                    SOLELY RESPONSIBLE FOR VALIDATING THE ACCURACY OF ALL OUTPUT
                                    AND REPORTS, AND FOR PROTECTING YOUR DATA AND PROGRAMS FROM
                                    LOSS BY IMPLEMENTING APPROPRIATE SECURITY MEASURES. YOU
                                    HEREBY WAIVE ANY DAMAGES OCCASIONED BY LOST OR CORRUPT DATA,
                                    INCORRECT REPORTS, OR INCORRECT DATA FILES RESULTING FROM
                                    PROGRAMMING ERROR, OPERATOR ERROR, EQUIPMENT OR SOFTWARE
                                    MALFUNCTION, SECURITY VIOLATIONS, OR THE USE OF THIRD-PARTY
                                    SOFTWARE. WE ARE NOT RESPONSIBLE FOR THE CONTENT OF ANY
                                    INFORMATION TRANSMITTED OR RECEIVED THROUGH OUR PROVISION OF
                                    THE SERVICES.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Limitation of Liability.
                                    </span>{' '}
                                    NOTWITHSTANDING ANYTHING IN THIS AGREEMENT TO THE CONTRARY,
                                    OUR AGGREGATE LIABILITY UNDER THIS AGREEMENT, REGARDLESS OF
                                    THEORY OF LIABILITY, SHALL BE LIMITED TO THE AGGREGATE FEES
                                    ACTUALLY PAID BY YOU UNDER THIS AGREEMENT FOR THE SIX (6)
                                    MONTH PERIOD PRECEDING THE EVENT FIRST GIVING RISE TO THE
                                    CLAIM.
                                </li>
                            </ol>
                            <h5 style={{ fontWeight: 'bold' }}>VIII. Insurance</h5>
                            <p>
                                You will obtain and maintain such policies of general
                                liability, errors and omissions, and professional liability
                                insurance with reputable insurance companies as is usually
                                carried by persons engaged in your business covering the term
                                of this Agreement.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>
                                IX. Term; Modification; Suspension; Termination
                            </h5>
                            <ol type="a" style={{marginLeft:'35px'}}>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>Term.</span>{' '}
                                    The initial term of this Agreement shall commence on the
                                    date You “sign up” for the Services and automatically
                                    continue monthly thereafter until terminated as provided in
                                    this Section.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Termination upon Notice.
                                    </span>{' '}
                                    We or You may terminate this Agreement at any time without
                                    cause upon thirty (30) days’ prior written notice to the
                                    other Party.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Modification.
                                    </span>
                                    We may update or change the Services or the terms set forth
                                    in this Agreement from time to time and recommend that you
                                    review the Agreement on a regular basis. You understand and
                                    agree that your continued use of the Services after the
                                    Agreement has been updated or changed constitutes your
                                    acceptance of the revised Agreement. Without limiting the
                                    foregoing, if we make a change to the Agreement that
                                    materially affects your use of the Services, we may post
                                    notice or notify you via email or our website(s) of any such
                                    change.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Mandated Termination, Suspension or Amendment as a Result
                                        of Government Action.
                                    </span>{' '}
                                    Notwithstanding anything to the contrary in this Agreement,
                                    we have the right, on notice to you, immediately to
                                    terminate, suspend, or amend this Agreement, without
                                    liability: (a) to comply with any order issued or proposed
                                    to be issued by any governmental agency; (b) to comply with
                                    any provision of law, any standard of participation in any
                                    reimbursement program, or any accreditation standard; (c) if
                                    performance of any term of this Agreement by either Party
                                    would cause it to be in violation of law; (d) if a finding
                                    or stipulation is made or entered into that You have
                                    violated any standard or requirement of federal or state law
                                    or regulation relating to the privacy or security of health
                                    information is made in any administrative or civil
                                    proceeding; (e) you cease to be qualified to provide
                                    services as a health care professional, or we are unable to
                                    verify your qualifications as such (any termination on the
                                    above stated grounds being a “Mandatory Termination”)
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Suspension of Access.
                                    </span>{' '}
                                    We may suspend access to the Services by you or any member
                                    of your Authorized Users immediately pending your cure of
                                    any breach of this Agreement, or in the event we determine
                                    in our sole discretion that access to or use of the Services
                                    by you or the member of your Authorized Users may jeopardize
                                    the Services or the confidentiality, privacy, security,
                                    integrity or availability of information within the
                                    Services, or that you or the member of your Authorized Users
                                    has violated or may violate this Agreement or our Policies
                                    and Procedures, or has jeopardized or may jeopardize the
                                    rights of any third party, or that any person is or may be
                                    making unauthorized use of the Services with any Credentials
                                    assigned to you or a member of your Authorized Users. We may
                                    terminate the access of any member of your Authorized Worker
                                    upon termination or change in status of his or her
                                    employment with you. Our election to suspend the Services
                                    shall not waive or affect our rights to terminate this
                                    Agreement as permitted under this Agreement.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Obligations after Termination.
                                    </span>{' '}
                                    Upon termination of this Agreement, you will (i) cease all
                                    use of the Services, (ii) pay the outstanding balance of any
                                    fees due to us, and (iii) remove all software provided under
                                    this Agreement from your computer systems. All provisions of
                                    the Agreement which, by their nature, should survive
                                    termination shall survive termination.
                                </li>
                            </ol>
                            <h5 style={{ fontWeight: 'bold' }}>X. Applicable Law</h5>
                            <p>
                                The interpretation of this Agreement and the resolution of any
                                disputes arising under this Agreement shall be governed by the
                                laws of the State of California, without regards to its
                                conflicts of laws provisions. If any action or other
                                proceeding is brought on or in connection with this Agreement,
                                the venue of such action shall be exclusively in the City and
                                County of Sacramento, California.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>XI. Arbitration</h5>
                            <ol type="a" style={{marginLeft:'35px'}}>
                                <li>
                                    EXCEPT FOR CLAIMS FOR ANY FAILURE TO PAY FOR THE SERVICES,
                                    ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING
                                    TO THIS AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT,
                                    INTERPRETATION OR VALIDITY THEREOF, INCLUDING THE
                                    DETERMINATION OF THE SCOPE OR APPLICABILITY OF THIS
                                    AGREEMENT TO ARBITRATE, SHALL BE SUBJECT TO FINAL AND
                                    BINDING ARBITRATION GOVERNED BY THE FEDERAL ARBITRATION ACT
                                    (9 U.S.C. §§ 1 ET SEQ.). THE ARBITRATION SHALL BE CONDUCTED
                                    BEFORE A SINGLE ARBITRATOR IN ACCORDANCE WITH THE COMMERCIAL
                                    DISPUTE RESOLUTION PROCEDURES AND THE SUPPLEMENTARY
                                    PROCEDURES FOR CONSUMER RELATED DISPUTES OF THE AMERICAN
                                    ARBITRATION ASSOCIATION (THE “AAA”) THEN IN EFFECT, AS
                                    MODIFIED BY THIS AGREEMENT, AND WILL BE ADMINISTERED BY THE
                                    AAA. JUDGMENT ON THE AWARD MAY BE ENTERED IN ANY COURT
                                    HAVING JURISDICTION. THIS CLAUSE SHALL NOT PRECLUDE EITHER
                                    PARTY FROM SEEKING TEMPORARY OR PRELIMINARY INJUNCTIVE
                                    RELIEF IN CONNECTION WITH AN ARBITRABLE CONTROVERSY, BUT
                                    ONLY UPON THE GROUND THAT THE AWARD TO WHICH THAT PARTY MAY
                                    BE ENTITLED MAY BE RENDERED INEFFECTUAL WITHOUT SUCH
                                    PROVISIONAL RELIEF.
                                </li>
                                <li>
                                    THE PARTIES AGREE THAT EACH MAY BRING CLAIMS AGAINST THE
                                    OTHER ONLY IN AN INDIVIDUAL CAPACITY, AND NOT AS A
                                    PLAINTIFF, CLASS MEMBER, OR PRIVATE ATTORNEY GENERAL IN ANY
                                    PURPORTED CLASS, REPRESENTATIVE, OR PRIVATE-ATTORNEY-GENERAL
                                    PROCEEDING. BY ENTERING INTO THIS AGREEMENT, YOU AND WE ARE
                                    EACH WAIVING THE RIGHT TO A JURY TRIAL OR TO PARTICIPATE IN
                                    A CLASS ACTION. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN
                                    ONE PERSON’S CLAIM WITH YOUR CLAIMS OR OUR CLAIMS AND MAY
                                    NOT OTHERWISE PRESIDE OVER ANY REPRESENTATIVE, CLASS, OR
                                    PRIVATE-ATTORNEY-GENERAL PROCEEDING. THE ARBITRATOR MAY
                                    AWARD DECLARATORY OR INJUNCTIVE RELIEF ONLY IN FAVOR OF THE
                                    INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
                                    NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT PARTY’S
                                    INDIVIDUAL CLAIM.
                                </li>
                                <li>
                                    Any part of this agreement to arbitrate that shall prove to
                                    be invalid, void, or illegal shall in no way affect, impair,
                                    or invalidate any other provision of this agreement to
                                    arbitrate, and such other provisions shall remain in full
                                    force and effect.
                                </li>
                            </ol>
                            <h5 style={{ fontWeight: 'bold' }}>
                                XII. Amending Provider Information; Assignability of this
                                Agreement; Assurances
                            </h5>
                            <ol type="a" style={{marginLeft:'35px'}}>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Amending Provider Information.
                                    </span>{' '}
                                    If you are a Provider of Record, you are required to submit
                                    to us all information necessary to confirm yourself as the
                                    Provider of Record, and maintain the accuracy of such
                                    information, in a timely fashion, during the term of this
                                    Agreement. We shall be entitled to rely on all information
                                    you submit to us. In the event that you contact us and
                                    assert that you have authority to act on behalf of a
                                    Provider or any of its account(s) or data, you hereby agree
                                    to submit to us such written certifications, assurances
                                    (which may include a written opinion of your counsel
                                    identifying us as beneficiaries entitled to rely on such
                                    opinion), instruments or judicial process as we, in our sole
                                    discretion, may request.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Assignments.
                                    </span>
                                    This Agreement may be transferred in its entirety by a
                                    Provider of Record in connection with the sale, transfer or
                                    reorganization of all or substantially all of the practice
                                    or business to which this Agreement
                                    relates;&nbsp;provided&nbsp;that&nbsp;each of the following
                                    conditions are satisfied in full: (a) an authorized
                                    representative of the transferor or transferee notifies us
                                    in writing of the transfer, the legal name of the
                                    transferee, and date of transfer; (b) the transferor or
                                    transferee submits to us such written certifications,
                                    assurances (which may include a written opinion of your
                                    counsel identifying us as beneficiaries entitled to rely on
                                    such opinion) or instruments as we, in our sole discretion,
                                    may request; and (c) we are satisfied, in our sole
                                    discretion, of the validity of the certifications,
                                    assurances or instruments submitted. Upon our recognition of
                                    a transfer by a Provider of Record, the Administrative
                                    Rights and all Authorized User accounts of such Provider of
                                    Record’s Authorized Worker shall automatically transfer to
                                    such Provider of Record’s recognized transferee. Except as
                                    expressly set forth in this Section you may not assign or
                                    transfer this Agreement, in whole or in part, without our
                                    prior written consent, which may be withheld at our sole
                                    discretion. We may freely assign this Agreement in
                                    connection with a merger, acquisition, or sale of assets, or
                                    by operation of law or otherwise.
                                </li>
                                <li>
                                    <span style={{ textDecoration: 'underline' }}>
                                        Assurances.
                                    </span>
                                    <p>
                                        By requesting or directing us to take any action described
                                        above in this section you represent and warrant that (i) you
                                        have the authority to act on such Provider’s behalf or to
                                        control such account(s) or data, and (ii) your request is
                                        not in furtherance of any purpose or action that would
                                        violate any provision of this Agreement, applicable law or
                                        the rights of any person or entity. YOU HEREBY WAIVE AND
                                        UNCONDITIONALLY RELEASE US, OUR AFFILIATES, AND OUR AND
                                        THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS,
                                        FROM ANY AND ALL CLAIMS, DEMANDS, DAMAGES, DEBTS,
                                        LIABILITIES, EXPENSES, ACTIONS AND CAUSES OF ACTIONS OF
                                        EVERY KIND AND NATURE, WHETHER NOW KNOWN OR UNKNOWN, ARISING
                                        OUT OF OR IN CONNECTION WITH ANY ACTION WE TAKE OR DO NOT
                                        TAKE IN RESPONSE TO ANY REQUEST. ACCORDINGLY, YOU AGREE TO
                                        WAIVE THE BENEFIT OF ANY LAW, INCLUDING, TO THE EXTENT
                                        APPLICABLE, CALIFORNIA CIVIL CODE § 1542 (OR SIMILAR
                                        PROVISIONS OF THE LAWS OF OTHER STATES), WHICH STATES:
                                    </p>
                                    <p>
                                        A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR
                                        DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE
                                        TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER
                                        MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
                                        DEBTOR
                                    </p>

                                </li>
                                <li>
                                    You hereby agree to indemnify, defend, and hold harmless us
                                    and other Authorized Users, and our and their respective
                                    affiliates, officers, directors, employees and agents, from
                                    and against any claim, cost or liability, including
                                    reasonable attorneys’ fees arising from: (a) any action we
                                    take in reliance on any information, certification,
                                    assurance or instrument you provide to us, or (b) any action
                                    we take that complies with any request or direction you at
                                    any time make or made.
                                </li>
                            </ol>
                            <h5 style={{ fontWeight: 'bold' }}>
                                XIII. Supervening Circumstances
                            </h5>
                            <p>
                                No Party to this Agreement shall be deemed in violation of
                                this Agreement if it is prevented from performing any of the
                                obligations under this Agreement by reason of: (a) severe
                                weather and storms; (b) earthquakes or other natural
                                occurrences; (c) strikes or other labor unrest; (d) power
                                failures; (e) nuclear or other civil or military emergencies;
                                (f) acts of legislative, judicial, executive, or
                                administrative authorities; or (g) any other circumstances
                                that are not within its reasonable control.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>XIV. Severability</h5>
                            <p>
                                Any provision of this Agreement that shall prove to be
                                invalid, void, or illegal, shall in no way affect, impair, or
                                invalidate any other provision of this Agreement, and such
                                other provisions shall remain in full force and effect.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>XV. Notices</h5>
                            <p>
                                Any and all notices required or permitted under this Agreement
                                shall be sent by United States mail or fax transmission to the
                                address provided below or to such other and different
                                addresses as the Parties may designate in writing. If you
                                supply us with an electronic mail address, we may give notice
                                by email message addressed to such address; provided that if
                                we receive notice that the email message was not delivered, we
                                will give the notice by United States mail or fax.
                            </p>
                            <p>
                                {' '}
                                To us:
                                <br />EHRZip
                                <br />Attention:
                                <br />Customer Support
                                <br />6510 Lonetree Blvd.,Ste 300
                                <br />Rocklin, CA 95765
                                <br />Main 916-672-6622
                                <br />Fax: 916-872-1998
                            </p>
                            <p>
                                To you, at the current contact information on file with us at
                                the time notice is given.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>XVI. Waiver</h5>
                            <p>
                                No term of this Agreement shall be deemed waived and no breach
                                excused, unless such waiver or consent shall be in writing and
                                signed by the Party claimed to have waived or consented. Any
                                consent by any Party to, or waiver of a breach by the other,
                                whether expressed or implied, shall not constitute a consent
                                to, waiver of, or excuse for any other different or subsequent
                                breach.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>
                                XVII. Complete Understanding; Amendments
                            </h5>
                            <p>
                                This Agreement contains the entire understanding of the
                                Parties, and there are no other written or oral understandings
                                or promises between the Parties with respect to the subject
                                matter of this Agreement other than those contained or
                                referenced in this Agreement.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>
                                XVIII. No Third-Party Beneficiaries
                            </h5>
                            <p>
                                Nothing express or implied in this Agreement is intended to
                                confer, nor shall confer, upon any person or entity other than
                                the parties and their respective successors or assigns any
                                rights, remedies, obligations, or liabilities whatsoever as
                                the intended beneficiaries of this Agreement are only the
                                Parties hereto.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>XIX. Electronic Transactions</h5>
                            <p>
                                The Services give you the ability to enter into agreements,
                                authorizations, consents and applications; make referrals;
                                order lab tests; prescribe medications; or engage in others
                                transactions electronically. YOU ACKNOWLEDGE THAT YOUR
                                ELECTRONIC SUBMISSIONS VIA THE SERVICES IN CONNECTION WITH
                                SUCH ACTIVITIES CONSTITUTE YOUR AGREEMENT AND INTENT TO BE
                                BOUND BY SUCH AGREEMENTS AND TRANSACTIONS, AND APPLIES TO ALL
                                RECORDS RELATING TO SUCH TRANSACTIONS. You represent and
                                warrant that you have the authority to take such actions.
                            </p>
                            <h5 style={{ fontWeight: 'bold' }}>XX. Privacy Policy</h5>
                            <p>
                                The Services are provided by us under this Agreement on our
                                website ehradin.com. Your use of our Services are subject to
                                our Privacy Policy. By using the Services, you are consenting
                                to the terms of the Privacy Policy and acknowledged that you
                                have reviewed our Privacy Policy.
                            </p>
                            <p style={{ fontWeight: 'bold' }}>
                                BY CLICKING “I AGREE,” OR BY OTHERWISE REGISTERING FOR AN
                                ACCOUNT, OR BY ACCESSING OR USING THE SERVICES YOU ARE
                                ENTERING INTO THIS AGREEMENT AND YOU AGREE TO BE BOUND BY ITS
                                TERMS AND CONDITIONS.
                            </p>
                        </div>
                    </PerfectScrollbar>
                </div>
            </div>
        </span>
    );
};

Agree.propTypes = {
    className: PropTypes.string
};

export default withRouter(withStyles(styles)(Agree));
