import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { computeAge } from 'util/helper';

const PrimaryInsurance = props => {
  const { classes, className, patientinformation, ...rest } = props;

  return (
    <>
      <Card {...rest} className={clsx(classes.cardRoot, className)}>
        <CardContent style={{ margin: -17 }}>
          <Grid container>
            <Grid className={classes.cardSubHeader} item xs={12}>
              <Typography variant="h3">Spouse's Details</Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.pMetaWrap}
            container
            item
            style={{ borderTop: '1px solid #D4E5F9' }}
            xs={12}>
            <Grid item md={4} sm={4} xs={6}>
              <Typography variant="h5">Name</Typography>
              <Typography variant="h6">
                {patientinformation.spouse
                  ? (patientinformation.spouse.lastname != null
                    ? patientinformation.spouse.lastname
                    : '') +
                  ' ' +
                  (patientinformation.spouse.middleinitial != null
                    ? patientinformation.spouse.middleinitial
                    : '') +
                  ' ' +
                  (patientinformation.spouse.firstname != null
                    ? patientinformation.spouse.firstname
                    : '')
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
              <Typography variant="h5">Date of Birth</Typography>
              <Typography variant="h6">
                {patientinformation.spouse
                  ? patientinformation.spouse.dob
                    ? moment(patientinformation.spouse.dob).format('MM/DD/YYYY')
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
              <Typography variant="h5">Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.spouse
                  ? patientinformation?.spouse?.phonenumber
                    ? patientinformation.spouse.phonenumber
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        {...rest}
        className={clsx(classes.cardRoot, className)}
        style={{ marginTop: 20 }}>
        <CardContent style={{ margin: -17 }}>
          <Grid container>
            <Grid className={classes.cardSubHeader} item xs={12}>
              <Typography variant="h3">Primary Insurance</Typography>
            </Grid>
            <Grid className={classes.pInfoWrap} container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  {patientinformation.primaryInsurance
                    ? patientinformation.primaryInsurance.lastname != null ||
                      patientinformation.primaryInsurance.firstname != null ||
                      patientinformation.primaryInsurance.middleinitial != null
                      ? (patientinformation.primaryInsurance.lastname != null
                        ? patientinformation.primaryInsurance.lastname
                        : '') +
                      ' ' +
                      (patientinformation.primaryInsurance.firstname != null
                        ? patientinformation.primaryInsurance.firstname
                        : '') +
                      ' ' +
                      (patientinformation.primaryInsurance.middleinitial !=
                        null
                        ? patientinformation.primaryInsurance.middleinitial
                        : '')
                      : 'NA'
                    : 'NA'}
                </Typography>
                <Typography variant="h4">
                  {patientinformation.primaryInsurance
                    ? patientinformation.primaryInsurance.gender
                      ? patientinformation.primaryInsurance.gender
                      : ''
                    : ''}
                  {patientinformation.primaryInsurance
                    ? patientinformation.primaryInsurance.dob
                      ? ',' +
                      (computeAge(patientinformation.primaryInsurance.dob) +
                        'yrs. ')
                      : ''
                    : ''}
                  {patientinformation.primaryInsurance
                    ? patientinformation.primaryInsurance.address
                      ? patientinformation.primaryInsurance.address.address
                        ? (patientinformation.primaryInsurance.address
                          .address != null
                          ? patientinformation.primaryInsurance.address
                            .address
                          : '') +
                        ' ' +
                        (patientinformation.primaryInsurance.address.city !=
                          null
                          ? patientinformation.primaryInsurance.address.city
                          : '') +
                        ' ' +
                        (patientinformation.primaryInsurance.address.state !=
                          null
                          ? patientinformation.primaryInsurance.address.state
                          : '') +
                        ' ' +
                        (patientinformation.primaryInsurance.address.zip !=
                          null
                          ? patientinformation.primaryInsurance.address.zip
                          : '')
                        : ''
                      : ''
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.pMetaWrap} container item xs={12}>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Date of Birth</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.dob
                  ? moment(patientinformation.primaryInsurance.dob).format(
                    'MM/DD/YYYY'
                  )
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">SSN</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.socialsecurity
                  ? patientinformation.primaryInsurance.socialsecurity
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Relationship</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.relationship
                  ? patientinformation.primaryInsurance.relationship
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Employer Name</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.nameofemployer
                  ? patientinformation.primaryInsurance.nameofemployer
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.cardSubHeader}
            item
            style={{ backgroundColor: 'transparent' }}
            xs={12}>
            <Typography variant="h3">Insurance Details</Typography>
          </Grid>
          <Grid className={classes.pMetaWrap} container item xs={12}>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Provider</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.grouppolicy &&
                  patientinformation.primaryInsurance.grouppolicy.insurancecompany
                  ? patientinformation.primaryInsurance.grouppolicy
                    .insurancecompany
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Group #</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.grouppolicy &&
                  patientinformation.primaryInsurance.grouppolicy.groupnumber
                  ? patientinformation.primaryInsurance.grouppolicy.groupnumber
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">ID</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.grouppolicy &&
                  patientinformation.primaryInsurance.grouppolicy.groupid
                  ? patientinformation.primaryInsurance.grouppolicy.groupid
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation.primaryInsurance.grouppolicy &&
                  patientinformation.primaryInsurance.grouppolicy.phonenumber
                  ? patientinformation.primaryInsurance.grouppolicy.phonenumber
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Address</Typography>
              <Typography variant="h6">
                {patientinformation.primaryInsurance &&
                  patientinformation?.primaryInsurance?.grouppolicy &&
                  patientinformation?.primaryInsurance?.grouppolicy?.address ?
                  (patientinformation?.primaryInsurance?.grouppolicy?.address?.address
                    ? patientinformation.primaryInsurance.grouppolicy.address.address + ', '
                    : '') +
                  (patientinformation?.primaryInsurance?.grouppolicy?.address?.city ?
                    patientinformation.primaryInsurance.grouppolicy.address.city + ', '
                    : '') +
                  (patientinformation?.primaryInsurance?.grouppolicy?.address?.state ?
                    patientinformation.primaryInsurance.grouppolicy.address.state + ', '
                    : '') +
                  (patientinformation?.primaryInsurance?.grouppolicy?.address?.zip ?
                    patientinformation.primaryInsurance.grouppolicy.address.zip + ', ' :
                    '')
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

PrimaryInsurance.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(PrimaryInsurance));
