import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid} from '@material-ui/core';

import { Details } from './components';

const useStyles = makeStyles(() => ({
  colWrap: { display: 'flex', flex: '1 0 auto' }
}));

const PTInfo = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid
        className={classes.colWrap}
        item
        md={12}
        xs={12}
      >
        <Details />
      </Grid>
    </Grid>
  );
};

PTInfo.propTypes = {
  className: PropTypes.string
};

export default PTInfo;
