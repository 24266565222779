import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { computeAge } from 'util/helper';

const SecondaryInsurance = props => {
  const { classes, className, patientinformation, ...rest } = props;

  return (
    <>
      <Card {...rest} className={clsx(classes.cardRoot, className)}>
        <CardContent style={{ margin: -17 }}>
          <Grid container>
            <Grid className={classes.cardSubHeader} item xs={12}>
              <Typography variant="h3">Emergency Contact</Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.pMetaWrap}
            container
            item
            style={{ borderTop: '1px solid #D4E5F9' }}
            xs={12}>
            <Grid item md={4} sm={4} xs={6}>
              <Typography variant="h5">Name</Typography>
              <Typography variant="h6">
                {patientinformation.emergencyContact
                  ? patientinformation.emergencyContact.name
                    ? patientinformation.emergencyContact.name
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
              <Typography variant="h5">Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.emergencyContact
                  ? patientinformation.emergencyContact.phonenumber
                    ? patientinformation.emergencyContact.phonenumber
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item md={4} sm={4} xs={6}>
              <Typography variant="h5">Relationship</Typography>
              <Typography variant="h6">
                {patientinformation.emergencyContact
                  ? patientinformation.emergencyContact.relationship
                    ? patientinformation.emergencyContact.relationship
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card
        {...rest}
        className={clsx(classes.cardRoot, className)}
        style={{ marginTop: 20 }}>
        <CardContent style={{ margin: -17 }}>
          <Grid container>
            <Grid className={classes.cardSubHeader} item xs={12}>
              <Typography variant="h3">Secondary Insurance</Typography>
            </Grid>
            <Grid className={classes.pInfoWrap} container item xs={12}>
              <Grid item xs={12}>
                <Typography variant="h2">
                  {patientinformation.secondaryInsurance
                    ? patientinformation.secondaryInsurance.lastname !== null ||
                      patientinformation.secondaryInsurance.firstname !==
                        null ||
                      patientinformation.secondaryInsurance.middleinitial !==
                        null
                      ? (patientinformation.secondaryInsurance.lastname
                          ? patientinformation.secondaryInsurance.lastname
                          : '') +
                        ' ' +
                        (patientinformation.secondaryInsurance.firstname
                          ? patientinformation.secondaryInsurance.firstname
                          : '') +
                        ' ' +
                        (patientinformation.secondaryInsurance.middleinitial
                          ? patientinformation.secondaryInsurance.middleinitial
                          : '')
                      : ''
                    : 'NA'}
                </Typography>
                <Typography variant="h4">
                  {patientinformation.secondaryInsurance
                    ? patientinformation.secondaryInsurance.gender
                      ? patientinformation.secondaryInsurance.gender
                      : ''
                    : ''}
                  {patientinformation.secondaryInsurance
                    ? patientinformation.secondaryInsurance.dob
                      ? ',' +
                        (computeAge(patientinformation.secondaryInsurance.dob) +
                          'yrs. ')
                      : ''
                    : ''}
                  {patientinformation.secondaryInsurance &&
                  patientinformation.secondaryInsurance.address
                    ? patientinformation.secondaryInsurance.address.address
                      ? (patientinformation.secondaryInsurance.address
                          .address != null
                          ? patientinformation.secondaryInsurance.address
                              .address
                          : '') +
                        ' ' +
                        (patientinformation.secondaryInsurance.address.city !=
                        null
                          ? patientinformation.secondaryInsurance.address.city
                          : '') +
                        '' +
                        (patientinformation.secondaryInsurance.address.state !=
                        null
                          ? patientinformation.secondaryInsurance.address.state
                          : '') +
                        ' ' +
                        (patientinformation.secondaryInsurance.address.zip !=
                        null
                          ? patientinformation.secondaryInsurance.address.zip
                          : '')
                      : ''
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.pMetaWrap} container item xs={12}>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Date of Birth</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance
                  ? patientinformation.secondaryInsurance.dob
                    ? moment(patientinformation.secondaryInsurance.dob).format(
                        'MM/DD/YYYY'
                      )
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">SSN</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance
                  ? patientinformation.secondaryInsurance.socialsecurity
                    ? patientinformation.secondaryInsurance.socialsecurity
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Relationship</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance
                  ? patientinformation.secondaryInsurance.relationship
                    ? patientinformation.secondaryInsurance.relationship
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Employer Name</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance
                  ? patientinformation.secondaryInsurance.nameofemployer
                    ? patientinformation.secondaryInsurance.nameofemployer
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.cardSubHeader}
            item
            style={{ backgroundColor: 'transparent' }}
            xs={12}>
            <Typography variant="h3">Insurance Details</Typography>
          </Grid>
          <Grid className={classes.pMetaWrap} container item xs={12}>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Provider</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance &&
                patientinformation.secondaryInsurance.grouppolicy
                  ? patientinformation.secondaryInsurance.grouppolicy
                      .insurancecompany
                    ? patientinformation.secondaryInsurance.grouppolicy
                        .insurancecompany
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Group #</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance &&
                patientinformation.secondaryInsurance.grouppolicy
                  ? patientinformation.secondaryInsurance.grouppolicy
                      .groupnumber
                    ? patientinformation.secondaryInsurance.grouppolicy
                        .groupnumber
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">ID</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance &&
                patientinformation.secondaryInsurance.grouppolicy
                  ? patientinformation.secondaryInsurance.grouppolicy.groupid
                    ? patientinformation.secondaryInsurance.grouppolicy.groupid
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item sm={3} xs={6}>
              <Typography variant="h5">Phone #</Typography>
              <Typography variant="h6">
                {patientinformation.secondaryInsurance &&
                patientinformation.secondaryInsurance.grouppolicy
                  ? patientinformation.secondaryInsurance.grouppolicy
                      .phonenumber
                    ? patientinformation.secondaryInsurance.grouppolicy
                        .phonenumber
                    : 'NA'
                  : 'NA'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Address</Typography>
              <Typography variant="h6">
              {patientinformation.secondaryInsurance &&
                  patientinformation?.secondaryInsurance?.grouppolicy &&
                  patientinformation?.secondaryInsurance?.grouppolicy?.address ?
                  (patientinformation?.secondaryInsurance?.grouppolicy?.address?.address
                    ? patientinformation.secondaryInsurance.grouppolicy.address.address + ', '
                    : '') +
                  (patientinformation?.secondaryInsurance?.grouppolicy?.address?.city ?
                    patientinformation.secondaryInsurance.grouppolicy.address.city + ', '
                    : '') +
                  (patientinformation?.secondaryInsurance?.grouppolicy?.address?.state ?
                    patientinformation.secondaryInsurance.grouppolicy.address.state + ', '
                    : '') +
                  (patientinformation?.secondaryInsurance?.grouppolicy?.address?.zip ?
                    patientinformation.secondaryInsurance.grouppolicy.address.zip + ', ' :
                    '')
                  : 'NA'} 
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

SecondaryInsurance.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(SecondaryInsurance));
