import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Link,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';

import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getSubjectiveTemplates,
  UpdateSubjectiveTemplate,
  DeleteSubjectiveTemplate,
  AddSubjectiveTemplate
} from 'services/providers/action';

const MyTemplates = props => {
  const { classes, ehrID, userID } = props;
  const initialUserState = {
    id: null,
    referencedata: false,
    topic: '',
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topic: {
      presence: { allowEmpty: false }
    }
  };
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialUserState);
  const [subjectiveTopics, setSubjectiveTopics] = useState(null);
  const [topicsMasterList, setTopicsMasterList] = useState(null);
  const [handleEditSubjectiveTopic, setHandleEditSubjectiveTopic] = useState({
    showSubjectiveTopicEdit: false,
    editId: 0
  });

  useEffect(() => {
    if (props.templates !== null) {
      setSubjectiveTopics(props.templates);
      setTopicsMasterList(props.templates);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [props.templates]);

  const editSubjectiveTopic = id => {
    let showSubjectiveTopicEdit = handleEditSubjectiveTopic.showSubjectiveTopicEdit
      ? handleEditSubjectiveTopic.showSubjectiveTopicEdit.slice()
      : [];
    showSubjectiveTopicEdit[id] = true;
    setHandleEditSubjectiveTopic({
      showSubjectiveTopicEdit: showSubjectiveTopicEdit,
      editId: id
    });
  };

  const handleChange = (field, value, id) => {
    let allTopics = [...subjectiveTopics];
    console.log(topicsMasterList);
    console.log(subjectiveTopics);
    const index = allTopics.findIndex(c => c.id === id);
    if (index != -1) {
      let selectedData = allTopics[index];
      selectedData.topic = value;
      allTopics[index] = selectedData;
      setSubjectiveTopics(allTopics);
    }
    console.log(topicsMasterList);
    console.log(subjectiveTopics);
  };

  const updateSubjectiveTopic = subjectiveTopic => {
    props.UpdateSubjectiveTemplate(ehrID, userID, subjectiveTopic).then(res => {
      closeEditSubjectiveTopic(subjectiveTopic.id);
    });
  };

  const closeEditSubjectiveTopic = subjective => {
    let showSubjectiveTopicEdit = handleEditSubjectiveTopic.showSubjectiveTopicEdit
      ? handleEditSubjectiveTopic.showSubjectiveTopicEdit.slice()
      : [];
    showSubjectiveTopicEdit[subjective.id] = false;
    setHandleEditSubjectiveTopic({
      showSubjectiveTopicEdit: showSubjectiveTopicEdit
    });

    // let allDatas = [...topicsMasterList];
    // const index = topicsMasterList.findIndex(c => c.id === subjective.id);
    // if (index != -1) {
    //   let selectedData = topicsMasterList[index];
    //   selectedData.topic = subjective.topic;
    //   allDatas[index] = selectedData;
    //   setSubjectiveTopics(allDatas);
    // }
    props.getSubjectiveTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        setSubjectiveTopics(res.data);
      }
    });
  };

  const deleteSubjectiveTopic = id => {
    props.DeleteSubjectiveTemplate(ehrID, userID, id).then(res => {
      props.getSubjectiveTemplates(ehrID, userID).then(res => {
        if (res && res.data.length > 0) {
          setSubjectiveTopics(res.data);
          setTopicsMasterList(res.data);
        }
      });
      // closeEditSubjectiveTopic(id);
    });
  };

  const handleTopicChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const addTopic = () => {
    if (values.isValid) {
      const data = {
        id: values.id,
        referencedata: values.referencedata,
        topic: values.topic,
        topicText: values.topicText
      };
      props.AddSubjectiveTemplate(ehrID, userID, data).then(res => {
        setValues(initialUserState);
        props.getSubjectiveTemplates(ehrID, userID).then(res => {
          if (res && res.data.length > 0) {
            setSubjectiveTopics(res.data);
            setTopicsMasterList(res.data);
          }
        });
      });
    }
  };

  const setTopicText = topicText => {
    props.setTopicText(topicText);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>My Templates</Typography>
      </div>
      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '100%' }}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Add New Topic"
                      variant="outlined"
                      className={classes.textBox}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      value={values.topic}
                      onChange={event =>
                        handleTopicChange('topic', event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ whiteSpace: 'nowrap', maxWidth: 100 }}>
                    <IconButton
                      disabled={!values.isValid}
                      size="small"
                      color="primary"
                      onClick={() => {
                        addTopic();
                      }}>
                      <SaveOutlined />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setValues(initialUserState);
                      }}>
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              ) : subjectiveTopics && subjectiveTopics.length > 0 ? (
                subjectiveTopics.map(subjective => (
                  <TableHead>
                    <TableRow>
                      {handleEditSubjectiveTopic.showSubjectiveTopicEdit &&
                      handleEditSubjectiveTopic.showSubjectiveTopicEdit[
                        subjective.id
                      ]
                        ? [
                            <TableCell>
                              <TextField
                                id="outlined-basic"
                                placeholder="Add New Topic"
                                variant="outlined"
                                className={classes.textBox}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                value={subjective.topic}
                                onChange={event =>
                                  handleChange(
                                    'topic',
                                    event.target.value,
                                    subjective.id
                                  )
                                }
                              />
                            </TableCell>,
                            <TableCell
                              align="center"
                              style={{ whiteSpace: 'nowrap' }}>
                              <IconButton
                                disabled={subjective.topic === ''}
                                // disabled={!topicValues.isValid}
                                size="small"
                                color="primary"
                                onClick={() => {
                                  updateSubjectiveTopic(subjective);
                                }}>
                                <SaveOutlined />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  closeEditSubjectiveTopic(subjective);
                                }}>
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>
                          ]
                        : [
                            <TableCell className={classes.letterFont}>
                              <Link
                                component={'button'}
                                onClick={() => {
                                  setTopicText(subjective.topic);
                                }}>
                                {subjective.topic}
                              </Link>
                            </TableCell>,
                            <TableCell width={72} align="center">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  editSubjectiveTopic(subjective.id);
                                }}>
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton
                                style={{
                                  visibility:
                                    subjective.topicText.length === 0
                                      ? 'visible'
                                      : 'hidden'
                                }}
                                size="small"
                                onClick={() => {
                                  deleteSubjectiveTopic(subjective.id);
                                }}>
                                <DeleteOutline style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>
                          ]}
                    </TableRow>
                  </TableHead>
                ))
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={10}
                    style={{ textAlign: 'center' }}>
                    No records of subjective templates
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};

MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getSubjectiveTemplates,
  UpdateSubjectiveTemplate,
  DeleteSubjectiveTemplate,
  AddSubjectiveTemplate
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
