import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Typography,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Link,
  CircularProgress
} from '@material-ui/core';
import {
  SaveOutlined,
  ClearOutlined,
  Edit,
  DeleteOutline
} from '@material-ui/icons';

import validate from 'validate.js';
import { connect } from 'react-redux';
import {
  getObjectiveTemplates,
  AddObjectiveTemplate,
  UpdateObjectiveTemplate,
  DeleteObjectiveTemplate
} from 'services/providers/action';

const MyTemplates = props => {
  const { classes, ehrID, userID } = props;
  const initialUserState = {
    id: null,
    referencedata: false,
    topic: '',
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const schema = {
    topic: {
      presence: { allowEmpty: false }
    }
  };

  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState(initialUserState);

  const [objectiveTopics, setObjectiveTopics] = useState(null);
  const [handleEditObjectiveTopic, setHandleEditObjectiveTopic] = useState({
    showObjectiveTopicEdit: false
  });

  useEffect(() => {
    if (props.objectiveTopics !== null) {
      setObjectiveTopics(props.objectiveTopics);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [props.objectiveTopics]);

  const editObjectiveTopic = id => {
    let showObjectiveTopicEdit = handleEditObjectiveTopic.showObjectiveTopicEdit
      ? handleEditObjectiveTopic.showObjectiveTopicEdit.slice()
      : [];
    showObjectiveTopicEdit[id] = true;
    setHandleEditObjectiveTopic({
      showObjectiveTopicEdit: showObjectiveTopicEdit,
      editId: id
    });
  };

  const handleChange = (field, value, id) => {
    let allDatas = [...objectiveTopics];
    const index = objectiveTopics.findIndex(c => c.id === id);
    if (index != -1) {
      let selectedData = objectiveTopics[index];
      selectedData.topic = value;
      allDatas[index] = selectedData;
      setObjectiveTopics(allDatas);
    }
  };

  const updateObjectiveTopic = objective => {
    props.UpdateObjectiveTemplate(ehrID, userID, objective).then(res => {
      closeEditObjectiveTopic(objective.id);
    });
  };

  const closeEditObjectiveTopic = id => {
    let showObjectiveTopicEdit = handleEditObjectiveTopic.showObjectiveTopicEdit
      ? handleEditObjectiveTopic.showObjectiveTopicEdit.slice()
      : [];
    showObjectiveTopicEdit[id] = false;
    setHandleEditObjectiveTopic({
      showObjectiveTopicEdit: showObjectiveTopicEdit
    });

    props.getObjectiveTemplates(ehrID, userID).then(res => {
      if (res && res.data.length > 0) {
        setObjectiveTopics(res.data);
      }
    });
  };

  const deleteObjectiveTopic = id => {
    props.DeleteObjectiveTemplate(ehrID, userID, id).then(res => {
      closeEditObjectiveTopic(id);
    });
  };

  const handleTopicChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const addObjectiveTopic = () => {
    if (values.isValid) {
      props.AddObjectiveTemplate(ehrID, userID, values).then(res => {
        setValues(initialUserState);
        props.getObjectiveTemplates(ehrID, userID).then(res => {
          if (res && res.data.length > 0) {
            setObjectiveTopics(res.data);
          }
        });
      });
    }
  };

  const setTopicText = topicText => {
    props.setTopicText(topicText);
  };

  return (
    <div className={classes.templateWrap}>
      <div className={classes.templateHead}>
        <Typography>My Templates</Typography>
      </div>
      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 298px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '100%' }}>
                    <TextField
                      id="outlined-basic"
                      placeholder="Add New Topic"
                      variant="outlined"
                      className={classes.textBox}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline
                        }
                      }}
                      value={values.topic}
                      onChange={event =>
                        handleTopicChange('topic', event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
                    <IconButton
                      disabled={!values.isValid}
                      size="small"
                      color="primary"
                      onClick={() => {
                        addObjectiveTopic();
                      }}>
                      <SaveOutlined />
                    </IconButton>
                    <IconButton
                      disabled={!values.isValid}
                      size="small"
                      onClick={() => {
                        setValues(initialUserState);
                      }}>
                      <ClearOutlined style={{ color: '#DE5555' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* <TableBody> */}
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={24} />
                </div>
              ) : objectiveTopics && objectiveTopics.length > 0 ? (
                objectiveTopics.map(objective => (
                  <TableHead>
                    <TableRow>
                      {handleEditObjectiveTopic.showObjectiveTopicEdit &&
                      handleEditObjectiveTopic.showObjectiveTopicEdit[
                        objective.id
                      ]
                        ? [
                            <TableCell>
                              <TextField
                                id="outlined-basic"
                                placeholder="Add New Topic"
                                variant="outlined"
                                className={classes.textBox}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline
                                  }
                                }}
                                value={objective.topic}
                                onChange={event =>
                                  handleChange(
                                    'topic',
                                    event.target.value,
                                    objective.id
                                  )
                                }
                              />
                            </TableCell>,
                            <TableCell
                              align="center"
                              style={{ whiteSpace: 'nowrap', maxWidth: 100 }}>
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                  updateObjectiveTopic(objective);
                                }}>
                                <SaveOutlined />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  closeEditObjectiveTopic(objective.id);
                                }}>
                                <ClearOutlined style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>
                          ]
                        : [
                            <TableCell className={classes.letterFont}>
                              <Link
                                component={'button'}
                                onClick={() => {
                                  setTopicText(objective.topic);
                                }}>
                                {objective.topic}
                              </Link>
                            </TableCell>,
                            <TableCell width={72} align="center">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  editObjectiveTopic(objective.id);
                                }}>
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton
                                style={{
                                  visibility:
                                    objective.topicText.length === 0
                                      ? 'visible'
                                      : 'hidden'
                                }}
                                size="small"
                                onClick={() => {
                                  deleteObjectiveTopic(objective.id);
                                }}>
                                <DeleteOutline style={{ color: '#DE5555' }} />
                              </IconButton>
                            </TableCell>
                          ]}
                    </TableRow>
                  </TableHead>
                ))
              ) : (
                <TableRow className={classes.tableRow}>
                  <TableCell
                    className={classes.tableCell}
                    colSpan={10}
                    style={{ textAlign: 'center' }}>
                    No records of objective templates
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getObjectiveTemplates,
  AddObjectiveTemplate,
  UpdateObjectiveTemplate,
  DeleteObjectiveTemplate
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
