import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Roles } from 'util/enum';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './style';

import {
  UserSettings,
  PTsettings,
  OrgProfile,
  PersonalProfile
} from './components';
import {
  HomeOutlined,
  DescriptionOutlined,
  InfoOutlined,
  AccountBoxOutlined
} from '@material-ui/icons';
import { connect } from 'react-redux';
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    height: 4,
    marginBottom: -10,
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: '#e8e8e8',
      borderRadius: 2
    }
  },
  scroller: {
    overflow: 'visible!important'
  },
  root: {
    overflow: 'visible!important'
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: '#606060',
    fontWeight: 600,
    fontSize: 16,
    marginRight: 0,
    background: '#E3E3E3',
    borderRadius: '5px 5px 0 0',
    border: '1px solid #C8C8C8',
    minHeight: 50,
    minWidth: 150,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto'
    },
    letterSpacing: 0,
    '&:hover': {
      backgroundColor: '#ececec'
    },
    '&:focus': {
      opacity: 1,
      backgroundColor: '#f9f9f9'
    },
    '&.Mui-selected': {
      backgroundColor: 'white',
      border: 'transparent',
      boxShadow: '-1px -2px 2px rgba(0, 0, 0, 0.04)'
    },
    '& span': {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0!important',
        transform: 'scale(.8)'
      }
    }
  }
}))(props => <Tab {...props} />);

const Default = props => {
  const { classes, role } = props; 
  function TabContainer(props) {
    return (
      <Typography component="div" className={classes.tabWrap}>
        {props.children}
      </Typography>
    );
  }

  TabContainer.propTypes = {
    children: PropTypes.node.isRequired
  };

  // const [value, setValue] = React.useState(role !== Roles.staff ? 0 :1);
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}>
            {role !== Roles.staff && (
              <StyledTab icon={<HomeOutlined />} label="User Settings" />
            )}

            <StyledTab icon={<DescriptionOutlined />} label="PT Settings" />

            {role !== Roles.staff && (
              <StyledTab icon={<InfoOutlined />} label="Organization Profile" />
            )}
            <StyledTab icon={<AccountBoxOutlined />} label="Personal Profile" />
          </StyledTabs>
          {role !== Roles.staff ? (
            <div>
              {value === 0 && (
                <TabContainer>
                  <UserSettings />
                </TabContainer>
              )}

              {value === 1 && (
                <TabContainer>
                  <PTsettings />
                </TabContainer>
              )}
              {value === 2 && (
                <TabContainer>
                  <OrgProfile />
                </TabContainer>
              )}
              {value === 3 && (
                <TabContainer>
                  <PersonalProfile />
                </TabContainer>
              )}
            </div>
          ) : (
            <div>
              {value === 0 && (
                <TabContainer>
                  <PTsettings />
                </TabContainer>
              )}
              {value === 1 && (
                <TabContainer>
                  <PersonalProfile />
                </TabContainer>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  role: state.profile && state.profile.role
});
export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(Default))
);
