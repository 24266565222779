import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import styles from './../../../style';
import {
  Grid, Typography, Avatar, IconButton, Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress
} from '@material-ui/core';
import { computeAge } from 'util/helper';
import moment from 'moment';
import { connect } from 'react-redux';
import { CloseOutlined } from '@material-ui/icons';
import SendActivation from 'views/PTMessage/components/Modal_SendActivation';

import {
  sendInvite,
  resendInvite
} from 'services/ptmessages/action';
import _ from 'lodash'
import { getPatientInfo } from 'services/soap/action.js';

const Meta = props => {

  const { classes, patientInfo, ehrID, } = props;
  const [opensendinvite, setOpenSendInvite] = useState(false);
  // const [responsepopupdata, setResponsePopupData] = useState([]);
  const [poploading, setPopLoading] = useState(false);
  const [openresenddialogue, setOpenResendDialogue] = React.useState(false);
  const handleOpenSendInvite = () => {


    setOpenSendInvite(true);

  }
  const handleCloseSendInvite = () => {
    setOpenSendInvite(false);
  };
  const handleSendActivation = async (ehrID, patientId, values) => {

    setPopLoading(true);
    const res = await props.sendInvite(ehrID, patientId, values);
    if (res) {
      handleCloseSendInvite();

    }
    setPopLoading(false);
    setOpenSendInvite(false);
    props.getPatientInfo(patientId);
  };

  const handleOpenResendInvite = () => {

    setOpenResendDialogue(true);
  };
  const handleCloseResendInvite = () => {
    setOpenResendDialogue(false);
  };
  const handleResendActivation = async () => {
    setPopLoading(true);
    const res = await props.resendInvite(ehrID, patientInfo.credentials.id);
    if (res) {
      handleCloseResendInvite();

    }
    setPopLoading(false);
  };

  const PatientStatusAvatar = () => {
    return (
      !_.isEmpty(patientInfo) && (patientInfo.credentials == null ? (

        <Box
          style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
          <Button
            style={{ padding: 0, minWidth: 0, cursor: 'pointer' }}
            size="small"
            onClick={() =>
              handleOpenSendInvite(patientInfo)}
            title="PT Portal is Not Activated, click to create PT Portal Account">
            <Avatar variant="square" style={{ width: '30px', height: '30px', backgroundColor: '#ED2024' }} />
          </Button>
        </Box>
      ) : !patientInfo.credentials.enabled ? (

        <Box style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
          <Button
            style={{ padding: 0, minWidth: 0, cursor: 'pointer' }}
            size="small"
            onClick={() =>
              handleOpenResendInvite(patientInfo)}
            title="PT Portal is Inactive, click to Resend Activation Link">
            <Avatar variant="square" style={{ width: '30px', height: '30px', backgroundColor: '#DDAC5D' }} />
          </Button>
        </Box>

      ) : (

        <Box style={{ justifyContent: 'flex-end', width: '100%', display: 'flex' }}>
          <Button
            style={{ padding: 0, minWidth: 0, cursor: 'default' }}
            size="small"
            title="PT Portal is Active">
            <Avatar variant="square" style={{ width: '30px', height: '30px', backgroundColor: '#13b998' }} />
          </Button>
        </Box>
      )
      ))
  }

  return (
    <Grid
      className={classes.cardHeaderSoap}
      container
    >
      {PatientStatusAvatar()}
      <Grid
        className={classes.cardHeader}
        item
        md={2}
        xs={4}
      >
        <Typography
          align="center"
          variant="h2"
        >ID {patientInfo && patientInfo.id}</Typography>
      </Grid>
      <Grid
        className={classes.cardHeader}
        item
        md={2}
        xs={4}
      >
        <Typography variant="h2"> {patientInfo && patientInfo.lastname
          ? patientInfo.lastname
          : ''}
          &nbsp;
          {patientInfo && patientInfo.firstname
            ? patientInfo.firstname
            : ''}
          &nbsp;
          {patientInfo && patientInfo.middleinitial
            ? patientInfo.middleinitial
            : ''}

        </Typography>
      </Grid>
      <Grid
        className={classes.cardHeader}
        item
        md={2}
        xs={4}
      >
        <Typography variant="h2">
          {patientInfo && patientInfo.dob
            ? moment(patientInfo.dob).format('MM/DD/YYYY')
            : ' '}
          {patientInfo && patientInfo.dob
            ? ' (' + computeAge(patientInfo.dob) + ' yrs) '
            : ''}</Typography>
      </Grid>
      <Grid
        className={classes.cardHeader}
        item
        md={2}
        xs={4}
      >
        <Typography variant="h2"> {patientInfo && patientInfo.gender
          ? patientInfo.gender + ' '
          : ''}</Typography>
      </Grid>
      <Grid
        className={classes.cardHeader}
        item
        md={2}
        xs={4}
      >
        <Typography variant="h2">Ph # {patientInfo && patientInfo.phonenumber
          ? patientInfo.phonenumber + ' '
          : ''}</Typography>
      </Grid>
      <Grid
        className={classes.cardHeader}
        item
        md={2}
        xs={4}
      >
        <Typography variant="h2">Ins: {patientInfo && patientInfo.primaryInsurance && patientInfo.primaryInsurance.grouppolicy
          ? patientInfo.primaryInsurance.grouppolicy.insurancecompany + ' '
          : ''}</Typography>
      </Grid>
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseSendInvite}
        open={opensendinvite}>
        <Box className={classes.modalWrap} style={{ width: 650 }}>
          <Box className={classes.modalHead}>
            <Typography variant="h2">EHR Account Activation</Typography>
            <IconButton
              aria-label="delete"
              className={classes.modalClose}
              onClick={() => handleCloseSendInvite()}>
              <CloseOutlined />
            </IconButton>
          </Box>
          {patientInfo &&
            <SendActivation
              handleSendActivation={handleSendActivation}
              onCancel={handleCloseSendInvite}
              patient={patientInfo}
            />
          }
        </Box>
      </Modal>

      <Dialog
        aria-describedby="are-you-sure"
        aria-labelledby="Complete"
        keepMounted={false}
        onClose={handleCloseResendInvite}
        open={openresenddialogue}>
        <DialogTitle className={classes.dialTitle} id="complete">
          {'Resend Account Invite'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="are-you-sure">
            {patientInfo && patientInfo.lastname ? patientInfo.lastname : ''}
            {patientInfo && patientInfo.firstname
              ? ', ' + patientInfo.firstname + ' '
              : ''}
            has not activated the account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseResendInvite()}
            size="small"
            style={{ boxShadow: 'none' }}
            variant="contained">
            Cancel
          </Button>
          {poploading ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              autoFocus
              color="primary"
              onClick={() => handleResendActivation()}
              size="small"
              variant="contained">
              Resend Account Invite
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

Meta.propTypes = {
  className: PropTypes.string
};


const mapDispatchToProps = { sendInvite, resendInvite, getPatientInfo };

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  patientInfo: state.soap && state.soap.patientInfo
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Meta))
);


