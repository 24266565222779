import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';

import {
  Grid,
  Typography,
  Modal,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress
} from '@material-ui/core';
import styles from './style';
import SendActivation from 'views/PTMessage/components/Modal_SendActivation';
import StaffResponse from 'views/PTMessage/components/Modal_StaffResponse';
import { PatientList } from './components';
import { Calendars, Reminders, PatientSearch } from '../Common/components';
import { CloseOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import {
  getAllPhyPtMessages,
  sendInvite,
  resendInvite
} from 'services/ptmessages/action';
import MessageBox from 'util/messageBox';

const Default = props => {
  const {
    classes,
    ehrID,
    userID,
    allPhyPtMessages,
    allPhyPtMessagesofDate
  } = props;
  const ptMessagechildRef = useRef(null);
  const [openresponse, setOpenResponse] = React.useState(false);
  const [responsepopupdata, setResponsePopupData] = useState([]);
  const [opensendinvite, setOpenSendInvite] = useState(false);
  const [selpatient, setSelPatient] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openresenddialogue, setOpenResendDialogue] = React.useState(false);
  const [poploading, setPopLoading] = useState(false);
  const [succMsg, setSuccMsg] = useState(false);
  const [state, setState] = useState({ page: 0, count: 0 });
  const [isLocalStorage, setIsLocalStorage] = useState(false);
  const [filvals, setFilVals] = useState({
    location: localStorage.getItem('filterPTMessageLocation') ? localStorage.getItem('filterPTMessageLocation') : '',
    provider: '',
    status: localStorage.getItem('filterPTMessageStatus') ? localStorage.getItem('filterPTMessageStatus') : ''
  });

  useEffect(() => {
    props.getAllPhyPtMessages(ehrID, '', filvals.location, '', filvals.status);
    setIsLocalStorage(true);
  }, []);

  useEffect(() => {
    if (allPhyPtMessages && allPhyPtMessages.content !== undefined) {
      setState({ ...state, count: allPhyPtMessages.totalElements });
      let datatopush = [];
      allPhyPtMessages.content.forEach(function(item) {
        datatopush.push({
          id: item.id,
          patientId: item.patientId,
          patientName: item.patientName,
          dob: item.dob,
          message: item.message,
          ptMessageBy: item.ptMessageBy,
          providerResponse: item.providerResponse,
          providerResponseBy: item.providerResponseBy,
          providerName: item.providerName,
          responseCreatedDate: item.responseCreatedDate,
          updatedDate: item.updatedDate,
          status: item.status,
          completedPerson: item.completedPerson,
          completedDate: item.completedDate,
          dateOfBirth: item.dateOfBirth,
          ptMessageOrdersList: item.ptMessageOrdersList
        });
      });
      setData(datatopush);
      setLoading(false);
    }
  }, [props.allPhyPtMessages]);

  useEffect(() => {
    if (
      allPhyPtMessagesofDate &&
      (allPhyPtMessagesofDate.content !== undefined) !== undefined
    ) {
      setState({ ...state, count: allPhyPtMessagesofDate.totalElements });
      let datatopush = [];
      allPhyPtMessagesofDate.content.forEach(function(item) {
        datatopush.push({
          id: item.id,
          patientId: item.patientId,
          patientName: item.patientName,
          dob: item.dob,
          message: item.message,
          ptMessageBy: item.ptMessageBy,
          providerResponse: item.providerResponse,
          providerResponseBy: item.providerResponseBy,
          providerName: item.providerName,
          responseCreatedDate: item.responseCreatedDate,
          updatedDate: item.updatedDate,
          status: item.status,
          completedPerson: item.completedPerson,
          completedDate: item.completedDate,
          dateOfBirth: item.dateOfBirth,
          ptMessageOrdersList: item.ptMessageOrdersList
        });
      });
      setData(datatopush);
      setLoading(false);
    }
  }, [props.allPhyPtMessagesofDate]);

  const handleDateChange = async date => {
    setIsLocalStorage(false);
    setLoading(true);
    await props.getAllPhyPtMessages(ehrID, date);
    setLoading(false);
  };

  const clearValues = () => {
    if (ptMessagechildRef && ptMessagechildRef.current) {
      ptMessagechildRef.current.clearFilterValue();
    }
  };

  const handleDrpDownChange = (location, provider, selstatus) => {
    setFilVals({
      location: location,
      provider: '',
      status: selstatus
    });
    setLoading(true);
    setState({ page: 0, count: 0 });
    props.getAllPhyPtMessages(ehrID, '', location, '', selstatus);
  };

  //Staff Response

  const handleOpenResponse = (patient, staffMessage = '') => {
    if (staffMessage !== '') {
      setResponsePopupData(staffMessage);
    } else {
      setResponsePopupData({
        // patientName: patient.patientName,
        patientName:
          (patient.lastname ? patient.lastname : '') +
          (patient.firstname ? ',' + patient.firstname : ''),
        dob: patient.dob ? patient.dob : '',

        patientId: patient.id,
        status: 1,
        providerResponseBy: patient.providerResponseBy
      });
    }
    setOpenResponse(true);
  };

  const handleCloseResponse = () => {
    setOpenResponse(false);
    props.getAllPhyPtMessages(ehrID, '', filvals.location, '', filvals.status, '', state.page);
  };

  const showSuccessMsg = () => {
    setSuccMsg(true);
    handleCloseResponse();
  };

  //Send Invite

  const handleOpenSendInvite = (patient, staffMessage) => {
    setSelPatient(patient);
    if (staffMessage) {
      setResponsePopupData(staffMessage);
    } else {
      setResponsePopupData({
        //id: 0,
        patientName:
          (patient.lastname ? patient.lastname : '') +
          (patient.firstname ? ',' + patient.firstname : ''),
        dob: patient.dob ? patient.dob : '',
        patientId: patient.id,
        status: 1,
        providerResponseBy: userID
      });
    }
    setOpenSendInvite(true);
  };

  const handleCloseSendInvite = () => {
    setOpenSendInvite(false);
  };

  const handleSendActivation = async (ehrID, patientId, values) => {
    setPopLoading(true);
    const res = await props.sendInvite(ehrID, patientId, values);
    if (res) {
      handleCloseSendInvite();
      handleOpenResponse('', responsepopupdata);
    }
    setPopLoading(false);
    setOpenSendInvite(false);
  };

  //Resend Invite

  const handleOpenResendInvite = (patient, staffMessage) => {
    setSelPatient(patient);
    if (staffMessage) {
      setResponsePopupData(staffMessage);
    } else {
      setResponsePopupData({
        //id: 0,
        patientName:
          (patient.lastname ? patient.lastname : '') +
          (patient.firstname ? ',' + patient.firstname : ''),
        dob: patient.dob ? patient.dob : '',
        patientId: patient.id,
        status: 1,
        providerResponseBy: userID
      });
    }
    setOpenResendDialogue(true);
  };

  const handleCloseResendInvite = () => {
    setOpenResendDialogue(false);
  };

  const handleResendActivation = async () => {
    setPopLoading(true);
    const res = await props.resendInvite(ehrID, selpatient.credentials.id);
    if (res) {
      handleCloseResendInvite();
      handleOpenResponse('', responsepopupdata);
    }
    setPopLoading(false);
  };

  const computeAge = dob => {
    var magicNumber = 1000 * 60 * 60 * 24 * 365;
    var age = Math.abs(new Date() - new Date(dob));
    age = Math.floor(age / magicNumber);
    return age;
  };

  const handleCloseAll = () => {
    setSuccMsg(false);
  };

  const handleChangePage = async page => {
    setLoading(true);
    setState({ ...state, page: page });
    await props.getAllPhyPtMessages(
      ehrID,
      '',
      filvals.location,
      '',
      filvals.status,
      '',
      page
    );
    window.scrollTo(0, 0);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.contentWrap} container xs={12}>
        <Grid className={classes.leftWrap} item md={3} xs={12}>
          <Calendars
            allPhyPtMessages={allPhyPtMessages}
            handleDateChange={handleDateChange}
            isptMessages
          />
          <Reminders isptMessages />
        </Grid>

        {loading ? (
          <div
            className={classes.progressWrapper}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              display: 'flex',
              bottom: 0,
              top: 0,
              alignItems: 'flex-start',
              paddingTop: 120,
              justifyContent: 'center',
              backgroundColor: 'rgba(0, 0, 0,.2)'
            }}>
            <CircularProgress style={{ height: 30, width: 30 }} />
          </div>
        ) : (
          <Grid className={classes.rightWrap} item md={9} xs={12}>
            <PatientSearch
              clear={clearValues}
              data={data}
              handleOpenResendInvite={handleOpenResendInvite}
              handleOpenResponse={handleOpenResponse}
              handleOpenSendInvite={handleOpenSendInvite}
              isptMessages
              plane
            />
            <PatientList
              data={data}
              handleChangePage={handleChangePage}
              handleDrpDownChange={handleDrpDownChange}
              handleOpenResponse={handleOpenResponse}
              isLocalStorage={isLocalStorage}
              ref={ptMessagechildRef}
              state={state}
            />

            {/* Start Modal for StaffResponse */}

            <Modal
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
              disableBackdropClick
              onClose={handleCloseResponse}
              open={openresponse}>
              <Box className={classes.modalWrap} style={{ width: 650 }}>
                <Box className={classes.modalHead}>
                  <Typography variant="h2">
                    Staff Response to
                    {responsepopupdata.patientName
                      ? ' ' + responsepopupdata.patientName
                      : ''}
                    {responsepopupdata.dob !== null &&
                    responsepopupdata.dob !== ''
                      ? '(' + computeAge(responsepopupdata.dob) + ')'
                      : ''}
                  </Typography>
                  <IconButton
                    aria-label="delete"
                    className={classes.modalClose}
                    onClick={() => handleCloseResponse()}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <StaffResponse
                  onCancel={handleCloseResponse}
                  responsedata={responsepopupdata}
                  showSuccessMsg={showSuccessMsg}
                />
              </Box>
            </Modal>
            {/* End Modal for StaffResponse */}

            {/* Start Modal for SendInvite */}
            <Modal
              aria-describedby="simple-modal-description"
              aria-labelledby="simple-modal-title"
              disableBackdropClick
              onClose={handleCloseSendInvite}
              open={opensendinvite}>
              <Box className={classes.modalWrap} style={{ width: 650 }}>
                <Box className={classes.modalHead}>
                  <Typography variant="h2">EHR Account Activation</Typography>
                  <IconButton
                    aria-label="delete"
                    className={classes.modalClose}
                    onClick={() => handleCloseSendInvite()}>
                    <CloseOutlined />
                  </IconButton>
                </Box>
                <SendActivation
                  handleSendActivation={handleSendActivation}
                  onCancel={handleCloseSendInvite}
                  patient={selpatient}
                />
              </Box>
            </Modal>
            {/* End Modal for SendInvite */}
            {/* ------------------------Resend Activation */}
            <Dialog
              aria-describedby="are-you-sure"
              aria-labelledby="Complete"
              keepMounted={false}
              onClose={handleCloseResendInvite}
              open={openresenddialogue}>
              <DialogTitle className={classes.dialTitle} id="complete">
                {'Resend Account Invite'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="are-you-sure">
                  {selpatient.lastname ? selpatient.lastname : ''}
                  {selpatient.firstname
                    ? ', ' + selpatient.firstname + ' '
                    : ''}
                  has not activated the account.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => handleCloseResendInvite()}
                  size="small"
                  style={{ boxShadow: 'none' }}
                  variant="contained">
                  Cancel
                </Button>
                {poploading ? (
                  <CircularProgress className={classes.progress} size={20} />
                ) : (
                  <Button
                    autoFocus
                    color="primary"
                    onClick={() => handleResendActivation()}
                    size="small"
                    variant="contained">
                    Resend Account Invite
                  </Button>
                )}
              </DialogActions>
            </Dialog>
            {/* -------------------End Resend Activation */}
          </Grid>
        )}

        <MessageBox
          message="Staff response sent successfully"
          onClose={handleCloseAll}
          open={succMsg}
          variant="success"
        />
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  getAllPhyPtMessages,
  sendInvite,
  resendInvite
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  allPhyPtMessages:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessages &&
    state.ptmessages.allPhyPtMessages.result,
  allPhyPtMessagesofDate:
    state.ptmessages &&
    state.ptmessages.allPhyPtMessagesofDate &&
    state.ptmessages.allPhyPtMessagesofDate.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Default))
);
