export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  textGrey: {
    color: '#a2a2a2'
  },
  textGreen: {
    color: theme.palette.success.main
  },
  modalWrap: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    background: '#fff',
    padding: 20,
    borderRadius: 5,
    boxShadow: '0 0 10px 10px rgba(0,0,0,.1)',
    outline: 'none'
  },
  modalHead: {
    margin: -20,
    padding: '10px 15px',
    borderBottom: '1px solid #f9f9f9',
    paddingBottom: 10,
    marginBottom: 10,
    boxShadow: '0 2px 10px rgba(0,0,0,.12)',
    '& h2': {
      fontWeight: 300,
      fontSize: 28
    }
  },
  modalBody: {
    padding: '10px 0'
  },
  modalClose: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  modalFooter: {
    padding: '20px 0px 10px 0px',
    textAlign: 'right'
  },
  clickable: {
    cursor: 'pointer'
  },
  dialTitle: {
    '& h2': {
      fontWeight: '800'
    }
  },
  simpleTable: {
    '& td a': {
      color: '#263238',
      '&:hover': {
        color: '#000'
      },
      '&:active': {
        color: '#BE8F44'
      }
    }
  },
  cardHeader: {
    padding: '8px 20px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& h2': {
      fontWeight: 600,
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: 18
    }
  },
  cardSubHeader: {
    padding: '8px 20px',
    alignItems: 'center',
    display: 'flex',
    background: '#EFF5FC',
    borderTop: '1px solid #D4E5F9',
    borderBottom: '1px solid #D4E5F9',
    justifyContent: 'space-between',
    '& h3': {
      fontWeight: 'normal',
      fontSize: 18,
      color: '#0A0A0A'
    },
    '& svg': {
      height: '.8em',
      width: '.8em'
    },
    '& button': {
      marginLeft: '10px'
    }
  },
  pInfoWrap: {
    padding: '15px 20px',
    '& h2': {
      fontWeight: 300,
      fontSize: 36,
      color: '#242424'
    },
    '& h4': {
      fontWeight: 300,
      fontSize: 20,
      marginTop: 10
    },
    '& h5': {
      fontWeight: 600,
      fontSize: 14,
      color: '#5698D4'
    }
  },
  pMetaWrap: {
    padding: '0px 20px 15px 20px',
    '& h5': {
      fontWeight: 600,
      fontSize: 12,
      color: '#5698D4',
      marginTop: 15
    },
    '& h6': {
      fontSize: 14,
      color: '#2C2C2C'
    }
  },
  labelFocused: {
  
    backgroundColor: theme.palette.primary.main,
    color: 'white!important',
    padding: '5px 10px',
    marginLeft: -4,
    height: 25,
    paddingTop: 5,
    marginTop: -2
  },
  textField: {
    borderColor: '#D8D8D8',
    width: '100%',
    marginTop: theme.spacing(2),
    borderRadius: 1,
    '&::placeholder': {
      color: 'blue',
      fontSize: 10
    },
    '& + & ': {
      marginTop: theme.spacing(2)
    },
    '&:focus $notchedOutline': {
      borderColor: 'green'
    },
    '&:hover $notchedOutline': {
      borderColor: theme.palette.primary.main
    },
    '& $notchedOutline': {
      borderColor: '#E6E9EC',
      borderRadius: 1
    }
  },
  formElements: {
    '& .MuiGrid-item': {
      padding: 10,
      '& h3': {
        fontSize: 16,
        lineHeight: '22px'
      },
      '& h4': {
        fontSize: 12
      }
    }
  },
  subHeader: {
    width: 'calc(100% + 20px)',
    padding: '15px 0 0 20px',
    fontSize: 18,
    margin: '10px -10px',
    borderTop: '1px solid #ececec',
    '& p': {
      fontSize: 18,
      fontWeight: 600
    }
  }
});
