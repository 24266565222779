import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import logo from '../../assets/images/logo.png';
import { PatientAgreement, Agree, Success, OwnerAgreement } from './components';
import { Grid, Box, withStyles, AppBar, Toolbar, Fab } from '@material-ui/core';
import styles from '../SignIn/style';
import { connect } from 'react-redux';
import { activateAccount, getUserByUsername } from 'services/user/action';
import { Roles } from 'util/enum';

const Terms = props => {
  const { classes, getUserByUsername } = props;
  const [showterms, setShowTerms] = useState({
    terms: true,
    success: false,
    isProvider: false,
    isPatient: false,
    isOwner: false,
    isStaff: false
  });
  const [data, setData] = useState();

  useEffect(() => {
    if (props.location && props.location.state && props.location.state.data) {
      fetchData(props.location.state.data.ehrid, props.location.state.data.username)
      setData(props.location.state.data);
    }
    else {
      setShowTerms({ ...showterms, isOwner: true });
    }

    async function fetchData(ehrID, userName) {
      const res = await getUserByUsername(ehrID, userName);
      if (res.data.role === Roles.provider) {
        setShowTerms({ ...showterms, isProvider: true });
      }
      if (res.data.role === Roles.staff) {
        setShowTerms({ ...showterms, isStaff: true });
      }
      if (res.data.role === Roles.patient) {
        setShowTerms({ ...showterms, isPatient: true });
      }
    }
  }, [props.location && props.location.state]);

  const [values, setValues] = useState({
    name: '',
    organizationname: ''
  });

  const showSuccess = () => {
    setShowTerms({
      terms: false,
      success: true
    });
  };

  useEffect(() => {
    if (props.location && props.location.state) {
      setValues({
        name: props.location.state.name,
        organizationname: props.location.state.organizationname
      });
    }
  }, []);

  const handleActivateAccount = async () => {
    const res = await props.activateAccount(data.ehrid, data.token, data);
    if (res && res.data.role === "ROLE_PROVIDER" || res && res.data.role === "ROLE_STAFF") {
      props.history.push('/sign-in');
    }
    if (res && res.data.role === "ROLE_STAFF") {
      props.history.push('/sign-in');
    }
    else if (res && res.data.role === "ROLE_PATIENT") {
      props.history.push('/pt-login');
    }
    else {
      setValues({ ...values, isLoading: false, succMsg: false });
    }
  };




  return (
    <div className={classes.root}>
      <AppBar className={classes.headerWrap} position="relative">
        <Toolbar style={{ padding: 0 }}>
          <Box
            className={classes.naviWrap}
            style={{ justifyContent: 'center' }}>
            <Box>
              <RouterLink to="/">
                <img alt="Logo" className={classes.logoImg} src={logo} />
              </RouterLink>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Grid className={classes.grid} container>
        <Grid
          className={clsx(classes.content, classes.contentFrgt)}
          item
          xs={12}>
          {showterms.terms && showterms.isProvider || showterms.terms && showterms.isStaff ? (
            <span>
              <Agree />
              <div className={classes.buttonWrap}>
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="primary"
                  onClick={() => handleActivateAccount()}
                  size="medium"
                  variant="extended">
                  I have read and accept the terms of service
                </Fab>
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="grey"
                  component={RouterLink}
                  size="medium"
                  to="/sign-up"
                  variant="extended">
                  Cancel
                </Fab>
              </div>
            </span>
          ) : showterms.terms && showterms.isPatient ? (
            <span>
              <PatientAgreement />
              <div className={classes.buttonWrap}>
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="primary"
                  onClick={() => handleActivateAccount()}
                  size="medium"
                  variant="extended">
                  Agree
                </Fab>
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="grey"
                  component={RouterLink}
                  size="medium"
                  to="/sign-up"
                  variant="extended">
                  Cancel
                </Fab>
              </div>
            </span>
          ) : showterms.terms && showterms.isOwner ? (
            <span>
              <OwnerAgreement />
              <div className={classes.buttonWrap}>
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="primary"
                  onClick={() => showSuccess()}
                  size="medium"
                  variant="extended">
                  Agree
                </Fab>
                <Fab
                  aria-label="add"
                  className={classes.roundButton}
                  color="grey"
                  component={RouterLink}
                  size="medium"
                  to="/sign-up"
                  variant="extended">
                  Cancel
                </Fab>
              </div>
            </span>
          ) : null}
          {showterms.success ? <Success values={values} /> : null}
        </Grid>
      </Grid>
    </div>
  );
};

Terms.propTypes = {
  history: PropTypes.object
};

const mapDispatchToProps = { activateAccount, getUserByUsername };
const mapStateToProps = state => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Terms))
);
