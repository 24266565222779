import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from './../../style';
import {
  Grid,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Typography,
  Box
} from '@material-ui/core';
import { EncounterSUmmary } from 'views/Default/components/Soap/components';
import { connect } from 'react-redux';
import { Details } from './components';
import { PtReview } from 'views/Common/components';
import moment from 'moment';

import { CloseOutlined } from '@material-ui/icons';
import {
  getAllPhyPtSchedules,
  deletePtSchedule,
  savePtSchedule
} from 'services/ptschedules/action';
import ScheduleAppointment from '../../../PTSchedule/components/Modal_ScheduleAppointment';
import MessageBox from 'util/messageBox';
import {
  getPatientDocuments,
  getChartViewSoapandPatientDetails
} from 'services/home/action';
import { resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks  } from 'services/soap/action'; 

const ClinicalVisits = props => {
  console.log(props.state);
  const {
    classes,
    className,
    ehrID, 
    userID,
    documentInfo,
    chartSoapPatientDetails,
    resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks  } = props;

  const [openScheduleAppointment, setOpenScheduleAppointment] = useState({
    showPopUp: false,
    appointment: null
  });
  const [openDeleteAppointment, setOpenDeleteAppointment] = useState({
    showPopUp: false,
    id: 0
  });
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });
  const [isloading, setisloading] = useState(true);
  const [openDoc, setOpenDoc] = useState(false);
  const [openViewSoap, setOpenViewSoap] = useState({
    open: false,
    patientId: 0,
    soap: {}
  });

  // useEffect(() => {
  //   props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
  //   setisloading(false);
  // }, []);

  // useEffect(() => {
  //   setisloading(false);
  // }, [allPhyPtSchedules]);

  //#region Schedule


  const handleCloseScheduleAppointment = async () => {
    setOpenScheduleAppointment({ showPopUp: false });
    await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
  };

  const handleSavePtSchedule = async ptSchedule => {
    const res = await props.savePtSchedule(ehrID, ptSchedule);
    if (res) {
      setOpenScheduleAppointment({ showPopUp: false });
      setSuccMsg({ succ: true, succMsg: 'Appointment scheduled successfully' });
    }
    await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
  };

  const handleClose = async () => {
    setSuccMsg({ succ: false });
  };

  //#endregion

  //#region Edit & Delete Appointment

  const handleOpenDeleteAppointment = appointmentId => {
    setOpenDeleteAppointment({ showPopUp: true, appointmentId: appointmentId });
  };

  const handleDeleteOK = async () => {
    if (openDeleteAppointment.appointmentId) {
      const res = await props.deletePtSchedule(
        ehrID,
        openDeleteAppointment.appointmentId
      );
      if (res) {
        setSuccMsg({ succ: true, succMsg: 'Appointment cancelled successfully' });
      }
      setOpenDeleteAppointment({ showPopUp: false });
      setOpenScheduleAppointment({ showPopUp: false });
      await props.getAllPhyPtSchedules(ehrID, moment().format('MM/DD/YYYY'));
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteAppointment({ showPopUp: false });
  };

  const handleOpenDoc = async (patientId, patientDocumentId, routeTo) => {
    setOpenDoc(true);
    await props.getPatientDocuments(
      ehrID,
      patientId,
      patientDocumentId,
      routeTo
    );
  };

  const handleOpenViewSoap = async (patientId, resourceID) => {
    const res = await props.getChartViewSoapandPatientDetails(
      ehrID,
      patientId,
      userID,
      resourceID
    ); 
    if (res.status) {
      setOpenViewSoap({
        open: true,
        patientId: patientId,
        soap: res.chartSoapPatientDetails.soap,
        patientInfo: res.chartSoapPatientDetails.patientInfo
      });
      getPatientActiveMedications(patientId) 
      getPatientInactiveDiagnosis(patientId) 
      getPatientInactiveMedication(patientId)
      getPatientMedicalHistory(patientId)
      getPatientPastDiagnosis(patientId)
      getPatientRemarks(patientId) 
    }
  };

  const handleCloseViewSoap = () => {
    setOpenViewSoap({ open: false });
  };

  //#endregion

  const changeTab = (patientId, tabId) => {
    props.changeTab(patientId, tabId);
  };

  return (
    <Grid container spacing={3}>
      <Grid className={classes.colWrap} item md={7} xs={12}>
        <Details
          changeTab={changeTab} 
          handleOpenDoc={handleOpenDoc}
          handleOpenViewSoap={handleOpenViewSoap}
        />
      </Grid>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseViewSoap}
        open={openViewSoap.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="h2">
              {openViewSoap && openViewSoap.soap && openViewSoap.soap.visitdate
                ? 'Encounter Date :' +
                moment(openViewSoap.soap.visitdate).format('MM/DD/YYYY')
                : ''}
            </Typography>
            <span>
              <IconButton aria-label="delete" onClick={handleCloseViewSoap}>
                <CloseOutlined />
              </IconButton>
            </span>
          </Box>
          <EncounterSUmmary
            // handleCopy={handleCopy}
            // handleEdit={handleEdit}
            // isHistory={isHistory}
            // onCancel={handleCloseSoap}
            patientInfo={openViewSoap && openViewSoap.patientInfo}
            // setHistory={setHistory}
            // showSuccessMsg={showSuccessMsg}
            soap={openViewSoap && openViewSoap.soap}
          />
        </Box>
      </Modal>

      <Grid className={classes.colWrap} item md={5} xs={12}>
        <PtReview
          userID={userID}
        // data={allPhyPtSchedules}
        // isloading={isloading}
        // handleEditAppointment={handleEditAppointment}
        // handleDeleteAppointment={handleOpenDeleteAppointment}
        // handleOpenScheduleAppointment={handleOpenScheduleAppointment}
        // handleDrpDownChange={handleDrpDownChange}
        />
      </Grid>

      {/* Start Dialogue for Delete Appointments */}
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={openDeleteAppointment.showPopUp}>
        <DialogTitle id="confirmation-dialog-title">
          PT Schedule Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to confirm and delete the whole appointment ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleDeleteOK()}>
            OK
          </Button>
          <Button color="primary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* End Dialogue for Delete Appointments */}

      {/* Start Create Appointments */}
      <Modal
        disableBackdropClick
        onClose={handleCloseScheduleAppointment}
        open={openScheduleAppointment.showPopUp}>
        <ScheduleAppointment
          appointment={openScheduleAppointment.appointment}
          // data={allPhyPtSchedules}
          handleDeleteAppointment={handleOpenDeleteAppointment}
          handleSavePtSchedule={handleSavePtSchedule}
          onCancel={handleCloseScheduleAppointment}
        />
      </Modal>

      {/* End Create Appointments */}

      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />
    </Grid>
  );
};

ClinicalVisits.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  deletePtSchedule,
  savePtSchedule,
  getPatientDocuments,
  getChartViewSoapandPatientDetails,
  resetValue, clearSoap,  
  getPatientActiveMedications, 
  getPatientInactiveDiagnosis, 
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  chartSoapPatientDetails: state.home && state.home.chartSoapPatientDetails
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClinicalVisits))
);
