import React from "react";
import styles from "./PaymentForm.module.css";
import { useState, useEffect, useRef } from "react";
import {
  PayPalScriptProvider,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields
} from "@paypal/react-paypal-js";
import { generateAccessToken } from "../api/token";
import { liveURLs, sandboxURLs } from "../api/constants";
import paypal from '../../../../assets/images/paypal-logo.png'
import payment from '../../../../assets/images/payment.svg'
import clsx from "clsx";
import MessageBox from "util/messageBox";
import { PayPalStatus, TrasactionStatus } from "util/enum";

export const PaymentForm = (props) => {
  const { user, handleRenewLicense, validation, setValidationError } = props;
  const [loader, showLoader] = useState(false);
  const [success, showSuccess] = useState(false);
  const [error, showErrorMsg] = useState(false);
  const [emptyCvv, setEmptyCvv] = useState(false);
  const [emptyCardNumber, setEmptyCardNumber] = useState(false);
  const [emptyExpiryDate, setEmptyExpiryDate] = useState(false);
  const [holderName, setholderName] = useState({
    cardholdername: ''
  });
  const [isCardHolderName, setIsCardHolderName] = useState(false);
  const [transactionData, setTransactionData] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [errors, setErrors] = useState(false);
  const { ORDERS_API_URL } = liveURLs;


  useEffect(() => {
    if (validation) {
      setEmptyCvv(false);
      setEmptyCardNumber(false);
      setEmptyExpiryDate(false);
      setIsCardHolderName(false);
      setValidationError(false);
    }
  }, [validation])

  const handleCloseMsg = () => {
    setErrors(false);
  };

  const cardHolderName = useRef(null);

  const handleFieldChange = (field, value) => {
    setholderName({ ...holderName, cardholdername: value });
  };

  const SubmitPayment = () => {
    // Here declare the variable containing the hostedField instance
    const { cardFields } = usePayPalHostedFields();


    const capturePaymentforOrder = async (orderId) => {
      const accessToken = await generateAccessToken();
      const response = await fetch(`${ORDERS_API_URL}/${orderId}/capture`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        }
      });
      return response;
    };
    const submitHandler = () => {
      if (typeof cardFields.submit !== "function") return; // validate that `submit()` exists before using it
      let isValid = true;
      if (errorMsg) showErrorMsg(false);
      showLoader(true);
      showSuccess(false);

      if (cardFields.getState().fields.cvv.isEmpty) {
        setEmptyCvv(true);
        showLoader(false);
        isValid = false
      }
      if (cardFields.getState().fields.expirationDate.isEmpty) {
        setEmptyExpiryDate(true);
        showLoader(false);
        isValid = false
      }
      if (cardFields.getState().fields.number.isEmpty) {
        setEmptyCardNumber(true);
        showLoader(false);
        isValid = false
      }
      if (!cardHolderName?.current?.value || cardHolderName?.current?.value === "") {
        setIsCardHolderName(true);
        showLoader(false);
        isValid = false
      }
      if (isValid) {
        cardFields
          .submit({
            // The full name as shown in the card and billing addresss
            // These fields are optional for Sandbox but mandatory for production integration
            cardholderName: cardHolderName?.current?.value
          })
          .then((order) => {
            const { orderId } = order;

            capturePaymentforOrder(orderId)
              .then((response) => response.json())
              .then((data) => {
                if (data && data.purchase_units[0].payments.captures[0].status === PayPalStatus.COMPLETED) {
                  showLoader(false);
                  showSuccess(true);
                  setTransactionData(data);
                  handleRenewLicense(orderId, TrasactionStatus.getValueByName(data.purchase_units[0].payments.captures[0].status))

                  window.$(`#paymentModal_payment`).modal("hide");
                  window.$(`#paymentModal_successful`).modal("show");
                } else {
                  showLoader(false);
                  showErrorMsg(true);
                  window.$(`#paymentModal_payment`).modal("hide");
                  window.$(`#paymentModal_failure`).modal("show");
                }

                // Inside the data you can find all the information related to the payment
              })
              .catch((err) => {
                // Handle capture order error
                showLoader(false);
                showErrorMsg(true);
                setErrorMsg(err);
                window.$(`#paymentModal_payment`).modal("hide");
                window.$(`#paymentModal_failure`).modal("show");
              });
          })
          .catch((err) => {
            // Handle validate card fields error
            showLoader(false);
            showErrorMsg(true);
            setErrorMsg(err);
            window.$(`#paymentModal_payment`).modal("hide");
            window.$(`#paymentModal_failure`).modal("show");
          });
      } else {
        setErrors(true);
      }
    };


    return (
      <div>
        {
          loader &&
          <button class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Processing...
          </button>
        }
        {
          !loader &&
          <button
            onClick={submitHandler}
            className="btn btn-primary"
            style={{ display: "flex" }}
          >
            Make Payment
          </button>
        }

      </div>
    );
  };

  const createOrder = async (req) => {
    try {
      const accessToken = await generateAccessToken();
      const payload = {
        intent: "CAPTURE",
        // change this block and dynamically get the order amount from session
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: liveURLs.PAYMENT_AMOUNT
            }
          }
        ]
      };
      const response = await fetch(ORDERS_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      console.log(`Create Order Call- ${JSON.stringify(data)}`);
      return data;
    }
    catch (err) {
      showLoader(false);
      showErrorMsg(true);
      setErrorMsg(err);
    }
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id": props.clientID,
        "data-client-token": props.clientToken,
        components: "hosted-fields",
      }}
    >
      <div class="payment_contents">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class=" payment_details">
              <div class="amountwraper">
                <img src={paypal} alt="" class="img-fluid logo_paypal" />
                <div class="amountpay">
                  <h6>$299.00 usd</h6>
                </div>
              </div>

              <div class="card_details ">
                <div class="itemhead">
                  <h5>Pay with debit or credit card</h5>
                  <p>We don't share your financial details with merchant.</p>
                </div>
                <PayPalHostedFieldsProvider
                  createOrder={async (req) => {
                    const response = await createOrder()
                    return response.id
                  }}
                >
                  <section>
                    <div>
                      <label htmlFor="card-number">Card Number</label>
                      <PayPalHostedField
                        id="card-number"
                        hostedFieldType="number"
                        options={{
                          selector: "#card-number",
                          placeholder: "XXXX XXXX XXXX XXXX",
                        }}
                        className={emptyCardNumber ? clsx(styles.card_field, styles.error) : styles.card_field}
                      />
                      <section style={{ display: "flex" }}>
                        <div style={{ flexDirection: "column", marginRight: "10px" }}>
                          <label htmlFor="cvv">CVV</label>
                          <PayPalHostedField
                            id="cvv"
                            hostedFieldType="cvv"
                            options={{
                              selector: "#cvv",
                              placeholder: "XXX",
                            }}
                            className={emptyCvv ? clsx(styles.card_field, styles.error) : styles.card_field}
                          />
                        </div>
                        <div style={{ flexDirection: "column" }}>
                          <label htmlFor="expiration-date">Expiration Date</label>
                          <PayPalHostedField
                            id="expiration-date"
                            hostedFieldType="expirationDate"
                            className={emptyExpiryDate ? clsx(styles.card_field, styles.error) : styles.card_field}
                            options={{
                              selector: "#expiration-date",
                              placeholder: "MM/YY",
                            }}
                          />
                        </div>
                      </section>

                      <label title="This represents the full name as shown in the card">
                        Card Holder Name
                      </label>
                      <input
                        id="card-holder"
                        ref={cardHolderName}
                        className={isCardHolderName ? clsx(styles.card_field, styles.error) : styles.card_field}
                        onChange={event =>
                          handleFieldChange('cardholdername', event.target.value)
                        }
                        type="text"
                        placeholder="Full name"
                        value={holderName?.cardholdername ? holderName.cardholdername : ''}
                      />
                      {<SubmitPayment />}
                      {
                        <MessageBox
                          open={errors}
                          variant="error"
                          onClose={handleCloseMsg}
                          message="Please fill the required fields"
                        />
                      }
                    </div>
                  </section>
                </PayPalHostedFieldsProvider>

              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6">
            <div class="img-wrapper">
              <img src={payment} alt="" class="pay_vector" />
              <h4>PayPal is the safer, easier way to pay</h4>
              <p>No matter where you shop, we keep your financial information secure.</p>
            </div>
          </div>

        </div>
      </div>
    </PayPalScriptProvider>
  );
};
