import { errorHandler } from '../error/action';
import http from 'services/http';

export const getAllPhyPtMessages = (
  ehrID,
  date = '',
  location = '',
  provider = '',
  status = '',
  limit = 20,
  page = 0
) => async dispatch => {
  try {
    let loc = location === 'undefined' || location === null ? '' : location;
    let pro = provider === 'undefined' || provider === null ? '' : provider;
    let sta = status === 'undefined' || status === null ? '' : status;
    const res = await http.get(
      `/ehr/${ehrID}/PhyPtMessages?date=${date}&limit=${limit}&location=${loc}&order=DESC&page=${page}&physician=${pro}&sortby=messageUpdatedDate&status=${sta}`
    );
    if (res) {
      if (date) {
        dispatch({
          type: 'PHYPTMESSAGES_GET_DATE',
          data: res.data
        });
        return true;
      }
      dispatch({
        type: 'PHYPTMESSAGES_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const sendResponse = (ehrID, patientID, user, orderHxId) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/patient/${patientID}/savePhyPtMessage?orderHxId=${orderHxId}&isNew=true`,
      user
    );
    if (res) {
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const sendInvite = (ehrID, patientId, user) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/users?patientID=${patientId}&roles=ROLE_USER+ROLE_PATIENT&isNew=true`,
      user
    );
    if (res) {
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const resendInvite = (ehrID, userID) => async () => {
  try {
    const res = await http.post(
      `/ehr/${ehrID}/resendActivationEmail?userID=${userID}&isNew=true`
    );
    if (res) {
      // dispatch({
      //   type: "PATIENT_GET",
      //   data: res.data
      // });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAllPatPtMessages = (ehrID, userID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/patients/${userID}/allPtMessages`
    );
    if (res) {
      dispatch({
        type: 'PATPTMESSAGES_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const getAllPatforPtMessages = (ehrID, userID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/patients/${userID}/ptMessages?date=&limit=20&order=DESC&sortby=messageUpdatedDate`
    );
    if (res) {
      dispatch({
        type: 'PAT_FOR_PTMESSAGES_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const setPtMessageComplete = (userID, data) => async dispatch => {
  try {
    const res = await http.post(
      `/patient/${userID}/setPtMessageComplete`,
      data
    );
    if (res) {
      dispatch({
        type: 'REFRESH_COUNT',
        data: true
      });
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const savePtMessage = (userID, data) => async () => {
  try {
    const res = await http.post(`/patient/${userID}/savePtMessage`, data);
    if (res) {
      return true;
    }
  } catch (error) {
    errorHandler(error);
    return false;
  }
};
