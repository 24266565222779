import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../style';
import { withRouter } from 'react-router-dom';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  TextField,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { ptScheduleListTimeElapsed } from 'util/helper';
import { connect } from 'react-redux';
import empty from '../../../assets/images/empty.svg';

const PTList = props => {
  const {
    classes,
    data,
    isfromScheduleAppointment,
    scheduleBtn,
    seldate,
    ehrID,
    locations,
    providers,
    filtervals,
    ps
  } = props;

  const [ptScheduleList, setPtScheduleList] = useState(null);
  const [, setTimeFrom] = useState(null);
  const [duration, setDuration] = useState(null);
  const [slots, setSlots] = useState(null);
  const [openQV, setOpenQV] = React.useState({ showQV: false, item: null });

  const [locs, setLocs] = useState([]);
  const [pros, setPros] = useState([]);

  const [filtervalues, setFilterValues] = useState({});

  const handleOpenQV = (e, item) => {
    e.stopPropagation();
    setOpenQV({ showQV: true, item: item });
  };

  const handleCloseQV = () => {
    setOpenQV({ showQV: false });
  };

  const loadInitialData = async () => {
    await loadTimeSlots();
    await loadDuration();
    await loadHours();
  };

  useEffect(() => {
    if (data) {
      loadInitialData();
    }
  }, []);

  useEffect(() => {
    if (filtervals) {
      setFilterValues({
        location: filtervals.location,
        provider: filtervals.provider
      });
    }
  }, [filtervals]);

  useEffect(() => {
    if (data && data.length > 0 && slots && slots.length > 0) {
      const ptScheduleList = groupPtSchedule(data);
      setPtScheduleList(ptScheduleList);
    }
  }, [data, slots]);
  useEffect(() => {
    if (ptScheduleList) {
      document.getElementById('7').scrollIntoView();
      if (ps && ps.current) {
        const curr = ps.current;
        if (curr) {
          curr.scrollTop = 0;
        }
      }
      window.scrollTo(0, 0);
    }
  }, [ptScheduleList]);

  const loadTimeSlots = () => {
    let time = [{ key: 'From', value: '' }];
    for (let i = 480; i <= 1380; i += 15) {
      let hours, minutes, ampm;
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = '0' + minutes; // adding leading zero
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      let slot = hours + ':' + minutes + ' ' + ampm;
      time.push({ key: slot, value: slot });
    }
    setTimeFrom(time);
  };

  const loadDuration = () => {
    let duration = [];
    let durations = ['15', '30', '45', '60'];
    for (let i = 0; i < durations.length; i++) {
      duration.push({
        key: durations[i] + ' min',
        value: durations[i] + ' min'
      });
    }
    setDuration(duration);
  };

  const loadHours = () => {
    let time = [];
    let hours, ampm;

    for (let i = 60; i <= 1380; i += 60) {
      hours = Math.floor(i / 60);
      ampm = hours < 12 ? ' AM' : ' PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      let slot = hours + ampm;
      time.push({ key: slot, value: slot });
    }
    setSlots(time);
  };

  const groupPtSchedule = ptScheduleList => {
    let groupedCollection = {};
    let ptScheduleCollection = {};
    const scheduleList = ptScheduleList.map(item => {
      let hours = moment(item.timeFrom).format('HH');
      let ampm = hours >= 12 ? ' PM' : ' AM';
      hours = hours % 12;
      hours = hours ? hours + ampm : '12' + ampm; // the hour '0' should be '12'

      if (ptScheduleCollection[hours] == undefined) {
        ptScheduleCollection[hours] = [];
      }

      if (
        item &&
        item.duration === 60 &&
        moment(item.timeFrom).format('HH') !==
          moment(item.timeTo).format('HH') &&
        moment(item.timeFrom).format('mm') !== '00'
      ) {
        let a = { ...item };
        a.timeTo = moment(a.timeTo).toDate();
        a.timeTo.setMinutes(0);
        ptScheduleCollection[hours].push({ schedule: a, key: hours });
        let b = { ...item };
        b.timeFrom = moment(b.timeTo).toDate();
        b.timeFrom.setMinutes(0);
        let h1 = hours.split(' ');
        let hourNew = parseInt(h1[0]) + 1 + ' ' + h1[1];
        if (ptScheduleCollection[hourNew] == undefined) {
          ptScheduleCollection[hourNew] = [];
        }
        ptScheduleCollection[hourNew].push({
          schedule: b,
          key: hourNew
        });
      } else {
        ptScheduleCollection[hours].push({ schedule: item, key: hours });
      }
    });
    const ptKeys = Object.keys(ptScheduleCollection);
    slots &&
      slots.map(item => {
        groupedCollection[item.value] = [];
        if (ptKeys.indexOf(item.value) != -1) {
          groupedCollection[item.value] = ptScheduleCollection[item.value];
        } else {
          groupedCollection[item.value].push({ key: item.value });
        }
      });
    return groupedCollection;
  };

  const handleEditAppointment = item => {
    setOpenQV({ showQV: false });
    props.handleEditAppointment(item);
  };

  const handleDeleteAppointment = item => {
    setOpenQV({ showQV: false });
    props.handleDeleteAppointment(item);
  };

  const handleOpenScheduleAppointment = e => {
    e.stopPropagation();
    props.handleOpenScheduleAppointment();
  };

  //For home page Schedule

  useEffect(() => {
    if (locations != undefined) {
      let locationtopush = [];
      locationtopush.push({ key: 'All Locations', value: '' });
      locations.forEach(function(item) {
        locationtopush.push({
          value: item.id,
          key:
            (item.companyname !== null ? item.companyname : '') +
            ',' +
            (item.address && item.address.address ? item.address.address : '')
        });
      });
      setLocs(locationtopush);
    }
  }, [locations]);

  useEffect(() => {
    if (providers != undefined) {
      let providertopush = [];
      providertopush.push({ key: 'All Providers', value: '' });
      providers.forEach(function(item) {
        providertopush.push({
          value: item.id,
          key:
            (item.firstname ? item.firstname : '') +
            (item.lastname ? ' ' + item.lastname : '') +
            (item.title ? ' ' + item.title : '')
        });
      });
      setPros(providertopush);
    }
  }, [providers]);

  const handleDrpDownChange = (field, value) => {
    const newState = { ...filtervalues };
    let location, provider;
    if (field === 'location') {
      location = value;
    } else {
      location = newState.location;
    }
    if (field === 'provider') {
      provider = value;
    } else {
      provider = newState.provider;
    }
    setFilterValues({ ...filtervalues, [field]: value });
    props.handleDrpDownChange(location, provider);
  };

  return (
    <>
      {scheduleBtn ? (
        <Box className={classes.PTfilterWrap} style={{ padding: 10 }}>
          <Box className={classes.PTfilterLeft}>
            <TextField
              InputLabelProps={{ shrink: true }}
              name="Title"
              onChange={event =>
                handleDrpDownChange('location', event.target.value)
              }
              select
              SelectProps={{ native: true }}
              style={{ maxWidth: 150 }}
              value={filtervalues.location || ''}
              variant="outlined">
              {locs &&
                locs.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
            &nbsp;
            <TextField
              name="Title"
              onChange={event =>
                handleDrpDownChange('provider', event.target.value)
              }
              select
              SelectProps={{ native: true }}
              style={{ maxWidth: 125 }}
              value={filtervalues.provider || ''}
              variant="outlined">
              {pros &&
                pros.map(item => (
                  <option value={item.value}>{item.key}</option>
                ))}
            </TextField>
          </Box>
          <Box className={classes.PTfilterRight}>
            <Button
              color="primary"
              onClick={props.handleOpenScheduleAppointment}
              size="small"
              style={{ fontSize: 11, height: 32 }}
              variant="contained">
              Schedule Appointment
            </Button>
          </Box>
        </Box>
      ) : null}
      {ptScheduleList ? (
        <React.Fragment>
          <Table aria-label="simple table" className={classes.tableCalendar}>
            <TableHead>
              <TableRow>
                {isfromScheduleAppointment ? (
                  <TableCell></TableCell>
                ) : (
                  <TableCell>
                    {moment(seldate).format('ddd')} <br />
                    {moment(seldate).format('DD')}
                  </TableCell>
                )}

                {duration &&
                  duration.length > 0 &&
                  duration.map(dur => (
                    <TableCell align="right">
                      <span>{dur.value}</span>
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <PerfectScrollbar style={{ paddingRight: 15, marginRight: -15 }}>
              <TableBody className={classes.tableScrollBody}>
                {Object.entries(ptScheduleList).map(([key, value], idx) => (
                  <TableRow
                    id={idx}
                    onClick={
                      isfromScheduleAppointment
                        ? ''
                        : e => handleOpenScheduleAppointment(e)
                    }>
                    <TableCell>{key}</TableCell>
                    <TableCell
                      align="right"
                      className={classes.scheduleTd}
                      colSpan={5}>
                      <Box className={classes.scheduleBoxWrap}>
                        {value &&
                          value.map(item => {
                            if (
                              item &&
                              item.schedule &&
                              item.schedule.timeFrom
                            ) {
                              let timeFrom = item.schedule.timeFrom
                                ? moment(item.schedule.timeFrom)
                                    .format('HH:mm')
                                    .split(':')
                                : null;
                              let timeTo = item.schedule.timeTo
                                ? moment(item.schedule.timeTo)
                                    .format('HH:mm')
                                    .split(':')
                                : null;

                              let clsPosition =
                                (timeFrom[1] === '00' && timeTo[1] === '15') ||
                                (timeFrom[1] === '00' && timeTo[1] === '30') ||
                                (timeFrom[1] === '00' && timeTo[1] === '45') ||
                                (timeFrom[1] === '00' && timeTo[1] === '00')
                                  ? classes.position_1
                                  : (timeFrom[1] === '15' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '15' &&
                                      timeTo[1] === '30') ||
                                    (timeFrom[1] === '15' &&
                                      timeTo[1] === '45') ||
                                    (timeFrom[1] === '15' && timeTo[1] === '00')
                                  ? classes.position_2
                                  : (timeFrom[1] === '30' &&
                                      timeTo[1] === '45') ||
                                    (timeFrom[1] === '30' && timeTo[1] === '00')
                                  ? classes.position_3
                                  : timeFrom[1] === '45' && timeTo[1] === '00'
                                  ? classes.position_4
                                  : '';
                              let clsColor =
                                (timeFrom[1] === '00' && timeTo[1] === '15') ||
                                (timeFrom[1] === '15' && timeTo[1] === '30') ||
                                (timeFrom[1] === '30' && timeTo[1] === '45') ||
                                (timeFrom[1] === '45' && timeTo[1] === '00')
                                  ? classes.color_1
                                  : (timeFrom[1] === '30' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '00' &&
                                      timeTo[1] === '30') ||
                                    (timeFrom[1] === '15' && timeTo[1] === '45')
                                  ? classes.color_2
                                  : (timeFrom[1] === '15' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '00' && timeTo[1] === '45')
                                  ? classes.color_3
                                  : timeFrom[1] === '00' && timeTo[1] === '00'
                                  ? classes.color_4
                                  : '';
                              let clsSize =
                                (timeFrom[1] === '00' && timeTo[1] === '15') ||
                                (timeFrom[1] === '15' && timeTo[1] === '30') ||
                                (timeFrom[1] === '30' && timeTo[1] === '45') ||
                                (timeFrom[1] === '45' && timeTo[1] === '00')
                                  ? classes.size_1
                                  : (timeFrom[1] === '30' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '00' &&
                                      timeTo[1] === '30') ||
                                    (timeFrom[1] === '15' && timeTo[1] === '45')
                                  ? classes.size_2
                                  : (timeFrom[1] === '15' &&
                                      timeTo[1] === '00') ||
                                    (timeFrom[1] === '00' && timeTo[1] === '45')
                                  ? classes.size_3
                                  : timeFrom[1] === '00' && timeTo[1] === '00'
                                  ? classes.size_4
                                  : '';
                              return (
                                <Box
                                  className={clsx(
                                    classes.scheduleBox,
                                    clsPosition,
                                    clsColor,
                                    ptScheduleListTimeElapsed(item.schedule)
                                      ? classes.color_5
                                      : '',
                                    clsSize
                                  )}
                                  onClick={
                                    isfromScheduleAppointment
                                      ? ''
                                      : e => handleOpenQV(e, item)
                                  }>
                                  <Typography className={classes.labelOne}>
                                    Appointment not available
                                    {/* {item &&
                                    item.schedule &&
                                    item.schedule.patientName} */}
                                  </Typography>
                                  {/* <Typography className={classes.labelTwo}>
                                  {item &&
                                  item.schedule &&
                                  item.schedule.timeFrom
                                    ? moment(item.schedule.timeFrom).format(
                                      'hh:mm A'
                                    )
                                    : null}
                                  {item && item.schedule && item.schedule.timeTo
                                    ? ' - ' +
                                      moment(item.schedule.timeTo).format(
                                        'hh:mm A'
                                      )
                                    : null}

                                  {item &&
                                    item.schedule &&
                                    ', ' + item.schedule.routePhysicianName}
                                </Typography> */}
                                </Box>
                              );
                            }
                          })}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </PerfectScrollbar>
          </Table>
        </React.Fragment>
      ) : (
        <Box className={classes.emptyWrap}>
          <img src={empty} />
          <Typography>No appointments to display.</Typography>
        </Box>
      )}
    </>
  );
};

PTList.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PTList))
);
