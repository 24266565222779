import { errorHandler } from '../error/action';
import http from 'services/http';

export const getReminders = (ehrID, userID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/providers/${userID}/getReminders`
    );
    if (res) {
      dispatch({
        type: 'REMINDERS_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addReminders = (ehrID, userID, data) => async () => {
  try {
    let res;
    if (data.id > 0) {
      res = await http.post(
        `/ehr/${ehrID}/providers/${userID}/reminder/${data.id}/updateReminder?reminderDesc=${data.reminderDesc}`,
        data
      );
    } else {
      res = await http.post(
        `/ehr/${ehrID}/providers/${userID}/reminders`,
        data
      );
    }
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deleteReminder = (ehrID, userID, reminderId) => async () => {
  try {
    const res = await http.delete(
      `/ehr/${ehrID}/providers/${userID}/reminder/${reminderId}/deleteReminder`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};

export const getPtReminders = (ehrID, userID) => async dispatch => {
  try {
    const res = await http.get(
      `/ehr/${ehrID}/patients/${userID}/getPtReminders`
    );
    if (res) {
      dispatch({
        type: 'PT_REMINDERS_GET',
        data: res.data
      });
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const addPtReminders = (ehrID, userID, data) => async () => {
  try {
    let res;
    if (data.id > 0) {
      res = await http.post(
        `/ehr/${ehrID}/patients/${userID}/reminder/${data.id}/updatePtReminder?reminderDesc=${data.reminderDesc}`,
        data
      );
    } else {
      res = await http.post(
        `/ehr/${ehrID}/patients/${userID}/ptReminders`,
        data
      );
    }
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    errorHandler(error);
    return false;
  }
};

export const deletePtReminder = (ehrID, userID, reminderId) => async () => {
  try {
    const res = await http.delete(
      `/ehr/${ehrID}/patients/${userID}/reminder/${reminderId}/deletePtReminder`
    );
    if (res) {
      return true;
    }
    return false;
  } catch (error) {
    let message;
    if (error.response.status === 409) {
      message = 'Not Allowed';
    }
    errorHandler(error, message);
    return false;
  }
};