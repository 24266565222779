/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import DraggableList from './DraggableList';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  IconButton
} from '@material-ui/core';
import { CheckOutlined, ClearOutlined } from '@material-ui/icons';
import {
  getAssessmentPlanTemplates,
  getSubjectiveTemplates,
  getObjectiveTemplates,
  AddAssessmentPlanTopicText,
  AddSubjectiveTopicText,
  AddObjectiveTopicText,
  UpdateAssessmentPlanTopicText,
  UpdateSubjectiveTemplate,
  UpdateObjectiveTemplate,
  UpdateSubjectiveTopicText,
  UpdateObjectiveTopicText
} from 'services/providers/action';
import { connect } from 'react-redux';
import validate from 'validate.js';
const MyTemplates = props => {
  const {
    classes,
    type,
    ehrID,
    userID,
    assessmentPlanTopic,
    objectiveTopic,
    subjectiveTopic
  } = props;

  // const [loading, setLoading] = useState(true);

  const [subjective, setSubjective] = useState(null);
  const [objective, setObjective] = useState(null);
  const [assessmentPlan, setAssessmentPlan] = useState(null);

  // const [topicText, setTopicText] = useState(null);
  const [handleEditTopicText, setHandleEditTopicText] = useState({
    showTopicTextEdit: false
  });

  const initialUserState = {
    id: null,
    referencedata: false,
    topic: '',
    topicText: [],
    isValid: false,
    touched: {},
    errors: {}
  };

  const [values, setValues] = useState(initialUserState);

  const schema = {
    topicText: {
      presence: { allowEmpty: false }
    }
  };

  useEffect(() => {
    if (props.subjectiveTemplates && subjectiveTopic) {
      const subjective = props.subjectiveTemplates.data.filter(
        item => item.topic === subjectiveTopic
      );
      setSubjective(subjective[0]);
      // setLoading(false);
    }
  }, [subjectiveTopic]);

  useEffect(() => {
    if (type === 2 && props.objectiveTemplates && objectiveTopic) {
      const objective = props.objectiveTemplates.data.filter(
        item => item.topic === objectiveTopic
      );
      setObjective(objective[0]);
      // setLoading(false);
    }
  }, [objectiveTopic]);

  useEffect(() => {
    if (type === 3 && props.assessmentPlanTemplates && assessmentPlanTopic) {
      const assessmentPlan = props.assessmentPlanTemplates.data.filter(
        item => item.topic === assessmentPlanTopic
      );
      setAssessmentPlan(assessmentPlan[0]);
      // setLoading(false);
    }
  }, [assessmentPlanTopic]);

  //#region Assessment Plan


  const handleCheckboxChange = (event, idx) => { 
    if (type === 1) {
      let topic = {...subjective};
      let allTexts = topic.topicText;
      let selectedData = allTexts[idx];
      if (event.target.checked) {
        selectedData.checked = true;
      } else {
        selectedData.checked = false;
      }
      setSubjective(topic);
      props.saveSubText(topic);
    } else if (type === 2) {
      let topic = {...objective};
      let allTexts = topic.topicText;
      let selectedData = allTexts[idx];
      if (event.target.checked) {
        selectedData.checked = true;
      } else {
        selectedData.checked = false;
      }
      setObjective(topic);
      props.saveSubText(topic);
    } else if (type === 3) {
      let topic = {...assessmentPlan};
      let allTexts = topic.topicText;
      let selectedData = allTexts[idx];
      if (event.target.checked) {
        selectedData.checked = true;
      } else {
        selectedData.checked = false;
      }
      setAssessmentPlan(topic);
      props.saveSubText(topic);
    }
    
  };

  const saveSubText = (selectedItems) => {
    if (type === 1) {
      let items={...subjective};
      items.topicText=selectedItems
      props.saveSubText(items);
    }
    else if (type === 2) {
      let items={...objective};
      items.topicText=selectedItems
      props.saveSubText(items);
    }
    else if (type === 3) {
      let items={...assessmentPlan};
      items.topicText=selectedItems
      props.saveSubText(items);
    }
  }

  const handleChange = (field, value, idx) => { 
    if (type === 1) {
      const topic = { ...subjective };
      let allTexts = topic.topicText;
      let selectedData = allTexts[idx];
      selectedData.text = value;
      allTexts[idx] = selectedData;
      topic.topicText = allTexts;
      setSubjective(topic);
    } else if (type === 2) {
      const topic = { ...objective };
      let allTexts = topic.topicText;
      let selectedData = allTexts[idx];
      selectedData.text = value;
      allTexts[idx] = selectedData;
      topic.topicText = allTexts;
      setObjective(topic);
    } else if (type === 3) {
      const topic = { ...assessmentPlan };
      let allTexts = topic.topicText;
      let selectedData = allTexts[idx];
      selectedData.text = value;
      allTexts[idx] = selectedData;
      topic.topicText = allTexts;
      setAssessmentPlan(topic);
    }
  };

  const updateText = () => {
    if (type === 1) {
      props.UpdateSubjectiveTopicText(ehrID, userID, subjective);
    } else if (type === 2) {
      props.UpdateObjectiveTopicText(ehrID, userID, objective);
    } else if (type === 3) {
      props.UpdateAssessmentPlanTopicText(ehrID, userID, assessmentPlan);
    }
  };


  const handleTextChange = (field, value) => {
    const newState = { ...values };
    newState[field] = value;
    newState.touched[field] = true;
    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;
    setValues(newState);
  };

  const addTopicText = () => {
    if (values.isValid) {
      if (type === 1) {
        const topic = { ...subjective };
        topic.topicText.push({ text: values.topicText });
        props.AddSubjectiveTopicText(ehrID, userID, topic).then(() => {
          setSubjective(topic);
          setValues(initialUserState);
        });
      }
      if (type === 2) {
        const topic = { ...objective };
        topic.topicText.push({ text: values.topicText });
        props.AddObjectiveTopicText(ehrID, userID, topic).then(() => {
          setObjective(topic);
          setValues(initialUserState);
        });
      }
      if (type === 3) {
        const topic = { ...assessmentPlan };
        topic.topicText.push({ text: values.topicText });
        props.AddAssessmentPlanTopicText(ehrID, userID, topic).then(() => {
          setAssessmentPlan(topic);
          setValues(initialUserState);
        });
      }
    }
  };

  //#endregion

  return assessmentPlanTopic !== null ||
    objectiveTopic !== null ||
    subjectiveTopic !== null ? (
      <div className={classes.templateWrap}>
        <div className={classes.templateHead}>
          <Typography>
            {type === 1
              ? subjectiveTopic
              : type === 2
                ? objectiveTopic
                : type === 3
                  ? assessmentPlanTopic
                  : ''}
          </Typography>
        </div>
        <div className={classes.templateBody}>
          <PerfectScrollbar>
            <div className={classes.templatePop}>
              <Table className={classes.templateTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TextField
                        className={classes.textBox}
                        id="outlined-basic"
                        InputProps={{
                          classes: {
                            notchedOutline: classes.notchedOutline
                          }
                        }}
                        onChange={event =>
                          handleTextChange('topicText', event.target.value)
                        }
                        placeholder="Add New Text"
                        value={values.topicText}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ whiteSpace: 'nowrap', width: 104 }}
                    >
                      <IconButton
                        color="primary"
                        disabled={!values.isValid}
                        onClick={() => {
                          addTopicText();
                        }}
                        size="small"
                      >
                        <CheckOutlined />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setValues(initialUserState);
                        }}
                        size="small"
                      >
                        <ClearOutlined style={{ color: '#DE5555' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colspan={2}
                      style={{ padding: 0 }}
                    >
                      <DraggableList
                        assessmentPlan={assessmentPlan}
                        data={
                          type === 1
                            ? subjective &&
                            subjective.topicText &&
                            subjective.topicText.length > 0
                              ? subjective.topicText
                              : ''
                            : type === 2
                              ? objective &&
                            objective.topicText &&
                            objective.topicText.length > 0
                                ? objective.topicText
                                : ''
                              : type === 3
                                ? assessmentPlan &&
                            assessmentPlan.topicText &&
                            assessmentPlan.topicText.length > 0
                                  ? assessmentPlan.topicText
                                  : ''
                                : ''
                        }
                        handleChange={handleChange}
                        handleCheckboxChange={handleCheckboxChange}
                        objective={objective}
                        subjective={subjective}
                        type={type}
                        updateText={updateText}
                        saveSubText={saveSubText}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    ) : (
      ''
    );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  getAssessmentPlanTemplates,
  getSubjectiveTemplates,
  getObjectiveTemplates,
  AddAssessmentPlanTopicText,
  AddSubjectiveTopicText,
  AddObjectiveTopicText,
  UpdateAssessmentPlanTopicText,
  UpdateSubjectiveTemplate,
  UpdateObjectiveTemplate,
  UpdateSubjectiveTopicText,
  UpdateObjectiveTopicText
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  subjectiveTemplates: state.providers && state.providers.subjectiveTemplates,
  objectiveTemplates: state.providers && state.providers.objectiveTemplates,
  assessmentPlanTemplates:
    state.providers && state.providers.assessmentPlanTemplates
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);
