import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import styles from '../../../style';

import {
  Typography,
  Box,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const Providers = props => {
  const { classes, className, ...rest } = props;
  return (
    <div style={{ maxHeight: 275, maxWidth: 400 }}>
      <Table className={classes.plainTable}>
        <TableBody>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>Signed Document: CT Scan of brain</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>Need to check the vitals lorem ispsum</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>
              Signed SOAP Note: per pt request Signed By: Low MD
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>Need to check the vitals lorem ispsum</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>
              Signed SOAP Note: per pt request Signed By: Low MD
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>Need to check the vitals lorem ispsum</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">03/15/20</TableCell>
            <TableCell>
              Signed SOAP Note: per pt request Signed By: Low MD
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

Providers.propTypes = {
  className: PropTypes.string
};

export default withRouter(withStyles(styles)(Providers));
