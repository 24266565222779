/**
 * Combine All Reducers
 */

import { combineReducers } from 'redux';
import user from 'services/user/reducer';
import error from 'services/error/reducer';
import util from 'services/util/reducer';
import profile from 'services/profile/reducer';
import patient from 'services/patient/reducer';
import ehr from 'services/ehr/reducer';

import providers from 'services/providers/reducer';
import ptissues from 'services/ptissues/reducer';
import ptschedules from 'services/ptschedules/reducer';
import ptmessages from 'services/ptmessages/reducer';
import reminders from 'services/reminders/reducer';
import orders from 'services/orders/reducer';
import locations from 'services/locations/reducer';
import icd10 from 'services/icd10/reducer';
import cpt from 'services/cpt/reducer';
import home from 'services/home/reducer';
import ptinfo from 'services/ptinfo/reducer';

import soap from 'services/soap/reducer';

// Combine all
const appReducer = combineReducers({
  profile,
  user,
  ehr,
  patient,
  error,
  util,
  home,
  providers,
  ptissues,
  ptschedules,
  ptmessages,
  reminders,
  orders,
  locations,
  icd10,
  cpt,
  ptinfo,soap
});

// Setup root reducer
const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  //const newState = state;
  return appReducer(state, action);
};

export default rootReducer;
