import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import 'react-perfect-scrollbar/dist/css/styles.css';

// Material helpers
import { withStyles } from '@material-ui/core';

import {
  Toolbar,
  Typography,
  Box,
  AppBar,
  Divider,
  Grid,
  Button,
  CircularProgress
} from '@material-ui/core';

import styles from '../styles';
const ProfilePic = props => {
  const [state, setState] = useState({ isLoading: false });

  const { classes } = props;

  const onSubmit = () => {
    setState({ ...state, isLoading: true });
    props.onSubmit();
    //setState({ ...state, isLoading: false });
  };

  const showButton = () => {
    return state.isLoading ? (
      <CircularProgress />
    ) : (
      <>
        <Button
          className={classes.modalBtnSecondary}
          onClick={props.onDialogClose}
          variant="contained">
          Cancel
        </Button>
        {'    '}
        {props.preview ? (
          <Button
            className={classes.modalBtnPrimary}
            onClick={onSubmit}
            //color="secondary"
            variant="contained">
            Submit
          </Button>
        ) : null}
      </>
    );
  };
  return (
    <Box
      className={classes.modalWrap}
      width={{ xs: '90%', sm: '90%', md: '700px' }}>
      <AppBar align="center" color="default" position="static">
        <Toolbar className={classes.modalHeadWrap}>
          <Typography className={classes.modalHead} variant="h6">
            Upload EHR Logo
          </Typography>
        </Toolbar>
      </AppBar>
      <Divider className={classes.modalHeadHr} />

      <Box className={classes.modalContent}>
        <Grid className={classes.formContainer} container item spacing={3}>
          <Grid item md={8}>
            <Avatar
              height={250}
              imageHeight={250}
              imageWidth={300}
              label={'Choose EHR logo'}
              onBeforeFileLoad={props.onBeforeFileLoad}
              onClose={props.onClose}
              onCrop={props.onCrop}
              src={props.src}
              style={{ backgroundColor: 'white' }}
              width={300}
            />
          </Grid>
          <Grid item md={2} style={{ textAlign: 'center' }}>
            {props.preview ? (
              <img alt="Preview" src={props.preview} width="125" />
            ) : null}
          </Grid>
        </Grid>
        <Grid className={classes.modalFooter} item xs={10}>
          {showButton()}
        </Grid>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(ProfilePic);
