function Enum() {
  this.self = arguments[0];
}
Enum.prototype = {
  keys: function () {
    return Object.keys(this.self);
  },
  values: function () {
    var me = this;
    return this.keys(this.self).map(function (key) {
      return me.self[key];
    });
  },
  getKeyValuePairs: function () {
    var me = this;
    return this.keys(this.self).map(function (key) {
      return { name: key, value: me.self[key] };
    });
  },
  getValueByName: function (key) {
    return this.self[
      this.keys(this.self)
        .filter(function (k) {
          return key === k;
        })
        .pop() || ''
    ];
  },
  getNameByValue: function (value) {
    var me = this;
    return (
      this.keys(this.self)
        .filter(function (k) {
          return me.self[k] === value;
        })
        .pop() || null
    );
  }
};

export const Roles = {
  admin: 'ROLE_ADMIN',
  user: 'ROLE_USER',
  provider: 'ROLE_PROVIDER',
  staff: 'ROLE_STAFF',
  patient: 'ROLE_PATIENT',
  owner: 'ROLE_OWNER'
};

export const LicenseType = {
  freetrial: 1,
  extendedtrial: 2,
  annuallicense: 3,
  extendedlicense: 4
};

export const PayPalStatus = {
  COMPLETED: "COMPLETED", //The funds could not be captured.
  DECLINED: "DECLINED", //An amount less than this captured payment's amount was partially refunded to the payer.
  PARTIALLY_REFUNDED: "PARTIALLY_REFUNDED", //The funds for this captured payment was not yet credited to the payee's PayPal account.
  PENDING: "PENDING", //The funds for this captured payment was not yet credited to the payee's PayPal account.
  REFUNDED: "REFUNDED", //An amount greater than or equal to this captured payment's amount was refunded to the payer.
  FAILED: "FAILED", //There was an error while capturing payment.
}

export const TrasactionStatus = new Enum({ // This is same as paypal status, any change in paypal status needs to be updated in transaction status as well.
  "COMPLETED": 1, 
  "DECLINED": 2, 
  "PARTIALLY_REFUNDED": 3, 
  "PENDING": 4, 
  "REFUNDED": 5,  
  "FAILED": 6, 
})
// export const MigrationStatus = {
//   Inprogress: 1,
//   completed: 2,
//   failed: 3,
  
// }
// export const MigrationStatusDesc = new Enum({
//   1: 'Inprogress',
//   2: 'Completed',
//   3: 'Failed'
// });
