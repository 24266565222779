import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import {
  BlockOutlined,
  CheckCircleOutlineOutlined,
  ExpandMore,
  AddCircleOutlineOutlined,
  EditOutlined,
  DeleteOutlined,
  ClearOutlined,
  CheckOutlined, CalendarToday
} from '@material-ui/icons/';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import styles from '../../../style';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  saveAllergy,
  updateAllergy,
  deleteAllergy,
  saveSocialHistory,
  updateSocialHistory,
  deleteSocialHistory,
  saveFamilyHistory,
  updateFamilyHistory,
  deleteFamilyHistory,
  saveAdvanceDirective,
  updateAdvanceDirective,
  deleteAdvanceDirective,
  saveAdditionalItems,
  updateAdditionalItems,
  deleteAdditionalItems,
  saveHealthMaintenance,
  updateHealthMaintenance,
  deleteHealthMaintenance,
  saveVaccinations,
  updateVaccinations,
  deleteVaccinations,
  saveMedication,
  updateMedication,
  inactivateMedication,
  activateMedication,
  deleteMedication,
  getPatientInactiveDiagnosis,
  getPatientActiveMedications
} from 'services/soap/action';

const MHxSoap = props => {
  const {
    classes,
    getPatientActiveMedications,
    patientId
  } = props;

  //#region Allergy
  const initialUserStateAllergies = {
    id: 0,
    name: '',
    reaction: ''
  };
  const [valuesAllergy, setValuesAllergy] = useState(initialUserStateAllergies);
  const [editvaluesAllergy, setEditValuesAllergy] = useState(
    initialUserStateAllergies
  );
  const [allergy, setAllergy] = useState(props.allergies);
  const [isaddallergy, setAddAllergy] = useState(false);
  const [isEditAllergy, setIsEditAllergy] = useState(false);

  useEffect(() => {
    if (props.allergies && props.allergies.length > 0) {
      setAllergy(props.allergies);
    }
  }, [props.allergies]);

  const handleFieldChange = (field, value) => {
    setValuesAllergy({ ...valuesAllergy, [field]: value });
  };

  const handleEditFieldChange = (field, value) => {
    setEditValuesAllergy({ ...editvaluesAllergy, [field]: value });
  };
  const handleAddAllergy = async (data, index) => {
    setAddAllergy(true);
    if (data) {
      setValuesAllergy({
        id: data.id,
        name: data.name,
        reaction: data.reaction
      });
    }
  };
  const handleAddAllergyCancel = () => {
    setAddAllergy(false);
    setValuesSH(initialUserStateSH);
  };

  const handleSaveAllergy = async () => {
    if (valuesAllergy.name || valuesAllergy.reaction) {
      const res = await props.saveAllergy(patientId, valuesAllergy);
      if (res.status) {
        setAllergy(res.allergies);
        setValuesAllergy(initialUserStateAllergies);
        props.getAllergy(res.allergies);
      }
    }
    setAddAllergy(false);
  };

  const handleUpdateAllergy = async idx => {
    if (editvaluesAllergy.name || editvaluesAllergy.reaction) {
      const res = await props.updateAllergy(patientId, editvaluesAllergy);
      if (res.status) {
        const newState = [...allergy];
        newState[idx] = res.allergy;
        setAllergy(newState);
        setIsEditAllergy(false);
        props.getAllergy(newState);
      }
    }
  };

  const handleEditAllergy = async (data, index) => {
    setIsEditAllergy(true);
    if (data) {
      setEditValuesAllergy({
        id: data.id,
        name: data.name,
        reaction: data.reaction,
        editIndex: index
      });
    }
  };
  const clearAllergy = () => {
    setIsEditAllergy(false);
    setValuesAllergy(initialUserStateAllergies);
  };

  const _handleAddAllergyClick = event => {
    event.stopPropagation();
    handleAddAllergy();
  };

  const [handledeleteAllergy, setHandleDeleteAllergy] = useState({
    showDeletePopup: false,
    itemId: ''
  });
  const [popup, setPopup] = useState(false);

  const handleOpenDeleteAllergy = id => {
    setHandleDeleteAllergy({
      showDeletePopup: true,
      itemId: id
    });
  };

  const handleCancelDeleteAllergy = () => {
    setHandleDeleteAllergy({ showDeletePopup: false });
  };

  const handleDeleteAllergyOk = async itemId => {
    const res = await props.deleteAllergy(patientId, itemId);
    if (res.status) {
      setAllergy(res.allergies);
      setValuesAllergy(initialUserStateAllergies);
      props.getAllergy(res.allergies);
    }
    setHandleDeleteAllergy({ showDeletePopup: false });
  };

  const renderDeleteAllergyDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteAllergy.showDeletePopup}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete allergy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popup ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteAllergyOk(handledeleteAllergy.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteAllergy}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  //#region Social History
  const initialUserStateSH = {
    id: 0,
    description: ''
  };
  const [valuesSH, setValuesSH] = useState(initialUserStateSH);
  const [editvaluesSH, setEditValuesSH] = useState(initialUserStateSH);
  const [SH, setSH] = useState(props.socialHistory);
  const [isaddSH, setIsAddSH] = useState(false);
  const [isEditSH, setIsEditSH] = useState(false);

  useEffect(() => {
    if (props.socialHistory && props.socialHistory.length > 0) {
      setSH(props.socialHistory);
    }
  }, [props.socialHistory]);

  const handleFieldChangeSH = (field, value) => {
    setValuesSH({ ...valuesSH, [field]: value });
  };

  const handleEditFieldChangeSH = (field, value) => {
    setEditValuesSH({ ...editvaluesSH, [field]: value });
  };
  const handleAddSH = async (data, index) => {
    setIsAddSH(true);
    if (data) {
      setValuesSH({
        id: data.id,
        description: data.description
      });
    }
  };
  const handleAddSHCancel = () => {
    setIsAddSH(false);
    setValuesSH(initialUserStateSH);
  };

  const handleSaveSocialHistory = async () => {
    if (valuesSH.description) {
      const res = await props.saveSocialHistory(patientId, valuesSH);
      if (res.status) {
        setSH(res.socialHistory);
        setValuesSH(initialUserStateSH);
        props.getSocialHistory(res.socialHistory);
      }
    }
    setIsAddSH(false);
  };
  const handleUpdateSocialHistory = async idx => {
    if (editvaluesSH.description) {
      const res = await props.updateSocialHistory(patientId, editvaluesSH);
      if (res.status) {
        const newState = [...SH];
        newState[idx] = res.socialHistory;
        setSH(newState);
        setIsEditSH(false);
        props.getSocialHistory(newState);
      }
    }
  };
  const handleEditSocialHistory = async (data, index) => {
    setIsEditSH(true);

    if (data) {
      setEditValuesSH({
        id: data.id,
        description: data.description,

        editIndex: index
      });
    }
  };
  const clearSocialHistory = () => {
    setIsEditSH(false);
    setValuesSH(initialUserStateSH);
  };
  const _handleAddSHClick = event => {
    event.stopPropagation();
    handleAddSH();
  };
  const [handledeleteSH, setHandleDeleteSH] = useState({
    showDeletePopupSH: false,
    itemId: ''
  });
  const [popupSH, setPopupSH] = useState(false);

  const handleOpenDeleteSH = id => {
    setHandleDeleteSH({
      showDeletePopupSH: true,
      itemId: id
    });
  };
  const handleCancelDeleteSH = () => {
    setHandleDeleteSH({ showDeletePopupSH: false });
  };
  const handleDeleteSHOk = async itemId => {
    const res = await props.deleteSocialHistory(patientId, itemId);
    if (res.status) {
      setSH(res.socialHistory);
      setValuesSH(initialUserStateSH);
      props.getSocialHistory(res.socialHistory);
    }
    setHandleDeleteSH({ showDeletePopupSH: false });
  };
  const renderDeleteSHDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteSH.showDeletePopupSH}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete social history?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupSH ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteSHOk(handledeleteSH.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteSH}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //#endregion

  //#region Family History
  const initialUserStateFH = {
    id: 0,
    description: ''
  };
  const [valuesFH, setValuesFH] = useState(initialUserStateFH);
  const [editvaluesFH, setEditValuesFH] = useState(initialUserStateFH);
  const [FH, setFH] = useState(props.familyHistory);
  const [isaddFH, setIsAddFH] = useState(false);
  const [isEditFH, setIsEditFH] = useState(false);

  useEffect(() => {
    if (props.familyHistory && props.familyHistory.length > 0) {
      setFH(props.familyHistory);
    }
  }, [props.familyHistory]);

  const handleFieldChangeFH = (field, value) => {
    setValuesFH({ ...valuesFH, [field]: value });
  };

  const handleEditFieldChangeFH = (field, value) => {
    setEditValuesFH({ ...editvaluesFH, [field]: value });
  };
  const handleAddFH = async (data, index) => {
    setIsAddFH(true);
    if (data) {
      setValuesFH({
        id: data.id,
        description: data.description
      });
    }
  };
  const handleAddFHCancel = () => {
    setIsAddFH(false);
    setValuesFH(initialUserStateFH);
  };
  const handleSaveFamilyHistory = async () => {
    if (valuesFH.description) {
      const res = await props.saveFamilyHistory(patientId, valuesFH);
      if (res.status) {
        setFH(res.familyHistory);
        setValuesFH(initialUserStateFH);
        props.getFamilyHistory(res.familyHistory);
      }
    }
    setIsAddFH(false);
  };
  const handleUpdateFamilyHistory = async idx => {
    if (editvaluesFH.description) {
      const res = await props.updateFamilyHistory(patientId, editvaluesFH);
      if (res.status) {
        const newState = [...FH];
        newState[idx] = res.familyHistory;
        setFH(newState);
        setIsEditFH(false);
        props.getFamilyHistory(newState);
      }
    }
  };
  const handleEditFamilyHistory = async (data, index) => {
    setIsEditFH(true);

    if (data) {
      setEditValuesFH({
        id: data.id,
        description: data.description,
        editIndex: index
      });
    }
  };
  const clearFamilyHistory = () => {
    setIsEditFH(false);
    setValuesFH(initialUserStateFH);
  };
  const _handleAddFHClick = event => {
    event.stopPropagation();
    handleAddFH();
  };
  const [handledeleteFH, setHandleDeleteFH] = useState({
    showDeletePopupFH: false,
    itemId: ''
  });
  const [popupFH, setPopupFH] = useState(false);
  const handleOpenDeleteFH = id => {
    setHandleDeleteFH({
      showDeletePopupFH: true,
      itemId: id
    });
  };
  const handleCancelDeleteFH = () => {
    setHandleDeleteFH({ showDeletePopupFH: false });
  };
  const handleDeleteFHOk = async itemId => {
    const res = await props.deleteFamilyHistory(patientId, itemId);
    if (res.status) {
      setFH(res.familyHistory);
      setValuesFH(initialUserStateFH);
      props.getFamilyHistory(res.familyHistory);
    }
    setHandleDeleteFH({ showDeletePopupFH: false });
  };
  const renderDeleteFHDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteFH.showDeletePopupFH}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete family history?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupFH ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteFHOk(handledeleteFH.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteFH}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //#endregion

  //#region Advance Directives
  const initialUserStateAD = {
    id: 0,
    description: ''
  };
  const [valuesAD, setValuesAD] = useState(initialUserStateAD);
  const [editvaluesAD, setEditValuesAD] = useState(initialUserStateAD);
  const [AD, setAD] = useState(props.advanceDirective);
  const [isaddAD, setIsAddAD] = useState(false);
  const [isEditAD, setIsEditAD] = useState(false);

  useEffect(() => {
    if (props.advanceDirectives && props.advanceDirectives.length > 0) {
      setAD(props.advanceDirectives);
    }
  }, [props.advanceDirectives]);

  const handleFieldChangeAD = (field, value) => {
    setValuesAD({ ...valuesAD, [field]: value });
  };
  const handleEditFieldChangeAD = (field, value) => {
    setEditValuesAD({ ...editvaluesAD, [field]: value });
  };
  const handleAddAD = async (data, index) => {
    setIsAddAD(true);
    if (data) {
      setValuesAD({
        id: data.id,
        description: data.description
      });
    }
  };
  const handleAddADCancel = () => {
    setIsAddAD(false);
    setValuesAD(initialUserStateAD);
  };
  const handleSaveAdvanceDirective = async () => {
    if (valuesAD.description) {
      const res = await props.saveAdvanceDirective(patientId, valuesAD);
      if (res.status) {
        setAD(res.advanceDirective);
        setValuesAD(initialUserStateAD);
        props.getAdvanceDirectives(res.advanceDirective);
      }
    }
    setIsAddAD(false);
  };
  const handleUpdateAdvanceDirective = async idx => {
    if (editvaluesAD.description) {
      const res = await props.updateAdvanceDirective(patientId, editvaluesAD);
      if (res.status) {
        const newState = [...AD];
        newState[idx] = res.advanceDirective;
        setAD(newState);
        setIsEditAD(false);
        props.getAdvanceDirectives(newState);
      }
    }
  };
  const handleEditAdvanceDirective = async (data, index) => {
    setIsEditAD(true);

    if (data) {
      setEditValuesAD({
        id: data.id,
        description: data.description,

        editIndex: index
      });
    }
  };
  const clearAdvanceDirective = () => {
    setIsEditAD(false);
    setValuesAD(initialUserStateAD);
  };
  const _handleAddADClick = event => {
    event.stopPropagation();
    handleAddAD();
  };
  const [handledeleteAD, setHandleDeleteAD] = useState({
    showDeletePopupAD: false,
    itemId: ''
  });
  const [popupAD, setPopupAD] = useState(false);
  const handleOpenDeleteAD = id => {
    setHandleDeleteAD({
      showDeletePopupAD: true,
      itemId: id
    });
  };
  const handleCancelDeleteAD = () => {
    setHandleDeleteAD({ showDeletePopupAD: false });
  };
  const handleDeleteADOk = async itemId => {
    const res = await props.deleteAdvanceDirective(patientId, itemId);
    if (res.status) {
      setAD(res.advanceDirective);
      setValuesAD(initialUserStateAD);
      props.getAdvanceDirectives(res.advanceDirective);
    }
    setHandleDeleteAD({ showDeletePopupAD: false });
  };
  const renderDeleteADDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteAD.showDeletePopupAD}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete advance directive?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupAD ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteADOk(handledeleteAD.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteAD}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //#endregion

  //#region Additional items

  const initialUserStateAI = {
    id: 0,
    description: ''
  };
  const [valuesAI, setValuesAI] = useState(initialUserStateAI);
  const [editvaluesAI, setEditValuesAI] = useState(initialUserStateAI);
  const [AI, setAI] = useState(props.additionalItems);
  const [isaddAI, setIsAddAI] = useState(false);
  const [isEditAI, setIsEditAI] = useState(false);

  useEffect(() => {
    if (props.additionalItems && props.additionalItems.length > 0) {
      setAI(props.additionalItems);
    }
  }, [props.additionalItems]);

  const handleFieldChangeAI = (field, value) => {
    setValuesAI({ ...valuesAI, [field]: value });
  };
  const handleEditFieldChangeAI = (field, value) => {
    setEditValuesAI({ ...editvaluesAI, [field]: value });
  };
  const handleAddAI = async (data, index) => {
    setIsAddAI(true);
    if (data) {
      setValuesAI({
        id: data.id,
        description: data.description
      });
    }
  };
  const handleAddAICancel = () => {
    setIsAddAI(false);
    setValuesAI(initialUserStateAI);
  };
  const handleSaveAdditionalItems = async () => {
    if (valuesAI.description) {
      const res = await props.saveAdditionalItems(patientId, valuesAI);
      if (res.status) {
        setAI(res.additionalItems);
        setValuesAI(initialUserStateAI);
        props.getAdditionalItems(res.additionalItems);
      }
    }
    setIsAddAI(false);
  };
  const handleUpdateAdditionalItems = async idx => {
    if (editvaluesAI.description) {
      const res = await props.updateAdditionalItems(patientId, editvaluesAI);
      if (res.status) {
        const newState = [...AI];
        newState[idx] = res.additionalItems;
        setAI(newState);
        setIsEditAI(false);
        props.getAdditionalItems(newState);
      }
    }
  };
  const handleEditAdditionalItems = async (data, index) => {
    setIsEditAI(true);
    if (data) {
      setEditValuesAI({
        id: data.id,
        description: data.description,
        editIndex: index
      });
    }
  };
  const clearAdditionalItems = () => {
    setIsEditAI(false);
    setValuesAI(initialUserStateAI);
  };
  const _handleAddAIClick = event => {
    event.stopPropagation();
    handleAddAI();
  };
  const [handledeleteAI, setHandleDeleteAI] = useState({
    showDeletePopupAI: false,
    itemId: ''
  });
  const [popupAI, setPopupAI] = useState(false);
  const handleOpenDeleteAI = id => {
    setHandleDeleteAI({
      showDeletePopupAI: true,
      itemId: id
    });
  };
  const handleCancelDeleteAI = () => {
    setHandleDeleteAI({ showDeletePopupAI: false });
  };
  const handleDeleteAIOk = async itemId => {
    const res = await props.deleteAdditionalItems(patientId, itemId);
    if (res.status) {
      setAI(res.additionalItems);
      setValuesAI(initialUserStateAI);
      props.getAdditionalItems(res.additionalItems);
    }
    setHandleDeleteAI({ showDeletePopupAI: false });
  };
  const renderDeleteAIDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteAI.showDeletePopupAI}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete additional item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupAI ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteAIOk(handledeleteAI.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteAI}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  //#region Health maintenance
  const initialUserStateHM = {
    id: 0,
    name: '',
    recordedDate: new Date(),
    findings: ''
  };
  const [valuesHM, setValuesHM] = useState(initialUserStateHM);
  const [editvaluesHM, setEditValuesHM] = useState(initialUserStateHM);
  const [HM, setHM] = useState(props.healthMaintenance);
  const [isaddHM, setIsAddHM] = useState(false);

  const [isEditHM, setIsEditHM] = useState(false);

  useEffect(() => {
    if (props.healthMaintenance && props.healthMaintenance.length > 0) {
      setHM(props.healthMaintenance);
    }
  }, [props.healthMaintenance]);

  const handleFieldChangeHM = (field, value) => {
    setValuesHM({ ...valuesHM, [field]: value });
  };
  const handleEditFieldChangeHM = (field, value) => {
    setEditValuesHM({ ...editvaluesHM, [field]: value });
  };
  const handleAddHM = async (data, index) => {
    setIsAddHM(true);
    if (data) {
      setValuesHM({
        id: data.id,
        name: data.name,
        recordedDate: data.recordedDate,
        findings: data.findings,
        editIndex: index
      });
    }
  };
  const handleAddHMCancel = () => {
    setIsAddHM(false);
    setValuesHM(initialUserStateHM);
  };
  const handleSaveHealthMaintenance = async () => {
    if (valuesHM.name || valuesHM.findings) {
      const res = await props.saveHealthMaintenance(patientId, valuesHM);
      if (res.status) {
        setHM(res.healthMaintenance);
        setValuesHM(initialUserStateHM);
        props.getHealthMaintenance(res.healthMaintenance);
      }
    }
    setIsAddHM(false);
  };
  const handleUpdateHealthMaintenance = async idx => {
    if (editvaluesHM.name || editvaluesHM.findings) {
      const res = await props.updateHealthMaintenance(patientId, editvaluesHM);
      if (res.status) {
        const newState = [...HM];
        newState[idx] = res.healthMaintenance;
        setHM(newState);
        setIsEditHM(false);
        props.getHealthMaintenance(newState);
      }
    }
  };
  const handleEditHealthMaintenance = async (data, index) => {
    setIsEditHM(true);

    if (data) {
      setEditValuesHM({
        id: data.id,
        name: data.name,
        recordedDate: data.recordedDate,
        findings: data.findings,
        editIndex: index
      });
    }
  };
  const clearHealthMaintenance = () => {
    setIsEditHM(false);
    setValuesHM(initialUserStateHM);
  };
  const _handleAddHMClick = event => {
    event.stopPropagation();
    handleAddHM();
  };
  const [handledeleteHM, setHandleDeleteHM] = useState({
    showDeletePopupHM: false,
    itemId: ''
  });
  const [popupHM, setPopupHM] = useState(false);
  const handleOpenDeleteHM = id => {
    setHandleDeleteHM({
      showDeletePopupHM: true,
      itemId: id
    });
  };
  const handleCancelDeleteHM = () => {
    setHandleDeleteHM({ showDeletePopupHM: false });
  };
  const handleDeleteHMOk = async itemId => {
    const res = await props.deleteHealthMaintenance(
      patientId,
      itemId,
      props.activeTab
    );
    if (res.status) {
      setHM(res.healthMaintenance);
      setValuesHM(initialUserStateHM);
      props.getHealthMaintenance(res.healthMaintenance);
    }
    setHandleDeleteHM({ showDeletePopupHM: false });
  };
  const renderDeleteHMDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteHM.showDeletePopupHM}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete health maintenance?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupHM ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteHMOk(handledeleteHM.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteHM}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  //#endregion

  //#region  vaccination
  const initialUserStateVC = {
    id: 0,
    name: '',
    dategiven: new Date(),
    lot: ''
  };
  const [valuesVC, setValuesVC] = useState(initialUserStateVC);
  const [editvaluesVC, setEditValuesVC] = useState(initialUserStateVC);
  const [VC, setVC] = useState(props.vaccinations);
  const [isaddVC, setIsAddVC] = useState(false);

  const [isEditVC, setIsEditVC] = useState(false);
  const handleFieldChangeVC = (field, value) => {
    setValuesVC({ ...valuesVC, [field]: value });
  };
  const handleEditFieldChangeVC = (field, value) => {
    setEditValuesVC({ ...editvaluesVC, [field]: value });
  };
  const handleAddVC = async (data, index) => {
    setIsAddVC(true);
    if (data) {
      setValuesVC({
        id: data.id,
        name: data.name,
        dategiven: data.dategiven,
        lot: data.lot,
        editIndex: index
      });
    }
  };
  const handleAddVCCancel = () => {
    setIsAddVC(false);
    setValuesVC(initialUserStateVC);
  };
  const handleSaveVaccinations = async () => {
    if (valuesVC.name || valuesVC.lot) {
      const res = await props.saveVaccinations(patientId, valuesVC);
      if (res.status) {
        setVC(res.vaccinations);
        setValuesVC(initialUserStateVC);
        props.getVaccination(res.vaccinations);
      }
    }
    setIsAddVC(false);
  };
  const handleUpdateVaccinations = async idx => {
    if (editvaluesVC.name || editvaluesVC.lot) {
      const res = await props.updateVaccinations(patientId, editvaluesVC);
      if (res.status) {
        const newState = [...VC];
        newState[idx] = res.vaccinations;
        setVC(newState);
        setIsEditVC(false);
        props.getVaccination(newState);
      }
    }
  };
  const handleEditVaccinations = async (data, index) => {
    setIsEditVC(true);

    if (data) {
      setEditValuesVC({
        id: data.id,
        name: data.name,
        dategiven: data.dategiven,
        lot: data.lot,
        editIndex: index
      });
    }
  };
  const clearVaccinations = () => {
    setIsEditVC(false);
    setValuesVC(initialUserStateVC);
  };
  const _handleAddVCClick = event => {
    event.stopPropagation();
    handleAddVC();
  };
  const [handledeleteVC, setHandleDeleteVC] = useState({
    showDeletePopupVC: false,
    itemId: ''
  });
  const [popupVC, setPopupVC] = useState(false);
  const handleOpenDeleteVC = id => {
    setHandleDeleteVC({
      showDeletePopupVC: true,
      itemId: id
    });
  };
  const handleCancelDeleteVC = () => {
    setHandleDeleteVC({ showDeletePopupVC: false });
  };
  const handleDeleteVCOk = async itemId => {
    const res = await props.deleteVaccinations(patientId, itemId);
    if (res.status) {
      setVC(res.vaccinations);
      setValuesVC(initialUserStateVC);
      props.getVaccination(res.vaccinations);
    }
    setHandleDeleteVC({ showDeletePopupVC: false });
  };
  const renderDeleteVaccinationDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteVC.showDeletePopupVC}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete vaccination?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupVC ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteVCOk(handledeleteVC.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteVC}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion vaccination

  //#region Medication

  const initialUserStateMed = {
    id: 0,
    name: '',
    dose: '',
    directions: ''
  };

  const [valuesMed, setValuesMed] = useState(initialUserStateMed);
  const [editvaluesMed, setEditValuesMed] = useState(initialUserStateMed);
  const [med, setMed] = useState([]);
  const [inactivemed, setInactiveMed] = useState([]);
  const [isaddMed, setIsAddMed] = useState(false);
  const [isEditMed, setIsEditMed] = useState(false);
  const [handledeleteMed, setHandleDeleteMed] = useState({
    showDeletePopupMed: false,
    itemId: ''
  });
  const [popupMed, setPopupMed] = useState(false);

  useEffect(() => {
    if (props.medication && props.medication.length > 0) {
      setMed(props.medication);
    }
  }, [props.medication]);

  useEffect(() => {
    if (props.inactivemedication && props.inactivemedication.length > 0) {
      setInactiveMed(props.inactivemedication);
    }
  }, [props.inactivemedication]);

  const handleFieldChangeMed = (field, value) => {
    setValuesMed({ ...valuesMed, [field]: value });
  };
  const handleEditFieldChangeMed = (field, value) => {
    setEditValuesMed({ ...editvaluesMed, [field]: value });
  };
  const handleAddMed = async (data, index) => {
    setIsAddMed(true);
    if (data) {
      setValuesMed({
        id: data.id,
        name: data.name,
        dose: data.dose,
        directions: data.directions,
        editIndex: index
      });
    }
  };
  const handleAddMedCancel = () => {
    setIsAddMed(false);
    setValuesMed(initialUserStateMed);
  };
  const handleSaveMedication = async () => {
    if (valuesMed.name || valuesMed.dose || valuesMed.directions) {
      const res = await props.saveMedication(patientId, valuesMed);
      if (res.status) {
        getPatientInactiveDiagnosis(patientId)
        getPatientActiveMedications(patientId)
        // let newres = await props.getSoapActiveInactiveMedication(patientId)
        // if(newres){
        //   props.getMedication(newres.medications);
        //   setMed(newres.medications);
        // }

        setValuesMed(initialUserStateMed);
      }
    }
    setIsAddMed(false);
  };
  const handleUpdateMedication = async idx => {
    if (editvaluesMed.name || editvaluesMed.reaction) {
      const res = await props.updateMedication(patientId, editvaluesMed);
      if (res.status) {
        const newState = [...med];
        newState[idx] = res.medication;
        setMed(newState);
        props.getMedication(newState);
        setIsEditMed(false);
      }
    }
  };
  const handleEditMedication = async (data, index) => {
    setIsEditMed(true);
    if (data) {
      setEditValuesMed({
        id: data.id,
        name: data.name,
        dose: data.dose,
        directions: data.directions,
        editIndex: index
      });
    }
  };

  const handleInactivateMedication = async item => {
    const res = await props.inactivateMedication(patientId, item);
    if (res.status) {
      setInactiveMed(res.inactiveMedication);
      props.getInactiveMedication(res.inactiveMedication);
      if (med) {
        let newState = [...med];
        setMed(newState.filter(c => c.id !== item.id));
        props.getMedication(newState.filter(c => c.id !== item.id));
      }
    }
  };

  const handleActivateMedication = async item => {
    const res = await props.activateMedication(patientId, item);
    if (res.status) {
      setMed(res.activeMedication);
      props.getMedication(res.activeMedication);
      if (inactivemed) {
        let newState = [...inactivemed];
        setInactiveMed(newState.filter(c => c.id !== item.id));
        props.getInactiveMedication(newState.filter(c => c.id !== item.id));
      }
    }
  };
  const clearMedication = () => {
    setIsEditMed(false);
    setValuesMed(initialUserStateMed);
  };
  const _handleAddMedClick = event => {
    event.stopPropagation();
    handleAddMed();
  };

  const handleOpenDeleteMed = id => {
    setHandleDeleteMed({
      showDeletePopupMed: true,
      itemId: id
    });
  };
  const handleCancelDeleteMed = () => {
    setHandleDeleteMed({ showDeletePopupMed: false });
  };
  const handleDeleteMedOk = async itemId => {
    const res = await props.deleteMedication(patientId, itemId);
    if (res.status) {
      if (inactivemed) {
        let newState = [...inactivemed];
        setInactiveMed(newState.filter(c => c.id !== itemId));
        props.getInactiveMedication(newState.filter(c => c.id !== itemId));
      }
    }
    setHandleDeleteMed({ showDeletePopupMed: false });
  };
  const renderDeleteMedicationDialogue = () => {
    return (
      <Dialog
        aria-labelledby="confirmation-dialog-title"
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        open={handledeleteMed.showDeletePopupMed}>
        <DialogTitle id="confirmation-dialog-title">
          Patient MedicalHx Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete medication?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {popupMed ? (
            <CircularProgress className={classes.progress} size={20} />
          ) : (
            <Button
              color="primary"
              onClick={() => handleDeleteMedOk(handledeleteMed.itemId)}>
              Yes
            </Button>
          )}
          <Button color="primary" onClick={handleCancelDeleteMed}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  //#endregion

  return (
    <Grid className={classes.rightWrap} item xs={12}>
      <PerfectScrollbar style={{ overflowX: 'hidden!important' }}>
        <div className={clsx(classes.MHXwrap, classes.MHXsoapWrap)}>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header"
              style={{ alignItems: 'center' }}>
              <Typography className={classes.heading}>Allergies</Typography>
              <IconButton
                onClick={_handleAddAllergyClick}
                size="small"
                title="Add New Allergy">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.allergyTable)}>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">Allergy Type</TableCell>
                    <TableCell width="calc(60% - 100px)">Reaction</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddallergy ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChange('name', event.target.value)
                          }
                          placeholder="Add New Allergy"
                          value={valuesAllergy.name}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChange('reaction', event.target.value)
                          }
                          placeholder="Add Reaction"
                          value={valuesAllergy.reaction}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveAllergy()}
                          size="small">
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddAllergyCancel()}
                          size="small">
                          <ClearOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {allergy &&
                    allergy.length > 0 &&
                    allergy
                      .sort((a, b) => b.id - a.id)
                      .map((item, idx) => {
                        return (
                          <TableRow>
                            {isEditAllergy &&
                              idx === editvaluesAllergy.editIndex ? (
                              <>
                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleEditFieldChange(
                                        'name',
                                        event.target.value
                                      )
                                    }
                                    placeholder="Input Name"
                                    value={editvaluesAllergy.name}
                                    variant="outlined"
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleEditFieldChange(
                                        'reaction',
                                        event.target.value
                                      )
                                    }
                                    placeholder="Input Reaction"
                                    value={editvaluesAllergy.reaction}
                                    variant="outlined"
                                  />
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.reaction}</TableCell>
                              </>
                            )}

                            <TableCell align="center">
                              {isEditAllergy &&
                                idx === editvaluesAllergy.editIndex ? (
                                <>
                                  <IconButton
                                    className={classes.iconGreen}
                                    onClick={() => handleUpdateAllergy(idx)}
                                    size="small">
                                    <CheckOutlined />
                                  </IconButton>
                                  <IconButton
                                    className={classes.iconRed}
                                    onClick={() => clearAllergy()}
                                    size="small">
                                    <ClearOutlined />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    onClick={() => handleEditAllergy(item, idx)}
                                    size="small">
                                    <EditOutlined />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleOpenDeleteAllergy(item.id)
                                    }
                                    size="small">
                                    <DeleteOutlined
                                      className={classes.delIcon}
                                    />
                                  </IconButton>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>Medications</Typography>
              <IconButton onClick={_handleAddMedClick} size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.medicTable)}>
                <TableHead>
                  <TableRow>
                    <TableCell width="40%">Medication</TableCell>
                    <TableCell width="20%">Dose</TableCell>
                    <TableCell width="calc(40% - 100px)">Direction</TableCell>
                    <TableCell
                      align="center"
                      style={{ width: 100, whiteSpace: 'nowrap' }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddMed ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeMed('name', event.target.value)
                          }
                          placeholder="Input Name"
                          value={valuesMed.name}
                          variant="outlined"
                        />
                      </TableCell>

                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeMed('dose', event.target.value)
                          }
                          placeholder="Input dose"
                          value={valuesMed.dose}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeMed(
                              'directions',
                              event.target.value
                            )
                          }
                          placeholder="Input directions"
                          value={valuesMed.directions}
                          variant="outlined"
                        />
                      </TableCell>

                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveMedication()}
                          size="small">
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddMedCancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {med &&
                    med.length > 0 &&
                    med
                      .sort((a, b) => b.id - a.id)
                      .map((item, idx) => {
                        return (
                          <TableRow>
                            {isEditMed && idx === editvaluesMed.editIndex ? (
                              <>
                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleEditFieldChangeMed(
                                        'name',
                                        event.target.value
                                      )
                                    }
                                    placeholder="Input name"
                                    value={editvaluesMed.name}
                                    variant="outlined"
                                  />
                                </TableCell>

                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleEditFieldChangeMed(
                                        'dose',
                                        event.target.value
                                      )
                                    }
                                    placeholder="Input dose"
                                    value={editvaluesMed.dose}
                                    variant="outlined"
                                  />
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    className={classes.textBox}
                                    id="outlined-basic"
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes.notchedOutline
                                      }
                                    }}
                                    onChange={event =>
                                      handleEditFieldChangeMed(
                                        'directions',
                                        event.target.value
                                      )
                                    }
                                    placeholder="Input directions"
                                    value={editvaluesMed.directions}
                                    variant="outlined"
                                  />
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.dose}</TableCell>
                                <TableCell>{item.directions}</TableCell>
                              </>
                            )}
                            <TableCell align="center">
                              {isEditMed && idx === editvaluesMed.editIndex ? (
                                <>
                                  <IconButton
                                    className={classes.iconGreen}
                                    onClick={() => handleUpdateMedication(idx)}
                                    size="small">
                                    <CheckOutlined />
                                  </IconButton>
                                  <IconButton
                                    className={classes.iconRed}
                                    onClick={() => clearMedication()}
                                    size="small">
                                    <ClearOutlined
                                      style={{ color: '#DE5555' }}
                                    />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <IconButton
                                    onClick={() =>
                                      handleInactivateMedication(item, idx)
                                    }
                                    size="small"
                                    title="Active">
                                    <CheckCircleOutlineOutlined />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleEditMedication(item, idx)
                                    }
                                    size="small">
                                    <EditOutlined />
                                  </IconButton>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}

                  {inactivemed &&
                    inactivemed.length > 0 &&
                    inactivemed
                      .sort((a, b) => b.id - a.id)
                      .map((item, idx) => {
                        return (
                          <TableRow className={classes.inactiveRow}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.dose}</TableCell>
                            <TableCell>{item.directions}</TableCell>

                            <TableCell align="center" width={72}>
                              {/* {isEditMed && idx === editvaluesMed.editIndex ? (
                              <>
                                <IconButton
                                  onClick={() => handleUpdateMedication(idx)}
                                  size="small"
                                  style={{ color: '#03A484' }}>
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => clearMedication()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : ( */}
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleActivateMedication(item, idx)
                                  }
                                  size="small"
                                  title="Inactive">
                                  <BlockOutlined style={{ color: '#ccc' }} />
                                </IconButton>

                                <IconButton
                                  onClick={() => handleOpenDeleteMed(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                              {/* )} */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>
                Social History
              </Typography>
              <IconButton onClick={_handleAddSHClick} size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.socialHTable)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Social History</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddSH ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeSH(
                              'description',
                              event.target.value
                            )
                          }
                          placeholder="Input description"
                          value={valuesSH.description}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveSocialHistory()}
                          size="small">
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddSHCancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {SH &&
                    SH.length > 0 &&
                    SH.sort((a, b) => b.id - a.id).map((item, idx) => {
                      return (
                        <TableRow>
                          {isEditSH && idx === editvaluesSH.editIndex ? (
                            <>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeSH(
                                      'description',
                                      event.target.value
                                    )
                                  }
                                  // placeholder="Input description"
                                  value={editvaluesSH.description}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item.description}</TableCell>
                            </>
                          )}
                          <TableCell align="center">
                            {isEditSH && idx === editvaluesSH.editIndex ? (
                              <>
                                <IconButton
                                  className={classes.iconGreen}
                                  onClick={() => handleUpdateSocialHistory(idx)}
                                  size="small">
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  className={classes.iconRed}
                                  onClick={() => clearSocialHistory()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleEditSocialHistory(item, idx)
                                  }
                                  size="small">
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpenDeleteSH(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>
                Health Maintenance
              </Typography>
              <IconButton onClick={_handleAddHMClick} size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.healthMaint)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Health Maintenance</TableCell>
                    <TableCell>Date Recorded</TableCell>
                    <TableCell>Findings</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddHM ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeHM('name', event.target.value)
                          }
                          placeholder="Input Name"
                          value={valuesHM.name}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>

                        <DatePicker
                          customInput={
                            <TextField
                              className={classes.textField}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <CalendarToday
                                      style={{ height: 16, width: 16 }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              // label="Date Recorded"
                              margin="dense"
                              variant="outlined"
                            />
                          }
                          format="MM/DD/YYYY"
                          onBlur={e => (e.target.placeholder = 'Date of birth')}
                          onChange={date =>
                            handleFieldChangeHM(
                              'recordedDate',
                              date
                            )
                          }
                          onFocus={e => (e.target.placeholder = '')}
                          placeholderText="Date Recorded"
                          selected={
                            valuesHM.recordedDate &&
                            new Date(valuesHM.recordedDate)
                          }
                          showMonthDropdown="bool"
                          showYearDropdown="bool"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeHM('findings', event.target.value)
                          }
                          placeholder="Input Findings"
                          value={valuesHM.findings}
                          variant="outlined"
                        />
                      </TableCell>

                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveHealthMaintenance()}
                          size="small">
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddHMCancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {HM &&
                    HM.length > 0 &&
                    HM.sort((a, b) => b.id - a.id).map((item, idx) => {
                      return (
                        <TableRow>
                          {isEditHM && idx === editvaluesHM.editIndex ? (
                            <>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeHM(
                                      'name',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input Name"
                                  value={editvaluesHM.name}
                                  variant="outlined"
                                />
                              </TableCell>
                              <TableCell>
                                <DatePicker
                                  customInput={
                                    <TextField
                                      className={classes.textField}
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment>
                                            <CalendarToday
                                              style={{ height: 16, width: 16 }}
                                            />
                                          </InputAdornment>
                                        )
                                      }}
                                      // label="Date of birth"
                                      margin="dense"
                                      variant="outlined"
                                    />
                                  }
                                  format="MM/DD/YYYY"
                                  onBlur={e => (e.target.placeholder = 'Date of birth')}
                                  onChange={date =>
                                    handleEditFieldChangeHM(
                                      'recordedDate',
                                      date
                                    )
                                  }
                                  onFocus={e => (e.target.placeholder = '')}
                                  placeholderText="Date of birth"
                                  selected={
                                    editvaluesHM.recordedDate &&
                                    new Date(editvaluesHM.recordedDate)
                                  }
                                  showMonthDropdown="bool"
                                  showYearDropdown="bool"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeHM(
                                      'findings',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input Findings"
                                  value={editvaluesHM.findings}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item && item.name}</TableCell>
                              <TableCell>
                                {item && item.recordedDate
                                  ? moment(item.recordedDate).format(
                                    'MM/DD/YYYY'
                                  )
                                  : ''}
                              </TableCell>
                              <TableCell>{item && item.findings}</TableCell>
                            </>
                          )}
                          <TableCell align="center">
                            {isEditHM && idx === editvaluesHM.editIndex ? (
                              <>
                                <IconButton
                                  className={classes.iconGreen}
                                  onClick={() =>
                                    handleUpdateHealthMaintenance(idx)
                                  }
                                  size="small">
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  className={classes.iconRed}
                                  onClick={() => clearHealthMaintenance()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleEditHealthMaintenance(item, idx)
                                  }
                                  size="small">
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpenDeleteHM(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>Vaccination</Typography>
              <IconButton onClick={_handleAddVCClick} size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable, classes.vaccination)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Vaccinations</TableCell>
                    <TableCell>Date Recorded</TableCell>
                    <TableCell>Comments</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddVC ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeVC('name', event.target.value)
                          }
                          placeholder="Input Name"
                          value={valuesVC.name}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>

                        <DatePicker
                          customInput={
                            <TextField
                              className={classes.textField}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment>
                                    <CalendarToday
                                      style={{ height: 16, width: 16 }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              // label="Date Recorded"
                              margin="dense"
                              variant="outlined"
                            />
                          }
                          format="MM/DD/YYYY"
                          onBlur={e => (e.target.placeholder = 'dategiven')}
                          onChange={date =>
                            handleFieldChangeVC(
                              'dategiven',
                              date
                            )
                          }
                          onFocus={e => (e.target.placeholder = '')}
                          placeholderText="dategiven"
                          selected={
                            valuesVC.dategiven &&
                            new Date(valuesVC.dategiven)
                          }
                          showMonthDropdown="bool"
                          showYearDropdown="bool"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeVC('lot', event.target.value)
                          }
                          placeholder="Comments"
                          value={valuesVC.lot}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveVaccinations()}
                          size="small">
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddVCCancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {VC &&
                    VC.length > 0 &&
                    VC.sort((a, b) => b.id - a.id).map((item, idx) => {
                      return (
                        <TableRow>
                          {isEditVC && idx === editvaluesVC.editIndex ? (
                            <>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeVC(
                                      'name',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Input Name"
                                  value={editvaluesVC.name}
                                  variant="outlined"
                                />
                              </TableCell>

                              <TableCell>

                                <DatePicker
                                  customInput={
                                    <TextField
                                      className={classes.textField}
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment>
                                            <CalendarToday
                                              style={{ height: 16, width: 16 }}
                                            />
                                          </InputAdornment>
                                        )
                                      }}
                                      // label="Date Recorded"
                                      margin="dense"
                                      variant="outlined"
                                    />
                                  }
                                  format="MM/DD/YYYY"
                                  onBlur={e => (e.target.placeholder = 'dategiven')}
                                  onChange={date =>
                                    handleEditFieldChangeVC(
                                      'dategiven',
                                      date
                                    )
                                  }
                                  onFocus={e => (e.target.placeholder = '')}
                                  placeholderText="dategiven"
                                  selected={
                                    editvaluesVC.dategiven &&
                                    new Date(editvaluesVC.dategiven)
                                  }
                                  showMonthDropdown="bool"
                                  showYearDropdown="bool"
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeVC(
                                      'lot',
                                      event.target.value
                                    )
                                  }
                                  placeholder="Comments"
                                  value={editvaluesVC.lot}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item && item.name}</TableCell>
                              <TableCell>
                                {item && item.dategiven
                                  ? moment(item.dategiven).format('MM/DD/YYYY')
                                  : ''}
                              </TableCell>
                              <TableCell>{item && item.lot}</TableCell>
                            </>
                          )}
                          <TableCell align="center">
                            {isEditVC && idx === editvaluesVC.editIndex ? (
                              <>
                                <IconButton
                                  className={classes.iconGreen}
                                  onClick={() => handleUpdateVaccinations(idx)}
                                  size="small">
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  className={classes.iconRed}
                                  onClick={() => clearVaccinations()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleEditVaccinations(item, idx)
                                  }
                                  size="small">
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpenDeleteVC(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>
                Family History
              </Typography>
              <IconButton
                //  onClick={
                onClick={_handleAddFHClick}
                size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table
                className={clsx(classes.plainTable, classes.FamilyHistory)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Family History</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddFH ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeFH(
                              'description',
                              event.target.value
                            )
                          }
                          placeholder="Input description"
                          value={valuesFH.description}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveFamilyHistory()}
                          size="small">
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddFHCancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {FH &&
                    FH.length > 0 &&
                    FH.sort((a, b) => b.id - a.id).map((item, idx) => {
                      return (
                        <TableRow>
                          {isEditFH && idx === editvaluesFH.editIndex ? (
                            <>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeFH(
                                      'description',
                                      event.target.value
                                    )
                                  }
                                  value={editvaluesFH.description}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item.description}</TableCell>
                            </>
                          )}
                          <TableCell align="center">
                            {isEditFH && idx === editvaluesFH.editIndex ? (
                              <>
                                <IconButton
                                  className={classes.iconGreen}
                                  onClick={() => handleUpdateFamilyHistory(idx)}
                                  size="small">
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  className={classes.iconRed}
                                  onClick={() => clearFamilyHistory()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleEditFamilyHistory(item, idx)
                                  }
                                  size="small">
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpenDeleteFH(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>
                Advance Directives
              </Typography>
              <IconButton
                //  onClick={
                onClick={_handleAddADClick}
                size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable)}>
                <TableHead>
                  <TableRow>
                    <TableCell> Advance Directives</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddAD ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeAD(
                              'description',
                              event.target.value
                            )
                          }
                          placeholder="Input description"
                          value={valuesAD.description}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveAdvanceDirective()}
                          size="small"
                          style={{ color: '#03A484' }}>
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddADCancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {AD &&
                    AD.length > 0 &&
                    AD.sort((a, b) => b.id - a.id).map((item, idx) => {
                      return (
                        <TableRow>
                          {isEditAD && idx === editvaluesAD.editIndex ? (
                            <>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeAD(
                                      'description',
                                      event.target.value
                                    )
                                  }
                                  value={editvaluesAD.description}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item.description}</TableCell>
                            </>
                          )}
                          <TableCell align="center">
                            {isEditAD && idx === editvaluesAD.editIndex ? (
                              <>
                                <IconButton
                                  className={classes.iconGreen}
                                  onClick={() =>
                                    handleUpdateAdvanceDirective(idx)
                                  }
                                  size="small"
                                  style={{ color: '#03A484' }}>
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  className={classes.iconRed}
                                  onClick={() => clearAdvanceDirective()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleEditAdvanceDirective(item, idx)
                                  }
                                  size="small">
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpenDeleteAD(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel
            className={classes.accordionWrap}
            defaultExpanded="true">
            <ExpansionPanelSummary
              aria-controls="panel1a-content"
              className={classes.accordionHeader}
              expandIcon={<ExpandMore />}
              id="panel1a-header">
              <Typography className={classes.heading}>
                Additional Items
              </Typography>
              <IconButton
                //  onClick={
                onClick={_handleAddAIClick}
                size="small">
                <AddCircleOutlineOutlined className={classes.iconBlue} />
              </IconButton>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Table className={clsx(classes.plainTable)}>
                <TableHead>
                  <TableRow>
                    <TableCell> Additional Items</TableCell>
                    <TableCell style={{ width: 100, whiteSpace: 'nowrap' }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isaddAI ? (
                    <TableRow>
                      <TableCell>
                        <TextField
                          className={classes.textBox}
                          id="outlined-basic"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.notchedOutline
                            }
                          }}
                          onChange={event =>
                            handleFieldChangeAI(
                              'description',
                              event.target.value
                            )
                          }
                          placeholder="Input description"
                          value={valuesAI.description}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          className={classes.iconGreen}
                          onClick={() => handleSaveAdditionalItems()}
                          size="small"
                          style={{ color: '#03A484' }}>
                          <CheckOutlined />
                        </IconButton>
                        <IconButton
                          className={classes.iconRed}
                          onClick={() => handleAddAICancel()}
                          size="small">
                          <ClearOutlined style={{ color: '#DE5555' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {AI &&
                    AI.length > 0 &&
                    AI.sort((a, b) => b.id - a.id).map((item, idx) => {
                      return (
                        <TableRow>
                          {isEditAI && idx === editvaluesAI.editIndex ? (
                            <>
                              <TableCell>
                                <TextField
                                  className={classes.textBox}
                                  id="outlined-basic"
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline
                                    }
                                  }}
                                  onChange={event =>
                                    handleEditFieldChangeAI(
                                      'description',
                                      event.target.value
                                    )
                                  }
                                  value={editvaluesAI.description}
                                  variant="outlined"
                                />
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell>{item.description}</TableCell>
                            </>
                          )}
                          <TableCell align="center">
                            {isEditAI && idx === editvaluesAI.editIndex ? (
                              <>
                                <IconButton
                                  className={classes.iconGreen}
                                  onClick={() =>
                                    handleUpdateAdditionalItems(idx)
                                  }
                                  size="small"
                                  style={{ color: '#03A484' }}>
                                  <CheckOutlined />
                                </IconButton>
                                <IconButton
                                  className={classes.iconRed}
                                  onClick={() => clearAdditionalItems()}
                                  size="small">
                                  <ClearOutlined style={{ color: '#DE5555' }} />
                                </IconButton>
                              </>
                            ) : (
                              <>
                                <IconButton
                                  onClick={() =>
                                    handleEditAdditionalItems(item, idx)
                                  }
                                  size="small">
                                  <EditOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleOpenDeleteAI(item.id)}
                                  size="small">
                                  <DeleteOutlined className={classes.delIcon} />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </PerfectScrollbar>

      {handledeleteAllergy && renderDeleteAllergyDialogue()}
      {handledeleteSH && renderDeleteSHDialogue()}
      {handledeleteFH && renderDeleteFHDialogue()}
      {handledeleteAD && renderDeleteADDialogue()}
      {handledeleteAI && renderDeleteAIDialogue()}
      {handledeleteHM && renderDeleteHMDialogue()}
      {handledeleteVC && renderDeleteVaccinationDialogue()}
      {handledeleteMed && renderDeleteMedicationDialogue()}
    </Grid>
  );
};

MHxSoap.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {
  saveAllergy,
  updateAllergy,
  deleteAllergy,
  saveSocialHistory,
  updateSocialHistory,
  deleteSocialHistory,
  saveFamilyHistory,
  updateFamilyHistory,
  deleteFamilyHistory,
  saveAdvanceDirective,
  updateAdvanceDirective,
  deleteAdvanceDirective,
  saveAdditionalItems,
  updateAdditionalItems,
  deleteAdditionalItems,
  saveHealthMaintenance,
  updateHealthMaintenance,
  deleteHealthMaintenance,
  saveVaccinations,
  updateVaccinations,
  deleteVaccinations,
  saveMedication,
  updateMedication,
  inactivateMedication,
  activateMedication,
  deleteMedication,
  getPatientInactiveDiagnosis,
  getPatientActiveMedications
};

const mapStateToProps = () => ({});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MHxSoap))
);
