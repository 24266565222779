export default {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    //username: true,
    length: {
      minimum: 4,
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 6,
      maximum: 128
    }
  }
};
