/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './../../../style';
import moment from 'moment';
import { getPastSoapNoteById } from 'services/soap/action';
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Box,
  Modal,
  Avatar
} from '@material-ui/core';
import {
  LastPage,
  CloseOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  NavigateNext, NavigateBefore
} from '@material-ui/icons';
import Loader from '../../../../Common/components/Loader'
import { connect } from 'react-redux';
import { EncounterSUmmary } from './';
import MessageBox from 'util/messageBox';

const SoapPast = props => {
  const { classes, pastSoapNote, patientId, patientInfo } = props;
  const [openSoap, setOpenSoap] = React.useState(false);
  const [isHistory, setIsHistory] = useState(false); 
  const [page, setPage] = useState(0) 

  const setHistory = () => {
    setIsHistory(!isHistory);
  };
  const handleOpenSoap = item => {
    setOpenSoap({ open: true, soap: item });
  };
  const handleCloseSoap = () => {
    setOpenSoap(false);
  };

  const handleCopyPast = (soap, pastSoapNoteDetails) => {
    props.handleCopyPast(soap, pastSoapNoteDetails);
    setOpenSoap(false);
  };
  const handleEditPast = (soap, pastSoapNoteDetails) => {
    props.handleEditPast(soap, pastSoapNoteDetails);
    setOpenSoap(false);
  };

  //#region success
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });

  const showSuccessMsg = msg => {
    setSuccMsg({ succ: true, succMsg: msg });
  };

  const renderSuccess = () => {
    return (
      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />
    );
  };

  //#region error
  const [errmsg, setErrMsg] = useState({ err: false, errMsg: '' });


  const renderError = () => {
    return (
      <MessageBox
        message={errmsg.errMsg}
        onClose={handleClose}
        open={errmsg.err}
        variant="error"
      />
    );
  };

  const handleClose = () => {
    setSuccMsg({ succ: false });
  };



  // useEffect(() => {  
  // async function fetchData(patientId) { 
  //     const res =  await props.getPastSoapNoteById(patientId, page) 
  //      setPage(page)   
  //   };
  //   if(patientId)
  //   { 
  //     fetchData(patientId) 
  //   }
  // }, []);

  const handleChange = async (nav) => {
    let p;
    if (nav === 'next') {
      p = page + 1;
    } else if (nav === 'first') {
      p = 0;
    } else {
      p = page - 1;
    }
    await props.getPastSoapNoteById(patientId, p)
    setPage(p);
  };


  //#endregion

  return (
    //  <div
    // style={{
    //   border: "1px solid black",
    //   height: "300px", 
    // }} >   

    <Box className={classes.soapPastWrap}>
      <Box className={classes.soapPastHeader}>
        <Typography style={{ display: 'flex' }}>Past SOAP Notes
          {pastSoapNote && pastSoapNote.totalPages > 1 && <>
            {/* <IconButton disabled={page + 1 === 1} onClick={() => handleChange('first')} size="small">
            <FirstPage />
          </IconButton> */}
            <IconButton disabled={page + 1 === 1} onClick={() => handleChange('prev')} size="small">
              <NavigateBefore />
            </IconButton>
            <Avatar style={{ width: 20, height: 20, top: 5, fontSize: 12 }}> {page + 1} </Avatar>
            <IconButton disabled={pastSoapNote && pastSoapNote.last === true} onClick={() => handleChange('next')} size="small">
              <NavigateNext />
            </IconButton></>}
        </Typography>
        {props.isPast ? null : (
          <IconButton onClick={() => props.setPast()} size="small">
            <LastPage />
          </IconButton>
        )}
      </Box>
      <Box>
        {/* <div
      style={{
        border: "1px solid black",
        height: "300px",
        overflow: "auto"
      }} >   */}
        <PerfectScrollbar style={{ height: '75vh', paddingRight: 10 }} >

          <Table className={clsx(classes.plainTable, classes.blueBg)}>
            <TableBody>
              {!pastSoapNote && <Loader type="table" />}
              {pastSoapNote && pastSoapNote.totalPages === 0 && <TableRow> <TableCell>No records found </TableCell></TableRow>}
              {pastSoapNote && pastSoapNote.content &&
                pastSoapNote.content.length > 0 &&
                pastSoapNote.content.map(item => (
                  <TableRow onClick={() => handleOpenSoap(item)}>
                    <TableCell>
                      {!item.signed ? (
                        <span
                          style={{
                            height: 5,
                            width: 5,
                            borderRadius: 3,
                            backgroundColor: 'red',
                            display: 'inline-block',
                            position: 'absolute',
                            left: 6
                          }}
                        />
                      ) : (
                        ''
                      )}
                      {item.visitdate
                        ? moment(item.visitdate).format('MM/DD/YYYY')
                        : ''}
                    </TableCell>
                    <TableCell>
                      {item.soapnote &&
                        item.soapnote.subjective &&
                        item.soapnote.subjective.substring(0, 50)}
                    </TableCell>
                    <TableCell>
                      {item.signed
                        ? 'Signed by :' +
                        (item.provider.lastname
                          ? item.provider.lastname
                          : '') +
                        ' ' +
                        (item.provider.firstname
                          ? item.provider.firstname
                          : '')
                        : !item.signed
                          ? 'Routed to :' +
                          (item.provider.lastname
                            ? item.provider.lastname
                            : '') +
                          ' ' +
                          (item.provider.firstname
                            ? item.provider.firstname
                            : '')
                          : !item.routed && !item.signed
                            ? 'Created by :' +
                            (item.provider.lastname
                              ? item.provider.lastname
                              : '') +
                            ' ' +
                            (item.provider.firstname
                              ? item.provider.firstname
                              : '')
                            : // :
                            //  !item.routed && item.signed
                            //   ? 'Signed by : ' + item.signedby
                            //     ? item.signedby
                            //     : (item.provider.lastname
                            //       ? item.provider.lastname
                            //       : '') +
                            // ' ' +
                            // (item.provider.firstname
                            //   ? item.provider.firstname
                            //   : '')
                            ''}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
        {/* </div> */}

      </Box>
      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseSoap}
        open={openSoap.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="h2">
              {openSoap && openSoap.soap && openSoap.soap.visitdate
                ? 'Encounter Date :' +
                moment(openSoap.soap.visitdate).format('MM/DD/YYYY')
                : ''}
            </Typography>
            <span>
              <Button
                color="primary"
                onClick={() => setHistory()}
                size="small"
                startIcon={
                  isHistory ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )
                }
                style={{ textTransform: 'capitalize' }}
                variant="outlined">
                {isHistory ? 'Show' : 'Hide'}&nbsp;Medical History
              </Button>
              <IconButton aria-label="delete" onClick={handleCloseSoap}>
                <CloseOutlined />
              </IconButton>
            </span>
          </Box>
          <EncounterSUmmary
            page={page}
            additionalItems={props.additionalItems}
            advanceDirectives={props.advanceDirectives}
            allergies={props.allergies}
            familyHistory={props.familyHistory}
            handleCopyPast={handleCopyPast}
            handleEditPast={handleEditPast}
            healthMaintenance={props.healthMaintenance}
            inactivemedication={props.inactivemedication}
            isHistory={isHistory}
            medication={props.medication}
            onCancel={handleCloseSoap}
            patientId={patientId}
            patientInfo={patientInfo}
            setHistory={setHistory}
            showSuccessMsg={showSuccessMsg}
            soap={openSoap && openSoap.soap}
            socialHistory={props.socialHistory}
            vaccinations={props.vaccinations}
          />
        </Box>
      </Modal>
      {succmsg.succ && renderSuccess()}
      {errmsg.err && renderError()}
    </Box>
    // {/* </PerfectScrollbar> */}

    // </div> 
  );
};

SoapPast.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getPastSoapNoteById
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  pastSoapNote: state.soap && state.soap.pastSoapNote,
  patientId: state.soap && state.soap.patientInfo && state.soap.patientInfo.id,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SoapPast))
);
