/* eslint-disable react/no-multi-comp */
import {
  Box, Card,
  CardContent, CircularProgress, Divider,
  Grid,
  IconButton, Modal, Tab, Tabs, Typography
} from '@material-ui/core';
import {
  CloseOutlined, DateRangeOutlined, FileCopyOutlined,
  QueryBuilderOutlined
} from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getChartViewSoapandPatientDetails,
  getDocInfo, getPatientDocuments,
} from 'services/home/action';
import { getAllPhyPtSchedules } from 'services/ptschedules/action';
import {
  clearSoap, getPastSoapNoteDetails, getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication, getPatientInfo, getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks
} from 'services/soap/action';
import { getLocations, getProviders } from 'services/util/action';
import { Roles } from 'util/enum';
import MessageBox from 'util/messageBox';
import { DocViewer } from 'views/Common/components';
import { EncounterSUmmary } from 'views/Default/components/Soap/components';
import { ChartReview, Reminders } from '.';
import styles from '../../Default/style';
import Schedule from '../../PTSchedule/components/CalendarDaily';

const Review = props => {
  const {
    classes,
    className,
    data,
    isloading,
    ehrID,
    locations,
    providers,
    documentInfo,
    userID,
    chartSoapPatientDetails,
    role,
    isSearchPage,
    getPatientInfo,
    getPatientActiveMedications,
    getPatientInactiveDiagnosis,
    getPatientInactiveMedication,
    getPatientMedicalHistory,
    getPatientPastDiagnosis,
    getPatientRemarks,
    getPastSoapNoteDetails,
    ...rest
  } = props;
  let [isClinical, setIsClinical] = useState(false);
  const scroll_ps = useRef();
  const [dataDocViewer, setDataDocViewer] = useState([]);
  const [doc, setDoc] = useState([]);
  const [openDoc, setOpenDoc] = useState(false);
  const [openViewSoap, setOpenViewSoap] = useState({
    open: false,
    patientId: 0,
    soap: {}
  });

  const loadInitialData = async () => {
    props.getLocations(ehrID);
    props.getProviders(ehrID);
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const [value, setValue] = React.useState(0);
  const [filtervalues, setFilterValues] = React.useState(null);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const handleChange = (_event, newValue) => {
      setValue(newValue);
    };
    return (
      <Typography
        aria-labelledby={`simple-tab-${index}`}
        component="div"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        role="tabpanel"
        {...other}>
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  //#region Schedule
  const handleEditAppointment = item => {
    props.handleEditAppointment(item);
  };

  const handleDeleteAppointment = item => {
    props.handleDeleteAppointment(item);
  };

  const handleOpenScheduleAppointment = () => {
    props.handleOpenScheduleAppointment();
  };

  //#endregion

  //Filter

  const handleDrpDownChange = (location, provider) => {
    setFilterValues({ location: location, provider: provider });
    props.handleDrpDownChange(location, provider);
  };
  //---------------Doc viewer
  useEffect(() => {
    if (documentInfo !== undefined) {
      setDataDocViewer(documentInfo);
    }
  }, [documentInfo]);

  const handleCloseDoc = () => {
    setOpenDoc(false);
    setDataDocViewer({});
  };

  const handleDeleteDoc = (_ptid) => { 
    setOpenDoc(false); 
    setDataDocViewer([])
  };

  const handleOpenDoc = async (
    patientId,
    patientDocumentId,
    routeTo,
    isClinical
  ) => {
    setIsClinical(isClinical);
    setOpenDoc(true);
    const doc = await props.getDocInfo(patientId,patientDocumentId)
    setDoc(doc)
    props.getPatientDocuments(
      ehrID,
      patientId,
      patientDocumentId,
      routeTo,
      isClinical
    );
    getPatientActiveMedications(patientId)
    getPatientInactiveDiagnosis(patientId)
    getPatientInactiveMedication(patientId)
    getPatientMedicalHistory(patientId)
    getPatientPastDiagnosis(patientId)
    getPatientRemarks(patientId)
  };

  const handleOpenViewSoap = async (patientId, resourceID) => {
    setOpenViewSoap({
      open: true,
      patientId: patientId,
    });
    props.getChartViewSoapandPatientDetails(
      ehrID,
      patientId,
      userID,
      resourceID
    );
    getPastSoapNoteDetails(ehrID, patientId, userID, resourceID)
    getPatientActiveMedications(patientId)
    getPatientInactiveDiagnosis(patientId)
    getPatientInactiveMedication(patientId)
    getPatientMedicalHistory(patientId)
    getPatientPastDiagnosis(patientId)
    getPatientRemarks(patientId)

  };

  const handleCloseViewSoap = () => {
    setOpenViewSoap({ open: false });
    props.clearSoap();
  };

  const handleCopyfromChartReview = (soap, pastSoapNoteDetails) => {
    props.clearSoap();
    props.history.push({
      pathname: '/default',
      state: {
        soap: soap,
        pastSoapNoteDetails: pastSoapNoteDetails,
        isAction: 'Copy'
      }
    });
  };

  const handleEditfromChartReview = (soap, pastSoapNoteDetails) => {
    props.clearSoap();
    props.history.push({
      pathname: '/default',
      state: {
        soap: soap,
        pastSoapNoteDetails: pastSoapNoteDetails,
        isAction: 'Edit'
      }
    });
  };

  //#region success
  const [succmsg, setSuccMsg] = useState({ succ: false, succMsg: '' });

  const showSuccessMsg = msg => {
    setSuccMsg({ succ: true, succMsg: msg });
  };

  const renderSuccess = () => {
    return (
      <MessageBox
        message={succmsg.succMsg}
        onClose={handleClose}
        open={succmsg.succ}
        variant="success"
      />
    );
  };

  const handleClose = () => {
    setSuccMsg({ succ: false });
  };

  return (
    <Card {...rest} className={clsx(classes.subTabRoot, className)}>
      <Grid container>
        <Grid item xs={12}>
          <Tabs
            aria-label="simple tabs example"
            className={classes.subTab}
            indicatorColor="primary"
            onChange={handleChange}
            value={value}
            variant="fullWidth">
            {role !== Roles.staff && (
              <Tab
                icon={<FileCopyOutlined />}
                label="Chart Review"
                {...a11yProps(0)}
              />
            )}

            <Tab
              icon={<QueryBuilderOutlined />}
              label="Schedule"
              {...a11yProps(1)}
            />
            <Tab
              icon={<DateRangeOutlined />}
              label="Reminders"
              {...a11yProps(2)}
            />
          </Tabs>
        </Grid>
      </Grid>
      <Divider />
      <CardContent className={classes.inlineTabWrap}>
        {role !== Roles.staff ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TabPanel index={0} style={{ padding: 0 }} value={value}>
                <PerfectScrollbar>
                  <div style={{ maxHeight: '65vh' }}>
                    <ChartReview
                      handleOpenDoc={handleOpenDoc}
                      handleOpenViewSoap={handleOpenViewSoap}
                      isClinical={isClinical}
                    />
                  </div>
                </PerfectScrollbar>
              </TabPanel>
              <TabPanel index={1} value={value}>
                <PerfectScrollbar containerRef={el => (scroll_ps.current = el)}>
                  {/* <div style={{ maxHeight: '75vh' }}> */}
                  {isloading ? (
                    <div
                      className={classes.progressWrapper}
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        marginBottom: 30
                      }}>
                      <CircularProgress style={{ height: 30, width: 30 }} />
                    </div>
                  ) : (
                    <Schedule
                      data={data}
                      filtervals={filtervalues}
                      isHomeLocalStorage={props.isHomeLocalStorage}
                      handleDeleteAppointment={handleDeleteAppointment}
                      handleDrpDownChange={props.handleDrpDownChange}
                      handleEditAppointment={handleEditAppointment}
                      handleOpenScheduleAppointment={
                        handleOpenScheduleAppointment
                      }
                      locations={locations}
                      openSchedule={props.handleOpen1}
                      providers={providers}
                      isSearchPage={props.isSearchPage}
                      scheduleBtn
                      scroll_ps={scroll_ps}
                    />
                  )}
                  {/* </div> */}
                </PerfectScrollbar>
              </TabPanel>
              <TabPanel index={2} value={value}>
                <PerfectScrollbar>
                  <div style={{ maxHeight: '75vh' }}>
                    <Reminders noTitle />
                  </div>
                </PerfectScrollbar>
              </TabPanel>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <TabPanel index={0} value={value}>
              <PerfectScrollbar containerRef={el => (scroll_ps.current = el)}>
                <div style={{ maxHeight: '75vh' }}>
                  {isloading ? (
                    <div
                      className={classes.progressWrapper}
                      style={{
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        marginBottom: 30
                      }}>
                      <CircularProgress style={{ height: 30, width: 30 }} />
                    </div>
                  ) : (
                    <Schedule
                      data={data}
                      filtervals={filtervalues}
                      isHomeLocalStorage={props.isHomeLocalStorage}
                      isSearchLocalStorage={props.isSearchLocalStorage}
                      handleDeleteAppointment={handleDeleteAppointment}
                      handleDrpDownChange={props.handleDrpDownChange}
                      handleEditAppointment={handleEditAppointment}
                      handleOpenScheduleAppointment={
                        handleOpenScheduleAppointment
                      }
                      locations={locations}
                      openSchedule={props.handleOpen1}
                      providers={providers}
                      isSearchPage={props.isSearchPage}
                      scheduleBtn
                      scroll_ps={scroll_ps}
                    />
                  )}
                </div>
              </PerfectScrollbar>
            </TabPanel>

            <TabPanel index={1} value={value}>
              <PerfectScrollbar>
                <div style={{ maxHeight: '75vh' }}>
                  <Reminders noTitle />
                </div>
              </PerfectScrollbar>
            </TabPanel>
          </Grid>
        )}
      </CardContent>
      {succmsg.succ && renderSuccess()}
      <Modal onClose={handleCloseDoc} open={openDoc}>
        <DocViewer
          dataDocViewer={dataDocViewer}
          onCancel={handleCloseDoc}
          isClinical={isClinical}
          doc={doc}
          handleDeleteDoc={handleDeleteDoc}
        />
      </Modal>

      <Modal
        aria-describedby="simple-modal-description"
        aria-labelledby="simple-modal-title"
        disableBackdropClick
        onClose={handleCloseViewSoap}
        open={openViewSoap.open}>
        <Box
          className={classes.modalWrap}
          style={{ width: '95%', maxWidth: 1200 }}>
          <Box className={classes.modalHead}>
            <Typography
              style={{ display: 'flex', alignItems: 'center' }}
              variant="h2">
              {chartSoapPatientDetails && chartSoapPatientDetails.soap && chartSoapPatientDetails.soap.visitdate
                ? 'Encounter Date :' +
                moment(chartSoapPatientDetails.soap.visitdate).format('MM/DD/YYYY')
                : ''}
            </Typography>
            <span>
              <IconButton aria-label="delete" onClick={() => { handleCloseViewSoap() }}>
                <CloseOutlined />
              </IconButton>
            </span>
          </Box>
          <EncounterSUmmary
            handleCopyPast={handleCopyfromChartReview}
            handleEditPast={handleEditfromChartReview}
            // isHistory={isHistory}
            onCancel={handleCloseViewSoap}
            // setHistory={setHistory}
            patientId={openViewSoap && openViewSoap.patientId}
            showSuccessMsg={showSuccessMsg}
          />
        </Box>
      </Modal>
    </Card>
  );
};

Review.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  getAllPhyPtSchedules,
  getLocations,
  getProviders,
  getPatientDocuments,
  getChartViewSoapandPatientDetails,
  getPatientInfo,
  getPatientActiveMedications,
  getPatientInactiveDiagnosis,
  getPatientInactiveMedication,
  getPatientMedicalHistory,
  getPatientPastDiagnosis,
  getPatientRemarks,
  getPastSoapNoteDetails,
  clearSoap,
  getDocInfo,
};

const mapStateToProps = state => ({
  role: state.profile && state.profile.role,
  ehrID: state.profile && state.profile.ehrID,
  userID: state.profile && state.profile.userID,
  locations: state.util.locations,
  providers: state.util.providers,
  allPhyPtSchedules:
    state.ptschedules &&
    state.ptschedules.allPhyPtSchedules &&
    state.ptschedules.allPhyPtSchedules.result,
  // categories:
  //   state.home &&
  //   state.home.documentInfo &&
  //   state.home.documentInfo.patientDocument &&
  //   state.home.documentInfo.patientDocument.category,
  documentInfo: state.home && state.home.documentInfo,
  chartSoapPatientDetails: state.home && state.home.chartSoapPatientDetails
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Review))
);
