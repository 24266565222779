import React, {
  useState, useEffect, forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import styles from '../../../SignIn/style';
import {
  IconButton,
  InputAdornment,
  Grid,
  TextField,
  Typography,
  withStyles,
  Slide
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import validate from "validate.js";
import schema from "./schema";
import { connect } from "react-redux";

const StepTwo = forwardRef((props, ref) => {
  const { classes } = props;
  useImperativeHandle(ref, () => {
    return {
      validateForm,
      fetchdata
    };
  });


  const [values, setValues] = useState({
    medicallicense: "",
    individualnpi: "",
    dea: "",
    username: "",
    password: "",
    confirmpassword: "",
  });
  const [errors, setErrors] = useState({
    username: null,
    password: null,
    confirmpassword: null
  });
  const [, setValid] = useState(false);
  const [touched, setTouched] = useState({
    password: false,
    confirmpassword: false,
  });
  const [ispassword, setIsPassword] = useState(false);
  const [isconfirmPassword, setIsConfirmPassword] = useState(false);

  const validateForm = () => {
    let errors = validate(values, schema);
    setErrors(errors || {});
    let valid = errors ? false : true;
    setValid(valid);
    return { valid: valid, data: values };
  }

  const fetchdata = () => {
    return values;
  }

  const handleFieldChange = (field, value) => {
    setValues({ ...values, [field]: value });
    setTouched({ ...touched, [field]: true });
  };

  const handleClickShowPassword = () => {
    setIsPassword(!ispassword);
  };

  const handleClickShowConfirmPassword = () => {
    setIsConfirmPassword(!isconfirmPassword);
  };

  useEffect(() => {
    if (props.nextdatas && (Object.keys(props.nextdatas).length !== 0) && props.isback) {
      setValues(props.nextdatas);
    }
  }, []);

  return ( 
      <div>
        <Typography className={classes.formSubTitle}>
          Step 2 - Medical Information &amp; Credentials
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Medical License #"
              name="medicallicense"
              variant="outlined"
              onChange={event =>
                handleFieldChange("medicallicense", event.target.value)
              }
              value={values.medicallicense}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Individual NPI #"
              name="individualnpi"
              variant="outlined"
              onChange={event =>
                handleFieldChange("individualnpi", event.target.value)
              }
              value={values.individualnpi}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="DEA #"
              name="dea"
              variant="outlined"
              onChange={event =>
                handleFieldChange("dea", event.target.value)
              }
              value={values.dea}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputProps={{
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              fullWidth
              label="Username"
              name="username"
              required
              variant="outlined"
              error={errors.username}
              onChange={event =>
                handleFieldChange("username", event.target.value)
              }
              value={values.username}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {!ispassword ? (
                        <VisibilityOff />
                      ) : (
                          <Visibility />
                        )}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              fullWidth
              label="Password"
              name="password"
              type={ispassword ? "text" : "password"}
              required
              variant="outlined"
              error={errors.password}
              onChange={event =>
                handleFieldChange("password", event.target.value)
              }
              value={values.password}
              helperText={
                errors.password ? errors.password[0] : null
              }
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocusedS
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {!isconfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                          <Visibility />
                        )}
                    </IconButton>
                  </InputAdornment>
                ),
                classes: {
                  root: classes.inputOutlinedG,
                  focused: classes.inputFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
              fullWidth
              label="Confirm Password"
              name="confirmpassword"
              type={isconfirmPassword ? "text" : "password"}
              required
              variant="outlined"
              error={errors.confirmpassword}
              onChange={event =>
                handleFieldChange("confirmpassword", event.target.value)
              }
              value={values.confirmpassword}
              helperText={
                errors.confirmpassword ? errors.confirmpassword[0] : null
              }
            />
          </Grid>
        </Grid>
      </div> 
  );
});

StepTwo.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = {};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(withStyles(styles)(StepTwo));

