import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
//import { withRouter } from 'react-router-dom';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
// Material components
import { CloseOutlined, CancelOutlined } from '@material-ui/icons';
import styles from './../../../style';
import validate from 'validate.js';
import schema from './schema';
import MessageBox from 'util/messageBox';
import { GlobalSearch } from 'views/Common/components/';

import {
  Card,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton
} from '@material-ui/core';

import { connect } from 'react-redux';
import moment from 'moment';

import {
  addPatient,
  getPatients,
  getSearchPatients
} from 'services/patient/action';
import { computeAge } from 'services/util/action';
import { Roles } from 'util/enum';

const Create = props => {
  const { classes, className, ehrID, patName, ...rest } = props;

  const initialUserState = {
    patientName: '',
    firstname: '',
    middleinitial: '',
    lastname: '',
    roles: Roles.user + '+' + Roles.patient,
    username: '',
    emailaddress: '',
    confirmEmail: '',
    selectedPatient: null,
    isValid: false,
    touched: {},
    errors: {},
    isLoading: false,
    page:0,
    limit: 20,
    order: 'ASC',
    sortBy: 'lastname',
    search:''
  };
  const [succMsg, setSuccMsg] = useState(false);
  const [values, setValues] = useState(initialUserState);
  const [search, setSearch] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [data, setData] = useState(null);
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    if (search) {
      if (props.patients !== undefined) {
        setData(props.patients);
        if (data && data.length > 0) {
          setShowSearch(true);
        } else {
          setShowSearch(false);
        }
      }
    }
  }, [props.patients]);

  const hasError = field =>
    values.touched[field] && values.errors[field] ? true : false;

  const handleChange = (field, value) => {
   const newState = { ...values };

    newState[field] = value;
    newState.touched[field] = true;
    if (field === 'patientName') {
    const name = value.split(' ');
      if (name.length === 1) {
        newState.firstname = name[0];
        newState.lastname = name[0];
      } else if (name.length === 2) {
        newState.firstname = name[0];
        newState.lastname = name[1];
      } else {
        newState.firstname = name[0];
        newState.middleinitial = name[1];
        newState.lastname = name[2];
      }
      setSearch(value);
      if (value && value.length > 0) {
        setShowSearch(true);
        // props.getSearchPatients(ehrID, value);
      } else {
        setData(null);
        setShowSearch(false);
      }
    }

    const errors = validate(newState, schema);
    newState.errors = errors || {};
    newState.isValid = errors ? false : true;

    setValues(newState);
  };

  const handleSubmit = async () => {
    if (values.isValid) {
      const newState = { ...values };
      const name = newState.patientName.split(',');
       if (name.length === 1) { 
         newState.firstname = name[0];
       
        newState.lastname = name[0];
      
      } else {
        newState.lastname = name[0];
        newState.firstname = name[1];
      }
      const patientId = newState.selectedPatient
        ? newState.selectedPatient.id
        : 0;
      setValues({ ...values, isLoading: true });

      const status = await props.addPatient(ehrID, patientId, newState);
      if (status) {
        setSuccMsg(true);
        setValues(initialUserState);
        const patients = props
          .getPatients(ehrID, values.page,values.search,values.limit)
          .then(patientsStatus => {
            if (patientsStatus) {
              setPatients(patients);
            }
          });
      } else {
        setValues({ ...values, isLoading: false });
      }
    }
  };

  const handleCloseAll = () => {
    setSuccMsg(false);
  };

  const computeAge = dob => {
    var magicNumber = 1000 * 60 * 60 * 24 * 365;
    var age = Math.abs(new Date() - new Date(dob));
    age = Math.floor(age / magicNumber);

    return age;
  };

  const prefillPatient = patient => {
    const newState = { ...values };
    newState.patientName = patient.lastname + ',' + patient.firstname;
    newState.emailaddress = patient.emailaddress || '';
    newState.confirmEmail = patient.emailaddress || '';
    newState.selectedPatient = patient;
    setValues(newState);
    setShowSearch(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.cardRoot, className)}
      style={{ borderTopLeftRadius: 0 }}>
      <Grid container>
        <Grid item xs={12} className={classes.cardHeader}>
          <Typography variant="h2">Add</Typography>
        </Grid>
        <Grid item xs={12} className={classes.cardSubHeader}>
          <Typography variant="h3">Patient Information</Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              autoComplete="off"
              error={hasError('patientName')}
              fullWidth
              helperText={
                hasError('patientName') ? values.errors.patientName[0] : null
              }
              label="Patient Name"
              name="patientName"
              required
              variant="outlined"
              color="Primary"
              onChange={event =>
                handleChange('patientName', event.target.value)
              }
              value={values.patientName}
            />
            {showSearch ? (
              <GlobalSearch
                isGlobal={false}
                searchterm={search}
                globalsearchList={data}
                open={showSearch}
                setShowSearch={setShowSearch}
                prefillPatient={prefillPatient}
              />
            ) : (
              ''
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                classes: {
                  focused: classes.labelFocused
                }
              }}
              error={hasError('username')}
              fullWidth
              helperText={
                hasError('username') ? values.errors.username[0] : null
              }
              label="Username"
              name="username"
              required
              variant="outlined"
              color="Primary"
              onChange={event => handleChange('username', event.target.value)}
              value={values.username}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused
                }
              }}
              error={hasError('emailaddress')}
              fullWidth
              helperText={
                hasError('emailaddress') ? values.errors.emailaddress[0] : null
              }
              label="Email Address"
              name="emailaddress"
              required
              variant="outlined"
              color="Primary"
              onChange={event =>
                handleChange('emailaddress', event.target.value)
              }
              value={values.emailaddress}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true,
                classes: {
                  focused: classes.labelFocused
                }
              }}
              error={hasError('confirmEmail')}
              fullWidth
              helperText={
                hasError('confirmEmail') ? values.errors.confirmEmail[0] : null
              }
              label="Confirm Email Address"
              name="confirmEmail"
              required
              variant="outlined"
              color="Primary"
              onChange={event =>
                handleChange('confirmEmail', event.target.value)
              }
              value={values.confirmEmail}
            />
          </Grid>
          {values.isLoading ? (
            <div
              className={classes.progressWrapper}
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginBottom: 30
              }}>
              <CircularProgress style={{ height: 30, width: 30 }} />
            </div>
          ) : (
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!values.isValid}
                onClick={handleSubmit}>
                Send Invite
              </Button>
            </Grid>
          )}
        </Grid>
        <MessageBox
          open={succMsg}
          variant="success"
          onClose={handleCloseAll}
          message="Account activation mail sent successfully"
        />
      </CardContent>
    </Card>
  );
};

Create.propTypes = {
  className: PropTypes.string
};

const mapDispatchToProps = {
  addPatient,
  getPatients,
  getSearchPatients
  //computeAge
};

const mapStateToProps = state => ({
  ehrID: state.profile && state.profile.ehrID,
  patName: state.search,
  patients: state.patient && state.patient.search && state.patient.search.result
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Create))
);
