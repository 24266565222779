import React from 'react';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import styles from '../../../style';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core';
import { ChevronLeftOutlined } from '@material-ui/icons';
import { connect } from 'react-redux';
import {  getPatientActiveMedications, getPatientInactiveMedication } from 'services/soap/action';

const MyTemplates = props => {
  const { classes, ptInactiveMedication, ptActiveMedication } = props;   

  const handleCopy = item => {
    props.handleCopy(item);
  };

  return   (
    <div className={classes.templateWrap}>
      <div className={classes.templateBody}>
        <PerfectScrollbar>
          <div style={{ maxHeight: 'calc(100vh - 236px)' }}>
            <Table className={classes.templateTable}>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.medicatn}
                    colspan={5}
                  >
                    Active Medications
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ptActiveMedication && ptActiveMedication.length>0 && ptActiveMedication.sort((a, b) => b.id - a.id).map((item) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <span
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <IconButton
                              color="primary"
                              size="small"
                            >
                              <ChevronLeftOutlined
                                onClick={() => handleCopy(item)}
                              />
                            </IconButton>
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                                maxWidth: 70,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}
                            >
                              {item.name ? item.name : ''}
                            </span>
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: 50,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: 'inline-block'
                            }}
                          >
                            {item.dose ? item.dose : ''}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              whiteSpace: 'nowrap',
                              maxWidth: 50,
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: 'inline-block'
                            }}
                          >
                            {item.directions ? item.directions : ''}
                          </span>
                        </TableCell>
                       
                      </TableRow>
                    );
                  })}
              </TableBody>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.medicatn}
                    colspan={5}
                  >
                    Inactive Medications
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ptInactiveMedication && ptInactiveMedication.length>0 && ptInactiveMedication.sort((a, b) => b.id - a.id).map((item) => {
                    return (
                      <TableRow>
                        <TableCell>
                          <IconButton
                            color="primary"
                            size="small"
                          >
                            <ChevronLeftOutlined
                              onClick={() => handleCopy(item)}
                            />
                          </IconButton>
                          {item.name ? item.name : ''}
                        </TableCell>
                        <TableCell>{item.dose ? item.dose : ''}</TableCell>
                        <TableCell>
                          {item.directions ? item.directions : ''}
                        </TableCell>
                      
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );
};
MyTemplates.propTypes = {
  className: PropTypes.string
};
const mapDispatchToProps = { 
  getPatientInactiveMedication,
  getPatientActiveMedications
};
const mapStateToProps = (state) => ({ 
  ptInactiveMedication: state.soap && state.soap.ptInactiveMedication,
  ptActiveMedication: state.soap && state.soap.ptActiveMedication, 
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyTemplates))
);

